import {postAjax,getAjax}  from "../common/axios.js"

//卡项列表-总店
export const productList = params => {
	return postAjax('/api/run/product/list',params)
}
//卡项列表-门店
export const list4Store = params => {
	return postAjax('/api/run/product/list4Store',params)
}

//添加卡项
export const productInsert = params => {
	return postAjax('/api/run/product/insert',params)
}

//卡项详情
export const productInfo = params => {
	return postAjax('/api/run/product/info',params)
}

//编辑卡项
export const productUpdate = params => {
	return postAjax('/api/run/product/update',params)
}
//删除卡项
export const productDelete = params => {
	return postAjax('/api/run/product/delete',params)
}

//获取绑定课程
export const courseNexus = params => {
	return postAjax('/api/run/product/courseNexus',params)
}

//列表绑定课程
export const updateCourseNexus = params => {
	return postAjax('/api/run/product/updateCourseNexus',params)
}

//获取上架门店信息
export const storeShelfState = params => {
	return postAjax('/api/run/product/storeShelfState',params)
}
//更新上架状态
export const updateShelf = params => {
	return postAjax('/api/run/product/updateShelf',params)
}

//更新消费类目
export const updateRun = params => {
	return postAjax('/api/run/product/updateRun',params)
}
