<template>
    <div>
        <el-dialog 
            v-model="pageData.outerVisible"
            width="980px"
            title="课节调整"
            :close-on-press-escape="false"
            :close-on-click-modal="false"
            :before-close="handleClose"
        >
            <template #default>
                <div class="pt20 pb40 pl40 pr40 editForm h600">
                    <el-form ref="formRef" :model="form" :rules="rules" label-width="120px">
                        <el-row>
                            <el-col>
                                <CommonTitle text="基本信息"/> 
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="选择课程">
                                    <el-select v-model="form.courseNameId" :disabled="propData.type == 13">
                                        <el-option
                                            v-for="item in pageData.courseList"
                                            :key="item.courseNameId"
                                            :label="item.courseName"
                                            :value="item.courseNameId"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="上课教练" prop="teacherIdList">
                                    <el-select
                                        v-model="form.teacherIdList"
                                        placeholder="请选择上课教练"
                                      >
                                        <el-option
                                          v-for="item in pageData.teacherList"
                                          :key="item.id"
                                          :label="item.realName"
                                          :value="item.id"
                                        >
                                        </el-option>
                                      </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="容纳人数" prop="allPeopleNum">
                                    <el-input v-model="form.allPeopleNum" placeholder="请输入容纳人数">
                                        <template #suffix>
                                            人
                                        </template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="课节主题">
                                    <el-input v-model="form.courseCustomName" placeholder="请输入课节主题" />
                                </el-form-item>
                            </el-col>
                            <courseSeniorSet ref="seniorSetDot" :type="propData.type" :classType="propData.classType" :info="pageData.dataInfo" style="width:100%"></courseSeniorSet>
                            <el-col>
                               <CommonTitle text="排课信息"/>
                            </el-col>
                            <el-col>
                                <el-form-item label="开课日期" prop="classTimeStartStr">
                                    <el-date-picker
                                        v-model="form.classTimeStartStr"
                                        type="date"
                                        placeholder="请选择开课日期"
                                        format="YYYY-MM-DD"
                                        value-format="YYYY-MM-DD"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col>
                                <el-form-item label="上课时间">
                                    <el-col :span="4" class="mr30">
                                        <el-time-select
                                            v-model="form.startTime"
                                            :max-time="form.endTime"
                                            class="mr-4"
                                            placeholder="开始时间"
                                            start="00:00"
                                            step="00:05"
                                            end="23:55"
                                        />
                                    </el-col>
                                   <el-col :span="1" style="margin-right: 8px">
                                        <span>至</span>
                                    </el-col>
                                    <el-col :span="4">
                                        <el-time-select
                                            v-model="form.endTime"
                                            :min-time="form.startTime"
                                            placeholder="结束时间"
                                            start="00:00"
                                            step="00:05"
                                            end="23:55"
                                        />
                                    </el-col>
                                </el-form-item>
                            </el-col>
                           <el-col>
                               <CommonTitle text="上课场地"/>
                            </el-col>
                            <el-col>
                                <el-form-item label="上课场地">
                                    <span class="mr10" v-if="pageData.PlaceCheckList.length">{{  pageData.PlaceCheckList[0].roomName }}</span>
                                    <span class="mr10" v-else>{{ form.roomName ? form.roomName : '请选择场地' }}</span>
                                    <el-button type="primary" @click="selectRoom">{{ form.roomName ? '重选场地' : '选择场地' }}</el-button>
                                    <el-button @click="addNewPlace(0)">新增场地</el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
                <el-dialog
                    v-model="pageData.innerVisible"
                    width="800px"
                    title="选择教室"
                    :before-close="placeClose"
                    append-to-body
                >   
                    <div class="p20">
                        <el-table stripe ref="refPlace" @selection-change="selectPlaceChange" :data="pageData.PlaceList" height="500" class="single-select-table">
                            <el-table-column type="selection" width="55" />
                            <el-table-column prop="roomName" label="场地名称" />
                            <el-table-column prop="containNum" label="容纳人数" align="right" />
                            <el-table-column label="冲突状态">
                                <template #default="scope">
                                    <div v-if="scope.row.conflictStatus == 0"><span class="sp-green v_a_m d_i_b mr5"></span>空闲</div>
                                    <div v-if="scope.row.conflictStatus != 0"><span class="sp-red v_a_m d_i_b mr5"></span>冲突</div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="bbb" label="冲突信息">
                                <template #default="scope">
                                    <a v-if="scope.row.conflictStatus != 0" class="color-409EFF tool_btn t_d_u" href="javaScript:;" @click="toConflict(scope.row)">查看课表</a>
                                    <span v-if="scope.row.conflictStatus == 0" >查看课表</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <el-dialog
                        v-model="pageData.innerPlaceShow"
                        width="500px"
                        title="冲突详情"
                        append-to-body
                    >
                        <div class="p20">
                            <el-table stripe :data="pageData.innerPlaceList"  height="400">
                                <el-table-column type="index" label="序号" width="55" />
                                <el-table-column prop="roomName" label="冲突日期">
                                    <template #default="scope">
                                        {{ scope.row.classTimeStartStr }}({{ scope.row.week }})
                                    </template>
                                </el-table-column>
                                <el-table-column prop="containNum" label="冲突时间" >
                                    <template #default="scope">
                                        {{ scope.row.startTime }}-{{ scope.row.endTime }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="冲突原因">
                                    <template #default="scope">
                                        <span v-if="scope.row.courseName">{{ scope.row.courseName }}排课冲突</span>
                                        <span v-else>场地被锁定</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </el-dialog>
                    <template #footer>
                        <div class="dialog-footer">
                            <el-button @click="placeClose">取 消</el-button>
                            <el-button type="primary" @click="submitSelectRoom">确 定</el-button>
                        </div>
                    </template>
                </el-dialog>
                
                <el-dialog
                  v-model="pageData.innerVisible1"
                  width="500px"
                  title="新增场地"
                  append-to-body
                >
                    <div class="p20">
			        	<div class="d_f mb20">
			        		<div class="query_tip w100 rx-ec"><span class="color-red">*</span>场地名称：</div>
			        		<el-input class="w300" v-model="pageData.addPlaceFormData.roomName" placeholder="输入场地名称(限10字内)" maxlength="10"/>
                        </div>
                        <div class="d_f mb20">
			        		<div class="query_tip w100">可容纳人数：</div>
			        		<el-input class="w300" v-model.number="pageData.addPlaceFormData.containNum" oninput="value=value.replace(/[^0-9]/g,'')">
                                <template #append>人</template>
                            </el-input>
                        </div>
                        <div class="d_f mb20">
			        		<div class="query_tip w100">备注：</div>
			        		<el-input
                                class="w300"
                                v-model="pageData.addPlaceFormData.remark"
                                :rows="4"
                                type="textarea"
                                placeholder="请输入备注"
                            />
                        </div>
			        </div>
                    <template #footer>
                        <div class="dialog-footer">
                            <el-button @click="pageData.innerVisible1 = false">取 消</el-button>
                            <el-button type="primary" @click="addNewPlace(1)">确 定</el-button>
                        </div>
                    </template>
                </el-dialog>
            </template>
            <template #footer>
                <div class="dialog-footer">
                  <el-button @click="handleClose">取 消</el-button>
                  <el-button type="primary" @click="onEditWork">确 定</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import courseSeniorSet from '@/components/branch-store/course/courseSeniorSet.vue';
    import { ElMessage } from 'element-plus'
    import CommonTitle from '@/components/CommonTitle.vue'
    import { courseList, courseToEdit, courseEdit } from '@/api/course.js';
    import { getStoreAccountStaffSimple } from '@/api/staff.js';
    import { getRoomConflictForEditList, sportRoomInsert } from '@/api/place.js';
    const formRef=ref()
    const seniorSetDot = ref()
    const propData = defineProps({
		id: String, //课表详情
        type: Number,
        classType: Number,
	})
    const pageData = reactive({
        outerVisible: false, // 编辑课节弹框
        innerVisible: false, // 选择教室弹框
        innerVisible1: false, // 新增教室弹框
        addPlaceFormData: {}, // 新增教室表单
        innerPlaceShow: false, // 冲突场地相亲
        PlaceCheckList: [], // 选中的数据
        courseList: [], // 课程列表
        teacherList: '', // 教练列表
        dataInfo: {}, // 高级设置详情
        PlaceList: [], // 场地数据
    })

	const emit = defineEmits(['handleClose','onEditWork']);
	function show(){
        pageData.outerVisible = !pageData.outerVisible;
        if (pageData.outerVisible) {
            getCourseNameList()
        }
	}
	//暴露方法
	defineExpose({
	  show,
      getEditInfo
	})
    const form = ref({
        name: '',
        courseNameId: '',
        teacherIdList: [],
        allPeopleNum: '',
        classTimeStartStr: '',
        startTime: '08:40',
        endTime: '09:40',
        roomName: '',
        classroomId: '',
    })
    /**
     * 新增场地
     */
    function addNewPlace(val){
        if (!val) {
            pageData.addPlaceFormData = {}
            pageData.innerVisible1 = true
        } else {
            if (!pageData.addPlaceFormData.roomName) {
                ElMessage.warning('请输入场地名称')
                return
            }
            let params = {
                roomName: pageData.addPlaceFormData.roomName,
                containNum: pageData.addPlaceFormData.containNum,
                remark: pageData.addPlaceFormData.remark,
                purposeClass: 1,
                purposeOrder: 0
            }
            sportRoomInsert(params).then(res => {
                if (res.code == 0) {
                    ElMessage.success('新增成功')
                    pageData.innerVisible1 = false
                }
            })
        }
    }
    /**
     * 表单验证规则
     */
    const rules = {
        teacherIdList: [{ required: true, message: '请选择授课老师', trigger: 'change' }],
        allPeopleNum: [{ required: true, message: "请输入容纳人数", trigger: "blur" }],
        startDates: [{ required: true, message: '请选择开课日期', trigger: 'change' }],
    };
    // 查询待排课程列表及教练
    function getCourseNameList(){
        courseList({ courseType: propData.type, page: 1, limit: 9999 }).then((res)=>{
            if(res.code == 0){
                pageData.courseList = res.data;
            }
        })
    }
    /**
     * 获取课节详情
     */
    function getEditInfo (id) {
        courseToEdit({encryptionId: id}).then((res) => {
            if (res.code == 0) {
                form.value = res.data
                form.value.teacherIdList = Number(res.data.courseTeacher);
                form.value.startTime = res.data.startTime
                form.value.endTime = res.data.endTime
                pageData.dataInfo.ifOpenBook = res.data.ifOpenBook, // 是否开放预约1是2否
                pageData.dataInfo.bookingStartRule= res.data.bookingStartRule,// 预约开始时间前多少小时
                pageData.dataInfo.bookingEndRule= res.data.bookingEndRule,// 预约截止时间前多少小时
                pageData.dataInfo.ifFull= res.data.ifFull,// 是否满员开课0否1是
                pageData.dataInfo.fullNum = res.data.fullNum,// 满足多少人开课
                pageData.dataInfo.fullEndRule= res.data.fullEndRule,// 截止课前多少分钟关闭课节
                pageData.dataInfo.ifCancelBook= res.data.ifCancelBook,//是否可以取消预约1是2否
                pageData.dataInfo.cancelEndRule= res.data.cancelEndRule,// 取消预约截止规则上课前多少小时
                pageData.dataInfo.fullCancelNum= res.data.fullCancelNum,// 满员取消预约人数限制
                pageData.dataInfo.ifAfter = res.data.ifAfter,// 0禁止候补，1：支持候补
                pageData.dataInfo.afterNum = res.data.afterNum,// 候补人数
                pageData.dataInfo.ifConfirmClass= res.data.ifConfirmClass,//会员扫码签到1是2否
                pageData.dataInfo.ifBook= res.data.ifBook // 自动签到1是2否
                pageData.dataInfo.autoConsumeRule= res.data.autoConsumeRule
                pageData.teacherList = res.data.teacherList
            }
        })
    }
    /**
     * 取消编辑课节弹框
     */
    const handleClose=() => {
       formRef.value.resetFields()
       pageData.outerVisible = false
       emit('handleClose');
    }
    /**
     * 唤起选择教室弹框
     */
    const selectRoom=() => {
        pageData.innerVisible = true
        getRoomList()
    }
    /**
     * 获取教室列表
     */
    const getRoomList=()=>{
        let params = {
            classTime:form.value.startTime,
            classTimeEnd: form.value.endTime
        }
        getRoomConflictForEditList(params).then((res)=>{
            if(res.code == 0){
                pageData.PlaceList = res.data;
                pageData.placeShow = true
            }
        });
    }
    /**
     * 查看冲突
     */
    function toConflict(row) {
        pageData.innerPlaceList = row.courseList
        pageData.innerPlaceShow = true
    }
    /**
     * 选中场地数据
     */
    function selectPlaceChange(val){
        pageData.PlaceCheckList = val
    }
    /**
     * 取消选择场地
     */
    function placeClose() {
        pageData.PlaceCheckList = []
        pageData.innerVisible = false
    }
    /**
     * 提交选择教室
     */
    const submitSelectRoom=() => {
        if (!pageData.PlaceCheckList.length) {
            ElMessage.warning('请选择场地')
            return
        }
        if (pageData.PlaceCheckList[0].conflictStatus) {
            pageData.confirmTitle = '是否确定选择改场地'
            pageData.confirmText = '冲突<span class="color-red"> '+pageData.PlaceCheckList[0].courseList.length+' </span>节';
            confirmRef.value.show();
        }else{
            pageData.innerVisible = false
        }
        
    }
    // 切换选择课程名称
    function changeCourseName(val){
        var courseNameList = pageData.courseNameList;
        for(var i=0;i<courseNameList.length;i++){
            if(courseNameList[i]['courseNameId'] == val){
                // 开始赋值
                var courseTime = courseNameList[i]['courseTime'];
                pageData.formData.courseTime = courseTime;
                // 计算
                var timeStart = pageData['temptimestart'];
                if(timeStart){
                    var hour = parseInt(timeStart.split(':')[0]);
                    var min  = parseInt(timeStart.split(':')[1]);
                    min = min + parseInt(courseTime);
                    hour = hour + parseInt(min / 60) + '';
                    min = min % 60 + '';
                    hour = hour.length == 1 ? '0'+ hour : hour;
                    min = min.length == 1 ? '0'+ min : min;
                    if (hour > 23) {
                        form.value.endTime = ''
                    } else if(min > 55) {
                        form.value.endTime = ''
                    } else {
                        form.value.endTime = hour + ':' + min;
                    }
                }
            }
        }
    }
    // 修改开始时间
    function changeWeekTimeStart(timeStart){
        if(timeStart){
            var hour = parseInt(timeStart.split(':')[0]);
            var min  = parseInt(timeStart.split(':')[1]);
            min = min + parseInt(pageData.formData.courseTime || '45');
            hour = hour + parseInt(min / 60) + '';
            min = min % 60 + '';
            hour = hour.length == 1 ? '0'+hour : hour;
            min = min.length == 1? '0'+min : min;
            if (hour > 23) {
                form.value.endTime = ''
            } else if(min > 55) {
                form.value.endTime = ''
            } else {
                form.value.endTime = hour + ':' + min;
            }
        }
    }
    /**
     * 编辑课节弹框确认提交
     */
    const onEditWork=() => {
        let formData = {}
        let seniorSet = seniorSetDot.value.getSeniorSet();
        Object.assign(formData,seniorSet)
        if(formData['ifOpenBook'] == 1){
            if(!formData['bookingStartRule'] && formData['bookingStartRule'] != 0){
                ElMessage({
                        type: 'warning',
                        message: '预约开始时间不能为空',
                    })
                return false
            }
            if(!formData['bookingEndRule'] && formData['bookingEndRule'] != 0){
                ElMessage({
                        type: 'warning',
                        message: '预约截止时间不能为空',
                    })
                return false
            }
            if(parseInt(formData['bookingEndRule']) > parseInt(formData['bookingStartRule'])){
                ElMessage({
                        type: 'warning',
                        message: '预约截止时间不能早于开始时间',
                    })
                return false
            }
            // 当不开放预约时，下面全不展示，所以放在开放预约里面
            if(formData['ifFull'] == 1){
                if(!formData['fullNum'] && formData['fullNum'] != 0){
                    ElMessage({
                        type: 'warning',
                        message: '满员人数不能为空',
                    })
                    return false
                }
                if(!formData['fullEndRule'] && formData['fullEndRule'] !=0){
                    ElMessage({
                        type: 'warning',
                        message: '满员截止时间不能为空',
                    })
                    return false
                }
            }
            if(formData['ifCancelBook'] == 1){
                if(!formData['cancelEndRule'] && formData['cancelEndRule'] != 0){
                    ElMessage({
                        type: 'warning',
                        message: '取消截止时间不能为空',
                    })
                    return false
                }
                if(formData['ifFull'] == 1 && !formData['fullCancelNum']){
                    ElMessage({
                        type: 'warning',
                        message: '满员取消人数不能为空',
                    })
                    return false
                }
            }
            if(formData['ifAfter'] == 1){
                if(!formData['afterNum'] && formData['afterNum'] != 0){
                    ElMessage({
                        type: 'warning',
                        message: '候补人数不能为空',
                    })
                    return false
                }
            }
        }
        if(formData['ifBook'] == 1){
            if(!formData['autoConsumeRule'] && formData['autoConsumeRule'] != 0){
                ElMessage({
                    type: 'warning',
                    message: '自动签到时间不能为空',
                })
                return false
            }
        } 
        if (!form.value.startTime) {
            ElMessage({
                type: 'warning',
                message: '请选择上课开始时间',
            })
            return false
        }
        if (!form.value.endTime) {
            ElMessage({
                type: 'warning',
                message: '请选择上课结束时间',
            })
            return false
        }
        formRef.value.validate((valid) => {
            if (valid) {
                let data = form.value.classTimeStartStr.split('-')
                let obj = {
                    id: form.value.id,
                    courseNameId: form.value.courseNameId,
                    courseTeacher: form.value.teacherIdList || '',
                    allPeopleNum: form.value.allPeopleNum,
                    courseCustomName: form.value.courseCustomName ? form.value.courseCustomName : '',
                    classTimeStartStr: form.value.classTimeStartStr,
                    startTimeHour: form.value.startTime.split(':')[0],
                    startTimeMinute: form.value.startTime.split(':')[1],
                    endTimeHour: form.value.endTime.split(':')[0],
                    endTimeMinute: form.value.endTime.split(':')[1]
                }
                let params = Object.assign(obj, pageData.dataInfo)
                params.classTime = data[0]+data[1]+data[2]+params.startTimeHour+params.startTimeMinute+'00'
                params.classTimeEnd =  data[0]+data[1]+data[2]+ params.endTimeHour+params.endTimeMinute+'00'
                if (pageData.PlaceCheckList.length) {
                    params.classroomId = pageData.PlaceCheckList[0].id
                } else {
                    params.classroomId = form.value.classroomId
                }
                courseEdit(params).then((res) => {
                    if (res.code == 0) {
                        ElMessage.success('编辑成功')
                        show()
                        emit('onEditWork');
                    }
                })
            } else {
              console.log('error submit!!');
              return false;
            }
        })
    }
</script>
<style type='css/style' scoped>
    .editForm /deep/ .el-date-editor--timerange.el-input__inner {
        width: 330px;
    }
    .editForm /deep/ .el-date-editor.el-input{
        width: 330px;
    }
    .single-select-table /deep/ thead .el-table-column--selection .cell{
        display: none;
    }
    .single-select-table /deep/ .el-checkbox__inner { width: 16px; height: 16px; border-radius: 50%; }
    .single-select-table /deep/ .el-checkbox__inner::after{
        height: 8px;
        left: 5px;
    }
    .sp-green{
        height:5px;width:5px;background-color:#00ee00;border-radius:10px;
    }
    .sp-red{
        height:5px;width:5px;background-color:#ee0000;border-radius:10px;
    }
</style>