<template>
	<div class="members_info" style="max-width: 400px;" v-show="storeData.moduleStatus">
		<div class="rx-sc mb10">
			<h3 class="ft16 mr20">会员资产</h3>
			<span class="color-999 ft12">拖动模块进行排序</span>
		</div>
		<div class="bg-fff pl10 pb10 pt10 pr10">
			<MoveSort :list="storeData.list" @moveover="over">
				<template #[index] v-for="(item,index) in storeData.list" :key="index">
					<div class="pt5 pb5">
						<div class="moveItem bg-fff	" :class="{active:storeData.active == index}" @click="clickLi(index)">
							<div class="rx-bc pt5 pb5 pl20 pr20" style="border-bottom: 1px solid #ccc;">
								<div class="rx-sc">
									<unicon width="15px" name="align-center-v" fill="#C2C2C4"></unicon>
									<span class="ml10">{{item.name}}</span>
								</div>
							</div>
							<div class="rx-sc pl40 pt20 pb20 pr20">
								<span>显示名称：</span>
								<div>
									<el-input type="text" placeholder="请输入（ 限5个字 ）" maxlength="5" v-model="item.newName"></el-input>
								</div>
							</div>
						</div>
					</div>
				</template>
			</MoveSort>
		</div>
	</div>
</template>

<script setup>
	const propData = defineProps({
	  tab:String,
	  itemIndex:Number
	})
	import {ref,reactive} from 'vue';
	import { useStore } from 'vuex'
	import MoveSort from '../MoveSort.vue'
	const store = useStore();
	const storeData = store.state.appletFormData[propData.tab].list[propData.itemIndex];
	function clickLi(index){
		storeData.active = index;
	}
</script>

<style scoped lang="stylus">
	.members_info{
		.moveItem{
			border: 1px solid #ccc;
		}
		.moveItem.active{
			border: 1px dashed #409EFF;
			background-color #EFF6FF;
		}
	}
</style>
