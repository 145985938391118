<template>
    <workArea :barlist="barlist">
    <div class="bg-fff pl16 pt10 pr16">
        <el-tabs v-model="pageData.tabNum" @tab-click="handleClick">
            <el-tab-pane label="正式会员" name="0" v-if="utils.getMenuRole(31300)"></el-tab-pane>
            <el-tab-pane label="会员卡项" name="1" v-if="utils.getMenuRole(31301)"></el-tab-pane>
            <el-tab-pane label="会员课程" name="2" v-if="utils.getMenuRole(31302)"></el-tab-pane>
        </el-tabs>
        <component :is="components[pageData.tabNum]" :tab="pageData.tabNum"></component>
    </div>
    </workArea>
</template>
<script>
  export default {
    name: 'BranchMemberList'
  }
</script>
<script setup>
  import {ref,reactive,onMounted,onBeforeUnmount} from 'vue';
  import member from '@/views/branch-store/member/memberList/member.vue' //会员列表
  import memberCards from '@/views/branch-store/member/memberList/memberCards.vue' //会员卡列表
  import memberCourse from '@/views/branch-store/member/memberList/memberCourse.vue' //会员课程
  import {useRouter} from 'vue-router';
  import workArea from '@/components/branch-store/workArea.vue'; 
  import utils from '@/common/utils.js'; 
  const router = useRouter();
  const barlist = [{ name : '首页' },{ name : '会员管理' }];
  const components = [member, memberCards, memberCourse];
  const pageData = reactive({
      tabNum : '0'
  });
  var menuId = [31300,31301,31302]; //会员有权限tab默认项
  for(var i = 0;i < menuId.length;i++){
  	if(utils.getMenuRole(menuId[i])){
  		pageData.tabNum = i.toString();
  		break;
  	}
  }  
  function handleClick(tab) {
      localStorage.setItem('memberList', tab.props.name)
	}
	onMounted(()=> {
      if (localStorage.getItem('memberList') != null) {
          pageData.tabNum = localStorage.getItem('memberList')
	    }
	})
  onBeforeUnmount(() => {
		localStorage.removeItem('memberList')
	})
    
</script>

<style>
</style>
