<template>
    <div class="pt20">
        <div class="top rx-c mb20">
            <el-button class="mr16" type="primary" @click="addRules()" v-if="utils.getMenuRole(3350401)">新增规则</el-button>
            <el-form :inline="true" :model="pageData.formInline" class="demo-form-inline">
                <el-form-item>
                    <el-input v-model="pageData.formInline.ruleName" placeholder="输入计费规则名称" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="search" :icon="Search">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table :data="pageData.tableData" stripe style="width: 100%" height="650">
            <el-table-column prop="ruleName" label="计费规则名称" />
            <el-table-column label="计费方式">
                <template #default="scope">
                    {{ scope.row.costType == 1 ? '按时间计费' : '按人数计费' }}
                </template>
            </el-table-column>
            <el-table-column label="规则详情">
                <template #default="scope">
                    <div v-for="item in scope.row.rulePriceList" :key="item">
                        <div v-if="scope.row.costType == 1">{{ item.sectionStart }}-{{ item.sectionEnd }}点，每小时{{ item.orderPrice }}元，会员价每小时{{ item.vipPrice }}元</div>
                        <div v-else>{{ item.sectionStart }} <span v-if="item.sectionEnd != 9999">-{{ item.sectionEnd }}</span>人<span v-if="item.sectionEnd == 9999 ">以上</span>，每人{{ item.orderPrice }}元，会员价每人{{ item.vipPrice }}元</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="address" label="操作">
                <template #default="scope">
                    <a class="color-409EFF tool_btn t_d_u" href="javaScript:;" v-if="utils.getMenuRole(3350402)" @click="modify(scope.row)">编辑</a>
                    <a class="color-red t_d_u " href="javascript:;" v-if="utils.getMenuRole(3350403)" @click="remove(scope.row)">删除</a>
                </template>
            </el-table-column>
        </el-table>
        <div class="mt20 rx-ec">
		    <el-pagination
		        v-model:currentPage="pageData.page"
		        :page-sizes="[15,20,30,50,100]"
		        :page-size="pageData.limit"
		        layout="total, sizes, prev, pager, next, jumper"
		        :total="pageData.count"
		        @size-change="classPageSizeChange"
                @current-change="pageChange"
		    >
		    </el-pagination>
		</div>
        <el-dialog
            v-model="pageData.dialogShow"
            :title="pageData.title"
            width="970px"
            :before-close="dialogShowClose"
          >
            <div class="pt25 pl50 pr50 dialog_form">
                <el-form ref="ruleFormRef" :rules="rules" :model="pageData.dialogShowForm" label-width="100px">
                    <el-form-item label="规则名称：" prop="ruleName">
                        <el-input class="w300" maxlength="10" v-model="pageData.dialogShowForm.ruleName" placeholder="输入计费规则名称（10字内）" />
                    </el-form-item>
                    <el-form-item label="计费方式：" prop="costType">
                        <el-radio-group v-model="pageData.dialogShowForm.costType" @change="changeCostType">
                            <el-radio :label="1">
                                <span class="color-333">按时间计费</span>
                            </el-radio>
                            <el-radio :label="2">
                                <span class="color-333">按人数计费</span>
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="计费规则：" class="color">
                        <div class="ft12 color-999">温馨提示：设置会员价后，预定人是会员时，按照会员单价计费，不填则按原单价计费。</div>
                    </el-form-item>
                    <div class="dialogBox mb20 rx-c" v-for="(item, index) in pageData.dialogShowForm.rulesList" :key="index">
                        <div class="rx-bc dialogDiv">
                            <div v-if="pageData.dialogShowForm.costType == 1" class="rx-c">
                                <label class="fwb v_a_m">时间段：</label>
                                <div class="w280 rx-c v_a_m">
                                    <el-select v-model="item.startTime" disabled placeholder="开始时间" >
                                        <el-option
                                            v-for="val in pageData.timer"
                                            :key="val"
                                            :label="val == 24 ? '次日0点' :  val +'点'"
                                            :value="val"
                                        />
                                    </el-select>
                                    <span class="pl20 pr20 v_a_m">至</span>
                                    <el-select v-model="item.endTime" :disabled="index == pageData.dialogShowForm.rulesList.length-1 ? true : false" placeholder="结束时间" @change="((val)=>{changeTimeIn(val, index)})">
                                        <el-option
                                            v-for="val in pageData.timer"
                                            :disabled="val <= item.startTime ? true : false"
                                            :key="val"
                                            :label="val == 24 ? '次日0点' : val +'点'"
                                            :value="val"
                                        />
                                    </el-select>
                                </div>
                            </div>
                            <div v-else class="numBox">
                                <el-input class="w100" v-model="item.minNum" maxlength="4" disabled onkeyup="value=value.replace(/[^\d]/g,'')">
                                    <template #append>人</template>
                                </el-input>
                                <span class="pl20 pr20 v_a_m">＜ 人数 <span v-if="index != pageData.dialogShowForm.rulesList.length-1"> ≤</span></span>
                                <el-input v-if="index != pageData.dialogShowForm.rulesList.length-1" class="w100 v_a_m" v-model="item.maxNum" maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')" @change="((item)=>{changeTimeIn(item, index)})">
                                    <template #append>人</template>
                                </el-input>
                            </div>
                            <div>
                                <label class="fwb v_a_m">单价：</label>
                                <el-input class="w120 v_a_m" v-model="item.price" onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')">
                                    <template #append>元/{{pageData.dialogShowForm.costType == 1 ? '小时' : '人'}}</template>
                                </el-input>
                            </div>
                            <div>
                                <label class="fwb v_a_m">会员价：</label>
                                <el-input class="w120 v_a_m" v-model="item.vipPrice" onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')">
                                    <template #append>元/{{pageData.dialogShowForm.costType == 1 ? '小时' : '人'}}</template>
                                </el-input>
                            </div>
                        </div>
                        <div class="dialogBox_img" v-if="index != pageData.dialogShowForm.rulesList.length-1">
                            <img class="w15 h15 ml20 c_p" src="@/assets/newAdd.png" v-if="pageData.dialogShowForm.rulesList.length < 3 && index == pageData.dialogShowForm.rulesList.length-2" @click="addRulesList">
                            <img class="w15 h15 ml20 c_p" src="@/assets/delete.png" v-if="index != 0" @click="deleteRulesList(index)">
                        </div>
                    </div>
                </el-form>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogShowClose">取消</el-button>
                    <el-button type="primary" @click="dialogShowConfirm">确定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import { ElMessage } from 'element-plus'
    import { Search } from '@element-plus/icons-vue';
    import { settingRuleList, settingRuleInsert, settingRuleUpdate, settingRuleUpdateDel } from '@/api/appstore.js';
    import utils from '@/common/utils.js';
    const ruleFormRef = ref()
    const pageData = reactive({
        formInline:{},
        page:1,
        limit:15,
        count: 0,
        tableData: [],
        dialogShow: false,
        title: '',
        dialogShowForm: {
            ruleName: '',
            costType: 1,
            rulesList:[
                { startTime: 0, price: '', vipPrice: '' },
                { endTime: 24, price: '', vipPrice: '' }
            ]
        },
        timer:[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24],
        encryptionId: ''
    })
    const rules = reactive({
        ruleName: [{ required: true, message: '请输入计费规则', trigger: 'blur' }],
        costType: [{ required: true, message: '请选择计费方式', trigger: 'change'}],
    })
    /**
     * 搜索
     */
    function search() {
        pageData.page = 1
        getList()
    }
    /**
     * 获取列表
     */
    function getList() {
        let params = {
            ruleName: pageData.formInline.ruleName,
            page: pageData.page,
            limit: pageData.limit
        }
        settingRuleList(params).then(res => {
            if (res.code == 0) {
                pageData.tableData = res.data
                pageData.count = res.count
            }
        })
    }
    /**
     * 每页条数更改
     */
    function classPageSizeChange(limit) {
        pageData.limit = limit
        getList()
    }
    /**
     * 页数更改
     */
    function pageChange(val) {
        pageData.page = val
        getList()
    }
    /**
     * 新增规则弹框
     */
    function addRules() {
        pageData.title = '新增规则'
        pageData.dialogShowForm = {
            ruleName: '',
            costType: 1,
            rulesList:[
                { startTime: 0, price: '', vipPrice: '' },
                { endTime: 24, price: '', vipPrice: '' }
            ]
        }
        pageData.dialogShow = true
    }
    /**
     * 编辑规则弹框
     */
    function modify(row) {
        pageData.title = '编辑规则'
        pageData.encryptionId = row.encryptionId
        pageData.dialogShowForm= {
            ruleName: row.ruleName,
            costType: row.costType,
            rulesList: []
        }
        let obj = {}
        row.rulePriceList.forEach(item => {
            obj = {
                price: item.orderPrice,
                vipPrice: item.vipPrice,
            }
            if (row.costType == 1) {
                obj.startTime = item.sectionStart
                obj.endTime = item.sectionEnd
            } else {
                obj.minNum = item.sectionStart
                obj.maxNum = item.sectionEnd
            }
            pageData.dialogShowForm.rulesList.push(obj)
        })
        
        pageData.dialogShow = true
    }
    /**
     * 删除列表计费规则模板
     */
    function remove(row) {
        settingRuleUpdateDel({encryptionId: row.encryptionId}).then(res => {
            if (res.code == 0) {
                ElMessage.success('删除成功')
                getList()
            }
        })
    }
    /**
     * 关闭弹框
     */
    function dialogShowClose() {
        ruleFormRef.value.resetFields()
        pageData.dialogShow = false
    }
    /**
     * 提交表单
     */
    function dialogShowConfirm() {
        ruleFormRef.value.validate((valid) => {
            if (valid) {
                let arr = pageData.dialogShowForm.rulesList
                if (!arr[arr.length-1].maxNum) {
                   arr[arr.length-1].maxNum = 9999
                }
                for (let i=0; i< arr.length; i++) {
                    if (pageData.dialogShowForm.costType == 1) {
                        if (!arr[i].endTime) {
                            ElMessage.warning('存在结束时间未选择')
                            return
                        }
                    } else {
                        if (!arr[i].minNum && arr[i].minNum != 0 || !arr[i].maxNum) {
                            ElMessage.warning('存在人数未输入')
                            return
                        }
                    }
                    if (!arr[i].price && arr[i].price == 0) {
                        ElMessage.warning('存在单价未输入')
                        return
                    }
                    
                }
                let params = {
                    ruleName: pageData.dialogShowForm.ruleName,
                    costType: pageData.dialogShowForm.costType,
                }
                let arr1 = []
                arr.forEach(item => {
                    if (pageData.dialogShowForm.costType == 1) {
                        let name = item.startTime +','+item.endTime+','+item.price+','+item.vipPrice
                        arr1.push(name)
                    }else{
                        let name = item.minNum +','+ (item.maxNum ? item.maxNum : 9999) +','+item.price+','+item.vipPrice
                        arr1.push(name)
                    }
                })
                params.ruleDetail = arr1.join(';')
                let apiName = ''
                if (pageData.title == '编辑规则') {
                    params.encryptionId = pageData.encryptionId
                    apiName = settingRuleUpdate
                } else {
                    apiName = settingRuleInsert
                }
                apiName(params).then(res => {
                    if (res.code == 0) {
                        ElMessage.success('保存成功')
                        getList()
                        pageData.dialogShow = false
                    }
                })
            } else {
              console.log('error submit!!');
              return false;
            }
        })
    }
    /**
     * 切换计费方式
     */
    function changeCostType(val) {
        if (val == 1) {
            pageData.dialogShowForm.rulesList = [
                { startTime: 0, price: '', vipPrice: '' },
                { endTime: 24, price: '', vipPrice: '' }
            ]
        } else {
            pageData.dialogShowForm.rulesList = [
                { minNum: 0, price: '', vipPrice: '' },
                { minNum: '', price: '', vipPrice: '' }
            ]
        }
    }
    /**
     * 添加规则
     */
    function addRulesList() {
        let arr = pageData.dialogShowForm.rulesList
        if (pageData.dialogShowForm.costType == 1) {
            arr.splice(arr.length-1, 0, {startTime:arr[arr.length-2].endTime})
        } else {
            arr.splice(arr.length-1, 0, {minNum:arr[arr.length-2].maxNum})
        }
    }
    /**
     * 输入时间规则
     */
    function changeTimeIn(item, index){
        let arr = pageData.dialogShowForm.rulesList
        if (pageData.dialogShowForm.costType == 1) {
            arr[index+1].startTime = item
        } else {
            if (Number(arr[index].minNum) >= Number(arr[index].maxNum)) {
                arr[index].maxNum = ''
                arr[index+1].minNum = ''
                return
            }
            arr[index+1].minNum = item
        }
    }
    /**
     * 删除规则
     */
    function deleteRulesList(index) {
        let arr = pageData.dialogShowForm.rulesList
        if (pageData.dialogShowForm.costType == 1) {
            arr[index+1].startTime = arr[index-1].endTime
        } else {
            arr[index+1].minNum = arr[index-1].maxNum
        }
        pageData.dialogShowForm.rulesList.splice(index,1);
    }
    onMounted(() => {
        getList()
    })
</script>
<style type='css/style' scoped>
    ::v-deep .el-form--inline .el-form-item {
        margin-right: 16px;
        margin-bottom: 0;
    }
    .dialogDiv{
        background: #F7F7F7;
        padding: 20px 15px;
        width: 90%;
    }
    .numBox {
        width: 338px;
    }
    .color ::v-deep .el-form-item__label{
        color: #225080;
    }
    ::v-deep .el-input.is-disabled .el-input__inner{
        color: #606266
    }
</style>