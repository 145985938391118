/* 前端数据字典 */
import {postAjax,getAjax}  from "./axios.js"
import {getFrameworkAllList,teacherList} from '@/api/staff.js';
import {getStoreAccountStaffSimple,followStatusManage,stuChannels,allotTagToBatch} from '@/api/intentionList.js';
import {courseList} from '@/api/course.js';

var funcs = {};

const dict = {
    "courseNameType" : [
        // { value : 1 , label : '班课' },
        { value : 2 , label : '团体课' },
        { value : 3 , label : '私教课1v1' },
        { value : 4 , label : '私教课1v多' }
    ] ,
    "timeType" : [
        { value : "1" , label : '今天' },
        { value : "2" , label : '昨天' },
        { value : "3" , label : '最近7天' },
        { value : "4" , label : '最近30天' },
        { value : "0" , label : '其他' }
    ],
    "waterKind" : [
        { value : "0" , label : '支出' },
        { value : "1" , label : '收入' }
    ],
    "waterStatus":[
        { value : "0" , label : '待审核' },
        { value : "1" , label : '已录入' },
        { value : "2" , label : '审核不通过' }
    ],
    "waterPayType":[
        { value : "1" , label : '现金' },
        { value : "2" , label : '支付宝' },
        { value : "3" , label : '微信' },
        { value : "4" , label : '刷银行卡' },
        { value : "6" , label : '微商' },
        { value : "7" , label : '大众点评' },
        { value : "8" , label : '银行转账' },
        { value : "100" , label : '收款码' },
        { value : "101" , label : '储值卡' },
        { value : "0" , label : '其他' }
    ],
    "waterType" : [] ,// 账单类型
	"productType" : [ //卡类型
        { value : "1" , label : '次卡' },
        { value : "3" , label : '期限卡' }
    ],
	"shelfState" : [ //上架状态
        { value : "1" , label : '已上架' },
        { value : "0" , label : '已下架' }
    ],
	"sortType" : [ //排序方式
        { value : "1" , label : '创建时间由早到晚' },
        { value : "2" , label : '名称由a-z' }
    ],
	"storeList":[],//所以机构列表
	"courseStatus" : [ //上架状态
	    { value : "1" , label : '已开启' },
	    { value : "0" , label : '已关闭' }
	],
	"courseType" : [ //课程类型
	    { value : '11' , label : '团体课' },
        { value : '12' , label : '私教课' },
        { value : '13' , label : '小班课'},
	    { value : '14' , label : '私教课1v1' },
	    { value : '15' , label : '私教课1v多' },
        { value : '21' , label : '预约场地' }
	],
	"followState" : [ //跟进状态
	    // { value : '-1' , label : '暂无状态' },
	],
	"starState" : [ //星级
        { value : '5' , label : '一星' },
        { value : '4' , label : '二星' },
        { value : '3' , label : '三星' },
        { value : '2' , label : '四星' },
        { value : '1' , label : '五星' }
    ],
    "dateType" : [ // 日期类型
        { value : '1' , label : '今天' },
        { value : '2' , label : '明天' },
        { value : '3' , label : '昨天' },
        { value : '4' , label : '本周' },
        { value : '5' , label : '本月' },
        { value : '6' , label : '其他' }
    ],
    "timeIn" : [ // 日期类型
        { value : '1' , label : '今天' },
        { value : '2' , label : '昨天' },
        { value : '5' , label : '最近7天' },
        { value : '20' , label : '本月' },
        { value : '-1' , label : '其他' }
    ],
	"followerList" : [], //所以门店员工/跟进人
	"channelList" : [], //会员来源
	"upTagList" : [], //会员标签
    "teamCourseNameAll" : [], // 全部团体课程 (全部课程)
    "teamCourseNameAll2" : [] // 全部私教课程 (全部课程)
};
// 静态
funcs.getDict = function(dictName,option){
    var dictArray = Object.assign([],dict[dictName]);
    if(option && dictArray){
        dictArray.unshift(option);
    }
    return dictArray;
}
function _getDictByUrl(dictName,cb){
    if(dictName == "waterType"){
        getAjax('/cashier/tallyType/list?limit=999',{}).then((res)=>{
            if(res.code == 0){
                var typeArray = [];
                for(var i=0;i<res.data.length;i++){
                    typeArray.push({
                        value : res.data[i]['id'],
                        label : res.data[i]['title'] 
                    });
                }
                cb(typeArray);
            }
        });
    }
	if(dictName == 'storeList'){ //机构列表
		getFrameworkAllList({
			ifBranchStore:1
		}).then((res)=>{
			if(res.code == 0){
				var typeArray = [];
				for(var i=0;i<res.data.length;i++){
				    typeArray.push({
				        value : res.data[i]['sid'],
				        label : res.data[i]['storeName'] 
				    });
				}
				cb(typeArray);
			}
		})
	}
	if(dictName == 'followState'){ //跟进状态
		followStatusManage({
			limit:999,
			status:1
		}).then((res)=>{
			if(res.code == 0){
				var typeArray = [];
				for(var i=0;i<res.data.length;i++){
				    typeArray.push({
				        value : res.data[i]['id'],
				        label : res.data[i]['upStatusName'] 
				    });
				}
				cb(typeArray);
			}
		})
	}
	if(dictName == 'followerList'){ //跟进人
		getStoreAccountStaffSimple().then((res)=>{
			if(res.code == 0){
				var typeArray = [];
				for(var i=0;i<res.data.length;i++){
				    typeArray.push({
				        value : res.data[i]['id'],
				        label : res.data[i]['realName'] 
				    });
				}
				cb(typeArray);
			}
		})
	}
	if(dictName == 'channelList'){ //会员来源
		stuChannels().then((res)=>{
			if(res.code == 0){
				var typeArray = [];
				for(var i=0;i<res.data.length;i++){
				    typeArray.push({
				        value : res.data[i]['id'],
				        label : res.data[i]['channelName'] 
				    });
				}
				cb(typeArray);
			}
		})
	}
	if(dictName == 'upTagList'){ //会员标签
        allotTagToBatch().then((res)=>{
            if(res.code == 0){
                var typeArray = [];
                for(var i=0;i<res.data.length;i++){
                    typeArray.push({
                        value : res.data[i]['id'],
                        label : res.data[i]['tagName'],
						eId:res.data[i]['encryptionId'],
                    });
                }
                cb(typeArray);
            }
        })
    }
    if(dictName == 'teacherList'){ //全部教练
        teacherList().then((res)=>{
            if(res.code == 0){
                var typeArray = [];
                for(var i=0;i<res.data.length;i++){
                    typeArray.push({
                        value : res.data[i]['id'],
                        label : res.data[i]['realName'] 
                    });
                }
                cb(typeArray);
            }
        })
    }
    if(dictName == 'teamCourseNameAll'){
        courseList({courseType : 11,limit:999}).then((res)=>{
            if(res.code == 0){
                var typeArray = [];
                for(var i=0;i<res.data.length;i++){
                    typeArray.push({
                        value : res.data[i]['courseNameId'],
                        label : res.data[i]['courseName'] 
                    });
                }
                cb(typeArray);
            }
        })
    }
	if(dictName == 'teamCourseNameAll2'){
        courseList({courseType : 12,limit:999}).then((res)=>{
            if(res.code == 0){
                var typeArray = [];
                for(var i=0;i<res.data.length;i++){
                    typeArray.push({
                        value : res.data[i]['courseNameId'],
                        label : res.data[i]['courseName'] 
                    });
                }
                cb(typeArray);
            }
        })
    }
	
}
// 动态获取
funcs.getDynaDict = function(dictName,option,cb){
    _getDictByUrl(dictName,function(res){
        if(option){
            res.unshift(option);
        }
        cb(res);
    });
}

export default funcs;







