<template>
	<div class="course_info" v-show="storeData.moduleStatus">
		<h3 class=" mb10 rx-sc" v-show="!pageData.updateTitleShow"> 
			<div class="rx-ss">
				<span class="ft16">{{storeData.name}}</span>
				<unicon class="ml10 mr10" width="18px" name="edit-alt" fill="#47A1FE" @click="pageData.updateTitleShow = true"></unicon>
			</div>
			<span class="tips color-999 ft12">下面最多可添加20个课程</span>
		</h3>
		<div class="mb10 rx-sc" v-show="pageData.updateTitleShow">
			<div class="w150 mr20">
				<el-input type="text" v-model="pageData.titleName" maxlength="6" placeholder="限6个字符"></el-input>
			</div>
			<span class="color-409EFF mr20 c_p tool_btn" @click="updateTitle">确定</span>
			<span class="c_p" @click="pageData.updateTitleShow = false">取消</span>
		</div>
		<div class="store_form mt20">
			<div class="rx-sc mb12">
				<span>展示方式：</span>
				<div>
					<el-radio-group v-model="storeData.showType">
						<el-radio label="1" size="large">横向滚动</el-radio>
						<el-radio label="2" size="large">列表模式</el-radio>
					</el-radio-group>
				</div>
			</div>
			<div class="rx-sc mb12">
				<span>展示规则：</span>
				<div>
					<el-radio-group v-model="storeData.showRule" @change="changeShowRule">
						<el-radio label="1" size="large">全部私教课</el-radio>
						<el-radio label="2" size="large">全部团体课</el-radio>
						<el-radio label="3" size="large">自定义课程</el-radio>
					</el-radio-group>
				</div>
			</div>
			<div class="rx-ss mb12" v-show="storeData.showRule == '3'">
				<div class="w70">展示课程：</div>
				<div class="flex1">
					<div class="bg-fff pt20 pb20 pl10 pr10 mb20 w400" v-if="storeData.list.length">
						<MoveSort :list="storeData.list" :key="(new Date()).getTime()">
							<template #[index] v-for="(item,index) in storeData.list" :key="index">
								<div class="pt5 pb5">
									<div class="coachMoveItem pl20 pt20 pb10" :class="{active:storeData.active == index}" >
										<div class="move_icon_top">
											<unicon width="15px" name="align-center-v" fill="#C2C2C4"></unicon>
										</div>
										<div class="rx-ss">
											<img class="w120 h80 mr20 bdr4" :src="item.httpPath" alt="">
											<div>
												<h3 class="mb15 ft16">{{item.courseName}}</h3>
												<p>
													<span class="moveLabel" v-for="(n,i) in item.labels" :key="i">{{n}}</span>
												</p>
											</div>
										</div>
										<div class="move_icon_bottom">
											<unicon width="18" height="18" @click.capture="deleItme(index)" name="trash-alt" fill="#C2C2C4" hover-fill="#F87677"></unicon>
										</div>
									</div>
								</div>
							</template>
						</MoveSort>
					</div>
					<div>
						<el-button  :icon="Plus" type="primary" @click="updateCoach">添加课程</el-button>
					</div>
				</div>
				
			</div>
		</div>
		<AddCardsOrClass ref="addCardsOrClassRef" @close="handleClose" @addSave="save"></AddCardsOrClass>
	</div>
</template>

<script setup>
	const propData = defineProps({
	  tab:String,
	  itemIndex:Number,
	  sid:String
	})
	import {ref,reactive,onMounted} from 'vue'
	import { useStore } from 'vuex'
	import MoveSort from '../MoveSort.vue'; //通用移动排序
	import { Plus } from '@element-plus/icons-vue'
	import AddCardsOrClass from './addCardsOrClass.vue';
	import { da } from 'element-plus/lib/locale';
	import { appletList } from '@/api/applet.js'
	
	const addCardsOrClassRef = ref()
	const store = useStore();
	const storeData = store.state.appletFormData[propData.tab].list[propData.itemIndex]
	const storage = JSON.parse(JSON.stringify(storeData.list))
	const pageData = reactive({
		titleName:'',
		updateTitleShow:false,
	})
	//编辑标题
	function updateTitle(){
		if(pageData.titleName){
			storeData.name = pageData.titleName;
			pageData.updateTitleShow = false;
		}else{
			ElMessage({
				message:'请输入标题',
				type: 'warning'
			})
		}
	}
	
	//选择课程
	function updateCoach(){
		let params = {
			storeData: storeData,
	  		sid: propData.sid,
			checkedList: storeData.list,
			title: '课程'
		}
		
		addCardsOrClassRef.value.show()
		addCardsOrClassRef.value.getInfo(params)
	}
	function save(data) {
		data.forEach((item) => {
			item.labels = item.trainPurposeName.split(',');
		})
		storeData.list = data
	}
	//删除已选
	function deleItme(index){
		storeData.list.splice(index,1);
	}
	
	// 切换展示规则
	function changeShowRule(val) {
		if (val != 3) {
			getList()
		} else {
			storeData.list = storage
		}
	}
	function getList() {
		let params = {
			page:1,
			limit: 9999
		}
        params.storeId = propData.sid,
		params.courseType = storeData.showRule == 1 ? 12 : 11
        appletList(params).then((res)=>{
			if(res.code == 0){
				res.data.forEach((item) => {
					item.httpPath = item.courseCover;
					item.labels = item.trainPurposeName.split(',');
				})
				storeData.list = res.data
			}
		})
	}
	onMounted(() => {
		if (storeData.showRule !=3) {
			getList()
		}
	})
</script>

<style scoped lang="stylus">
	.course_info{
		.coachMoveItem{
			position relative;
      border-bottom 1px solid #eee;
			.move_icon_top{
				position absolute;
				left 0px;
				top 0px;
			}
			.move_icon_bottom{
				position absolute;
				bottom 15px;
				right 5px;
				display none;
			}
			.moveLabel{
				display inline-block;
				background-color #F0F0F2;
				color #999;
				font-size 12px;
				padding 2px 10px;
				margin-right 5px;
			}
		}
		.coachMoveItem:hover .move_icon_bottom{
			display block;
		}
		.coachMoveItem:hover {
			border: 1px dashed #409eff;
			background: #EFF6FF;
		}
		.courseType{
			display inline-block;
			background-color #D5F0FB;
			border 1px solid #6CD5FD;
			color #6CD5FD;
			border-radius 2px;
		}
		.box-FA7D29{
			border 1px solid #FA7D29;
			color #FA7D29;
			background-color #FEE5D4;
		}
		.box-9476FF{
			border 1px solid #9476FF;
			color #9476FF;
			background-color #EAE4FF;
		}
		.box-3FC9FF{
			border 1px solid #3FC9FF;
			color #3FC9FF;
			background-color #D9F4FF;
		}
		.tips{
			font-weight: 500
		}
	}
	.flex1 h3 {
		width: 300px;
        overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
</style>
