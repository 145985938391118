<template>
    <div>
        门店数据中心
    </div>
</template>

<script setup>
    import {ref,reactive,onMounted} from 'vue'
    import {useRouter} from 'vue-router';
    import {getLocalStorage} from '../../common/common.js';
    const router = useRouter();
        
    function toPage(){
        router.push('/branch/memberList?a=009');
    }


</script>

<style>
</style>
