<template>
	<div class="pt20 pb20">
		<div class="rx-bc">
			<div class="rx-sc">
				<div class="w210 mr12">
					<el-select v-model="tabForm.timeIn" placeholder="日期类型">
						<el-option v-for="item in searchQueryList.timeTypeList" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</div>
				<div class="w210 mr12" v-show=" tabForm.timeIn == -1">
					<el-date-picker style="width: 210px; height: 32px" format="YYYY-MM-DD" value-format="YYYY-MM-DD"
						v-model="tabForm.searchTime" type="daterange" start-placeholder="开始日期"
						end-placeholder="结束日期" :disabled-date="datePickerDisabled.disabledDate" clearable>
					</el-date-picker>
				</div>
				<div class="w210 mr12" v-show=" tabForm.timeIn == -2">
					<el-date-picker style="width: 210px; height: 32px" format="YYYY-MM" value-format="YYYY-MM"
						v-model="tabForm.searchTime" type="monthrange" start-placeholder="开始月份"
						end-placeholder="结束月份" :disabled-date="datePickerDisabled.setMonthDisabled">
					</el-date-picker>
				</div>
				<el-button type="primary" @click="tabSearchClick" :icon="Search">搜索</el-button>
			</div>
			<div class="rx-cc">
				<el-button :icon="Download" @click="downloadEcharts">下载图表</el-button>
				<el-button :icon="Upload" @click="downloadTable">导出表格</el-button>
			</div>
		</div>
		<div class="rx-sc pl10 pr10 pt20 pb20 posi-r mt20" style="background-color: #EFF6FF;">
			<div class="ww25 pl10 pr10">
				<div class="bdr4 bg-fff rx-bc pl35 pr35 h110">
					<div>
						<h3 class="fwb color-333 ft32 mb5">{{summaryData.enterAllCount}}</h3>
						<p class="fwb color-999 ft16">入场总人次</p>
					</div>
					<div class="iconBox">
						<img class="w60" src="@/assets/peopleIcon.png" alt="">
						<span class="bg-FA73B0 rx-cc w25 h25 bdr100 color-fff fwb iconItem">人</span>
					</div>
				</div>
			</div>
			<div class="ww25 pl10 pr10">
				<div class="bdr4 bg-fff rx-bc pl35 pr35 h110">
					<div>
						<h3 class="fwb color-333 ft32 mb5">{{summaryData.enterPerDay}}</h3>
						<p class="fwb color-999 ft16">日均入场人次</p>
					</div>
					<div class="iconBox">
						<img class="w60" src="@/assets/peopleIcon.png" alt="">
						<span class="bg-5DDCA9 rx-cc w25 h25 bdr100 color-fff fwb iconItem">人</span>
					</div>
				</div>
			</div>
			<div class="ww25 pl10 pr10">
				<div class="bdr4 bg-fff rx-bc pl35 pr35 h110">
					<div>
						<h3 class="fwb color-333 ft32 mb5">￥{{summaryData.consumeAll}}</h3>
						<p class="fwb color-999 ft16">入场消费总金额</p>
					</div>
					<div class="iconBox">
						<img class="w60" src="@/assets/boxIcon.png" alt="">
						<span class="bg-B773FA rx-cc w25 h25 bdr100 color-fff fwb iconItem">额</span>
					</div>
				</div>
			</div>
			<img style="position: absolute;right: 40px;top: 5%;height: 90%;" src="@/assets/dataIcon.png" alt="">
		</div>
		<div class="mt20 pl10 fwb ft16 color-333" style="border-left: 4px solid #03A3FF;">
			日客流量趋势
		</div>
		<div id="main" class="h500 mt20"></div>
		<div class="mt20">
			<el-table :data="tableData.list.slice(tabForm.page*tabForm.limit-tabForm.limit,tabForm.page*tabForm.limit)"
				style="width: 100%">
				<el-table-column prop="enterDate" label="日期" />
				<el-table-column prop="enterCount" label="入场人次" sortable />
				<el-table-column prop="receptionCount" label="前台人工入场" sortable />
				<el-table-column prop="courseNum" label="智能硬件入场" sortable />
				<el-table-column prop="consumeMoney" label="入场消耗总金额(元)" sortable />
			</el-table>
			<div class="mt20 rx-ec" v-show="tableData.list.length > 0">
				<el-pagination v-model:currentPage="tabForm.page" :page-sizes="[15,20,30,50,100]"
					:page-size="tabForm.limit" layout="total, sizes, prev, pager, next, jumper" :total="tabForm.count"
					@size-change="pageSizeChange" @current-change="pageChange">
				</el-pagination>
			</div>
		</div>
		<Formula v-model="formulaShow" :type="formulaType"></Formula>
	</div>
</template>

<script setup>
	import {
		reactive,
		ref,
		onMounted,
		onBeforeUnmount
	} from 'vue';
	import {
		Search,
		Upload,
		Download
	} from '@element-plus/icons-vue';
	import * as echarts from 'echarts';
	import utils from '@/common/utils.js'
	import {
		getEnterOutAnalysis
	} from '@/api/dataCenter.js'
	import {
		ElMessage
	} from 'element-plus'
	var myChart = '';
	//日期范围/月份范围控件配置
	const datePickerDisabled = reactive({
		disabledDate(time) {
		  return time.getTime() > new Date().getTime()
		},
		setMonthDisabled(time) {
		    // 获取当前的月份信息
		    const date = new Date(); // 获取当前的时间基本信息，值是这样的： Tue Jul 20 2021 14:59:43 GMT+0800 (中国标准时间)
		    const year = date.getFullYear(); // 获取当前年份，值是这样的： 2021
		    let month = date.getMonth() + 1; // 获取当前月份，值是这样的： 6   getMonth()方法返回值是0-11，也就是1月份到12月份，所以要加上1，才是当前月份
		    if (month >= 1 && month <= 9) {
		      // 如果是1月到9月就要在前面补上一个0   比如：02、07    月份这样表示
		      month = "0" + month;
		    }
		    const nowDate = year.toString() + month.toString(); // 转换成字符串拼接，最终得到年和月，比如此时的时间是2021年7月20号，所以nowDate的值是：202107
		    // 获取时间选择器的月份信息
		    const timeyear = time.getFullYear(); // 获取时间选择器的年份（有很多）
		    let timemonth = time.getMonth() + 1; // 与上面同理
		    if (timemonth >= 1 && timemonth <= 9) {
		      timemonth = "0" + timemonth;
		    }
		    const elTimeData = timeyear.toString() + timemonth.toString();
		
		    // 返回，时间选择器的日期月份大于当前日期的月份，又因为disabledDate函数是控制月份禁用不可选
		    // 所以，最终就是：时间选择器的月份大于当前的月份，就都禁用掉，所以就实现了最终效果：
		    // 大于等于当前月份都不可选 
		    return elTimeData > nowDate; // 这里虽然是字符串，但是弱类型语言js会做一个转换，是可以比较大小的如： '202107' > '202008'
		},
	})
	//搜索项数据
	const searchQueryList = reactive({
		timeTypeList: [{
				label: '近7天',
				value: 5
			},
			{
				label: '近15天',
				value: 6
			},
			{
				label: '近30天',
				value: 7
			},
			{
				label: '近60天',
				value: 8
			},
			{
				label: '近90天',
				value: 9
			},
			{
				label: '近6月',
				value: 24
			},
			{
				label: '近12月',
				value: 25
			},
			{
				label: '自定义日期',
				value: -1
		},
			{
				label: '自定义月份',
				value: -2
		}
			]
	});
	
	//汇总数据字段
	const summaryData = {
		enterAllCount: 0, //入场总人次
		enterPerDay: 0, //日均入场人次
		consumeAll: 0, //入场消费总金额
	}
	//图标数据
	var getOption = {
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'cross',
				crossStyle: {
					color: '#999'
				}
			}
		},
		grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
		legend: {
			data: []
		},
		xAxis: [{
			type: 'category',
			data: [],
			axisPointer: {
				type: 'shadow'
			},
            axisLabel: {
                interval: 6,
                rotate: 35
            }
		}],
		yAxis: [],
		series: []
	};
	//获取数据
	const tableData = reactive({
		list: []
	});
	const tabForm = reactive({
		count: 0,
		limit: 15,
		page: 1,
		timeIn: 5,
		searchTime: [],
		exportData: 0
	})
	//切换列表数据展示数量
	const pageSizeChange = function(limit) {
		tabForm.limit = limit;
		// tabSearchClick();
	}
	//搜索
	const tabSearchClick = function(){
		if (tabForm.timeIn == '-1') {
            if (!tabForm.searchTime || !tabForm.searchTime.length) {
                ElMessage({
                    type : 'warning',
                    message : '请选择日期'
                });
                return;
            }
            if (utils.datedifference(tabForm.searchTime[0], tabForm.searchTime[1]) > 31) {
                ElMessage({
                    type : 'warning',
                    message : '自定义日期不能超过31天'
                });
                return;
            }
        }
        if (tabForm.timeIn == '-2') {
            if (!tabForm.searchTime || !tabForm.searchTime.length) {
                ElMessage({
                    type : 'warning',
                    message : '请选择月份'
                });
                return;
            }
            if (utils.MonthsBetw(tabForm.searchTime[0], tabForm.searchTime[1]) > 12) {
                ElMessage({
                    type : 'warning',
                    message : '自定义月份不能超过12个月'
                });
                return;
            }
        }
		tabForm.page = 1;
		getData();
	}
	const getData = function() {
		getEnterOutAnalysis({
			timeIn:tabForm.timeIn,
			searchTime:tabForm.searchTime.join(' - '),
			exportData: tabForm.exportData
		}).then((res) => {
			if (res.code == 0) {
				if (tabForm.exportData == 1) { //导出
					ElMessage({
						type: 'success',
						message: '正在导出，请稍后前往下载中心下载'
					})
					return false;
				}
				for (var k in summaryData) {
					summaryData[k] = res.results[k];
				}
				tableData.list = res.data;
				tabForm.count = res.count;
				var xData = [],
					seriesData = []
				tableData.list.map((n) => {
					xData.push(n.enterDate);
					seriesData.push(n.enterCount);
				})
				getOption.legend.data = [ '入场总人次'];
				getOption.xAxis[0].data = xData;
				if (tableData.list.length > 10) {
                    getOption.xAxis[0].axisLabel = {
                        interval:0,
                        rotate: 40
                    }
                } else {
                    getOption.xAxis[0].axisLabel = {
                        interval:0,
                        rotate: 0
                    }
                }
				getOption.yAxis = [{
						type: 'value',
						name: '入场人次/人', 
					}
				];
				getOption.series = [
					{
						name: '入场总人次',
						type: 'line',
						smooth: true,
                    	symbol: 'circle',
						symbolSize: 6,
						label: {
							show: true
						},
						data: seriesData,
						itemStyle:{
                    	    normal: {
                    	        color: '#FA7474', 
                    	        borderColor:'#FFF',
                    	        borderWidth:1,
                    	        lineStyle: {
                    	            color: '#FA7474',
                    	            shadowColor: 'rgba(250, 116, 116, 0.3)',//设置折线阴影
                    	            shadowBlur: 1,
                    	            shadowOffsetY: 10,
                    	        }
                    	    }
                    	},
					}
				]
				myChart.setOption(getOption);
			}
		})

	}


	//下载图表
	const downloadEcharts = function() {
		var myChartSrc = myChart.getDataURL({
			pixelRatio: 2,
			backgroundColor: '#fff'
		});
		var a = document.createElement('a');
		a.style.display = 'none';
		// a.href = 'data:image/jpeg;base64,'+myChartSrc;
		a.href = myChartSrc;
		a.download = '日客流量趋势.png';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}
	//导出表格
	const downloadTable = function() {
		tabForm.exportData = 1;
		getData();
	}
	onMounted(() => {
		var chartDom = document.getElementById('main');
		myChart = echarts.init(chartDom);
		window.addEventListener("resize",function(){
		    myChart.resize();
        });
		getData()
	})
	onBeforeUnmount(() => {
      if (!myChart) {
        return
      }
      myChart.dispose()
      myChart = null
    })
	//表格排序
	// const sortChange = function(column, prop, order){
	// 	tabForm.page = 1;
	// 	tableData.list.sort((a,b)=>{
	// 		var x = a[prop],y = b[prop];
	// 		if(order == 'ascending'){
	// 			return ((x < y) ? -1 : ((x > y) ? 1 : 0));
	// 		}else{
	// 			return ((x < y) ? ((x > y) ? 1 : 0) : -1);
	// 		}
	// 	})
	// }
</script>
<style type='css/style' scoped>
	.typeActive{
		position: relative;
		color: #00A3FF;
	}
	.typeActive::after{
		position: absolute;
		content: '';
		bottom: -8px;
		left: 50%;
		width: 70%;
		height: 3px;
		transform: translateX(-50%);
		background-color: #03A3FF;
		border-radius: 2px;
	}
	.bg-5DDCA9{
		background-color:  #5DDCA9;
	}
	.bg-FA73B0{
		background-color: #FA73B0;
	}
	.bg-B773FA{
		background-color: #B773FA; 
	}
	.iconBox{
		position: relative;
	}
	.iconItem{
		position: absolute;
		bottom: 0;
		right: 0;
	}
</style>
