<template>
	<div class="bg-fff pt16">
		<!-- 查询 -->
		<div class="rx-sc mb25">
			<div class="mr16">
				<el-input v-model="pageData.searchForm.searchKeys" placeholder="输入员工姓名、手机号" />
			</div>
			<el-button type="primary" @click="searchTable" :icon="Search">搜索</el-button>
		</div>
		<!-- 按钮区域 -->
		<div class="mb16 rx-sc">
			<el-button type="primary" @click="()=>{addStaff()}" v-if="utils.getMenuRole(3310000)">新增员工</el-button>
			<el-button type="danger" plain @click="deleStaff" v-if="utils.getMenuRole(3310002)">批量删除</el-button>
			<el-button @click="dataRole" v-if="utils.getMenuRole(3310003)">数据权限</el-button>
			<!-- <el-button @click="setAttenType" v-if="utils.getMenuRole(3310005)">考勤方式</el-button> -->
			<el-button @click="resetPassword" v-if="utils.getMenuRole(3310004)">重置密码</el-button>
		</div>
		<div>
			<CommonTip text="初始密码8个8,登录账号用作登录移动端和电脑端!"/>
		</div>
		<!-- 数据表格 -->
		<div>
			<el-table ref="satffTable" :data="pageData.tableData" stripe style="width: 100%" @selection-change="selectionChange">
				<el-table-column type="selection" width="55" />
			    <el-table-column prop="realName" label="员工姓名" show-overflow-tooltip >
					<template #default="scope">
						<span v-if="utils.getMenuRole(3310001) && (scope.row.userType == 2 || scope.row.userType == 5)">{{scope.row.realName}}</span>
						<a v-else href="javascript:;" class="color-409EFF tool_btn" @click="addStaff(scope.row)">{{scope.row.realName}}</a>
					</template>
				</el-table-column>
			    <el-table-column prop="roleName" label="员工岗位" show-overflow-tooltip />
			    <el-table-column label="授课权限" show-overflow-tooltip>
					<template #default="scope">
						{{ scope.row.teachRoles ? scope.row.teachRoles : '无' }}
					</template>
				</el-table-column>
			    <el-table-column prop="stallPhone" label="手机号" />
			    <el-table-column prop="logingName" label="登录账号" />
			    <el-table-column prop="ifFullTime" label="员工类型" >
			    	<template #default="scope">
						<span>{{scope.row.ifFullTime == 1 ? '全职' : scope.row.ifFullTime == 2 ? '兼职':'未知'}}</span>
					</template>
			    </el-table-column>
			    <el-table-column prop="attenTypeStr" label="考勤方式" />
				<el-table-column prop="sid" label="来源" >
					<template #default="scope">
						<span>{{scope.row.sid == 0 ? '总店' : '门店'}}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" >
					<template #default="scope">
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="utils.getMenuRole(3310006) && !scope.row.openId" @click="bindWechat(scope.row.encryptionId,scope.row.openId)">绑定+登录微信手机端</a>
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn color-red" v-if="utils.getMenuRole(3310007) && scope.row.openId" @click="bindWechat(scope.row.encryptionId,scope.row.openId)">解除绑定</a>
					</template>
				</el-table-column>
			</el-table>
			<div class="mt20 rx-ec" v-show="pageData.tableData.length > 0">
			    <el-pagination
			      v-model:currentPage="pageData.searchForm.page"
			      :page-sizes="[15,20,30,50,100]"
			      :page-size="pageData.searchForm.limit"
			      layout="total, sizes, prev, pager, next, jumper"
			      :total="pageData.totalCount"
			      @size-change="pageSizeChange"
			      @current-change="()=>{getList()}"
			    >
			    </el-pagination>
			  </div>
		</div>
		<!-- 新增员工 -->
		<el-dialog
		    v-model="pageData.drawer.show"
		    :title="pageData.drawer.title"
			width="700px"
			:before-close="()=>{pageData.drawer.show = false}"
		    custom-class="popwechat"
		  >
			<div class="p20">
				<CommonTip text="新增员工登录初始默认密码为8个8。"/>
				<div class="rx-sc mb25">
					<div class="query_tip rx-ec"><span class="color-red">*</span>员工头像：</div>
					<div class="rx-sc">
						<img class="w65 h65 b_r_100 mr10" :src="pageData.addFormData.headImgUrl" alt="">
						<div>
							<p>
								<CommonUpload @change="uploadChange" :autoCropWidth="375" :autoCropHeight="375" :isCropper="true">
									<el-button type="primary" plain>{{pageData.addFormData.headImgUrl=='https://image.taokatao.cn/imgs/runBusiness/detailPhoto.png' ? '立即上传' : '重新上传'}}</el-button>
								</CommonUpload>
								</p>
							<p class="color-999 mt10">建议大小：750*750px</p>
						</div>
					</div>
				</div>
				<div class="rx-bc mb25">
					<div class="query_item rx-bc">
						<div class="query_tip rx-ec"><span class="color-red">*</span>员工姓名：</div>
						<el-input class="query_con" v-model="pageData.addFormData.realName" placeholder="输入员工姓名(限20字符)" maxlength="20"/>
					</div>
					<div class="query_item rx-bc">
						<div class="query_tip rx-ec"><span class="color-red">*</span>登录账号：</div>
						<el-input class="query_con" v-model="pageData.addFormData.logingName" placeholder="输入登录账号" />
					</div>
				</div>
				<div class="rx-bc mb25">
					<div class="query_item rx-bc">
						<div class="query_tip rx-ec w75"><span class="color-red">*</span>手机号：</div>
						<el-input class="query_con" v-model="pageData.addFormData.stallPhone" maxlength="11" placeholder="输入手机号" @input="telInputEvent" />
					</div>
					<div class="query_item rx-bc">
						<div class="query_tip rx-ec">员工类型：</div>
						<div class="query_con">
							<el-select v-model="pageData.addFormData.ifFullTime" placeholder="请选择">
								<el-option :value="0" label="未知"></el-option>
								<el-option :value="1" label="全职"></el-option>
								<el-option :value="2" label="兼职"></el-option>
							</el-select>
						</div>
					</div>
				</div>
				<div class="rx-bc mb25">
					<div class="query_item rx-bc">
						<div class="query_tip rx-ec"><span class="color-red">*</span>员工岗位：</div>
						<div class="query_con">
							<el-select multiple collapse-tags v-model="pageData.addFormData.roleId" placeholder="请选择">
								<el-option :value="item.encryptionId" :label="item.roleName" v-for="item in pageData.roleList" :key="item.encryptionId"></el-option>
							</el-select>
						</div>
					</div>
					<div class="query_item rx-bc">
						<div class="query_tip rx-ec">考勤方式：</div>
						<div class="query_con">
							<el-select v-model="pageData.addFormData.attenType" placeholder="请选择">
								<el-option :value="1" label="默认考勤"></el-option>
								<el-option :value="2" label="课表考勤"></el-option>
								<el-option :value="3" label="指定考勤"></el-option>
							</el-select>
						</div>
					</div>
				</div>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="pageData.drawer.show = false">取消</el-button>
					<el-button type="primary" @click="saveStaff">确定</el-button>
				</div>
			</template>
		</el-dialog> 
		<!-- 数据权限 -->
		<el-dialog
		    v-model="pageData.dataRoleShow"
		    :title="pageData.dataRoleTitle"
			width="500px"
			:before-close="()=>{pageData.dataRoleShow = false}"
		    custom-class="popwechat"
		  >
			<div class="p20">
				<div class="rx-sc mb25">
					<span class="w130 t_a_r">会员权限：</span>
					<div>
						<el-radio v-model="pageData.dataRoleForm.menuType1" :label="1" size="large"><span class="color-666 fw400">看所有会员</span></el-radio>
						<el-radio v-model="pageData.dataRoleForm.menuType1" :label="2" size="large"><span class="color-666 fw400">只看我的会员</span></el-radio>
					</div>
				</div>
				<div class="rx-sc mb25">
					<span class="w130 t_a_r">课程权限：</span>
					<div>
						<el-radio v-model="pageData.dataRoleForm.menuType3" :label="1" size="large"><span class="color-666 fw400">看所有课程</span></el-radio>
						<el-radio v-model="pageData.dataRoleForm.menuType3" :label="2" size="large"><span class="color-666 fw400">只看我的课程</span></el-radio>
					</div>
				</div>
				<div class="rx-sc mb25">
					<span class="w130 t_a_r">手机号权限：</span>
					<div>
						<el-radio v-model="pageData.dataRoleForm.menuType9" :label="1" size="large"><span class="color-666 fw400">显示手机号</span></el-radio>
						<el-radio v-model="pageData.dataRoleForm.menuType9" :label="3" size="large"><span class="color-666 fw400">隐藏手机号</span></el-radio>
					</div>
				</div>
				<div class="rx-sc mb25">
					<span class="w130 t_a_r">会员剩余金额：</span>
					<div>
						<el-radio v-model="pageData.dataRoleForm.menuType15" :label="1" size="large"><span class="color-666 fw400">可以查看</span></el-radio>
						<el-radio v-model="pageData.dataRoleForm.menuType15" :label="3" size="large"><span class="color-666 fw400">不可以查看</span></el-radio>
					</div>
				</div>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="pageData.dataRoleShow = false">取消</el-button>
					<el-button type="primary" @click="dataRoleSave">确定</el-button>
				</div>
			</template>
		</el-dialog> 
		<!-- 考勤方式 -->
		 <el-dialog
		     v-model="pageData.attenTypeShow"
		     title="设置考勤方式"
		 	 width="308px"
		     :before-close="()=>{pageData.attenTypeShow = false}"
		     custom-class="popwechat"
		   >
			<div class="pl20 pt50 pb50 pr20">
				<div class="rx-cc">
					<span class="">考勤方式：</span>
					<div class="w180">
						<el-select v-model="pageData.attenTypeValue" placeholder="请选择">
							<el-option :value="1" label="默认考勤"></el-option>
							<el-option :value="2" label="课表考勤"></el-option>
							<el-option :value="3" label="指定考勤"></el-option>
						</el-select>
					</div>
				</div>
		  </div>
		   <template #footer>
		    	<div class="dialog-footer">
		        	<el-button @click="pageData.attenTypeShow = false">取消</el-button>
		        	<el-button type="primary" @click="setAttenTypeSave">确定</el-button>
		    	</div>
		    </template>
		</el-dialog>
		<!-- 绑定微信弹框 -->
		<el-dialog
		    v-model="pageData.bindWechat.show"
		    :title="pageData.bindWechat.title"
			width="280px"
			:before-close="bindWechatSave"
		    custom-class="popwechat"
		  >
		    <div class="ww100 h280 cx-sc">
		    	<div class="ww100 rx-cc mt20 h30 color-666">请使用微信扫一扫绑定</div>
		    	<div class="w160 h160 mt20 rx-cc bdr4" style="border:1px solid #d6d6d6">
			    	<div class="w140 h140" id="bindWechatQrcode"></div>
			    </div>
		    </div>
		    <template #footer>
		    	<div class="dialog-footer">
		        	<el-button type="primary" @click="bindWechatSave">确定</el-button>
		    	</div>
		    </template>
		</el-dialog>
		<Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave"/>
	</div>
</template>
<script setup>
	import {ref,reactive,onMounted} from 'vue';
	import { ElMessage} from 'element-plus'
	import QRCode from 'qrcodejs2'
	import {Refresh,DArrowRight,Connection,Close,Search} from '@element-plus/icons-vue'
	import CommonTip from '@/components/CommonTip.vue'
	import Confirm from '@/components/Confirm.vue'
	import CommonUpload from '@/components/CommonUpload.vue';
	import utils from '@/common/utils.js'
	import {
		employeeInsert,
		getStoreAccountStaff,
		userMenuDetailInsert,
		menuDetailManager,
		roleManageStore,
		getLinkWechat,// 员工绑定
		employeeListStatusUpdate,
		employeeUpdate,
		removeLinkWeChat,
		 employeeUpdateAttendance,
		 sysUserPasswordReset
	} from '@/api/staff.js';
	const pageData = reactive({
		tableData:[],
		totalCount : 0,
		searchForm:{
			page:1,
			limit:15,
			searchKeys:'',
		},
		roleList:[],
		addFormData:{
			encryptionId:'',
			realName:'',
			logingName:'',
			stallPhone:'',
			ifFullTime:0,
			attenType:1,
			roleId:[],
			headImgUrl:'https://image.taokatao.cn/imgs/runBusiness/detailPhoto.png'
		},
		drawer:{
			title:'新增员工',
			show:false,
		},
		bindWechat:{
			title : '绑定微信',
			show : false
		},
		confirmTitle:'',
		confirmText:'',
		confirmType:'',
		checkStaffList:[],
		
		dataRoleShow:false,
		dataRoleTitle:'',
		dataRoleForm:{
			menuType1:1,
			menuType3:1,
			menuType9:1,
			menuType15:1,
		},
		attenTypeShow:false,
		attenTypeValue:1
	})
	const confirmRef = ref();
	var selectCheckData = [],bindWechatId = '';
	
	function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		searchTable();
	}
	//获取员工列表数据
	function searchTable(){
		pageData.searchForm.page = 1;
		getList();
	};
	getList();
	function getList(){
		getStoreAccountStaff(pageData.searchForm).then((res)=>{
			if(res.code == 0){
				pageData.tableData = res.data;
				pageData.totalCount = res.count;
			}
		})
	}
	// 绑定微信
	function bindWechat(encryptionId,openId){
		bindWechatId = encryptionId;
		if(openId){ //解绑
			pageData.confirmTitle = '确定要对该员工解绑微信吗？'
			pageData.confirmType = 'bind';
			confirmRef.value.show();
		}else{ //绑定
			pageData.bindWechat.show = true;
			getLinkWechat({
				encryptionId : encryptionId
			}).then((res)=>{
				document.getElementById('bindWechatQrcode').innerHTML = '';
				new QRCode('bindWechatQrcode', {
				  width: 140,
				  height: 140, // 高度
				  text: res.data, // 二维码内容
				  background: '#fff'
				})
			});
		}
	}
	function bindWechatSave(){
		pageData.bindWechat.show = false;
		searchTable();
	}
	
	// 新增弹出窗
	function addStaff(row){
		//岗位搜索下拉框
		roleManageStore({page: 1, limit: 9999}).then((res)=>{
			if(res.code == 0){
				pageData.roleList = res.data;
			}
		})
		pageData.addFormData.encryptionId = '';
		pageData.addFormData.realName = '';
		pageData.addFormData.logingName = '';
		pageData.addFormData.stallPhone = '';
		pageData.addFormData.ifFullTime = 0;
		pageData.addFormData.attenType = 1;
		pageData.addFormData.roleId.length = 0;
		if(row){
			for(var key in pageData.addFormData){
				pageData.addFormData[key] = row[key];
			}
			if(!row.headImgUrl){
				pageData.addFormData.headImgUrl = 'https://image.taokatao.cn/imgs/runBusiness/detailPhoto.png';
			}
			pageData.addFormData.roleId = row.roleId ? row.roleId.split(',') : [];
		}
		pageData.drawer.title = row ? '编辑员工' : '新增员工';
		pageData.drawer.show = true;
	}
	
	
	//新增员工保存
	function saveStaff(){
		var formData = JSON.parse(JSON.stringify(pageData.addFormData));
		if(!formData.realName){
			ElMessage({
				type: 'warning',
				message: '请输入员工名称',
			})
			return false;
		}
		if(!formData.realName){
			ElMessage({
				type: 'warning',
				message: '请输入员工名称',
			})
			return false;
		}
		if(!formData.logingName){
			ElMessage({
				type: 'warning',
				message: '请输入登录账号',
			})
			return false;
		}
		var storeId = [];
		pageData.roleList.map((n)=>{
			if(formData.roleId.indexOf(n.id) > -1){
				storeId.push(n.sid);
			}
		})
		formData.roleId = formData.roleId.join(',');
		formData.storeId = storeId.join(',');
		if(pageData.drawer.title == '编辑员工'){
			employeeUpdate(formData).then((res)=>{
				if(res.code == 0){
					pageData.drawer.show = false;
					ajaxSaveCallBack('编辑成功');
				}
			})
			return false;
		}
		employeeInsert(formData).then((res)=>{
			if(res.code == 0){
				pageData.drawer.show = false;
				ajaxSaveCallBack('保存成功');
			}
		})
	}
	
	//删除员工
	function deleStaff(){
		CheckLengthCallBack(()=>{
			pageData.confirmTitle = '确定要删除选中的数据吗？';
			var arr = [];
			selectCheckData.map((n)=>{
				if(n.sid == 0){
					arr.push(n.realName);
				}
			})
			pageData.confirmText = arr.length > 0 ?'【'+arr.join('、')+'】为总店创建员工无权删除，将自动过滤。' :'';
			pageData.confirmType = 'dele';
			confirmRef.value.show();
		})
	}
	
	//数据权限
	function dataRole(){
		CheckLengthCallBack(()=>{
			if(selectCheckData.length == 1 ){
				menuDetailManager({
					encryptionId:selectCheckData[0].encryptionId
				}).then((res)=>{
					if(res.code == 0){
						if(res.data.menuList.length > 0){
							res.data.menuList.map((n)=>{
								pageData.dataRoleForm['menuType'+n.privilegeType] = n.privilegeValue;
							})
						}
						pageData.dataRoleTitle = '设置 '+selectCheckData[0].realName+' 的数据权限';
						pageData.dataRoleShow = true;
					}
				})
				
			}else{
				ElMessage({
					type: 'warning',
					message: '该操作只能选择一个会员',
				})
			}
		})
	}
	function dataRoleSave(){
		pageData.dataRoleForm.encryptionId = selectCheckData[0].encryptionId;
		userMenuDetailInsert(pageData.dataRoleForm).then((res)=>{
			if(res.code == 0){
				pageData.dataRoleShow = false;
				ajaxSaveCallBack('设置成功');
			}
		})
	}
	//设置考勤方式
	function setAttenType(){
	 	CheckLengthCallBack(()=>{
			pageData.attenTypeValue = 1;
	 		pageData.attenTypeShow = true;
	 	})
	}
	function setAttenTypeSave(){
		employeeUpdateAttendance({
			encryptionId:getSelectCheckKey('encryptionId'),
			attenType:pageData.attenTypeValue
		}).then((res)=>{
			if(res.code == 0){
				pageData.attenTypeShow = false;
				ajaxSaveCallBack('设置成功');
			}
		})
	}
	//重置密码
	function resetPassword(){
	 	CheckLengthCallBack(()=>{
			pageData.confirmTitle = '确定要重置选中数据的密码吗？';
			pageData.confirmType = 'reset';
			confirmRef.value.show();
	 	})
	}
	
	function confirmSave(){
		if(pageData.confirmType == 'dele'){
			var arr = [];
			selectCheckData.map((n)=>{
				if(n.sid != 0){
					arr.push(n.encryptionId);
				}
			})
			if(arr.length == 0){
				ElMessage({
					type: 'warning',
					message: '过滤后无可删除数据',
				})
				return false;
			}
			employeeListStatusUpdate({
				encryptionId:arr.join(',')
			}).then((res)=>{
				if(res.code == 0){
					ajaxSaveCallBack('删除成功');
				}
			})
		}else if(pageData.confirmType == 'bind'){
			removeLinkWeChat({
				encryptionId:bindWechatId
			}).then((res)=>{
				if(res.code == 0){
					ajaxSaveCallBack('解绑成功');
				}
			})
		}else if(pageData.confirmType == 'reset'){
			sysUserPasswordReset({
				encryptionId:getSelectCheckKey('encryptionId')
			}).then((res)=>{
				if(res.code == 0){
					ajaxSaveCallBack('重置成功');
				}
			})
		}
		
	}

	//上传封面
	function uploadChange(url){
		pageData.addFormData.headImgUrl = url;
	}
	//table通用勾选
	function selectionChange(data){
		selectCheckData = data;
	}
	//获取table勾选集合的某个字段集合
	function getSelectCheckKey(key){
	 	return selectCheckData.map(n=>n[key]).join(',');
	}
	// 验证批量操作是否勾选了数据
	function CheckLengthCallBack(callback){
		if(selectCheckData.length == 0){
			ElMessage({
				type: 'warning',
				message: '请选择需要操作的员工',
			})
			return false;
		}
		callback && callback();
	}
	//ajax提交保存通用提示
	function ajaxSaveCallBack(text,callback){
		ElMessage({
			type: 'success',
			message: text
		})
		searchTable();
		callback && callback();
	}
	//手机号输入验证
	var telVal = '';
	function telInputEvent(val){
		if(isNaN(Number(val))){
			pageData.addFormData.stallPhone = telVal;
		}else{
			telVal = val;
		}
	}
</script>

<style lang="stylus">
	.el-select .el-select__tags-text{
		max-width 75px !important;
		display inline-block !important;
	}

</style>
