<template>
    <div class="pl16 pt16 pr16">
        <formQuery ref="formQ" :queryOpts="queryOpts" @query="queryTableData"></formQuery>
    </div>
    <div class="ww100 h30 rx-bc p16">
        <div class="w300 rx-sc">
            <el-button  v-show="pageData.tableShowType == 1" type="primary" @click="toAddCourseInfo" v-if="utils.getMenuRole(3150000)">班级排期</el-button>
            <el-button  v-show="pageData.tableShowType == 1" type="primary" @click="replenishClass"  v-if="utils.getMenuRole(3150001)">安排补课</el-button>
            <el-button  v-show="pageData.tableShowType == 1" @click="addMember"  v-if="utils.getMenuRole(3150001)">批量预约</el-button>
        </div>
        <div class="rx-cc ml30" >
            <el-button class="mr10" type="default" @click="changeWeek(-1)">上周</el-button>
            <div class="w180 h30 rx-cc">{{pageData.tableWeekStart}}~{{pageData.tableWeekEnd}}</div>
            <el-button class="ml10" type="default" @click="changeWeek(1)">下周</el-button>
        </div>
        <div class="w500 rx-ec">
            <el-button v-show="pageData.tableShowType == 2" @click="batchDelete" type="danger" plain>批量删除</el-button>
            <el-button v-show="pageData.tableShowType == 2" @click="batchClose" type="danger" plain>批量关闭</el-button>
            <el-button v-show="pageData.tableShowType == 1" @click="changeTableShowType(2)">切换为列表</el-button>
            <el-button v-show="pageData.tableShowType == 2" @click="changeTableShowType(1)">切换为表格</el-button>
            <el-button v-if="utils.getMenuRole(3150002)" :icon="Printer" @click="print">打印</el-button>
            <el-button v-if="utils.getMenuRole(3150003)" :icon="Download" @click="downTable">下载</el-button>
        </div>
    </div>
    <div class="ww100 pl16 pr16 pt16 pb16" id="downBox">
        <!-- 小班课排期课表 -->
        <table v-show="pageData.tableShowType == 1" class="ww100 tableout" cellpadding="0" cellspacing="0">
            <thead>
                <tr>
                    <td class="w60 h50 rx-cc" style="border-bottom:1px solid #e5e5e5;">时间</td>
                    <td class="headcell" v-for="week in pageData.tableDataTitle" :key="week">{{week}}</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="time in pageData.tableData" :key="time">
                    <td class="w60" style="border-bottom:1px solid #e5e5e5;text-align:center;">{{time.name}}</td>
                    <td class="bodycell" style="vertical-align:top;" v-for="week in time.weekArray" :key="week">
                        <div v-for="course in week.courseArray" :key="course ">
                            <div class="ww100 hh100" v-if="course.blank">
                                <div @mouseenter="pageData.blankName = course.blank" @mouseleave="pageData.blankName = ''" class="ww100 rx-cc tableblank c_p h110">
                                    <div class="ww100 h100 rx-cc color-main" @click="toAddCourseInfo" v-show="course.blank == pageData.blankName">
                                        <Plus class="mr5" style="height:12px;width:12px;"></Plus>新增排课</div>
                                </div>
                            </div>
                            <div v-else :class="course.status == 2 ? 'close_class' : '' ">
                                <el-popover placement="top" :show-after="200" :width="275" trigger="hover">
                                <template #reference>
                                    <div class="ww100 cx-cc tablecell c_p h110 posi-r" :style="{'background-color':'#'+course.courseColor + '11'}">
                                        <div class="ww100 posi-a top0" style="height:4px;margin-top: -1px;" :style="{'background-color': '#'+ course.courseColor }"></div>
                                        <div v-if="course.courseState == 0" class="posi-a top0 bg-06BCA0" style="height:18px;width: 18px;margin-top: -1px; right: 48px">
                                            <el-tooltip
                                                class="box-item"
                                                effect="dark"
                                                content="待上课"
                                                placement="top-start"
                                              >
                                                <img style="height:16px;width:15px; margin-top: 1px; margin-left: 1px" src="@/assets/toBeTaught.png" alt="">
                                            </el-tooltip>
                                        </div>
                                        <div v-if="course.courseState == 2" class="posi-a top0 bg-808383" style="height:18px;width: 18px;margin-top: -1px; right: 48px">
                                            <el-tooltip
                                                class="box-item"
                                                effect="dark"
                                                content="已完结"
                                                placement="top-start"
                                              >
                                                <img style="height:16px;width:15px; margin-top: 1px; margin-left: 1px" src="@/assets/closed.png" alt="">
                                            </el-tooltip>
                                        </div>
                                        <div class="posi-a top0 bg-F4912B color-fff ft10 pl10 pr10" v-if="course.ifMakeUpCourse == 1" style="height:18px;margin-top: -1px;right: 78px">补课</div>
                                        <div class="posi-a right0 top0 bg-main color-fff ft10 pl10 pr10 rx-cc" style="height:18px;margin-top: -1px;">{{course.confirmClassNum}}/{{course.allPeopleNum}}</div>
                                        <div class="ww100 cx-sc" @click="toCourseInfoDetail(course)">
                                            <div class="rx-cc ft12"><Clock class="w15 h15"></Clock><span class="ml5">{{course.classTimeShow}}</span></div>
                                            <div class="ft12 h20 o_h">{{ course.courseCustomName ? course.courseCustomName +' ('+ course.courseName + ')' : course.courseName }}</div>
                                            <div class="ft12 h20 o_h color-999">{{course.cycleName}}</div>
                                            <div class="ft12 h20 o_h color-999">{{course.teachers}}</div>
                                        </div>
                                        <div class="w120 h20 rx-cc tablecell_icon" v-if="course.status != 2">
                                            <el-tooltip v-if="utils.getMenuRole(3150004)" class="box-item" effect="light" content="编辑本课节" placement="bottom">
                                                <el-icon class="cellicon" @click="editWork(course)"><Edit></Edit></el-icon>
                                            </el-tooltip>
                                            <el-tooltip v-if="utils.getMenuRole(3150007)" class="box-item" effect="light" content="删除本课节" placement="bottom">
                                                <el-icon class="cellicon" @click="removeWork(course.encryptionId)"><Delete></Delete></el-icon>
                                            </el-tooltip>
                                            <el-tooltip v-if="utils.getMenuRole(3150005)" class="box-item" effect="light" content="关闭本课节" placement="bottom">
                                                <el-icon class="cellicon" @click="closeWork(course.encryptionId)"><View></View></el-icon>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                </template>
                                <!-- 悬浮框内容 -->
                                <div style="width:250px;">
                                    <img v-if="course.status == 2 && course.fullStatus != 2" src="https://s.flow360.cn/src/style/res/manualClose.png" class="autoClose">
                                    <img v-if="course.status == 2 && course.fullStatus == 2" src="https://s.flow360.cn/src/style/res/autoClose.png" class="autoClose">
                                    <div class="ww100 h30 mb8 rx-sc fwb">
                                        <div class="w80 rx-ec">上课时间：</div>
                                        <div class="w170 h30 maxLength rx-sc">{{course.classTimeBetween}}</div>
                                    </div>
                                    <div class="ww100 cx-sc" >
                                        <div class="ww100 h20 mb8 rx-sc">
                                            <div class="w80 rx-ec ">
                                                <span class="teacher d_i_b">师</span>
                                                <span class="teacher-name">{{ course.teachers }}</span>
                                            </div>
                                            <div class="w80 rx-ec">
                                                <span class="student d_i_b">生</span>
                                                <span>{{course.confirmClassNum}}/{{course.allPeopleNum}}</span>
                                            </div>
                                        </div>
                                        <div class="ww100 h20 mb8 rx-sc">
                                            <div class="ml10">小班课</div>
                                        </div>
                                        <div class="ww100 h20 mb8 rx-sc">
                                            <div class="w80 rx-ec">课程名称：</div>
                                            <div class="w170 h30 maxLength rx-sc">{{course.courseName}}</div>
                                        </div>
                                        <div class="ww100 h20 mb8 rx-sc">
                                            <div class="w80 rx-ec">课程主题：</div>
                                            <div class="w170 h30 maxLength rx-sc">{{ course.courseCustomName }}</div>
                                        </div>
                                         <div class="ww100 h20 mb8 rx-sc">
                                            <div class="w80 rx-ec">上课场地：</div>
                                            <div class="w170 h30 maxLength rx-sc">{{ course.roomName }}</div>
                                        </div>
                                        <div class="ww100 h30 mb8 rx-sc">
                                            <div class="rx-cc ml10">
                                                <span>已签到({{ course.confirmClassCourse }})</span>
                                            </div>
                                            <div class="rx-cc ml10">
                                                <span>未签到({{course.noAttendanceCount}})</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ww100 h30 ml10 rx-sc" v-if="course.status != 2">
                                        <el-button size="small" type="primary" @click="editWork(course)">调课</el-button>
                                        <el-button size="small" type="success"  @click="toCourseInfoDetail(course)">签到</el-button>
                                    </div>
                                </div>
                            </el-popover>
                            </div>
                            
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <!-- 小班课排期-列表 -->
        <div class="ww100" v-show="pageData.tableShowType == 2" >
            <el-table class="ww100"  :data="pageData.teamListData.queryTeam.tableData" stripe border style="" @selection-change="checkChange">
                <el-table-column type="selection" width="55" />
                <el-table-column prop="classTimeStr" fixed width="160" label="上课时间" ></el-table-column>
                <el-table-column label="课程名称">
                    <template #default="scope">
                        <a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="toCourseInfoDetail(scope.row)">{{ scope.row.courseName }}</a>
                    </template>
                </el-table-column>
                <el-table-column prop="cycleName" label="班级名称" />
                <el-table-column label="班级类型">
                    <template #default="scope">
                        {{ scope.row.cycleType == 11 ? '固定上课模式' : '自主预约模式' }}
                    </template>
                </el-table-column>
                <el-table-column prop="courseCustomName"  label="课节主题" />
                <el-table-column prop="teachers"  label="上课教练" />
                <el-table-column prop="roomName"  label="上课场地" />
                <el-table-column prop="confirmClassNum"  label="已预约人数" />
                <el-table-column prop="confirmClassCourse"  label="签到人数" />
                <el-table-column width="210" fixed="right" label="操作" >
                    <template #default="scope">
                        <div v-if="scope.row.status != 2">
                            <a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="closeWork(scope.row.encryptionId)">关闭课节</a>
                            <a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="removeWork(scope.row.encryptionId)">删除课节</a>
                       </div> 
                    </template>
                </el-table-column>
            </el-table>
            <div class="mt20 ml20" v-show="pageData.teamListData.queryTeam.tableData.length >= 0">
                <el-pagination
                  v-model:currentPage="pageData.teamListData.queryTeam.searchForm.page"
                  :page-sizes="[15,20,30,50,100]"
                  :page-size="pageData.teamListData.queryTeam.searchForm.limit"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="pageData.teamListData.queryTeam.totalCount"
                  @size-change="pageSizeChange"
                  @current-change="getCourseInfoPage">
                </el-pagination>
            </div>
        </div>
    </div>
    <Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave">
		<template #btns>
            <div v-if="confirmType == 'courseClose'">
                <el-button size="small" @click="confirmClose">取消</el-button>
		        <el-button size="small" type="primary" @click="()=>{confirmSave(2)}">关闭课节</el-button>
                <el-button size="small" type="primary" @click="()=>{confirmSave(1)}">关闭课节并提醒会员</el-button>
            </div>
		    <div v-if="confirmType == 'courseDelete'">
                <el-button size="small" @click="confirmClose">取消</el-button>
		        <el-button size="small" type="primary" @click="()=>{confirmSave(2)}">删除课节</el-button>
                <el-button size="small" type="primary" @click="()=>{confirmSave(1)}">删除课节并提醒会员</el-button>
            </div>
		</template>
	</Confirm>
    <EditWork ref="editWorkRef" @close="editWorkClose" :type="13" @onEditWork="confirmEditWork"></EditWork>
    <el-dialog 
        v-model="pageData.outerVisible"
        title="补课安排"
        width="600px"
        :before-close="handleOuterClose"
    >
        <template #default>
            <div class="p20">
                <div class="rx-sc mb16">
					<div class="w80 t_a_r"><span class="color-red">*</span>补课班级：</div>
					<div class="w220">
						<el-select @change="getCycleLeaveStu" v-model="pageData.formData.encryptionId" placeholder="请选择班级">
							<template v-for="(select, index) in pageData.courseNameList" :key="index">
								<el-option :label="select.label" :value="select.value"></el-option>
							</template>
                        </el-select>
					</div>
				</div>
                <div class="rx-ss mb16">
					<div class="w80 t_a_r"><span class="color-red">*</span>补课会员：</div>
					<div>
						<span v-if="pageData.subscribeSelection.length" class="color-999">已选择</span>
                        <span class="color-999" v-for="(item, index) in pageData.subscribeSelection" :key="index">
                            [{{ item.realName }}]
                            <span v-if="index != pageData.subscribeSelection.length-1">、</span>
                        </span>
					</div>
				</div>
                <div class="color-red">请假情况(未安排补课)：</div>
                <el-table ref="subscribeTable" :row-key="getRowId" :data="pageData.subscribePage.tableData.slice((pageData.subscribePage.searchForm.page-1)*pageData.subscribePage.searchForm.limit, pageData.subscribePage.searchForm.page*pageData.subscribePage.searchForm.limit)" stripe @selection-change="checkSubscribe" height="400px">
                    <el-table-column type="selection" :reserve-selection="true" width="55" />
                    <el-table-column prop="realName" label="会员姓名" ></el-table-column>
                    <el-table-column prop="classTimeStr" label="请假课节" />
                    <el-table-column prop="courseTeacher" label="上课教练" />
                    <el-table-column prop="courseCustomName" label="课节主题" />
                </el-table>
                <div class="mt10 ml20" v-show="pageData.subscribePage.tableData.length >= 0">
                    <el-pagination
                        v-model:currentPage="pageData.subscribePage.searchForm.page"
                        :page-sizes="[15,20,30,50,100]"
                        :page-size="pageData.subscribePage.searchForm.limit"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pageData.subscribePage.totalCount"
                        @size-change="pageSizeChange1"
                        @current-change="getCycleLeaveStu"
                    >
                    </el-pagination>
                </div>
            </div>
            <el-dialog
              v-model="pageData.innerVisible"
              width="400px"
              title="补课安排"
              append-to-body
              :before-close="handleClose"
            >
                <div class="p20">
                    <el-form ref="ruleFormRef" :model="pageData.ruleForm" :rules="rules" label-width="80px">
                        <el-form-item label="日期" prop="value">
                            <el-date-picker
                                style="width:100%"
                                v-model="pageData.ruleForm.value"
                                type="date"
                                placeholder="请选择日期"
                                format="YYYY-MM-DD"
                                value-format="YYYY-MM-DD"
                             />
                        </el-form-item>
                        <el-form-item label="开始时间" prop="value1">
                            <el-time-picker
                                style="width:100%"
                                v-model="pageData.ruleForm.value1"
                                :min-time="pageData.ruleForm.value2"
                                format="HH:mm"
                                value-format="HH:mm"
                                placeholder="请选择开始时间"
                                start="00:00"
                                step="00:05"
                                end="23:55" 
                            />
                        </el-form-item>
                        <el-form-item label="结束时间" prop="value2">
                            <el-time-picker
                                style="width:100%"
                                v-model="pageData.ruleForm.value2"
                                :min-time="pageData.ruleForm.value1"
                                format="HH:mm"
                                value-format="HH:mm"
                                placeholder="请选择结束时间" 
                                start="00:00"
                                step="00:05"
                                end="23:55"
                            />
                        </el-form-item>
                        <el-form-item label="教练" prop="teacherIds">
                            <el-select v-model="pageData.ruleForm.teacherIds" multiple collapse-tags placeholder="请选择教练">
                                <el-option v-for="item in pageData.teacherList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="场地">
                            <el-select v-model="pageData.ruleForm.classroomId" multiple collapse-tags placeholder="请选择场地">
                                <el-option v-for="item in pageData.classroomList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="排课名称">
                            <el-input v-model="pageData.ruleForm.courseCustomName"  placeholder="请输入排课名称"/>
                        </el-form-item>
                    </el-form>
                </div>
                <template #footer>
                    <div class="dialog-footer">
                        <el-button @click="handleClose">取消</el-button>
                        <el-button type="primary" @click="addReplenish">提交</el-button>
                    </div>
                </template>
            </el-dialog>
        </template>
        <template #footer>
            <div class="dialog-footer">
                <el-button type="primary" @click="goReplenish">去排课</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script setup>
    import moment from "moment";
    import utils from '@/common/utils.js';
    import formQuery from '@/components/branch-store/formQuery.vue';
    import workArea from '@/components/branch-store/workArea.vue';
    import { useStore } from 'vuex'
    import { useRouter } from 'vue-router';
    import { ElMessage } from 'element-plus';
    import { ref,reactive,onMounted,onActivated , onBeforeUnmount} from 'vue';
    import { onBeforeRouteLeave,onBeforeRouteUpdate } from "vue-router";
    import { Search,Edit,DocumentCopy,Delete,View,Clock,Plus,Download,Printer } from '@element-plus/icons-vue';
    import { courseCycleListSimple, courseList, courseListPlus, courseListPagePlus, addReCourse, cycleLeaveStu } from '@/api/course.js'
    import { 
        deleteCourseInfoBatch,
        closeCourseInfoBatch,
        courseClose,
        courseDelete,
    } from '@/api/courseInfo.js';
    import { getStoreAccountStaffSimple } from '@/api/staff.js';
    import EditWork from '@/components/branch-store/course/editWork.vue'
    import Confirm from '@/components/Confirm.vue'
    import html2canvas from 'html2canvas'
    import { sportRoomListAll } from '@/api/place.js';
    
    const subscribeTable = ref();
    const ruleFormRef = ref();
    const router    = useRouter();
    const store     = useStore();
    const confirmRef = ref();
    const editWorkRef = ref()
    const formQ     = ref();                // 表单查询条件
    const barlist   = reactive([{ name : '首页' },{ name : '课表管理' }]); // 面包屑
    const pageData  = reactive({
        outerVisible: false, //  补课弹框
        innerVisible: false, // 确定补课
        loadingTable   : false,
        tableData      : reactive([]),      // 小班课表格数据
        tableWeekNum   : 0,                 // 小班课表格周
        tableWeekStart : '',                // 小班课表格周显示开始
        tableWeekEnd   : '',                // 小班课表格周显示结束
        tableDataTitle : reactive([]),      // 小班课表格头
        tableForm      : {},                // 表单内容
        teamListData   : {                  // 列表内容数据集合
            queryTeam      : {              // 列表数据
                totalCount : 0,
                searchForm : {
                    page   : 1,
                    limit  : 15
                },
                tableData  : []                 // 列表--小班课列表数据
            },
        },
        tableShowType  : 1,                 // 1 课表表格，2课表列表
        blankName      : '',                 // 空格显示名称
        deleCheckData  : [],                //  待删除列表
        formData: {}, // 补课班级 
        courseNameList: [],
        subscribeSelection: [],
        subscribePage : {
            totalCount : 0,
            searchForm : {
                page : 1,
                limit : 15
            },
            tableData : []
        },
        ruleForm: {
            value: '',
            value1: '',
            value2: '',
            teacherIds: [],
            classroomId: [],
            courseCustomName: ''
        },
        teacherList:[],
        classroomList: [],
    });
    
    let confirmType = ref() // 全局弹框类型
    let confirmId = '' // 全局课节id
    const queryOpts = reactive({});         // 表单查询字段
    queryOpts['fields'] = [{
        type : 'selectMany',
        fieldName : 'cycleType',
        placehold : '班级类型',
        options : [{label : '固定上课班级' ,value : '11'},{ value : '12' , label : '自主预约班级' }]
    },{
        type : 'selectMany',
        fieldName : 'encryptionId',
        placehold : '全部班级',
        options : []
    },{
        type : 'selectMany',
        fieldName : 'courseNameIds',
        placehold : '全部课程',
        options : []
    },{
        type : 'selectMany',
        fieldName : 'teacherIds',
        placehold : '全部教练',
        options : []
    }];
     /**
     * 表单验证规则
     */
    const rules = {
        value: [{ required: true, message: '请选择日期', trigger: 'change' }],
        value1: [{ required: true, message: '请选择开始时间', trigger: 'change' }],
        value2: [{ required: true, message: '请选择结束时间', trigger: 'change' }],
        teacherIds: [{ required: true, message: '请选择教练', trigger: 'change' }],
    };
    /**
     * 补课
     */
    function replenishClass() {
        pageData.outerVisible = true
    }
    /**
     * 取消补课弹框
     */
    function handleOuterClose() {
        pageData.formData.encryptionId = ''
        pageData.subscribeSelection = []
        subscribeTable.value.clearSelection()
        pageData.outerVisible = false
    }
    /**
     * 查询待补课的会员
     */
    function getCycleLeaveStu(val){
        let params = {
            page: pageData.subscribePage.searchForm.page,
            limit: pageData.subscribePage.searchForm.limit,
            encryptionId: pageData.formData.encryptionId
        }
        cycleLeaveStu(params).then(res => {
            if (res.code == 0) {
                pageData.subscribePage.tableData = res.data
                pageData.subscribePage.totalCount = res.data.length
                pageData.subscribeSelection = []
                subscribeTable.value.clearSelection()
            }
        })
    }
    /**
     * 补课排课
     */
    function goReplenish() {
        if (!pageData.formData.encryptionId) {
            ElMessage.warning('请选择补课班级')
            return
        }
        if (!pageData.subscribeSelection.length) {
            ElMessage.warning('请选择需要补课的会员')
            return
        }
        const map = new Map()
        const newArr = pageData.subscribeSelection.filter(key => !map.has(key.cardMyId) && map.set(key.cardMyId, 1))
        if (newArr.length != pageData.subscribeSelection.length){
            ElMessage.warning('不同的请假课节不能统一补课')
            return
        }
        let params = {
            teachType: 3,
            page: 1,
            limit: 9999,
            encryptionCourseCycleId: pageData.formData.encryptionId
        }
        getStoreAccountStaffSimple(params).then((res) => {
            if (res.code == 0) {
                var typeArray = [];
	    		for(var i=0;i<res.data.length;i++){
	    		    typeArray.push({
	    		        value : res.data[i]['id'],
	    		        label : res.data[i]['realName'] 
	    		    });
	    		}
                pageData.teacherList = typeArray
            }
        })
        pageData.innerVisible = true
        /**
         * 获取场地
         */
        sportRoomListAll({page: 1, limit: 9999}).then(res => {
           if (res.code == 0) {
                var typeArray = [];
	    		for(var i=0;i<res.data.length;i++){
	    		    typeArray.push({
	    		        value : res.data[i]['id'],
	    		        label : res.data[i]['roomName'] 
	    		    });
	    		}
                pageData.classroomList = typeArray
            } 
        })
    }
    /**
     * 取消排课补课
     */
    function handleClose() {
        ruleFormRef.value.resetFields()
        pageData.ruleForm.courseCustomName = ''
        pageData.innerVisible = false
    }
    /**
     * 确定排课补课
     */
    function addReplenish() {
        ruleFormRef.value.validate((valid) => {
            if (valid) {
                let list = []
                pageData.subscribeSelection.forEach(item => {
                    list.push({
                        cardMyId: item.cardMyId,
                        courseInfoId: item.courseInfoId,
                        courseNameId: item.courseNameId
                    })
                })
                let params = {
                    encryptionId: pageData.formData.encryptionId,
                    courseTeacher: pageData.ruleForm.teacherIds.join(),
                    classroomId: pageData.ruleForm.classroomId.join(),
                    courseCustomName: pageData.ruleForm.courseCustomName,
                    classTimeStr: pageData.ruleForm.value + ' ' + pageData.ruleForm.value1 +':00' + ' - ' + pageData.ruleForm.value + ' ' + pageData.ruleForm.value2 +':00',
                    infoList: list
                }
                addReCourse(params).then(res => {
                    if (res.code == 0) {
                        ElMessage.success('补课成功')
                        pageData.innerVisible = false
                        pageData.outerVisible = false
                        getTableData();
                    }
                })
            } else {
              console.log('error submit!!');
              return false;
            }
        })
    }
    /**
     * 添加会员
     */
    function addMember() {
        router.push({ path: '/branch/classManage/addMember', query :{ type: 1}})
    }
    /**
     * 获取教练
     */
    getStoreAccountStaffSimple({teachType: 3, page: 1, limit: 9999}).then((res) => {
        if (res.code == 0) {
            var typeArray = [];
			for(var i=0;i<res.data.length;i++){
			    typeArray.push({
			        value : res.data[i]['id'],
			        label : res.data[i]['realName'] 
			    });
			}
            queryOpts['fields'][3]['options'] = typeArray
        }
    })
     /**
     * 班级列表
     */
    courseCycleListSimple({ page: 1, limit: 99999 }).then(res => {
        if (res.code == 0) {
            var typeArray = [], cycleList = [];
            for(var i=0;i<res.data.length;i++){
			    typeArray.push({
			        value : res.data[i]['encryptionId'],
			        label : res.data[i]['cycleName'] 
			    });
                if (res.data[i].cycleType == 11) {
                   cycleList.push({
			            value : res.data[i]['encryptionId'],
			            label : res.data[i]['cycleName'] 
			        });
                }
			}
            queryOpts['fields'][1]['options'] = typeArray
            pageData.courseNameList = cycleList
        }
    })
    /**
     * 课程列表
     */
    courseList({ page: 1, limit: 99999, courseType: 13}).then(res => {
        if (res.code == 0) {
            var typeArray = [];
            for(var i=0;i<res.data.length;i++){
			    typeArray.push({
			        value : res.data[i]['courseNameId'],
			        label : res.data[i]['courseName'] 
			    });
			}
            queryOpts['fields'][2]['options'] = typeArray
        }
    })
    // 批量删除
    function batchDelete(){
        if(pageData.deleCheckData.length == 0){
            ElMessage({ type : 'warning' ,message : '请先选择数据'});
            return;
        }
        deleteCourseInfoBatch({
            ids : pageData.deleCheckData.join(',')
        }).then((res)=>{
            if(res.code == 0){
                pageData.deleCheckData = [];
                ElMessage({ type : 'success' ,message : res.msg});
                getCourseInfoPage();
            }else{
                ElMessage({ type : 'warning' ,message : res.msg});
            }
        });
    }
    // 批量关闭
    function batchClose(){
        if(pageData.deleCheckData.length == 0){
            ElMessage({ type : 'warning' ,message : '请先选择数据'});
            return;
        }
        closeCourseInfoBatch({
            encryptionCourseIds : pageData.deleCheckData.join(',')
        }).then((res)=>{
            if(res.code == 0){
                pageData.deleCheckData = [];
                ElMessage({ type : 'success' ,message : '关闭成功'});
                getCourseInfoPage();
            }
        });
    }
    // 列表-选择列表内容
    function checkChange(data){
        pageData.deleCheckData = data.map(n=>n.encryptionId);
    }

    // 表单回调
    function queryTableData(){
        if(pageData.tableShowType == 1){
            getTableData();
        }else{
            getCourseInfoPage();
        }
        pageData.teamListData.queryTeam.searchForm.page = 1
    }
    
    // 查询表格内容
    function getTableData(cb){
        var param = Object.assign({},formQ.value.getQueryForm());
        param['startDate'] = pageData.tableWeekStart;
        param['endDate'] = pageData.tableWeekEnd;
        pageData.loadingTable = true;
        param['teacherIds']  = param['teacherIds'] && param['teacherIds'].join(',') || '';
        param['courseNameIds'] = param['courseNameIds'] && param['courseNameIds'].join(',') || '';
        param['cycleType'] = param['cycleType'] && param['cycleType'].join(',') || '';
        param['encryptionId'] = param['encryptionId'] && param['encryptionId'].join(',') || '';
        
        param['courseType'] = 13
        courseListPlus(param).then((res)=>{
            pageData.loadingTable = false;
            if(res.code != 0 ){
                ElMessage({ type: 'warning', message: res.message })
                return;
            }
            pageData.tableDataTitle = utils.getWeekArray(pageData.tableWeekStart); // 根据周一日期获取一周的日期名称
            var tableData   = []; // 表格数据集合
            var timeCourses = {};
            var courseList  = res.data.map((n)=>{
				n.classTimeBetween = n.startTimeBetween.split(' ')[1];
				return n;
			});
            for(var i=0;i<courseList.length;i++){
                var lessonTimeStart = moment(courseList[i]['classTimeStr']).format('HH:mm');
                var lessonTimeEnd   = moment(courseList[i]['classTimeEndStr']).format('HH:mm');
                courseList[i]['classTimeShow'] = lessonTimeStart + '~' + lessonTimeEnd;
                var time            = moment(courseList[i]['classTimeStr']).format('H');
                var timeObj         = timeCourses[time] || {};
                var timeCourseCount = timeObj['timeCourseCount'] || 0;
                var weekIdx         = moment(courseList[i]['classTimeStr']).format('E');
                var courseWeekArray = timeObj[weekIdx] || [];
                courseWeekArray.push(courseList[i]);
                timeObj[weekIdx]  = courseWeekArray;
                timeCourseCount   = courseWeekArray.length > timeCourseCount ? courseWeekArray.length : timeCourseCount;
                timeObj['timeCourseCount'] = timeCourseCount;
                timeCourses[time] = timeObj;
            }
            for(var i=0;i<24;i++){
                if(timeCourses[i]){
                    var hourStart = i+'';
                    hourStart     = hourStart.length == 1 ? '0'+hourStart : hourStart;
                    var hourEnd   = i+1 + '';
                    hourEnd       = hourEnd.length == 1 ? '0'+hourEnd : hourEnd;
                    var name      = hourStart + ':00';
                    var timeObj   = { name : name, weekArray : [] };
                    for(var j=1;j<8;j++){
                        var dayCourse = { courseArray : [] };
                        if(timeCourses[i][j] && timeCourses[i][j].length > 0){
                            dayCourse['courseArray'] = timeCourses[i][j];
                        }
                        for(var k = dayCourse.courseArray.length;k<timeCourses[i]['timeCourseCount'];k++){
                            dayCourse['courseArray'].push({blank:i+'-'+j+'-'+k});
                        }
                        timeObj.weekArray.push(dayCourse);
                    }
                    tableData.push(timeObj);
                }
            }
            pageData.monday    = res.results.monday;
            pageData.tableData = tableData;
            cb && cb();
        });
    }
    // 切换课节展示方式
    function changeTableShowType(type){
        pageData.tableShowType = type;
        if(type == 2 && pageData.teamListData.queryTeam.tableData.length == 0){
            getCourseInfoPage();
        }
        if(type == 1 && pageData.tableData.length == 0){
            getTableData();
        }
    }
    //切换分页数量
	function pageSizeChange1(limit){
		pageData.subscribePage.searchForm.limit = limit;
		getCourseInfoPage();
	}
	//切换分页数量
	function pageSizeChange(limit){
		pageData.teamListData.queryTeam.searchForm.limit = limit;
		getCourseInfoPage();
	}
    // 列表--分页查询课节列表
    function getCourseInfoPage(){
        var param = {};
        Object.assign(param,formQ.value.getQueryForm());
        param.limit = pageData.teamListData.queryTeam.searchForm.limit
        param.page = pageData.teamListData.queryTeam.searchForm.page
        param['startDate'] = pageData.tableWeekStart;
        param['endDate'] = pageData.tableWeekEnd;
        param['teacherIds']  = param['teacherIds'] && param['teacherIds'].join(',') || '';
        param['courseNameIds'] = param['courseNameIds'] && param['courseNameIds'].join(',') || '';
        param['cycleType'] = param['cycleType'] && param['cycleType'].join(',') || '';
        param['encryptionId'] = param['encryptionId'] && param['encryptionId'].join(',') || '';
        param['courseType']   = 13;
        pageData.loadingTable = true;
        courseListPagePlus(param).then((res)=>{
            pageData.loadingTable = false;
            if(res.code == 0){
                pageData.teamListData.queryTeam.tableData = res.data;
                pageData.teamListData.queryTeam.totalCount = res.count
            }
        });
    }
    // 跳转课节详情-关闭当前页面全部浮窗
    function toCourseInfoDetail(row){
        if (row.status == 2 ) {
            return false
        }
        var x = document.getElementsByClassName("el-popper");
        for (var i = 0; i < x.length; i++) {
            x[i].style.display = "none";
        }
        router.push({
            path:'/branch/courseInfoDetail',
            query: {
                encryptionId: row.encryptionId,
                encryptionCourseNameId: row.encryptionCourseNameId
            }
        });
    }
    // 点击上一周/下一周 type：-1上周1下周
    function changeWeek(type){
        if(pageData.loadingTable){ return; }
        pageData.tableWeekNum   = type == -1 ? pageData.tableWeekNum - 1 : pageData.tableWeekNum + 1;
        var tableWeekArray      = utils.getWeekDays(pageData.tableWeekNum);
        pageData.tableWeekStart = tableWeekArray[0];
        pageData.tableWeekEnd   = tableWeekArray[1];
        getTableData();
    }
    // 跳转排课
    function toAddCourseInfo(){
        router.push({ path: '/branch/addClass', query :{ tableWeekNum: pageData.tableWeekNum  } })
    }
    /**
     * 编辑课节
     */
    function editWork(row) {
        editWorkRef.value.getEditInfo(row.encryptionId)
		editWorkRef.value.show();
    }
    /**
     * 确定编辑
     */
    function confirmEditWork(){
        getTableData()
    }
    /**
     * 删除课节
     */
    function removeWork(id) {
        pageData.confirmTitle = '确定删除课节吗？';
        pageData.confirmText = '删除后将自动为会员取消预约本节课！'
		confirmType.value = 'courseDelete';
        confirmId = id
		confirmRef.value.show();
    }
    /**
     * 关闭课节
     */
    function closeWork(id) {
        pageData.confirmTitle = '确定关闭课节吗？';
        pageData.confirmText = '关闭后的课节不能再次开启，关闭后将自动为会员取消本节课！'
		confirmType.value = 'courseClose';
        confirmId = id
		confirmRef.value.show();
    }
    /**
     * 确定提交询问框
     */
    function confirmSave(type) {
        if (confirmType.value == 'courseClose') {
           courseClose({ encryptionId: confirmId, type}).then((res)=> {
                if (res.code == 0) {
                   ElMessage.success('成功关闭')
                   getTableData()
                }
				confirmRef.value.close();
           })
        } else {
            courseDelete({ encryptionId: confirmId, type}).then((res)=> {
                if (res.code == 0) {
                   ElMessage.success('成功删除')
                   getTableData()
                }
                confirmRef.value.close();
           })
        }
    }
	function confirmClose(){
		confirmRef.value.close();
	}

  // 下载
    function downTable(){
      html2canvas(document.getElementById('downBox')).then((canvas)=>{
        const imgUrl = canvas.toDataURL('png');
        const aDom = document.createElement('a');
        aDom.href = imgUrl;
        aDom.download = '小班课排期课表';
        document.body.appendChild(aDom);
        aDom.click();
        aDom.remove();
      })
    }
    /**
     * 打印
     */
    function print() {
        html2canvas(document.getElementById('downBox')).then((canvas)=>{
        const imgUrl = canvas.toDataURL('png');
        var iframe = document.createElement('iframe');
        iframe.style.width = '100%';
		document.body.appendChild(iframe)
        iframe.contentWindow.document.write(`<!DOCTYPE html>
						                    <html>
						                    	<head>
                                                    <title>小班课排期课表</title>
						                    	</head>
						                    	<body>
                                                    <img src="${imgUrl}" style="width:100%;">
						                    	</body>
						                    </html>`)
        setTimeout(() =>{
            iframe.contentWindow.print();
		    iframe.remove();
        }, 200)
      })
    }
    /**
     * 获取选择会员数据
     */
    const checkSubscribe = (val) => {
        pageData.subscribeSelection = val
    }
    /**
     * 记住分页标记（课节信息）
     */
    const getRowId=(row) => {
        return row.id
    }
    // 页面启动
    onMounted(()=>{
        var tableWeekArray = []
        if (localStorage.getItem('tableWeekNum') != null) {
            tableWeekArray = utils.getWeekDays(localStorage.getItem('tableWeekNum'))
	    } else {
            tableWeekArray = utils.getWeekDays(pageData.tableWeekNum)
        }
        pageData.tableWeekStart = tableWeekArray[0];
        pageData.tableWeekEnd   = tableWeekArray[1];
        getTableData();
    })
    // 页面激活，返回时刷新
    onActivated(()=>{
        getTableData();
    })
    onBeforeUnmount(() => {
		localStorage.removeItem('tableWeekNum')
	})
</script>

<style type="text/css" scoped>
.box-item{ font-size:10px !important;}
    .tableout{
        border:1px solid #e5e5e5;
        border-bottom: none;
        border-radius: 5px;
    }
    .headcell{
        width:14.2857%;
        border-left:1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        text-align: center;
    }
    .bodycell{
        border-left:1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        text-align: center;
    }
    .tablecell{
        border:1px solid #ffffff;
        border-bottom: 1px solid #f0f0f0;
    }
    .tablecell_icon {
        display: none;
    }
    .tablecell:hover .tablecell_icon{
        display: block;
    }
    
    .tableblank{
        border:1px solid #ffffff;
        border-bottom: 1px solid #ffffff;
    }
     .tableblank:hover{
        border:1px dashed #00a3ff;
    }
    .cellicon{ width : 22px;height:22px; }
    .cellicon:hover{color:#00a3ff;}
    fieldset {
        display: block;
        margin-inline-start: 2px;
        margin-inline-end: 2px;
        padding-block-start: 0.35em;
        padding-inline-start: 0.75em;
        padding-inline-end: 0.75em;
        padding-block-end: 0.625em;
        min-inline-size: min-content;
        border-width: 2px;
        border-style: groove;
        border-color: threedface;
        border-image: initial;
    }
    legend {
        display: block;
        padding-inline-start: 2px;
        padding-inline-end: 2px;
        border-width: initial;
        border-style: none;
        border-color: initial;
        border-image: initial;
    }
    .close_class {
        background: #ffffff;
        opacity: .3;
    }
    .autoClose {
        position: absolute;
        top: 0;
        right: 0;
        width: 25%;
    }
    .teacher {
        padding: 0 3px;
        border: 1px solid #10D2B7;
        color: #10D2B7;
        border-radius: 2px;
        margin-right: 8px;
    }
    .teacher-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 40px;
    }
    .student {
        padding: 0 3px;
        border: 1px solid #FE8080;
        color: #FE8080;
        border-radius: 2px;
        margin-right: 8px;
    }
</style>




