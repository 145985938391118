<template>
	<div class="mores_info" v-show="storeData.moduleStatus">
		<h3 class=" mb10 rx-sc" v-show="!pageData.updateTitleShow">
			<div class="rx-ss">
				<span class="ft16">{{storeData.name}}</span>
				<unicon class="ml10 mr10" width="18px" name="edit-alt" fill="#47A1FE" @click="pageData.updateTitleShow = true"></unicon>
			</div>
		</h3>
		<div class="mb10 rx-sc" v-show="pageData.updateTitleShow">
			<div class="w150 mr20">
				<el-input type="text" v-model="pageData.titleName" maxlength="6" placeholder="限6个字符"></el-input>
			</div>
			<span class="color-409EFF mr20 c_p tool_btn" @click="updateTitle">确定</span>
			<span class="c_p" @click="pageData.updateTitleShow = false">取消</span>
		</div>
		<div class="store_form mt20">
			<div class="rx-sc mb12">
				<span>模块风格：</span>
				<div>
					<el-radio-group v-model="storeData.showType">
						<el-radio label="1" size="large">风格一</el-radio>
						<el-radio label="2" size="large">风格二</el-radio>
					</el-radio-group>
				</div>
			</div>
			<div class="rx-ss mb12">
				<span>选择服务：</span>
				<div class="w300">
					<ul class="fun_list">
						<li class="t_a_c ww25" v-for="(item,index) in storeData.modules" :key="index">
							<img class="ww50" :src="serviceIcon[storeData.showType-1]['icon'+storeData.showType+''+(index+1)]" alt="">
							<p class="color-333 ftb ft12">{{item.name}}</p>
							<p v-if="item.name == '预约日程' || item.name == '我的订单' || item.name == '体验卡'|| item.name == '我的班级'"><el-checkbox v-model="item.checked" size="large"></el-checkbox></p>
							<p v-else><el-checkbox v-model="item.checked" disabled size="large"></el-checkbox></p>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	const propData = defineProps({
	  tab:String,
	  itemIndex:Number
	})
	import {ref,reactive} from 'vue'
	import { useStore } from 'vuex'
	const store = useStore();
	const storeData = store.state.appletFormData[propData.tab].list[propData.itemIndex];
	const serviceIcon = store.state.serviceIcon;
	const pageData = reactive({
		uploadData:'',
		areaList:[],
		updateTitleShow:false,
		titleName:'',
	})
	
	
	//编辑标题
	function updateTitle(){
		if(pageData.titleName){
			storeData.name = pageData.titleName;
			pageData.updateTitleShow = false;
		}else{
			ElMessage({
				message:'请输入标题',
				type: 'warning'
			})
		}
	}
	//切换展示数量 重置展示模块勾选状态
	function showNumChange(){
		storeData.modules.map((n,i)=>{
			n.disabled = false;
			n.checked = false;
		})
	}
	//选择模块判断展示数量  超出禁用其他模块
	function checkChange(){
		var checks = storeData.modules.filter(n=>n.checked);
		storeData.modules.map((n,i)=>{
			if(checks.length == storeData.showNum){ //展示数量已满
				n.disabled = n.checked ? false : true;
			}else{
				n.disabled = false;
			}
		})
	}

	
</script>

<style scoped lang="stylus">
	.mores_info{
		.fun_list{
			overflow hidden;
			li{
				float left;
				padding 10px 0;
			}
		}
		.store_form{
			clear both;
		}
	}
</style>
