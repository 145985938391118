<template>
	<div class="color-999 ft12 mt16">温馨提示：展示可在本门店消费的已购课程</div>
	<div class="mt16">
		<div :name="(i+1)" v-for="(n,i) in pageData.list" :key="i">
			<div class="card-top rx-bc c_p" @click="n.open = !n.open">
				<div class="rx-sc">
					<span class="d_i_b w5 h5 v_a_m bdr4 badge mr5"></span>
					<span class="ml5 fwb">已购{{n.title}}</span>
					<div class="ml20 color-666">共<span class="ml5 mr5 color-red">{{ n.title == '私教课' ? (propData.num.privateCourseNum || 0) : n.title == '团体课' ? (propData.num.groupCourseNum || 0) : (propData.num.classCourseNum || 0) }}</span>节</div>
				</div>
				<a href="javaScript:;" class="tool_btn color-main">
					<span class="v_a_m">{{ n.open ? '收起' : '展开' }}</span>
					<DArrowRight v-show="!n.open" class="arrowdown"></DArrowRight>
                    <DArrowLeft v-show="n.open" class="arrowdown"></DArrowLeft>
				</a>
			</div>
			<div class="mb25" v-show="n.open">
				<el-table :data="n.data" strip>
					<el-table-column prop="productName" :label="n.title+'名称'"  width="110" show-overflow-tooltip />
					<el-table-column prop="storeName" label="购买门店"  width="110" show-overflow-tooltip />
					<el-table-column prop="operationTime" label="购买时间" />
					<el-table-column prop="actualMoney" label="实收金额(元)" />
					<el-table-column label="购买额度">
						<template #default="scope">
							<span>{{scope.row.productNumBuy+'节'}}</span>
						</template>
					</el-table-column>
					<el-table-column label="赠送额度">
						<template #default="scope">
							<span v-if="scope.row.buyGiveNum">{{scope.row.buyGiveNum+'节'}}</span>
							<span v-else>-</span>
						</template>
					</el-table-column>
					<el-table-column label="剩余额度">
						<template #default="scope">
							<span v-if="scope.row.productNum">{{scope.row.productNum+'节'}}</span>
							<span v-else>-</span>
						</template>
					</el-table-column>
					<el-table-column label="有效期" width="200">
						<template #default="scope">
							<span v-if="scope.row.startTime">{{scope.row.startTime+'至'+scope.row.endTime}}</span>
							<span v-else>未开始</span>
						</template>
					</el-table-column>
					<el-table-column label="状态">
						<template #default="scope">
							<span :class="scope.row.orderState == 1 ? 'text-color' : '' ">{{scope.row.orderState == 1 ? '正常' : scope.row.orderState == 2 ? '已冻结' : scope.row.orderState == 3 ? '已结束': '已退款'}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="canUse" label="可用门店">
						<template #default="scope">
							<span class="t_d_u color-409EFF tool_btn">{{ scope.row.storeName }}</span>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ref, reactive } from 'vue';
	import { DArrowRight, DArrowLeft } from '@element-plus/icons-vue';
	import { queryBuyCourseList, queryConsumeSid } from '@/api/member.js';
	const propData = defineProps({
		info: Object,
		num: Object
	})
	console.log(propData)
	const pageData = reactive({
		lookStoreShow: false,
		lookStoreList: [],
		lookStoreCount: 0,
		lookStoreForm:{
			page:1,
			limit:15
		},
		list: [{
				title: '私教课',
				data: []
			},{
				title: '团体课',
				data: []
			}]
	});
	function getBuyCardsList() {
		queryBuyCourseList({
			encryptionCardMyId: propData.info.encryptionId
		}).then((res) => {
			if (res.code == 0) {
				res.data.map((n) => {
					n.open = false
					var num = n.courseType == 12 ? 0 : 1
					pageData.list[num].data.push(n);
				})
			}
		})
	}
	if (propData.info.encryptionId) {
		getBuyCardsList();
	}
	//查看门店
	function lookStore(id) {
		pageData.lookStoreForm.id = id;
		queryConsumeSid(pageData.lookStoreForm).then((res) => {
			if (res.code == 0) {
				pageData.lookStoreList = res.data;
				pageData.lookStoreCount = res.count;
				pageData.lookStoreShow = true;
			}
		})
	}
</script>

<style scoped>
.badge {
	background: #333;
}
.text-color{
	color: #018000;
}
.card-top{
	background: rgba(64, 158, 255, 0.06);
	padding: 10px 17px 11px 17px;
	margin-bottom: 17px;
}
.arrowdown{
	transform:rotate(90deg);
	-ms-transform:rotate(90deg);     /* IE 9 */
	-moz-transform:rotate(90deg);    /* Firefox */
	-webkit-transform:rotate(90deg); /* Safari 和 Chrome */
	-o-transform:rotate(90deg);  /* Opera */
	width:13px;
	vertical-align: middle;
 	margin-left:2px;
}
</style>
