<template>
    <workArea :barlist="barlist">
        <div class="p16">
            <div class="h30 bg-main-6 rx-sc">
                <div class="title-bar ml10"></div><div class="ml10 fwb">课程信息</div>
            </div>
            <div class="rx-sc mt20">
                <div class="formitem rx-sc">
                    <div class="formtip">选择课程</div>
                    <div class="w220 ml10">
                        <el-select @change="changeCourseName" v-model="pageData.formData.courseNameId" placeholder="请选择课程" @focus="getCoursList">
							<template v-for="(select, index) in pageData.courseNameList" :key="index">
								<el-option v-if="select.state == 1" :label="select.courseName" :value="select.courseNameId"></el-option>
							</template>
                        </el-select>
                    </div>
                    <el-button class="ml16" type="primary" plain @click="addCourse()">新增课程</el-button>
                </div>
                <div class="formitem rx-sc">
                    <div class="formtip ml60">容纳人数</div>
                    <el-input class="formcontent" @input="inputAllPeopleNum" type="text" placeholder="请输入容纳人数" v-model="pageData.formData.allPeopleNum">
                        <template #append>人</template>
                    </el-input>
                </div>
            </div>
            <div class="rx-sc mt20">
                <div class="formitem  rx-sc">
                    <div class="formtip ">单课时长</div>
                    <el-input class="formcontent" @change="inputCourseTime" type="text" placeholder="单课时长为5的倍数" v-model="pageData.formData.courseTime">
                        <template #append>分钟</template>
                    </el-input>
                </div>
            </div>
            <courseSeniorSet class="mt15" ref="seniorSetDot" style="width:100%"></courseSeniorSet>
            <div class="h30 bg-main-6 mt16 rx-sc">
                <div class="title-bar ml10"></div><div class="ml10 fwb">安排课表</div>
            </div>
            <table class="mt20 weektable" cellspacing="0" cellpadding="0">
                    <tr class="bg-eee">
                        <td class="h35">周一</td>
                        <td class="h35">周二</td>
                        <td class="h35">周三</td>
                        <td class="h35">周四</td>
                        <td class="h35">周五</td>
                        <td class="h35">周六</td>
                        <td class="h35">周日</td>
                    </tr>
                    <tr>
                        <td class="h35 c_p" @click="addTime(1)">+</td>
                        <td class="h35" @click="addTime(2)">+</td>
                        <td class="h35" @click="addTime(3)">+</td>
                        <td class="h35" @click="addTime(4)">+</td>
                        <td class="h35" @click="addTime(5)">+</td>
                        <td class="h35" @click="addTime(6)">+</td>
                        <td class="h35" @click="addTime(7)">+</td>
                    </tr>
                    <tr>
                        <td class="timecon">
                            <div class="rx-cc h20" v-for="(item,idx) in pageData.week1" :key="idx">
                                <span>{{item}}</span><Close class="close c_p" @click="removeTime(1,idx)"></Close>
                            </div>
                        </td>
                        <td class="timecon">
                            <div class="rx-cc h20" v-for="(item,idx) in pageData.week2" :key="idx">
                                <span>{{item}}</span><Close class="close c_p" @click="removeTime(2,idx)"></Close>
                            </div>
                        </td>
                        <td class="timecon">
                            <div class="rx-cc h20" v-for="(item,idx) in pageData.week3" :key="idx">
                                <span>{{item}}</span><Close class="close c_p" @click="removeTime(3,idx)"></Close>
                            </div>
                        </td>
                        <td class="timecon">
                            <div class="rx-cc h20" v-for="(item,idx) in pageData.week4" :key="idx">
                                <span>{{item}}</span><Close class="close c_p" @click="removeTime(4,idx)"></Close>
                            </div>
                        </td>
                        <td class="timecon">
                            <div class="rx-cc h20" v-for="(item,idx) in pageData.week5" :key="idx">
                                <span>{{item}}</span><Close class="close c_p" @click="removeTime(5,idx)"></Close>
                            </div>
                        </td>
                        <td class="timecon">
                            <div class="rx-cc h20" v-for="(item,idx) in pageData.week6" :key="idx">
                                <span>{{item}}</span><Close class="close c_p" @click="removeTime(6,idx)"></Close>
                            </div>
                        </td>
                        <td class="timecon">
                            <div class="rx-cc h20" v-for="(item,idx) in pageData.week7" :key="idx">
                                <span>{{item}}</span><Close class="close c_p" @click="removeTime(7,idx)"></Close>
                            </div>
                        </td>
                    </tr>
            </table>
            <div class="ww100 rx-sc mt20">
                <div class="h30 rx-sc">
                    <div class="w80 rx-ec">开始日期</div>
                    <div class="w230">
                        <el-date-picker class="ml10" v-model="pageData.formData.startDate" type="date" placeholder="选择开始日期" format="YYYY-MM-DD" value-format="YYYY-MM-DD"></el-date-picker>
                    </div>
                    <div class="w80 ml60 rx-ec">结束方式</div>
                    <div class="w200 ml10">
                        <el-select v-model="pageData.formData.overType">
                            <el-option label="按次数" value="1"></el-option>
                            <el-option label="按时间" value="2"></el-option>
                        </el-select>
                    </div>
                </div>
            </div>

            <div class=" rx-sc mt20 mb30" >
                <div class="h30 rx-sc" v-show="pageData.formData.overType == '1'">
                    <div class="w80 rx-ec">排课总次数</div>
                    <el-input class="formcontent" @input="inputCycleNum" type="text" placeholder="请输入次数" v-model="pageData.formData.cycleNum">
                        <template #append>次</template>
                    </el-input>
                </div>
                <div class="h30 rx-sc" v-show="pageData.formData.overType == '2'">
                    <div class="w80 rx-ec">结束日期</div>
                    <div class="w230">
                        <el-date-picker class="ml10" v-model="pageData.formData.endDate" type="date" placeholder="选择结束日期" format="YYYY-MM-DD" value-format="YYYY-MM-DD"></el-date-picker>
                    </div>
                </div>
            </div>


            <div class="h30 bg-main-6 mt20 rx-sc">
                <div class="title-bar ml10"></div><div class="ml10 fwb">选择教练</div>
            </div>
            <div class="ww100 h60 mt20 rx-sc">
                <div class="ml25 mr10">授课教练：</div>
                <div class="cx-cc h60 posi-r" @mouseenter="mouseenter(idx)" @mouseleave="mouseleave(idx)" v-for="(item,idx) in pageData.teacherCheckList" :key="idx">
                    <img :src="item.newImg" class="w40 h40" style="border-radius:50px;" />
                    <div class="rx-cc w50 maxLength color-333 ft12">{{item.realName}}</div>
                    <div class="ww100 h40 posi-a top0 left0 rx-cc" v-show="item.hover" style="z-index:2" @click="removeTeacher(item.id)">
                            <Delete class="w20 h20 color-red c_p"></Delete>
                    </div>
                </div>
                <el-popover placement="top" :width="430" trigger="click">
                    <div class="w400 h250 mt10 cx-sc" style="overflow-y:auto">
                        <el-table stripe ref="multipleTable" @selection-change="handleSelectTeacherChange" :data="pageData.teacherList" height="250" class="w380 single-select-table">
                            <el-table-column type="selection" width="55" />
                            <el-table-column prop="realName" label="授课教练" width="100" />
                            <el-table-column label="冲突状态" width="100">
                                <template #default="scope">
                                    <div v-if="scope.row.conflictStatus == 0"><span class="sp-green v_a_m d_i_b mr5"></span>空闲</div>
                                    <div v-if="scope.row.conflictStatus != 0"><span class="sp-red v_a_m d_i_b mr5"></span>冲突</div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="bbb" label="操作">
                                <template #default>
                                    <a class="color-409EFF tool_btn" href="javaScript:;" @click="toTeam">查看课表</a>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <template #reference>
                      <el-button type="primary" class="ml16" @click="getTeacherList">选择教练</el-button>
                    </template>
                </el-popover>
                <el-button class="ml16" @click="addStaff">新增教练</el-button>
            </div>
            <div class="h30 bg-main-6 mt20 rx-sc">
                <div class="title-bar ml10"></div><div class="ml10 fwb">选择场地</div>
            </div>
            <div class="ww100 h60 mt20" :class="pageData.PlaceCheckList[0] ? '' : 'rx-sc'">
                <div>
                    <span class="ml25 mr10">上课场地：</span>
                    <span v-if="pageData.PlaceCheckList.length">{{ pageData.PlaceCheckList[0].roomName }}</span>
                </div>
                <div :class="pageData.PlaceCheckList[0] ? 'mt20' : ''">
                    <el-button type="primary" class="ml16" @click="selectPlace">{{ pageData.PlaceCheckList[0] ? '重选场地' : '选择场地' }}</el-button>
                    <el-button class="ml16" @click="addNewPlace(0)">新增场地</el-button>
                </div>
            </div>
            <div class="h60 rx-sc mt60 ml25">
                <el-button class="w100" @click="goBack">返回</el-button>
                <el-button class="w100" type="primary" @click="sub">提交</el-button>
            </div>
        </div>

        <!-- 弹框 选择时间 -->
        <el-dialog
            v-model="pageData.addweektimepop"
            title="添加排课时间"
            width="500px"
            :before-close="handleClose">
            <div class="ww100 pt30 pb30 cx-cc">
                <div class="ww100 h50 rx-cc">
                    <div class="w100 t_a_r">开始时间：</div>
                    <div class="w120">
                        <el-time-select
                          @change="changeWeekTimeStart"
                          v-model="pageData.temptimestart"
                          class="mr-4"
                          placeholder="开始时间"
                          start="00:00"
                          step="00:05"
                          end="23:55">
                        </el-time-select>
                    </div>
                </div>
                <div class="ww100 h50 rx-cc">
                    <div class="w100 t_a_r">结束时间：</div>
                    <div class="w120">
                        <el-time-select
                          v-model="pageData.temptimeend"
                          :min-time="pageData.temptimestart"
                          placeholder="结束时间"
                          start="00:00"
                          step="00:05"
                          end="23:55">
                        </el-time-select>
                    </div>
                </div>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="pageData.addweektimepop = false">取消</el-button>
                <el-button type="primary" @click="addTimeSub"
                  >确定</el-button
                >
              </span>
            </template>
        </el-dialog>
        <!-- 新增教练 -->
        <el-drawer v-model="pageData.drawer" :before-close="()=>{pageData.drawer = false}">
            <template #title>
              <h4>新增员工</h4>
            </template>
            <template #default>
                <div class="p20">
			    	<CommonTip text="新增员工登录初始默认密码为8个8。"/>
			    	<div class="rx-sc mb12">
			    		<div class="query_tip w80 rx-ec"><span class="color-red">*</span>员工头像：</div>
			    		<div class="rx-sc">
			    			<img class="w65 h65 b_r_100 mr10" :src="pageData.addFormData.headImgUrl" alt="">
			    			<div>
			    				<p>
									<CommonUpload @change="uploadChange" :autoCropWidth="375" :autoCropHeight="375" :isCropper="true">
			    				        <el-button type="primary" plain>{{ pageData.addFormData.headImgUrl == 'https://image.taokatao.cn/imgs/runBusiness/detailPhoto.png' ? '立即上传' : '重新上传' }}</el-button>
			    				    </CommonUpload></p>
			    				<p class="color-999 mt10">建议大小：750*750px</p>
			    			</div>
			    		</div>
			    	</div>
			    	<div class="query_item rx-bc mb20">
			    		<div class="query_tip w80 rx-ec"><span class="color-red">*</span>员工姓名：</div>
			    		<el-input class="w230" v-model="pageData.addFormData.realName" placeholder="输入员工姓名(限20字符)" maxlength="20"/>
			    	</div>
			    	<div class="query_item rx-bc mb20">
			    		<div class="query_tip w80 rx-ec"><span class="color-red">*</span>登录账号：</div>
			    		<el-input class="w230" v-model="pageData.addFormData.logingName" placeholder="输入登录账号" />
			    	</div>
			    	<div class="query_item rx-bc mb20">
			    		<div class="query_tip w80 rx-ec">手机号：</div>
			    		<el-input class="w230" v-model="pageData.addFormData.stallPhone" maxlength="11" placeholder="输入手机号" @input="telInputEvent" />
			    	</div>
			    	<div class="query_item rx-bc mb20">
			    		<div class="query_tip w80 rx-ec">员工类型：</div>
			    		<div class="w230">
			    			<el-select v-model="pageData.addFormData.ifFullTime" placeholder="请选择">
			    				<el-option :value="0" label="未知"></el-option>
			    				<el-option :value="1" label="全职"></el-option>
			    				<el-option :value="2" label="兼职"></el-option>
			    			</el-select>
			    		</div>
			    	</div>
			    	<div class="query_item rx-bc mb20">
			    		<div class="query_tip w80 rx-ec"><span class="color-red">*</span>员工岗位：</div>
			    		<div class="w230">
			    			<el-select multiple collapse-tags v-model="pageData.addFormData.roleId" placeholder="请选择">
			    				<el-option :value="item.encryptionId" :label="item.roleName" v-for="(item, index) in pageData.roleList" :key="index"></el-option>
			    			</el-select>
			    		</div>
			    	</div>
			    	<div class="query_item rx-bc mb20">
			    		<div class="query_tip w80 rx-ec">考勤方式：</div>
			    		<div class="w230">
			    			<el-select v-model="pageData.addFormData.attenType" placeholder="请选择">
			    				<el-option :value="1" label="默认考勤"></el-option>
			    				<el-option :value="2" label="课表考勤"></el-option>
			    				<el-option :value="3" label="指定考勤"></el-option>
			    			</el-select>
			    		</div>
			    	</div>
			    </div>
            </template>
            <template #footer>
                <div style="flex: auto">
                    <el-button @click="pageData.drawer = false">取消</el-button>
                    <el-button type="primary" @click="saveStaff">确定</el-button>
                </div>
            </template>
        </el-drawer>
        <!-- 选择场地 -->
        <el-dialog
            v-model="pageData.placeShow"
            title="选择场地"
            width="800px"
            :before-close="placeClose"
        >
            <template #default>
                <div class="p20">
                    <el-table stripe ref="refPlace" @selection-change="selectPlaceChange" :data="pageData.PlaceList"  height="500" class="single-select-table">
                        <el-table-column type="selection" width="55"  :selectable="(row)=>{return row.conflictStatus == 0}"/>
                        <el-table-column prop="roomName" label="场地名称" />
                        <el-table-column prop="containNum" label="容纳人数" align="right" />
                        <el-table-column label="冲突状态">
                            <template #default="scope">
                                <div v-if="scope.row.conflictStatus == 0"><span class="sp-green v_a_m d_i_b mr5"></span>空闲</div>
                                <div v-if="scope.row.conflictStatus != 0"><span class="sp-red v_a_m d_i_b mr5"></span>冲突</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="bbb" label="冲突信息">
                            <template #default="scope">
                                <a v-if="scope.row.conflictStatus != 0" class="color-409EFF tool_btn t_d_u" href="javaScript:;" @click="toConflict(scope.row)">查看课表</a>
                                <span v-if="scope.row.conflictStatus == 0">查看课表</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <el-dialog
                    v-model="pageData.innerPlaceShow"
                    width="500px"
                    title="冲突详情"
                    append-to-body
                >
                    <div class="p20">
                        <el-table stripe :data="pageData.innerPlaceList"  height="400">
                            <el-table-column type="index" label="序号" width="55" />
                            <el-table-column prop="roomName" label="冲突日期">
                                <template #default="scope">
                                    {{ scope.row.classTimeStartStr }}({{ scope.row.week }})
                                </template>
                            </el-table-column>
                            <el-table-column prop="containNum" label="冲突时间" >
                                <template #default="scope">
                                    {{ scope.row.startTime }}-{{ scope.row.endTime }}
                                </template>
                            </el-table-column>
                            <el-table-column label="冲突原因">
                                <template #default="scope">
                                    <span v-if="scope.row.courseName">{{ scope.row.courseName }}排课冲突</span>
                                    <span v-else>场地被锁定</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-dialog>
            </template>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="placeClose">取消</el-button>
                <el-button type="primary" @click="addPlace">确定</el-button>
              </span>
            </template>
        </el-dialog>
        <Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave"/>
        <!-- 新增场地 -->
        <el-drawer v-model="pageData.placeDrawer" :before-close="()=>{pageData.placeDrawer = false}">
            <template #title>
              <h4>新增场地</h4>
            </template>
            <template #default>
                <div>
			    	<div class="d_f mb20">
			    		<div class="query_tip w100 rx-ec"><span class="color-red">*</span>场地名称：</div>
			    		<el-input class="w230" v-model="pageData.addPlaceFormData.roomName" placeholder="输入场地名称(限10字内)" maxlength="10"/>
                    </div>
                    <div class="d_f mb20">
			    		<div class="query_tip w100">可容纳人数：</div>
			    		<el-input class="w230" v-model.number="pageData.addPlaceFormData.containNum" oninput="value=value.replace(/[^0-9]/g,'')">
                            <template #append>人</template>
                        </el-input>
                    </div>
                    <div class="d_f mb20">
			    		<div class="query_tip w100">备注：</div>
			    		<el-input
                            class="w230"
                            v-model="pageData.addPlaceFormData.remark"
                            :rows="4"
                            type="textarea"
                            placeholder="请输入备注"
                        />
                    </div>
			    </div>
            </template>
            <template #footer>
                <div style="flex: auto">
                    <el-button @click="pageData.placeDrawer = false">取消</el-button>
                    <el-button type="primary" @click="addNewPlace(1)">确定</el-button>
                </div>
            </template>
        </el-drawer>
    </workArea>
</template>
<script>
  export default {
    name: 'BranchCourseInfoAdd'
  }
</script>
<script setup>
    import {ref,reactive,onMounted,onActivated} from 'vue';
    import {onBeforeRouteLeave,onBeforeRouteUpdate,useRouter} from "vue-router";
    import {useStore} from 'vuex'
    import workArea from '@/components/branch-store/workArea.vue';
    import CommonTip from '@/components/CommonTip.vue'
    import { ElMessage ,ElMessageBox} from 'element-plus'
    import {Close,Delete} from '@element-plus/icons-vue';
    import courseSeniorSet from '@/components/branch-store/course/courseSeniorSet.vue';
    import { courseList } from '@/api/course.js';
    import { getTeacherConflictList,courseGroupCycleArrange } from '@/api/courseInfo.js';
    import { roleManageStore, employeeInsert } from '@/api/staff.js';
    import utils from '@/common/utils.js';
    import CommonUpload from '@/components/CommonUpload.vue';
    import Confirm from '@/components/Confirm.vue'
    import { getRoomConflictList, sportRoomInsert } from '@/api/place.js';
    

    const barlist = reactive([{ name : '返回',type:0 },{ name : '新增排课' }]); // 面包屑
    const router    = useRouter();
    const store = useStore();
    const seniorSetDot = ref();
    const multipleTable = ref()
    const ruleFormRef = ref()
    const refPlace = ref()
    const confirmRef =ref()
    const pageData = reactive({
        confirmTitle: '',
        confirmText: '',
        drawer: false, // 新增教练抽屉
        placeShow: false, // 场地弹框
        innerPlaceShow: false, // 冲突场地详情弹框
        innerPlaceList: [], // 冲突场地列表
        PlaceList: [], // 场地列表
        PlaceCheckList: [], // 选中的场地
        placeDrawer: false, // 新增场地抽屉
        addPlaceFormData:{}, // 新增场地表单
        checkteachershow:false,
        addweektimepop : false, // 显示增加时间
        temptimestart : '',
        temptimeend : '',
        tempweeknum : 1,
        courseNameList : [], // 课程列表
        teacherList : [], // 老师列表
        teacherCheckList : [], // 已经选择的教练列表
        week1:[],
        week2:[],
        week3:[],
        week4:[],
        week5:[],
        week6:[],
        week7:[],
        formData : {
            repeatType:3,
            courseType: 11,
            arrangeKind:1,
            courseTime : '',// 单课时长
            courseNameId : '',// 课程id
            allPeopleNum : '',// 容纳人数
            overType:"1",// 结束方式1次数，2日期
            startDate:"",// 开始日期
            endDate:"",// 结束日期
            cycleNum:'',// 排课总次数
            week1:'',
            week2:'',
            week3:'',
            week4:'',
            week5:'',
            week6:'',
            week7:''
        },
        roleList:[],
        addFormData: {
            addFormData:{
			encryptionId:'',
			realName:'',
			logingName:'',
			stallPhone:'',
			ifFullTime:0,
			attenType:1,
			roleId:[],
			headImgUrl:''
		}
        }, // 新增教练表单
    });
    /**
     * 新增场地
     */
    function addNewPlace(val){
        if (!val) {
            pageData.addPlaceFormData = {}
            pageData.placeDrawer = true
        } else {
            if (!pageData.addPlaceFormData.roomName) {
                ElMessage.warning('请输入场地名称')
                return
            }
            let params = {
                roomName: pageData.addPlaceFormData.roomName,
                containNum: pageData.addPlaceFormData.containNum,
                remark: pageData.addPlaceFormData.remark,
                purposeClass: 1,
                purposeOrder: 0
            }
            sportRoomInsert(params).then(res => {
                if (res.code == 0) {
                    ElMessage.success('新增成功')
                    pageData.placeDrawer = false
                }
            })
        }
    }
    /**
     * 选择场地
     */
    function selectPlace() {
        var  paramsRes =  getParams();
        if(!paramsRes['status']){
            ElMessage({
                type: 'warning',
                message: paramsRes['message'],
            })
            return;
        }
        pageData.PlaceCheckList = []
        getRoomConflictList(paramsRes.params).then((res)=>{
            if(res.code == 0){
                pageData.PlaceList = res.data;
                pageData.placeShow = true
            }
        });
    }
    
    /**
     * 选中场地数据
     */
    function selectPlaceChange(val){
        pageData.PlaceCheckList = val
        if (val.length > 1) {
          refPlace.value.clearSelection();
          refPlace.value.toggleRowSelection(val.pop());
        } else {
          pageData.PlaceCheckList = val;
        }
    }
    /**
     * 取消选择场地
     */
    function placeClose() {
        pageData.PlaceCheckList = []
        pageData.placeShow = false
    }
    /**
     * 查看冲突
     */
    function toConflict(row) {
        pageData.innerPlaceList = row.courseList
        pageData.innerPlaceShow = true
    }
    /**
     * 确定选择场地
     */
    function addPlace() {
        if (!pageData.PlaceCheckList.length) {
            ElMessage({
                type: 'warning',
                message: '请选择场地'
            })
            return
        }
        let num = 0
        if (pageData.PlaceCheckList[0].conflictStatus) {
            if (pageData.formData.overType == 1) {
                num = pageData.formData.cycleNum
            } else {
                const weekArr = ["星期日","星期一","星期二","星期三","星期四","星期五","星期六"]
                let allData = getAllData(pageData.formData.startDate, pageData.formData.endDate)
                let arr = [],week1 = [],week2 = [],week3 = [],week4 = [],week5 = [],week6 = [],week7 = [];
                let sum1 = pageData.week1.length ? pageData.week1.length : 0
                let sum2 = pageData.week2.length ? pageData.week2.length : 0
                let sum3 = pageData.week3.length ? pageData.week3.length : 0
                let sum4 = pageData.week4.length ? pageData.week4.length : 0
                let sum5 = pageData.week5.length ? pageData.week5.length : 0
                let sum6 = pageData.week6.length ? pageData.week6.length : 0
                let sum7 = pageData.week7.length ? pageData.week7.length : 0
                let day1=0,day2=0,day3=0,day4=0,day5=0,day6=0,day7=0;
                allData.forEach((item) => {
                    console.log(item)
                    let getWeek = new Date(item).getDay()
                    arr.push(weekArr[getWeek])
                })
                arr.forEach((item) => {
                    switch(item) {
		                case '星期一':
    		            	week1.push(item)
		                    break;
                        case '星期二':
    		            	week2.push(item)
		                    break;
                        case '星期三':
    		            	week3.push(item)
		                    break;
                        case '星期四':
    		            	week4.push(item)
		                    break;
                        case '星期五':
    		            	week5.push(item)
		                    break;
                        case '星期六':
    		            	week6.push(item)
		                    break;
                        case '星期日':
    		            	week7.push(item)
		                    break;
                    }
                })
                
                if (sum1) {
                    day1 = sum1*week1.length
                }
                if (sum2) {
                    day2 = sum2*week2.length
                }
                if (sum3) {
                    day3 = sum3*week3.length
                }
                if (sum4) {
                    day4 = sum4*week4.length
                }
                if (sum5) {
                    day5 = sum5*week5.length
                }
                if (sum6) {
                    day6 = sum6*week6.length
                }
                if (sum7) {
                    day7 = sum7*week7.length
                }
                num = day1+day2+day3+day4+day5+day6+day7
            }
            pageData.confirmTitle = '是否确定选择改场地'
            pageData.confirmText = '共排课<span class="color-red"> '+num+' </span>节课，冲突<span class="color-red"> '+pageData.PlaceCheckList[0].courseList.length+' </span>节';
            confirmRef.value.show();
        } else {
            pageData.placeShow = false
        }
    }
    /**
     * 获取两个日期之间所有的日期数据
     */
    function getAllData(startTime, endTime){
        let start = new Date(startTime)
        let end = new Date(endTime)
        const resultTime = []
        // 当 开始时间小于结束时间的时候进入循环
        while (start <= end) {
            let getDay = start.getDate()
            // 月份需要加 1
            let getMonth = start.getMonth() + 1
            let getYear = start.getFullYear()
            /**
             * 拼接时间格式
             * (getMonth >= 10 ? `${getMonth}` : `0${getMonth}`) 自动给 小于10的时间前面补0
             */
            let setTime =
                `${getYear}-` +
                (getMonth >= 10 ? `${getMonth}` : `0${getMonth}`) +
                '-' +
                (getDay >= 10 ? `${getDay}` : `0${getDay}`)

            resultTime.push(setTime)
            /**
             * 重新设置开始时间
             * 使用 setFullYear() 方法会自动将时间累加，返回的是时间戳格式
             * 使用 new Date() 将时间重新设置为标准时间
             * getMonth - 1 将月份时间重新还原
             */
            start = new Date(start.setFullYear(getYear, getMonth - 1, getDay + 1))
        }
        return resultTime
    }
    /**
     * 坚持选择冲突场地
     */
    function confirmSave() {
        pageData.placeShow = false
    }
    // 新增教练弹出窗
	function addStaff(){
		//岗位搜索下拉框
		roleManageStore({page: 1, limit: 9999}).then((res)=>{
			if(res.code == 0){
				pageData.roleList = res.data;
			}
		})
		pageData.addFormData.encryptionId = '';
		pageData.addFormData.realName = '';
		pageData.addFormData.logingName = '';
		pageData.addFormData.stallPhone = '';
		pageData.addFormData.ifFullTime = 0;
		pageData.addFormData.attenType = 1;
		pageData.addFormData.roleId = []
        pageData.addFormData.headImgUrl = 'https://image.taokatao.cn/imgs/runBusiness/detailPhoto.png'
		pageData.drawer = true;
	}
    //新增员工保存
	function saveStaff(){
		var formData = JSON.parse(JSON.stringify(pageData.addFormData));
		if(!formData.realName){
			ElMessage({
				type: 'warning',
				message: '请输入员工名称',
			})
			return false;
		}
		if(!formData.realName){
			ElMessage({
				type: 'warning',
				message: '请输入员工名称',
			})
			return false;
		}
		if(!formData.logingName){
			ElMessage({
				type: 'warning',
				message: '请输入登录账号',
			})
			return false;
		}
		var storeId = [];
		pageData.roleList.map((n)=>{
			if(formData.roleId.indexOf(n.id) > -1){
				storeId.push(n.sid);
			}
		})
		formData.roleId = formData.roleId.join(',');
		formData.storeId = storeId.join(',');
		employeeInsert(formData).then((res)=>{
			if(res.code == 0){
                ElMessage({
			    	type: 'success',
			    	message: '新增成功',
			    })
				pageData.drawer = false;
				
			}
		})
	}
	//上传封面
	function uploadChange(url){
		pageData.addFormData.headImgUrl = url;
	}
    //手机号输入验证
	var telVal = '';
	function telInputEvent(val){
		if(isNaN(Number(val))){
			pageData.addFormData.stallPhone = telVal;
		}else{
			telVal = val;
		}
	}
    /**
     * 查看课表
     */
    function toTeam(){
        window.open('/#/branch/courseTable', '_blank')
    }
    // 切换选择课程名称
    function changeCourseName(val){
        var courseNameList = pageData.courseNameList;
        for(var i=0;i<courseNameList.length;i++){
            if(courseNameList[i]['courseNameId'] == val){
                // 开始赋值
                var courseTime = courseNameList[i]['courseTime'];
                pageData.formData.courseTime = courseTime;
                // 计算
                var timeStart = pageData['temptimestart'];
                if(timeStart){
                    var hour = parseInt(timeStart.split(':')[0]);
                    var min  = parseInt(timeStart.split(':')[1]);
                    min = min + parseInt(courseTime);
                    hour = hour + parseInt(min / 60) + '';
                    min = min % 60 + '';
                    hour = hour.length == 1 ? '0'+ hour : hour;
                    min = min.length == 1 ? '0'+ min : min;
                    if (hour > 23) {
                        pageData.temptimeend = ''
                    } else if(min > 55) {
                        pageData.temptimeend = ''
                    } else {
                        pageData.temptimeend = hour + ':' + min;
                    }
                }
            }
        }
    }
    // 修改开始时间
    function changeWeekTimeStart(timeStart){
        if(timeStart){
            var hour = parseInt(timeStart.split(':')[0]);
            var min  = parseInt(timeStart.split(':')[1]);
            min = min + parseInt(pageData.formData.courseTime || '45');
            hour = hour + parseInt(min / 60) + '';
            min = min % 60 + '';
            hour = hour.length == 1 ? '0'+hour : hour;
            min = min.length == 1? '0'+min : min;
            if (hour > 23) {
                pageData.temptimeend = ''
            } else if(min > 55) {
                pageData.temptimeend = ''
            } else {
                pageData.temptimeend = hour + ':' + min;
            }
        }
    }
    // 鼠标进入

    function mouseenter(idx){
        pageData.teacherCheckList[idx]['hover'] = true;
    }
    // 鼠标离开
    function mouseleave(idx){
        pageData.teacherCheckList[idx]['hover'] = false;
    }
    function addTime(num){
        pageData.tempweeknum = num;
        pageData.addweektimepop = true;
    }
    function addTimeSub(){
        pageData.addweektimepop = false;
        // 添加数据
        var weektimeArrayKey = 'week'+pageData.tempweeknum;
        pageData[weektimeArrayKey].push(pageData.temptimestart+'-'+pageData.temptimeend);
    }
    function removeTeacher(id){
        var teacherList = pageData.teacherCheckList || [];
        var teacherTempList = [];
        for(var i=0;i<teacherList.length;i++){
            if(teacherList[i]['id'] != id){
                teacherTempList.push(teacherList[i]);
            }
        }
        pageData.teacherCheckList = teacherTempList;
    }
    function handleSelectTeacherChange(val){
        if (val.length > 1) {
          multipleTable.value.clearSelection();
          multipleTable.value.toggleRowSelection(val.pop());
        } else {
          pageData.teacherCheckList = val;
        }
    }
    function removeTime(num,idx){
        var weektimeArrayKey = 'week'+num;
        var timeArray = pageData[weektimeArrayKey];
        var timeTempArray = [];
        for(var i=0;i<timeArray.length;i++){
            if(i != idx){
                timeTempArray.push(timeArray[i]);
            }
        }
        pageData[weektimeArrayKey] = timeTempArray;
    }
    
    function getCoursList() {
        getCourseNameList()
    }
    // 查询待排课程列表
    function getCourseNameList(){
        courseList({courseType:11}).then((res)=>{
            if(res.code == 0){
                pageData.courseNameList = res.data;
            }
        });
    }
    function getSubParams(){
        var paramsRes = getParams();
        if(!paramsRes.status){
            return paramsRes;
        }
        // 添加教练id
        var courseTeacherArray = [];
        var teacherCheckList = pageData.teacherCheckList;
        if(teacherCheckList.length < 1){
            return {
                status : false,
                message : '请选择教练'
            }
        }
        for(var i=0;i<teacherCheckList.length;i++){
            courseTeacherArray.push(teacherCheckList[i]['id']);
        }
        paramsRes.params['courseTeacher'] = courseTeacherArray.join(',');
        return paramsRes;
    }
    // 获取表单内容
    function getParams(){
        var status = true; // 状态
        var message = '';  // 提示信息
        var formData = Object.assign({},pageData.formData);
         formData = utils.trimObject(formData)
        for(var i=1;i<8;i++){
            formData['week'+i] = pageData['week'+i].join(',');
        }
        // 高级设置
        var seniorSet = seniorSetDot.value.getSeniorSet();
        Object.assign(formData,seniorSet);
        // 验证高级设置
        // 验证参数
        if(!formData['courseNameId']){
            status = false;
            message= '请先选择课程';
        }
        if(status && formData['allPeopleNum'] < 1){
            status = false;
            message= '请输入容纳人数';
        }
        if(status && formData['courseTime'] < 1){
            status = false;
            message= '请输入单课时长';
        }
        var hasWeekTime = false;
        for(var i=1;i<8;i++){
            if(formData['week'+i].length > 0){
                hasWeekTime = true;
            }
        }
        // 判断高级设置
        if(status && formData['ifOpenBook'] == 1){
            if(!formData['bookingStartRule'] && formData['bookingStartRule'] != 0){
                status = false;
                message = '预约开始时间不能为空';
            }
            if(!formData['bookingEndRule'] && formData['bookingEndRule'] != 0){
                status = false;
                message = '预约截止时间不能为空';
            }
            if(parseInt(formData['bookingEndRule']) > parseInt(formData['bookingStartRule'])){
                status = false;
                message = '预约截止时间不能早于开始时间';
            }
            // 当不开放预约时，下面全不展示，所以放在开放预约里面
            if(status && formData['ifFull'] == 1){
                console.log(formData['fullNum'])
                console.log(!formData['fullNum'])
                console.log( formData['fullNum'] != 0)
                if(!formData['fullEndRule'] && formData['fullEndRule'] !=0){
                    status = false;
                    message = '满员截止时间不能为空';
                }
                if(!formData['fullNum'] && formData['fullNum'] != 0){
                    status = false;
                    message = '满员人数不能为空';
                }
            }
            if(status && formData['ifCancelBook'] == 1){
                if(formData['ifFull'] == 1 && !formData['fullCancelNum']){
                    status = false;
                    message = '满员取消人数不能为空';
                }
                if(!formData['cancelEndRule'] && formData['cancelEndRule'] != 0){
                    status = false;
                    message = '取消截止时间不能为空';
                }
            }
            if(status && formData['ifAfter'] == 1){
                if(!formData['afterNum'] && formData['afterNum'] != 0){
                    status = false;
                    message = '候补人数不能为空';
                }
            }
        }
        if(status && formData['ifBook'] == 1){
            if(!formData['autoConsumeRule'] && formData['autoConsumeRule'] != 0){
                status = false;
                message = '自动签到时间不能为空';
            }
        }
        if(status && !hasWeekTime){
            status = false;
            message= '请选择排课时间';
        }
        if(status && !formData['startDate']){
            status = false;
            message = '请输入开始日期';
        }
        if(status && formData['overType'] == '1' && formData['cycleNum'] < 1){
            status = false;
            message = '请输入排课次数';
        }
        if(status && formData['overType'] == '2' && !formData['endDate']){
            status = false;
            message = '请选择结束日期';
        }
        return {
            status : status,
            message : message,
            params : formData
        }
    }
    // 查询教练列表
    function getTeacherList(){
        var  paramsRes =  getParams();
        if(!paramsRes['status']){
            ElMessage({
                type: 'warning',
                message: paramsRes['message'],
            })
            return;
        }
        pageData.teacherList = [];
        getTeacherConflictList(paramsRes.params).then((res)=>{
            if(res.code == 0){
                var teacherList = res.data;
                var teacherCheckList = pageData.teacherCheckList;
                for(var i=0;i<teacherCheckList.length;i++){
                    for(var j=0;j<teacherList.length;j++){
                        if(teacherList[j]['id'] = teacherCheckList[i]['id']){
                            teacherList[j]['select'] = true;
                        }
                    }
                }
                pageData.teacherList = teacherList;
            }
        });
    }
    // 容纳人数只输数字
    function inputAllPeopleNum(val){
        pageData.formData.allPeopleNum = val.replace(/\D/g, '');
    }
    function inputCycleNum(val){
        pageData.formData.cycleNum = val.replace(/\D/g, '');
    }
    // 输入单课时长
    function inputCourseTime(val){
        var courseTime = parseInt(val.replace(/\D/g, '') || '0');
        if(courseTime > 0){
            if(courseTime % 5 > 0){
                courseTime = parseInt(courseTime / 5 ) * 5;
            }
        }
        pageData.formData.courseTime = courseTime;
    }
    function sub(event,ifRepeat){
        ifRepeat = ifRepeat || 0;
        var paramsRes = getSubParams();
        if(!paramsRes.status){
            ElMessage({
                type: 'warning',
                message: paramsRes['message'],
            })
            return;
        }
        paramsRes.params['ifRepeat'] = ifRepeat;
        paramsRes.params['classroomId'] = pageData.PlaceCheckList.length ? pageData.PlaceCheckList[0].id : ''
        courseGroupCycleArrange(paramsRes.params).then((res)=>{
            if(res.code == 0){
                if(res.results && res.results.errorType && res.results.errorType == "2"){
                    ElMessageBox.confirm(
                        '该时间段老师课程冲突，是否继续排课？',
                        '提示信息',
                        {
                          confirmButtonText: '确定',
                          cancelButtonText: '取消',
                          type: 'warning',
                        }).then(() => {
                          // 再次发起排课
                          sub(null,1);
                    }).catch(() => {
                        // 取消
                    })
                }else{
                    ElMessage({
                        type: 'success',
                        message: "排课成功",
                    })
                     history.go(-1) 
                }
            }
        });
        // var seniorSet = seniorSetDot.value.getSeniorSet();
        // console.log('set',set.ifOpenBook);
    }
    function goBack() {
        history.go(-1) 
    }
    function addCourse(){
        router.push({path:'/branch/addUpdateCourse'});
    }

    // 页面缓存
    onBeforeRouteLeave((to, from)=>{
        if (to.path === '/branch/addUpdateCourse') {
            store.commit('SetKeep',{keepLiveRoute : ['BranchCourseInfoAdd']});
        } else {
            store.commit('SetKeep',{keepLiveRoute : []});
        }
    })
</script>

<style scoped>
    .sp-green{
        height:5px;width:5px;background-color:#00ee00;border-radius:10px;
    }
    .sp-red{
        height:5px;width:5px;background-color:#ee0000;border-radius:10px;
    }
    .close{
        width:15px;
        height:15px;
        margin-left:10px;
    }
    .close:hover{
        color :#e00;
    }
    .weektable{
        width:100%;
        border-top:1px solid #ddd;
        border-left:1px solid #ddd;
    }
    .weektable td{
        width:14.2857%;
        overflow: hidden;
        border-right:1px solid #ddd;
        border-bottom:1px solid #ddd;
        text-align: center;
    }
    .timecon{
        padding-top: 18px;
        padding-bottom: 18px;
    }
    
    .single-select-table /deep/ thead .el-table-column--selection .cell{
        display: none;
    }
    .single-select-table /deep/ .el-checkbox__inner { width: 16px; height: 16px; border-radius: 50%; }
    .single-select-table /deep/ .el-checkbox__inner::after{
        height: 8px;
        left: 5px;
    }

</style>







