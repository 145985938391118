<template>
	<workArea :barlist="pageData.barlist">
		<div class="p20 posi-r">
			<img class="ww100" src="@/assets/payCourseMoney.png" alt="">
            <div class="form ww70 t_a_c">
                <div class="head ww80">
                    <div class="head_top rx-bc">
                        <div>
                            <span>订单号:{{ info.batchCode }}</span>
                        </div>
                        <div>
                            <span>姓名:{{ info.realName }}</span>
                        </div>
                        <div>
                            <span>手机号:{{ info.telephoneNum }}</span>
                        </div>
                    </div>
                    <div class="head_footer mt20">
                        <span>金额：￥{{ info.actualMoney }}</span>
                    </div>
                </div>
                <div class="typeList ww80">
                    <div class="ww80 hh100 rx-bc typeList_content">
                        <div class="pl10 pt2 pr10 pb2" :class="pageData.radio == 1 ? 'active' : 'noActive'">
                            <el-radio v-model="pageData.radio" :label="1">扫码枪/小白盒</el-radio>
                        </div>
                        <div class="pl10 pt2 pr10 pb2" :class="pageData.radio == 2 ? 'active' : 'noActive'">
                            <el-radio v-model="pageData.radio" :label="2" @change="changeRadio">订单收款码</el-radio>
                        </div>
                    </div>
                </div>
                <div v-show="pageData.radio == 1 && !pageData.success" class="ww80 tabType">
                    <div class="tabType_content">
                        <div class="rx-cc">
                            <img class="w160 h160" src="@/assets/saoma.png" alt="">
                            <span class="mr35 ml35">或</span>
                            <img class="h160 w160" src="@/assets/baihe.png" alt="">
                        </div>
                        <div class="mt25 ft16 tabTxt rx-cc">
                            将扫码枪/小白盒连接电脑USB接口，扫会员付款码进行收费
                            <span class="tips">自动确认到账</span>
                        </div>
                        <div class="mt35 tabNumber">
                            <el-form :model="pageData.form"  @submit.prevent>
                                <el-input class="w360 h48" ref="payInput" v-model="pageData.form.input" @keyup="validateNum(pageData.form.input)" autofocus="autofocus" />
                                <span class="v_a_m">
				 	            	<el-tooltip placement="right-end">
                                        <template #content> 在微信/支付宝付款页面<br />点条形码查看付款码 </template>
				 	            	    <img class="w15 h15 ml5" src="@/assets/wenhao.png" >
				 	            	</el-tooltip> 
				 	            </span>
                            </el-form>
                        </div>
                        <el-button class="mt30 mb30" type="primary" @click="launchPay(pageData.form.input)">确 定</el-button>
                    </div>
                </div>
                <div v-show="pageData.radio == 2 && !pageData.success" class="ww80 tabType">
                    <div class="mt25 ft16 tabTxt rx-cc">
                        会员打开支付宝或微信，扫一扫支付费用 
                        <span class="tips">自动确认到账</span>
                    </div>
                    <div class="payment">
                        <div id="qrcode" class="login_top_code" v-loading="pageData.codeLoading"></div>
                        <div v-show="pageData.timer2 == 0" class="failImg">
                            <img src="@/assets/branch/cuowu.png" alt="">
                            <span>订单已过期</span>
                            <span>请重新下单</span>
                        </div>
                        <div v-show="pageData.timer2 != 0 && pageData.timer1 == 0" class="statImg">
                            <img src="@/assets/branch/flush.png" alt="">
                            <span>二维码已过期</span>
                            <span>请重新  <a class="c_u" @click="changeRadio">刷新</a></span>
                        </div>
                    </div>
                </div>
                <div v-if="pageData.success" class="countTime ww80">
                    <div class="cx-cc">
                        <img class="w85 mb20" src="@/assets/successPay.png" alt="">
                        <span class="ft24 color-333">支付成功</span>
                        <p class="mt60 mb20"><span style="color:#E60012;font-size: 16px">{{ pageData.timer }}</span>
                            s后自动跳转
                        </p>
                    </div>
                </div>
            </div>
		</div>
	</workArea>
</template>

<script setup>
    import { ref ,reactive, onBeforeUnmount} from 'vue';
    import { useRoute, useRouter } from 'vue-router'
    import QRCode from 'qrcodejs2'
    import { ElMessage } from 'element-plus'
    import workArea from '@/components/branch-store/workArea.vue'
    import { macScanCode, selectOnePayOrderById } from '@/api/pay.js'
    import { getUid } from '@/api/login.js'
    import { getLocalStorage } from '@/common/common.js';
    const route = useRoute();
    const router = useRouter();
    const info = JSON.parse(decodeURIComponent(route.query.info))
    const payInput = ref()
    const pageData = reactive({
    	barlist:[{ name : '首页' },{ name : '收款码' }],
        radio: 1,
        codeLoading: false,
        success: false,
        code: '',
        lastTime: '',
        nextTime: '',
        lastCode: '',
        nextCode: '',
        timer: 3,
        timer1: 60,
        timer2: 300,
        authTimer: null,
        authTimer1: null,
        authTimer2: null,
        authTimer3: null,
        authTimer4: null,
        form:{
            input: ''
        }
    })
    function parseQRCode(code) {
        if (code.length === 18) {
            launchPay(code)
        } else if (code.length === 23) {
            console.log('B类条码:' + code)
        } else if (code.length === 0) {
            console.log('请输入条码！')
        }
        pageData.form.input = code.replace(/\s/g, '').replace(/[^\d]/g, '').replace(/(\d{4})(?=\d)/g, '$1 ')
    // 发送网络请求
    }
    window.document.onkeypress = (e) => {
        if (window.event) { // IE
            pageData.nextCode = e.keyCode
        } else if (e.which) { // Netscape/Firefox/Opera
            pageData.nextCode = e.which
        }
        if (e.which === 13) { // 键盘回车事件
            if (pageData.code.length < 3) return // 扫码枪的速度很快，手动输入的时间不会让code的长度大于2，所以这里不会对扫码枪有效
                parseQRCode(pageData.code) // 获取到扫码枪输入的内容，做别的操作
                pageData.lastCode = ''
                pageData.lastTime = ''
                return
        }
    
        pageData.nextTime = new Date().getTime()
        if (!pageData.lastTime && !pageData.lastCode) {
            pageData.code = '' // 清空上次的条形码
            pageData.code += e.key
        }
        if (pageData.lastCode && pageData.lastTime && pageData.nextTime - pageData.lastTime > 500) { // 当扫码前有keypress事件时,防止首字缺失
            pageData.code = e.key
        } else if (pageData.lastCode && pageData.lastTime) {
            pageData.code += e.key
        }
        pageData.lastCode = pageData.nextCode
        pageData.lastTime = pageData.nextTime
    }
    /**
     * 输入框每四位空格
     */

    function validateNum(val) {
        pageData.form.input = ''
        pageData.form.input = val.replace(/\s/g, '').replace(/[^\d]/g, '').replace(/(\d{4})(?=\d)/g, '$1 ')
    }

    /**
     * 扫码枪发起支付
     */
    function launchPay(code) {
        code = code.replace(/ /g,'')
        if (code.length == 0) {
            ElMessage({
				message: '请输入支付码',
				type: 'warning'
			})
            return
        }
        if (code.length < 18) {
            ElMessage({
				message: '请输入正确的支付码',
				type: 'warning'
			})
            return
        }
        
        let params = {
            encryptionBatchId: info.encryptionBatchId,
            authCode: code
        }
        macScanCode(params).then((res) => {
            if (res.code == 0) {
                pageData.success = true
                pageData.authTimer = setInterval(() => {
      	            pageData.timer--
      	            if (pageData.timer <= 0) {
                        if (route.query.from == 1) { // 跳转前台
                            router.push('/branch/reception')
                        }
                        if (route.query.from == 2) { // 跳转正式会员列表
                            router.push('/branch/memberList')
                        }
                        if (route.query.from == 3) { // 跳转会员详情
                            router.push('/branch/memberDetail?encryptionId='+ route.query.id)
                        }
                        if (route.query.from == 4) { // 跳转销售记录
                            router.push('branch/sale')
                        }
                        if (route.query.from == 5) { // 跳转销售记录详情
                            router.push('/branch/orderDetail?encryptionId='+ route.query.id)
                        }
                        if (route.query.from == 6) { // 跳转场地订单
                            router.push('/branch/sale')
                        }
                        if (route.query.from == 7) { // 场地代预约
                            router.push('/branch/agentOrder')
                        }
                        if (route.query.from == 8) { // 会员课程
                            router.push('/branch/memberList')
                            localStorage.setItem('memberList', '2')
                        }
      	            }
                }, 1000)
            }
        })
    }
    /**
     * 切换支付方式
     */
    function changeRadio() {
        pageData.codeLoading = true;
        pageData.timer1 = 60
		getUUid()
    }
    const uuid = ref('');
	//获取扫码登录唯一key
	function getUUid(){
		document.getElementById('qrcode').innerHTML = '';
		getUid({}).then((res)=>{
			if(res.code == 0){
				uuid.value = res.data;
				createQrCode(res.data);
			}
		})
	}

    /**
     * 查询订单状态
     */
    getOrderStatus()
    function getOrderStatus() {
        clearInterval(pageData.authTimer1)
        pageData.authTimer1 = setInterval(() => {
            if (pageData.timer2 <= 0) {
                pageData.timer2 = 0
      	        clearInterval(pageData.authTimer1)
      	    }
            if (pageData.timer1 <= 0) {
                 pageData.timer1 = 0
      	        clearInterval(pageData.authTimer1)
            }
            selectOnePayOrderById({ encryptionBatchId: info.encryptionBatchId }).then((res) => {
                if ( res.code == 0) {
                    if (res.data.status == 2) {
                        pageData.success = true
                        clearInterval(pageData.authTimer2)
                        pageData.authTimer2 = setInterval(() => {
      	                    pageData.timer--
      	                    if (pageData.timer <= 0) {
                                if (route.query.from == 1) { // 跳转前台
                                    router.push('/branch/reception')
                                }
                                if (route.query.from == 2) { // 跳转正式会员列表
                                    router.push('/branch/memberList')
                                }
                                if (route.query.from == 3) { // 跳转会员详情
                                    router.push('/branch/memberDetail?encryptionId='+ route.query.id)
                                }
                                if (route.query.from == 4) { // 跳转销售记录
                                    router.push('/branch/sale')
                                }
                                if (route.query.from == 5) { // 跳转销售记录详情
                                    router.push('/branch/orderDetail?encryptionId='+ route.query.id)
                                }
                                if (route.query.from == 6) { // 跳转场地订单
                                    router.push('/branch/sale')
                                }
                                if (route.query.from == 7) { // 场地代预约
                                    router.push('/branch/agentOrder')
                                }
                                if (route.query.from == 8) { // 会员课程
                                    router.push('/branch/memberList')
                                    localStorage.setItem('memberList', '2')
                                }
      	                    }
                        }, 1000)
                    }
                    
                }
            })
        }, 3000)
    }
    

    //生成二维码
	function createQrCode(uid){
        const token = getLocalStorage('businessToken');
		new QRCode('qrcode', {
		  width: 157,
		  height: 157, // 高度
		  text: location.origin +'/h5/payMoney.html?allMoney='+ encodeURIComponent(info.actualMoney) +'&orderBatchId=' + encodeURIComponent(info.encryptionBatchId) +'&coodie=' + encodeURIComponent(token),
		  background: '#fff'
		})
		pageData.codeLoading = false;
        clearInterval(pageData.authTimer3)
        pageData.authTimer3 = setInterval(() => {
      	    pageData.timer1--
      	    if (pageData.timer1 <= 0) {
                pageData.timer1 = 0
                clearInterval(pageData.authTimer3)
      	    }
        }, 1000)
        clearInterval(pageData.authTimer4)
        pageData.authTimer4 = setInterval(() => {
      	    pageData.timer2--
      	    if (pageData.timer2 <= 0) {
                pageData.timer1 = 60
                pageData.timer2 = 0
      	        clearInterval(pageData.authTimer4)
      	    }
        }, 1000)
        getOrderStatus()
        
	}
    onBeforeUnmount(() => {
        clearInterval(pageData.authTimer)
        clearInterval(pageData.authTimer1)
        clearInterval(pageData.authTimer2)
        clearInterval(pageData.authTimer3)
        clearInterval(pageData.authTimer4)
        pageData.authTimer  = null
        pageData.authTimer1  = null
        pageData.authTimer2  = null
        pageData.authTimer3  = null
        pageData.authTimer4  = null
    })
</script>
<style scoped>
    .form {
        background: rgba(255,255,255,1);
        box-shadow: 0px 0px 63px 5px rgb(177 177 177 / 35%);
        border-radius: 10px;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        margin: -9% auto;
    }
    .head {
        font-weight: bold;
        margin: auto;
        margin-top: 30px;
    }
    .head_top span {
        color: rgba(51,51,51,1);
        font-size: 16px;
    }
    .head_footer span{
        color: #E60012;
        font-size: 24px;
    }
    .typeList{
        background: rgba(240,245,249,1);
        border-radius: 40px;
        margin: 32px auto;
        height: 80px;
    }
    .typeList_content, .tabType, .countTime {
        margin: auto;
    }
    .typeList_content>div{
        background: rgba(255,255,255,1);
        border: 1px solid rgba(214,214,214,1);
    }
    .typeList_content .active {
        background: #409eff;
        border: 1px solid #409eff;
        
    }
    .typeList_content /deep/ .active .el-radio__input.is-checked+.el-radio__label{
        color: #FFF;
    }
    .typeList_content /deep/ .active .el-radio__input.is-checked .el-radio__inner{
        border-color: #FFF;
    }
    .typeList_content /deep/ .active .el-radio__inner::after{
        width: 6px;
        height: 6px;
    }
    .tabTxt {
        color: rgba(51,51,51,1);
    }
    .tips {
        display: block;
        width: 94px;
        height: 22px;
        background: rgba(255,234,197,1);
        border-radius: 14px 0px 14px 0px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(235,72,85,1);
        line-height: 22px;
        text-align: center;
        margin-left: 14px;
    }
    .tabNumber /deep/ .el-input__inner {
        height: 48px !important;
        border-radius: 4px;
        font-size: 18px;
        text-indent: 20px;
    }
    .payment {
        height: 172px;
        width: 172px;
        margin: 30px auto;
        background: #ffffff;
        border-radius: 4px;
        border: 6px solid rgba(210,231,247,1);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .statImg,.failImg{
        background: rgba(51,51,51,0.9);
        width: 157px;
        height: 157px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .statImg img,.failImg img{
        width: 40px;
        height: 40px;
        margin-bottom: 10px;
    }
    .statImg span,.failImg span{
        font-size:14px;
        font-family:Microsoft YaHei;
        font-weight:400;
        color:rgba(255,255,255,1);
    }
    .statImg a{
        color: #00A3FF;
        cursor: pointer;
    }
</style>