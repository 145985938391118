<template>
	<div v-if="state == 0">
		<p class="t_a_c pt60 pb60 ft16">正在加载...</p>
		<div class="rx-cc">
			<!-- <img class="ww70 a_t" src="../assets/no_data_tip.png" alt=""> -->
		</div>
	</div>
	<div v-if="state == 1">
		<p class="t_a_c pt60 pb60 ft16">该账号下未绑定店铺</p>
		<div class="rx-cc">
			<!-- <img class="ww70 a_t" src="../assets/no_data_tip.png" alt=""> -->
		</div>
	</div>
	<div v-if="state == 2">
		<div class="rx-cc pt50 pb20">
			<!-- <img class="ww30" src="../assets/success.png" > -->
		</div>
		<h3 class="t_a_c ft16 mb10 color-333">授权成功</h3>
		<p class="t_a_c ft14 color-333">请在电脑上选择您要进入的门店</p>
	</div>
</template>

<script setup>
	import {ref} from 'vue'
	import axios from 'axios'
	import {useRoute} from 'vue-router'
	import { ElMessage } from 'element-plus'
	const route = useRoute();
	const state = ref(0);
	let uuid = route.query.uuid;
	var dataString = route.query.dataString;
	var resData = JSON.parse(dataString);
	var  openid= resData.openid;
	if(openid){
		loginByOpenid(openid)
	}else{
		console.log('授权失败，未获取到openid');
	}
	
	function loginByOpenid(openid){
		axios({
		  url:'/api/nologin/loginByOpenidPre?uuid='+uuid+'&openid='+openid+'&version=2&graycode=keep4',
		  method:'post',
		  data: {}
		}).then(function(res) {
			if(res.data.code == 0){
				state.value = 2;
			}else{
				ElMessage({
					message:res.data.msg,
					type: 'warning'
				})
			}
		});
	}
</script>

<style scoped lang="stylus">
	
</style>
