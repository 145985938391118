import {postAjax,getAjax}  from "../common/axios.js"

// 审核设置-查询
export const getConfig = params => {
    return postAjax('/api/cashier/tallySetting/queryConfig',params);
}

// 审核设置-更新
export const setConfig = params => {
    return postAjax('/api/cashier/tallySetting/update',params);
}

// 费用类型-列表
export const tallyTypeLsit = params => {
    return postAjax('/api/cashier/tallyType/list',params);
}

// 费用类型-修改
export const tallyTypeUpdate = params => {
    return postAjax('/api/cashier/tallyType/update',params);
}

// 费用类型-新增
export const tallyTypeAdd = params => {
    return postAjax('/api/cashier/tallyType/insert',params);
}

// 费用类型-删除
export const tallyTypeDelete = params => {
    return postAjax('/api/cashier/tallyType/delete',params);
}

// 收支明细-新增
export const tallyAdd = params => {
    return postAjax('/api/cashier/tally/insert',params);
}

// 收支明细-列表
export const tallyList = params => {
    return postAjax('/api/cashier/tally/list',params);
}

// 收支明细-删除
export const tallyDelete = params => {
    return postAjax('/api/cashier/tally/delete',params);
}

// 收支明细-修改
export const tallyUpdate = params => {
    return postAjax('/api/cashier/tally/update',params);
}

// 收支明细-审核
export const tallyCheck = params => {
    return postAjax('/api/cashier/tally/check',params);
}

// 收支明细-图表数据
export const chart = params => {
    return postAjax('/api/cashier/tally/querySummaryChart',params);
}

// 收支明细-饼图详细
export const pieDetail = params => {
    return postAjax('/api/cashier/tally/queryChartDetail',params);
}

// 销售记录列表
export const queryAllSaleList = params => {
    return postAjax('/api/order/product/queryAllSaleList',params);
}

// 销售记录详情
export const querySaleInfo = params => {
    return postAjax('/api/order/product/querySaleInfo',params);
}

// 取消订单
export const closeTrans = params => {
    return postAjax('/api/upay/wap/closeTrans',params);
}

// 收银台-收退款列表查询
export const upayOrderLog = params => {
    return postAjax('/api/upayOrderLog/list',params);
}

// 收银台-到账记录
export const upayOrderSettleDetail = params => {
    return postAjax('/api/upayOrderSettleDetail/list',params);
}
