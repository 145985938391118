<template>
    <workArea :barlist="barlist">
        <div class="p16">
            <div class="rx-sc mt20">
                <div class=" rx-sc">
                    <div class="formtip"><span class="color-red">*</span>班级名称：</div>
                    <div class="w220 ml10">
                        <el-select @change="changeCourseName" v-model="pageData.formData.encryptionId" placeholder="请选择班级">
							<template v-for="(select, index) in pageData.courseNameList" :key="index">
								<el-option :label="select.cycleName" :value="select.encryptionId"></el-option>
							</template>
                        </el-select>
                    </div>
                </div>
                <span class="ml10 ft12 color-999">已排 <span class="color-409EFF">{{ pageData.formData.cycleNumHas }}</span> 节课，本班课可排课总课为课时 <span class="color-409EFF">{{ pageData.formData.cycleNum }}</span> (节），开班时间为{{ pageData.formData.cycleStartCourseTimeStr }}</span>
            </div>
            <div class="rx-c mt16" v-if="TYPE == 12">
                <div class=" rx-c">
                    <div class="formtip">容纳人数：</div>
                    <el-input class="formcontent" @input="inputAllPeopleNum" type="text" placeholder="请输入容纳人数" v-model="pageData.formData.allPeopleNum">
                        <template #append>人</template>
                    </el-input>
                </div>
                <span class="ml10 ft12 color-999">课节的最大预约人数</span>
            </div>
            <div class="rx-sc mt16">
                <div class=" rx-sc">
                    <div class="formtip">排课方式：</div>
                    <div class="w220 ml10">
                        <el-select v-model="pageData.formData.arrangeKind" placeholder="请选择">
					    	<el-option label="循环排课" :value="1"></el-option>
					    	<el-option label="自定义排课" :value="2"></el-option>
					    </el-select>
                    </div>
                </div>
            </div>
            <div class="h30 bg-main-6 mt16 rx-sc">
                <div class="title-bar ml10"></div>
                <div class="ml10 fwb">安排课表</div>
            </div>
            <div class="mt16 color-999" v-if="pageData.formData.encryptionId">本班课为[{{ TYPE == 11 ? '固定上课模式' : '自主预约模式'}}],排课后已报名的会员将自动加入课节中</div>
            <div class="rx-sc mt16" v-show="pageData.formData.arrangeKind == 1">
                <el-row :gutter="20">
                  <el-col :span="6">
                    <div class="rx-sc">
                        <div class="formtip"><span class="color-red">*</span>结束方式：</div>
                        <div class="w220 ml10">
		    	    		<el-select v-model="pageData.formData.overType" placeholder="请选择结束方式">
                                <el-option label="按时间" :value="2" />
                                <el-option label="按次数" :value="1" />
                            </el-select>
		    	    	</div>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class=" rx-sc">
                        <div class="formtip"><span class="color-red">*</span>开始日期：</div>
                        <div class="w220 ml10">
		    	    		<el-date-picker
		    	    			style="width: 100%;"
    	    	    		  	v-model="pageData.formData.startDate"
    	    	    		  	type="date"
    	    	    		  	placeholder="开始日期"
		    	    		  	format="YYYY-MM-DD"
                        		value-format="YYYY-MM-DD"
                                :disabled-date="pageData.disabledDate"
    	    	    		/>
		    	    	</div>
                    </div>
                  </el-col>
                  <el-col :span="6" v-if="pageData.formData.overType == 2">
                    <div class="rx-sc">
                        <div class="formtip"><span class="color-red">*</span>结束日期：</div>
                        <div class="w220 ml10">
		    	    		<el-date-picker
		    	    			style="width: 100%;"
    	    	    		  	v-model="pageData.formData.endDate"
    	    	    		  	type="date"
    	    	    		  	placeholder="结束日期"
		    	    		  	format="YYYY-MM-DD"
                        		value-format="YYYY-MM-DD"
                                @change="changeCycleTimeStr"
                                :disabled-date="pageData.disabledDate"
    	    	    		/>
		    	    	</div>
                    </div>
                  </el-col>
                  <el-col :span="6" v-if="pageData.formData.overType == 1">
                    <div class="rx-sc">
                        <div class="formtip"><span class="color-red">*</span>上课次数：</div>
                        <div class="w220 ml10">
		    	    		<el-input-number class="ww100" v-model="pageData.formData.cycleNum1" :min="0" :max="999" :step="1" step-strictly :controls="false" placeholder="请输入上课次数" @change="changeCycleNum1" />
		    	    	</div>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="rx-sc">
                        <div class="formtip">重复方式：</div>
                        <div class="w220 ml10">
                            <el-select v-model="pageData.formData.repeatType" placeholder="请选择" @change="changeWay">
                                <el-option label="隔天重复" :value="1"></el-option>
                                <el-option label="隔周重复" :value="2"></el-option>
				    	    	<el-option label="每周重复" :value="3"></el-option>
				    	    </el-select>
                        </div>
                    </div>
                  </el-col>
                </el-row>
            </div>
            <table class="mt16 weektable" v-show="pageData.formData.arrangeKind == 1 && pageData.formData.repeatType !=1" cellspacing="0" cellpadding="0">
                    <tr class="bg-eee">
                        <td class="h35">周一</td>
                        <td class="h35">周二</td>
                        <td class="h35">周三</td>
                        <td class="h35">周四</td>
                        <td class="h35">周五</td>
                        <td class="h35">周六</td>
                        <td class="h35">周日</td>
                    </tr>
                    <tr>
                        <td class="h35 c_p" @click="addTime(1)">+</td>
                        <td class="h35" @click="addTime(2)">+</td>
                        <td class="h35" @click="addTime(3)">+</td>
                        <td class="h35" @click="addTime(4)">+</td>
                        <td class="h35" @click="addTime(5)">+</td>
                        <td class="h35" @click="addTime(6)">+</td>
                        <td class="h35" @click="addTime(7)">+</td>
                    </tr>
                    <tr>
                        <td class="timecon">
                            <div class="rx-cc h20" v-for="(item,idx) in pageData.week1" :key="idx">
                                <span>{{item}}</span><Close class="close c_p" @click="removeTime(1,idx)"></Close>
                            </div>
                        </td>
                        <td class="timecon">
                            <div class="rx-cc h20" v-for="(item,idx) in pageData.week2" :key="idx">
                                <span>{{item}}</span><Close class="close c_p" @click="removeTime(2,idx)"></Close>
                            </div>
                        </td>
                        <td class="timecon">
                            <div class="rx-cc h20" v-for="(item,idx) in pageData.week3" :key="idx">
                                <span>{{item}}</span><Close class="close c_p" @click="removeTime(3,idx)"></Close>
                            </div>
                        </td>
                        <td class="timecon">
                            <div class="rx-cc h20" v-for="(item,idx) in pageData.week4" :key="idx">
                                <span>{{item}}</span><Close class="close c_p" @click="removeTime(4,idx)"></Close>
                            </div>
                        </td>
                        <td class="timecon">
                            <div class="rx-cc h20" v-for="(item,idx) in pageData.week5" :key="idx">
                                <span>{{item}}</span><Close class="close c_p" @click="removeTime(5,idx)"></Close>
                            </div>
                        </td>
                        <td class="timecon">
                            <div class="rx-cc h20" v-for="(item,idx) in pageData.week6" :key="idx">
                                <span>{{item}}</span><Close class="close c_p" @click="removeTime(6,idx)"></Close>
                            </div>
                        </td>
                        <td class="timecon">
                            <div class="rx-cc h20" v-for="(item,idx) in pageData.week7" :key="idx">
                                <span>{{item}}</span><Close class="close c_p" @click="removeTime(7,idx)"></Close>
                            </div>
                        </td>
                    </tr>
            </table>
            <div v-show="pageData.formData.arrangeKind == 1 && pageData.formData.repeatType ==1">
                <div class=" rx-sc mt16">
                    <div class="formtip">间隔天数：</div>
                    <div class="w220 ml10 rx-c">
                        <span class="mr5">隔</span>
                        <el-input class="w150" type="text" placeholder="请输入间隔天数" v-model="pageData.formData.spaceDayCount" @input="(v) => (pageData.formData.spaceDayCount = v.replace(/^0|[^0-9]/g,''))" />
                        <span class="ml5">天重复</span>
                    </div>
                </div>
                <div class="mr40 rx-ss mt16">
					<span class="w80 t_a_r mt5">上课时间：</span>
					<div>
						<div class="rx-c" :class="pageData.classList.length > 1 ? 'mb16' : ''" v-for="(item, index) in pageData.classList" :key="index">
							<div class="w240">
								<el-time-picker 
									style="width: 100%;"
								  	v-model="item.timer"
								  	format="HH:mm"
									value-format="HH:mm"
								  	is-range
								  	range-separator="至"
								  	start-placeholder="开始时间"
								  	end-placeholder="结束时间"
									@visible-change="(val)=>{dateChange1(val, index)}"
								/>
							</div>
							<el-icon class="c_p ml10" :size="20" color="#409EFC" @click="addClassTime1">
								<CirclePlus style="width: 1.5em; height: 1.5em;"/>
							</el-icon>
							<el-icon v-if="pageData.classList.length>1" class="c_p ml10" :size="20" color="#F56C6C" @click="deleTime1(index)">
								<Remove style="width: 1.5em; height: 1.5em;"/>
							</el-icon>
						</div>
					</div>
				</div>
            </div>
            <div class="mt16" v-if="pageData.formData.arrangeKind == 2">
                <div class="rx-c">
                    <div class="mr10 rx-c h350 bde bdr4 mr15">
                        <div class="w190 t_a_c bg-fdfaef leftList">
                            <div class="h40 l_h40">共排课 <span class="color-red ft16 feb">{{ pageData.calendarNum }}</span> 天</div>
                            <el-scrollbar height="308px">
                                <div class="mb15" v-for="item in pageData.calendarData" :key="item">
                                    <div class="color-666 mb5">{{ item.title }}</div>
                                    <div class="ft12 color-999">本月已排课{{ item.value }}天</div>
                                </div>
                            </el-scrollbar>
                        </div>
                        <div class="hh100 w300">
                            <Calendar
                                backgroundText
                                selectMode="multi"
                                class-name="multi-mode"
                                :useSwipe="false"
                                @onSelect="onSelect"
                                :begin="pageData.formData.cycleStartCourseTimeStr.substring(0,10)"
                            />
                        </div>
                    </div>
                    <div class="bde bdr4 h350 flex1">
                        <div class="h40 l_h40 bg-fdfaef pl20 mb16">
                            <el-radio-group v-model="pageData.formData.radio">
                                <el-radio :label="1">
                                    <span class="color-333">固定上课时间</span>
                                </el-radio>
                                <el-radio :label="2">
                                    <span class="color-333">自定义上课时间</span>
                                </el-radio>
                            </el-radio-group>
                        </div>
                        <div>
                            <div v-show="pageData.formData.radio == 1">
                                <div class="mr40 h290 o_y_s rx-ss">
							    	<span class="w120 t_a_r mt5">上课时间：</span>
							    	<div>
							    		<div class="rx-c" :class="pageData.timerList.length > 1 ? 'mb16' : ''" v-for="(item, index) in pageData.timerList" :key="index">
							    			<div class="w240">
							    				<el-time-picker 
							    					style="width: 100%;"
						        				  	v-model="item.timer"
							    				  	format="HH:mm"
							    					value-format="HH:mm"
						        				  	is-range
						        				  	range-separator="至"
						        				  	start-placeholder="开始时间"
						        				  	end-placeholder="结束时间"
							    					@visible-change="(val)=>{dateChange(val, index)}"
						        				/>
							    			</div>
							    			<el-icon class="c_p ml10" :size="20" color="#409EFC" @click="addClassTime">
							    				<CirclePlus style="width: 1.5em; height: 1.5em;"/>
							    			</el-icon>
							    			<el-icon v-if="pageData.timerList.length>1" class="c_p ml10" :size="20" color="#F56C6C" @click="deleTime(index)">
							    				<Remove style="width: 1.5em; height: 1.5em;"/>
							    			</el-icon>
							    		</div>
							    	</div>
							    </div>
                            </div>
                            <div class="h290 o_y_s rx-wss" v-show="pageData.formData.radio == 2">
                                <div class="mr40 rx-c mb16" v-for="(item, index) in pageData.calendarOrdData" :key="index">
							    	<span class="w160 t_a_r ft12 color-666">{{ item.title }}：</span>
							    	<div>
							    		<div class="rx-c">
							    			<div class="w240">
							    				<el-time-picker 
							    					style="width: 100%;"
						        				  	v-model="item.timer"
							    				  	format="HH:mm"
							    					value-format="HH:mm"
						        				  	is-range
						        				  	range-separator="至"
						        				  	start-placeholder="开始时间"
						        				  	end-placeholder="结束时间"
						        				/>
							    			</div>
							    		</div>
							    	</div>
							    </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="mt16 color-999" v-show="pageData.formData.arrangeKind == 1 && (pageData.formData.endDate || pageData.formData.cycleNum1)"> 
                <span v-if="pageData.formData.overType == 2">即：本次共排课  {{ pageData.cycleNum }} 节，时间范围为 {{ pageData.formData.startDate }}～ {{ pageData.formData.endDate }}</span>
                <span v-if="pageData.formData.overType == 1">即：本次共排课  {{ pageData.cycleNum }} 节<span v-if="pageData.cycleNum + pageData.formData.cycleNumHas >= pageData.formData.cycleNum">,班级课节已排满</span>最后一次课节结束日期为{{ pageData.endData || '-' }}</span>
            </div>
            <courseSeniorSet v-if="pageData.formData.encryptionId" class="mt15" ref="seniorSetDot" :type="13" :classType="TYPE" style="width:100%"></courseSeniorSet>
            <div class="h30 bg-main-6 mt20 rx-sc">
                <div class="title-bar ml10"></div><div class="ml10 fwb">选择教练</div>
            </div>
            <div class="ww100 h60 mt20 rx-sc">
                <div class="ml25 mr10">授课教练：</div>
                <div class="cx-cc h60 posi-r" @mouseenter="mouseenter(idx)" @mouseleave="mouseleave(idx)" v-for="(item,idx) in pageData.teacherCheckList" :key="idx">
                    <img :src="item.newImg" class="w40 h40" style="border-radius:50px;" />
                    <div class="rx-cc w50 maxLength color-333 ft12">{{item.realName}}</div>
                    <div class="ww100 h40 posi-a top0 left0 rx-cc" v-show="item.hover" style="z-index:2" @click="removeTeacher(item.id)">
                            <Delete class="w20 h20 color-red c_p"></Delete>
                    </div>
                </div>
                <el-popover placement="top" :width="430" trigger="click">
                    <div class="w400 h250 mt10 cx-sc" style="overflow-y:auto">
                        <el-table stripe ref="multipleTable" @selection-change="handleSelectTeacherChange" :data="pageData.teacherList" height="250" class="w380 single-select-table">
                            <el-table-column type="selection" width="55" />
                            <el-table-column prop="realName" label="授课教练" width="100" />
                            <el-table-column label="冲突状态" width="100">
                                <template #default="scope">
                                    <div v-if="scope.row.conflictStatus == 0"><span class="sp-green v_a_m d_i_b mr5"></span>空闲</div>
                                    <div v-if="scope.row.conflictStatus != 0"><span class="sp-red v_a_m d_i_b mr5"></span>冲突</div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="bbb" label="操作">
                                <template #default>
                                    <a class="color-409EFF tool_btn" href="javaScript:;" @click="toTeam">查看课表</a>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <template #reference>
                      <el-button type="primary" class="ml16" @click="getTeacherList">选择教练</el-button>
                    </template>
                </el-popover>
                <el-button class="ml16" @click="addStaff">新增教练</el-button>
            </div>
            <div class="h30 bg-main-6 mt20 rx-sc">
                <div class="title-bar ml10"></div><div class="ml10 fwb">选择场地</div>
            </div>
            <div class="ww100 h60 mt20" :class="pageData.PlaceCheckList[0] ? '' : 'rx-sc'">
                <div>
                    <span class="ml25 mr10">上课场地：</span>
                    <span v-if="pageData.PlaceCheckList.length">{{ pageData.PlaceCheckList[0].roomName }}</span>
                </div>
                <div :class="pageData.PlaceCheckList[0] ? 'mt20' : ''">
                    <el-button type="primary" class="ml16" @click="selectPlace">{{ pageData.PlaceCheckList[0] ? '重选场地' : '选择场地' }}</el-button>
                    <el-button class="ml16" @click="addNewPlace(0)">新增场地</el-button>
                </div>
            </div>
            <div class="h60 rx-sc mt60 ml25">
                <el-button class="w100" @click="goBack">返回</el-button>
                <el-button class="w100" type="primary" @click="sub">提交</el-button>
            </div>
        </div>

        <!-- 弹框 选择时间 -->
        <el-dialog
            v-model="pageData.addweektimepop"
            title="添加排课时间"
            width="500px"
            >
            <div class="ww100 pt30 pb30 cx-cc">
                <div class="ww100 h50 rx-cc">
                    <div class="w100 t_a_r">开始时间：</div>
                    <div class="w120">
                        <el-time-select
                          @change="changeWeekTimeStart"
                          v-model="pageData.temptimestart"
                          class="mr-4"
                          placeholder="开始时间"
                          start="00:00"
                          step="00:05"
                          end="23:55">
                        </el-time-select>
                    </div>
                </div>
                <div class="ww100 h50 rx-cc">
                    <div class="w100 t_a_r">结束时间：</div>
                    <div class="w120">
                        <el-time-select
                          v-model="pageData.temptimeend"
                          :min-time="pageData.temptimestart"
                          placeholder="结束时间"
                          start="00:00"
                          step="00:05"
                          end="23:55">
                        </el-time-select>
                    </div>
                </div>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="pageData.addweektimepop = false">取消</el-button>
                <el-button type="primary" @click="addTimeSub"
                  >确定</el-button
                >
              </span>
            </template>
        </el-dialog>
        <!-- 新增教练 -->
        <el-drawer v-model="pageData.drawer" :before-close="()=>{pageData.drawer = false}">
            <template #title>
              <h4>新增员工</h4>
            </template>
            <template #default>
                <div class="p20">
			    	<CommonTip text="新增员工登录初始默认密码为8个8。"/>
			    	<div class="rx-sc mb12">
			    		<div class="query_tip w80 rx-ec"><span class="color-red">*</span>员工头像：</div>
			    		<div class="rx-sc">
			    			<img class="w65 h65 b_r_100 mr10" :src="pageData.addFormData.headImgUrl" alt="">
			    			<div>
			    				<p>
									<CommonUpload @change="uploadChange" :autoCropWidth="375" :autoCropHeight="375" :isCropper="true">
			    				        <el-button type="primary" plain>{{ pageData.addFormData.headImgUrl == 'https://image.taokatao.cn/imgs/runBusiness/detailPhoto.png' ? '立即上传' : '重新上传' }}</el-button>
			    				    </CommonUpload></p>
			    				<p class="color-999 mt10">建议大小：750*750px</p>
			    			</div>
			    		</div>
			    	</div>
			    	<div class="query_item rx-bc mb20">
			    		<div class="query_tip w80 rx-ec"><span class="color-red">*</span>员工姓名：</div>
			    		<el-input class="w230" v-model="pageData.addFormData.realName" placeholder="输入员工姓名(限20字符)" maxlength="20"/>
			    	</div>
			    	<div class="query_item rx-bc mb20">
			    		<div class="query_tip w80 rx-ec"><span class="color-red">*</span>登录账号：</div>
			    		<el-input class="w230" v-model="pageData.addFormData.logingName" placeholder="输入登录账号" />
			    	</div>
			    	<div class="query_item rx-bc mb20">
			    		<div class="query_tip w80 rx-ec">手机号：</div>
			    		<el-input class="w230" v-model="pageData.addFormData.stallPhone" maxlength="11" placeholder="输入手机号" @input="telInputEvent" />
			    	</div>
			    	<div class="query_item rx-bc mb20">
			    		<div class="query_tip w80 rx-ec">员工类型：</div>
			    		<div class="w230">
			    			<el-select v-model="pageData.addFormData.ifFullTime" placeholder="请选择">
			    				<el-option :value="0" label="未知"></el-option>
			    				<el-option :value="1" label="全职"></el-option>
			    				<el-option :value="2" label="兼职"></el-option>
			    			</el-select>
			    		</div>
			    	</div>
			    	<div class="query_item rx-bc mb20">
			    		<div class="query_tip w80 rx-ec"><span class="color-red">*</span>员工岗位：</div>
			    		<div class="w230">
			    			<el-select multiple collapse-tags v-model="pageData.addFormData.roleId" placeholder="请选择">
			    				<el-option :value="item.encryptionId" :label="item.roleName" v-for="(item, index) in pageData.roleList" :key="index"></el-option>
			    			</el-select>
			    		</div>
			    	</div>
			    	<div class="query_item rx-bc mb20">
			    		<div class="query_tip w80 rx-ec">考勤方式：</div>
			    		<div class="w230">
			    			<el-select v-model="pageData.addFormData.attenType" placeholder="请选择">
			    				<el-option :value="1" label="默认考勤"></el-option>
			    				<el-option :value="2" label="课表考勤"></el-option>
			    				<el-option :value="3" label="指定考勤"></el-option>
			    			</el-select>
			    		</div>
			    	</div>
			    </div>
            </template>
            <template #footer>
                <div style="flex: auto">
                    <el-button @click="pageData.drawer = false">取消</el-button>
                    <el-button type="primary" @click="saveStaff">确定</el-button>
                </div>
            </template>
        </el-drawer>
        <!-- 选择场地 -->
        <el-dialog
            v-model="pageData.placeShow"
            title="选择场地"
            width="800px"
            :before-close="placeClose"
        >
            <template #default>
                <div class="p20">
                    <el-table stripe ref="refPlace" @selection-change="selectPlaceChange" :data="pageData.PlaceList"  height="500" class="single-select-table">
                        <el-table-column type="selection" width="55"  :selectable="(row)=>{return row.conflictStatus == 0}"/>
                        <el-table-column prop="roomName" label="场地名称" />
                        <el-table-column prop="containNum" label="容纳人数" align="right" />
                        <el-table-column label="冲突状态">
                            <template #default="scope">
                                <div v-if="scope.row.conflictStatus == 0"><span class="sp-green v_a_m d_i_b mr5"></span>空闲</div>
                                <div v-if="scope.row.conflictStatus != 0"><span class="sp-red v_a_m d_i_b mr5"></span>冲突</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="bbb" label="冲突信息">
                            <template #default="scope">
                                <a v-if="scope.row.conflictStatus != 0" class="color-409EFF tool_btn t_d_u" href="javaScript:;" @click="toConflict(scope.row)">查看课表</a>
                                <span v-if="scope.row.conflictStatus == 0">查看课表</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <el-dialog
                    v-model="pageData.innerPlaceShow"
                    width="500px"
                    title="冲突详情"
                    append-to-body
                >
                    <div class="p20">
                        <el-table stripe :data="pageData.innerPlaceList"  height="400">
                            <el-table-column type="index" label="序号" width="55" />
                            <el-table-column prop="roomName" label="冲突日期">
                                <template #default="scope">
                                    {{ scope.row.classTimeStartStr }}({{ scope.row.week }})
                                </template>
                            </el-table-column>
                            <el-table-column prop="containNum" label="冲突时间" >
                                <template #default="scope">
                                    {{ scope.row.startTime }}-{{ scope.row.endTime }}
                                </template>
                            </el-table-column>
                            <el-table-column label="冲突原因">
                                <template #default="scope">
                                    <span v-if="scope.row.courseName">{{ scope.row.courseName }}排课冲突</span>
                                    <span v-else>场地被锁定</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-dialog>
            </template>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="placeClose">取消</el-button>
                <el-button type="primary" @click="addPlace">确定</el-button>
              </span>
            </template>
        </el-dialog>
        <Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave"/>
        <!-- 新增场地 -->
        <el-drawer v-model="pageData.placeDrawer" :before-close="()=>{pageData.placeDrawer = false}">
            <template #title>
              <h4>新增场地</h4>
            </template>
            <template #default>
                <div>
			    	<div class="d_f mb20">
			    		<div class="query_tip w100 rx-ec"><span class="color-red">*</span>场地名称：</div>
			    		<el-input class="w230" v-model="pageData.addPlaceFormData.roomName" placeholder="输入场地名称(限10字内)" maxlength="10"/>
                    </div>
                    <div class="d_f mb20">
			    		<div class="query_tip w100">可容纳人数：</div>
			    		<el-input class="w230" v-model.number="pageData.addPlaceFormData.containNum" oninput="value=value.replace(/[^0-9]/g,'')">
                            <template #append>人</template>
                        </el-input>
                    </div>
                    <div class="d_f mb20">
			    		<div class="query_tip w100">备注：</div>
			    		<el-input
                            class="w230"
                            v-model="pageData.addPlaceFormData.remark"
                            :rows="4"
                            type="textarea"
                            placeholder="请输入备注"
                        />
                    </div>
			    </div>
            </template>
            <template #footer>
                <div style="flex: auto">
                    <el-button @click="pageData.placeDrawer = false">取消</el-button>
                    <el-button type="primary" @click="addNewPlace(1)">确定</el-button>
                </div>
            </template>
        </el-drawer>
    </workArea>
</template>
<script>
  export default {
    name: 'BranchCourseInfoAdd'
  }
</script>
<script setup>
    import { ref, reactive, onMounted, onActivated } from 'vue';
    import {onBeforeRouteLeave,onBeforeRouteUpdate,useRouter} from "vue-router";
    import {useStore} from 'vuex'
    import workArea from '@/components/branch-store/workArea.vue';
    import CommonTip from '@/components/CommonTip.vue'
    import { ElMessage ,ElMessageBox} from 'element-plus'
    import { Close, Delete, Remove, CirclePlus} from '@element-plus/icons-vue';
    import courseSeniorSet from '@/components/branch-store/course/courseSeniorSet.vue';
    import Calendar from 'mpvue-calendar'
    import moment from 'moment';
    import { courseCycleList, courseCycleArrange } from '@/api/course.js';
    import { getTeacherConflictList } from '@/api/courseInfo.js';
    import { roleManageStore, employeeInsert } from '@/api/staff.js';
    import utils from '@/common/utils.js';
    import CommonUpload from '@/components/CommonUpload.vue';
    import Confirm from '@/components/Confirm.vue'
    import { getRoomConflictList, sportRoomInsert } from '@/api/place.js';
    
    const barlist = reactive([{ name : '返回',type:0 },{ name : '新增排课' }]); // 面包屑
    const router = useRouter();
    const store = useStore();
    const seniorSetDot = ref();
    const multipleTable = ref()
    const ruleFormRef = ref()
    const refPlace = ref()
    const confirmRef = ref()
    const encryptionId = router.currentRoute.value.query.id;
    const tableWeekNum = router.currentRoute.value.query.tableWeekNum;
    const TYPE = ref()
    const pageData = reactive({
        confirmTitle: '',
        confirmText: '',
        drawer: false, // 新增教练抽屉
        placeShow: false, // 场地弹框
        innerPlaceShow: false, // 冲突场地详情弹框
        innerPlaceList: [], // 冲突场地列表
        PlaceList: [], // 场地列表
        PlaceCheckList: [], // 选中的场地
        placeDrawer: false, // 新增场地抽屉
        addPlaceFormData:{}, // 新增场地表单
        checkteachershow:false,
        addweektimepop : false, // 显示增加时间
        temptimestart : '',
        temptimeend : '',
        tempweeknum : 1,
        courseNameList : [], // 班级列表
        teacherList : [], // 老师列表
        teacherCheckList : [], // 已经选择的教练列表
        week1:[],
        week2:[],
        week3:[],
        week4:[],
        week5:[],
        week6:[],
        week7:[],
        formData : {
            repeatType: 3,
            arrangeKind: 1,
            courseTime: '',
            encryptionId : '',// 班级加密id
            allPeopleNum : '',// 容纳人数
            radio: 1, // 固定时间 or 自定义上课时间
            spaceDayCount: '', // 隔多少天重复
            overType:1,// 结束方式1次数，2日期
            startDate:"",// 开始日期
            endDate:"",// 结束日期
            cycleNum1:'',// 排课总次数
            week1:'',
            week2:'',
            week3:'',
            week4:'',
            week5:'',
            week6:'',
            week7:''
        },
        cycleNum: 0, // 排课总次数
        roleList: [],
        addFormData: {
            addFormData:{
			encryptionId:'',
			realName:'',
			logingName:'',
			stallPhone:'',
			ifFullTime:0,
			attenType:1,
			roleId:[],
			headImgUrl:''
		}
        }, // 新增教练表单
        calendarData:[],
        calendarOrdData: [],
        calendarNum: 0,
        timerList: [{
			timer: []
		}], // 时间列表
        classList: [{
			timer: []
		}], // 时间列表
        endData: '',
        disabledDate(time) {
          return time.getTime() < new Date(pageData.formData.cycleStartCourseTimeStr).getTime()
        },
    });
    /**
     * 查询待排班级列表
     */
    function getCourseNameList(){
        let params = {
            page:1, 
            limit: 99999
        }
        courseCycleList(params).then((res)=>{
            if(res.code == 0){
                pageData.courseNameList = res.data;
                if (encryptionId) {
                    pageData.formData.encryptionId = encryptionId
                    changeCourseName(encryptionId)
                }
            }
        });
    }
    /**
     * 切换选择班级
     */
    function changeCourseName(val){
        var courseNameList = pageData.courseNameList;
        for(var i=0;i<courseNameList.length;i++){
            if(courseNameList[i]['encryptionId'] == val){
                TYPE.value = courseNameList[i]['cycleType']
                pageData.formData.cycleStartCourseTimeStr = courseNameList[i]['cycleStartCourseTimeStr']
                pageData.formData.cycleNumHas = courseNameList[i]['cycleNumHas']
                pageData.formData.cycleNum = courseNameList[i]['cycleNum']
                pageData.formData.courseTime = courseNameList[i]['courseTime']
                pageData.formData.allPeopleNum = courseNameList[i]['allPeopleNum']
                
                // 计算
                var timeStart = pageData['temptimestart'];
                var courseTime = courseNameList[i]['courseTime'];
                if(timeStart){
                    var hour = parseInt(timeStart.split(':')[0]);
                    var min  = parseInt(timeStart.split(':')[1]);
                    min = min + parseInt(courseTime);
                    hour = hour + parseInt(min / 60) + '';
                    min = min % 60 + '';
                    hour = hour.length == 1 ? '0'+ hour : hour;
                    min = min.length == 1 ? '0'+ min : min;
                    if (hour > 23) {
                        pageData.temptimeend = ''
                    } else if(min > 55) {
                        pageData.temptimeend = ''
                    } else {
                        pageData.temptimeend = hour + ':' + min;
                    }
                }
            }
        }
    }
    /**
     * 自定义选择日期
     */
    function onSelect(selectDate) {
        pageData.calendarData = []
        pageData.calendarOrdData = []
        pageData.calendarNum = 0
        selectDate.sort((p1,p2)=>{
            return utils.getDateObj(p1).time- utils.getDateObj(p2).time // 按年龄降序排
            // return p1.age-p2.age // 按年龄升序排
        })
        const obj = {}
        for (let i = 0; i < selectDate.length; i++) {
            var key = selectDate[i].substring(0,6)
            if (obj[key] === undefined) {
                obj[key] = 1
            } else {
                obj[key]++
            }
            pageData.calendarOrdData.push({
                title: `${utils.getDateObj(selectDate[i]).Y}-${utils.getDateObj(selectDate[i]).M}-${utils.getDateObj(selectDate[i]).D}（周${utils.getDateObj(selectDate[i]).week}）`,
                timer: []
            })
        }
        for (const key in obj) {
            let item = key.split('-')
            pageData.calendarData.push({
                title: item[0]+'年' + (Number(item[1]) < 10 ? ('0' + item[1] + '月') : (item[1] + '月')),
                value: Number(obj[key]),
            })
        }
        pageData.calendarData.forEach(item => {
            pageData.calendarNum += item.value
        })
    }
    /**
	 * 添加时间段
	 */
	function addClassTime() {
		pageData.timerList.push({
			timer: []
		})
	}
    /**
	 * 删除时间段
	 */
	function deleTime(index) {
		pageData.timerList.splice(index,1);
	}
    /**
	 * 修改上课时间段
	 */
	function dateChange(val){
		if (!val) {
			let timeArr = []
			pageData.timerList.forEach(item => {
				let obj = {
					start: moment.duration(item.timer[0]).as('seconds'),
					end:  moment.duration(item.timer[1]).as('seconds'),
				}
				timeArr.push(obj)
			})
			if (timeArr.length > 1 && !Fn(timeArr)) {
				ElMessage.warning('所选时间段有重叠')
			}
		}
	}
     /**
	 * 隔天添加时间段
	 */
	function addClassTime1() {
		pageData.classList.push({
			timer: []
		})
	}
    /**
	 * 隔天删除时间段
	 */
	function deleTime1(index) {
		pageData.classList.splice(index,1);
	}
	
	/**
	 * 隔天修改上课时间段
	 */
	function dateChange1(val){
		if (!val) {
			let timeArr = []
			pageData.classList.forEach(item => {
				let obj = {
					start: moment.duration(item.timer[0]).as('seconds'),
					end:  moment.duration(item.timer[1]).as('seconds'),
				}
				timeArr.push(obj)
			})
			if (timeArr.length > 1 && !Fn(timeArr)) {
				ElMessage.warning('所选时间段有重叠')
			}
		}
	}
	/**
	 * 判断时间段是否重叠
	 */
	function Fn(dateAr) {
	  for (let k in dateAr) {
	    if (!judege(k, dateAr)) {
	      return false
	    }
	  }
	  return true
	}
	function judege(idx, dateAr) {
	  for (let k in dateAr) {
	    if (idx !== k) {
	        if (dateAr[k].start <= dateAr[idx].start && dateAr[k].end > dateAr[idx].start) {
	          return false
	        }
	        if (dateAr[k].start < dateAr[idx].end && dateAr[k].end >= dateAr[idx].end) {
	          return false
	        }
	    }
	  }
	  return true
	}
    /**
     * 新增场地
     */
    function addNewPlace(val){
        if (!val) {
            pageData.addPlaceFormData = {}
            pageData.placeDrawer = true
        } else {
            if (!pageData.addPlaceFormData.roomName) {
                ElMessage.warning('请输入场地名称')
                return
            }
            let params = {
                roomName: pageData.addPlaceFormData.roomName,
                containNum: pageData.addPlaceFormData.containNum,
                remark: pageData.addPlaceFormData.remark,
                purposeClass: 1,
                purposeOrder: 0
            }
            sportRoomInsert(params).then(res => {
                if (res.code == 0) {
                    ElMessage.success('新增成功')
                    pageData.placeDrawer = false
                }
            })
        }
    }
    /**
     * 选择场地
     */
    function selectPlace() {
        var  paramsRes =  getParams();
        if(!paramsRes['status']){
            ElMessage({
                type: 'warning',
                message: paramsRes['message'],
            })
            return;
        }
        paramsRes.params['overType'] = pageData.formData.overType
        pageData.PlaceCheckList = []
        if (pageData.formData.arrangeKind == 2) {
            if (pageData.formData.radio == 1){
                let array = []
                pageData.calendarOrdData.forEach(item=> {
                    let arr = []
                    pageData.timerList.forEach(items => {
                        arr.push(items.timer.join('-'))
                    })
                    array.push({
                        date: item.title.substring(0,10),
                        times: arr
                    })
                })
                let courseNum = pageData.calendarOrdData.length * pageData.timerList.length
                if ((pageData.formData.cycleNum-pageData.formData.cycleNumHas) < courseNum) {
                    ElMessage.warning(`新增排课${courseNum}节，已排课${pageData.formData.cycleNumHas}节，总课节只有${pageData.formData.cycleNum},不可超过总课节数`)
                    return;
                }
                paramsRes.params['calendarTimes'] =  JSON.stringify(array)
                
            }
            if (pageData.formData.radio == 2) {
                let array = []
                pageData.calendarOrdData.forEach(item=> {
                    array.push({
                        date: item.title.substring(0,10),
                        times: [item.timer.join('-')]
                    })
                })
                let courseNum = pageData.calendarOrdData.length
                if ((pageData.formData.cycleNum-pageData.formData.cycleNumHas) < courseNum) {
                    ElMessage.warning(`新增排课${courseNum}节，已排课${pageData.formData.cycleNumHas}节，总课节只有${pageData.formData.cycleNum},不可超过总课节数`)
                    return;
                }
                paramsRes.params['calendarTimes'] = JSON.stringify(array)
            }
        } else {
            paramsRes.params['startDate'] = pageData.formData.startDate
            if (pageData.formData.overType == 2) {
                paramsRes.params['endDate'] = pageData.formData.endDate
            } else {
                paramsRes.params['cycleNum'] = pageData.formData.cycleNum1
            }
            if ((pageData.formData.cycleNum-pageData.formData.cycleNumHas) <  pageData.cycleNum) {
                ElMessage.warning(`新增排课${pageData.cycleNum}节，已排课${pageData.formData.cycleNumHas}节，总课节只有${pageData.formData.cycleNum},不可超过总课节数`)
                return;
            }
        }
        pageData.PlaceCheckList = []
        getRoomConflictList(paramsRes.params).then((res)=>{
            if(res.code == 0){
                pageData.PlaceList = res.data;
                pageData.placeShow = true
            }
        });
    }
    
    /**
     * 选中场地数据
     */
    function selectPlaceChange(val){
        pageData.PlaceCheckList = val
        if (val.length > 1) {
          refPlace.value.clearSelection();
          refPlace.value.toggleRowSelection(val.pop());
        } else {
          pageData.PlaceCheckList = val;
        }
    }
    /**
     * 取消选择场地
     */
    function placeClose() {
        pageData.PlaceCheckList = []
        pageData.placeShow = false
    }
    /**
     * 查看冲突
     */
    function toConflict(row) {
        pageData.innerPlaceList = row.courseList
        pageData.innerPlaceShow = true
    }
    /**
     * 确定选择场地
     */
    function addPlace() {
        if (!pageData.PlaceCheckList.length) {
            ElMessage({
                type: 'warning',
                message: '请选择场地'
            })
            return
        }
        let num = 0
        if (pageData.PlaceCheckList[0].conflictStatus) {
            if (pageData.formData.overType == 1) {
                num = pageData.formData.cycleNum
            } else {
                num = countNum()
            }
            pageData.confirmTitle = '是否确定选择改场地'
            pageData.confirmText = '共排课<span class="color-red"> '+num+' </span>节课，冲突<span class="color-red"> '+pageData.PlaceCheckList[0].courseList.length+' </span>节';
            confirmRef.value.show();
        } else {
            pageData.placeShow = false
        }
    }
    /**
     * 获取两个日期之间所有的日期数据
     */
    function getAllData(startTime, endTime){
        const start = moment(startTime);
        const end = moment(endTime);
        const day = end.diff(start, "days");
        let daysList = [startTime];
        for (let i = 1; i <= day; i++) {
            daysList.push(start.add(1, "days").format("yyyy-MM-DD"));
        }
        return daysList
    }
    /**
     * 坚持选择冲突场地
     */
    function confirmSave() {
        pageData.placeShow = false
    }
    // 新增教练弹出窗
	function addStaff(){
		//岗位搜索下拉框
		roleManageStore({page: 1, limit: 9999}).then((res)=>{
			if(res.code == 0){
				pageData.roleList = res.data;
			}
		})
		pageData.addFormData.encryptionId = '';
		pageData.addFormData.realName = '';
		pageData.addFormData.logingName = '';
		pageData.addFormData.stallPhone = '';
		pageData.addFormData.ifFullTime = 0;
		pageData.addFormData.attenType = 1;
		pageData.addFormData.roleId = []
        pageData.addFormData.headImgUrl = 'https://image.taokatao.cn/imgs/runBusiness/detailPhoto.png'
		pageData.drawer = true;
	}
    //新增员工保存
	function saveStaff(){
		var formData = JSON.parse(JSON.stringify(pageData.addFormData));
		if(!formData.realName){
			ElMessage({
				type: 'warning',
				message: '请输入员工名称',
			})
			return false;
		}
		if(!formData.realName){
			ElMessage({
				type: 'warning',
				message: '请输入员工名称',
			})
			return false;
		}
		if(!formData.logingName){
			ElMessage({
				type: 'warning',
				message: '请输入登录账号',
			})
			return false;
		}
		var storeId = [];
		pageData.roleList.map((n)=>{
			if(formData.roleId.indexOf(n.id) > -1){
				storeId.push(n.sid);
			}
		})
		formData.roleId = formData.roleId.join(',');
		formData.storeId = storeId.join(',');
		employeeInsert(formData).then((res)=>{
			if(res.code == 0){
                ElMessage({
			    	type: 'success',
			    	message: '新增成功',
			    })
				pageData.drawer = false;
				
			}
		})
	}
	//上传封面
	function uploadChange(url){
		pageData.addFormData.headImgUrl = url;
	}
    //手机号输入验证
	var telVal = '';
	function telInputEvent(val){
		if(isNaN(Number(val))){
			pageData.addFormData.stallPhone = telVal;
		}else{
			telVal = val;
		}
	}
    /**
     * 查看课表
     */
    function toTeam(){
        window.open('/#/branch/courseTable', '_blank')
    }
    
    // 修改开始时间
    function changeWeekTimeStart(timeStart){
        if(timeStart){
            var hour = parseInt(timeStart.split(':')[0]);
            var min  = parseInt(timeStart.split(':')[1]);
            min = min + parseInt(pageData.formData.courseTime || '45');
            hour = hour + parseInt(min / 60) + '';
            min = min % 60 + '';
            hour = hour.length == 1 ? '0'+hour : hour;
            min = min.length == 1? '0'+min : min;
            if (hour > 23) {
                pageData.temptimeend = ''
            } else if(min > 55) {
                pageData.temptimeend = ''
            } else {
                pageData.temptimeend = hour + ':' + min;
            }
        }
    }
    // 鼠标进入

    function mouseenter(idx){
        pageData.teacherCheckList[idx]['hover'] = true;
    }
    // 鼠标离开
    function mouseleave(idx){
        pageData.teacherCheckList[idx]['hover'] = false;
    }
    function addTime(num){
        if (!pageData.formData.encryptionId) {
            ElMessage.warning('请选择班级')
            return
        }
        if (!pageData.formData.startDate) {
            ElMessage.warning('请选择开始日期')
            return
        }
        if (pageData.formData.overType == 1 && !pageData.formData.cycleNum1) {
            ElMessage.warning('请选择上课次数')
            return
        }
        if (pageData.formData.overType == 2 && !pageData.formData.endDate) {
            ElMessage.warning('请选择结束日期')
            return
        }
        pageData.tempweeknum = num;
        pageData.addweektimepop = true;
    }
    function changeCycleNum1() {
        pageData.cycleNum = countNum()
    }
    function changeCycleTimeStr(val) {
        pageData.cycleNum = countNum()
    }
    function changeWay(val) {
        pageData.cycleNum = countNum()
    }
    
    function addTimeSub(){
        var weektimeArrayKey = 'week'+pageData.tempweeknum;
        pageData[weektimeArrayKey].push(pageData.temptimestart+'-'+pageData.temptimeend);
        pageData.cycleNum = countNum()
        console.log(pageData.formData.overType)
        pageData.addweektimepop = false;
    }
   
    /**计算多少课 */
    function countNum() {
        let num = 0
        const weekArr = ["星期日","星期一","星期二","星期三","星期四","星期五","星期六"]
        let allData = ''
        if ( pageData.formData.overType == 1 ) {
            let addNum = pageData.formData.cycleNum1 < 25 ? 1 :  pageData.formData.cycleNum1 < 50 ? 2 : pageData.formData.cycleNum1 < 75 ? 3 : 4
            allData = getAllData(pageData.formData.startDate, moment(pageData.formData.startDate).add(addNum ,'years').format("YYYY-MM-DD"))
        } else {
            allData = getAllData(pageData.formData.startDate, pageData.formData.endDate)
        }
        let arr = [],week1 = [],week2 = [],week3 = [],week4 = [],week5 = [],week6 = [],week7 = [];
        let sum1 = pageData.week1.length ? pageData.week1.length : 0
        let sum2 = pageData.week2.length ? pageData.week2.length : 0
        let sum3 = pageData.week3.length ? pageData.week3.length : 0
        let sum4 = pageData.week4.length ? pageData.week4.length : 0
        let sum5 = pageData.week5.length ? pageData.week5.length : 0
        let sum6 = pageData.week6.length ? pageData.week6.length : 0
        let sum7 = pageData.week7.length ? pageData.week7.length : 0
        let day1=0,day2=0,day3=0,day4=0,day5=0,day6=0,day7=0;
        allData.forEach((item) => {
            let getWeek = new Date(item).getDay()
            arr.push(weekArr[getWeek])
        })
        if ( pageData.formData.overType == 2 ) {
            if (pageData.formData.repeatType == 2) {
                let index = []
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i] == '星期日') {
                        index.push(i)
                    }
                }
                let newArrData = arr.slice(0, index[0]+1);
                let newArr = [newArrData];
                let newAllData = arr.splice(0, index[0]+1)
                while(arr.length > 0) {
                  newArr.push(arr.splice(0, 7));
                }
                let arr1 = []
                for (let i = 0; i < newArr.length; i++) {
                    if (i%2 != 0) {
                        arr1.push(newArr[i]);
                    }
                }
                newArr = arr1
                arr = newArr.flat(Infinity)
            }
        }
        arr.forEach((item, index) => {
            switch(item) {
		        case '星期一':
    	        	week1.push(item)
		            break;
                case '星期二':
    	        	week2.push(item)
		            break;
                case '星期三':
    	        	week3.push(item)
		            break;
                case '星期四':
    	        	week4.push(item)
		            break;
                case '星期五':
    	        	week5.push(item)
		            break;
                case '星期六':
    	        	week6.push(item)
		            break;
                case '星期日':
    	        	week7.push(item)
		            break;
            }
        })
        if (sum1) {
            day1 = sum1*week1.length
        }
        if (sum2) {
            day2 = sum2*week2.length
        }
        if (sum3) {
            day3 = sum3*week3.length
        }
        if (sum4) {
            day4 = sum4*week4.length
        }
        if (sum5) {
            day5 = sum5*week5.length
        }
        if (sum6) {
            day6 = sum6*week6.length
        }
        if (sum7) {
            day7 = sum7*week7.length
        }
        if ( pageData.formData.overType == 1 ) {
            let index = []
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] == '星期日') {
                    index.push(i)
                }
            }
            let newArrData = allData.slice(0, index[0]+1);
            let newArr = [newArrData];
            let newAllData = allData.splice(0, index[0]+1)
            while(allData.length > 0) {
              newArr.push(allData.splice(0, 7));
            }
            if (pageData.formData.repeatType == 2) {
                let arr = []
                for (let i = 0; i < newArr.length; i++) {
                    if (i%2 != 0) {
                        arr.push(newArr[i]);
                    }
                }
                newArr = arr
            }
            let list = []
            for (let i = 0; i < newArr.length; i++) {
                let arr = []
                for (let j = 0; j < newArr[i].length; j++) {
                    let getWeek = new Date(newArr[i][j]).getDay()
                    let obj = {
                        week: newArr[i][j]
                    }
                    if (weekArr[getWeek] == '星期一') {
                        obj.num = sum1
                    }
                    if (weekArr[getWeek] == '星期二') {
                        obj.num = sum2
                    }
                    if (weekArr[getWeek] == '星期三') {
                        obj.num = sum3
                    }
                    if (weekArr[getWeek] == '星期四') {
                        obj.num = sum4
                    }
                    if (weekArr[getWeek] == '星期五') {
                        obj.num = sum5
                    }
                    if (weekArr[getWeek] == '星期六') {
                        obj.num = sum6
                    }
                    if (weekArr[getWeek] == '星期日') {
                        obj.num = sum7
                    }
                    arr.push(obj)
                }
                list.push(arr)
            }
            datalist(list)
        }
        num = day1+day2+day3+day4+day5+day6+day7
        return pageData.formData.overType == 1 ? pageData.formData.cycleNum1 : num
    }
    function datalist(list){
        let number = 0
            for (let i = 0; i < list.length; i++) {
                for (let j = 0; j < list[i].length; j++) {
                    number += list[i][j].num
                    if (number == pageData.formData.cycleNum1) {
                        pageData.endData = list[i][j].week
                        return
                    }   
                }
            }
    }
    function removeTeacher(id){
        var teacherList = pageData.teacherCheckList || [];
        var teacherTempList = [];
        for(var i=0;i<teacherList.length;i++){
            if(teacherList[i]['id'] != id){
                teacherTempList.push(teacherList[i]);
            }
        }
        pageData.teacherCheckList = teacherTempList;
    }
    function handleSelectTeacherChange(val){
        if (val.length > 1) {
          multipleTable.value.clearSelection();
          multipleTable.value.toggleRowSelection(val.pop());
        } else {
          pageData.teacherCheckList = val;
        }
    }
    function removeTime(num,idx){
        var weektimeArrayKey = 'week'+num;
        var timeArray = pageData[weektimeArrayKey];
        var timeTempArray = [];
        for(var i=0;i<timeArray.length;i++){
            if(i != idx){
                timeTempArray.push(timeArray[i]);
            }
        }
        pageData[weektimeArrayKey] = timeTempArray;
        pageData.cycleNum = countNum()
    }
    
    function getSubParams(){
        var paramsRes = getParams();
        if(!paramsRes.status){
            return paramsRes;
        }
        // 添加教练id
        var courseTeacherArray = [];
        var teacherCheckList = pageData.teacherCheckList;
        if(teacherCheckList.length < 1){
            return {
                status : false,
                message : '请选择教练'
            }
        }
        for(var i=0;i<teacherCheckList.length;i++){
            courseTeacherArray.push(teacherCheckList[i]['id']);
        }
        paramsRes.params['courseTeacher'] = courseTeacherArray.join(',');
        return paramsRes;
    }
    // 获取表单内容
    function getParams(){
        var status = true; // 状态
        var message = '';  // 提示信息
        var formData = Object.assign({},pageData.formData);
         formData = utils.trimObject(formData)
        for(var i=1;i<8;i++){
            formData['week'+i] = pageData['week'+i].join(',');
        }
        // 高级设置
        var seniorSet = seniorSetDot.value.getSeniorSet();
        Object.assign(formData,seniorSet);
        // 验证高级设置
        // 验证参数
        if(!formData['encryptionId']){
            status = false;
            message= '请先选择班级';
        }
        if(status && formData['allPeopleNum'] < 1){
            status = false;
            message= '请输入容纳人数';
        }
        var hasWeekTime = false;
        for(var i=1;i<8;i++){
            if(formData['week'+i].length > 0){
                hasWeekTime = true;
            }
        }
        // 判断高级设置
        if(status && formData['ifOpenBook'] == 1 && TYPE == 12){
            if(!formData['bookingStartRule'] && formData['bookingStartRule'] != 0){
                status = false;
                message = '预约开始时间不能为空';
            }
            if(!formData['bookingEndRule'] && formData['bookingEndRule'] != 0){
                status = false;
                message = '预约截止时间不能为空';
            }
            if(parseInt(formData['bookingEndRule']) > parseInt(formData['bookingStartRule'])){
                status = false;
                message = '预约截止时间不能早于开始时间';
            }
            // 当不开放预约时，下面全不展示，所以放在开放预约里面
            if(status && formData['ifFull'] == 1){
                if(!formData['fullEndRule'] && formData['fullEndRule'] !=0){
                    status = false;
                    message = '满员截止时间不能为空';
                }
                if(!formData['fullNum'] && formData['fullNum'] != 0){
                    status = false;
                    message = '满员人数不能为空';
                }
            }
            if(status && formData['ifCancelBook'] == 1){
                if(formData['ifFull'] == 1 && !formData['fullCancelNum']){
                    status = false;
                    message = '满员取消人数不能为空';
                }
                if(!formData['cancelEndRule'] && formData['cancelEndRule'] != 0){
                    status = false;
                    message = '取消截止时间不能为空';
                }
            }
            if(status && formData['ifAfter'] == 1){
                if(!formData['afterNum'] && formData['afterNum'] != 0){
                    status = false;
                    message = '候补人数不能为空';
                }
            }
        }
        if(status && formData['ifBook'] == 1){
            if(!formData['autoConsumeRule'] && formData['autoConsumeRule'] != 0){
                status = false;
                message = '自动签到时间不能为空';
            }
        }
        if(formData['arrangeKind'] == 1 && status && !pageData.formData.startDate){
            status = false;
            message= '请选择开始日期';
        }
        if(formData['arrangeKind'] == 1 && status && pageData.formData.overType == 1 && !formData['cycleNum1']){
            status = false;
            message= '请选择上课次数';
        }
        if(formData['arrangeKind'] == 1 && status && pageData.formData.overType == 2 && !pageData.formData.endDate){
            status = false;
            message= '请选择结束日期';
        }
        if(formData['arrangeKind'] == 1 && status ){
            if (pageData.formData.repeatType == 1) {
                if (!formData['spaceDayCount']) {
                    status = false;
                    message= '请输入间隔天数';
                } else {
                    for (let i = 0; i < pageData.classList.length; i++) {
                        if (!pageData.classList[i].timer.length) {
                            status = false;
                            message= '有上课时间未选择';
                        }
                    }
                }
                let arr = []
                pageData.classList.forEach(item => {
                    arr.push(item.timer.join('-'))
                })
                formData['repeatTime'] = arr.join()
            }
            if (pageData.formData.repeatType != 1 && !hasWeekTime) {
                status = false;
                message= '请选择排课时间';
            }
            
        }
        if(formData['arrangeKind'] == 2 && status ){
            if (!pageData.calendarData.length) {
                status = false;
                message= '请选择排课日期';
            } else {
                if (formData['radio'] == 1 ) {
                    for (let i = 0; i < pageData.timerList.length; i++) {
                        if (!pageData.timerList[i].timer.length) {
                            status = false;
                            message= '有上课时间未选择';
                        }
                    }
                }
                if (formData['radio'] == 2) {
                    for (let i = 0; i < pageData.calendarOrdData.length; i++) {
                        if (!pageData.calendarOrdData[i].timer.length) {
                            status = false;
                            message= '有上课时间未选择';
                        }
                    }
                }
            }
        }
        return {
            status : status,
            message : message,
            params : formData
        }
    }
    // 查询教练列表
    function getTeacherList(){
        var  paramsRes =  getParams();
        if(!paramsRes['status']){
            ElMessage({
                type: 'warning',
                message: paramsRes['message'],
            })
            return;
        }
        paramsRes.params['overType'] = pageData.formData.overType
        pageData.teacherList = [];
        if (pageData.formData.arrangeKind == 2) {
            if (pageData.formData.radio == 1){
                let array = []
                pageData.calendarOrdData.forEach(item=> {
                    let arr = []
                    pageData.timerList.forEach(items => {
                        arr.push(items.timer.join('-'))
                    })
                    array.push({
                        date: item.title.substring(0,10),
                        times: arr
                    })
                })
                let courseNum = pageData.calendarOrdData.length * pageData.timerList.length
                if ((pageData.formData.cycleNum-pageData.formData.cycleNumHas) < courseNum) {
                    ElMessage.warning(`新增排课${courseNum}节，已排课${pageData.formData.cycleNumHas}节，总课节只有${pageData.formData.cycleNum},不可超过总课节数`)
                    return;
                }
                paramsRes.params['calendarTimes'] =  JSON.stringify(array)
                
            }
            if (pageData.formData.radio == 2) {
                let array = []
                pageData.calendarOrdData.forEach(item=> {
                    array.push({
                        date: item.title.substring(0,10),
                        times: [item.timer.join('-')]
                    })
                })
                let courseNum = pageData.calendarOrdData.length
                if ((pageData.formData.cycleNum-pageData.formData.cycleNumHas) < courseNum) {
                    ElMessage.warning(`新增排课${courseNum}节，已排课${pageData.formData.cycleNumHas}节，总课节只有${pageData.formData.cycleNum},不可超过总课节数`)
                    return;
                }
                paramsRes.params['calendarTimes'] = JSON.stringify(array)
            }
        } else {
            paramsRes.params['startDate'] = pageData.formData.startDate
            if (pageData.formData.overType == 2) {
                paramsRes.params['endDate'] = pageData.formData.endDate
            } else {
                paramsRes.params['cycleNum'] = pageData.formData.cycleNum1
            }
            if ((pageData.formData.cycleNum-pageData.formData.cycleNumHas) <  pageData.cycleNum) {
                ElMessage.warning(`新增排课${pageData.cycleNum}节，已排课${pageData.formData.cycleNumHas}节，总课节只有${pageData.formData.cycleNum},不可超过总课节数`)
                return;
            }
        }
        getTeacherConflictList(paramsRes.params).then((res)=>{
            if(res.code == 0){
                var teacherList = res.data;
                var teacherCheckList = pageData.teacherCheckList;
                for(var i=0;i<teacherCheckList.length;i++){
                    for(var j=0;j<teacherList.length;j++){
                        if(teacherList[j]['id'] = teacherCheckList[i]['id']){
                            teacherList[j]['select'] = true;
                        }
                    }
                }
                pageData.teacherList = teacherList;
            }
        });
    }
    // 容纳人数只输数字
    function inputAllPeopleNum(val){
        pageData.formData.allPeopleNum = val.replace(/\D/g, '');
    }
    function inputCycleNum(val){
        pageData.formData.cycleNum = val.replace(/\D/g, '');
    }
    function sub(event,ifRepeat){
        ifRepeat = ifRepeat || 0;
        var paramsRes = getSubParams();
        if(!paramsRes.status){
            ElMessage.warning(paramsRes['message'])
            return;
        }
        paramsRes.params['ifRepeat'] = ifRepeat;
        paramsRes.params['classroomId'] = pageData.PlaceCheckList.length ? pageData.PlaceCheckList[0].id : ''
        paramsRes.params['overType'] = pageData.formData.overType
        if (pageData.formData.arrangeKind == 2) {
            if (pageData.formData.radio == 1){
                let array = []
                pageData.calendarOrdData.forEach(item=> {
                    let arr = []
                    pageData.timerList.forEach(items => {
                        arr.push(items.timer.join('-'))
                    })
                    array.push({
                        date: item.title.substring(0,10),
                        times: arr
                    })
                })
                let courseNum = pageData.calendarOrdData.length * pageData.timerList.length
                if ((pageData.formData.cycleNum-pageData.formData.cycleNumHas) < courseNum) {
                    ElMessage.warning(`新增排课${courseNum}节，已排课${pageData.formData.cycleNumHas}节，总课节只有${pageData.formData.cycleNum},不可超过总课节数`)
                    return;
                }
                paramsRes.params['calendarTimes'] =  JSON.stringify(array)
                
            }
            if (pageData.formData.radio == 2) {
                let array = []
                pageData.calendarOrdData.forEach(item=> {
                    array.push({
                        date: item.title.substring(0,10),
                        times: [item.timer.join('-')]
                    })
                })
                let courseNum = pageData.calendarOrdData.length
                if ((pageData.formData.cycleNum-pageData.formData.cycleNumHas) < courseNum) {
                    ElMessage.warning(`新增排课${courseNum}节，已排课${pageData.formData.cycleNumHas}节，总课节只有${pageData.formData.cycleNum},不可超过总课节数`)
                    return;
                }
                paramsRes.params['calendarTimes'] = JSON.stringify(array)
            }
        } else {
            paramsRes.params['startDate'] = pageData.formData.startDate
            if (pageData.formData.overType == 2) {
                paramsRes.params['endDate'] = pageData.formData.endDate
            } else {
                paramsRes.params['cycleNum'] = pageData.formData.cycleNum1
            }
            if ((pageData.formData.cycleNum-pageData.formData.cycleNumHas) <  pageData.cycleNum) {
                ElMessage.warning(`新增排课${pageData.cycleNum}节，已排课${pageData.formData.cycleNumHas}节，总课节只有${pageData.formData.cycleNum},不可超过总课节数`)
                return;
            }
        }
        courseCycleArrange(paramsRes.params).then((res)=>{
            if(res.code == 0){
                if(res.results && res.results.errorType && res.results.errorType == "2"){
                    ElMessageBox.confirm(
                        '该时间段老师课程冲突，是否继续排课？',
                        '提示信息',
                        {
                          confirmButtonText: '确定',
                          cancelButtonText: '取消',
                          type: 'warning',
                        }).then(() => {
                          // 再次发起排课
                          sub(null,1);
                    }).catch(() => {
                        // 取消
                    })
                }else{
                    ElMessage({
                        type: 'success',
                        message: "排课成功",
                    })
                    if (encryptionId) {
                        router.push({ path: '/branch/classManage'})
                    } else {
                        history.go(-1) 
                    }
                    
                }
            }
        });
    }
    function goBack() {
        history.go(-1) 
    }
    onMounted(() => {
        getCourseNameList()
        localStorage.setItem('courseTable', '2')
        localStorage.setItem('tableWeekNum', tableWeekNum)
    })
    // 页面缓存
    onBeforeRouteLeave((to, from)=>{
        if (to.path === '/branch/addUpdateCourse') {
            store.commit('SetKeep',{keepLiveRoute : ['BranchCourseInfoAdd']});
        } else {
            store.commit('SetKeep',{keepLiveRoute : []});
        }
    })
</script>

<style scoped>
    .sp-green{
        height:5px;width:5px;background-color:#00ee00;border-radius:10px;
    }
    .sp-red{
        height:5px;width:5px;background-color:#ee0000;border-radius:10px;
    }
    .close{
        width:15px;
        height:15px;
        margin-left:10px;
    }
    .close:hover{
        color :#e00;
    }
    .weektable{
        width:100%;
        border-top:1px solid #ddd;
        border-left:1px solid #ddd;
    }
    .weektable td{
        width:14.2857%;
        overflow: hidden;
        border-right:1px solid #ddd;
        border-bottom:1px solid #ddd;
        text-align: center;
    }
    .timecon{
        padding-top: 18px;
        padding-bottom: 18px;
    }
    
    .single-select-table /deep/ thead .el-table-column--selection .cell{
        display: none;
    }
    .single-select-table /deep/ .el-checkbox__inner { width: 16px; height: 16px; border-radius: 50%; }
    .single-select-table /deep/ .el-checkbox__inner::after{
        height: 8px;
        left: 5px;
    }
    .leftList {
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
    }
    /deep/ .el-input-number .el-input__inner{
      text-align: left;
    }
    .formtip {
        width: 80px;
    }
</style>







