<template>
	<div class="order_info">
		<div class="rx-bc pt10 pb10 pl10 pr10" style="border: 1px dashed #409EFF;">
			<div class="labelList flex1 o_a">
				<div class="w500">
					<template v-for="(item,index) in storeData.list" :key="index">
						<span class="labelItem" v-if="item.checked" :style="{color:index == storeData.active?themeStore.list[themeStore.active].color:'',borderColor:themeStore.list[themeStore.active].color}" :class="{active:index == storeData.active}">{{item.newName || item.name}}</span>
					</template>
				</div>
			</div>
			<img class="w15 mr5 ml5" src="@/assets/applet/search.png" alt="">
		</div>
		<div class="rx-bc pl10 pr10 pt10 pb10" :style="{backgroundColor:themeStore.bgColor == 2 ? '#1D1D24' : '#FAFAFC'}">
			<div class="t_a_c color-666">
				<p class="ft16 fwb" :class="{'color-fff':themeStore.bgColor == 2}" >28</p>
				<p class="ft12" :class="{'color-fff':themeStore.bgColor == 2}">日</p>
			</div>
			<div class="t_a_c color-666" style="color: #FED85A;">
				<p class="ft16 fwb" :style="{color:themeStore.list[themeStore.active].color}">29</p>
				<p class="ft12" :style="{color:themeStore.list[themeStore.active].color}">一</p>
			</div>
			<div class="t_a_c color-666">
				<p class="ft16 fwb" :class="{'color-fff':themeStore.bgColor == 2}">30</p>
				<p class="ft12" :class="{'color-fff':themeStore.bgColor == 2}">二</p>
			</div>
			<div class="t_a_c color-666">
				<p class="ft16 fwb" :class="{'color-fff':themeStore.bgColor == 2}">01</p>
				<p class="ft12" :class="{'color-fff':themeStore.bgColor == 2}">三</p>
			</div>
			<div class="t_a_c color-666">
				<p class="ft16 fwb" :class="{'color-fff':themeStore.bgColor == 2}">02</p>
				<p class="ft12" :class="{'color-fff':themeStore.bgColor == 2}">四</p>
			</div>
			<div class="t_a_c color-666">
				<p class="ft16 fwb" :class="{'color-fff':themeStore.bgColor == 2}">03</p>
				<p class="ft12" :class="{'color-fff':themeStore.bgColor == 2}">五</p>
			</div>
			<div class="t_a_c color-666">
				<p class="ft16 fwb" :class="{'color-fff':themeStore.bgColor == 2}">04</p>
				<p class="ft12" :class="{'color-fff':themeStore.bgColor == 2}">六</p>
			</div>
		</div>
		<ul class="list">
			<li>
				<div class="rx-bc mt10 mb10">
					<div>
						<span class="d_i_b w5 h5 bdr4 mr5 v_a_m" style="background: #698FBC;"></span>
						<span class="ft12" :class="{'color-fff':themeStore.bgColor == 2}">09:00～10:00</span>
					</div>
					<div class="ft12" style="color: #698FBC;">预约未开始</div>

				</div>
				<div class="pt10 pl10 pb10 pr10" :style="{backgroundColor:themeStore.bgColor == 2 ? '#1D1D24' : '#FAFAFC'}">
					<div class="rx-ss mb10 posi-r">
						<img class="w80 mr12" src="@/assets/applet/course1.png" alt="">
						<div>
							<h3 class="mb5" :class="{'color-fff':themeStore.bgColor == 2}">马甲线的养成</h3>
							<p class="mb5">
								<span class="ft12 color-999" :class="{'color-fff':themeStore.bgColor == 2}">企业课</span>
							</p>
							<p class="color-red"><span class="ft10">￥</span><span class="ft12">2234.0</span></p>
						</div>
					</div>
					<div class="rx-bc pt10" :style="{'border-top': themeStore.bgColor == 2 ? '1px solid rgba(255, 255, 255, 0.16)' : '1px solid rgba(51, 51, 51, 0.16)' }">
						<div>
							<img class="w25 h25 mr12 v_a_m" src="@/assets/applet/coach2.png" alt="">
							<span class="v_a_m ft12">泽华教练</span>
						</div>
						<div class="ft12" :class="{'color-fff':themeStore.bgColor == 2}">已约 2/8</div>
					</div>
				</div>
			</li>
			<li>
				<div class="rx-bc mt10 mb10">
					<div>
						<span class="d_i_b w5 h5 bdr4 mr5 v_a_m" style="background: #44D7B6;"></span>
						<span class="ft12" :class="{'color-fff':themeStore.bgColor == 2}">09:00～10:00</span>
					</div>
					<div class="ft12" style="color: #44D7B6;">预约中</div>

				</div>
				<div class="pt10 pl10 pb10 pr10" :style="{backgroundColor:themeStore.bgColor == 2 ? '#1D1D24' : '#FAFAFC'}">
					<div class="rx-ss mb10 posi-r">
						<img class="w80 mr12" src="@/assets/applet/course1.png" alt="">
						<div>
							<h3 class="mb5" :class="{'color-fff':themeStore.bgColor == 2}">马甲线的养成</h3>
							<p class="mb5">
								<span class="ft12 color-999" :class="{'color-fff':themeStore.bgColor == 2}">企业课</span>
							</p>
							<p class="color-red"><span class="ft10">￥</span><span class="ft12">2234.0</span></p>
						</div>
						<div class="btn ft12" :style="{backgroundColor:themeStore.list[themeStore.active].color}">预约</div>
					</div>
					<div class="rx-bc pt10" :style="{'border-top': themeStore.bgColor == 2 ? '1px solid rgba(255, 255, 255, 0.16)' : '1px solid rgba(51, 51, 51, 0.16)' }">
						<div>
							<img class="w25 h25 mr12 v_a_m" src="@/assets/applet/coach2.png" alt="">
							<span class="v_a_m ft12">泽华教练</span>
						</div>
						<div class="ft12" :class="{'color-fff':themeStore.bgColor == 2}">已约 2/8</div>
					</div>
				</div>
			</li>
			<li>
				<div class="rx-bc mt10 mb10">
					<div>
						<span class="d_i_b w5 h5 bdr4 mr5 v_a_m" style="background: #44D7B6;"></span>
						<span class="ft12" :class="{'color-fff':themeStore.bgColor == 2}">09:00～10:00</span>
					</div>
					<div class="ft12" style="color: #44D7B6;">预约中</div>

				</div>
				<div class="pt10 pl10 pb10 pr10" :style="{backgroundColor:themeStore.bgColor == 2 ? '#1D1D24' : '#FAFAFC'}">
					<div class="rx-ss mb10 posi-r">
						<img class="w80 mr12" src="@/assets/applet/course1.png" alt="">
						<div>
							<h3 class="mb5" :class="{'color-fff':themeStore.bgColor == 2}">马甲线的养成</h3>
							<p class="mb5">
								<span class="ft12 color-999" :class="{'color-fff':themeStore.bgColor == 2}">企业课</span>
							</p>
							<p class="color-red"><span class="ft10">￥</span><span class="ft12">2234.0</span></p>
						</div>
						<!-- <div class="btn ft12" :style="{backgroundColor:themeStore.list[themeStore.active].color}">预约</div> -->
					</div>
					<div class="rx-bc pt10" :style="{'border-top': themeStore.bgColor == 2 ? '1px solid rgba(255, 255, 255, 0.16)' : '1px solid rgba(51, 51, 51, 0.16)' }">
						<div>
							<img class="w25 h25 mr12 v_a_m" src="@/assets/applet/coach2.png" alt="">
							<span class="v_a_m ft12">泽华教练</span>
						</div>
						<div class="ft12" :class="{'color-fff':themeStore.bgColor == 2}">已约 8/8【预约已满】</div>
					</div>
				</div>
			</li>
			<li>
				<div class="rx-bc mt10 mb10">
					<div>
						<span class="d_i_b w5 h5 bdr4 mr5 v_a_m" style="background: #44D7B6;"></span>
						<span class="ft12" :class="{'color-fff':themeStore.bgColor == 2}">09:00～10:00</span>
					</div>
					<div class="ft12" style="color: #44D7B6;">预约中</div>

				</div>
				<div class="pt10 pl10 pb10 pr10" :style="{backgroundColor:themeStore.bgColor == 2 ? '#1D1D24' : '#FAFAFC'}">
					<div class="rx-ss mb10 posi-r">
						<img class="w80 mr12" src="@/assets/applet/course1.png" alt="">
						<div>
							<h3 class="mb5" :class="{'color-fff':themeStore.bgColor == 2}">马甲线的养成</h3>
							<p class="mb5">
								<span class="ft12 color-999" :class="{'color-fff':themeStore.bgColor == 2}">企业课</span>
							</p>
							<p class="color-red"><span class="ft10">￥</span><span class="ft12">2234.0</span></p>
						</div>
						<div class="btn ft12" :style="{backgroundColor:themeStore.list[themeStore.active].color}">取消预约</div>
					</div>
					<div class="rx-bc pt10" :style="{'border-top': themeStore.bgColor == 2 ? '1px solid rgba(255, 255, 255, 0.16)' : '1px solid rgba(51, 51, 51, 0.16)' }">
						<div>
							<img class="w25 h25 mr12 v_a_m" src="@/assets/applet/coach2.png" alt="">
							<span class="v_a_m ft12">泽华教练</span>
						</div>
						<div class="ft12" :class="{'color-fff':themeStore.bgColor == 2}">已约 8/8【预约已满】</div>
					</div>
				</div>
			</li>
			<li>
				<div class="rx-bc mt10 mb10">
					<div>
						<span class="d_i_b w5 h5 bdr4 mr5 v_a_m" style="background: #44D7B6;"></span>
						<span class="ft12" :class="{'color-fff':themeStore.bgColor == 2}">09:00～10:00</span>
					</div>
					<div class="ft12" >预约结束</div>
				</div>
				<div class="pt10 pl10 pb10 pr10" :style="{backgroundColor:themeStore.bgColor == 2 ? '#1D1D24' : '#FAFAFC'}">
					<div class="rx-ss mb10 posi-r">
						<img class="w80 mr12" src="@/assets/applet/course1.png" alt="">
						<div>
							<h3 class="mb5" :class="{'color-fff':themeStore.bgColor == 2}">马甲线的养成</h3>
							<p class="mb5">
								<span class="ft12 color-999" :class="{'color-fff':themeStore.bgColor == 2}">企业课</span>
							</p>
							<p class="color-red"><span class="ft10">￥</span><span class="ft12">2234.0</span></p>
						</div>
						<div class="btn ft12" :style="{backgroundColor:themeStore.list[themeStore.active].color}">取消候补</div>
					</div>
					<div class="rx-bc pt10" :style="{'border-top': themeStore.bgColor == 2 ? '1px solid rgba(255, 255, 255, 0.16)' : '1px solid rgba(51, 51, 51, 0.16)' }">
						<div>
							<img class="w25 h25 mr12 v_a_m" src="@/assets/applet/coach2.png" alt="">
							<span class="v_a_m ft12">泽华教练</span>
						</div>
						<div class="ft12" :class="{'color-fff':themeStore.bgColor == 2}">已约 8/8【已预约】</div>
					</div>
				</div>
			</li>
			<li>
				<div  class="ft12 t_a_c" :class="{'color-fff':themeStore.bgColor == 2}">- 没有更多信息了 -</div>
				
			</li>
		</ul>
	</div>
</template>
<script setup>
	const propData = defineProps({
	  tab:String,
	  itemIndex:Number
	})
	import {ref,reactive} from 'vue'
	import { useStore } from 'vuex'
	const store = useStore();
	const storeData = store.state.appletFormData[propData.tab].list[propData.itemIndex];
	const themeStore = store.state.appletFormData[4].list[0];
</script>
<style scoped lang="stylus">
	.order_info{
		.labelItem{
			display inline-block;
			margin-right 5px;
			padding 1px 6px;
			border-radius 100px;
			color #333;
			background-color #F0F0F0;
			font-size 12px;
		}
		.labelItem.active{
			background-color #FFF1C2;
			border 1px solid #FED85A;
			color #FED85A;
		}
		.list{
			li{
				position relative;
				padding 5px 10px;
				.btn{
					font-size: 12px;
					position absolute;
					bottom: 0;
    				right: 0;
					background-color #FDC402;
					color white;
					border-radius 100px;
					padding 2px 10px;
				}
			}
		
		}
	}
</style>

