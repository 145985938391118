<template>
    <div class="pt20 pb20">
        <div class="nav">
            <div class="nav_div">1. 到账时间：交易成功的第二个工作日，法定节假日顺延</div>
            <div class="nav_div">2. 到账方式：当天所有收款订单将合并为一笔到账</div>
            <div class="nav_div">3. 若发现结算状态是成功，但对应银行卡没有相应的转账记录，请联系客服处理</div>
        </div>
        <formQuery ref="queryOptsRef" :queryOpts="queryOpts" @query="searchGetList" style="flex: 1"></formQuery>
        <el-table v-loading="pageData.loading" stripe element-loading-text="拼命加载中。。。" ref="multipleTable" :data="pageData.tableData" style="width: 100%">
			<el-table-column prop="settleDateStr" label="到账日期" />
            <el-table-column label="到账金额" >
                <template #default="scope">
                    ￥{{ scope.row.arrivalAmt ? scope.row.arrivalAmt : 0 }}
                </template>
            </el-table-column>
            <el-table-column prop="bankName" label="到账银行" />
            <el-table-column prop="bankNumber" label="到账银行卡" show-overflow-tooltip />
            <el-table-column label="状态">
                <template #default="scope">
                    <span class="color-f56c6c" v-if="scope.row.settleStat == 'S'">成功</span>
                    <span class="color-67c23a" v-else>失败</span>
                </template>
            </el-table-column>
            <el-table-column label="备注" >
                <template #default="scope">
                    {{ scope.row.settleMessage  ? scope.row.settleMessage : '-' }}
                </template>
            </el-table-column>
            <template #empty>
                <el-empty description="暂无数据"></el-empty>
            </template>
        </el-table>
        <div class="mt20 rx-bc">
            <div>
                <span class="mr20">到账成功：<span class="color-red mr5">{{ pageData.remark.arrivalAmt || 0 }}</span>元</span>
                <span>到账失败：<span class="color-red mr5">{{ pageData.remark.arrivalAmtFail || 0 }}</span>元</span>
            </div>
		    <el-pagination
		      v-model:currentPage="pageData.searchForm.page"
		      :page-sizes="[15,20,30,50,100]"
		      :page-size="pageData.searchForm.limit"
		      layout="total, sizes, prev, pager, next, jumper"
		      :total="pageData.totalCount"
		      @size-change="pageSizeChange"
		      @current-change="()=>{getList()}"
		    >
		    </el-pagination>
		</div>
    </div>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import formQuery from '@/components/branch-store/formQuery.vue';
    import { ElMessage } from 'element-plus'
    import { upayOrderSettleDetail } from '@/api/finance.js';
    const queryOptsRef = ref()
    const pageData = reactive({
        loading: false,
        tableData: [],
        searchForm: {
            limit: 15,
            page: 1
        },
        totalCount: 0,
        remark: {}
    })
    const timerList = [
        { value : "1" , label : '今天' },
        { value : "2" , label : '昨天' },
        { value : "5" , label : '最近7天' },
        { value : "7" , label : '最近30天' },
        { value : "20" , label : '本月' },
        { value : "-1" , label : '其他' }
    ]
    const queryOpts = reactive({});
    queryOpts['fields'] = [{
        type : 'select',
        fieldName : 'timeIn',
        placehold : '支付时间',
        value : '5',
        options : timerList
    },{
        showField : 'timeIn', // 根据显示字段
        showValue : '-1', // 根据显示字段值
        type : 'dateRange',
        fieldName : 'settleDateStr',
        placeholder : '日期范围',
        value:''
    },{
		type : 'select',
		fieldName : 'settleStat',
		placehold : '',
		options: [{ label: '全部状态', value: '' },{ label: '成功', value: 'S' },{ label: '失败', value: 'F' }],
        value:''
	}]
    /**
     * 切换每页条数
     */
    function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		getList();
	}
    /**
     * 搜索
     */
	function searchGetList() {
	   pageData.searchForm.page = 1;
       getList()
	}
    /**
     * 获取表格数据
     */
    function getList() {
        let params = {}
        Object.assign(params, queryOptsRef.value.getQueryForm())
		params.limit = pageData.searchForm.limit
		params.page = pageData.searchForm.page
        if (params.timeIn == '-1' && !params.settleDateStr) {
            ElMessage.warning('请选择自定义时间')
            return false
        }
        if (params.timeIn == '-1' && params.settleDateStr) {
            params.settleDateStr = params.settleDateStr.join('-')
        }
        pageData.loading = true
        upayOrderSettleDetail(params).then((res)=>{
			pageData.loading = false
			if(res.code == 0){
				pageData.tableData = res.data
				pageData.totalCount = res.count
				pageData.remark = res.remark || {}
			}
		}).catch(() => {
            pageData.loading = false
        })
    }
    onMounted(() => {
        getList()
    })
</script>
<style type='css/style' scoped>
    .nav {
        padding: 8px 5px;
        background: #E6F7FF;
        border: 1px solid #92D5FF;
        border-radius: 4px;
        color: #666;
    }
    .nav_div {
        font-size: 12px;
        padding: 2px 10px
    }
</style>