<template>
    <div class="pt20 pb20" v-loading="pageData.loading">
        <div class="nav">
            <span class="d_i_b c_p" :class="pageData.tabNum == item.val ? 'active': '' " v-for="(item, index) in pageData.tabeList" :key="index" @click="getContentList(item.val)">{{ item.label }}</span>
        </div>
        <div class="top rx-bc mt15">
            <div>
                <span class="c_p ft16 v_a_m" :class="{ active:pageData.dimension == 1 }" @click="dimension(1)">日期</span>
                <span class="divider v_a_m"></span>
                <span class="c_p ft16 v_a_m" :class="{ active:pageData.dimension == 2 }" @click="dimension(2)">教练</span>
            </div>
            <div>
                <span class="color-999 ft12 mr20">温馨提示：当前页面数据仅统计截止至当前时间</span>
                <a href="javascript:;" class="t_d_u color-409EFF tool_btn ft12" @click="formula">计算公式</a>
            </div>
        </div>
        <div class="rx-bc mt20">
            <div class="formSearch">
                <el-form :inline="true" :model="pageData.formInline" class="demo-form-inline">
                    <el-form-item v-if="pageData.tabNum == 12">
                        <el-select v-model="pageData.formInline.courseType" @change="getCourse(pageData.formInline.courseType)">
                            <el-option label="全部课程类型" value="" />
                            <el-option label="私教1V1" value="14" />
                            <el-option label="私教1V多" value="15" />
                        </el-select>
                    </el-form-item>
                    <el-form-item >
                        <el-select
                            v-model="pageData.formInline.courseNameIds"
                            multiple
                            collapse-tags
                            placeholder="全部课程"
                        >
                            <el-option v-for="(item, index) in pageData.courseList" :key="index" :label="item.courseName" :value="item.courseNameId" />
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                      <el-select
                        v-model="pageData.formInline.teacherIds"
                        multiple
                        collapse-tags
                        placeholder="全部教练"
                    >
                        <el-option v-for="(item, index) in pageData.teacherList" :key="index" :label="item.realName" :value="item.id" />
                      </el-select>
                    </el-form-item>
                    <el-form-item>
                      <el-select v-model="pageData.formInline.timeIn" @change="changeTimeIn">
                        <el-option v-for="(item, index) in dataList" :key="index" :label="item.label" :value="item.value" />
                      </el-select>
                    </el-form-item>
                    <el-form-item v-if="pageData.formInline.timeIn == -1">
                        <el-date-picker
                            style="height: 32px"
                            v-model="pageData.formInline.searchTime"
                            type="daterange"
                            range-separator="至"
                            format="YYYY-MM-DD"
                            value-format="YYYY-MM-DD"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :disabled-date="pageData.disabledDate"
                        />
                    </el-form-item>
                    <el-form-item v-if="pageData.formInline.timeIn == -2">
                      <el-date-picker
                            style="height: 32px"
                            v-model="pageData.formInline.searchTime"
                            type="monthrange"
                            format="YYYY-MM"
                            value-format="YYYY-MM"
                            range-separator="至"
                            start-placeholder="开始月份"
                            end-placeholder="结束月份"
                            :disabled-date="pageData.setMonthDisabled"
                        />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" :icon="Search" @click="search">搜索</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="w235 mb15">
                <el-button :icon="Download" @click="exportEcharts">下载图表</el-button> 
                <el-button :icon="Upload" @click="getInfo(pageData.tabNum, true)">导出表格</el-button>
            </div>
        </div>
        <div class="tabe-data rx-sc">
            <div class="tabe_div rx-bc">
                <div class="tabe_div_left">
                    <div class="color-333 fwb ft32 mb8">{{ pageData.remark.validCourseNum }}</div>
                    <div class="color-999 ft16">有效上课课节数</div>
                </div>
                <div class="tabe_div_right posi-r">
                    <img class="w60 h60" src="@/assets/bookIcon.png" alt="">
                    <div class="div_right_img ft14 color-fff fwb posi-a bg-F8CE66">节</div>
                </div>
            </div>
            <div class="tabe_div rx-bc">
                <div class="tabe_div_left">
                    <div class="color-333 fwb ft32 mb8">{{ pageData.remark.validCourseTimeH }}h</div>
                    <div class="color-999 ft16">有效上课总时长</div>
                </div>
                <div class="tabe_div_right posi-r">
                    <img class="w60 h60" src="@/assets/timeIcon.png" alt="">
                    <div class="div_right_img ft14 color-fff fwb posi-a bg-73B0FA">时</div>
                </div>
            </div>
            <div class="tabe_div rx-bc">
                <div class="tabe_div_left">
                    <div class="color-333 fwb ft32 mb8">{{ pageData.remark.confirmClassCourse || 0 }}</div>
                    <div class="color-999 ft16">已签到人数</div>
                </div>
                <div class="tabe_div_right posi-r">
                    <img class="w60 h60" src="@/assets/peopleIcon.png" alt="">
                    <div class="div_right_img ft14 color-fff fwb posi-a bg-FA73B0">人</div>
                </div>
            </div>
            <div class="tabe_div rx-bc">
                <div class="tabe_div_left">
                    <div class="color-333 fwb ft32 mb8">￥{{ pageData.remark.validCourseMoney }}</div>
                    <div class="color-999 ft16">有效课节消费金额</div>
                </div>
                <div class="tabe_div_right posi-r">
                    <img class="w60 h60" src="@/assets/boxIcon.png" alt="">
                    <div class="div_right_img ft14 color-fff fwb posi-a bg-B773FA">额</div>
                </div>
            </div>
        </div>
        <div class="color-333 fwb ft16 mt20 text">{{ pageData.dimension == 1 ? '有效上课趋势' : '教练上课汇总' }}</div>
        <div id="category" class="h400 mt25"></div>
        <div class="mt10">
            <el-table :data="pageData.tableData.slice((pageData.page-1)*pageData.limit, pageData.page*pageData.limit)" stripe >
				<el-table-column prop="courseTeacherName" label="教练姓名" v-if="pageData.dimension == 2 " show-overflow-tooltip />
                <el-table-column prop="classDateStr" label="日期" v-if="pageData.dimension == 1"/>
				<el-table-column prop="validCourseNum" label="有效上课节数" sortable  />
				<el-table-column prop="validCourseTimeH" label="有效上课时长" sortable >
					<template #default="scope">
						<span>{{ scope.row.validCourseTimeH }} h</span>
					</template>
				</el-table-column>
				<el-table-column prop="confirmClassCourse" label="已签到人数" sortable >
					<template #default="scope">
						<span>{{ scope.row.confirmClassCourse ? scope.row.confirmClassCourse : '-'}}</span>
					</template>
				</el-table-column>
                <el-table-column prop="validCourseMoney" label="有效课节消费金额(元)" sortable />
			</el-table>
            <div class="mt20 rx-ec">
		        <el-pagination
		          v-model:currentPage="pageData.page"
		          :page-sizes="[15,20,30,50,100]"
		          :page-size="pageData.limit"
		          layout="total, sizes, prev, pager, next, jumper"
		          :total="pageData.totalCount"
		          @size-change="classPageSizeChange"
		        >
		        </el-pagination>
		    </div>
        </div>
        <Formula v-model="pageData.formulaShow" :type="pageData.formulaType"></Formula>
    </div>
</template>

<script setup>
    import { ref, reactive, onMounted, onBeforeUnmount } from 'vue';
    import { Search, Upload, Download } from '@element-plus/icons-vue'
    import {ElMessage} from  'element-plus';
    import utils from '@/common/utils.js';
    import { getStoreAccountStaffSimple } from '@/api/staff.js';
    import { courseList } from '@/api/course.js';
    import { courseInfoAnalysisDate, courseInfoAnalysisTeacher } from '@/api/dataCenter.js';
    import Formula from '@/components/branch-store/datacenter/formula.vue';
    import * as echarts from 'echarts';
    let myChart = null
    const dataList =[
        { value : "5" , label : '近7天' },
        { value : "7" , label : '近30天' },
        { value : "8" , label : '近60天' },
        { value : "9" , label : '近90天' },
        { value : "24", label : '近6个月' },
        { value : "25", label : '近12个月' },
        { value : "-1" , label : '自定义日期'},
        { value : "-2" , label : '自定义月份' }
    ]
    const pageData = reactive({
        loading: false,
        tabNum: 11,
        dimension: 1,
        teacherList: [],
        courseList: [],
        tabeList: [{
            label: '团体课',
            val: 11
        }, {
            label: '私教课',
            val: 12
        }], // 头部tabe数组（单选伪装）
        formInline: {
            timeIn: '5',
            courseType: '',
            courseNameIds: '',
            teacherIds: '',
            searchTime: []
        },
        tableData: [],
        page:1,
        limit: 15,
        totalCount: 0,
        echartsData: [11,11,11,12], // 图标数据
        xData: [11,12,13,14], // x轴数据
        remark: {},
        echartsShow: false,
        formulaShow: false,
        formulaType: '',
        disabledDate(time) {
          return time.getTime() > new Date().getTime()
        },
        setMonthDisabled(time) {
            // 获取当前的月份信息
            const date = new Date(); // 获取当前的时间基本信息，值是这样的： Tue Jul 20 2021 14:59:43 GMT+0800 (中国标准时间)
            const year = date.getFullYear(); // 获取当前年份，值是这样的： 2021
            let month = date.getMonth() + 1; // 获取当前月份，值是这样的： 6   getMonth()方法返回值是0-11，也就是1月份到12月份，所以要加上1，才是当前月份
            if (month >= 1 && month <= 9) {
              // 如果是1月到9月就要在前面补上一个0   比如：02、07    月份这样表示
              month = "0" + month;
            }
            const nowDate = year.toString() + month.toString(); // 转换成字符串拼接，最终得到年和月，比如此时的时间是2021年7月20号，所以nowDate的值是：202107
            // 获取时间选择器的月份信息
            const timeyear = time.getFullYear(); // 获取时间选择器的年份（有很多）
            let timemonth = time.getMonth() + 1; // 与上面同理
            if (timemonth >= 1 && timemonth <= 9) {
              timemonth = "0" + timemonth;
            }
            const elTimeData = timeyear.toString() + timemonth.toString();

            // 返回，时间选择器的日期月份大于当前日期的月份，又因为disabledDate函数是控制月份禁用不可选
            // 所以，最终就是：时间选择器的月份大于当前的月份，就都禁用掉，所以就实现了最终效果：
            // 大于等于当前月份都不可选 
            return elTimeData > nowDate; // 这里虽然是字符串，但是弱类型语言js会做一个转换，是可以比较大小的如： '202107' > '202008'
        },
        option:{
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            title: {
              text: '上课数 / 节',
              textStyle: {
                  color: '#666',
                  fontSize: 14,
                  fontWeight: 400
              },
            },
            legend: {
                data: ['上课节数']
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                data: [],
                axisLabel: {
                    interval: 6,
                    rotate: 35
                }
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    name: '上课节数',
                    data: [],
                    type: 'line',
                    smooth: true,
                    symbol: 'circle',
                    label: {
						show: true,
                        position: 'top'
					},
                    symbolSize: 6,
                    itemStyle:{
                        normal: {
                            color: '#FD7D7D', 
                            borderColor:'#FFF',
                            borderWidth:1,
                            lineStyle: {
                                color: '#FD7D7D',
                                shadowColor: 'rgba(252, 125, 125, 0.3)',//设置折线阴影
                                shadowBlur: 1,
                                shadowOffsetY: 10,
                            }
                        }
                    },
                }
            ]
        }
    })
    /**
     * 计算公式
     */
    function formula() {
        pageData.formulaType = '1'
        pageData.formulaShow = true
    }
    /**
     * 下载图表
     */
    function exportEcharts() {
        let myChart = echarts.init(document.getElementById('category'))
        const picInfo = myChart.getDataURL({
          type: 'png',
          pixelRatio: 1,
          backgroundColor: '#FFF',
          excludeComponents: 'toolbox'
        });
        const elink = document.createElement('a');
        elink.download = '图表';
        elink.style.display = 'none';
        elink.href = picInfo;
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink)
    }
    /**
     * 全部课程
     */
    function getCourse(value) {
        let params = {
            page: 1,
            limit: 9999,
        }
        params.courseType = value ? value : pageData.tabNum
        courseList(params).then((res)=>{
            if(res.code == 0){
                pageData.courseList= res.data
            }
        })
    }
    /**
     * 全部教练
     */
    function getTeacher() {
        let params = {
            page: 1,
            limit: 9999,
            delFlag: 0,
        }
        params.teachType = pageData.tabNum == 11 ? 1: 2
        getStoreAccountStaffSimple(params).then((res)=>{
            if(res.code == 0){
                pageData.teacherList = res.data
            }
        })
    }
    /**
     * 切换日期范围
     */
    function changeTimeIn(val) {
        pageData.formInline.searchTime = []
    }
    /**
     * 切换团课/私教
     */
    function getContentList(val) {
        pageData.formInline = {
            timeIn: '5',
            courseType: '',
            courseNameIds: '',
            teacherIds: '',
            searchTime: []
        } 
        pageData.tabNum = val
        getInfo(pageData.tabNum)
        getTeacher()
        getCourse()
    }
    /**
     * 切换日期/教练
     */
    function dimension(val) {
        pageData.dimension = val
        pageData.formInline = {
            timeIn: '5',
            courseType: '',
            courseNameIds: '',
            teacherIds: '',
            searchTime: []
        }
        getInfo(pageData.tabNum)
    }
    /**
     * 获取数据信息
     */
    function getInfo(val, isExport) {
        let params = {
            timeIn: pageData.formInline.timeIn,
            searchTime: pageData.formInline.searchTime.join('-'),
            courseNameIds:pageData.formInline.courseNameIds && pageData.formInline.courseNameIds.join(','),
            teacherIds:pageData.formInline.teacherIds && pageData.formInline.teacherIds.join(',')
        }
        if (val == 11) {
            params.courseType = 11
        }
        if (val == 12) {
            if (!pageData.formInline.courseType) {
                params.courseType = 12
            } else {
                params.courseType = pageData.formInline.courseType
            }
        }
        if(isExport){ //导出
			params.exportData = 1;
		} else {
            pageData.loading = true
        }
        let apiName = pageData.dimension == 1 ? courseInfoAnalysisDate : courseInfoAnalysisTeacher
        myChart = echarts.init(document.getElementById('category'))
        apiName(params).then(res => {
            pageData.loading = false
            if (res.code == 0) {
                if(isExport){ //导出
					ElMessage({
						type: 'success',
						message: '正在导出，请稍后前往下载中心下载'
					})
				} else {
                    pageData.totalCount = res.count
                    pageData.tableData = res.data
                    pageData.remark = res.remark || {}
                    pageData.option.series[0].data = []
				    pageData.option.xAxis.data = []
                    pageData.option.series[0].type = pageData.dimension == 1 ? 'line' : 'bar'
                    pageData.option.series[0].barWidth = 30
                    pageData.option.series[0].itemStyle.normal.color = pageData.dimension == 1 ? '#FD7D7D' : '#F7C74F'
                    pageData.option.series[0].itemStyle.normal.lineStyle.color = pageData.dimension == 1 ? '#FD7D7D' : '#F7C74F'
                    let arr = JSON.parse(JSON.stringify(res.data))
                    if (pageData.dimension == 1) {
                        arr.sort(function (a, b) {
                            return a.classDateStr > b.classDateStr ? 1 : -1;
                        })
                    }
                    arr&&arr.forEach((item, index) => {
                        if (pageData.dimension == 1) {
                            pageData.option.xAxis.data.push(item.classDateStr)
                        } else {
                            if(index < 20){
                                pageData.option.xAxis.data.push(item.courseTeacherName)
                            }
                        }
				    	pageData.option.series[0].data.push(item.validCourseNum)
				    })
                    if (arr.length > 10) {
                        pageData.option.xAxis.axisLabel = {
                            interval:0,
                            rotate: 40
                        }
                    } else {
                        pageData.option.xAxis.axisLabel = {
                            interval:0,
                            rotate: 0
                        }
                    }
		            myChart.setOption( pageData.option, true);
		            window.addEventListener("resize",function(){
		                myChart.resize();
                    });
                }
            }
        }).catch(() => {
            pageData.loading = false
        })
    }
    function classPageSizeChange(limit){
		pageData.limit = limit;
	}
    /**
     * 搜索
     */
    function search() {
        if (pageData.formInline.timeIn == '-1') {
            if (!pageData.formInline.searchTime || !pageData.formInline.searchTime.length) {
                ElMessage({
                    type : 'warning',
                    message : '请选择日期'
                });
                return;
            }
            if (utils.datedifference(pageData.formInline.searchTime[0], pageData.formInline.searchTime[1]) > 31) {
                ElMessage({
                    type : 'warning',
                    message : '自定义日期不能超过31天'
                });
                return;
            }
        }
        if (pageData.formInline.timeIn == '-2') {
            if (!pageData.formInline.searchTime || !pageData.formInline.searchTime.length) {
                ElMessage({
                    type : 'warning',
                    message : '请选择月份'
                });
                return;
            }
            if (utils.MonthsBetw(pageData.formInline.searchTime[0], pageData.formInline.searchTime[1]) > 12) {
                ElMessage({
                    type : 'warning',
                    message : '自定义月份不能超过12个月'
                });
                return;
            }
        }
        pageData.page = 1
        getInfo(pageData.tabNum)
    }
    
    
    onMounted(() => {
        getInfo(11)
        getTeacher()
        getCourse()
    })
    onBeforeUnmount(() => {
      if (!myChart) {
        return
      }
      myChart.dispose()
      myChart = null
    })
</script>
<style type='css/style' scoped>
    .nav {
        background: #F2F2F2
    }
    .nav span{
        padding: 7px 20px 8px 18px;
        color: #666;
    }
    .nav .active {
        background: #03A3FF;
        color: #FFF;
        border-radius: 2px;
    }
    .top .divider{
        display: inline-block;
        width: 2px;
        height: 16px;
        margin: 0 20px;
        background: #A8ABB3;
    }
    .top .active{
        color: #00A3FF;
        position: relative;
    }
    .top .active::after{
        content: '';
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        width: 60%;
        border: 1px solid #00A3FF;
        background-color: #00A3FF;
    }
    .formSearch ::v-deep .el-form--inline .el-form-item {
        margin-right: 16px;
        margin-bottom: 15px;
    }
    ::v-deep .el-range-editor.el-input__inner {
        padding: 0 10px;
    }
    .tabe-data{
        height: 148px;
        background: #EFF6FF;
        padding: 21px 20px 23px 21px;
    }
    .tabe_div{
        height: 104px;
        background: #FFF;
        padding: 13px 32px 16px 34px;
        margin-right: 20px;
        width: 25%;
    }
    .div_right_img{
        width: 24px;
        height: 24px; 
        border-radius: 50%;
        text-align: center;
        line-height: 25px;
        bottom: 0;
        right: 0;
    }
    .text{
        padding-left: 16px;
        border-left: 4px solid #00A3FF;
    }
</style>