<template>
	<!-- <MoveSort :list="storeData.list" @moveover="over">
		<template #[index] v-for="(item,index) in storeData.list"> -->
			<div :ref="listDom" class="pt10 pl10 pr10 pb10" :class="{compActive:storeData.active == index}" v-for="(item, index) in storeData.list" :key="index">
				<component :is="data.components[item.component]" v-show="item.moduleStatus" :key="(new Date()).getTime()" :itemIndex="index" :tab="tab"></component>
			</div>
		<!-- </template>
	</MoveSort> -->
</template>

<script setup>
	const propData = defineProps({
	  tab:String,
	  activeIndex: Number
	})
	import { ref, reactive, onBeforeUpdate, onMounted} from 'vue'
	import { useStore } from 'vuex'
	// import MoveSort from '../MoveSort.vue'; //通用移动排序
	import StoreInfo from './homeConfig/StoreInfo.vue'; //门店介绍
	import FunEntry from './homeConfig/FunEntry.vue'; //功能入口
	import CoachIntroduction from './homeConfig/CoachIntroduction.vue'; //教练介绍
	import RecommendedCourses from './homeConfig/RecommendedCourses.vue'; //推荐课程
	import RecommendedCycles from './homeConfig/RecommendedCycles.vue'; //推荐班级
	import CourseCard from './homeConfig/CourseCard.vue'; //课程卡
	import EntranceCard from './homeConfig/EntranceCard.vue'; //入场卡
	import StoredCard from './homeConfig/StoredCard.vue'; //储值卡
	import ExperienceCard from './homeConfig/ExperienceCard.vue'; //体验卡
	const data = reactive({
		components:{
			StoreInfo,
			FunEntry,
			CoachIntroduction,
			RecommendedCourses,
			RecommendedCycles,
			EntranceCard,
			CourseCard,
			StoredCard,
			ExperienceCard
		}
	})
	const store = useStore();
	const storeData = store.state.appletFormData[propData.tab]
	
	let resultList = [];
	const listDom = (e) => {
      resultList.push(e);
    };
	const getListDom = () => {
	  	resultList[storeData.active].scrollIntoView()
    };
	onMounted(()=>{
		getListDom()
	})
	onBeforeUpdate(() => {
      resultList = [];
    });
</script>

<style scoped lang="stylus">
	.compActive{
		border 1px dashed #409eff;
	}
</style>
