<template>
    <workArea :barlist="barlist">
        <div class="ww100 h10"></div>
        <!-- tab切换 -->
        <div class="pl20 pr20" style="margin-bottom:-15px;">
            <el-tabs v-model="pageData.tabNum" @tab-click="handleClick">
                <el-tab-pane label="团课排期" name="0" v-if="utils.getMenuRole(31500)"></el-tab-pane>
                <el-tab-pane label="私教课预约表" name="1" v-if="utils.getMenuRole(31501)"></el-tab-pane>
                <el-tab-pane label="小班课排期" name="2" ></el-tab-pane>
            </el-tabs>
        </div>
        <component :is="components[pageData.tabNum]" :tab="pageData.tabNum"></component>
    </workArea>
</template>

<script>
  export default {
    name: 'BranchCourseTable'
  }
</script>

<script setup>
    import { ref, reactive, onMounted, onBeforeUnmount} from 'vue';
    import workArea from '@/components/branch-store/workArea.vue';
    import Team from '@/views/branch-store/work/courseTable/team.vue' //团课
    import Private from '@/views/branch-store/work/courseTable/private.vue' //私教课
    import SmallClass from '@/views/branch-store/work/courseTable/smallClass.vue' // 小班课排期
	import utils from '@/common/utils.js'; 
    const barlist = reactive([{ name : '首页' },{ name : '教务中心' }, { name : '课表管理' }]); // 面包屑
    const components = [Team,Private,SmallClass];
    const pageData = reactive({
        tabNum:'0',
    });
	var menuId = [31500,31501]; //会员有权限tab默认项
	for(var i = 0;i < menuId.length;i++){
		if(utils.getMenuRole(menuId[i])){
			pageData.tabNum = i.toString();
			break;
		}
	}  
    function handleClick(tab) {
       localStorage.setItem('courseTable', tab.props.name)
	}
	onMounted(()=> {
        if (localStorage.getItem('courseTable') != null) {
           pageData.tabNum = localStorage.getItem('courseTable')
	    }
	})
	onBeforeUnmount(() => {
		localStorage.removeItem('courseTable')
	})
</script>

<style type="text/css">

</style>


