<template>
    <workArea :barlist="barlist">
        <div class="ww100 rx-bc">
            <div class="cx-sc flex1" :style="{height:windowHeight + 'px'}">
                <div class="ww100 h30 rx-sc mt16">
                    <div class="title-bar ml20"></div><div class="ml10 fwb">快捷栏</div>
                </div>
                <div class="h100 rx-bc mt40" style="width:95%">
					<template v-for="(item, index) in pageData.quickList" :key="index">
						<div class="w100 h100 cx-cc" @click="jump(item)" v-if="utils.getMenuRole(item.menuId)">
						    <img :src="item.imgUrl" class="w50 h50 b_r_100 c_p" />
						    <div class="mt16" style="color:#3E4468">{{ item.name }}</div>
						</div>
					</template>
                </div>
                <div class="ww100 h30 rx-sc mt25" v-if="utils.getMenuRole(30401)">
                    <div class="title-bar ml20"></div><div class="ml10 fwb">会员入离场</div>
                </div>
                <div class="ww100 h80 mt10 rx-sc" v-if="utils.getMenuRole(30401)">
                    <div class="w555 h45 ml35 rx-bc searchInput">
                        <el-select
                            popper-class="el-select-option"
                            v-model="pageData.member"
                            placeholder="请输入会员姓名/手机号搜索"
					        filterable
					        remote
					        reserve-keyword
					        :remote-method="searchMember"
					        :loading="pageData.searchLoading"
                            @change="searchData"
                        >
                            <el-option 
                              v-for="item in pageData.checkUserList"
                              :key="item.encryptionId"
                              :label="item.realName"
                              :value="item.encryptionId"
                            >
                                <span class="f_l">{{item.realName}}</span>
                                <span class="f_r">{{item.telephoneNum}}</span>
                            </el-option>
                        </el-select>
                        <div class="w110 ipt-r bg-main color-fff rx-cc ft16" @click="search"><Search class="w20 h20 mr10"></Search>搜索</div>
                    </div>
                </div>
                
                <div class="ww100 h30 rx-sc mt16" v-if="utils.getMenuRole(30402)">
                    <div class="title-bar ml20"></div><div class="ml10 fwb">会员签单</div>
                </div>

                <div class="ww100 rx-sc" style="padding:20px 35px;" v-if="utils.getMenuRole(30402)">
                    <div class="h60 bdr4 mr20 ww25 rx-cc posi-r c_p" :style="{'background-color': item.back}"
                      v-for="(item, index) in pageData.signatureLsit" :key="index"
                      @click="memberSignature(item.value)"
                    >
                        <img :src="item.imgUrl" class="w35 h35 mr10" />
                        <div class="text">{{ item.name }}</div> 
                        <img src="@/assets/branch/reception/sign.png" class="w50 h50 posi-a" style="right:-10px;bottom:-10px;" />
                    </div>
                </div>
                <div class="ww100 rx-sc" style="padding:10px 35px;" v-if="utils.getMenuRole(30402)">
                    <div class="h60 bdr4 mr20 ww25 rx-cc posi-r c_p" :style="{'background-color': item.back}"
                        v-for="(item, index) in pageData.signatureNextLsit" :key="index"
                        @click="memberSignature(item.value)"
                    >
                        <img :src="item.imgUrl" class="w35 h35 mr10" />
                        <div class="text">{{ item.name }}</div> 
                        <img src="@/assets/branch/reception/sign.png" class="w50 h50 posi-a" style="right:-10px;bottom:-10px;" />
                    </div>
                    <div class="mr20 ww25"></div>
                    <div class="mr20 ww25"></div>
                </div>
            </div>
            <div class="w10 hh100 bg-gray" :style="{height:windowHeight+'px'}"></div>
            
            <div class="w400" :style="{height:windowHeight+'px'}">
                <div class="ww100 h30 rx-bc mt16">
                    <div class="pl20" v-show="pageData.isPresence">
                        <el-checkbox
                            v-model="pageData.checkAll"
                            :indeterminate="pageData.isIndeterminate"
                            @change="handleCheckAllChange"
                        >全选</el-checkbox>
                    </div>
                    <div class="pr10" v-show="pageData.isPresence">
                        <el-link type="primary" :underline="false" @click="presence">离场</el-link>
                        <el-divider direction="vertical" />
                        <el-link type="danger" plain :underline="false" @click="closePresence">取消</el-link>
                    </div>
                    <div class="rx-sc" v-show="!pageData.isPresence">
                        <div class="title-bar ml20"></div><div class="ml10 fwb">当前在场: {{ pageData.presenceList.length || 0 }} 人 </div>
                    </div>
                    <div  v-if="utils.getMenuRole(30401)" class="rx-ec mr10 c_p" v-show="!pageData.isPresence" @click="pageData.isPresence = !pageData.isPresence">
                        <el-link class="ft12" type="primary" :underline="false">批量离场</el-link>
                        <img src="@/assets/branch/reception/pllc.png" class="w15 h15 ml5"/>
                    </div>
                </div>
                <div class="scrollbar pl10 pr10">
                    <el-scrollbar max-height="335px">
                        <el-checkbox-group
                            v-model="checkedCities"
                            @change="handleCheckedCitiesChange"
                        >
							<template v-if="pageData.presenceList.length">
								<div v-for="(item, index) in pageData.presenceList" :key="index" :class="{active:pageData.member == item.encryptionCardMyId}" class="c_p rx-sc p5 pt10 pb10 pl10 pr10 scrollbar_item" >
									<el-checkbox v-show="pageData.isPresence" :label="item">{{''}}</el-checkbox>
									<div class="rx-bc" style="flex: 1" @click="departure(item.encryptionCardMyId)">
										<div class="rx-sc">
											<el-avatar :size="50" :src="item.headImgUrl"></el-avatar>
											<div class="ml15">
												<div class="mb5 rx-sc">
													<span class="color-333 mr5">{{ item.realName }}</span>
													<span class="d_i_b ft12 bdr100 pl5 pr5" style="color:#FB0008;background-color:rgba(251, 41, 47, .2)" v-if="item.time">{{item.time == -1 ? '即将超时' : '超时'+item.time}}</span>
												</div>
												<div class="color-999">{{ item.telephoneNum }}</div>
											</div>
										</div>
										<div >
											<div class="color-999">{{ item.enterSiteTimeShort }}入场</div>
											<div class="color-999 t_a_r">{{getConsumeType(item.consumeType)}}</div>
										</div>
									</div>
								</div>
							</template>
							<template v-else>
								<el-empty description="暂无在场人员" />
							</template>
                        </el-checkbox-group>
                    </el-scrollbar>
                </div>
                <el-carousel class="p10" height="215px">
                  <el-carousel-item v-for="item in pageData.tabList" :key="item" @click="openTab(item.linkUrl)">
                    <img :src="item.imageUrl" class="w400 h215"/>
                  </el-carousel-item>
                </el-carousel>
                <div class="ww100 h30 rx-bc mt10">
                    <div class="rx-sc">
                        <div class="title-bar ml20"></div><div class="ml10 fwb">产品公告动态</div>
                    </div>
                    <div class="rx-ec mr10 c_p">
                        <el-link :underline="false" @click="seeMore">
                            查看更多
                            <el-icon class="ml5">
                              <DArrowRight />
                            </el-icon>
                        </el-link>
                    </div>
                </div>
                <div class="p20 pt10">
                    <el-link class="mb15" v-for="item in pageData.noticeList" :key="item" @click="seeNotice(item.id)">
                        <span class="mr10">{{ utils.getFormatDate2(item.createTime) }}</span>
                        <span>{{ item.title }}</span>
                    </el-link>
                </div>
            </div>
        </div>
        <el-dialog
            v-model="pageData.dialogVisible"
            :title="pageData.title"
            :width="875"
            :before-close="handleClose"
        >
            <div class="p20">
                <div class="title rx-c">
                    <div></div>
                    会员信息
                </div>
                <div class="rx-bc mt20">
                    <div class="ww80">
                        <div class="posi-r d_i_b">
                            <el-avatar :size="60" :src="pageData.userInfo.avatar"></el-avatar>
                            <!-- <img class="sex posi-a" src="@/assets/male.png" alt=""> -->
                            <img class="sex posi-a" src="@/assets/female.png" alt="">
                        </div>
                        <div class="ml15 d_i_b v_a_t ww80">
                            <div>
                                <h4 class="d_i_b">{{ pageData.userInfo.name }}</h4>
                                <div class="ml5 d_i_b" :class="pageData.userInfo.trialFlag == 1 ? 'tag_blue' : 'tag_red'">{{ pageData.userInfo.trialFlag == 1 ? "正式会员" : "潜在会员" }}</div>
                            </div>
                            <div class="color-333 mt15">
                                <img class="w20 h20 mr5 v_a_m" src="@/assets/try_icon6.png" alt="">
                                <span class="v_a_m ">{{ pageData.userInfo.phone }}</span>
                            </div>
                            <div class=" mt15">
                                <span class="pl5 pr5 mr5 bdr2" v-for="(item, index) in pageData.userInfo.userTag" :key="index" :style="{color:'#'+item.fontColor,backgroundColor:'#'+item.backColor}">{{item.tagName}}</span>
                            </div>
                            <div class="color-333 mt15">
                                <span class="d_i_b w200">跟进员工：{{ pageData.userInfo.staff }}</span>
                                <span>备注：{{ pageData.userInfo.remark }}</span>
                            </div>
                            
                        </div>
                    </div>
                    <el-button type="primary" @click="toMemberDetail">会员详情</el-button>
                </div>
                <div class="title rx-c mt20">
                    <div></div>
                    入场凭证
                </div>
                <div class="mt10 mb10 t_a_r">
                    <span v-if="pageData.title == '会员离场'">入场时间： {{ pageData.enterOut.enterSiteTime }}</span>
                    <el-checkbox v-if="pageData.title == '会员入场'" v-model="pageData.checked">凭课入场后直接签到课程</el-checkbox>
                </div>
                <div class="h400 o_y_s">
                    <div class="box-border bdr4" v-if="pageData.courseList.length">
                        <div class="rx-c" v-for="(item, index) in pageData.courseList" :key="index">
                        <el-checkbox v-if="pageData.title == '会员入场'" v-model="item.checkbox" :label="item.id" size="large" @change="changeCourse">{{''}}</el-checkbox>
                        <el-image class="w120 h80 bdr4" :src="item.courseCover"></el-image>
                        <div class="ml15">
                            <div class="mb20">
                                <span
                                    class="d_i_b bdr2 pl5 pr5"
                                    :style="{
                                        color: courseType[item.courseType].color,
                                        backgroundColor:courseType[item.courseType].color+'30',
                                        border:'1px solid '+courseType[item.courseType].color}"
                                >
                                    {{courseType[item.courseType].text }}
                                </span>
                                <h4 class="d_i_b ml10">{{item.courseName}}</h4>
                            </div>
                            <div class="rx-cc">
                                <span class="mr50 d_i_b">上课班级：{{ item.cycleName }}</span>
                                <span class="mr50 d_i_b">教练：{{ item.courseTeacherName }}</span>
                                <span class="mr50 d_i_b">时间：{{ item.time }}</span>
                                <span class="mr50 d_i_b" v-if="item.orderQuota && item.courseType !=13">预约人数：{{ item.orderQuota }}</span>
                                <span class="mr50 d_i_b" v-if="remark && item.courseType !=13">备注：{{ item.remark }}</span>
                            </div>
                        </div>
                        </div>
                    </div>

					<div class="box-border bdr4 mt10" v-if="pageData.roomList.length">
					    <div class="rx-c" v-for="(item, index) in pageData.roomList" :key="index">
							<el-checkbox v-if="pageData.title == '会员入场'" v-model="item.checkbox" :label="item.id" size="large" @change="changeRoom">{{''}}</el-checkbox>
							<div class="ml15">
								<h4 class="mb20">场地预约</h4>
								<div class="rx-sc mb15" v-for="child in item.roomStrList" :key="child.roomName">
									<span class="mr50">场地名称：{{child.roomName}}</span>
									<span class="mr50">预约场次：{{child.list}}</span>
								</div>
								<div class="rx-sc">
									<span class="mr50">预订人数：{{pageData.title == '会员入场' ? item.peopleNum : item.peopleNumOrg}}</span>
									<div class="rx-sc" v-if="item.costType == 2">
										<span>实际入场人数：</span>
										<div class="w100" v-if="pageData.title == '会员入场'">
											<el-input placeholder="" min="1" @input="inputVerification('enterCount',index)" type="number" v-model="item.enterCount">
												<template #append>人</template>
											</el-input>
										</div>
										<span v-else>{{item.peopleNum}}</span>
									</div>
								</div>
							</div>
					    </div>
					</div>
                    <div class="box-border bdr4 mt10" v-if="pageData.orderList && pageData.orderList.length">
                    <div class="rx-c" v-for="(item, index) in pageData.orderList" :key="index">
                        <el-radio class="mr0" v-if="pageData.title == '会员入场'" v-model="pageData.radio" :label="item.id" size="large" @change="changeCard">{{''}}</el-radio>
                        <el-image class="w120 h80 bdr4" :src="item.productCover"></el-image>
                        <div class="ml15 flex1">
                            <div :class="item.productTyp == 1 ? 'mb5' : 'mb20'">
                                <span
                                    class="d_i_b bdr2 pl5 pr5"
                                    :style="{
                                        color: cordType[item.type].color,
                                        backgroundColor:cordType[item.type].color+'30',
                                        border:'1px solid '+cordType[item.type].color}"
                                >
                                    {{cordType[item.type].text }}
                                </span>
                                <h4 class="d_i_b ml10">{{item.productName}}</h4>
                            </div>
                            <div class="rx-wsc">
                                <span class="mr50 d_i_b">剩余次数：{{ item.productTyp == 3 ? '不限次' : item.productNum + '次' }}</span>
                                <span class="mr50 d_i_b" v-if="item.productUnit == 0">剩余天数：不限时</span>
                                <span class="mr50 d_i_b" v-else>剩余天数：{{ item.productCycle == -1 ? '未开始计时' : item.productCycle == -2 ? '不限时' :  item.productCycle }} <span v-if="item.productCycle >=0 ">{{ item.productUnit == 1 ? '天' : item.productUnit == 2 ? '月': item.productUnit == 3 ? '年' : '' }}</span> </span>
                                <span class="mr50 d_i_b" >有效期：{{ item.productUnit == 0 ? '--' : item.startTime ? item.endTime : '未开始' }}</span>
                                <span class="mr50 d_i_b">状态：{{ item.orderState == 0 ? '退款' :item.orderState == 1 ? '正常': item.orderState == 2 ? '冻结': '结束' }}</span>
                                <span v-if="item.productTyp == 1" class="mr50 d_i_b"> 本次入场扣次：<el-input-number class="w65 v_a_m mr10" :disabled="pageData.title == '会员离场'" v-model="item.consumeNum" :min="1" :max="item.productNum" :precision="0" :step="1" :controls="false" />次</span>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="box-border bdr4 mt10" v-if="pageData.visitList && pageData.visitList.length">
                    <div class="rx-c" v-for="(item, index) in pageData.visitList" :key="index">
                        <el-checkbox v-if="pageData.title == '会员入场' " v-model="item.checkbox" :label="item.id" size="large" @change="changeVisit">{{''}}</el-checkbox>
                        <div class="color-333">
                            <p>预约到访</p>
                            <p>
                                <span>预约到访时间：{{ item.orderVisitTime }}</span>
                                <span class="ml30">备注：{{ item.remark }}</span>
                            </p>
                        </div>
                    </div>
                    </div>
                    <div class="box-border bdr4 mt10" v-if="pageData.temporary">
                    <div class="rx-c">
                        <el-radio v-if="pageData.title == '会员入场'" v-model="pageData.visit" label="1" size="large">{{''}}</el-radio>
                        <span class="color-666 fw400">临时到访</span>
                    </div>
                    </div>
                </div>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <span class="mr30 color-red" v-if="pageData.title == '会员离场' && pageData.enterOut.overtime">已超时：{{ pageData.enterOut.time }}</span>
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" :disabled="pageData.disabled" @click="onSubmit">{{ pageData.buttonText }}</el-button>
              </span>
            </template>
        </el-dialog>
        <MemberSignature v-model="pageData.memberSignatureShow" :from="1" :type="pageData.memberSignatureType" @close="memberSignatureClose" @save="memberSignatureSave" />
        <Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave"/>
    </workArea>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import { Search, DArrowRight } from '@element-plus/icons-vue';
    import workArea from '@/components/branch-store/workArea.vue';
    import MemberSignature from '@/components/branch-store/member/MemberSignature.vue';
    import Confirm from '@/components/Confirm.vue'
    import { allBanners, getMemberVoucher, enter, onSiteList, out, batchOut, systemNotice } from '@/api/reception.js';
    import {useRouter} from 'vue-router';
    import { ElMessage, ElMessageBox } from 'element-plus'
    import { selectStoreMembersSimple } from '@/api/staff.js';
    import { getMemberDetail } from '@/api/member.js';
    import utils from '@/common/utils.js';

    const barlist = reactive([{ name : '首页' },{ name : '前台' }]); // 面包屑
    const router = useRouter();
    const windowHeight = ref(500);
    const windowWidth = ref(1000);
    const signBtnWidth = ref(200);
    const checkedCities = ref([])
    const confirmRef = ref();
    const pageData = reactive({
        searchLoading: false,
        checked:false,
        dialogVisible: false, // 入离场弹框
        title: '', // 入离场弹框标题
        buttonText: '', // 按钮文本
        disabled: false, // 按钮是否禁用
        isPresence: false, // 是否点击批量批量场
        tabList : [],
        checkUserList: [], // 会员列表
        member: '', // 选中的会员加密id
        memberSignatureShow : false,
        memberSignatureType : '0',
        radio: '',
        quickList: [{
           name: '场次预订',
           imgUrl: require('@/assets/branch/reception/ccyd.png'),
           path: '/branch/agentOrder',
        },{
           name: '入离场记录',
           imgUrl: require('@/assets/branch/reception/rlcjl.png'),
           path: '/branch/receptionRecord',
		   menuId:30400
        },{
           name: '预约管理',
           imgUrl: require('@/assets/branch/reception/yygl.png'),
           path: '/branch/appointment',
        },{
           name: '课表管理',
           imgUrl: require('@/assets/branch/reception/kbgl.png'),
           path: '/branch/courseTable'
        },{
           name: '潜在会员',
           imgUrl: require('@/assets/branch/reception/qzhy.png'),
           path: '/branch/intentionList'
        },{
           name: '正式会员',
           imgUrl: require('@/assets/branch/reception/zshy.png'),
           path: '/branch/memberList'
        }],
        signatureLsit: [{
           name: '入场卡签单',
           imgUrl: require('@/assets/branch/reception/rckqd.png'),
           back: '#EDDEF9',
           value: '2'
        },{
           name: '课程卡签单',
           imgUrl: require('@/assets/branch/reception/kckqd.png'),
           back: '#FFEC9C',
           value: '1'
        },{
           name: '体验卡签单',
           imgUrl: require('@/assets/branch/reception/tykqd.png'),
           back: '#F8D6D7',
           value: '4'
        },{
           name: '储值卡签单',
           imgUrl: require('@/assets/branch/reception/czkqd.png'),
           back: '#BEEDE2',
           value: '3'
        }],
        signatureNextLsit: [{
           name: '团体课签单',
           imgUrl: require('@/assets/branch/reception/ttkqd.png'),
           back: '#F8D6D7',
           value: '11'
        },{
           name: '私教课签单',
           imgUrl: require('@/assets/branch/reception/sjkqd.png'),
           back: '#D6E0F8',
           value: '12'
        },
        {
           name: '小班课签单',
           imgUrl: require('@/assets/branch/reception/bkqd.png'),
           back: '#EDDEF9',
           value: '13'
        }
        ],
        presenceList:[], // 在场列表
        noticeList:[], // 公告列表
        checkAll: false, // 是否全选
        isIndeterminate: false, // 是否半选
        userInfo:{}, // 会员信息
        courseList: [], // 课程信息
        orderList: [], // 卡信息
        visitList: [], // 预约到访信息
		roomList:[],//场地预约
        temporary: '', // 1=临时入场;0=无
        enterOut: '', // 入场信息
        visit:'', // 临时到访
    });
    const courseType = {
		11:{
			text:'团体课',
			color:'#FA7D29'
		},
		12:{
			text:'私教课',
			color:'#3FC9FF'
		},
        13:{
			text:'小班课',
			color:'#9476FF'
		},
		14:{
			text:'私教1v1',
			color:'#3FC9FF'
		},
		15:{
			text:'私教1v多',
			color:'#3FC9FF'
		}
		
    } // 标签
    const cordType = {
        1:{
			text:'课程卡',
			color:'#FA7D29'
		},
		2:{
			text:'入场卡',
			color:'#D650FB'
		},
		3:{
			text:'储值卡',
			color:'#9476FF'
		},
		4:{
			text:'储值卡',
			color:'#3FC9FF'
		},
    }
    /**
     * 输入框输入匹配
     */
    searchMember()
    var timer = ''
	function searchMember(key){
		clearTimeout(timer);
		timer = setTimeout(()=>{
			pageData.searchLoading = true;
			selectStoreMembersSimple({searchKeys: key,page:1,limit:9999}).then((res) => {
                pageData.searchLoading = false;
                if (res.code == 0) {
                    pageData.checkUserList = res.data
                }
            })
		}, 100)
	}
    function searchData(val) {
        pageData.member = val
        search()
    }
    /**
     * 获取公告列表
     */
    getSystemNotice()
    function getSystemNotice() {
        let params = {
            page: 1,
            limit: 4,
        }
        systemNotice(params).then((res) => {
            if (res.code == 0) {
                pageData.noticeList = res.data
            }
        })
    }
    /**
     * 点击搜索
     */
    function search() {
        if (pageData.member) {
            getMemberDetail({encryptionId: pageData.member}).then((res) => {
                if (res.code == 0) {
                    pageData.userInfo = {
                        avatar: res.data.headImgUrl,
                        name: res.data.realName,
                        phone: res.data.telephoneNum,
                        trialFlag: res.data.trialFlag,
                        staff: res.data.adviserNames,
                        remark: res.data.remark,
                        id: pageData.member
                    }
                }
            })
            getMemberVoucher({encryptionCardMyId: pageData.member}).then((res) => {
                console.log(res)
                if (res.code == 0) {
                    if (res.data.enterOutFlag == 1) {
                        pageData.title = "会员离场"
                        pageData.buttonText = "确定离场"
                    } else {
                        pageData.title = "会员入场"
                        pageData.buttonText = "确定入场"
                    }
                    res.data.courseList && res.data.courseList.forEach(item => {
                        item.checkbox = false
                    })
                    res.data.orderList && res.data.orderList.forEach(item => {
                        pageData.radio = ''
                        item.consumeNum = 1
                    })
                    res.data.visitList && res.data.visitList.forEach(item => {
                        item.checkbox = false
                    })
                    pageData.enterOut = res.data.enterOut
                    pageData.courseList = res.data.courseList || []
                    pageData.orderList = res.data.orderList || []
                    pageData.visitList = res.data.visitList || []
                    pageData.temporary = res.data.temporary;
                    pageData.roomList = res.data.batchList.map((z)=>{
						var obj = {};
						z.roomOrderList.map(n=>{
							var day = n.orderTime.substring(0,10);
							if(obj[n.roomName+day]){
								obj[n.roomName+day].list+='、'+n.orderTime.substring(11);
							}else{
								obj[n.roomName+day] = {
									roomName:n.roomName,
									list:n.orderTime
								}
							}
						})
						var list = [];
						for(var k in obj){
							list.push(obj[k]);
						}
						z.roomStrList = list;
						return z;
					})
                }
            })
            pageData.dialogVisible = true
        } else {
            ElMessage.warning('请选择会员')
        }
    }
    function getTabList(){
        allBanners().then((res) => {
            if (res.code == 0) {
                pageData.tabList = res.data
            }
            
        })
    }
    // 显示会员签单
    function memberSignature(val){
		pageData.memberSignatureType = val;
        pageData.memberSignatureShow = true;
    }
    // 保存会员签单
    function memberSignatureSave(res){
		memberSignatureClose();
    }
    // 关闭会员签单
    function memberSignatureClose(){
        pageData.memberSignatureShow = false;
    }
    /**
     * @param(String) path 路由地址
     * 快捷入口跳转
     */
    function jump(row) {
        console.log(row)
        if (row.name == '场次预订') {
            if (!utils.getStoresFun(1000)) {
                ElMessage.warning('该功能暂未开通，请联系销售顾问')
            } else {
                router.push(row.path);
            }
        } else {
            router.push(row.path);
        }
    }
    /**
     * 在场列表
     */
    getPresenceList()
    function getPresenceList() {
        onSiteList().then((res)=> {
            if (res.code == 0) {
                pageData.presenceList = res.data.map(n=>{
					if(n.lastOutSiteTime){
						var time = utils.getDateObj(n.lastOutSiteTime).time - (new Date()).getTime();
						if(time > 0 && time < 10*60*1000){ //即将超时
							n.time = -1;
						}
						if(time < 0){ //已超时
							var d = parseInt(time * -1 /1000/60);
							if(d > 60){
								n.time = parseInt(d/60)+'时'+d%60+'分';
							}else{
								n.time = d+'分';
							}; //转换正数并换算成分钟
							
						}
					}
					return n;
				})
            }
        })
    }
    /**
     * 单独离场
     */
    function departure(id) {
        pageData.member = id
        search()
    }
    /**
     * 批量立场选择
     */
    function handleCheckAllChange(val) {
        checkedCities.value = val ? pageData.presenceList : []
        pageData.isIndeterminate = false
    }
    function handleCheckedCitiesChange(value) {
      const checkedCount = value.length
      pageData.checkAll = checkedCount === pageData.presenceList.length
      pageData.isIndeterminate = checkedCount > 0 && checkedCount < pageData.presenceList.length
    }
    /**
     * 取消批量离场
     */
    function closePresence() {
        checkedCities.value = []
        pageData.isIndeterminate = false
        pageData.checkAll = false
        pageData.isPresence = !pageData.isPresence
    }
    /**
     * 确定批量离场
     */
    function presence() {
        if (!checkedCities.value.length) {
           ElMessage.error('请先选择需要离场的会员')
           return
        } else {
           pageData.confirmTitle = '确定离场吗？';
           pageData.confirmText = `共离场 <span class="color-red mr5">${ checkedCities.value.length }</span>人！`
	       confirmRef.value.show();
        }
    }
    function confirmSave(){
        let arry = []
        checkedCities.value.forEach((item) => {
           arry.push(item.encryptionCardMyId) 
        })
        let params = {
            encryptionCardMyId: arry.join()
        }
        batchOut(params).then((res)=> {
            if (res.code == 0) {
                ElMessage.success('离场成功')
                getPresenceList()
            }
        })
	}
    /**
     * 跳转会员详情
     */
    function toMemberDetail(){
        window.open('/#/branch/memberDetail?encryptionId=' +pageData.userInfo.id , '_blank');
    }
    /**
     * 查看更多
     */
    function seeMore() {
        window.open('/#/branch/noticeList', '_blank');
    }
    /**
     * @param(String) path 路由地址
     * 打开轮播图链接
     */
    function openTab(path) {
        if (path) {
           window.open(path, '_blank')
        }
    }

    /**
     * 选择课入场
     */
    function changeCourse() {
        pageData.visitList.forEach(item => {
            item.checkbox = false
        })
		pageData.roomList.forEach(item => {
		    item.checkbox = false
		})
		pageData.radio = '';
    }
	/**
	 * 选择卡入场
	 */
	function changeCard() {
	    pageData.visitList.forEach(item => {
	        item.checkbox = false
	    })
		pageData.roomList.forEach(item => {
		    item.checkbox = false
		})
		pageData.courseList.forEach(item => {
		    item.checkbox = false
		})
	}
	/**
	 * 选择场地预约
	 */
	function changeRoom() {
		pageData.visitList.forEach(item => {
		    item.checkbox = false
		})
	    pageData.courseList.forEach(item => {
	        item.checkbox = false
	    })
		pageData.radio = '';
	}
    /**
     * 选择预约到访
     */
    function changeVisit() {
        pageData.courseList.forEach(item => {
            item.checkbox = false
        })
		pageData.radio = '';
		pageData.roomList.forEach(item => {
		    item.checkbox = false
		})
    }
    /**
     * 取消弹框
     */
    function handleClose() {
       pageData.checked = false
       pageData.dialogVisible = false
    }
    /**
     * 确定入离场
     */
    function onSubmit(){
        if (pageData.buttonText == '确定离场') {
            out({encryptionCardMyId: pageData.member}).then((res) => {
                if (res.code == 0) {
                    ElMessage.success('离场成功')
                    pageData.member = ''
                    getPresenceList()
                }
            })
        } else {
            let courseIds = [], orderId = pageData.radio, consumeNum = '', orderVisitId = [], roomOrderIds = [];
			var enterBatchCounts = [];
			pageData.roomList.forEach(item => {
			    if (item.checkbox) {
					item.roomOrderList.map(z=>{
						roomOrderIds.push(z.id)
					})
					if(item.enterCount){
						enterBatchCounts.push(item.orderBatchId+':'+item.enterCount);
					}
			    }
			})
            pageData.courseList.forEach(item => {
                if (item.checkbox) {
                    courseIds.push(item.id)
                }
            })
            pageData.orderList.forEach(item => {
                if ( orderId== item.id) {
                    consumeNum = item.consumeNum
                }
            })
            pageData.visitList.forEach(item => {
                if (item.checkbox) {
                    orderVisitId.push(item.id)
                }
            })
            if (pageData.temporary) {
                if (!pageData.visit) {
                    ElMessage.warning('请选择入场凭证')
                    return
                }
            } else {
                if (!courseIds.length && !orderId && !orderVisitId.length && !roomOrderIds.length) {
                    ElMessage.warning('请选择入场凭证')
                    return
                }
            }
            let params = {
                encryptionCardMyId: pageData.member,
                consumeType: (orderId || courseIds.length) ? 0 : pageData.visit ? 4 : orderVisitId.length ? 3 : 5,
                courseIds: courseIds.join(','),
                orderId: orderId,
                orderVisitId: orderVisitId.join(','),
                roomOrderIds: roomOrderIds.join(','),
                consumeNum: consumeNum,
                ifSignCourse: pageData.checked ? 1 : 0,
				enterBatchCounts:enterBatchCounts.join(',')
            }
            enter(params).then((res) => {
                if (res.code == 0 ) {
                    if (res.data.lastOutSiteTime) {
                        ElMessageBox.alert(`会员最晚离场时间为：${res.data.lastOutSiteTime}`, '提示', {
                            confirmButtonText: '好的',
                        })
                    } else {
                        ElMessage.success('入场成功')
                    }
                    pageData.checked = false
                    getPresenceList()
                }
            })

        }
        pageData.dialogVisible = false
    }
    /**
     * 查看公告
     */
    function seeNotice(id) {
        router.push('/branch/noticeDetail?encryptionId='+ id);
    }
	
	//获取凭证类型
	function getConsumeType(type){
		switch (type){
			case 1:
				return '预约课程';
				break;
			case 2:
				return '入场卡';
				break;
			case 3:
				return '预约到访';
				break;
			case 4:
				return '临时到访';
				break;
			case 5:
				return '场地预约';
				break;
		}
	}
	//验证输入
	var inputValue = '';
	function inputVerification(val,index){
		var value = pageData.roomList[index][val];
		if(/^$|(^[1-9]\d*$)/.test(value) && value <= 9999){
			inputValue = value;
		}else{
			pageData.roomList[index][val] = inputValue;
		}
	}
    onMounted(()=>{
        getTabList();
        windowHeight.value = window.innerHeight - 120;
        windowWidth.value = window.innerWidth;
        signBtnWidth.value = (window.innerWidth - 720)/4
    });
</script>

<style scoped>
   .searchInput /deep/ .el-input__inner{
        border-color:#eee;
        border-right:none;
        border-radius:50px 0px 0px 50px;
        padding-left: 25px;
        height: 45px!important;
        line-height: 45px!important;
    }
    .searchInput /deep/ .el-input__wrapper{
        border-radius:50px 0px 0px 50px;
    }
    .el-select-option .el-select-dropdown__item{
        height: 44px;
        line-height: 44px;
    }
    .ipt-r{
        border-radius:0px 50px 50px 0px;
        cursor: pointer;
        height: 47px;
    }
    .scrollbar-demo-item:last-of-type{
        margin-bottom: 0;
    }
    .el-dialog .title{
        background-color: #EEF9FE;
        height: 32px;
        line-height: 32px;
	}
    .el-dialog .title div{
		width: 3px;
        height: 18px;
        background-color: #409EFF;
        margin: 0 8px;
	}
    .sex{
        width: 17px;
        height: 17px;
        bottom: 3px;
        right: -4px;
    }
    .box-border {
        border: 1px solid #D6D6D6;
    }
    .box-border>div{
        border-bottom: 1px solid #D6D6D6;
        padding: 12px 13px
    }
    .box-border div:last-child{
        border-bottom: 0;
    }
    .text {
        z-index: 100;
    }
    .el-checkbox-group{
		line-height: inherit !important;
	}
	.scrollbar_item:hover,.scrollbar_item.active{
		background-color: #EEF9FE;
	}
</style>