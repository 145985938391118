<!-- 会员详情页面 -->
<template>
    <workArea :barlist="barlist">
        <div class="ww100 cx-sc">
            <div class="ww100 h130 mt20 pl20 pr20 rx-bc">
                <div class="ww100 h130 rx-sc posi-r">
                    <div class="w400 h30 posi-a rx-ec right0 top0 c_p">
                        <el-button type="primary" @click="toEditMember">编辑会员信息</el-button>
                        <el-dropdown @command="memberSignature" @visible-change="changeDropdown" trigger="click">
                            <div class="pl10 pr10 ml16 h30 bdr2 rx-cc bd-main color-main">
                                会员签单
                                <DArrowRight v-show="!pageData.dropdown" class="arrowdown"></DArrowRight>
                                <DArrowLeft v-show="pageData.dropdown" class="arrowdown"></DArrowLeft>
                            </div>
                            <template #dropdown>
                              <el-dropdown-menu>
                                <el-dropdown-item v-for="sign in signBtnArray" :key="sign.id" :command="sign.id">
                                    <div class="ww100 h30 rx-sc">
                                        <div class="ml5">{{sign.name}}</div>
                                    </div>
                                </el-dropdown-item>
                              </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                        <!-- <el-button class="pl10 pr10 ml15" @click="manual">手动消费</el-button> -->
                    </div>
                    <div class="w60 h130">
                        <img :src="pageData.userInfo.headImgUrl" class="w60 h60 b_r_100">
                    </div>
                    <div class="h130 ml10 rx-ss">
                        <div class="w230 cx-ss">
                            <div class="ww100 h30 rx-sc">
                                <span class="fwb ft16">{{pageData.userInfo.realName}}</span>
                                <div class="tag_blue bdr2 ml5" v-if="pageData.userInfo.trialFlag == 1">正式会员</div>
                                <div class="tag_red bdr2 ml5" v-else>潜在会员</div>
                            </div>
                            <div class="ww100 h20 mt5 rx-sc">
                                <Iphone class="w15 h15"></Iphone>
                                <span>{{pageData.userInfo.telephoneNum}}</span>
                            </div>
                            <div class="ww100 h40 mt10 rx-sc">
                                <span>会员生日：</span>
                                <span>{{pageData.userInfo.userBirthday}}</span>
                            </div>
                        </div>
                        <div class=" cx-ss">
                            <div class="ww100 h30 rx-sc"></div>
                            <el-dropdown trigger="click">
                                <div class="ww100 h20 mt5 rx-sc color-main tool_btn c_p" style="text-decoration:underline">
                                    更多信息
                                    <el-icon><d-arrow-right /></el-icon>
                                </div>
                                <template #dropdown>
                                  <div class="w235 bdr4 pl10 pt15 pb20 pr10 cx-sc">
                                      <div class="ww100 rx-sc h30">
                                          <div class="w80 rx-ec color-666">绑定微信：</div>
                                          <div class="color-333">{{ pageData.userInfo.storeWechatBind ? '已绑定' : '未绑定' }}<span v-if="pageData.userInfo.storeWechatBind && utils.getMenuRole(3130008)" class="color-red ml5 c_p t_d_u" @click="bindWechat">解除绑定</span></div>
                                      </div>
                                      <div class="ww100 rx-sc h30">
                                          <div class="w80 rx-ec color-666">所属门店：</div>
                                          <div class="color-main c_p t_d_u tool_btn" @click="pageData.isSellShow = true">共{{ pageData.userInfo.storeCount }}家门店</div>
                                      </div>
                                      <div class="ww100 rx-sc h30">
                                          <div class="w80 t_a_r color-666">跟进员工：</div>
                                          <div class="w120 color-333">
                                              <el-tooltip
                    				            class="box-item"
                    				            effect="dark"
                    				            :content="pageData.userInfo.adviserNames"
                    				            placement="right"
                    				          >
                    				             <div class="right-text">{{ pageData.userInfo.adviserNames }}</div>
                    				          </el-tooltip>
                                          </div>
                                      </div>
                                      <div class="ww100 rx-sc h30">
                                          <div class="w80 rx-ec color-666">会员来源：</div>
                                          <div class="color-333">{{ pageData.userInfo.channelName }}</div>
                                      </div>
                                      <div class="ww100 rx-sc h30">
                                          <div class="w80 rx-ec t_a_r color-666">备注信息：</div>
                                          <div class="w120 color-333">
                                              <el-tooltip
                    				            class="box-item"
                    				            effect="dark"
                    				            :content="pageData.userInfo.remark"
                    				            placement="right"
                    				          >
                    				             <div class="right-text">{{ pageData.userInfo.remark }}</div>
                    				          </el-tooltip>
                                          </div>
                                      </div>
                                  </div>
                                </template>
                            </el-dropdown>
                            <div class="ww100 h40 mt10 rx-sc">
                                <span>会员标签：</span>
                                <div class="pl5 pr5 bdr4 h20 ft12 bg-main rx-cc color-fff mr10" :style="{'background-color':'#'+ tag.backColor,'color':'#' + tag.fontColor}" v-for="tag in pageData.userInfo.userTag" :key="tag">
                                    {{tag.tagName}}
                                    <img src="@/assets/branch/tag-delete.png" class="w15 h15 ml5 c_p" @click="deletLabelSave(tag.tagId)">
                                </div>
                                <div class="h20 bdr2 pl5 pr5 rx-cc ft12 c_p bd-main color-main" @click="batchSetLabelEvent">
                                    <Plus class="w10 h10"></Plus><span class="ft12">标签</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ww100 h130 rx-bc">
                <div class="h125 bg-gray rx-sc ml20" style="width:50%;">
                    <div class="w80 ml100 h125 rx-cc">
                        <img src="@/assets/branch/member-buy.png" class="w65 h65">
                    </div>
                    <div class="w400 h125 cx-cs">
                        <div class="ft16 h20 color-aaa fwb ml45">累计购买</div>
                        <div class="ft28 h30 color-main fwb ml40 mt10">￥{{pageData.cordOrCourse.actualMoney || 0}}</div>
                    </div>
                </div>
                <div class="w20 h125"></div>
                <div class="h125 bg-gray mr20 rx-sc" style="width:50%;">
                    <div class="w80 ml100 h125 rx-cc">
                        <img src="@/assets/branch/member-money.png" class="w65 h65">
                    </div>
                    <div class="w400 h125 cx-cs">
                        <div class="ft16 h20 color-aaa fwb ml45">累计消费</div>
                        <div class="ft28 h30 color-main fwb ml40 mt10">￥{{pageData.cordOrCourse.consumeMoney || 0}}</div>
                    </div>
                </div>
            </div>
            <div class="ww100 h130 mb20 rx-bc">
                <div class="h90 ml20 cardbox cx-cc">
                    <div class="ww100 h30 rx-cc color-333">入场卡：{{pageData.cordOrCourse.enterCardNum || 0}}张</div>
                    <div class="cardbar" style="background-color:#FAC510"></div>
                </div>
                <div class="w20 h110"></div>
                <div class="h90 cardbox cx-cc">
                    <div class="ww100 h30 rx-cc color-333">课程卡：{{pageData.cordOrCourse.courseCardNum || 0}}张</div>
                    <div class="cardbar" style="background-color:#43EAF8"></div>
                </div>
                <div class="w20 h110"></div>
                <div class="h90 cardbox cx-cc">
                    <div class="ww100 h30 rx-cc color-333">私教课：{{pageData.cordOrCourse.privateCourseNum || 0}}节</div>
                    <div class="cardbar" style="background-color:#FE5C5C"></div>
                </div>
                <div class="w20 h110"></div>
                <div class="h90 cardbox cx-cc">
                    <div class="ww100 h30 rx-cc color-333">团体课：{{pageData.cordOrCourse.groupCourseNum || 0}}节</div>
                    <div class="cardbar" style="background-color:#6F8AFE"></div>
                </div>
                <div class="w20 h110"></div>
                <div class="h90 cardbox cx-cc">
                    <div class="ww100 h30 rx-cc color-333">储值卡：{{pageData.cordOrCourse.storeCardMoney || 0}}元</div>
                    <div class="cardbar" style="background-color:#F85392"></div>
                </div>
                <div class="w20 h110"></div>
                <div class="h90 cardbox cx-cc">
                    <div class="ww100 h30 rx-cc color-333">体验卡：{{pageData.cordOrCourse.trialCardNum || 0}}张</div>
                    <div class="cardbar" style="background-color:#91F814"></div>
                </div>
                <div class="w20 h110"></div>
                <div class="h90 cardbox cx-cc mr20">
                    <div class="ww100 h30 rx-cc color-333">小班课：{{pageData.cordOrCourse.classCourseNum || 0}}节</div>
                    <div class="cardbar" style="background-color:#A589FE"></div>
                </div>
                <div class="w20 h110"></div>
            </div>
        </div>
        <div class="ww100 h10 bg-gray"></div>
        <div class="ww100 pl20 pr20 pt16 pb12">
            <div class="ww100 h25 rx-sc ft12 color-666 bdr4" style="background-color:#E6F7FF;border:1px solid #92D5FF;">
                <img src="@/assets/branch/comtip.png" class="w15 h15 ml10" />以上数据说明：累计数据统计的是会员在品牌所有门店的购买和消费数据，不包含退款数据；卡/课信息统计的是会员当前在所有门店有效卡和课节数。
            </div>
            <div class="mt10">
                <el-tabs v-model="pageData.tabNum">
                    <el-tab-pane label="已购卡项" name="0"></el-tab-pane>
                    <el-tab-pane label="已购课程" name="1"></el-tab-pane>
                    <el-tab-pane label="我的班级" name="2"></el-tab-pane>
                    <el-tab-pane label="预约记录" name="3"></el-tab-pane>
                    <el-tab-pane label="入场记录" name="4"></el-tab-pane>
                    <!-- <el-tab-pane label="上课记录" name="4"></el-tab-pane> -->
                    <el-tab-pane label="消费记录" name="5"></el-tab-pane>
                    <el-tab-pane label="跟进记录" name="6"></el-tab-pane>
                </el-tabs>
                <component v-if="pageData.userInfo" :is="components[pageData.tabNum]" :tab="pageData.tabNum" :memberId="pageData.userInfo.cardMyId" :info="pageData.userInfo" :num="pageData.cordOrCourse"></component>             
                <div class="ww100 h60"></div>
            </div>
        </div>
        <el-dialog
		     v-model="pageData.batchSetLabelShow"
		     title="添加标签"
		 	 width="400px"
		     :before-close="()=>{pageData.batchSetLabelShow = false}"
		     custom-class="popwechat"
		   >
			<div class="pl20 pt50 pb50 pr20">
				<div class="rx-cc">
					<span class="">选择标签：</span>
					<div>
						<el-select v-model="pageData.batchSetLabelId" placeholder="请选择">
							<template v-for="item in pageData.batchSetLabelList">
								<el-option v-if="item.value" :key="item.value" :label="item.label" :value="item.eId"></el-option>
							</template>
						</el-select>
					</div>
				</div>
		  </div>
		   <template #footer>
		    	<div class="dialog-footer">
		        	<el-button @click="pageData.batchSetLabelShow = false">取消</el-button>
		        	<el-button type="primary" @click="batchSetLabelSave">确定</el-button>
		    	</div>
		    </template>
		</el-dialog>
        <el-dialog
            v-model="pageData.dialogVisible"
            title="手动消费"
            width="500px"
            :before-close="handleClose"
        >
            <el-form
                ref="ruleFormRef"
                :model="pageData.ruleForm"
                :rules="pageData.rules"
                label-width="100px"
                class="p20 pb10 manualForm"
              >
                <el-form-item label="可用资产: " prop="region">
                    <el-select v-model="pageData.ruleForm.region" placeholder="请选可用资产" @change="changeAssets">
                       <el-option label="111" value="1" />
                       <el-option label="2222" value="2" />
                     </el-select>
                </el-form-item>
                <el-form-item label="本次消费: " prop="name">
                    <el-input v-if="!pageData.ruleForm.region" v-model="pageData.ruleForm.name" />
                    <div class="ww100" v-if="pageData.ruleForm.region == 1">
                        <el-input v-model="pageData.ruleForm.name" >
                            <template #append>次</template>
                        </el-input>
                    </div>
                    <div class="ww100" v-if="pageData.ruleForm.region == 2">
                        <!-- <el-input-number class="price v_a_m" v-model="pageData.ruleForm.name" :min="0" :max="pageData.ruleForm.name" :precision="2" :controls="false" @change="changeExemption" />
                        <span class="append v_a_m">元</span> -->
                        <el-input v-model="pageData.ruleForm.name" @blur="changeExemption">
                            <template #append>元</template>
                        </el-input>
                    </div>
                </el-form-item>
                <el-form-item label="备注: ">
                    <el-input v-model="pageData.ruleForm.remark" type="textarea" maxlength="50" placeholder="请输入" show-word-limit />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="handleClose">取 消</el-button>
                    <el-button type="primary" @click="defineManual">确 定</el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 所属门店-->
		<el-dialog
		    v-model="pageData.isSellShow"
		    title="查看门店"
			width="776px"
		    :before-close="()=>{pageData.isSellShow = false}"
		    custom-class="popwechat"
		  >
		    <div class="p20">
				<div>
					<el-table :data="pageData.isSellTableData" border stripe style="width: 100%">
						<el-table-column type="index" label="序号" width="80" />
					    <el-table-column prop="storeName" label="门店名称">
                            <template #default="scope">
                                {{ scope.row.storeName ? scope.row.storeName : '-' }}
							</template>
                        </el-table-column>
                        <el-table-column prop="storeAdd" label="门店名称">
                            <template #default="scope">
                                {{ scope.row.storeAdd ? scope.row.storeAdd : '-' }}
							</template>
                        </el-table-column>
					</el-table>
					<div class="mt20 rx-ec">
					    <el-pagination
					      v-model:currentPage="pageData.isSellPage"
					      page-size="15"
						  :total="pageData.totalCount"
					      layout="prev, pager, next"
					      @current-change="shelfChange"
					    >
					    </el-pagination>
					</div>
				</div>
		    </div>
			<template #footer>
    		  	<span class="dialog-footer"></span>
    		</template>
		</el-dialog>
    </workArea>
    <AddMember v-model="pageData.editMemberShow" :title="pageData.editMemberTitle" :id="pageData.userInfo.encryptionId" @close="editMemberClose" @save="editMemberSave"/>
    <MemberSignature v-model="pageData.memberSignatureShow"  :from="3" :type="pageData.memberSignatureType" :info="pageData.userInfo" @close="memberSignatureClose" @save="memberSignatureSave" />
</template>

<script setup>
    import { ref, reactive, onMounted, onActivated } from 'vue';
    import { useRouter, onBeforeRouteLeave, onBeforeRouteUpdate } from "vue-router";
    import { useStore } from 'vuex'
    import { ElMessage, ElMessageBox } from 'element-plus'
    import formQuery from '@/components/branch-store/formQuery.vue';
    import workArea from '@/components/branch-store/workArea.vue';
    import { getMemberDetail,queryMemberOrderSummary, selectOneMemberStore } from '@/api/member.js';
    import { allotTagToBatch, vipUpdateTagBatch, deleteTagDetails, unBindUser } from '@/api/intentionList.js';
    import { Search, Iphone, Plus, DArrowRight, DArrowLeft, CircleCloseFilled } from '@element-plus/icons-vue';
    import utils from '@/common/utils.js';
    import AddMember from '@/components/branch-store/member/AddMember.vue';
    import MemberSignature from '@/components/branch-store/member/MemberSignature.vue';
    import PurchasedCard from '@/views/branch-store/member/memberDetail/purchasedCard.vue';//已购卡项
    import PurchasedCourse from '@/views/branch-store/member/memberDetail/purchasedCourse.vue';//已购课程
    import myClass from '@/views/branch-store/member/memberDetail/myClass.vue';//已购课程
    import Subscribe from '@/views/branch-store/member/memberDetail/subscribe.vue';//预约记录
    import Admission from '@/views/branch-store/member/memberDetail/admission.vue';//入场记录
    // import attendClass from '@/views/branch-store/member/memberDetail/attendClass.vue';//上课记录
    import Consume from '@/views/branch-store/member/memberDetail/consume.vue';//消费记录
    import FollowRecord from '@/views/branch-store/member/memberDetail/followRecord.vue';//跟进记录
    const router = useRouter();
    const store = useStore();
    const barlist = reactive([{ name : '返回', type:0 },{ name : '会员详情' }]); // 面包屑
    const components = [PurchasedCard, PurchasedCourse, myClass, Subscribe, Admission, Consume, FollowRecord]
    const queryOpts = reactive({});
    const { encryptionId } = router.currentRoute.value.query;
    const confirmRef = ref()
    const ruleFormRef = ref()
    const pageData = reactive({
        isSellPage:1,
        totalCount: 0,
        isSellTableData: [],
        isSellShow: false, // 所属门店弹框
        dropdown: false,
        dialogVisible: false, // 手动扣除弹框
        memberSignatureShow : false,
        batchSetLabelShow: false, // 添加标签弹框
        batchSetLabelId:'', // 选中的标签ID
        batchSetLabelList: [], // 标签列表
        memberSignatureType : 0,
        editMemberTitle : '编辑会员信息',
        editMemberShow : false,
        userInfo : {},
        buycardsList : [], // 已购卡项
        followList : [], // 跟进记录
        tabNum: '',
		updateKey:'' ,//用来更新组件
		cordOrCourse:{}, //持有卡课数量
        ruleForm:{
            region: '',
            name: '',
            remark: ''
        }, // 手动消费表单
        rules: {
            region: [{ required: true, message: '请选择可用资产', trigger: 'change' }],
            name: [{ required: true, message: '请输入本次消费次数', trigger: 'blur' }]
        }
    });
    const signBtnArray = [
            { id : 1, name : '课程卡签单'},
            { id : 2, name : '入场卡签单'},
            { id : 3, name : '储值卡签单'},
            { id : 4, name : '体验卡签单'},
            { id : 11, name : '团体课签单'},
            { id : 12, name : '私教课签单'},
            { id : 13, name : '小班课签单'}
        ];
    /**
     * 签单动效
     */
    function changeDropdown(bool) {
		pageData.dropdown = bool
	}
    function changeExemption(e) {
        let price = e.detail.value;
        price = price.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
        price = price.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
        price = price.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
        price = price.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); //只能输入两个小数
        var reg = new RegExp("([0]*)([1-9]+[0-9]+)", "g");
        price = price.replace(reg, "$2")
        if(price[0] == 0 && price[2] == '.') {
          price = price.substr(1,price.length);
        }
        if(price[0] == '.') {
          price = '0' + price
        }
        pageData.ruleForm.name = price
    }
    getUserInfo(1);
    // 获取会员信息
    function getUserInfo(type){
        var params = {encryptionId};
        getMemberDetail(params).then((res)=>{
            pageData.userInfo = res.data
            if (type) {
                pageData.tabNum = '0'
            }
            pageData.updateKey = (new Date()).getTime();
        });
    }
    function getTagsList() {
        allotTagToBatch().then((res)=>{
            if(res.code == 0){
                var typeArray = [];
                for(var i=0;i<res.data.length;i++){
                    typeArray.push({
                        value : res.data[i]['id'],
                        label : res.data[i]['tagName'],
						eId:res.data[i]['encryptionId'],
                    });
                }
                pageData.batchSetLabelList = typeArray;
            }
        })
    }
    /**
     * 拉起手动消费弹框
     */
    function manual() {
        pageData.ruleForm ={
            region: '',
            name: '',
            remark: ''
        }
        pageData.dialogVisible = true
    }
    /**
     * 取消手动消费
     */
    function handleClose() {
        ruleFormRef.value.resetFields()
        pageData.dialogVisible = false
    }
    /**
     * 切换资产
     */
    function changeAssets() {
        pageData.ruleForm.region = ''
    }
    /**
     * 确定手动消费
     */
    function defineManual() {
        ruleFormRef.value.validate((valid) => {
            if (valid) {
                
            } else {
                console.log('error submit!!');
                return false;
            }
        });
    }
    /**
     * 解绑微信
     */
    function bindWechat() {
        ElMessageBox.confirm('确定解绑微信吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(() => {
			unBindUser({ encryptionId: encryptionId }).then((res)=>{
				if(res.code == 0){
					ElMessage({
						type: 'success',
						message: '解绑成功',
					})
                    getUserInfo();
				}
			})
        })
	   
    }
    /**
     * 会员标签
     */
    function batchSetLabelEvent() {
        pageData.batchSetLabelId = ''
        pageData.batchSetLabelShow = true;
    }
    // 添加标签保存
	function batchSetLabelSave(){
		vipUpdateTagBatch({
			idStr:encryptionId,
			encryptionId:pageData.batchSetLabelId,
		}).then((res)=>{
			if(res.code == 0){
				pageData.batchSetLabelShow = false;
				ElMessage({
		        	type: 'success',
		        	message: '添加成功'
		        })
                getUserInfo()
			}
		})
	}
    /**
     * 查看所属门店
     */
    shelfChange()
    function shelfChange() {
        let params = {
            page: pageData.isSellPage,
            encryptionId: encryptionId
        }
        selectOneMemberStore(params).then((res) => {
            if (res.code == 0) {
                pageData.isSellTableData = res.data
                pageData.totalCount = res.count
            }
        })
    }
    /**
     * 删除标签
     */
    function deletLabelSave(id) {
        deleteTagDetails({
			ids:encryptionId,
			tagIds:id
		}).then((res)=>{
			if(res.code == 0){
				pageData.deletLabelShow = false;
				ElMessage({
		        	type: 'success',
		        	message: '删除成功'
		        })
                getUserInfo()
			}
		})
    }
    // 显示会员签单
    function memberSignature(val){
		pageData.memberSignatureType = val;
        pageData.memberSignatureShow = true;
    }
    // 保存会员签单
    function memberSignatureSave(res){
		memberSignatureClose();
        getCordOrCourseNum();
        getUserInfo();
    }
    // 关闭会员签单
    function memberSignatureClose(){
        pageData.memberSignatureShow = false;
    }
	
	// 显示编辑会员
    function toEditMember(){
        pageData.editMemberShow = true;
    }
    // 编辑会员后
    function editMemberSave(res){
        pageData.editMemberShow = false;
        getUserInfo();
    }
    // 关闭新增会员
    function editMemberClose(){
        pageData.editMemberShow = false;
    }
	function getCordOrCourseNum(){
		queryMemberOrderSummary({
			encryptionCardMyId:encryptionId
		}).then((res)=>{
			if(res.code == 0){
				pageData.cordOrCourse = res.data;
			}
        });
	}
	getCordOrCourseNum();
    // mounted 钩子函数
    onMounted(()=>{
        getTagsList()
    })
</script>

<style type="text/css" scoped>
    .itemtable{width: 100%;border:1px solid #eaeaea;border-radius:5px;}
    .dot{width:6px;height:6px;border-radius:6px;background-color: #666;}
    .cardbox{ border:2px solid #e0e0e099; border-radius:4px;width:15%;}
    .cardbar{width:90px;height:4px;margin-top:10px;border-radius:5px;}
    .arrowdown{
        transform:rotate(90deg);
        -ms-transform:rotate(90deg);     /* IE 9 */
        -moz-transform:rotate(90deg);    /* Firefox */
        -webkit-transform:rotate(90deg); /* Safari 和 Chrome */
        -o-transform:rotate(90deg);  /* Opera */
        width:13px;
        height:13px;
        margin-left:2px;
        }
    .manualForm /deep/ .el-input__wrappe {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        text-align: left
    }
    .manualForm .price {
        width: 93%;
    }
    .right-text{
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
    }
</style>