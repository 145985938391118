<template>
	<workArea :barlist="appletData.barlist">
	<div class="applet_container hh100">
	<!-- 	<el-tabs v-if="appletData.appBindData" v-model="appletData.tabNum" @tab-click="tabClick" :before-leave="tabBefore">
			<el-tab-pane label="首页搭建" name="0"></el-tab-pane>
			<el-tab-pane label="预约页面" name="1"></el-tab-pane>
			<el-tab-pane label="我的页面" name="2"></el-tab-pane>
			<el-tab-pane label="底部导航" name="3"></el-tab-pane>
			<el-tab-pane label="换肤设置" name="4"></el-tab-pane>
			<el-tab-pane label="小程序设置" name="5"></el-tab-pane>
		</el-tabs> -->
		<div class="componet_tab mb12">
			<ul class="rx-sc">
				<li v-show="appletData.appBindData.bind == 1" :class="{active:appletData.tabNum == 0}" @click="tabClick(0)">首页搭建</li>
				<li v-show="appletData.appBindData.bind == 1" :class="{active:appletData.tabNum == 1}" @click="tabClick(1)">预约页面</li>
				<li v-show="appletData.appBindData.bind == 1" :class="{active:appletData.tabNum == 2}" @click="tabClick(2)">我的页面</li>
				<li v-show="appletData.appBindData.bind == 1" :class="{active:appletData.tabNum == 3}" @click="tabClick(3)">底部导航</li>
				<li v-show="appletData.appBindData.bind == 1" :class="{active:appletData.tabNum == 4}" @click="tabClick(4)">换肤设置</li>
				<li :class="{active:appletData.tabNum == 5}" @click="tabClick(5)">小程序设置</li>
			</ul>
		</div>
		
		<div class="rx-bc mb20 pt10 pr20 pb10 pl20" style="background-color: #EEF9FE;" v-show="appletData.tabNum != 5">
			<div>
				<span class="fwb v_a_m">当前配置门店：</span>
				<span class="v_a_m" v-show="appletData.tabNum > 1">全部门店</span>
				<el-dropdown class="v_a_m" v-show="appletData.tabNum <= 1" @command="changeStore" :tabindex="appletData.storeIndex">
					<span class="fwb c_p">
						<strong class="color-333" v-if="appletData.storeList.length > 0">{{appletData.storeList[appletData.storeIndex].storeName}}</strong>
						<el-icon class="el-icon--right"><ArrowDown /></el-icon>
					</span>
					<template #dropdown>
						<el-dropdown-menu>
							<template v-for="(item,index) in appletData.storeList" :key="index">
								<el-dropdown-item  :command="index" >{{item.storeName}}</el-dropdown-item>
							</template>
						</el-dropdown-menu>
				    </template>
				</el-dropdown>
			</div>
			<div>
				<el-button v-if="appletData.tabNum == 0" @click="saveOrIssued(0)">暂存本地</el-button>
				<el-button type="primary" @click="saveConfirm(1)">发布会员端</el-button>
			</div>
		</div>
		<div v-loading="appletData.loading">
			<component :is="appletData.tabComponent" :bind-data="appletData.appBindData" :update="getStoreMiniBindInfo" :key="(new Date()).getTime()" :tab="appletData.tabNum" :sid="appletData.sid"></component>
		</div>
		<Confirm ref="confirm" :title="appletData.confirmTitle" :text="appletData.confirmText" @save="confirmSave"></Confirm>
	</div>
	</workArea>
</template>

<script setup>
	import { ref, reactive, nextTick} from 'vue';
	import { useStore } from 'vuex'
	import { ElMessage } from 'element-plus'
	import {ArrowDown } from '@element-plus/icons-vue'
	import BasicSettings from '../../components/main-store/applet/BasicSettings.vue'
	import AppletSetting from '../../components/main-store/applet/AppletSetting.vue'
	import Confirm from '../../components/Confirm.vue'
	import {moduleList,insertAll,editList,getStoreMiniBindInfo,getAllAddress} from '../../api/applet.js';
	import {getFrameworkAllList} from '../../api/staff.js';
	import {getLocalStorage} from '../../common/common.js';
	import { getMyLineStore } from '@/api/storeCenter.js';
	import workArea from '@/components/branch-store/workArea.vue';
	const store = useStore();
	let oldDataByAjax = ''; //存储出进来获取的数据用于判断页面是否更改了某些信息然后提示
	const oldAppletFormData = JSON.parse(JSON.stringify(store.state.appletFormData));  //暂存原始数据，用于切分店初始化数据
	const loginData = getLocalStorage('businessNowStoreData');
	//切换tab
	const componentArr = [BasicSettings,AppletSetting];
	const appletData = reactive({
		barlist:[{ name : '首页' },{ name : '门店管理' },{ name : '小程序搭建' }],
		tabNum:0,
		tabComponent:'',
		storeList:[],
		storeIndex:0,
		appBindData:{},
		sid:'',
		confirmTitle:'',
		confirmText:'',
		confirmType:1, //区分是切tab 还是切分店
		toTable:0,
		saveStatus:false,
		loading:false,
		esid:''
	})
	var appletTabNum = localStorage.getItem('appletTabNum');
	if(appletTabNum){
		appletData.tabNum = appletTabNum;
	}
	//获取地区json数据
	getAllAddress().then((res)=>{
		if(res.code == 0){
			store.state.areaList = res.data;
		}
	})
	
	
	//获取小程序绑定状态
	getStoreMiniBindInfo({
		access_token:localStorage.getItem('businessToken'),
		type:2
		}).then((res)=>{
		if(res.code == 0 && res.results){
			appletData.appBindData = res.results;
			if(res.results.bind != 1){ //未绑定
				appletData.tabNum = 5;
				appletData.tabComponent = componentArr[1];
			}else{
				appletData.tabNum = appletTabNum || 0;
				//获取组织架构
				getFrameworkAllList().then((res)=>{
					if(res.code == 0 && res.data ){
						var list = res.data.filter(n=>n.ifBranchStore == 1);
						appletData.storeList = list;
						appletData.sid = list[0].sid;
						appletData.esid = list[0].encryptionId;
						if(appletTabNum < 5){
							getConfig();
							getConfig(4);
							appletData.tabComponent = componentArr[0];
						}else{
							appletData.tabComponent = componentArr[1];
						}
					}
				})
			}
		}
	})

	function confirmSave(){
		if(appletData.confirmType == 3){ //保存发布
			saveOrIssued(1);
			return false;
		}
		if(appletData.confirmType == 1){ //切tab
			appletData.tabNum = appletData.toTable;
		}else{  //切分店
			appletData.storeIndex = appletData.toTable;
			appletData.sid = appletData.storeList[appletData.toTable].sid;				
			getConfig(4);
		}
		getConfig();
	}
	const confirm = ref();
	//切换tab
	function tabClick(index){
		localStorage.setItem('appletTabNum',index);
		if(	appletData.loading == true || appletData.tabNum == index){
			return false;
		}
		appletData.toTable = index;
		if(appletData.tabNum < 5){
			let newDataByStore = JSON.stringify(store.state.appletFormData[appletData.tabNum].list);
			if(appletData.saveStatus && newDataByStore != oldDataByAjax){
				appletData.confirmText = '此时离开将丢失已编辑的内容!';
				appletData.confirmTitle = '是否确定离开？';
				appletData.confirmType = 1;
				confirm.value.show();
				return false;
			}
		}
		appletData.tabNum = index;
		const storeData = store.state.appletFormData[index];
		appletData.tabComponent = componentArr[index < 5 ? 0 : 1];
		if(index < 5){
			storeData.active = 0;
			getConfig();
		}
	}
	//切换配置门店
	function changeStore(index){
		if(	appletData.loading == true){
			return false;
		}
		appletData.toTable = index;
		let newDataByStore = JSON.stringify(store.state.appletFormData[appletData.tabNum].list);
		if(appletData.saveStatus && newDataByStore != oldDataByAjax){
			appletData.confirmText = '此时离开将丢失已编辑的内容!';
			appletData.confirmTitle = '是否确定离开？';
			appletData.confirmType = 2;
			confirm.value.show();
			return false;
		}else{
			appletData.storeIndex = index;
			appletData.esid = appletData.storeList[index].encryptionId;
			appletData.sid = appletData.storeList[index].sid;
			getConfig();
			getConfig(4);
		}
	}
	
	//保存并发布
	function saveConfirm(){
		appletData.confirmText = '';
		appletData.confirmTitle = '确定保存并发布吗？';
		appletData.confirmType = 3;
		confirm.value.show();
	}
	function saveOrIssued(type){
		getMyLineStore({ sid:  appletData.sid }).then((res) => {
		let storeData = JSON.parse(JSON.stringify(store.state.appletFormData[appletData.tabNum]));
		var isTrue = true;
		validation(storeData, res.data ,()=>{ //验证表单必填
			isTrue = false;
		});//验证
		if(!isTrue){
			return false;
		}
		var obj = {
			publishStatus:type,
			topIndex:Number(appletData.tabNum)+1,
		}
		obj.sid = appletData.tabNum > 1 ? 0 : appletData.storeList[appletData.storeIndex].sid;
		var moduleList = [];
		storeData.list.map((n,i)=>{
			var newN = JSON.parse(JSON.stringify(n));
			var module = {
				moduleName:newN.moduleName,
				moduleStatus:newN.moduleStatus ? 1: 0,
				sortNum:i+1,
				topIndex:obj.topIndex,
				id:newN.id
			};
			if(newN.moduleName == '1_STORE_INFO'){ //门店信息-经纬度
				module.latItude = newN.coordinate ? newN.coordinate.split(',')[0]:'';
				module.longItude = newN.coordinate ? newN.coordinate.split(',')[1] : '';
				// newN.banners.splice(0,1);
			}
			if(newN.moduleName == '1_FUNCTION_ENTRANCE'){ //功能入口-存储功能的名字
				module.runAppletShowModules = []
				newN.modules.map((k,j)=>{
					module.runAppletShowModules.push({
						sortNum:1+j,
						checkStatus:k.checked ? 1 : 0,
						id:k.id,
						newName:k.name,
						nameType:k.nameType
					})
				})
				delete newN.modules;
			}
			if(newN.moduleName == '1_COACH_INTRODUCTION'){  //tab教练单独存储
				newN.list.map((n,i)=>{
					n.certificateLink = n.certificateLink.join(',');
					n.labels = n.labels.join(',');
				})
				module.coachList = newN.list;
				delete newN.list;
			}
			if(newN.moduleName == '1_RECOMMENDED_COURSES'){  //推荐课程单独处理
				module.runAppletCourseNameList = newN.list;
				delete newN.list;
			}
			if(newN.moduleName == '1_RECOMMENDED_CYCLES'){  //推荐班级单独处理
				module.runAppletCourseCycleList = newN.list;
				delete newN.list;
			}
			if(newN.moduleName == '1_COURSE_NAME_CARD' || newN.moduleName == '1_ADMISSION_CARD' || newN.moduleName == '1_STORED_VALUE_CARD' || newN.moduleName == '1_EXPERIENCE_CARD' ){  //卡项配置
				module.runAppletProductList = newN.list;
				delete newN.list;
			}
			if(newN.moduleName == '5_SKIN_SETTINGS'){ //主题
			// 	newN.list.map((n,index)=>{
			// 		n.id = index+1;
			// 	})
				delete newN.bgUse;
				delete newN.colorUse;
			}
			delete newN.component;
			delete newN.moduleName;
			delete newN.moduleStatus;
			module.moduleInfo = n.moduleStatus ? JSON.stringify(newN) :'';
			moduleList.push(module);
		})
		obj.runAppletSettingList = moduleList;
		insertAll(obj).then((res)=>{
			appletData.saveStatus = false;
			if(res.code == 0){
				ElMessage({
					message:type == 1 ? '发布成功' : '暂存成功',
					type: 'success'
				})
			}
		})
	  })
	}
	//获取tab对应的配置
	function getConfig(tabNum){
		var newTabNum = tabNum || appletData.tabNum;
		appletData.loading = true;
		appletData.saveStatus = true;
		var storeData = store.state.appletFormData[newTabNum];
		storeData.active = 0; //初始化tab下左侧选中状态
		editList({
			topIndex:Number(newTabNum)+1,
			sid: appletData.storeList[appletData.storeIndex].sid,
		}).then((res)=>{
			if(res.code == 0){
				let arrFide = res.remark && res.remark.menuIds || []
				let fide = arrFide.indexOf(335) >-1 ? true : false
				localStorage.setItem("fide", fide)
				var storeDataList = [];
				res.data.map((n,u)=>{
					var info = oldAppletFormData[newTabNum].list.filter(l => n.moduleName == l.moduleName);
					if(info.length > 0){
						var initData = JSON.parse(JSON.stringify(info[0]));
						var moduleInfo = n.moduleInfo ? JSON.parse(n.moduleInfo) : '';
						if(moduleInfo){
							for(var key in moduleInfo){
								initData[key] = moduleInfo[key];
							}
							if(initData.moduleName == '1_STORE_INFO'){ // 首页
								initData['banners'].splice(0,0,'1');
							}
							if(initData.moduleName == '5_SKIN_SETTINGS'){
								initData.bgUse = moduleInfo.bgColor;
								initData.colorUse = moduleInfo.active;
							}
						}
						initData.moduleStatus = u == 0 || n.moduleStatus == 1 ? true : false;
						initData.id = n.id;
						if(initData.moduleName == '1_FUNCTION_ENTRANCE'){ //功能入口特殊处理
							n.runAppletShowModules.map((j,i)=>{
								if(j.newName){ //有才替换
									initData.modules[i].name = j.newName;
								}
								initData.modules[i].nameType = j.nameType;
								initData.modules[i].id = j.id || '';
								initData.modules[i].checked = j.checkStatus == 1 ? true : false;
							})
						}
						if(initData.moduleName == '1_COACH_INTRODUCTION'){  //教练介绍特殊处理
							var newList = [];
							if(n.coachList && n.coachList.length > 0){
								n.coachList.map((z,m)=>{
									newList.push({
										briefIntroduction: z.briefIntroduction,
										certificateLink: z.certificateLink ? z.certificateLink.split(',') :[],
										headImgUrl: z.headImgUrl,
										id: z.id,
										labels: z.labels ? z.labels.split(',') :[],
										name: z.name,
										userId: z.userId
									})
								})
							}
							initData.list = newList;
						}
						if(initData.moduleName == '1_RECOMMENDED_COURSES'){  // 预约课程特殊处理
							initData.list = n.runAppletCourseNameList.map((n)=>{
								n.labels = n.trainPurposeName ? n.trainPurposeName.split(',') : []
								return n
							});
						}
						if(initData.moduleName == '1_RECOMMENDED_CYCLES' && n.runAppletCourseCycleList){  // 推荐班级特殊处理
						  initData.list = n.runAppletCourseCycleList.map((n)=>{
							n.labels = n.trainPurposeName ? n.trainPurposeName.split(',') : [];
							n.id = n.courseCycleId;
							return n
						  });
						}
						if(initData.moduleName == '1_COURSE_NAME_CARD' || initData.moduleName == '1_ADMISSION_CARD' || initData.moduleName == '1_STORED_VALUE_CARD' ||  initData.moduleName == '1_EXPERIENCE_CARD'){  //预约课程特殊处理
							initData.list = n.runAppletProductList.map(n=>{
								n.productCover = n.productImg;
								return n;
							});
						}
						storeDataList.push(initData);
					}
				})
				if(!tabNum){
					oldDataByAjax = JSON.stringify(storeDataList);
				}
				storeData.list = storeDataList;
				
			}
			appletData.loading = false;
		})
	}
	//验证必填
	function validation(storeData, data,callback){
		console.log(data)
		storeData.list.map((z)=>{
			if(z.moduleName == '2_APPOINTMENT_TYPE'){ //预约验证
				var checkList = z.list.filter(n => n.checked);
				if(checkList.length == 0){
					ElMessage({
						message:'至少选择一项展示', 
						type: 'warning'
					})
					callback();
					return false;
				}
			}
			if(z.moduleName == '3_MORE_FEATURES' && z.moduleStatus){ //更多服务
				var checkList = z.modules.filter(n => n.checked);
				if(checkList.length == 0){
					ElMessage({
						message:'更多服务至少选择一项展示', 
						type: 'warning'
					})
					callback();
					return false;
				}
			}
			var arr = [];
			if(z.moduleName == '1_STORE_INFO'){ //门店信息
					z.city = [data.provinceName,data.cityName, data.areaName]
					z.address = data.storeAdd
					z.coordinate = data.latitude+','+data.longitude
					z['banners'].splice(0,1)
					arr = [
						{name:'轮播图',value:'banners'},
						{name:'门店logo',value:'logo'},
						{name:'门店名称',value:'storeName'},
						{name:'营业时间',value:'time'},
						{name:'联系电话',value:'phone'},
						{name:'店铺地址',value:'city'},
						{name:'详细地址',value:'address'},
						{name:'地址坐标',value:'coordinate'}
					];
					for(var i=0;i<arr.length;i++){
						var item = z[arr[i].value]
						var val = Object.prototype.toString.call(item);
						if(((val == '[object String]' || val == '[object Number]') && !item) || (val == '[object Array]' && item.length == 0)){
							ElMessage({
								message:arr[i].name+'为必填项', 
								type: 'warning'
							})
							callback()
							return false
						}
					}
				}
			})
	}
</script>

<style scoped lang="stylus">
	.applet_container {
		padding 20px;
		background-color white;
		.componet_tab{
			position relative;
			height 40px;
			ul{
				position absolute;
				left 0;
				top 0;
				bottom 0;
				right 0;
				z-index 10;
			}
			li{
				height 40px;
				line-height 40px;
				margin-right 40px;
				cursor pointer;
			}
			li.active{
				color #409eff;
				border-bottom 2px solid #409eff;
			}
		}
		.componet_tab::after{
			content: "";
			position: absolute;
			left: 0;
			bottom: 0px;
			width: 100%;
			height: 2px;
			border-bottom 2px solid #e4e7ed;
		}
	}
</style>
