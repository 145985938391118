<template>
	<workArea :barlist="pageData.barlist">
    <div class="p20 bg-fff">
        <!-- 查询 -->
        <div class="ww100 rx-wsc" ref="queryBox">
            <div class="query_item mb16 rx-c">
                <div class="query_tip rx-ec">店铺</div>
                <el-select class="query_con" v-model="pageData.searchForm.encryptionId" placeholder="请选择">
                    <el-option label="全部" value="" selected></el-option>
                    <el-option v-for="item in pageData.storeList" :key="item.id" :label="item.storeName" :value="item.encryptionId">
                    </el-option>
                </el-select>
            </div>
            <div class="query_item mb16 rx-c">
                <div class="query_tip rx-ec">时间范围</div>
                <el-select  class="query_con" v-model="pageData.searchForm.timeIn" placeholder="请选择">
                    <el-option label="今天" value="1" selected></el-option>
                    <el-option label="昨天" value="2" ></el-option>
                    <el-option label="近7天" value="5" ></el-option>
                    <el-option label="本月" value="20" ></el-option>
                    <el-option label="上月" value="21" ></el-option>
                    <el-option label="近三月" value="23" ></el-option>
                    <el-option label="其他" value="-1" ></el-option>
                </el-select>
            </div>
            <div class="query_item mb16 rx-sc ml16" style="width:280px" v-show="pageData.searchForm.timeIn == -1">
                <el-date-picker class="ww100 hh100"
                    v-model="pageData.searchForm.transTimeStr"
                    value-format="YYYY-MM-DD"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </div>
            <el-button class="mb16 ml16" type="primary" :icon="Search" @click="searchTable">查询</el-button>
            <div v-show="queryItemShowAll < 2" @click="triggerShowItemAll" class="query_btn_trans mb16 ml16 rx-cc">{{queryItemShowAll == 1 ? "收起":"展开"}}<el-icon class="el-icon--right" :style="{transform: 'rotate('+(queryItemShowAll == 1 ? '-90deg':'90deg')+')'}"><DArrowRight /></el-icon></div>

            <el-button class="mb16 ml16" :icon="Upload" style="position:absolute;right:38px;font-weight:normal;" @click="exportAllData">导出</el-button>
        </div>
        <!-- 数据表格 -->
        <div>
            <el-table ref="gatherTable" :data="pageData.tableData" stripe style="width: 100%">
                <el-table-column prop="transDateStr" label="日期" ></el-table-column>
                <el-table-column prop="ordAmt" label="交易金额(元)" />
                <el-table-column prop="merFee" label="手续费(元)" />
                <el-table-column prop="orgRefAmtTotal" label="退款总金额(元)" />
                <el-table-column prop="orgRefFeeAmtTotal"  label="返还手续费(元)" />
                <el-table-column prop="money" label="结算款(元)" ></el-table-column>
                <el-table-column label="操作" align="center">
                    <template #default="scope">
                        <a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="bindShowDetail(scope.row)">查看明细</a>
                        <a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="downloadBill(scope.row)">下载对账单</a>
                    </template>
                </el-table-column>
            </el-table>
            <div class="mt20 rx-ec" v-show="pageData.tableData.length > 0">
                <el-pagination
                  v-model:currentPage="pageData.searchForm.page"
                  :page-sizes="[15,20,30,50,100]"
                  :page-size="pageData.searchForm.limit"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="pageData.totalCount"
                  @size-change="sizeChange"
                  @current-change="()=>{getList()}"
                >
                </el-pagination>
              </div>
        </div>

        <!-- 查看明细 -->
        <el-dialog
            v-model="pageData.gatherDetail.show"
            :title="pageData.gatherDetail.title"
            width="1000px"
            :before-close="()=>{pageData.gatherDetail.show = false}"
          >
            <div class="p20">
                <div class="ww100 rx-wbs">
                    <div class="query_item mb16 rx-c">
					    <div class="w110 t_a_r mr16">店铺</div>
					    <div class="w200">
					    	<el-select class="query_con" v-model="pageData.detailForm.encryptionId" :disabled="pageData.isDisabled" placeholder="请选择">
					    		<el-option v-for="item in pageData.storeList" :key="item.id" :label="item.storeName" :value="item.encryptionId">
					    		</el-option>
					    	</el-select>
					    </div>
					</div>
                    <div class="query_item mb16 rx-c">
                        <div class="w110 t_a_r mr16">订单号</div>
                        <el-input class="query_con" v-model="pageData.detailForm.batchCode" placeholder="输入订单号" />
                    </div>
                    <div class="query_item mb16 rx-c">
                        <div class="w110 t_a_r mr16">交易单号</div>
                        <el-input class="query_con" v-model="pageData.detailForm.batchCodePay" placeholder="输入交易单号" />
                    </div>
                    <div class="query_item mb16 rx-c">
                        <div class="w110 t_a_r mr16">第三方交易单号</div>
                        <el-input class="query_con" v-model="pageData.detailForm.batchCodeThree" placeholder="输入第三方交易单号" />
                    </div>

                    <div class="query_item mb16 rx-c">
                        <div class="w110 t_a_r mr16">支付方式</div>
					    <div class="w200">
					    	<el-select  class="query_con" v-model="pageData.detailForm.payChannelType" placeholder="请选择">
					    		<el-option label="全部" value="" selected></el-option>
					    		<el-option label="微信" value="10" ></el-option>
					    		<el-option label="支付宝" value="20" ></el-option>
					    		<el-option label="刷卡" value="30" ></el-option>
					    		<el-option label="银联二维码" value="40" ></el-option>
					    		<el-option label="云闪付" value="50" ></el-option>
					    	</el-select>
					    </div>
                    </div>
                    <div>
                        <el-button class="mb16 ml16" type="primary" :icon="Search" @click="searchDetailTable">查询</el-button>
                        <el-button class="mb16 ml16" :icon="Upload" @click="getDetailList(1)">导出</el-button>
                    </div>
                    
                </div>
                <div class="ww100">
                    <el-table ref="gatherDetailTable" :data="pageData.gatherDetail.tableData" stripe >
                        <el-table-column prop="storeName" fixed width="160" label="机构名称" ></el-table-column>
                        <el-table-column prop="payTimeStr" width="160" label="交易时间" />
                        <el-table-column prop="batchCode" width="160" label="订单号" />
                        <el-table-column prop="termOrdId" width="160" label="交易单号" />
                        <el-table-column prop="outTransId" width="160" label="第三方交易单号" />
                        <el-table-column prop="shortCode" width="160"  label="支付码" />
                        <el-table-column prop="ordAmt" width="160"  label="交易金额（元）" />
                        <el-table-column prop="merFee" width="160"  label="手续费（元）" />
                        <el-table-column width="160"  label="支付方式">
                            <template #default="scope">
                                <span v-if="scope.row.payChannelType == 1">支付宝支付</span>
                                <span v-if="scope.row.payChannelType == 2">微信支付</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="remark" width="160"  label="备注" />
                        <el-table-column width="160"  label="状态">
                            <template #default="scope">
                                <span class="color-67c23a" v-if="scope.row.orderType == 1">已入账</span>
                                <span class="color-EE4B36" v-if="scope.row.orderType == 2">已退款</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="mt20 ml20 rx-ec" v-show="pageData.gatherDetail.tableData.length > 0">
                        <el-pagination
                          v-model:currentPage="pageData.detailForm.page"
                          :page-sizes="[15,20,30,50,100]"
                          :page-size="pageData.detailForm.limit"
                          layout="total, sizes, prev, pager, next, jumper"
                          :total="pageData.detailCount"
                          @size-change="detailSizeChange"
                          @current-change="getDetailList"
                        >
                        </el-pagination>
                      </div>
                </div>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="pageData.gatherDetail.show = false">取消</el-button>
              </span>
            </template>
        </el-dialog>
    </div>
	</workArea>
</template>
<script setup>
    import {ref,reactive,onMounted} from 'vue';
    import { ElMessage} from 'element-plus'
    import QRCode from 'qrcodejs2'
    import {Search,Upload} from '@element-plus/icons-vue'
    import {Refresh,DArrowRight,Connection,Close} from '@element-plus/icons-vue'
    import CommonTip from '@/components/CommonTip.vue'
    import Confirm from '@/components/Confirm.vue'
    import utils from '@/common/utils.js'
    import { getUpayOrderLog,getUpayOrderLogDetail,exportAll,getDownloadBill } from '@/api/gatherData.js';
    import { getFrameworkAllList } from '@/api/staff.js'
	import workArea from '@/components/branch-store/workArea.vue';
    const pageData = reactive({
		barlist:[{ name : '首页' },{ name : '数据统计' },{ name : '收银台数据' }],
        tableData:[],
        totalCount : 0,
        searchForm:{
            page:1,
            limit:15,
            timeIn:"1",
            transTimeStr:'',
			encryptionId:''
        },
        storeList:[],
        gatherDetail:{  //新增员工、编辑员工
            show:false,
            title:'查看明细',
            tableData:[],
        },
		isDisabled:false,
		detailCount:0,
		detailForm:{
			limit:15,
			page : 1,
			encryptionId:'',
			payChannelType:'',
			batchCode: '',
			batchCodePay: '',
			batchCodeThree: ''
		},
		
    })
    // 查看明细
    function bindShowDetail(item){
		for(var key in pageData.detailForm){
			pageData.detailForm[key] = '';
		}
        pageData.detailForm.limit = 15;
        pageData.detailForm.page = 1;
        pageData.detailForm.transDateStr = item['transDateStr'];
        pageData.gatherDetail.show = true;
		pageData.detailForm.encryptionId = pageData.searchForm.encryptionId || pageData.storeList[0].encryptionId;
		getDetailList();
    }
    // 下载对账单
    function downloadBill(item){
        var params = {
            transDateStr: item.transDateStr,
            encryptionId: item.encryptionId
        };
        getDownloadBill(params).then((res)=>{
            if(res.code == 0){
                ElMessage({
                    type: 'success',
                    message: '下载成功'
                })
            }
        })
    }
	function sizeChange(limit){
		pageData.searchForm.limit = limit;
		searchTable();
	}
    //获取收银台列表数据
	function searchTable(){
		pageData.searchForm.page = 1;
		getList();
	}
    getList();
    function getList(){
        var params = Object.assign({}, pageData.searchForm)
        if(params['timeIn'] == -1){
            if(!params.transTimeStr){
                ElMessage({
                    type: 'warning',
                    message: '请选择日期范围'
                })
                return;
            }
            params.transTimeStr = params.transTimeStr.join('-')
        }
        getUpayOrderLog(params).then((res)=>{
            if(res.code == 0){
                pageData.tableData = res.data;
                pageData.totalCount = res.count;
            }
        })
    }
	function detailSizeChange(limit){
		pageData.detailForm.limit = limit;
		searchDetailTable();
	}
	//获取收银台列表数据
	function searchDetailTable(){
		pageData.detailForm.page = 1;
		getDetailList();
	}
    function getDetailList(val){
        pageData.isDisabled = pageData.searchForm.encryptionId ? true : false; 
        var params = Object.assign({}, pageData.detailForm)
        if (val == 1) {
            params.exportData = 1
        }
        getUpayOrderLogDetail(params).then((res)=>{
            if(res.code == 0){
                if (val == 1) {
                    ElMessage.success('正在导出，请稍后前往下载中心下载')
                } else {
                    pageData.gatherDetail.tableData = res.data;
                    pageData.gatherDetail.totalCount = res.count;
                }
            }
        })
    }

    function exportAllData(){
        var params = Object.assign({}, pageData.searchForm)
        if(params['timeIn'] == -1){
            if(!params.transTimeStr){
                ElMessage({
                    type: 'warning',
                    message: '请选择日期范围'
                })
                return;
            }
            params.transTimeStr = params.transTimeStr.join('-')
        }
        exportAll(params).then((res)=>{
            if(res.code == 0){
                ElMessage({
                    type: 'success',
                    message: '导出成功'
                })
            }
        })
    }
    
    //所属架构搜索下拉框
    getFrameworkAllList().then((res)=>{
        if(res.code == 0){
            pageData.storeList = res.data;
        }
    })
    // 控制查询区域显示隐藏
    var queryBox = ref(null)
    var queryItemShowAll = ref(9);
    // 触发隐藏和展开
    function triggerShowItemAll(){
        for(var i=queryBox.value.children.length - 1;i>0;i--){
            if(queryBox.value.children[i].className.indexOf('query_item') > -1){
                queryBox.value.children[i].style.display= queryItemShowAll.value == 1 ? "none" : "";
            }
            if(queryItemShowAll.value == 1 && queryBox.value.offsetHeight < 60){
                break;
            }
        }
        queryItemShowAll.value = queryItemShowAll.value == 1 ? 0 : 1;
    }
    onMounted(()=>{
        utils.initQuery(queryItemShowAll,queryBox);
    });
</script>

<style scoped lang="stylus">
    @media screen and (max-width: 1366px) {
        .h400{
            height 300px !important;
        }
    }
.el-dialog__body{padding:0px !important;}
    .shuttle_box{
        border 1px solid #EBEEF5;
        border-radius 4px;
        .add_title{
            background-color #F4F9FF;
            padding 10px;
            text-align center;
        }
        .add_checked{
            width 100%;
            padding 20px;
            li{
                background-color #F5F5F5;
                border-radius 2px;
                border 1px solid #D6D6D6;
                cursor pointer;
                padding 5px 10px;
            }
            li:hover{
                border 1px solid #409EFF;
                i{
                    color red;
                }
            }
        }
        
    }
</style>
