<template>
	<div class="coach_info" v-show="storeData.moduleStatus">
		<h3 class=" mb10 rx-sc" v-show="!pageData.updateTitleShow"> 
			<div class="rx-ss">
				<span class="ft16">{{storeData.name}}</span>
				<unicon class="ml10 mr10" width="18px" name="edit-alt" fill="#47A1FE" @click="pageData.updateTitleShow = true"></unicon>
			</div>
			<span class="tips color-999 ft12">下面最多可添加30个教练</span>
		</h3>
		<div class="mb10 rx-sc" v-show="pageData.updateTitleShow">
			<div class="w150 mr20">
				<el-input type="text" v-model="pageData.titleName" maxlength="6" placeholder="限6个字符"></el-input>
			</div>
			<span class="color-409EFF mr20 c_p tool_btn" @click="updateTitle">确定</span>
			<span class="c_p" @click="pageData.updateTitleShow = false">取消</span>
		</div>
		<div class="store_form mt20">
			<div class="rx-sc mb12">
				<span>展示方式：</span>
				<div>
					<el-radio-group v-model="storeData.showType">
						<el-radio label="1" size="large">横向滚动</el-radio>
						<el-radio label="2" size="large">列表模式</el-radio>
					</el-radio-group>
				</div>
			</div>
			<div class="rx-ss mb12" v-if="storeData.list">
				<div class="w70">添加教练：</div>
				<div class="bg-fff flex1 pt20 pb20 pl10 pr10">
					<div v-show="storeData.list.length == 0">
						<AddCoach :sid="sid" v-if="storeData.list.length == 0" ref="addcoach1"></AddCoach>
						<div class="mt20 pl80">
							<el-button type="primary" @click="addSave(1)">确定</el-button>
						</div>
					</div>
					<div v-show="storeData.list.length > 0" style="max-width: 400px;">
						<MoveSort :list="storeData.list" @moveover="over">
							<template #[index] v-for="(item,index) in storeData.list" :key="index">
								<div class="pt5 pb5">
									<div class="coachMoveItem pl20 bg-FAFAFC pt20 pb20" :class="{active:storeData.active == index}" >
										<div class="move_icon_top">
											<unicon width="15px" name="align-center-v" fill="#C2C2C4"></unicon>
										</div>
										<div class="rx-sc" style="height: 44px;">
											<img class="w55 h55 b_r_100 mr20" :src="item.headImgUrl" alt="">
											<div>
												<h3 class="mb5">{{item.name}}</h3>
												<p>
													<span class="moveLabel" v-for="(n,i) in item.labels" :key="i">{{n}}</span>
												</p>
											</div>
										</div>
										<div class="move_icon_bottom">
											<unicon name="edit-alt" fill="#C2C2C4" hover-fill="#47A1FE" @click.capture="updateCoach(item,index)"></unicon>
											<unicon name="trash-alt" fill="#C2C2C4" hover-fill="#F87677" @click.capture="deleItme(index)"></unicon>
										</div>
									</div>
								</div>
							</template>
						</MoveSort>
						<div class="mt20">
							<el-button type="primary" @click="updateCoach('')"><el-icon><Plus /></el-icon>添加教练</el-button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-dialog
		    v-model="pageData.addCoachShow"
		    :title="pageData.addCoachTitle"
			width="800px"
		    :before-close="()=>{pageData.addCoachShow = false}"
		  >
		    <div class="pt20 pl20 pr20 pb20">
				<AddCoach :sid="sid" v-if="pageData.addCoachShow" ref="addcoach2"></AddCoach>
			</div>
		    <template #footer>
		      <div class="dialog-footer">
		        <el-button @click="pageData.addCoachShow = false">取消</el-button>
		        <el-button type="primary" @click="addSave(2)">确定</el-button>
		      </div>
		    </template>
		  </el-dialog>
	</div>
</template>

<script setup>
	const propData = defineProps({
	  tab:String,
	  itemIndex:Number,
	  sid:String
	})
	import {ref,reactive} from 'vue'
	import { useStore } from 'vuex'
	import MoveSort from '../MoveSort.vue'; //通用移动排序
	import AddCoach from './AddCoach.vue'
	import {Plus} from '@element-plus/icons-vue'
	import { ElMessage } from 'element-plus'
	import {appletCoachInsert} from '../../../../api/applet.js';
	const store = useStore();
	const storeData = store.state.appletFormData[propData.tab].list[propData.itemIndex];
	const addCoachForm = store.state.addCoachForm; //添加教练formData
	const pageData = reactive({
		uploadData:'',
		titleName:'',
		updateTitleShow:false,
		addCoachShow:false,
		addCoachTitle:'',
		updateCoachIndex:0
	})
	//编辑标题
	function updateTitle(){
		if(pageData.titleName){
			storeData.name = pageData.titleName;
			pageData.updateTitleShow = false;
		}else{
			ElMessage({
				message:'请输入标题',
				type: 'warning'
			})
		}
	}
	
	const addcoach1 = ref(); //页面内新增
	const addcoach2 = ref(); //弹窗新增 
	
	//新增/编辑教练
	function updateCoach(row,index){
		// 初始化数据
		for(var key in addCoachForm){
			addCoachForm[key] = '';
		}
		addCoachForm.labels = [];
		addCoachForm.certificateLink = [];
		pageData.updateCoachIndex = index;
		row = JSON.parse(JSON.stringify(row));
		pageData.addCoachTitle = row ? '编辑教练' : '新增教练';
		pageData.addCoachShow = true;
		if(row){
			for(var key in addCoachForm){  //编辑回显数据
				addCoachForm[key] = row[key];
			}
			setTimeout(()=>{
				addcoach2.value.returnHtml('set');
			},0)
		}
	}
	//添加教练保存
	function addSave(type){
		if(type == 1){
			addcoach1.value.returnHtml(); //获取个人简介
		}else{
			addcoach2.value.returnHtml(); //获取个人简介
		}
		setTimeout(()=>{
			var coachForm = JSON.parse(JSON.stringify(addCoachForm));
			if(!coachForm.userId){
				ElMessage({
					message:'请选择关联员工',
					type: 'warning'
				})
				return false;
			}
			if(!coachForm.headImgUrl){
				ElMessage({
					message:'请上传教练头像',
					type: 'warning'
				})
				return false;
			}
			if(!coachForm.name){
				ElMessage({
					message:'请填写教练名称',
					type: 'warning'
				})
				return false;
			}
			coachForm.sid = propData.sid;
			if(type == 2 && pageData.addCoachTitle == '编辑教练'){ //弹窗编辑
				storeData.list[pageData.updateCoachIndex] = coachForm;
				pageData.addCoachShow = false;
			}else{
				coachForm.labels = coachForm.labels.join(',');
				coachForm.certificateLink = coachForm.certificateLink.join(',');
				appletCoachInsert(coachForm).then((res)=>{
					if(res.code == 0){
						ElMessage({
							message:'保存成功',
							type: 'success'
						})
						pageData.addCoachShow = false;
						storeData.list.push({
							id:res.data,
							userId:coachForm.userId,
							headImgUrl:coachForm.headImgUrl,
							name:coachForm.name,
							labels:coachForm.labels ? coachForm.labels.split(',') : [],
							certificateLink:coachForm.certificateLink ? coachForm.certificateLink.split(',') : [],
							briefIntroduction:coachForm.briefIntroduction
						})
					}
				})
			}
		},500)
	}
	//删除已选
	function deleItme(index){
		storeData.list.splice(index,1);
	}
	// //更新store
	// function updateAppletData(){
	// 	store.commit('setAppletListData',{name:propData.tab,index:propData.itemIndex,value:pageData});
	// }
</script>

<style scoped lang="stylus">
	.coach_info{
		.coachMoveItem{
			position relative;
			.move_icon_top{
				position absolute;
				left 0px;
				top 0px;
			}
			.move_icon_bottom{
				position absolute;
				bottom 5px;
				right 5px;
				display none;
			}
			.moveLabel{
				display inline-block;
				background-color #F0F0F2;
				color #999;
				font-size 12px;
				padding 2px 10px;
				margin-right 5px;
			}
		}
		.coachMoveItem:hover .move_icon_bottom{
			display block;
		}
		.tips{
			font-weight: 500
		}
	}
</style>
