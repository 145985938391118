<template>
	<div class="pt20 pb20">
		<div class="rx-bc bg-f2f2f2 h35">
			<div class="rx-sc">
				<div class="w95 h35 rx-cc bdr2 color-666 c_p" :class="{'color-fff bg-03A3FF':tab == 1}" @click="tabClick(1)">
					订单汇总</div>
				<div class="w95 h35 rx-cc bdr2 color-666 c_p" :class="{'color-fff bg-03A3FF':tab == 2}" @click="tabClick(2)">
					订单分析</div>
			</div>
			<div class="rx-cc mr20">
				<span class="color-999 mr20 ft12">温馨提示：当前 页面数据仅统计截止至当前时间</span>
				<span class="color-main c_p t_d_u ft12" @click="formulaClick">计算公式</span>
			</div>
		</div>
		<div class="rx-bc mt20">
			<div class="rx-sc">
				<div class="w210 mr12">
					<el-select v-model="searchQuery.timeIn" placeholder="日期类型">
						<el-option v-for="item in searchQueryList.timeTypeList" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</div>
				<div class="w210 mr12" v-show="searchQuery.timeIn == -1">
					<el-date-picker style="width: 210px; height: 32px" format="YYYY-MM-DD" value-format="YYYY-MM-DD"
						v-model="searchQuery.searchTime" type="daterange" start-placeholder="开始日期"
						end-placeholder="结束日期" :disabled-date="datePickerDisabled.disabledDate" clearable>
					</el-date-picker>
				</div>
				<div class="w210 mr12" v-show="searchQuery.timeIn == -2">
					<el-date-picker style="width: 210px; height: 32px" format="YYYY-MM" value-format="YYYY-MM"
						v-model="searchQuery.searchTime" type="monthrange" start-placeholder="开始月份"
						end-placeholder="结束月份" :disabled-date="datePickerDisabled.setMonthDisabled">
					</el-date-picker>
				</div>
				<el-button type="primary" @click="tabSearchClick" :icon="Search">搜索</el-button>
			</div>
			<el-button :icon="Upload" @click="downloadTable">导出表格</el-button>
		</div>
		<div class="rx-sc bg-f2f2f2 h90 mt20" v-show="tab == 1" >
			<div class="rx-cc h90" style="width: 9%;background-color:#CBE2FF;">
				<div class="t_a_c">
					<p class="color-333 fwb mb10">本次筛选</p>
					<p class="color-333 fwb ft16">合计</p>
				</div>
			</div>
			<div class="rx-cc" style="width: 9%;">
				<div class="t_a_c">
					<p class="color-999 fwb mb10">订单总数</p>
					<p class="color-333 fwb ft16">{{ tableData.remark.orderCount }}</p>
				</div>
			</div>
			<div class="rx-cc" style="width: 9%;">
				<div class="t_a_c">
					<p class="color-999 fwb mb10">已支付订单</p>
					<p class="color-333 fwb ft16">{{ tableData.remark.orderPayCount }}</p>
				</div>
			</div>
			<div class="rx-cc" style="width: 9%;">
				<div class="t_a_c">
					<p class="color-999 fwb mb10">已取消订单</p>
					<p class="color-333 fwb ft16">{{ tableData.remark.orderCancelCount }}</p>
				</div>
			</div>
			<div class="rx-cc" style="width: 9%;">
				<div class="t_a_c">
					<p class="color-999 fwb mb10">已退款订单</p>
					<p class="color-333 fwb ft16">{{ tableData.remark.orderRefundCount }}</p>
				</div>
			</div>
			<div class="rx-cc" style="width: 9%;">
				<div class="t_a_c">
					<p class="color-999 fwb mb10">部分退款订单</p>
					<p class="color-333 fwb ft16">{{ tableData.remark.orderRefundPartCount }}</p>
				</div>
			</div>
			<div class="rx-cc" style="width: 9%;">
				<div class="t_a_c">
					<p class="color-999 fwb mb10">订单总实收</p>
					<p class="color-333 fwb ft16">{{ tableData.remark.orderActualMoney }}</p>
				</div>
			</div>
			<div class="rx-cc" style="width: 9%;">
				<div class="t_a_c">
					<p class="color-999 fwb mb10">订单总退款</p>
					<p class="color-333 fwb ft16">{{ tableData.remark.orderRefundMoney }}</p>
				</div>
			</div>
			<div class="rx-cc" style="width: 9%;">
				<div class="t_a_c">
					<p class="color-999 fwb mb10">订单净收入</p>
					<p class="color-333 fwb ft16">{{ tableData.remark.orderNetIncomeMoney }}</p>
				</div>
			</div>
			<div class="rx-cc" style="width: 9%;">
				<div class="t_a_c">
					<p class="color-999 fwb mb10">应售卖总额</p>
					<p class="color-333 fwb ft16">{{ tableData.remark.orderShouldMoney }}</p>
				</div>
			</div>
			<div class="rx-cc" style="width: 9%;">
				<div class="t_a_c">
					<p class="color-999 fwb mb10">减免金额</p>
					<p class="color-333 fwb ft16">{{ tableData.remark.orderReduceMoney }}</p>
				</div>
			</div>
		</div>
		<div class="mt20 color-999 ft12" v-show="tab == 1">温馨提示：总实收金额中包含使用储值卡付款购买商品的金额。</div>
		<div class="mt20">
			<el-table :data="tableData.list"
				style="width: 100%">
				<el-table-column prop="realName" fixed label="商品类型">
					<template #default="scope">
						{{ filtersType(scope.row.productType) }}
					</template>
				</el-table-column>
				<el-table-column prop="orderCount" label="订单总数"  v-if="tab == 1 " />
				<el-table-column prop="orderPayCount" label="已支付订单"  v-if="tab == 1 " />
				<el-table-column prop="orderCancelCount" label="已取消订单"  v-if="tab == 1 " />
				<el-table-column prop="orderRefundCount" label="已退款订单"  v-if="tab == 1 " />
				<el-table-column prop="orderRefundPartCount" label="部分退款订单"  v-if="tab == 1 " />
				<el-table-column prop="orderActualMoney" label="订单总实收(元)"  v-if="tab == 1 " />
				<el-table-column prop="orderRefundMoney" label="订单总退款(元)"  v-if="tab == 1 " />
				<el-table-column prop="orderNetIncomeMoney" label="订单净收入(元)"  v-if="tab == 1 " />
				<el-table-column prop="orderShouldMoney" label="应售卖总额(元)"  v-if="tab == 1 " />
				<el-table-column prop="orderReduceMoney" label="减免金额(元)"  v-if="tab == 1 " />

				<!-- //商品 -->
				<el-table-column prop="orderCount" label="已售出订单总数"  v-if="tab == 2 " />
				<el-table-column prop="orderDayAvgCount" label="日均单量"  v-if="tab == 2 " />
				<el-table-column prop="orderActualMoney" label="订单总收入(元)"  v-if="tab == 2 " />
				<el-table-column prop="orderAvgMoney" label="客单价(元) "  v-if="tab == 2 " />
				<el-table-column prop="orderOnlineCount" label="线上订单数"  v-if="tab == 2 " />
				<el-table-column prop="orderOnlineMoney" label="线上实收(元)"  v-if="tab == 2 " />
			</el-table>
		</div>
		<Formula v-model="formulaShow" :type="formulaType"></Formula>
	</div>
</template>

<script setup>
	import {
		reactive,
		ref,
		onMounted
	} from 'vue';
	import {
		Search,
		Upload,
		Download
	} from '@element-plus/icons-vue';
	import utils from '@/common/utils.js'
	import {
		orderSummary,
		orderAnalysis
	} from '@/api/dataCenter.js'
	import {
		ElMessage
	} from 'element-plus'
	import Formula from '@/components/branch-store/datacenter/formula.vue';
	//日期范围/月份范围控件配置
	const datePickerDisabled = reactive({
		disabledDate(time) {
			return time.getTime() > new Date().getTime()
		},
		setMonthDisabled(time) {
			// 获取当前的月份信息
			const date = new Date(); // 获取当前的时间基本信息，值是这样的： Tue Jul 20 2021 14:59:43 GMT+0800 (中国标准时间)
			const year = date.getFullYear(); // 获取当前年份，值是这样的： 2021
			let month = date.getMonth() + 1; // 获取当前月份，值是这样的： 6   getMonth()方法返回值是0-11，也就是1月份到12月份，所以要加上1，才是当前月份
			if (month >= 1 && month <= 9) {
				// 如果是1月到9月就要在前面补上一个0   比如：02、07    月份这样表示
				month = "0" + month;
		 }
			const nowDate = year.toString() + month
		.toString(); // 转换成字符串拼接，最终得到年和月，比如此时的时间是2021年7月20号，所以nowDate的值是：202107
			// 获取时间选择器的月份信息
			const timeyear = time.getFullYear(); // 获取时间选择器的年份（有很多）
			let timemonth = time.getMonth() + 1; // 与上面同理
			if (timemonth >= 1 && timemonth <= 9) {
				timemonth = "0" + timemonth;
			}
			const elTimeData = timeyear.toString() + timemonth.toString();

			// 返回，时间选择器的日期月份大于当前日期的月份，又因为disabledDate函数是控制月份禁用不可选
		 // 所以，最终就是：时间选择器的月份大于当前的月份，就都禁用掉，所以就实现了最终效果：
			// 大于等于当前月份都不可选 
			return elTimeData > nowDate; // 这里虽然是字符串，但是弱类型语言js会做一个转换，是可以比较大小的如： '202107' > '202008'
		},
	})
	/**
     * @param (String, Number) value-类型, type-1 商品类型 2-支付状态 3-支付方式
     * 表格字段过滤过滤展示
     */
	const productTypeList = [
		{value: 1, label: '课程卡' },
		{value: 2, label: '入场卡' },
		{value: 3, label: '储值卡' },
		{value: 4, label: '体验卡' },
		{value: 11, label: '团体课' },
		{value: 12, label: '私教课' },
		{value: 13, label: '小班课' }
	]
    function filtersType(value) {
        let text = ''
        productTypeList.forEach((item) => {
            if (item.value == value) {
                text = item.label
    		}
        })
        return text
    }

	//搜索字段
	const searchQuery = reactive({
		timeIn: 5,
		searchTime: [],
	})
	//切换类型
	const tab = ref(1);
	const tabClick = function(num) {
		tab.value = num;
		initSearchQuery();
		getData();
	}

	//搜索项数据
	const searchQueryList = reactive({
		timeTypeList: [{
				label: '近7天',
				value: 5
			},
			{
				label: '近15天',
				value: 6
			},
			{
				label: '近30天',
				value: 7
			},
			{
				label: '近60天',
				value: 8
			},
			{
				label: '近90天',
				value: 9
			},
			{
				label: '近6月',
				value: 24
			},
			{
				label: '近12月',
				value: 25
			},
			{
				label: '自定义日期',
				value: -1
			},
			{
				label: '自定义月份',
				value: -2
			}
		]
	});
	/**
	 * 搜索
	 */
	function tabSearchClick() {
		if (searchQuery.timeIn == '-1') {
            if (!searchQuery.searchTime || !searchQuery.searchTime.length) {
                ElMessage({
                    type : 'warning',
                    message : '请选择日期'
                });
                return;
            }
            if (utils.datedifference(searchQuery.searchTime[0], searchQuery.searchTime[1]) > 31) {
                ElMessage({
                    type : 'warning',
                    message : '自定义日期不能超过31天'
                });
                return;
            }
        }
        if (searchQuery.timeIn == '-2') {
            if (!searchQuery.searchTime || !searchQuery.searchTime.length) {
                ElMessage({
                    type : 'warning',
                    message : '请选择月份'
                });
                return;
            }
            if (utils.MonthsBetw(searchQuery.searchTime[0], searchQuery.searchTime[1]) > 12) {
                ElMessage({
                    type : 'warning',
                    message : '自定义月份不能超过12个月'
                });
                return;
            }
        }
		getData()
	}
	//初始化表单
	const initSearchQuery = function() {
		for (var k in searchQuery) {
			searchQuery[k] = Object.prototype.toString.call(searchQuery[k]) == '[object Array]' ? [] : '';
		}
		searchQuery['timeIn'] = 5;
		searchQueryList.cardDataList = [];
	}
	//获取数据
	const tableData = reactive({
		list: [],
		remark: {}
	});
	const formulaType = ref('2'); //计算公式类型
	const getData = function(exportData) {
		if (tab.value == 1) {
			formulaType.value = '17';
			orderSummary({
				timeIn: searchQuery.timeIn,
				searchTime: searchQuery.searchTime.join(' - '),
				exportData:exportData || 0
			}).then((res) => {
				if (res.code == 0) {
					if (exportData == 1) { //导出
						ElMessage({
							type: 'success',
							message: '正在导出，请稍后前往下载中心下载'
						})
						return false;
					}
					tableData.list = res.data
					tableData.remark = res.remark || {}
				}
			})
		} else if (tab.value == 2) {
			formulaType.value = '18';
			orderAnalysis({
				timeIn: searchQuery.timeIn,
				searchTime: searchQuery.searchTime.join(' - '),
				exportData: exportData || 0
			}).then((res) => {
				if (res.code == 0) {
					if (exportData == 1) { //导出
						ElMessage({
							type: 'success',
							message: '正在导出，请稍后前往下载中心下载'
						})
						return false;
					}
					tableData.list = res.data;
				}
			})
		}
	}	
	getData();

	//导出表格
	const downloadTable = function() {
		getData(1);
	}

	//表格排序
	// const sortChange = function(column, prop, order){
	// 	tabForm.page = 1;
	// 	tableData.list.sort((a,b)=>{
	// 		var x = a[prop],y = b[prop];
	// 		if(order == 'ascending'){
	// 			return ((x < y) ? -1 : ((x > y) ? 1 : 0));
	// 		}else{
	// 			return ((x < y) ? ((x > y) ? 1 : 0) : -1);
	// 		}
	// 	})
	// }
	//计算公式
	const formulaShow = ref(false);
	const formulaClick = function() {
		formulaShow.value = true;
	}
	
	
</script>
<style type='css/style' scoped>
	
</style>
