<template>
	<el-upload v-if="propData.isCropper" class="uploadImg" ref="fileUpload" :action="uploadInfo.uploadUrl"
		:data="uploadInfo.uploadData" :on-change="uploadChange" :auto-upload="false"
		:show-file-list="false" :http-request='httpRequest'>
		<slot></slot>
	</el-upload>
	<el-upload v-else class="uploadImg" ref="fileUpload" :action="uploadInfo.uploadUrl"
		:data="uploadInfo.uploadData" :on-change="uploadChange" :auto-upload="false"
		:show-file-list="false">
		<slot></slot>
	</el-upload>
	<el-dialog 
		v-model="pageData.dialogVisible"
		title="图片裁剪"
		width="600px"
		:before-close="cancel"
		append-to-body="true"
	>
  		<div class="h600 w600 p20 vueCropper">
  		    <VueCropper
  		      ref="cropper"
  		      :img="pageData.option.img"
  		      :outputSize="pageData.option.size"
  		      :outputType="pageData.option.outputType"
  		      :info="true"
  		      :full="pageData.option.full"
  		      :fixed="pageData.option.fixed"
  		      :fixedNumber="pageData.option.fixedNumber"
  		      :can-move="pageData.option.canMove"
  		      :can-move-box="pageData.option.canMoveBox"
  		      :fixed-box="pageData.option.fixedBox"
  		      :original="pageData.option.original"
  		      :auto-crop="pageData.option.autoCrop"
  		      :auto-crop-width="pageData.option.autoCropWidth"
  		      :auto-crop-height="pageData.option.autoCropHeight"
  		      :center-box="pageData.option.centerBox"
  		      @realTime="realTime"
  		      :high="pageData.option.high"
  		      @img-load="imgLoad"
  		      mode="cover"
  		      :max-img-size="pageData.option.max"
  		      @crop-moving="cropMoving"
  		    />
  		</div>
		<template #footer>
    	  <span class="dialog-footer">
    	    <el-button @click="cancel">取 消</el-button>
    	    <el-button type="primary" @click="addCropper">确定裁剪</el-button>
    	  </span>
    	</template>
  	</el-dialog>
</template>

<script setup>
	import { ref, reactive } from 'vue';
	import { commonUpload } from '@/common/common.js'
	import 'vue-cropper/dist/index.css'
	import { VueCropper }  from "vue-cropper";
	import { ElMessage } from 'element-plus'
	import utils from '@/common/utils.js';
	import axios from 'axios'
	const propData = defineProps({
	  	isCropper:{
   		  	type: Boolean,
   		  	required: true
   		},
		autoCropWidth: {
			type: Number
		},
		autoCropHeight: {
			type: Number
		}
	})
	const emit = defineEmits(['change']);
	const cropper = ref()
	const pageData = reactive({
		dialogVisible: false,
		url: '',
		option: {
      	  	img: "",
      	  	size: 1,
      	  	outputType: "png",
      	  	fixedBox: true,
      	  	canMoveBox: false,
      	  	autoCrop: true,
			original: true,
			info: false,
			autoCropWidth: propData.autoCropWidth,
			autoCropHeight: propData.autoCropHeight,
      	  	max: 99999,
      	},
	})
	const uploadInfo = reactive({
		uploadData:'',
		uploadUrl:'',
	});
	const fileUpload = ref();
	
	//上传图片前 调起裁剪框
	function uploadChange(file, type) {
		if (propData.isCropper) {
			if (file.status == 'ready') { //上传前
				const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
  				const whiteList = ["jpg", "png", "bmp"];
  				if (whiteList.indexOf(fileSuffix) === -1) {
  				  ElMessage.warning("上传文件只能是 jpg,png,bmp格式");
				  fileUpload.value.clearFiles();
  				  return false;
  				}
				const isLt1M = file.size / 1024 / 1024 < 2
  				if (!isLt1M) {
  				   	ElMessage.warning("上传图片大小不能超过 2MB!");
					fileUpload.value.clearFiles();
  				  	return false
  				}
				pageData.option.img = URL.createObjectURL(file.raw)
				pageData.dialogVisible = true
			}
		} else {
			if (file.status == 'ready') { //上传前
				commonUpload({
					fileData: file
				}, function(res,ossUrl) {
					uploadInfo.uploadData = res;
					uploadInfo.uploadUrl = ossUrl;
					setTimeout(() => {
						fileUpload.value.submit();
					}, 0)
				})
			} else if (file.status == 'success') { //上传成功后
				var url = file.response.data.wholeUrl;
				emit('change',url);
			}
		}
		
	}
	/**
	 * 自动提交
	 */
	const httpRequest = (requestdata) => {
		let { name, key, policy, OSSAccessKeyId, callback, signature } = uploadInfo.uploadData
        let { action } = requestdata;
        const formData = new FormData();
		formData.append("name", name);
  		formData.append("key", key);
  		formData.append("policy", policy);
  		formData.append("OSSAccessKeyId", OSSAccessKeyId);
  		formData.append("callback", callback);
  		formData.append("signature", signature);
  		formData.append("file", utils.base64ImgtoFile(pageData.url));
        axios.request({
          	url: action,
          	method: 'post',
          	data: formData,
			withCredentials:false,
        }).then(res => {
          //调用上传接口后的处理
		  	var url = res.data.data.wholeUrl
			emit('change', url);
        })
      }
	/**
	 * 取消裁剪
	 */
	function cancel() {
		pageData.dialogVisible = false
	}
	/**
	 * 确定裁剪
	 */
	function addCropper() {
		cropper.value.getCropData((data) => {
			pageData.url = data
			commonUpload({
				fileData: utils.base64ImgtoFile(data)
			},(res,ossUrl) =>{
				uploadInfo.uploadData = res;
				uploadInfo.uploadUrl = ossUrl;
				setTimeout(() => {
					fileUpload.value.submit();
					fileUpload.value.clearFiles();
					pageData.dialogVisible = false
				}, 0)
			})
        })
	}
	
</script>

<style scoped>
	p {
		line-height: 22px;
	}
	.vueCropper {
		margin: 0 auto;
	}
</style>
