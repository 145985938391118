<template>
	<workArea :barlist="barlist">
		<div id="agentOrder" class="agentOrder pl16 pt16 pr16 pb16 hh100" v-loading="loading">
			<el-tabs v-model="groupData.activeId" @tab-click="initTimeData">
				<el-tab-pane v-for="item in groupData.list" :key="item.id" :label="item.groupName" :name="item.id"></el-tab-pane>
			</el-tabs>
			<div class="rx-bc pt30 pb20">
				<div class="flex1 rx-sc">
					<b class="color-333 ft18">预订日程</b>
					<span class="mr15 ml15 color-B3B3B3">|</span>
					<div class="rx-cc mr35 c_p posi-r">
						<b class="color-325EFE ft18 mr20">{{weekData.list[weekData.active].MEn+'，'+weekData.list[weekData.active].Y}}</b>
						<img src="@/assets/calendar.png" class="w20" alt="">
						<div class="datePicker">
							<el-date-picker
								  v-model="dataTime"
								  type="date"
								  @change="changeDataTime"
								  placeholder="选择日期">
								</el-date-picker>
						</div>
					</div>
					<el-button size="small" style="margin: 0 20px 0 0;" @click="checkAllWeek(-7)">上一周</el-button>
					<el-button size="small" style="margin: 0 35px 0 0;" @click="checkAllWeek(7)">下一周</el-button>
				</div>
				<div class="btns" id="btns">
					<el-button size="small" :disabled="timeItemData.type !== 0 && timeItemData.type != 3" class="toolBtn" @click="()=>{bookEvent()}">预订</el-button>
					<el-button size="small" :disabled="!(timeItemData.type == 2 || timeItemData.type == 6) " class="toolBtn" @click="()=>{paymentClick()}">付款</el-button>
					<el-button size="small" :disabled="!(timeItemData.type == 1 || timeItemData.type == 2 || timeItemData.type == 5 || timeItemData.type == 6)" class="toolBtn" @click="cancelClick">取消</el-button>
					<el-button size="small" :disabled="!(timeItemData.type == 1 || timeItemData.type == 2 || timeItemData.type == 5 || timeItemData.type == 6)" class="toolBtn" @click="changeEvent">换场</el-button>
					<el-button size="small" :disabled="!( timeItemData.type == 2 ||  timeItemData.type == 6)" class="toolBtn" @click="updateInfoEvent">修改</el-button>
					<el-button size="small" :disabled="!(timeItemData.type == 0 || timeItemData.type == 3)" class="toolBtn" @click="lockEvent">锁定</el-button>
					<el-button size="small" :disabled="timeItemData.type != 7" class="toolBtn" @click="unlockEvent">解锁</el-button>
					<el-button size="small" :disabled="!( timeItemData.type == 2 ||  timeItemData.type == 6)" class="toolBtn" @click="appendEvent">加订</el-button>
					<el-button size="small" :disabled="!(!timeItemData.consumeId && timeItemData.consumeId !== 0 && (timeItemData.type == 1 || timeItemData.type == 2 || timeItemData.type == 5 || timeItemData.type == 6))" @click="enterEvent(1)" class="toolBtn">入场</el-button>
					<el-button size="small" type="primary" :disabled="!timeItemData.consumeId" class="toolBtn" @click="enterEvent(2)">离场</el-button>
				</div>

			</div>
			<div class="box mb20" :style="{'height':boxHeight+'px'}">
				<div class="leftBox hh100">
					<div class="rx-cc mb20 weekBox" style="background-color: #EFFBFF;">
						<template v-for="(item,index) in weekData.list" :key="item.id">
							<div class="h65 rx-cc flex1 c_p" :class="{weekActive:index == weekData.active}" @click="changeWeek(index)">
								<div>
									<p class="mb5 color-666" :class="{'color-fff':index == weekData.active}">{{item.M+'-'+item.D}}</p>
									<p class="fwb color-666" :class="{'color-fff':index == weekData.active}">{{'周'+item.week}}</p>
								</div>
							</div>
						</template>
					</div>
					<div class="timeBox hh100" v-if="roomData.list && roomData.list.length > 0">
						<div class="timeBoxHead" :style="{width:roomData.list[0].timesList.length*100+280+'px'}">
							<div class="rx-sc">
								<div class="w140 h70 rx-cc timeBoxHeadName">
									<div class="color-666 fwb">场地名称</div>
								</div>
								<template v-for="(child,num) in roomData.list[0].timesList" :key="num">
									<div class="flex1 h70 timeItem rx-bc bg-F4F7FB">
										<div>{{child.stime}}</div>
										<div v-if="roomData.list[0].timesList.length-1 == num">{{child.etime}}</div>
									</div>
								</template>
								<div class="w140 h70 rx-cc timeBoxHeadName">
									<div class="color-666 fwb">场地名称</div>
								</div>
							</div>
						</div>
						<div class="timeBoxBody" :style="{width:roomData.list[0].timesList.length*100+280+'px'}">
							<div class="rx-sc" v-for="(item,index) in roomData.list" :key="index">
								<div class="w140 h70 rx-cc timeBoxHeadName">
									<img class="w15 mr10 c_p" @click="batchLock(item)" v-if="!item.lock" src="@/assets/lock1.png" alt="">
									<img class="w15 mr10 c_p" @click="batchUnLock(item)" v-else src="@/assets/lock2.png" alt="">
									<div class="color-333 fwb w80 maxLength" :title="item.roomName">{{item.roomName}}</div>
								</div>
								<template v-for="(child,num) in item.timesList" :key="num">
									<div class="flex1 w95 h70 timeItem" :class="{'border-dashed':timeItemData.check.indexOf(child.roomId+'#'+child.orderTimeInterval) > -1}" @click="(e)=>{clickTimeItem(child,index,e)}">
										<!-- 可预订 -->
										<div class="color-2EB99A hh100 rx-cc" v-if="child.orderState == 0">{{child.costType == 1 ? ('￥'+child.orderPrice) : '预订'}}</div>
										<!-- 过期时段 -->
										<div class="bg-FAFAFC hh100" v-if="child.orderState == 3"></div>
										<!-- 线上预约/线下预约  consumeId -->
										<div class="hh100 rx-cc onLineBox" :class="{'bg-FCC9C8':child.orderState == 5 ||child.orderState == 6 || child.createBy > 0}" v-if="child.orderState == 1 || child.orderState == 2 || child.orderState == 5 || child.orderState == 6 || child.orderState == 9" @mouseenter="timeItemOver(child)" @mouseleave="lookOrderDetail.batchId = ''">
											
											<div class="popup pt20 pb20 pl20 pr20" :class="{popupBottom:index > 0 && index >= roomData.list.length - 2}" v-show="lookOrderDetail.batchId == child.encryptionRoomOrderId">
											  	<div class="rx-sc hh100 mb10">
											  		<img class="w40 h40 bdr100 mr5" :src="child.headImgUrl" alt="">
											  		<div class="flex1">
											  			<p class="fwb color-333 ft12 mb10">{{child.realName+'（'+(child.trialFlag == 2 ? '潜' : 'vip')+'）'}}</p>
											  			<p class="color-666 ft12">{{child.telephoneNum}}</p>
											  		</div>
											  	</div>
											  	<div class="color-666 ft12 mb10">支付状态：{{child.orderState == 2 ||child.orderState == 6 ? '未支付' : '已支付'}}</div>
											  	<div class="color-666 ft12 mb10" v-if="child.orderState == 1 ||child.orderState == 5">支付方式：{{ getPayMethod(child.payMethodId)}}</div>
											  	<div class="">
											  		<el-button size="mini" type="primary" @click="getOrderDetail(child)">查看订单详情</el-button>
											  	</div>
											</div>
											<div class="ww100 pl10 pr10">
											  	<p class="color-333 ft12 mb5 maxLength">{{'【'+(child.trialFlag == 2 ? '潜' : 'vip')+'】'+child.realName}}</p>
											  	<p class="color-666 ft12 pl5">{{child.telephoneNum}}</p>
											</div>
											<span class="color-fff ft12 payState" v-if="child.orderState == 2 ||child.orderState == 6">未付</span>
											<span class="color-fff ft12 enterState" v-if="child.consumeId || child.consumeId === 0">{{child.consumeId > 0 ? '在场' : '离场'}}</span>
										</div>
										<!-- 线上已排课 -->
										<div class="bg-FFEAAB hh100 rx-cc" v-if="child.orderState == 4">
											<span class="color-666 maxLength">{{child.courseName}}</span>
										</div>
										<!-- 锁定 -->
										<div class="timeItemLock rx-cc" v-if="child.orderState == 7 || child.orderState == 8">
											<img class="w15" src="@/assets/lock2.png" alt="">
											<div class="popup pt20 pb20 pl20 pr20 w150" :class="{popupBottom:index > 0 && index >= roomData.list.length - 2}" v-if="child.lockRemark">
												<div class="color-666 ft12" style="min-height: 80px;max-height: 150px;overflow: auto;">
											  		{{child.lockRemark}}
												</div>
											</div>
										</div>
										<!-- 选中图标 -->
										<img class="check_blue w20" v-show="timeItemData.check.indexOf(child.roomId+'#'+child.orderTimeInterval) > -1" src="@/assets/check_green.png" alt="">
									</div>
								</template>
								<div class="w140 h70 rx-cc timeBoxHeadName">
									<img class="w15 mr10 c_p" @click="batchLock(item)" v-if="!item.lock" src="@/assets/lock1.png" alt="">
									<img class="w15 mr10 c_p" @click="batchUnLock(item)" v-else src="@/assets/lock2.png" alt="">
									<div class="color-333 fwb w80 maxLength" :title="item.roomName">{{item.roomName}}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="rx-cc hh100 noOrderData" :class="{'border-E6E6E6':!roomData.list || roomData.list.length == 0}" :style="{'min-height':(boxHeight - 85)+'px'}"  v-else>
						<div class="rx-sc noOrderDataTitle">
							<div class="w140 h70 rx-cc bg-F4F7FB">
								<div class="color-666 fwb">场地名称</div>
							</div>
							<div class="flex1 h70 rx-cc bg-F4F7FB">
								<div class="color-666 fwb">09:00</div>
							</div>
							<div class="flex1 h70 rx-cc bg-F4F7FB">
								<div class="color-666 fwb">10:00</div>
							</div>
							<div class="flex1 h70 rx-cc bg-F4F7FB">
								<div class="color-666 fwb">11:00</div>
							</div>
							<div class="flex1 h70 rx-cc bg-F4F7FB">
								<div class="color-666 fwb">12:00</div>
							</div>
							<div class="flex1 h70 rx-cc bg-F4F7FB">
								<div class="color-666 fwb">13:00</div>
							</div>
							<div class="flex1 h70 rx-cc bg-F4F7FB">
								<div class="color-666 fwb">14:00</div>
							</div>
							<div class="w140 h70 rx-cc bg-F4F7FB">
								<div class="color-666 fwb">场地名称</div>
							</div>
						</div>
						<div>
							<img class="w300" src="@/assets/noData.png" alt="">
							<p class="color-999 ft18 mt10 t_a_c">暂无排期</p>
						</div>
					</div>
				</div>
				<div class="w330 rightBox">
					<div class="rightBoxTop">
						<h3 class="t_a_c pt20 pb20"><b class="ft18 color-333">今日预约</b></h3>
						<div class="mb10 pl15 pr15">
							<CommonTip :text="'今日预约共'+toDayOrderData.form.allPeople +'人，未到场'+toDayOrderData.form.unreachPeople +'人，即将迟到'+toDayOrderData.form.latePeople +'人'"></CommonTip>
						</div>
						<div class="rx-bc pl15 pr15">
							<el-radio-group v-model="toDayOrderData.type" @change="getTodayData">
								<el-radio label="">全部</el-radio>
								<el-radio :label="0">未到场</el-radio>
								<el-radio :label="1">已到场</el-radio>
								<el-radio :label="2">已离场</el-radio>
							</el-radio-group>
						</div>
					</div>
					<div class="rightBoxBody" v-if="toDayOrderData.list.length > 0">
						<template v-for="item in toDayOrderData.list" :key="item.id">
							<div class="rx-bs mb30 pl15 pr15 ">
								<div class="rx-sc">
									<img :src="item.headImgUrl" class="w50 h50 mr15 bdr100" alt="">
									<div class="">
										<div class="rx-sc mb5">
											<div class="color-333 maxLength mr5" :style="{'max-width':item.time ? '65px' : '135px'}" :title="item.realName">{{item.realName}}</div>
											<div class="ft12 bdr100 pl5 pr5" style="color:#FB0008;background-color:rgba(251, 41, 47, .2)" v-if="item.time && item.enterState !=1">{{item.time == -2 ? '即将迟到' : item.time == -1 ? '即将超时' : '超时'+item.time}}</div>
										</div>
										<div class="color-999">{{item.telephoneNum}}</div>
									</div>
								</div>
								<div class="">
									<div class="color-666 mt5" v-for="child in item.timeList" :key="child.orderBatchId">{{child}}</div>
								</div>
							</div>
						</template>
					</div>
					<div class="rx-cc hh100" v-if="toDayOrderData.list.length == 0">
						<div>
							<el-empty description="暂无数据" />
						</div>
					</div>
				</div>
			</div>
			<div class="rx-sc timeTips">
				<i class=" mr10"></i>
				<span class="ft12 color-666 mr25">可预订</span>
				<i class="bg-FAFAFC mr10"></i>
				<span class="ft12 color-666 mr25">过期时段</span>
				<i class="bg-FCC9C8 mr10"></i>
				<span class="ft12 color-666 mr25">线下预订</span>
				<i class="bg-CDE9FF mr10"></i>
				<span class="ft12 color-666 mr25">线上预订</span>
				<i class="bg-FFEAAB mr10"></i>
				<span class="ft12 color-666 mr25">线上已排课</span>
				<i class="bg-E0E0E0 mr10"></i>
				<span class="ft12 color-666 mr25">锁场</span>
			</div>
		</div>
	</workArea>
	<!-- 查看订单详情 -->
	<el-dialog
	    v-model="lookOrderDetail.show"
	    title="订单详情"
		width="750px"
	    :before-close="()=>{lookOrderDetail.show = false}"
	    custom-class="popwechat"
	  >
	    <div class="pt20 pl70 pr70 pb50">
			<div class="rx-sc bg-F4F7FB pl25 mb20 pt30 pb30">
				<div class="posi-r mr15">
					<img class="w60 h60 bdr100" :src="lookOrderDetail.form.headImgUrl" alt="">
				</div>
				<div class="flex1">
					<div class="rx-sc mb15">
						<b class="ft16 mr5" style="color: #47545C;">{{lookOrderDetail.form.realName}}</b>
						<span class="bd-main bdr2 color-main pl5 pr5">{{lookOrderDetail.form.trialFlag == 2 ? '潜在' : '正式'}}会员</span>
					</div>
					<div class="rx-sc">
						<img class="w20 mr10" src="@/assets/try_icon6.png" alt="">
						<span class=" color-333">{{lookOrderDetail.form.telephoneNum}}</span>
					</div>
				</div>
			</div>
			<div class="mb30">
				<el-table ref="satffTable" border :data="lookOrderDetail.list" stripe style="width: 100%">
					<el-table-column prop="roomName" label="场地名称"  min-width="120" show-overflow-tooltip/>
					<el-table-column prop="time" label="预订时段"  min-width="180" show-overflow-tooltip/>
					<el-table-column prop="ifFullTime" label="支付状态" >
						<template #default="scope">
							<span :class="scope.row.refundState == 1? 'color-red' :scope.row.state == 6? 'color-E19C0E' : 'color-018000'">{{scope.row.refundState == 1 ? '已退款' : scope.row.state == 1 ? '已支付':scope.row.state == 6 ? '未支付':'已释放'}}</span>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="rx-sc mb25">
				<div class="flex1 color-333">订单编号：{{lookOrderDetail.form.batchCode}}</div>
				<div class="flex1 color-333">订单总时长：{{lookOrderDetail.form.timeNum}}小时</div>
			</div>
			<div class="rx-sc mb25">
				<div class="flex1 color-333">订单来源：{{lookOrderDetail.orderState == 5 || lookOrderDetail.orderState == 6 ? '后台创建' : '自主预约'}}</div>
				<div class="flex1 color-333">支付状态：<span :class="lookOrderDetail.form.payMethodId == 0 ? 'color-E19C0E' : lookOrderDetail.form.partRefund == 1 ? 'color-red' : 'color-018000'">{{lookOrderDetail.form.payMethodId == 0 ? '未支付' : lookOrderDetail.form.partRefund == 1 ? '部分退款' : '已支付'}}</span></div>
			</div>
			<div class="rx-sc mb25" v-if="lookOrderDetail.form.costType == 2">
				<div class="flex1 color-333">计费方式：按人数计费，{{lookOrderDetail.form.peopleMoney || 0}}元/人</div>
				<div class="flex1 color-333">预定人数：{{lookOrderDetail.form.peopleNum}}</div>
			</div>
			<div class="rx-sc mb25" v-if="lookOrderDetail.orderState == 1 || lookOrderDetail.orderState == 5">
				<div class="flex1 color-333">支付金额：{{lookOrderDetail.form.actualMoney}}</div>
				<div class="flex1 color-333">支付方式：{{lookOrderDetail.form.payMethodName}}</div>
			</div>
			<div class="rx-sc mb25">
				<div class="flex1 color-333">预订时间：{{lookOrderDetail.form.createTime}}</div>
				<div class="flex1 color-333">订单备注：{{lookOrderDetail.form.remark}}</div>
			</div>
	    </div>
		<template #footer>
		  	<span class="dialog-footer">
				<el-button type="primary" @click="lookOrderDetail.show = false">关闭</el-button>
			</span>
		</template>
	</el-dialog>
	<!-- 预订场次 -->
	<el-dialog
	    v-model="bookData.show"
	    :title="bookData.form.encryptionBatchId ? '修改信息':'预订场次'"
		width="700px"
	    :before-close="()=>{bookData.show = false}"
	    custom-class="popwechat"
	  >
	    <div class="pt20 pl70 pr70 pb50">
			<div class="rx-ss bdr2 pl15 pr15 pt10 pb10 mb30" style="border: 1px solid #92D5FF;background-color: #E6F7FF;">
				 <b class="color-333">预订时段：</b>
				 <span class="color-333 flex1">{{bookData.appointmentStr}}</span>
			</div>
			<div class="mb25 rx-ss">
				<div class="w80 t_a_r"><span class="color-red">*</span>预订信息：</div>
				<div class="flex1">
					<div class="mb20 rx-sc">
						<div class="flex1 mr15">
							<div class="inputBox">
								<el-input
								  type="text"
								  placeholder="预定人姓名(必填)"
								  v-model="bookData.form.realName"
								  show-word-limit
								  @focus="inputFocusEvent(1)"
								  @blur="inputData.type = ''"
								  @input="inputChangeEvent"
								/>
								<ul v-show="inputData.type == 1 && bookData.form.realName">
									<template v-for="item in inputData.list" :key="item.id">
										<li class="maxLength" :title="item.realName" @mousedown="inputItemClick(item)" :class="{active:item.cardMyId == bookData.form.cardMyId}">{{item.realName}}</li>
									</template>
									<p class="rx-cc h30" v-show="inputData.list.length == 0">无数据</p>
								</ul>
							</div>
						</div>
						<div class="flex1">
							<div class="inputBox">
								<el-input
								  type="number"
									min="0"
								  placeholder="预定人手机号(必填)"
								  v-model="bookData.form.telephoneNum"
								  maxlength="11"
								  show-word-limit
								  @focus="inputFocusEvent(2)"
								  @blur="inputData.type = ''"
								  @input="inputChangeEvent"
								/>
								<ul v-show="inputData.type == 2 && bookData.form.telephoneNum">
									<template v-for="item in inputData.list" :key="item.id">
										<li class="maxLength" :title="item.realName" @mousedown="inputItemClick(item)" :class="{active:item.cardMyId == bookData.form.cardMyId}">{{item.realName}}</li>
									</template>
									<p class="rx-cc h30" v-show="inputData.list.length == 0">无数据</p>
								</ul>
							</div>
						</div>
					</div>
					<div class="rx-sc">
						<div class="flex1 mr15">
							<el-input type="number" min="1" maxlength="4" @input="inputVerification(bookData.form,'peopleNum')" :placeholder="'预计到店人数('+(timeItemData.costType == 1 ? '选填':'必填')+')'" v-model="bookData.form.peopleNum">
							    <template #append>人</template>
							</el-input>
						</div>
						<div class="flex1">
						</div>
					</div>
				</div>
			</div>
			<div class="mb25 rx-ss">
				<div class="w80 t_a_r">备注：</div>
				<div class="flex1">
					<el-input
					  type="textarea"
					  placeholder="请输入内容"
					  v-model="bookData.form.remark"
					  maxlength="50"
					  show-word-limit
					/>
				</div>
			</div>
	    </div>
		<template #footer>
		  	<div class="dialog-footer">
				<el-button @click="bookData.show = false">取消</el-button>
				<el-button type="primary" @click="bookOnlyOrder(2)" v-if="!bookData.form.encryptionBatchId">直接付款</el-button>
				<el-button type="primary" @click="()=>{bookOnlyOrder()}">{{bookData.form.encryptionBatchId ? '确定' : '预订场地'}}</el-button>
			</div>
		</template>
	</el-dialog>
	<!-- 付款 -->
	<el-dialog
	    v-model="paymentData.show"
	    title="收银台"
		:close-on-click-modal="false"
		width="700px"
	    :before-close="paymentClose"
	    custom-class="popwechat"
	  >
	    <div class="pt20 pl70 pr70 pb50">
			<div class="rx-sc pl25 mb20">
				<div class="posi-r mr15">
					<img class="w60 h60 bdr100" :src="lookOrderDetail.form.headImgUrl" alt="">
				</div>
				<div class="flex1">
					<div class="rx-sc mb15">
						<b class="ft16 mr5" style="color: #47545C;">{{lookOrderDetail.form.realName}}</b>
						<span class="bd-main bdr2 color-main pl5 pr5">{{lookOrderDetail.form.trialFlag == 2 ? '潜在' : '正式'}}会员</span>
					</div>
					<div class="rx-sc">
						<img class="w20 mr10" src="@/assets/try_icon6.png" alt="">
						<span class=" color-333">{{lookOrderDetail.form.telephoneNum}}</span>
					</div>
				</div>
			</div>
			<div class="mb20 rx-sc">
				<div class="w80 color-333 t_a_r">计费方式：</div>
				<span class="color-333">{{lookOrderDetail.form.costType == 1 ? '按时间计费' : '按人数计费'}}</span>
			</div>
			<div class="mb20 rx-sc" v-if="lookOrderDetail.form.costType == 2">
				<div class="w80 color-333 t_a_r">预订人数：</div>
				<div>
					<el-input placeholder="" type="number" min="1" @input="getActualMoneyByPeople" v-model="lookOrderDetail.form.peopleNum">
					    <template #append>人</template>
					</el-input>
				</div>
				<div class="color-666 ml20">单价：{{lookOrderDetail.form.peopleMoney || 0}}元/人</div>
			</div>
			<div class="mb20 rx-sc">
				<div class="w80 color-333 t_a_r">订单应收：</div>
				<span class="color-red">￥{{lookOrderDetail.form.shouldMoney}}元</span>
			</div>
			<div class="mb20 rx-sc">
				<div class="w80 color-333 t_a_r">场地实收：</div>
				<div>
					<el-input placeholder="" type="number" min="0" @input="getMoneyByActualMoney" v-model="lookOrderDetail.form.actualMoney">
					    <template #append>元</template>
					</el-input>
				</div>
			</div>
			
			<div class="mb20">
				<el-table ref="satffTable" border :data="lookOrderDetail.list" stripe style="width: 100%">
					<el-table-column prop="roomName" label="场地名称" min-width="120" show-overflow-tooltip />
					<el-table-column prop="time" label="预订时段" min-width="180"/>
					<el-table-column prop="actualMoney" label="会员单价" v-if="lookOrderDetail.form.costType == 1" />
					<el-table-column label="实收金额" v-if="lookOrderDetail.form.costType == 1" >
						<template #default="scope">
							<el-input type="number" min="0" @input="getActualMoneyByMoney" placeholder="" v-model="scope.row.money" />
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="mb20 rx-sc">
				<div class="w80 color-333 t_a_r"><span class="color-red">*</span>收款方式：</div>
				<div>
					<el-select v-model="lookOrderDetail.form.payMethodId" placeholder="请选择">
					    <el-option
					      v-for="item in payTypeData.payList"
					      :key="item.payId"
					      :label="item.payMethodName"
					      :value="item.payId">
					    </el-option>
					  </el-select>
				</div>
			</div>
			<div class="mb20 rx-sc" v-if="lookOrderDetail.form.payMethodId == 101">
				<div class="w80 color-333 t_a_r"><span class="color-red">*</span>储值卡：</div>
				<div>
					<el-select v-model="lookOrderDetail.form.orderId" placeholder="请选择">
					    <el-option
					      v-for="item in payTypeData.cardList"
					      :key="item.id"
					      :label="item.productName"
					      :value="item.id">
					    </el-option>
					  </el-select>
				</div>
			</div>
			<div class="rx-ss">
				<div class="w80 color-333 t_a_r">备注：</div>
				<div class="w215">
					<el-input
					  type="textarea"
					  placeholder="请输入内容"
					  v-model="lookOrderDetail.form.remark"
					  maxlength="100"
					  show-word-limit
					/>
				</div>
			</div>
	    </div>
		<template #footer>
		  	<div class="dialog-footer rx-bc">
				<div class="rx-sc pl70">
					<b class="w80 t_a_r">实收：</b>
					<b class="color-red">￥{{lookOrderDetail.form.actualMoney}}</b>
				</div>
				<div>
					<el-button @click="paymentClose">取消</el-button>
					<el-button type="primary" @click="paymentSave">确定</el-button>
				</div>
			</div>
		</template>
	</el-dialog>
	<!-- 退款 -->
	<el-dialog
	    v-model="refundData.show"
	    title="退款"
		width="880px"
	    :before-close="()=>{refundData.show = false}"
	    custom-class="popwechat"
	  >
	    <div class="pt30 pl30 pr30 pb30">
			<CommonTitle text="订单详情"></CommonTitle>
			<div class="rx-bc mb40">
				<div class="ww30">
					<div class="color-333 mb30">订单编号：{{lookOrderDetail.form.batchCode}}</div>
					<div class="color-333">应收总金额：{{lookOrderDetail.form.costType == 1 ? lookOrderDetail.form.shouldMoney : (lookOrderDetail.form.peopleNum+'人*'+(lookOrderDetail.form.peopleMoney || 0)+'元/人')}}</div>
				</div>
				<div class="ww30">
					<div class="color-333 mb30">订单来源：{{timeItemData.type == 5 ||timeItemData.type == 6 ? '后台创建' : '自主预约'}}</div>
					<div class="color-333">实收总金额：{{lookOrderDetail.form.actualMoneyOld}}</div>
				</div>
				<div class="ww30">
					<div class="color-333 mb30">订单计费方式：{{lookOrderDetail.form.costType == 1 ? '按时间计费' : '按人数计费'}}</div>
					<div class="rx-sc"><span class="color-333">订单状态：</span><span :class="lookOrderDetail.form.partRefund == 1? 'color-red' : 'color-018000'">{{lookOrderDetail.form.partRefund == 1 ? '部分退款' : '已支付'}}</span></div>
				</div>
			</div>
			<CommonTitle text="订场明细"></CommonTitle>
			<div class="mb30">
				<el-table ref="satffTable" border :data="lookOrderDetail.list" stripe style="width: 100%">
					<el-table-column prop="roomName" label="场地名称" min-width="120" show-overflow-tooltip />
					<el-table-column prop="time" label="预订时段" min-width="180"/>
					<el-table-column prop="actualMoney" label="单价" v-if="lookOrderDetail.form.costType == 1" />
 					<el-table-column prop="actualMoney" label="实收" v-if="lookOrderDetail.form.costType == 1" />
					<el-table-column label="支付状态" >
						<template #default="scope">
							<span :class="scope.row.refundState == 1? 'color-red' : 'color-018000'">{{scope.row.refundState == 1 ? '已退款' : scope.row.state == 1 ? '已支付':scope.row.state == 6 ? '未支付':'已释放'}}</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" >
						<template #default="scope">
							<el-checkbox v-if="scope.row.refundState != 1" @change="refundCheckChange(scope.row)" v-model="scope.row.check" label="" size="large" />
						</template>
					</el-table-column>
				</el-table>
			</div>
			<CommonTitle text="操作退款"></CommonTitle>
			<div class="o_h">
				<div class="ww50 rx-sc f_l mb30" v-if="lookOrderDetail.form.costType == 2">
					<div class="w80 color-333 t_a_r">退款人数：</div>
					<div class="w220">
						<el-input placeholder="" min="0" type="number" @input="refundPeopleNumInput" v-model="lookOrderDetail.form.peopleNumNew">
						    <template #append>人</template>
						</el-input>
					</div>
				</div>
				<div class="ww50 rx-sc f_l mb30">
					<div class="w80 color-333 t_a_r">退款金额：</div>
					<div class="w220">
						<el-input placeholder="" min="0" type="number" @input="refundActualMoneyNewInput" v-model="lookOrderDetail.form.actualMoneyNew">
						    <template #append>元</template>
						</el-input>
					</div>
				</div>
				<div class="ww50 rx-sc f_l mb30">
					<div class="w80 color-333 t_a_r"><span class="color-red">*</span>退款方式：</div>
					<div class="w220">
						<el-select v-model="lookOrderDetail.form.refundType" placeholder="请选择">
							<el-option label="退现金" value="1" v-if="lookOrderDetail.form.payMethodId != 101" />
							<el-option label="退款至原支付账户" value="2" v-if="lookOrderDetail.form.payMethodId == 100 || (lookOrderDetail.form.payMethodId == 3 && lookOrderDetail.form.createBy == 0)"/>
							<el-option label="退至储值卡" value="3" v-if="lookOrderDetail.form.payMethodId == 101"/>
						</el-select>
					</div>
				</div>
				<div class="ww50 rx-ss f_l mb30">
					<div class="w80 color-333 t_a_r">备注：</div>
					<div class="w220">
						<el-input
						  type="textarea"
						  placeholder="请输入内容"
						  v-model="lookOrderDetail.form.remark"
						  maxlength="100"
						  show-word-limit
						/>
					</div>
				</div>
			</div>
	    </div>
		<template #footer>
		  	<div class="dialog-footer rx-bc">
				<div class="rx-sc pl30">
					<b class="w80 t_a_r">退款：</b>
					<b class="color-red">￥{{lookOrderDetail.form.actualMoneyNew}}</b>
				</div>
				<div>
					<el-button @click="refundData.show = false">取消</el-button>
					<el-button type="primary" @click="refundSave">确定</el-button>
				</div>
			</div>
		</template>
	</el-dialog>
	<!-- 换场  -->
	<el-dialog
	    v-model="changeData.show"
	    title="换场"
		width="460px"
	    :before-close="()=>{changeData.show = false}"
	    custom-class="popwechat"
	  >
	    <div class="pt20 pl70 pr70 pb50">
			<div class="rx-sc pl25 mb20">
				<div class="posi-r mr15">
					<img class="w60 h60 bdr100" :src="changeData.form.headImgUrl" alt="">
				</div>
				<div class="flex1">
					<div class="rx-sc mb15">
						<b class="ft16 mr5" style="color: #47545C;">{{changeData.form.realName}}</b>
						<span class="bd-main bdr2 color-main pl5 pr5">{{changeData.form.trialFlag == 2 ? '潜在' : '正式'}}会员</span>
					</div>
					<div class="rx-sc">
						<img class="w20 mr10" src="@/assets/try_icon6.png" alt="">
						<span class=" color-333">{{changeData.form.telephoneNum}}</span>
					</div>
				</div>
			</div>
			<div class="mb20 rx-sc">
				<div class="w80 color-333 t_a_r"><span class="color-red">*</span>修改日期：</div>
				<div>
					<span v-if="timeItemData.consumeId">{{changeData.orderDate}}</span>
					<el-date-picker
							v-else
						  v-model="changeData.form.orderDate"
						  type="date"
						  @change="changeDate"
						  placeholder="请选择">
						</el-date-picker>
				</div>
			</div>
			<div class="mb20 rx-sc">
				<div class="w85 color-333 t_a_r">预订时间段：</div>
				<span class="color-333">{{changeData.str}}</span>
			</div>
			<div class="mb20 rx-sc">
				<div class="w85 color-333 t_a_r"><span class="color-red">*</span>修改场地：</div>
				<div>
					<el-select v-model="changeData.form.encryptionRoomId" placeholder="请选择">
						<template v-for="item in changeData.list" :key="item.roomId">
							<el-option
								v-if="changeData.roomId != item.roomId && changeData.costType == item.costType"
							  :label="item.roomName"
							  :value="item.encryptionRoomId">
							</el-option>
						</template>
					</el-select>
				</div>
			</div>
	    </div>
		<template #footer>
		  	<span class="dialog-footer">
				<el-button @click="changeData.show = false">取消</el-button>
				<el-button type="primary" @click="changeSave">确定</el-button>
			</span>
		</template>
	</el-dialog>
	<!-- 加订  -->
	<el-dialog
	    v-model="appendData.show"
	    title="加订"
		width="580px"
	    :before-close="()=>{appendData.show = false}"
	    custom-class="popwechat"
	  >
	    <div class="pt20 pl70 pr70 pb50">
			<div class="rx-sc pl25 mb20">
				<div class="posi-r mr15">
					<img class="w60 h60 bdr100" :src="lookOrderDetail.form.headImgUrl" alt="">
				</div>
				<div class="flex1">
					<div class="rx-sc mb15">
						<b class="ft16 mr5" style="color: #47545C;">{{lookOrderDetail.form.realName}}</b>
						<span class="bd-main bdr2 color-main pl5 pr5">{{lookOrderDetail.form.trialFlag == 2 ? '潜在' : '正式'}}会员</span>
					</div>
					<div class="rx-sc">
						<img class="w20 mr10" src="@/assets/try_icon6.png" alt="">
						<span class=" color-333">{{lookOrderDetail.form.telephoneNum}}</span>
					</div>
				</div>
			</div>
			<div class="mb20 rx-ss">
				<div class="w85 color-333 t_a_r">已订时间段：</div>
				<span class="color-333 flex1">{{appendData.str}}</span>
			</div>
			<div class="mb20 rx-sc">
				<div class="w85 color-333 t_a_r"><span class="color-red">*</span>加订场地：</div>
				<div>
					<el-select v-model="appendData.roomId" placeholder="请选择" @change="changeRoomEvent">
						<template v-for="item in roomData.list" :key="item.roomId">
							<el-option
								v-if="item.costType == timeItemData.costType"
							  :label="item.roomName"
							  :value="item.roomId">
							</el-option>
						</template>
					</el-select>
				</div>
			</div>
			<div class="mb20 rx-sc">
				<div class="w85 color-333 t_a_r"><span class="color-red">*</span>加订时段：</div>
				<div>
					<el-date-picker
						@change="changeRoomEvent"
						  v-model="appendData.orderDate"
						  type="date"
						  placeholder="请选择">
						</el-date-picker>
				</div>
			</div>
			<div class="pt40">
				<div class="pl85" v-if="!appendData.list || appendData.list.length == 0">暂无可加订的时段</div>
				<div class="f_l w70 h45 rx-cc posi-r appendTime" v-for="item in appendData.list" :key="item.roomId">
					<span class="startTime">{{item.stime}}</span>
					<span class="endTime">{{item.etime}}</span>
					<!-- 可预订 -->
					<div class="color-2EB99A ww100 hh100 rx-cc" @click="appendSelect(item)" v-if="item.orderState == 0">{{item.costType == 1 ? ('￥'+item.orderPrice) : '预订'}}</div>
					<!-- 过期时段 -->
					<div class="bg-FAFAFC ww100 hh100" v-if="item.orderState != 0"></div>
					<img class="check_blue" v-show="appendData.check.indexOf(item.orderTimeInterval) > -1" src="@/assets/check_blue.png" alt="">
				</div>
			</div>
	    </div>
		<template #footer>
		  	<span class="dialog-footer">
				<el-button @click="appendData.show = false">取消</el-button>
				<el-button type="primary" @click="appendSave">确定</el-button>
			</span>
		</template>
	</el-dialog>
	<!-- 入场 -->
	<el-dialog
	    v-model="enterRoom.show"
	    :title="enterRoom.title"
		width="700px"
	    :before-close="()=>{enterRoom.show = false}"
	    custom-class="popwechat"
	  >
	    <div class="pt20 pl20 pr20 pb50">
			<CommonTitle text="会员信息"></CommonTitle>
			<div class="rx-bs mb20">
				<div class="rx-ss pl20">
					<div class="posi-r mr15">
						<img class="w60 h60 bdr100" :src="enterRoom.form.headImgUrl" alt="">
					</div>
					<div class="flex1">
						<div class="rx-sc mb15">
							<b class="ft16 mr5" style="color: #47545C;">{{enterRoom.form.realName}}</b>
							<span class="bd-main bdr2 color-main pl5 pr5">{{enterRoom.form.trialFlag == 2 ? '潜在' : '正式'}}会员</span>
						</div>
						<div class="rx-sc mb15">
							<img class="w20 mr10" src="@/assets/try_icon6.png" alt=""/>
							<span class=" color-333">{{enterRoom.form.telephoneNum}}</span>
						</div>
						<div class="mb15" v-if="enterRoom.form.userTag && enterRoom.form.userTag.length > 0">
							<span class="d_i_b v_a_m mr15 ft12 pl10 pr10 bdr2" :style="{color:'#'+item.fontColor,backgroundColor:'#'+item.backColor}" v-for="item in enterRoom.form.userTag" :key="item.id">{{item.tagName}}</span>
						</div>
						<div class="rx-sc">
							<span class="mr50">跟进员工：{{enterRoom.form.staff}}</span>
							<span>备注：{{enterRoom.form.remark}}</span>
						</div>
					</div>
				</div>
				<el-button type="primary" @click="lookMemberDetail">查看详情</el-button>
			</div>
			<CommonTitle text="入场凭证"></CommonTitle>
			<div class="mb30">
				<div class="pl20 pr20 pb20 pt20 bdr2" style="border:1px solid #D6D6D6;">
					<div class="fwb ft16 mb20" style="color:#47545C;">场地预约</div>
					<div class="rx-sc mb15" v-for="item in enterRoom.orderInfo.roomStrList" :key="item.roomName">
						<span class="mr50">场地名称：{{item.roomName}}</span>
						<span class="mr50">预约场次：{{item.list}}</span>
					</div>
					<div class="rx-sc">
						<span class="mr50">预订人数：{{enterRoom.title == '入场' ? enterRoom.orderInfo.peopleNum : enterRoom.orderInfo.peopleNumOrg}}</span>
						<div class="rx-sc" v-if="enterRoom.orderInfo.costType == 2">
							<span>实际入场人数：</span>
							<div class="w100" v-if="enterRoom.title == '入场'">
								<el-input placeholder="" min="1" type="number" v-model="enterRoom.orderInfo.enterCount">
									<template #append>人</template>
								</el-input>
							</div>
							<span v-else>{{enterRoom.orderInfo.peopleNum}}</span>
						</div>
					</div>
				</div>
			</div>
	    </div>
		<template #footer>
		  	<span class="dialog-footer">
				<el-button @click="enterRoom.show = false">关闭</el-button>
				<el-button type="primary" @click="enterSave">确定</el-button>
			</span>
		</template>
	</el-dialog>
	<!-- 锁场备注 -->
	<el-dialog
	    v-model="lockData.show"
	    title="锁场"
		width="400px"
	    :before-close="()=>{lockData.show = false}"
	    custom-class="popwechat"
	  >
	    <div class="pt20 pl20 pr20 pb50">
			<div class="rx-ss">
				<div class="w80 color-333 t_a_r">备注：</div>
				<div class="w215">
					<el-input
					  type="textarea"
					  placeholder="请输入内容"
					  v-model="lockData.remark"
					  maxlength="200"
					  show-word-limit
					/>
				</div>
			</div>
	    </div>
		<template #footer>
		  	<span class="dialog-footer">
				<el-button @click="lockData.show = false">关闭</el-button>
				<el-button type="primary" @click="lockSave">确定</el-button>
			</span>
		</template>
	</el-dialog>
	<Confirm ref="confirmRef" :title="confirmData.title" :text="confirmData.text" @save="confirmSave">
		<template #btns v-if="timeItemData.type == 1 || timeItemData.type == 5">
			<el-button type="primary" @click="refundEvent">去退款</el-button>
			<el-button type="primary" @click="cancelEvent(2)">仅释放，不退款</el-button>
		</template>
	</Confirm>
	<div class="tipBpx" v-show="timeBox.show == 'open'">
		<div class="timeItemBox" :style="{top:timeBox.top,left:timeBox.left}">
			<img class="ww100 hh100" src="@/assets/demo1.png" alt="">
		</div>
		<div class="posi-a" style="width: 710px;" :style="{top:timeBox.pTop,right:timeBox.pRight}">
			<div class="mb10">
				<img class="ww100 hh100" src="@/assets/demo2.png" alt="">
			</div>
			<div class="t_a_c mb10" >
				<img style="width: 70%;" class=" hh100" src="@/assets/demo3.png" alt="">
			</div>
			<div class="t_a_c" >
				<img style="width: 30%;" @click="closeTips" class=" hh100" src="@/assets/demo4.png" alt="">
			</div>
		</div>
	</div>
</template>

<script setup>
    import { ref, reactive,onMounted,onUnmounted } from 'vue';
    import { useRouter, onBeforeRouteLeave } from 'vue-router';
    import workArea from '@/components/branch-store/workArea.vue';
    import utils from '@/common/utils.js';
    import { 
		sportRoomGroupList,
		getStoreRoomOrderTimeList,
		sportRoomLockSectionInsert,
		appointment,
		getOrderToPay,
		doOrderPay,
		cancelTimesOnly,
		memberOrderRoomManyCancel,
		changeRoom,
		updateAppointmentInfo,
		appendAppointment,
		getToday,
		deleteOrderToPay
		} from '@/api/appstore.js';
	import { payDetailToEdit } from '@/api/storeCenter.js'
	import {
		listStoreCard4Order,
		selectStoreMembersSimple,
		getMemberDetail
	} from '@/api/member.js';
	import { getMemberVoucher,enter,out } from '@/api/reception.js';
	import { getPayCode } from '@/api/pay.js'
	import Confirm from '@/components/Confirm.vue'
	import CommonTip from '@/components/CommonTip.vue'
	import CommonTitle from '@/components/CommonTitle.vue'
	import { ElMessage } from 'element-plus'
    const barlist = reactive([{ name : '返回',type:0 },{ name : '前台' },{ name : '场次预订' }]); // 面包屑
    const router = useRouter();
	const loading = ref(true);
	const confirmRef = ref ()
	const confirmData = reactive({
		title:'',
		text:'',
		type:''
	})
	var boxHeight = ref(0);
	//获取分组
	const groupData = reactive({
		list:[],
		activeId:0
	})
	function getGroupData(orderDateStr){
		sportRoomGroupList({
			orderDateStr
		}).then((res)=> {
		    if (res.code == 0) {
		        groupData.list = res.data;
		        groupData.activeId = res.data[0].id;
				getOrderData(orderDateStr);
				getTodayData();
		    }
		}).catch(() => {
		    loading.value = false;
		})
	}
	//今日预约列
	const toDayOrderData = reactive({
		list:[],
		type:'',
		form:{
			allPeople: 0,
			latePeople: 0,
			unreachPeople: 0
		}
	})
	//获取当前分组今日预约记录
	function getTodayData(){
		getToday({
			type:toDayOrderData.type,
			groupId:groupData.activeId
		}).then((res)=> {
			if (res.code == 0) {
				toDayOrderData.list = res.data.map(n=>{
					var timeArr = [];
					n.orderList = n.orderList.map(z=>{
						z.time = z.orderStartTime.substring(11,16)+'-'+z.orderEndTime.substring(11,16);
						var arr = timeArr.filter(n=>z.time == n.timeStr);
						if(arr.length == 0){
							timeArr.push({
								timeStr:z.time,
								time:utils.getDateObj(z.orderStartTime).time
							});
						}
						return z;
					})
					timeArr.sort((a,b)=>a.time-b.time); //按时间排序
					var timeList = [],str= '',et='';
					timeArr.map((q,w)=>{
						var t = q.timeStr.split('-');
						if(w == 0){
							str = t[0] + '-';
							et = t[1];
						}else{
							var prev = timeArr[w-1].timeStr.split('-');
							if(t[0] == prev[1]){ //后一个开始时间等于前一个结束时间
								et = t[1];
							}else{
								timeList.push(str+et);
								str = t[0]+'-';
								et = t[1];
							}
						}
						if(timeArr.length - 1 == w){ //循环到最后一个元素
							timeList.push(str+et);
						}

					})
					n.timeList = timeList;


					if(n.lastOutSiteTime){
						var time = utils.getDateObj(n.lastOutSiteTime).time - (new Date()).getTime();
						if(time > 0 && time < 10*60*1000){ //即将超时
							n.time = -1;
						}
						if(time < 0){ //已超时
							var d = parseInt(time * -1 /1000/60);
							if(d > 60){
								n.time = parseInt(d/60)+'时'+d%60+'分';
							}else{
								n.time = d+'分';
							}; //转换正数并换算成分钟
						}
					}else{
						var time = utils.getDateObj(n.orderList[0].orderStartTime).time - (new Date()).getTime();
						if(time > 0 && time < 10*60*1000){ //即将迟到
							n.time = -2;
						}
					}
				
					return n;
				});
				toDayOrderData.form = res.results;
			}
		})
	}
	//获取当前组、周的预约时段数据
	const roomData = reactive({
		list:[],
		perSceneTime:''
	})
	function getOrderData(orderDateStr){
		getStoreRoomOrderTimeList({
			groupId:groupData.activeId,
			orderDateStr,
		}).then((res)=> {
			loading.value = false;
		    if (res.code == 0 && res.data && res.data.length > 0) {
				roomData.perSceneTime = res.results.group.perSceneTime;
				roomData.list = res.data.map(n=>{
					n.lock = false;
					var orderNum = 0,lockNum = 0;
					n.timesList = n.timesList.map(z=>{
						var timeArr = z.orderTimeInterval.split('-');
						z.stime = timeArr[0];
						z.etime = timeArr[1];
						z.roomId = n.roomId;
						z.encryptionRoomId = n.encryptionRoomId;
						z.roomName = n.roomName;
						z.orderPrice = res.results.group.perSceneTime == 30 ? (Number(z.orderPrice)/2).toFixed(2) : Number(z.orderPrice).toFixed(2) ;
						z.perSceneTime =  res.results.group.perSceneTime;
						if(z.orderState == 3 || z.orderState === 0){
							orderNum++;
						}
						if(z.orderState == 7 || z.orderState == 8){
							lockNum++;
						}
						return z;
					})
					n.lock = orderNum === 0 && lockNum > 0; //无可约且有锁定的时段判定为全锁
					return n;
				});
		    }else{
				roomData.list = [];
			}
		}).catch(() => {		  
			loading.value = false;
		})
	}
	
   //初始化时间
   const weekData = reactive({
	   list:[],
	   active:0
   })
   initWeek();
   function initWeek(time){
	   weekData.list = [];
	   var timeInfo = utils.getDateObj(time);
	   var week = timeInfo.W == 0 ? 6 : timeInfo.W-1; //求出距离周一几天
	   weekData.active = week; //存储当前选中的周几的索引
	   var monday = timeInfo.time - week*24*60*60*1000; //求出周一的时间
	   for(var i=0;i<7;i++){
		   var t = monday + i*24*60*60*1000;
		   var d = utils.getDateObj(t);
		   weekData.list.push(d);
	   }
	   if(time){ //切换周获取排期时段
		   getOrderData(timeInfo.Y+'-'+timeInfo.M+'-'+timeInfo.D);
	   }else{ //初始化获取分组
			getGroupData(timeInfo.Y+'-'+timeInfo.M+'-'+timeInfo.D);
	   }
   }
   //切换天
   function changeWeek(index){
	   weekData.active = index;
	   initTimeData();
   }
	//切换整周
	function checkAllWeek(type){ // -7  上一周   7 下一周
		var t = weekData.list[weekData.active];
		var w = t.time+type*24*60*60*1000; //上一周或下一周
		initWeek(w);
	}
	//切换日期
	const dataTime = ref('');
	function changeDataTime(){
		initWeek(dataTime.value);
	}
	
	//查看订单详情
	const lookOrderDetail = reactive({
		show:false,
		list:[],
		batchId:'',
		form:{},
		timesPriceMap:{},//按人计费规则
		orderState:'',
	})
	function timeItemOver(row){
		lookOrderDetail.batchId = row.encryptionRoomOrderId;
	}
	function getOrderDetail(row){
		lookOrderDetail.orderState = row.orderState;
		paymentEvent(row.encryptionBatchId);
		lookOrderDetail.show = true;
	}
	// 获取订单详情
	function paymentEvent(id,callback){ 
		getOrderToPay({
			encryptionBatchId:id
		}).then((res)=>{
			lookOrderDetail.form = res.results.batch;
			lookOrderDetail.form.actualMoneyOld = res.results.batch.actualMoney;
			lookOrderDetail.form.actualMoneyNew = 0;
			lookOrderDetail.form.createTime = res.results.batch.createTime.substring(0,16).replace(/T/,' ');
			lookOrderDetail.form.payMethodName = getPayMethod(lookOrderDetail.form.payMethodId);
			lookOrderDetail.form.payMethodId = !lookOrderDetail.form.payMethodId ? '' : lookOrderDetail.form.payMethodId;
			lookOrderDetail.form.timeNum = roomData.perSceneTime*res.results.roomOrderList.length/60;
			lookOrderDetail.form.headImgUrl = res.results.roomOrderList[0].headImgUrl;
			lookOrderDetail.timesPriceMap = res.results.timesPriceMap;
			lookOrderDetail.form.peopleNumNew = '';
			lookOrderDetail.form.refundType = lookOrderDetail.form.payMethodId == 101 ? '3' : '1';
			if(timeItemData.costType == 2){ //按人计费
				getActualMoneyByPeople();
			}
			var refundState = 1;
			var list = res.results.roomOrderList.map(n=>{
				if(n.state == 0){
					refundState = 0;
				}
				var d = n.orderStartTime.substring(0,10);
				var s = n.orderStartTime.substring(11,16);
				var e = n.orderEndTime.substring(11,16);
				n.time = d+' '+s+'-'+e;
				n.orderTimeInterval = s+'-'+e;
				n.money = n.actualMoney;
				n.check = false;
				n.timeNum = utils.getDateObj(n.orderStartTime).time;
				return n;
			})
			list.sort((a,b)=>a.timeNum-b.timeNum);
			lookOrderDetail.list = list;
			lookOrderDetail.form.refundState = refundState;//退款时的订单状态
			callback && callback();
		})
	}
	
	//选中时段
	const timeItemData = reactive({
		type:-1,
		costType:0,
		encryptionBatchId:'',
		consumeId:'',
		list:[],
		check:[]
	})
	const timeBox = reactive({
		top:0,
		left:0,
		show:localStorage.getItem('orderTips'),
		pRight:0,
		pTop:0
		})
	function closeTips(){
		timeBox.show = 'close';

	}
	function clickTimeItem(row,index,e){
		if(!timeBox.show){ //初始第一次进系统引导提示
			timeBox.top = e.clientY-e.offsetY+'px';
			timeBox.left = e.clientX-e.offsetX+'px';
			timeBox.pRight = window.innerWidth > 1500 ? 370+'px' : 15+'px';
			var btns = document.getElementById('btns');
			timeBox.pTop = btns.offsetTop-10+'px';
			timeBox.show = 'open';
			localStorage.setItem('orderTips','close');
		} 
		if(row.orderState == 9){ //退款不释放无需操作
			return false;
		}
		if(row.orderState != timeItemData.type || row.costType != timeItemData.costType || row.encryptionBatchId != timeItemData.encryptionBatchId){ //判断是否选取了不同类型的时段重置选中的数据
			timeItemData.type = row.orderState;
			timeItemData.costType = row.costType;
			timeItemData.encryptionBatchId = row.encryptionBatchId;
			timeItemData.consumeId = row.consumeId;
			timeItemData.list = [];
			timeItemData.check = [];
		}
		if(row.orderState == 8){ //会员代付锁场不处理
			timeItemData.type = -1;
			timeItemData.costType = 0;
			timeItemData.encryptionBatchId = '';
			timeItemData.consumeId = '';
			return false;
		}
		var arr = [],noOrder = false;
		if((row.orderState == 0 || row.orderState == 3) && row.continueTime){ //可约且是连场
			var continueTime = row.continueTime.split('-');
			arr = roomData.list[index].timesList.filter(n=>{ //取到连场范围内的时段
				var st = n.stime.replace(/:/,'');
				if(Number(st) >= Number(continueTime[0]) && Number(st) < Number(continueTime[1])){
					if(n.orderState !== 0 && n.orderState != 3){ //存在不可约的时段
						noOrder = true;
					}
					return n;
				}
			})
		}
		var index = timeItemData.check.indexOf(row.roomId+'#'+row.orderTimeInterval); //判断当前时段是否已选中
		if(index > -1){
			if(arr.length > 0){//连场时段批量删除
				arr.map(n=>{
					var num = timeItemData.check.indexOf(n.roomId+'#'+n.orderTimeInterval); //获取已选集合内时段索引
					timeItemData.list.splice(num,1);
					timeItemData.check.splice(num,1);
				})
				
			}else{
				timeItemData.list.splice(index,1);
				timeItemData.check.splice(index,1);
			}
			if(timeItemData.list.length == 0){
				timeItemData.type = -1;
				timeItemData.costType = 0;
				timeItemData.encryptionBatchId = '';
				timeItemData.consumeId = '';
			}
		}else{
			if(noOrder){ //存在不可约的时段 终止并提示
				ElMessage({
					type: 'warning',
					message: '该连场时段已有部分被占用，不可再预约',
				})
				return false;
			}
			if(arr.length > 0){
				arr.map(n=>{
					timeItemData.list.push(n);
					timeItemData.check.push(n.roomId+'#'+n.orderTimeInterval);
				})
			}else{
				timeItemData.list.push(row);
				timeItemData.check.push(row.roomId+'#'+row.orderTimeInterval);
			}
			
		}
	}
	//获取支付方式
	const payTypeData = reactive({
		payList:[],
		cardList:[],
	})
	function getPayTypeList(encryptionId){
		payDetailToEdit().then((res)=>{
			if(res.code == 0){
				getPayCode().then((resolve) => {
					if (resolve.code == 0) {
						payTypeData.payList = res.data;
						lookOrderDetail.form.payMethodId = res.data[0].payId;
						if (resolve.data&&resolve.data.url) {
							payTypeData.payList.push({
								payMethodName:'收款码',
								payId:100
							})
							lookOrderDetail.form.payMethodId = 100;

						}
					}
				})
				getStoreCard(encryptionId);
			}
		})
	}
	//获取可用消费的储值卡
	function getStoreCard(stuId){
		listStoreCard4Order({
			encryptionId:stuId,
			consumeType: 7
		}).then((res)=>{
			if(res.code == 0){
				if(res.data.length > 0){
					payTypeData.cardList = res.data;
					payTypeData.payList.push({
						payMethodName:'储值卡',
						payId:101
					})
				}
			}
		})
	}
	
	//预订
	const bookData = reactive({
		show:false,
		loading:false,
		appointmentStr:'',
		form:{
			encryptionBatchId:'',
			appointmentStr:'',
			realName:'',
			telephoneNum:'',
			cardMyId:'',
			orderDate:'',
			groupId:'',
			peopleNum:'',
			remark:'',
		}
		
	})
	function bookEvent(data){ //有data -> 编辑
		var roomIds = [];
		timeItemData.list.map(n=>{
			if(roomIds.indexOf(n.roomId) == -1){ //所选时段的场地id
				roomIds.push(n.roomId);
			}
		})
		var group = groupData.list.filter(n=>n.id == groupData.activeId);
		if(!data && group[0].minHour*60 > timeItemData.list.length*group[0].perSceneTime){ //不够起订时长
			ElMessage({
				type: 'warning',
				message: '未达到起订时长，起订时长为'+group[0].minHour+'小时！'
			})
			return false;
		}
		if(!data && timeItemData.costType == 2){  //预订判断按人数计费 只能选择一个场地的时段
			if(roomIds.length > 1){ //存在多个场地
				ElMessage({
					type: 'warning',
					message: '按人数计费方式只能操作一个场地的时段'
				})
				return false;
			}
		}
		for(var k in bookData.form){
			bookData.form[k] = data ? data[k] : '';
		}
		var list = [],dataList = data ? lookOrderDetail.list : timeItemData.list;
		dataList.map((n)=>{ 
			if(list[n.roomId || n.roomOrderId]){
				list[n.roomId || n.roomOrderId].arr.push(n.orderTimeInterval);
			}else{
				list[n.roomId || n.roomOrderId] = {
					name:n.roomName,
					arr:[n.orderTimeInterval]
				}
			}
		})
		var check1 = [],check2 = [];
		list.map((n,i)=>{
			check1.push(n.name+''+n.arr.join('、'));
			check2.push(i+'#'+n.arr.join('/').replace(/:/g,''))
		})
		bookData.appointmentStr = check1.join(',');
		bookData.form.appointmentStr = check2.join(',');
		bookData.show = true;
	}
	
	//修改信息
	function updateInfoEvent(){
		paymentEvent(timeItemData.encryptionBatchId,function(){
			bookEvent(lookOrderDetail.form);
		});
	}
	
	//预订-搜索组件
	const inputData = reactive({
		type:'',
		list:[]
	})
	function inputFocusEvent(type){
		inputData.type = type;
	}
	function inputItemClick(row){
		bookData.form.cardMyId = row.cardMyId;
		bookData.form.realName = row.realName;
		bookData.form.telephoneNum = row.telephoneNum;
	}
	let timer = '';
	function inputChangeEvent(){
		clearTimeout(timer);
		setTimeout(()=>{
			bookData.form.cardMyId = '';
			selectStoreMembersSimple({
				searchKeys:inputData.type == 1 ? bookData.form.realName : bookData.form.telephoneNum
			}).then((res)=>{
				if(res.code == 0){
					inputData.list = res.data;
				}
			})
		},500)
	}
	function bookOnlyOrder(type){
		if(!bookData.form.realName){
			ElMessage({
				type: 'warning',
				message: '请输入姓名',
			})
			return false;
		}
		if(!bookData.form.telephoneNum){
			ElMessage({
				type: 'warning',
				message: '请输入手机号',
			})
			return false;
		}
		if(timeItemData.costType == 2 && !bookData.form.peopleNum){
			ElMessage({
				type: 'warning',
				message: '请输入预计到店人数',
			})
			return false;
		}
		if(type){ //预订并支付
			orderAjax(type);
		}else{ //仅预约
			if(bookData.form.encryptionBatchId){ //修改
				orderAjax();
			}else{
				confirmFun('确定预约吗？',timeItemData.type === 0 ? '' : '选中的场地含已过期时段！','book');
			}
		}
	}
	function orderAjax(type){
		var timeInfo = weekData.list[weekData.active];
		bookData.form.orderDate = timeInfo.Y+''+timeInfo.M+''+timeInfo.D;
		bookData.form.groupId = groupData.activeId;
		if(bookData.form.encryptionBatchId){ //修改
			var {encryptionBatchId,realName,telephoneNum,peopleNum,cardMyId,remark} = bookData.form;
			updateAppointmentInfo({
				encryptionBatchId,
				realName,
				telephoneNum,
				peopleNum,
				cardMyId,
				remark
			}).then((res)=>{
				if(res.code == 0){
					bookData.show = false;
					ElMessage({
						type: 'success',
						message: '修改成功',
					})
					initTimeData();
				}
			})
		}else{
			bookData.form.payAfter = type ? 0 : 1;
			appointment(bookData.form).then((res)=>{
				if(res.code == 0){
					bookData.show = false;
					if(type){ //预订并支付
						paymentClick(res.results.encryptionBatchId);
					}else{
						ElMessage({
							type: 'success',
							message: '预订成功',
						})
						initTimeData();
					}
				}
			})
		}
	}
	
	//付款
	const paymentData = reactive({
		show:false,
		encryptionBatchId:'' //用来判断是直接付款  还是预订并付款
	})
	
	function paymentClick(id,type){
		paymentData.encryptionBatchId = id || ''; //用来判断是直接付款  还是预订并付款
		paymentEvent(id || timeItemData.encryptionBatchId,function(){
			getPayTypeList(lookOrderDetail.form.encryptionCardMyId);
			paymentData.show = true;
		});
	}
	
	
	//通过实收计算每个场地的金额
	function getMoneyByActualMoney(){
		if(lookOrderDetail.form.costType == 1){ //按时间计费
			var actualMoney = isNaN(Number(lookOrderDetail.form.actualMoney)) ? 0 :lookOrderDetail.form.actualMoney;
			var len = lookOrderDetail.list.length;
			var average = parseInt(Number(actualMoney)/len); //平均差不多分配的金额
			var num = Number(actualMoney) - (len-1)*average; //最后一条记录剩下的钱数
			lookOrderDetail.list.map((n,i)=>{
				if(i == len-1){
					n.money = num;
				}else{
					n.money = average;
				}
			})
		}
	}
	//通过每个场地的金额计算实收
	function getActualMoneyByMoney(){
		var num = 0;
		lookOrderDetail.list.map((n,i)=>{
			num+=isNaN(Number(n.money)) ? 0 : Number(n.money);
		})
		lookOrderDetail.form.actualMoney = num;
	}
	var allNum = 0;
	//跟据人数获取总实收
	function getActualMoneyByPeople(){
		var {peopleNum,trialFlag} = lookOrderDetail.form;
		if(/^$|(^[1-9]\d*$)/.test(peopleNum) && peopleNum <= 9999){
			allNum = lookOrderDetail.form.peopleNum ;
		}else{
			peopleNum = lookOrderDetail.form.peopleNum  = allNum;
		}
		var timesPriceMap = lookOrderDetail.timesPriceMap[timeItemData.list[0].roomId];
		timesPriceMap.map(n=>{ //按人计费通过设置的计费规则计算钱数
			if(n.sectionStart < peopleNum && peopleNum <= n.sectionEnd){
				lookOrderDetail.form.peopleMoney = trialFlag == 1 ? n.vipPrice : n.orderPrice;
			}
		})
		lookOrderDetail.form.shouldMoney = lookOrderDetail.form.actualMoney = peopleNum*(lookOrderDetail.form.peopleMoney || 0);
	}
	//支付保存
	function paymentSave(){
		var {encryptionBatchId,actualMoney,payMethodId,peopleNum,orderId,costType,remark} = lookOrderDetail.form;
		var roomOrderMoney = [];
		if(costType == 1){ //按时间计费
			lookOrderDetail.list.map((n,i)=>{
				roomOrderMoney.push(n.roomOrderId+':'+n.money);
			})
		}
		doOrderPay({
			remark,
			encryptionBatchId,
			actualMoney,
			payMethodId,
			peopleNum,
			orderId,
			roomOrderMoney:roomOrderMoney.join(',')
		}).then((res)=>{
			if(res.code == 0){
				if (payMethodId == 100) {
					if (actualMoney  == 0.00) {
						ElMessage({
							type: 'success',
							message: '支付成功'
						})
						initTimeData();
						paymentData.show = false;
					} else {
						let data = {
                            encryptionBatchId: encryptionBatchId,
                            batchCode: lookOrderDetail.form.batchCode,
                            actualMoney: actualMoney,
                            realName: lookOrderDetail.form.realName,
                            telephoneNum: lookOrderDetail.form.telephoneNum
                        } 
						window.open('/#/branch/payCourseMoney?from=7&info=' + encodeURIComponent(JSON.stringify(data)), '_blank')
					}
				} else {
					ElMessage({
						type: 'success',
						message: '支付成功'
					})
					initTimeData();
					paymentData.show = false;
				}
			}
		})
	}
	//删除订单
	function paymentClose(){
		if(paymentData.encryptionBatchId){ //取消支付并删除订单
			deleteOrderToPay({
				encryptionBatchId:paymentData.encryptionBatchId,
			}).then((res)=>{
				
			})
		}
		paymentData.show = false;
	}
	
	//退款
	const refundData = reactive({
		show:false,
	})
	function refundEvent(){
		confirmRef.value.close();
		paymentEvent(timeItemData.encryptionBatchId,function(){
			refundData.show = true;
		});
	}
	function refundSave(){
		var {encryptionBatchId,actualMoneyNew,refundType,remark,costType,peopleNumNew} = lookOrderDetail.form;
		var roomOrderIds = [];
		lookOrderDetail.list.map((n,i)=>{
			if(n.check){
				roomOrderIds.push(n.roomOrderId);
			}
		})
		if(costType == 1 && roomOrderIds.length == 0){ //按时间计费
			ElMessage({
				type: 'warning',
				message: '请选择需要退款的时段'
			})
			return false;
		}
		if(!actualMoneyNew && actualMoneyNew !== 0){
			ElMessage({
				type: 'warning',
				message: '请输入退款金额'
			})
			return false;
		}
		if(!refundType){
			ElMessage({
				type: 'warning',
				message: '请选择退款方式'
			})
			return false;
		}
		memberOrderRoomManyCancel({
		 	encryptionBatchId,
		 	actualMoney:actualMoneyNew,
		 	refundType,
			peopleNum:peopleNumNew,
		 	roomOrderIds:roomOrderIds.join(','),
			remark
		}).then((res)=>{
			if(res.code == 0){
				ElMessage({
					type: 'success',
					message: '退款成功'
				})
				initTimeData();
				refundData.show = false;
			}
		})
	}
	function refundCheckChange(row){
		var num = 0;
		lookOrderDetail.list.map(n=>{
			if(n.check){
				num+=n.actualMoney;
			}
		})
		lookOrderDetail.form.actualMoneyNew = num;
	}
	
	//换场
	const changeData = reactive({
		list:[],
		show:false,
		str:'',
		roomId:'',
		costType:'',
		ids:[],
		form:{
			headImgUrl:'',
			realName:'',
			telephoneNum:'',
			trialFlag:'',
			orderDate:'',
			encryptionRoomId:'',
		}
	})
	function changeEvent(){
		changeData.ids = [];
		changeData.roomId = '';
		changeData.form.orderDate = '';
		changeData.form.encryptionRoomId = '';
		var arr = [],roomIds = [];
		timeItemData.list.map(n=>{
			if(roomIds.indexOf(n.roomId) == -1){ //手机所选时段的场地id
				roomIds.push(n.roomId);
				changeData.costType = n.costType;
			}
			arr.push(n.orderTimeInterval);
			changeData.ids.push(n.encryptionRoomOrderId);
		})
		if(roomIds.length > 1){ //存在多个场地
			ElMessage({
				type: 'warning',
				message: '换场只能操作一个场地的时段'
			})
			return false;
		}
		changeData.roomId = roomIds[0];
		changeData.form.headImgUrl = timeItemData.list[0].headImgUrl;
		changeData.form.realName = timeItemData.list[0].realName;
		changeData.form.telephoneNum = timeItemData.list[0].telephoneNum;
		changeData.form.trialFlag = timeItemData.list[0].trialFlag;
		changeData.str = arr.join(',');
		if(timeItemData.consumeId){ //在场状态操作换场
			var timeInfo = weekData.list[weekData.active];
			changeData.form.orderDate = timeInfo.Y+'-'+timeInfo.M+'-'+timeInfo.D;
			changeData.orderDate = timeInfo.Y+'-'+timeInfo.M+'-'+timeInfo.D;
		}
		changeData.show = true;
	}
	// 选择日期重新获取场地
	function changeDate(){
		var {Y,M,D} = utils.getDateObj(changeData.form.orderDate);
		getStoreRoomOrderTimeList({
			groupId:groupData.activeId,
			orderDateStr:Y+'-'+M+'-'+D,
		}).then((res)=> {
			if(res.code == 0){
				changeData.list = res.data || [];
			}
		})
	}
	function changeSave(){
		if(!changeData.form.orderDate){
			ElMessage({
				type: 'warning',
				message: '请选择修改日期'
			})
			return false;
		}
		if(!changeData.form.encryptionRoomId){
			ElMessage({
				type: 'warning',
				message: '请选择修改场地'
			})
			return false;
		}
		var {Y,M,D} = utils.getDateObj(changeData.form.orderDate);
		changeRoom({
		  	encryptionBatchId:timeItemData.encryptionBatchId,
		  	roomOrderIds:changeData.ids.join(','),
		  	orderDate:Y+''+M+''+D,
		  	encryptionRoomId:changeData.form.encryptionRoomId
		}).then((res)=>{
		 	if(res.code == 0){
		 		ElMessage({
		 			type: 'success',
		 			message: '换场成功'
		 		})
		 		initTimeData();
		 		changeData.show = false;
		 	}
		})
	}
	
	//加订
	const appendData = reactive({
		show:false,
		str:'',
		roomId:'',
		orderDate:'',
		list:[],
		check:[]
	})
	function appendEvent(){
		appendData.roomId = '';
		appendData.orderDate = '';
		appendData.check = [];
		appendData.list = [];
		paymentEvent(timeItemData.encryptionBatchId,function(){
			var list = [];
			lookOrderDetail.list.map((n)=>{ 
				if(list[n.roomOrderId ]){
					list[n.roomOrderId].arr.push(n.orderTimeInterval);
				}else{
					list[n.roomOrderId] = {
						name:n.roomName,
						arr:[n.orderTimeInterval]
					}
				}
			})
			var check1 = [];
			list.map((n,i)=>{
				check1.push(n.name+''+n.arr.join('、'));
			})
			appendData.str = check1.join(',');
			appendData.show = true;
		});
	}
	function changeRoomEvent(){
		appendData.list = [];
		if(!appendData.roomId || !appendData.orderDate){
			return false;
		}
		var {Y,M,D} = utils.getDateObj(appendData.orderDate);
		getStoreRoomOrderTimeList({
			groupId:groupData.activeId,
			orderDateStr:Y+'-'+M+'-'+D,
		}).then((res)=> {
			if(res.code == 0){
				var arr = res.data.filter(n => n.roomId == appendData.roomId);
				var timesList = arr[0].timesList.map(n=>{
					var time = n.orderTimeInterval.split('-');
					n.roomId = arr[0].roomId;
					n.roomName = arr[0].roomName;
					n.stime = time[0];
					n.etime = time[1];
					n.encryptionRoomId = arr[0].encryptionRoomId;
					n.orderPrice = roomData.perSceneTime == 30 ? (Number(n.orderPrice)/2).toFixed(2) : Number(n.orderPrice).toFixed(2) ;
					return n;
				})
				appendData.list = timesList;
			}
		})
	}
	//选择追加时段
	function appendSelect(row){
		var arr = [],noOrder = false;
		if((row.orderState == 0) && row.continueTime){ //可约且是连场
			var continueTime = row.continueTime.split('-');
			arr = appendData.list.filter(n=>{ //取到连场范围内的时段
				var st = n.stime.replace(/:/,'');
				if(Number(st) >= Number(continueTime[0]) && Number(st) < Number(continueTime[1])){
					if(n.orderState !== 0){ //存在不可约的时段
						noOrder = true;
					}
					return n;
				}
			})
		}
		var index = appendData.check.indexOf(row.orderTimeInterval);
		if(index > -1){
			if(arr.length > 0){//连场时段批量删除
				arr.map(n=>{
					var num = appendData.check.indexOf(n.orderTimeInterval); //获取已选集合内时段索引
					appendData.check.splice(num,1);
				})
			}else{
				appendData.check.splice(index,1);
			}
		}else{
			if(noOrder){ //存在不可约的时段 终止并提示
				ElMessage({
					type: 'warning',
					message: '该连场时段已有部分被占用，不可再预约',
				})
				return false;
			}
			if(arr.length > 0){
				arr.map(n=>{
					appendData.check.push(n.orderTimeInterval);
				})
			}else{
				appendData.check.push(row.orderTimeInterval);
			}
		}
	}
	function appendSave(){
		if(appendData.check.length == 0){
			ElMessage({
				type: 'warning',
				message: '请选择加订时段'
			})
			return false;
		}
		var {Y,M,D} = utils.getDateObj(appendData.orderDate);
		var str = appendData.roomId+'#'+appendData.check.join('/').replace(/:/g,'');
		appendAppointment({
		 	encryptionBatchId:timeItemData.encryptionBatchId,
			appointmentStr:str,
			orderDate:Y+''+M+''+D
		}).then((res)=>{
		 	if(res.code == 0){
				appendData.show = false;
		 		ElMessage({
		 			type: 'success',
		 			message: '加订成功'
		 		})
		 		initTimeData();
		 	}
		})
	}
	
	//取消预订
	function cancelClick(){
		if(timeItemData.type == 2 || timeItemData.type == 6){
			confirmData.title = '是否取消此预定？'
			confirmData.type = 'cancel';
		}else{
			confirmData.title = '客户预定时已支付，是否取消并退款？'
		}
		confirmRef.value.show();
	}
	function cancelEvent(type){
		if(type == 2){ 
			confirmRef.value.close();
		}
		var ids = timeItemData.list.map(n=>n.encryptionRoomOrderId);
		cancelTimesOnly({
			encryptionRoomOrderIds:ids.join(',')
		}).then((res)=>{
			if(res.code == 0){
				ElMessage({
					type: 'success',
					message: '取消成功'
				})
				initTimeData();
			}
		})
	}
	const lockData = reactive({
			show:false,
			remark:''
		})
	var lockDataList = [];
	//锁场
	function lockEvent(){
		lockData.remark = '';
		lockData.show = true;
	}
	function lockSave(){
		lockFunction(timeItemData.list,1,()=>{
			lockData.show = false;
		});
	}
	//批量锁定
	function batchLock(row){
		lockData.remark = '';
		timeItemData.type = -1;
		lockDataList = row.timesList;
		confirmData.title = '确定锁定该场地吗？';
		confirmData.text = '锁定场地后，今日该场地将不可操作预订！';
		confirmData.type = 'batchlock';
		confirmRef.value.show();
	}
	//锁定、解锁请求
	function lockFunction(dataList,kind,callback){
		var timeInfo = weekData.list[weekData.active];
		var day = timeInfo.Y+''+timeInfo.M+''+timeInfo.D;
		var list = [];
		dataList.map((n)=>{
			var type = 3;
			if(n.perSceneTime == 30){
				if(n.stime.indexOf('30') > -1){
					type = 2;
				}else{
					type = 1;
				}
			}
			list.push({
				remark:lockData.remark,
				roomId:n.roomId,
				day,
				kind,
				lockStr:parseInt(n.stime)+':'+type
			})
		});
		sportRoomLockSectionInsert(list).then((res)=>{
			if(res.code == 0){
				ElMessage({
					type: 'success',
					message: kind == 1 ? '锁定成功' : '解锁成功',
				})
				initTimeData();
				callback && callback();
			}
		})
	}
	//解锁
	function unlockEvent(){
		confirmData.title = '确定解锁该场地吗？';
		confirmData.text = '解锁后该场次可进行预订操作！';
		confirmData.type = 'unlock';
		confirmRef.value.show();
	}
	//批量解锁
	function batchUnLock(row){
		lockDataList = row.timesList;
		confirmData.title = '确定解锁该场地吗？';
		confirmData.text = '解锁后该场次可进行预订操作！';
		confirmData.type = 'batchunlock';
		confirmRef.value.show();
	}

	
	//入场
	const enterRoom = reactive({
		show:false,
		title:'',
		form:{},
		orderInfo:{}
	})
	
	function enterEvent(type){
		enterRoom.title = type == 1 ? '入场' : '离场';
		getMemberDetail({
			encryptionId: timeItemData.list[0].encryptionCardMyId,
		}).then((res) => {
		    if (res.code == 0) {
		        enterRoom.form = res.data;
		    }
		})
		getMemberVoucher({
			encryptionCardMyId:timeItemData.list[0].encryptionCardMyId
		}).then(res=>{
			if(type == 1 && res.data.enterOutFlag == 1){
				ElMessage({
					type: 'warning',
					message: '该会员已在场，请先离场！',
				})
				return false;
			}
			var arr = res.data.batchList.filter(n=>n.encryptionBatchId == timeItemData.encryptionBatchId);
			enterRoom.orderInfo = arr.length > 0 ? arr[0] : {};
			var list = arr[0].roomOrderList.map(n=>{
				n.timeNum = utils.getDateObj(n.orderTime.substring(0,16)+':00').time;
				return n;
			})
			list.sort((a,b)=>a.timeNum-b.timeNum); //按时间排序
			var obj = {};
			list.map(n=>{
				var day = n.orderTime.substring(0,10);
				if(obj[n.roomName+day]){
					obj[n.roomName+day].list+='、'+n.orderTime.substring(11);
				}else{
					obj[n.roomName+day] = {
						roomName:n.roomName,
						list:n.orderTime
					}
				}
			})
			var list = [];
			for(var k in obj){
				list.push(obj[k]);
			}
			enterRoom.orderInfo.roomStrList = list; 
			enterRoom.show = true;	
		})
	}
	function enterSave(){
		if(enterRoom.title == '入场'){
			var ids = enterRoom.orderInfo.roomOrderList.map(n=>n.id);
			enter({
				encryptionCardMyId:enterRoom.form.encryptionId,
				consumeType:5,
				enterBatchCounts:enterRoom.orderInfo.enterCount ? enterRoom.orderInfo.orderBatchId+':'+enterRoom.orderInfo.enterCount :'',
				roomOrderIds:ids.join(',')
			}).then(res=>{
				if(res.code == 0){
					enterRoom.show = false;
					ElMessage({
						type: 'success',
						message: '入场成功',
					})
					initTimeData();
				}
			})
		}else{
			out({
				encryptionCardMyId:enterRoom.form.encryptionId,
			}).then(res=>{
				if(res.code == 0){
					enterRoom.show = false;
					ElMessage({
						type: 'success',
						message: '离场成功',
					})
					initTimeData();
				}
			})	
		}
		
	}
	//确认框调起
	function confirmFun(title,text,type){
		confirmData.title = title;
		confirmData.text = text;
		confirmData.type = type;
		confirmRef.value.show();
	}
	//确认框回调
	function confirmSave(){
		if(confirmData.type == 'book'){ //预约
			orderAjax();
		}else if(confirmData.type == 'lock'){ //锁定
			lockFunction(timeItemData.list,1);
		}else if(confirmData.type == 'batchlock'){ //批量锁定
			lockFunction(lockDataList,1);
		}else if(confirmData.type == 'unlock'){ //解锁
			lockFunction(timeItemData.list,2);
		}else if(confirmData.type == 'batchunlock'){ //解锁
			lockFunction(lockDataList,2);
		}else if(confirmData.type == 'cancel'){ //取消
			cancelEvent(timeItemData.list);
		}
	}
	function initTimeData(){
		setTimeout(()=>{
			timeItemData.type = -1;
			timeItemData.costType = 0;
			timeItemData.encryptionBatchId = '';
			timeItemData.consumeId = '';
			timeItemData.list = [];
			timeItemData.check = [];
			var timeInfo = weekData.list[weekData.active];
			getOrderData(timeInfo.Y+'-'+timeInfo.M+'-'+timeInfo.D);
			getTodayData();
		})
		
	}
	//验证输入
	var inputValue = '';
	function inputVerification(form,val){
		if(/^$|(^[1-9]\d*$)/.test(form[val]) && form[val] <= 9999){
			inputValue = form[val];
		}else{
			form[val] = inputValue;
		}
	}
	//退款金额验证
	var actualMoney = '';
	function refundActualMoneyNewInput(){
		if(lookOrderDetail.form.actualMoneyNew <= Number(lookOrderDetail.form.actualMoneyOld)){
			inputValue = lookOrderDetail.form.actualMoneyNew;
		}else{
			lookOrderDetail.form.actualMoneyNew = inputValue;
		}
	}
	//获取支付方式
	function getPayMethod(payMethodId){
		var payList = utils.getDict('waterPayType');
		var arr = payList.filter(n=>n.value == payMethodId.toString());
		return arr.length > 0 ? arr[0].label : '';
	}
	function lookMemberDetail(){
		window.open('/#/branch/memberDetail?encryptionId=' +enterRoom.form.encryptionId , '_blank');
	}
	var timeer = '';
	onMounted(()=>{
		setTimeout(()=>{
			var agentOrder = document.getElementById('agentOrder');
			boxHeight.value = (agentOrder.clientHeight - 190);
		})
		timeer = setInterval(()=>{
			getTodayData();
		},300000)
	})
	onUnmounted(() => {
		clearInterval(timeer)
	})
	function refundPeopleNumInput(){
		if(lookOrderDetail.form.peopleNumNew > lookOrderDetail.form.peopleNum){
			lookOrderDetail.form.peopleNumNew = lookOrderDetail.form.peopleNum;
		}
		lookOrderDetail.form.actualMoneyNew = lookOrderDetail.form.peopleNumNew*(lookOrderDetail.form.peopleMoney ||  0);
	}

</script>
<style lang="stylus" scoped>
	.color-325EFE{
		color: #325EFE;
	}
	.color-B3B3B3{
		color: #B3B3B3;
	}
	.toolBtn{
		margin: 0;
		margin-right: 20px;
		box-shadow: -2px -2px 4px 0px rgba(46,185,154,0.28), 2px 2px 4px 0px rgba(0,0,0,0.28);
		background-color:#2EB99A;
		color:#fff;
		border:0px solid #fff;
	}
	.toolBtn:last-child{
		margin:0;
	}
	button[disabled],button[disabled]:hover{
		color:#fff;
		border:0px solid #fff;
		background-color:#86D3FF;
		box-shadow: -2px -2px 4px 0px rgba(3, 163, 255, 0.28), 2px 2px 4px 0px rgba(0,0,0,0.28);
	}
	.btns{
		padding-right: 355px;
	}
	@media screen and (max-width: 1500px) {
	    .btns{
			padding-right: 0 !important;
		}
		.timeBoxCenter{
			padding:0 0 0 140px !important;
		}
		.timeBoxRight{
			display:none;
		}
	}
	.box{
		position: relative;
		padding-right: 355px;
	}
	.leftBox{
		position: relative;
		padding-top: 85px;
		.weekBox{
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
		}
	}
	.rightBox{
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		border-radius: 40px 0 40px 0;
		background-color: #F4F7FB;
		overflow: hidden;
		.el-radio{
			margin: 5px;
		}
	}
	.rightBoxTop{
		background-color: #F4F7FB;
		position: absolute;
		top: 0;
		width:100%;
		left:0;
		z-index:100;
		border-radius: 40px 0 0 0;
	}
	.rightBoxBody{
		padding-top:160px;
		height:95%;
		overflow: auto;
	}
	.weekActive{
		background-color: #00A3FF;
	}
	.timeBox{
		overflow: auto;
		position: relative;
		border: 1px solid #E6E6E6;
		.timeBoxHead{
			position: sticky;
			top: 0;
			z-index: 161;
			.timeBoxHeadName{
				background-color: #f4f7fb !important;
			}
			.timeItem{
				border-left: none !important;
			}
		}
		.timeBoxHeadName{
			position: sticky;
			background-color: #fff;
			z-index: 160;
			border-bottom:1px solid #E6E6E6;
		}
		.timeBoxHeadName:first-child{
			left: 0;
			box-shadow: inset -10px 0 10px -10px rgba(0, 0, 0, 0.15);;
		}
		.timeBoxHeadName:last-child{
			right: 0;
			box-shadow: inset 10px 0 10px -10px rgba(0, 0, 0, 0.15);
		}
	}
	
	.timeItem{
		position: relative;
		border-left: 1px solid #E6E6E6;
		border-bottom: 1px solid #E6E6E6;
	}
	.timeItem:nth-child(2){
		border-left:none;
	}
	.onLineBox{
		background-color:#CDE9FF; 
	}
	.popup{
		position: absolute;
		background-color:#fff;
		border-radius:2px;
		box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.1500);
		top:20px;
		left:100px;
		z-index:210;
	}
	.popup::after{
		position: absolute;
		top:20px;
		left:-8px;
		content:'';
		width: 0;
		height: 0;
		border-top: 8px solid transparent;
		border-right: 8px solid #fff;
		border-bottom: 8px solid transparent;
	}
	.popupBottom.popup::after{
		top: initial;
		bottom:20px;
	}
	.popupBottom{
		top: initial;
		bottom:20px;
	}
	.payState{
		position: absolute;
		top: 0;
		right: 0;
		background-color: #FF5B57;
		border-radius: 0 0 0 50px;
		padding: 0 10px;
	}
	.enterState{
		position: absolute;
		top: 0;
		left: 0;
		background-color: #00a3ff;
		border-radius: 0 0 50px 0;
		padding: 0 10px;
	}
	.check_blue{
		position: absolute;
		right: 0;
		bottom: 0;
		height: 20px;
		width: 20px;
		z-index: 100;
	}
	.timeItemLock{
		position: absolute;
		top:0;
		right:0;
		bottom:0;
		left:0;
		background-color:#E0E0E0;
		.popup{
			display:none;
		}
	}

	.timeItemLock:hover .popup{
		display:block;
	}
	.timeTips{
		i{
			width: 18px;
			height: 18px;
			border: 1px solid #E6E6E6;
		}
	}
	.border-E6E6E6{
		border: 1px solid #E6E6E6;
	}
	.inputBox{
		position: relative;
		ul{
			position: absolute;
			top: 40px;
			left: 0;
			right: 0;
			background-color: #fff;
			border-radius: 4px;
			z-index: 100;
			box-shadow: 0 0 5px #ccc;
			padding: 10px 0px;
			max-height: 200px;
			overflow: auto;
			li{
				padding: 0px 20px;
				height: 30px;
				line-height: 30px;
			}
			li:hover{
				background-color: #03A3FF;
				color: #fff;
			}
			li.active{
				background-color: #03A3FF;
				color: #fff;
			}
		}
	}
	.appendTime{
		border:1px solid #ccc;
		border-right: 0;
		margin-bottom: 40px;
		span{
			position: absolute;
			top: -20px;
			color: #0C6394;
			font-weight: bold;
			font-size: 12px;
		}
		span.startTime{
			left: -10px;
		}
		span.endTime{
			right: -10px; 
			display: none;
		}
	}
	
	.appendTime:last-child,.appendTime:nth-child(6n){
		border-right: 1px solid #ccc;
		span.endTime{
			display: block;
		}
	}
	
	.bg-FCC9C8{
		background-color: #FCC9C8;
	}
	.bg-CDE9FF{
		background-color: #CDE9FF;
	}
	.bg-FFEAAB{
		background-color: #FFEAAB;
	}
	.bg-E0E0E0{
		background-color: #E0E0E0;
	}
	.bg-F4F7FB{
		background-color: #F4F7FB;
	}
	.bg-2EB99A{
		background-color:#2EB99A;
	}
	
	.color-2EB99A{
		color: #2EB99A;
	}
	.color-E19C0E{
		color: #E19C0E;
	}
	.color-018000{
		color: #018000;
	}
	.datePicker{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0;
		overflow: hidden;
	}
	.border-dashed::after{
		content:'';
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		border:2px dashed #2EB99A;
		z-index:150;
	}
	.noOrderData{
		position:relative;
	}
	.noOrderDataTitle{
		position:absolute;
		top:0;
		left:0;
		right:0;
	}
	.el-table{
		border:none;
	}
	.timeItemBox{
		position:fixed;
		background-color:red;
		width:100px;
		height:70px;
		z-index:1000;
	}
	.tipBpx{
		position:fixed;
		left:0;
		right:0;
		top:0;
		bottom:0;
		background-color:rgba(0,0,0,0.5);
		z-index:900;
	}
	
</style>