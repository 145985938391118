<template>
	<div class="course_card">
		<formQuery :queryOpts="pageData.queryOpts" @query="getTableData"></formQuery>
		<div class="mb16">
			<el-button type="primary" @click="updateRow('')" v-if="utils.getMenuRole(3160200)">新增储值卡</el-button>
			<el-button type="danger" plain @click="deletSomeOne" v-if="utils.getMenuRole(3160201)">批量删除</el-button>
		</div>
		<div>
			<el-table :data="pageData.tableData" stripe style="width: 100%" @selection-change="selectionChange">
				<el-table-column type="selection" width="55" :selectable="(row)=>{return row.sid != 0}" />
			    <el-table-column prop="productName" label="储值卡名称" show-overflow-tooltip>
					<template #default="scope">
						<a href="javascript:;" class="color-409EFF tool_btn" v-if="scope.row.sid != 0 && utils.getMenuRole(3160203)" @click="updateRow(scope.row.encryptionId)">{{ scope.row.productName }}</a>
						<span v-else>{{scope.row.productName}}</span>
					</template>
				</el-table-column>
			    <el-table-column prop="storeCardValue" label="储值卡面额(元)" />
          <el-table-column prop="productCycle" label="可消费时限" >
            <template #default="scope">
                <span>
                  {{
                    (scope.row.productCycle || '') + (scope.row.productCycle ? scope.row.ifMonth === 1 ? '天' : scope.row.ifMonth === 2 ? '月' : '年' : '不限时')
                  }}
                </span>
            </template>
          </el-table-column>
			    <el-table-column prop="productPrice" label="售价(元)" />
				<el-table-column prop="consumeStoreName" label="可消费门店" show-overflow-tooltip />
			    <el-table-column prop="name" label="储值卡状态" v-if="utils.getMenuRole(3160202)">
			    	<template #default="scope">
			    		<div @click="updateShelfState(scope.row)">
			    			<!-- el-switch 初始值为0默认回走一次onchange事件 所以不用 -->
			    			<el-switch v-model="scope.row.shelfState" inline-prompt :active-value="1" :inactive-value="0" ></el-switch>
			    		</div>
			    	</template>
			    </el-table-column>
			    <el-table-column prop="storeName" label="发布渠道" />
				<el-table-column label="操作" width="160">
					<template #default="scope">
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="scope.row.sid != 0 && utils.getMenuRole(3160204)" @click="categoryClick(scope.row)">消费类目</a>
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="lookDetail(scope.row)">查看详情</a>
					</template>
				</el-table-column>
			</el-table>
			<div class="mt20 rx-ec">
			    <el-pagination
			      v-model:currentPage="pageData.searchForm.page"
			      :page-sizes="[15,20,30,50,100]"
			      :page-size="pageData.searchForm.limit"
			      layout="total, sizes, prev, pager, next, jumper"
			      :total="pageData.totalCount"
			      @size-change="pageSizeChange"
			      @current-change="()=>{getList()}"
			    >
			    </el-pagination>
			</div>
		</div>
    
<!--    消费类目-->
    <el-dialog
        v-model="pageData.categoryShow"
        title="消费类目"
        width="516px"
        :before-close="()=>{pageData.categoryShow = false}"
        @close="getList"
        custom-class="popwechat"
    >
      	<div class="pl20 pt40 pr20 pb40">
      	    <div class="rx-wss">
				<div class="category mb20 mr20" v-for="item in pageData.categoryList" :key="item.id">
					<el-checkbox v-model="item.check" :label="item.id" >
			          	<span class="color-666 fw400">{{item.name}}</span>
			      	</el-checkbox>
				</div>
      		</div>
      	</div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="pageData.categoryShow = false">取消</el-button>
          <el-button type="primary" @click="categorySave">确定</el-button>
        </div>
      </template>
    </el-dialog>
	</div>
</template>

<script setup>
	import {ref,reactive,onMounted,onActivated} from 'vue';
	import {useRouter,onBeforeRouteLeave} from 'vue-router';
	import {useStore} from 'vuex'
	import {Refresh,DArrowRight,Connection,Close} from '@element-plus/icons-vue'
	import utils from '@/common/utils.js'
  	import {list4Store, updateShelf,updateRun,productDelete} from '@/api/cards.js';
  	import {ElMessage} from "element-plus";
  	import formQuery from '@/components/branch-store/formQuery.vue';
  const store = useStore();
	const router = useRouter();
	const pageData = reactive({
		tabNum:'0',
		storeList:[],
		tableData:[],
		totalCount:0,
		searchForm:{
			page:1,
			limit:15,
			type:3,
			productName:'',
			shelfState:'1',
			storeId:'',
			sortType:'1'
		},
    categoryList:[
      {id:1, name:'预约单节团体课', check: false},
      {id:2, name:'预约单节私教课', check: false},
      {id:3, name:'购买私教课', check: false},
      {id:4, name:'购买团课', check: false},
      {id:5, name:'购买卡项', check: false},
      // {id:6,name:'购买商城商品'},
      {id:7,name:'预约场地'},
	  {id:8,name:'购买班级'}
    ],
    categoryShow:false,
	confirmTitle:'',
	confirmText:'',
	deleCheckData:[],
	queryOpts:{
		fields:[{
			type : 'input',
			fieldName : 'productName',
			placehold : '请输入课程卡名称'
		},{
			type : 'select',
			fieldName : 'shelfState',
			placehold : '选择卡状态',
			options : utils.getDict('shelfState',{label : '全部状态' ,value : ''}),
			value:'1'
		},{
			type : 'select',
			fieldName : 'storeId',
			placehold : '全部发布渠道',
			options : [],
			value:''
		},{
			type : 'select',
			fieldName : 'sortType',
			placehold : '排序方式',
			options : utils.getDict('sortType'),
			value:'1'
		}]
	}
	})
	utils.getDynaDict('storeList',{label : '全部发布渠道' ,value : ''},function(res){
	    for(var i=0;i<res.length;i++){
	        pageData.queryOpts.fields[2].options.push(res[i]);
	    }
	});
	//筛选表单回调
	function getTableData(options){
		for(var key in options){
			pageData.searchForm[key] = options[key];
		}
		searchTable();
	}
	
	//搜索table
	function searchTable(){
		pageData.searchForm.page = 1;
		getList();
	}
	//切换分页数量
	function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		searchTable();
	}
	getList();
	function getList(){
		list4Store(pageData.searchForm).then((res)=>{
			if(res.code == 0){
				pageData.tableData = res.data;
        pageData.totalCount = res.count;
			}
		})
	}
	//新增或编辑
	function updateRow(id){
		router.push({path:'/branch/storeCard',query:{id}});
	}
	//查看详情
	function lookDetail(row){
		router.push({path:'/branch/cardDetail',query:{
			id:row.encryptionId,
			consumeType: row.consumeType
		}});
	}
  //上下架操作
  function updateShelfState(row){
  	updateShelf({
  		productIds:row.encryptionId,
  		shelfState:row.shelfState ? 1 : 0
  	}).then((res)=>{
  		if(res.code == 0){
  			ElMessage({
  			  type: 'success',
  			  message: row.shelfState ?'上架成功':'下架成功',
  			})
  			searchTable();
  		}
  	})
  }
  //批量删除员工
  const confirmRef = ref();
  function selectionChange(data){
  	pageData.deleCheckData = data.map(n=>n.encryptionId);
  }
  function deletSomeOne(){
  	if(pageData.deleCheckData.length == 0){
  		ElMessage({
  			type: 'warning',
  			message: '请选择要删除的卡项',
  		})
  		return false;
  	}
  	pageData.confirmTitle = '确定要删除选中的数据吗？';
  	confirmRef.value.show();
  }
  function confirmSave(){
  	productDelete({
  		encryptionId:pageData.deleCheckData.join(',')
  	}).then((res)=>{
  		if(res.code == 0){
  			ElMessage({
  				type: 'success',
  				message: '删除成功',
  			})
  			searchTable();
  		}
  	})
  	
  }
  //消费类目
  let rowId = '' ;
  function categoryClick(row) {
    rowId = row.encryptionId;
   	row.consumeType.split(',').forEach((item) => {
      	pageData.categoryList.filter((n) => {
      	  	if (n.id == item) {
      	  	    n.check = true
      	  	}
      	})
    })
    pageData.categoryShow = true;
  }
  function categorySave() {
	let arr = []
    pageData.categoryList.filter((n) => {
        if (n.check) {
            arr.push(n.id)
        }
    })
    updateRun({
      consumeType: arr.join(','),
      encryptionId:rowId
    }).then((res)=>{
      if(res.code == 0){
        ElMessage({
          type: 'success',
          message: '保存成功',
        })
        pageData.categoryShow = false;
        getList();
      }
    })
  }

	// 初始化页面
	onActivated(()=>{
	    getList();
	})

// 页面缓存
	onBeforeRouteLeave((to, from)=>{
	    if (to.path === '/branch/cardDetail' || to.path === '/branch/storeCard') {
	        store.commit('SetKeep',{keepLiveRoute : ['BranchCards']});
	    } else {
	        store.commit('SetKeep',{keepLiveRoute : []});
	    }
	})
	
	
</script>

<style scoped lang="stylus">
	.course_card{
		.bind_table{
			position relative;
			overflow hidden;
			transition 0.5s;
			.bind_table_show{
				position absolute;
				right 10px;
				top 9px;
				z-index 100;
				color #409EFF;
				
			}
		}
		.bind_table_num{
			border none;
			border-bottom 1px solid #999;
			background-color initial;
		}
    .stateIcon{
      position relative;
    }
    .stateIcon::after{
      content '';
      position absolute;
      left 0;
      top 50%;
      transform translateY(-50%);
      width 8px;
      height 8px;
      border 2px solid #FBE3E3;
      background-color #FE8585;
      border-radius 100%;
    }
    .stateIcon.blue::after{
      content '';
      position absolute;
      left 0;
      top 50%;
      transform translateY(-50%);
      width 8px;
      height 8px;
      border 2px solid #DAF7F0;
      background-color #44D7B6;
      border-radius 100%;
    }
	}
	  .category {
		padding: 0 13px;
		background: #F7F7F7;
    	border-radius: 1px;
    	border: 1px solid #EAF0F8;
	}
</style>
