<template>
	<workArea :barlist="pageData.barlist">
	<div class="update_course_card bg-fff p20 hh100 o_a" v-loading="pageData.loading">
		<CommonTitle text="基本信息"/>
		<div class="rx-sc mb12">
			<div class="rx-sc mr40">
				<span class="w120 t_a_r"><span class="color-red">*</span>储值卡名称：</span>
				<div class="w240">
					<!-- :disabled="id ? true : false" -->
					<el-input type="text" placeholder="请输入(限50字)" maxlength="50" v-model="pageData.formData.productName" ></el-input>
				</div>
			</div>
			<div class="rx-sc mr40">
				<span class="w120 t_a_r"><span class="color-red">*</span>储值卡面额：</span>
				<div class="w240">
					<el-input type="text" placeholder="请输入(整数)" v-model="pageData.formData.storeCardValue" @blur="storeCardValueBlur" maxlength="6">
						<template #append>元</template>
					</el-input>
				</div>
			</div>
		</div>
		<div class="rx-sc mb12">
			<div class="rx-sc mr40">
				<span class="w120 t_a_r">卡消费时长：</span>
				<div class="w240 select-name">
					<el-input type="text" placeholder="请输入大于0的数字" :disabled="!pageData.formData.ifMonth" v-model="pageData.formData.productCycle" @blur="productCycleBlur">
						<template #append>
							<el-select v-model="pageData.formData.ifMonth" style="width: 115px">
        					  	<el-option v-for="item in pageData.dateType" :key="item.id" :label="item.name" :value="item.id" />
        					</el-select>
							<!-- <el-dropdown @command="changeStore" :disabled="!pageData.formData.ifMonth" :tabindex="pageData.dateTypeIndex">
								<span class="c_p">
									<span >{{pageData.dateType[pageData.dateTypeIndex].name}}</span>
									<el-icon class="el-icon--right"><ArrowDown /></el-icon>
								</span>
								<template #dropdown>
									<el-dropdown-menu>
										<template v-for="item in pageData.dateType" :key="item.id">
											<el-dropdown-item :command="item.id">{{item.name}}</el-dropdown-item>
										</template>
									</el-dropdown-menu>
								</template>
							</el-dropdown> -->
						</template>
					</el-input>
				</div>
			</div>
			<div class="rx-sc mr40">
				<span class="w120 rx-ec">时长开始：</span>
				<div class="w300">
					<el-radio-group v-model="pageData.formData.productTimeType" :disabled="id ? true : false">
						<el-radio :label="1">从购买之日起算</el-radio>
						<el-radio :label="2">第一次消费起算</el-radio>
					</el-radio-group>
				</div>
			</div>
		</div>
		<div class="rx-ss mb12">
			<div class="rx-ss mr40">
				<span class="w120 t_a_r"><span class="color-red">*</span>可消费类目：</span>
				<div class="w800">
					<el-checkbox-group v-model="pageData.formData.consumeType">
						<template v-for="(item, index) in pageData.categoryList" :key="index">
							<el-checkbox class="mb10" :label="item.id" border>{{item.name}}</el-checkbox>
						</template>
					</el-checkbox-group>
				</div>
			</div>
		</div>
		
		<CommonTitle text="售卖信息"/>
		<div class="rx-sc mb12">
			<div class="rx-sc mr40">
				<span class="w120 t_a_r"><span class="color-red">*</span>销售金额：</span>
				<div class="w240">
					<el-input type="text" placeholder="请输入(保留2位小数)" maxlength="6" v-model="pageData.formData.productPrice" @blur="productPriceBlur">
						<template #append>元</template>
					</el-input>
				</div>
			</div>
			<div class="rx-sc">
				<span class="w120 t_a_r"><span class="color-red">*</span>售卖方式：</span>
				<div class="w240">
					<el-checkbox-group v-model="pageData.formData.sellType">
						<el-checkbox label="1"><span class="color-666 fw400">会员端售卖</span></el-checkbox>
						<el-checkbox label="2"><span class="color-666 fw400">线下售卖</span></el-checkbox>
					</el-checkbox-group>
				</div>
			</div>
		</div>
		<div class="rx-ss mb12">
			<div class="rx-sc mr40">
				<span class="w120 t_a_r"><span class="color-red">*</span>扣费顺序：</span>
				<div class="w500">
					<el-radio v-model="pageData.formData.deductionType" :label="1" size="large">优先扣除本金</el-radio>
				</div>
			</div>
		</div>
		<CommonTitle text="其他信息"/>
		<div class="rx-ss mb12">
			<span class="w120 t_a_r pt10"><span class="color-red">*</span>储值卡封面：</span>
			<div>
				<el-radio-group v-model="pageData.coverCheck">
					<el-radio label="0">风格一</el-radio>
					<el-radio label="1">风格二</el-radio>
					<el-radio label="2">自定义</el-radio>
				</el-radio-group>
				<div class="rx-ss">
					<div class="cover_img">
						<div v-if="pageData.coverCheck == 2" class="cover_dele"><unicon name="trash-alt" fill="#fff" hover-fill="#fff" @click="deleCover"></unicon></div>
						<img v-show="pageData.cover[pageData.coverCheck]" class="w120 h80" :src="pageData.cover[pageData.coverCheck]" alt=""/>
					</div>
					<div v-show="!pageData.cover[pageData.coverCheck]" class="w120 t_a_c color-999 h80 rx-cc mr10" style="border: 1px solid #ccc;">
						<div>
							<CommonUpload @change="uploadChange" :autoCropWidth="375" :autoCropHeight="250" :isCropper="true">
								<img class="w25" src="@/assets/addBank.png" alt="">
								<p>上传图片</p>
							</CommonUpload>
						</div>
					</div>
					<div v-show="!pageData.cover[pageData.coverCheck]" class="color-999 w200">
						建议大小为750*500px的jpg、png、bmp格式不超过2M的图片。
					</div>
				</div>
			</div>
		</div>
		<div class="rx-ss mb12">
			<span class="w120 t_a_r">储值卡介绍：</span>
			<div class="flex1" ref="editor"></div>
		</div>
		
		<div class="pl100">
			<el-button @click="goBack()">取消</el-button>
			<el-button type="primary" @click="addSave">保存并上架</el-button>
		</div>
	</div>
	</workArea>
</template>

<script setup>
	import {ref,reactive,onMounted,onBeforeUnmount} from 'vue';
  import {useRouter} from 'vue-router';
  import {Plus,ArrowRight,ArrowDown,CircleCheckFilled} from '@element-plus/icons-vue'
	import { ElMessage} from 'element-plus'
	 import WangEditor from 'wangeditor'
	import CommonTitle from '@/components/CommonTitle.vue'
  import {productInfo, productInsert, productUpdate} from '@/api/cards.js'
	import {wangEditorUpload} from '@/common/common.js'
	import workArea from '@/components/branch-store/workArea.vue';
	import CommonUpload from '@/components/CommonUpload.vue';
  const router = useRouter();
  const id = router.currentRoute.value.query.id;
  const editor = ref();
   let instance = ''; 
	const pageData = reactive({
		barlist:[{ name : '返回',type:0 },{ name : (id ? '编辑':'创建') +'储值卡'}],
		loading:false,
		formData:{
			type:3,
			productName:'',
			productTyp:'1',
			ifMonth:1,
			productCycle:'',
			storeCardValue:'',
			deductionType:1,
			productTimeType:'',
			productPrice:'',
			consumeType:[],
			sellType:[],
			productContent:''
		},
		categoryList:[
			{id:1,name:'预约单节团体课'},
			{id:2,name:'预约单节私教课'},
			{id:3,name:'购买私教课'},
			{id:4,name:'购买团课'},
			{id:5,name:'购买卡项'},
			// {id:6,name:'购买商城商品'},
			{id:7,name:'预约场地'},
			{id:8,name:'购买班级'},
			],
		consumeCheckList:[],
		sellCheckList:[],
		coverCheck:'0',
		cover:['https://image.taokatao.cn/imgs/runBusiness/card1.png','https://image.taokatao.cn/imgs/runBusiness/card2.png'],
		dateType:[{id:1,name:'天'},{id:2,name:'月'},{id:3,name:'年'}],
	})
	
	
	//保存
	function addSave(){
		var formData = JSON.parse(JSON.stringify(pageData.formData));
		if(!formData.productName){
			ElMessage({
				type: 'warning',
				message: '请填写储值卡名称',
			})
			return false;
		}
		
		if(formData.productTyp == 3 && !formData.productCycle){
			ElMessage({
				type: 'warning',
				message: '期限卡类型须填写卡消费时长',
			})
			return false;
		}
		if(!formData.productPrice){
			ElMessage({
				type: 'warning',
				message: '请输入售卖金额',
			})
			return false;
		}
		if(formData.sellType.length == 0){
			ElMessage({
				type: 'warning',
				message: '请选择售卖方式',
			})
			return false;
		}
		if(pageData.coverCheck == 2 && pageData.cover.length == 2){
			ElMessage({
				type: 'warning',
				message: '请上传储值卡封面',
			})
			return false;
		}
		if(!pageData.formData.productCycle && pageData.formData.productCycle != 0){
			pageData.formData.ifMonth = 0
		}
		formData.consumeType = formData.consumeType.join(',');
		formData.sellType = formData.sellType.join(',');
		formData.productCover = pageData.cover[pageData.coverCheck];
		formData.productContent = instance.txt.html();
    	pageData.loading = true;
    if(id){
      formData.encryptionId = id;
      productUpdate(formData).then((res)=>{
        pageData.loading = false;
        if(res.code == 0){
          ElMessage({
            type: 'success',
            message: '保存成功',
          })
          setTimeout(()=>{
            goBack();
          },1000)
        }
      })
    }else{
      productInsert(formData).then((res)=>{
        pageData.loading = false;
        if(res.code == 0){
          ElMessage({
            type: 'success',
            message: '保存成功',
          })
          goBack();
        }
      })
    }
		
	}
  // 取消返回
  function goBack(){
    router.push({path:'/branch/cards',query:{tab:2}});
  }
	//初始化数据
	function toInit(){
		pageData.formData = {
			type:3,
			productName:'',
			productTyp:'1',
			ifMonth:1,
			productCycle:'',
			storeCardValue:'',
			deductionType:1,
			productTimeType:'',
			productPrice:'',
			consumeType:[],
			sellType:[],
			productContent:''
		}
		pageData.coverCheck = '0';
		pageData.cover = ['https://image.taokatao.cn/imgs/runBusiness/card1.png','https://image.taokatao.cn/imgs/runBusiness/card2.png'];
		pageData.consumeCheckList = [];
		pageData.sellCheckList = [];
		instance.txt.html('');
	}
	
	//消费时长失去焦点
	function productCycleBlur(){
    	var {productCycle,productTimeType} = pageData.formData;
		if ( productCycle == 0 ) {
				ElMessage({
			    	type: 'warning',
			    	message: '消费时长不能为0',
			    })
    	       return false;
			}
    	if(id && productTimeType){
    	  return false;
    	}
    	if(productCycle && !productTimeType){
    	  pageData.formData.productTimeType = 1;
    	}else if(!productCycle){
    		pageData.formData.productTimeType = '';
    	}
		
	}
	
	//消销售金额失去焦点
	function productPriceBlur(){
		var {productPrice} = pageData.formData;
		if(productPrice){
			if(isNaN(Number(productPrice))){
				ElMessage({
					type: 'warning',
					message: '输入销售金额数不正确',
				})
				pageData.formData.productPrice = '';
				return false;
			}
			pageData.formData.productPrice = Number(productPrice).toFixed(2);
		}
	}
	//消销售金额失去焦点
	function storeCardValueBlur(type){
		var {storeCardValue} = pageData.formData;
		if(storeCardValue){
			if(isNaN(Number(storeCardValue))){
				ElMessage({
					type: 'warning',
					message: '输入储值卡面额数不正确',
				})
				pageData.formData.storeCardValue = '';
				return false;
			}
			pageData.formData.storeCardValue = Number(storeCardValue).toFixed(2);
		}
	}
	
	//上传封面
	function uploadChange(url){
		pageData.cover.push(url);
	}
	// 删除封面
	function deleCover(){
		pageData.cover.splice(2,1);
	}
	onMounted(()=>{
		instance = new WangEditor(editor.value);
		instance.config.customUploadImg = function (resultFiles, insertImgFn) {
			wangEditorUpload({fileData:resultFiles[0]},(res)=>{
				if(res.data.code == 0){
					insertImgFn(res.data.data.wholeUrl);
				}else{
					ElMessage({
						type: 'warning',
						message: '上传失败',
					})
				}
			});
		}
		Object.assign(instance.config, {
			height:300,
			zIndex:100,
		});
		instance.create();
    //编辑
    if(id){
      // pageData.loading = true;
      productInfo({
        encryptionId:id
      }).then((res)=>{
        if(res.code == 0){
          // pageData.loading = false;
          for(var key in res.data){
            pageData.formData[key] = res.data[key];
          }
          pageData.formData.consumeType = res.data.consumeType.split(',').map(n=>Number(n));
          pageData.formData.sellType = res.data.sellType.split(',');
          var coverIndex = '2';
          pageData.cover.map((n,i)=>{
            if(n == res.data.productCover){
              coverIndex = i+'';
            }
          });
          if(coverIndex == 2){ //证明是自定义
            pageData.cover.push(res.data.productCover);
          }
          pageData.coverCheck = coverIndex;
          instance.txt.html(res.data.productContent);
        }
      })
    }
	})
	onBeforeUnmount(() => {
	  instance.destroy();
	  instance = null;
	 });
</script>

<style scoped lang="stylus">
	.update_course_card{
		.step_box{
			background-color #FFFAE7;
			li{
				position relative;
				.step_icon{
					width 32px;
					height 32px;
					line-height 32px;
					text-align center;
					border-radius 100%;
					border 1px solid #909399;
					color #909399;
				}
				.step_icon.active{
					color white;
					background-color #409EFF;
					border-color #409EFF;
				}
				.step_right{
					position absolute;
					right 0;
					top 0;
				}
			}
		}
		.table_list{
			table{
				width 100%;
				border-bottom 1px solid #ccc;
				thead{
					tr:first-child{
						background-color #F4F9FF;
						color #999;
					}
					div{
						padding 10px 0;
					}
				}
				tbody{
					tr:nth-child(even){
						background-color #fafafa;
					}
				}
			}
		}
		.cover_img{
			position relative;
			.cover_dele{
				position absolute;
				left 0;
				top 0;
				bottom 0;
				right 0;
				background-color rgba(0,0,0,.3);
				color white;
				text-align center;
				cursor pointer;
				display none;
				line-height 80px;
			}
		}
		.cover_img:hover .cover_dele{
			display block;
		}
	}
	.select-name /deep/ .el-input-group__append{
	    padding:0 !important;
		width: 62px
	}
</style>
