<template>
    <workArea :barlist="barlist">
        <div class="p20 pt10">
            <el-form :inline="true" :model="pageData.formInline" class="demo-form-inline">
                <el-form-item v-if="TYPE == 2">
                    <el-select v-model="pageData.formInline.cycleIds" clearable>
                        <el-option label="全部班级" value="" />
                        <el-option v-for="(item, index) in pageData.cycleList" :key="index" :label="item.label" :value="item.value" />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="pageData.formInline.courseNameIds" clearable>
                        <el-option label="全部课程" value="" />
                        <el-option v-for="(item, index) in pageData.courseNameList" :key="index" :label="item.label" :value="item.value" />
                    </el-select>
                </el-form-item>
                <el-form-item  v-if="TYPE == 1">
                    <el-select v-model="pageData.formInline.courseType" clearable>
                        <el-option label="全部课程类型" value="" />
                        <el-option label="团体课" value="11" />
                        <el-option label="小班课" value="13" />
                        <el-option label="私教1v1" value="14" />
                        <el-option label="私教1v多" value="15" />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="searchGetList" :icon="Search">搜索</el-button>
                </el-form-item>
            </el-form>
            <el-table class="ww100" ref="subscribeTable" :data="pageData.signInList" stripe v-loading="pageData.loading">
                <el-table-column prop="classTimeStr" fixed label="上课时间" width="230">
                    <template #default="scope">
                        <span>{{ scope.row.classTimeStr }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="courseName" label="课程名称" width="200">
			    	<template #default="scope">
			    		<span>{{ scope.row.courseName }}</span>
			    		<span class="pl5 pr5 ml5" v-if="scope.row.ifMakeUpCourse == 1" :style="{ color: '#FFF',backgroundColor:'#F96667',border:'1px solid #F96667' }">补课</span>
			    	</template>
			    </el-table-column>
                <el-table-column label="课节类型" v-if="TYPE == 1">
                    <template #default="scope">
                        <span class="d_i_b bdr2 pl5 pr5" :style="{color: courseType[scope.row.courseType].color,backgroundColor:courseType[scope.row.courseType].color+'30',border:'1px solid '+courseType[scope.row.courseType].color}">{{courseType[scope.row.courseType].text }}</span>
                    </template>
                </el-table-column>
                <el-table-column v-if="TYPE == 2" prop="cycleName" label="所属班级" />
                <el-table-column prop="courseCustomName" label="课节主题" />
                <el-table-column prop="courseTeacherName" label="上课教练" />
                <el-table-column prop="confirmClassNum" label="预约人数" />
                <el-table-column prop="confirmClassCourse" label="签到人数" />
                <el-table-column  v-if="TYPE == 2" prop="leaveCount" label="请假人数" />
                <el-table-column prop="consumeMoney" label="消课金额（元）" />
                <el-table-column label="操作" >
                <template #default="scope">
					<a href="javascript:;" class="color-409EFF t_d_u" @click="details(scope.row)">查看详情</a>
				</template>
            </el-table-column>
                <template #empty>
                    <el-empty description="暂无数据"></el-empty>
                </template>
            </el-table>
            <div class="mt10 rx-bc" v-show="pageData.signInList.length >= 0">
                <div>
                    <span class="mr20">已签到人数：<span class="color-red mr5">{{ pageData.remark.confirmClassCourseAll }}</span>人</span>
                    <span>消课金额总计：<span class="color-red mr5">{{ pageData.remark.consumeMoneyAll  }}</span>元</span>
                </div>
                <el-pagination
                    v-model:currentPage="pageData.signInForm.page"
                    :page-sizes="[15,20,30,50,100]"
                    :page-size="pageData.signInForm.limit"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageData.signInForm.totalCount"
                    @size-change="pageSizeChange"
                    @current-change="()=>{getList()}"
                >
                </el-pagination>
            </div>
        </div>
        <ClassDialog v-model="pageData.addShow" :sid="pageData.coachId" :enId="pageData.enId" :type="TYPE" @close="handleClose"/>
    </workArea>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import workArea from '@/components/branch-store/workArea.vue';
    import ClassDialog from '@/views/branch-store/work/consume/classDialog.vue'; // 弹框
    import { useStore } from 'vuex'
    import { useRouter } from 'vue-router'
    import { Search } from '@element-plus/icons-vue';
    import { list4TeacherInfo, listSimple } from '@/api/consume.js'
    import { courseCycleList } from '@/api/course.js'
    import utils from '@/common/utils.js'
    
    const barlist = reactive([{ name : '返回', type: 0},{ name : '有效课节明细' }]); // 面包屑
    const router = useRouter();
    const store = useStore();
    const encryptionId = router.currentRoute.value.query.id;
    const timeIn = router.currentRoute.value.query.timeIn;
    const classTimeStr = router.currentRoute.value.query.classTimeStr
    const TYPE = router.currentRoute.value.query.type;

    const pageData = reactive({
        loading: false,
        signInForm: {
            page : 1,
            limit : 15,
            totalCount : 0
        },
        signInList:[], // 签到列表数据
        addShow: false,
        coachId:'', // 教练id
        enId: '', // 课节id
        remark: {},
        formInline: {
            cycleIds: '',
            courseNameIds: '',
            courseType: ''
        },
        courseNameList: [],
        cycleList: []
    })
    /**
	 * 获取全部课程列表
	 */
	function getClassList() {
		let params = {
			page: 1,
			limit: 9999,
            courseType: TYPE == 2 ? 13 : ''
		}
		listSimple(params).then((res) => {
			if (res.code == 0) {
				res.data.forEach((item) => {
                    pageData.courseNameList.push({
						label: item.courseName,
						value: item.courseNameId +''
					})
				})
			}
		})
	}
     /**
     * 班级列表
     */
    courseCycleList({ page: 1, limit: 99999 }).then(res => {
        if (res.code == 0) {
            var typeArray = [];
            for(var i=0;i<res.data.length;i++){
			    typeArray.push({
			        value : res.data[i]['id'],
			        label : res.data[i]['cycleName'] 
			    });
			}
            pageData.cycleList = typeArray
        }
    })
     //课程类型
	const courseType = {
		11:{
			text:'团体课',
			color:'#FA7D29'
		},
		12:{
			text:'私教课',
			color:'#3FC9FF'
		},
		14:{
			text:'私教1v1',
			color:'#3FC9FF'
		},
		15:{
			text:'私教1v多',
			color:'#3FC9FF'
		},
		13:{
			text:'小班课',
			color:'#9476FF'
		},
	}
    const details=(row) => {
        pageData.coachId = row.encryptionTeacherId
        pageData.enId = row.encryptionId
        pageData.addShow = true
    }
    //切换分页数量
	function pageSizeChange(limit){
		pageData.signInForm.limit = limit;
		getList();
	}
	function searchGetList() {
	   pageData.signInForm.page = 1;
       getList()
	}
    /**
     * 获取列表
     */
    const getList=() => {
       pageData.loading = true
		let params = {}
		Object.assign(params, pageData.formInline)
		params.limit = pageData.signInForm.limit
		params.page = pageData.signInForm.page
        params.encryptionTeacherId = encryptionId
        params.timeIn = timeIn
        params.classTimeStr = classTimeStr
        if (TYPE == 2) {
            params.courseType = 13
        }
        list4TeacherInfo(params).then((res) => {
            pageData.loading = false
            if (res.code == 0) {
                pageData.signInList = res.data
                pageData.signInForm.totalCount = res.count
                pageData.remark = res.remark
            }
        }).catch(() => {
            pageData.loading = false
        })
    }
    /**
     * 关闭弹框
     */
    const handleClose=() => {
        // pageData.coachId = row
        pageData.addShow = false
    }
    onMounted(() => {
        getList()
        getClassList()
    })
</script>

<style type="text/css" scoped>
    .courseType{
		display: inline-block;
		background-color: #D5F0FB;
		border: 1px solid #6CD5FD;
		color: #6CD5FD;
		border-radius: 2px;
	}
	.box-FA7D29{
		border: 1px solid #FA7D29;
		color: #FA7D29;
		background-color: #FEE5D4;
	}
	.box-9476FF{
		border: 1px solid #9476FF;
		color: #9476FF;
		background-color: #EAE4FF;
	}
	.box-3FC9FF{
		border: 1px solid #3FC9FF;
		color: #3FC9FF;
		background-color: #D9F4FF;
	}
</style>