<template>
	<workArea :barlist="info.barlist">
	<div class="bg-fff hh100 o_a p25" v-loading="info.loading">
		<div class="rx-sc mb12">
			<img class="w240 h160 mr20 bdr8" :src="info.productCover" alt="">
			<div class="flex1">
				<div class="rx-sc mb12">
					<span v-if="info.type !=3" class="mr10 pl5 pr5" :class="info.productTyp == 1 ? 'box-FF6400' :'box-44D7B6'">{{info.productTyp == 1 ? '次卡' : '期限卡'}}</span>
					<b class="ft16 color-47545C">{{info.productName}}</b>
					<span v-if="info.type == 3" class="ml10 pl5 pr5" :class="info.productTyp == 1 ? 'box-FF6400' :'box-44D7B6'">储值卡</span>
				</div>
				<div class="rx-sc pl10 pt10 pb10 bg-FFF5ED mb12">
					<span class="color-666 fw400">售价：</span>
					<b v-if="info.type !=3" class="color-red">
						<span class="ft24">￥{{ info.type == 4 ? info.experiencePrice : info.productPrice || '-'}}</span>
						<span>/{{info.productTyp == 1 ? info.productNum+'次' : ( info.productCycle+ (info.ifMonth == 1 ?'天':info.ifMonth == 2 ?'月':'年'))}}</span>
					</b>
					<b v-else class="color-red">
						<span class="ft24">￥{{info.productPrice || '-'}}</span>
					</b>
				</div>
				<div class="color-333 mb12">
					<span class="v_a_m">{{ info.type == 2 ? '可入场门店' : '可消费门店' }}：{{info.consumeStoreName}}</span> 
					<el-tooltip
    				  	class="box-item ml5"
    				  	effect="dark"
    				  	content="会员可使用本卡跨店消费"
    				  	placement="right"
						v-if="info.type != 3"
    				>
    			   	 <img class="w15 h15 ml5 v_a_m" src="@/assets/wenhao.png" >
    			  </el-tooltip>
				</div>
				<div class="rx-sc">
					<div class="mb12 color-333 mr50" v-if="info.type == 3">储值金额：<span>{{info.storeCardValue}} 元</span></div>
					<div class="color-333 mb12">可消费时长：{{ info.productCycle}}{{ info.ifMonth == 1 ?'天 ':info.ifMonth == 2 ?'月 ':info.ifMonth == 3 ? '年 ' : '不限时 '}} {{ info.productTimeType == 1 ? ' 从购买之日起算' : ' 第一次消费起算' }}</div>
				</div>
			</div>
		</div>
		<CommonTitle text="售卖信息"></CommonTitle>
		<div class="mb12 color-333 pl20">售卖门店：{{info.sellStoreName}}</div>
		<div class="rx-sc">
			<div class="mb12 color-333 pl20 mr50">售卖方式：<span>{{info.mode}}</span></div>
			<div class="mb12 color-333 pl20" v-show="info.type == 4">购买限制：<span>{{info.buyUserType == 1 ? '仅限新客' : info.buyUserType == 2 ? '仅会员' :'全部人'}}</span></div>
		</div>
		<div class="mb12 color-333 pl20" v-if="info.type == 3">扣费顺序：<span>优先扣除本金</span></div>
		<CommonTitle text="绑定课程信息" v-show="info.type == 1 || info.type == 4"></CommonTitle>
		<div v-show="info.type == 1 || info.type == 4">
			<el-table :data="info.courseList" stripe style="width: 100%" max-height="300">
			    <el-table-column prop="courseName" label="课程名称" width="300"/>
			    <el-table-column prop="" label="课程种类" width="200" >
					<template #default="scope">
						<span class="pl5 pr5 br-2px" :class="{'box-FA7D29':scope.row.courseType == 11,'box-9476FF':scope.row.courseType == 12}">{{scope.row.courseType == 11 ? '团体课' :scope.row.courseType == 12 ? '私教课' : scope.row.courseType == 14 ?'私教1v1':scope.row.courseType == 15?'私教1v多':''}}</span>
					</template>
				</el-table-column>
			    <el-table-column prop="consumeStoreName" label="上课门店" width="300"/>
			    <el-table-column prop="consumeNum" label="每节课扣除次数" />
			</el-table>
		</div>
		<div v-if="info.type == 3">
			<CommonTitle text="消费类目"></CommonTitle>
			<div class="rx-c pl20 pr20">
				<div class="category" v-for="item in info.categoryList" :key="item.id">
					<img class="w15 v_a_m" src="@/assets/li_icon.png" alt="">
					<span class="color-333 ml5 v_a_m">{{item.name}}</span>
				</div>
      		</div>
		</div>
		<CommonTitle text="其他信息"></CommonTitle>
		<div class="rx-ss">
			<div class="mb12 color-333 pl20">{{info.type == 1 ? '课程卡' :info.type == 2 ? '入场卡' :info.type == 3 ? '储值卡' :'体验卡'}}介绍：</div>
			<div class="flex1" v-html="info.productContent"></div>
		</div>
	</div>
	</workArea>
</template>

<script setup>
	import {reactive} from 'vue';
	import {useRouter} from 'vue-router';
  	import {courseNexus, productInfo} from '@/api/cards.js'
	import CommonTitle from '@/components/CommonTitle.vue';
	import workArea from '@/components/branch-store/workArea.vue';
	const router = useRouter();
	const id = router.currentRoute.value.query.id
	const consumeType = router.currentRoute.value.query.consumeType
	const type = router.currentRoute.value.query.type
	const info = reactive({
		type:'',
		productTyp:0,
		productName:'',
		ifMonth:'',
		productNum:'',
		productCycle:'',
		productPrice:'',
		experiencePrice: '',
		storeName:'',
		sellStoreName:'',
		consumeStoreName:'',
		productCover:'',
		productContent:'',
		sellType:'',
		buyUserType:'',
		courseList:[],
		productTimeType:1,
		loading:false,
		mode: '',
		storeCardValue: 0,
		categoryList:[
    	  { id: 1, name: '预约单节团体课'},
    	  { id: 2, name: '预约单节私教课'},
    	  { id: 3, name: '购买私教课'},
    	  { id: 4, name: '购买团课'},
    	  { id: 5, name: '购买卡项'},
    	  // {id:6,name:'购买商城商品'},
    	  {id:7,name:'预约场地'},
	  	  {id:8,name:'购买班级'}
    	],
	})
	// 获取卡详情
  info.loading = true;
	productInfo({
		encryptionId:id
	}).then((res)=>{
		if(res.code == 0){
			info.loading = false;
			for(var key in info){
				if (key != 'categoryList') {
					info[key] = res.data[key]
				}
			}
			let arry = []
			if (info.sellType.indexOf('1') > -1) {
				arry.push('会员端售卖')
			}
			if (info.sellType.indexOf('2') > -1) {
				arry.push('线下售卖')
			} 
			info.mode = arry.join('&')
			info.trainPurposeName = res.data.trainPurposeName? res.data.trainPurposeName.split(',') : [];
			info.barlist = [{ name : '返回',type:'0' },{ name : '卡项详情' }];
			if(res.data.type == 1 || res.data.type == 4){ //课程卡  体验卡
			// 获取绑课详情
				courseNexus({
				  page:1,
				  limit:99999,
				  encryptionId:id
				}).then((res)=>{
					info.courseList = res.data;
				})
			}
		}
	})
	localStorage.setItem('vipCard', type)

</script>

<style scoped lang="stylus">
	.box-FF6400{
		background-color #FF6400;
		border-radius 100px 100px 100px 0;
		color white;
	}
	.box-44D7B6{
		background-color #44D7B6;
		border-radius 100px 100px 100px 0;
		color white;
	}
	.box-FA7D29{
		border 1px solid #FA7D29;
		color #FA7D29;
		background-color #FEE5D4;
	}
	.box-9476FF{
		border 1px solid #9476FF;
		color #9476FF;
		background-color #EAE4FF;
	}
	.box-3FC9FF{
		border 1px solid #3FC9FF;
		color #3FC9FF;
		background-color #D9F4FF;
	}
	.br-2px{
		border-radius 2px;
	}
	.bg-FFF5ED{
		background-color #FFF5ED;
	}
	.color-47545C{
		color #47545C;
	}
	.trainPurpose{
		background-color #F5F5F5;
	}
	.stateIcon{
		position relative;
	}
	.stateIcon::after{
			content '';
			position absolute;
			left 0;
			top 50%;
			transform translateY(-50%);
			width 8px;
			height 8px;
			border 2px solid #FBE3E3;
			background-color #FE8585;
			border-radius 100%;
		}
	.stateIcon.open::after{
		content '';
		position absolute;
		left 0;
		top 50%;
		transform translateY(-50%);
		width 8px;
		height 8px;
		border 2px solid #DAF7F0;
		background-color #44D7B6;
		border-radius 100%;
	}
	.category {
		padding: 7px 13px;
		background: #F5F5F5;
		border-radius: 3px;
		border: 1px solid #D6D6D6;
		margin: 0 20px 20px 0
	}
</style>
