import {postAjax,getAjax}  from "../common/axios.js"

//登录
export const login = params => {
    return postAjax('/api/system/login',params);
}
//总店登录
export const loginByBusiness = params => {
	return postAjax('/api/system/loginByBusiness',params);
}
//获取扫码登录key
export const getUid = params => {
	return getAjax('/api/nologin/getUid',params);
}
//获取扫码登录状态
export const checkLogin = params => {
	return getAjax('/api/nologin/checkLogin',params);
}
//获取扫码登录状态
export const loginByOpenidPre = params => {
	return postAjax('/api/nologin/loginByOpenidPre',params);
}

//获取菜单
export const getUserMenuListJson = params => {
	return getAjax('/api/menu/getUserMenuListJson',params);
}

//退出登录
export const logout = params => {
	return getAjax('/api/system/logout',params);
}

//申请试用
export const tryUse = params => {
	return postAjax('/api/register/wap/registerInsert', params);
}

//退出登录
export const captchaImage = params => {
	return getAjax('/api/nologin/captcha/captchaImage',params);
}

// 获取手机验证码
export const getTelRandomCode = params => {
	return postAjax('/api/wap/getTelRandomCode',params);
}
