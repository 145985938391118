<template>
    <workArea :barlist="barlist" v-loading="pageData.loading" element-loading-text="正在努力加载中。。。">
        <div class="storeInfo">
            <div class="p15">
                <h1 class="ft16">机构信息</h1>
            </div>
            <el-divider class="mt0 mb0"></el-divider>
            <div class="p15 w800">
                <el-descriptions :column="1" border>
                    <el-descriptions-item label="机构名称" label-class-name="w150">{{ pageData.storeInfo.storeName }}</el-descriptions-item>
                    <el-descriptions-item label="总店名称">{{ pageData.storeInfo.businessName }}</el-descriptions-item>
                    <el-descriptions-item label="店长姓名">{{ pageData.storeInfo.realName }}</el-descriptions-item>
                    <el-descriptions-item label="机构logo">
						<div class="rx-sc">
							<el-avatar :size="100" :src="pageData.storeInfo.logoTag" @error="errorHandler">
								<img :src="pageData.circleUrl"/>
							</el-avatar>
							<CommonUpload @change="uploadChange" :autoCropWidth="375" :autoCropHeight="375" :isCropper="true">
								<el-button type="primary ml30">重新上传</el-button>
							</CommonUpload>
						</div>
                    </el-descriptions-item>
                    <el-descriptions-item label="注册日期">{{ pageData.storeInfo.createTimeString }}</el-descriptions-item>
                    <el-descriptions-item label="截止日期">{{ pageData.storeInfo.deadTimeStr }}</el-descriptions-item>
                    <el-descriptions-item label="机构状态">{{ pageData.storeInfo.storeStatus }}</el-descriptions-item>
                </el-descriptions>
                <div>
                    <div class="rx-bc p15 store-address">
                       <div class="store-addres_text">机构地址</div>
                       <div>
                           <el-button :icon="Edit" @click="edit(pageData.storeInfo)" v-if="utils.getMenuRole(33000) && pageData.storeInfo.provinceName">设置机构地址</el-button>
                           <el-button :icon="Edit" @click="edit(pageData.storeInfo)" v-if="utils.getMenuRole(33001) && !pageData.storeInfo.provinceName">编辑</el-button>
                       </div>
                    </div>
                    <el-descriptions :column="1" border>
                        <el-descriptions-item label="所在地" label-class-name="w150">{{ pageData.storeInfo.provinceName }}&nbsp;{{ pageData.storeInfo.cityName }}&nbsp;{{ pageData.storeInfo.areaName }}</el-descriptions-item>
                        <el-descriptions-item label="详细地址" >{{ pageData.storeInfo.storeAdd }}</el-descriptions-item>
                        <!-- <el-descriptions-item label="坐标导航">{{ pageData.storeInfo.longitude ? '经度' + pageData.storeInfo.longitude : '' }}&nbsp;&nbsp;{{ pageData.storeInfo.latitude ? '纬度' + pageData.storeInfo.latitude : '' }}</el-descriptions-item> -->
                    </el-descriptions>
                </div>
            </div>
        </div>
    </workArea>
    <el-dialog
        v-model="pageData.dialogVisible"
        :title="pageData.title"
        width="600px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="handleClose"
      >
        <div class="p20">
            <el-form 
              :model="ruleForm" 
              :rules="rules"
              ref="refruleForm" 
              label-width="85px" 
            >
                <el-row>
                    <el-col>
                        <el-form-item label="所在区域" prop="city">
                            <div class="w220">
					        	<el-cascader
					        		class="ww100"
					        		v-model="ruleForm.city"
					        	    placeholder="请选择"
					        	    :options="pageData.allAddress"
					        	    clearable 
					        	/>
					        </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="详细地址" prop="storeAdd">
                          <el-input type="text" v-model="ruleForm.storeAdd" placeholder="请输入详细地址"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="经度" prop="longItude">
                          <el-input type="Number" v-model="ruleForm.longItude" placeholder="请输入经度"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="纬度" prop="latIude">
                          <el-input type="Number" v-model="ruleForm.latIude" placeholder="请输入纬度"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col class="ml90 mb10 rx-c">
                        <el-link class="t_d_u" href="https://lbs.qq.com/getPoint/" :underline="false" type="primary" target="_blank" @click="getCoordinate">获取坐标</el-link>
                        <div class="rx-c" v-show="pageData.coordinateShow">
                            <el-input class="mr20" v-model="pageData.coordinate" placeholder="请将复制的坐标粘贴到此处" />
                            <el-button type="primary" @click="coordinateSubmit">确认</el-button>
                        </div>
                        
                    </el-col>
                    <el-col>
                        <el-form-item>
                          <span class="color-999 ft12">教程：点击获取坐标，打开网后输入店铺详细地址，选中地图上正确的位置后将出现一组数字（纬度，经度），即为坐标，可双击鼠标复制并粘贴到此处对应的输入框。</span>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="onSubmit">确 定</el-button>
          </span>
        </template>
    </el-dialog>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
	import { useStore } from 'vuex'
    import workArea from '@/components/branch-store/workArea.vue';
	import CommonUpload from '@/components/CommonUpload.vue';
	import {getLocalStorage} from '@/common/common.js';
    import utils from '@/common/utils.js'
    import { Plus, Edit, Delete } from '@element-plus/icons-vue'
    import { ElMessage, ElMessageBox } from 'element-plus'
    import { getMyLineStore, storeAddAddr, storeUpdAddr, storeDeleteAddr } from '@/api/storeCenter.js';
    import { getAllAddress } from '@/api/applet.js';
	const store = useStore();
    const barlist = reactive([{ name : '首页' },{ name : '机构中心' },{ name : '机构信息' }]); // 面包屑
    const pageData = reactive({
        loading: false,
        circleUrl: 'https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png', // 默认图片，当机构LOGO加载错误时展示
        title: '',  // 弹框标题
        dialogVisible: false, // 弹框显隐
        storeInfo: {},
        allAddress:[],
        coordinateShow: false,
        coordinate: '',
        
    })
    const refruleForm=ref()
    /**
     * 表单字段
     */
    const ruleForm = reactive({
        provinceId: '',
        cityId: '',
        areaId: '',
        storeAdd: '',
        longItude: '',
        latIude: '',
        city:[]
    });
    /**
     * 表单验证规则
     */
    const rules = {
        city: [{ required: true, message: '请选择所在区域', trigger: 'change' }],
        storeAdd: [{ required: true, message: "请输入详细地址", trigger: "blur" }],
        // businessPhone: [{ validator: checkPhone, trigger: "blur" }],
        longItude: [{ required: true, message: "请输入经度", trigger: "blur" }],
        latIude: [{ required: true, message: "请输入纬度", trigger: "blur" }]
    };
    /**
     * 获取机构信息
     */
    const getStoreInfo=() => {
        getMyLineStore().then((res) => {
            if(res.code == 0) {
                pageData.storeInfo = res.data
            }
        })
    }
    /**
     * 获取地区json数据
     */
    const getAddress=() => {
        pageData.loading = true
        getAllAddress().then((res) => {
            pageData.loading = false
            if(res.code == 0){
		    	pageData.allAddress = res.data;
		    }
        }).catch(() => {
            pageData.loading = false
        })
    }
    /**
     * @param (Obj) row 机构地址信息
     * 编辑地址
     */
    const edit=(row)=> {
        pageData.title = '编辑机构地址'
        ruleForm.storeAdd = row.storeAdd
        ruleForm.longItude = row.longitude
        ruleForm.latIude = row.latitude
        ruleForm.city = [row.provinceId, row.cityId, row.areaId]
        pageData.dialogVisible = true
    }
    /**
     * 取消关闭弹框
     */
    const handleClose=() => {
        refruleForm.value.resetFields()
        pageData.dialogVisible = false
    }
    /**
     * 新增编辑地址参数
     */
    const getParams=() => {
        let params = {
            provinceId: ruleForm.city[0],
            cityId: ruleForm.city[1],
            areaId: ruleForm.city[2],
            storeAdd: ruleForm.storeAdd,
            longItude: ruleForm.longItude,
            latItude: ruleForm.latIude,
        }
        return params
    }
    /**
     * 确定更新关闭弹框
     */
    const onSubmit=() => {
        refruleForm.value.validate((valid) => {
            if (valid) {
                let params = getParams()
                storeUpdAddr(params).then((res) => {
                    if (res.code == 0) {
                       getStoreInfo()
                       ElMessage.success('编辑成功')
                    }
                })
                refruleForm.value.resetFields()
                pageData.dialogVisible = false
            } else {
              return false;
            }
        });
    }
    /**
     * 获取坐标
     */
    const getCoordinate=() => {
        pageData.coordinate = ''
        pageData.coordinateShow = true
    }
    /**
     * 提交复制的坐标
     */
    const coordinateSubmit=()=> {
        if (pageData.coordinate) {
            let arry = pageData.coordinate.split(',')
            ruleForm.longItude = arry[1]
            ruleForm.latIude = arry[0]
            
        }
        pageData.coordinateShow = false
    }
	

	//上传封面
	function uploadChange(url) {
		storeUpdAddr({
			sid:pageData.storeInfo.id,
			logoTag:url,
		}).then((res)=>{
			if(res.code == 0){
				ElMessage.success('上传成功')
				pageData.storeInfo.logoTag = url;
				store.state.storeLogo.value = url;
				var userInfo = getLocalStorage('businessUserData');
				userInfo.logoUrl = url;
				localStorage.setItem('businessUserData',JSON.stringify(userInfo));
			}
		})
		
	}
    onMounted(() => {
       getStoreInfo()
       getAddress()
    })
</script>

<style scoped>
    .store-address {
        background: #f5f7fa;
        border-left: 1px solid #ebeef5;
        border-right: 1px solid #ebeef5
    }
    .store-addres_text{
        color: #606266;
        font-weight: 700;
    }
    
    .el-row /deep/ input::-webkit-outer-spin-button,.el-row /deep/ input::-webkit-inner-spin-button{
      -webkit-appearance: none !important;
      -moz-appearance: none !important;
      -o-appearance: none !important;
      -ms-appearance: none !important;
      appearance: none !important;
      margin: 0;
    }
    .el-row /deep/ input[type="number"]{
      -webkit-appearance:textfield !important;
      -moz-appearance:textfield !important;
      -o-appearance:textfield !important;
      -ms-appearance:textfield !important;
      appearance:textfield !important;
    }
</style>