<template>
	<div class="post_container" v-loading="pageData.loading">
		<formQuery :queryOpts="pageData.queryOpts" @query="getTableData"></formQuery>
		<div class="rx-sc mb12">
			<el-button  type="primary" @click="addAndupdate('')" v-if="utils.getMenuRole(3310100)">新增岗位</el-button>
		</div>
		<div>
			<el-table empty-text="无数据" :data="pageData.postTable" stripe style="width: 100%">
				<el-table-column prop="roleName" label="岗位名称" >
					<template #default="scope">
						<span v-if="scope.row.createChannel != 3 || !utils.getMenuRole(3310101)">{{scope.row.roleName}}</span>
						<a v-else href="javascript:;" class="color-409EFF tool_btn" @click="addAndupdate(scope.row)">{{scope.row.roleName}}</a>
					</template>
				</el-table-column>
				<el-table-column prop="remark" label="备注" show-overflow-tooltip />
				<el-table-column prop="createChannel" label="发布渠道" >
					<template #default="scope">
						<span>{{scope.row.createChannel == 1 ? '系统默认' : scope.row.createChannel == 2 ? '总店' : '门店'}}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="150">
					<template #default="scope">
						<a v-if="scope.row.createChannel != 3" href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="lookPower(scope.row,false)">查看权限</a>
						<a v-else href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="lookPower(scope.row,true)">菜单权限</a>
						<a v-if="scope.row.createChannel == 3 || !utils.getMenuRole(3310102)" href="javascript:;" class="t_d_u color-F96667 tool_btn_r" @click="delePost(scope.row.encryptionId)">删除</a>
					</template>
				</el-table-column>
			</el-table>
			<div class="mt20 rx-ec">
			    <el-pagination
			      v-model:currentPage="pageData.postSearchForm.page"
			      :page-sizes="[15,20,30,50,100]"
			      :page-size="pageData.postSearchForm.limit"
			      layout="total, sizes, prev, pager, next, jumper"
			      :total="pageData.tableCount"
			      @size-change="pageSizeChange"
			      @current-change="getRoleManage"
			    >
			    </el-pagination>
			</div>
		</div>
		<el-dialog
		    v-model="pageData.roleMenu.show"
		    :title="pageData.roleMenu.title"
			width="500px"
		    :before-close="()=>{pageData.roleMenu.show = false}"
		  >
			<div class="p20 o_a" style="max-height: 650px">
				<el-tree
					ref="treeRef"
				    :data="pageData.treeData"
					default-expand-all
				    :show-checkbox="pageData.isCheck"
					:props="{label:'menuName', id: id, children: children}"
				    node-key="id"
				/>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<div v-show="pageData.isCheck">
						<el-button @click="pageData.roleMenu.show = false">取消</el-button>
						<el-button type="primary" @click="savePower">确定</el-button>
					</div>
				</div>
			</template>
		</el-dialog>
		<!-- 新增/编辑岗位 -->
		<el-dialog
		    v-model="pageData.updatePost.show"
		    :title="pageData.updatePost.title"
			width="400px"
		    :before-close="()=>{pageData.updatePost.show = false}"
		  >
		    <div class="pl30 pt20 pb20">
				<div class="rx-sc mb12">
					<span class="mr10 w65 t_a_r"><span class="color-red">*</span>岗位名称</span>
					<div class="w200">
						<el-input v-model="pageData.updatePost.form.roleName" maxlength="15" placeholder="请输入岗位名称" />
					</div>
				</div>
				<div class="rx-ss mb12">
					<span class="mr10 w65 t_a_r">岗位备注</span>
					<div class="w200">
						<el-input :rows="5" type="textarea" v-model="pageData.updatePost.form.remark" placeholder="请输入岗位备注" />
					</div>
				</div>
				<div class="rx-ss">
					<span class="mr10 w65 t_a_r mt5">授课权限</span>
					<div v-if="nowStoreData.sysVersion == 1" class="w300 rx-wsc">
						<el-checkbox class="w90" v-model="pageData.ifTeaCourse" label="可授班课" />
						<el-checkbox class="w90" v-model="pageData.ifTeaOne" label="可授一对一" />
					</div>
					<div v-if="nowStoreData.sysVersion == 2" class="w300 rx-wsc">
						<el-checkbox class="w90" v-model="pageData.ifRunTeam" label="可授团体课" />
						<el-checkbox class="w90" v-model="pageData.ifRunPrivate" label="可授私教课" />
						<el-checkbox class="w90" v-model="pageData.ifRunCourse" label="可授班课" />
					</div>
				</div>
			</div>
		    <template #footer>
		      <span class="dialog-footer">
		        <el-button @click="pageData.updatePost.show = false">取消</el-button>
		        <el-button type="primary" @click="postSave">确定</el-button>
		      </span>
		    </template>
		</el-dialog>
		<Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="delePostSave"/>
	</div>
</template>
<script setup>
	import { ref, reactive, onMounted, nextTick } from 'vue';
	import { ElMessage, ElMessageBox } from 'element-plus'
	import CommonTip from '@/components/CommonTip.vue'
	import CommonTitle from '@/components/CommonTitle.vue'
	import Confirm from '@/components/Confirm.vue'
	import formQuery from '@/components/branch-store/formQuery.vue';
	import utils from '@/common/utils.js'
	import {roleManageStore,userRoleInsertStore,userRoleUpdate,userRoleDelete,menuToRole,userRoleMenuInsert} from '@/api/post.js';
	import {getLocalStorage} from '@/common/common.js';
	const nowStoreData = getLocalStorage('businessNowStoreData');
	const treeRef = ref()
	//组织架构集合
	const pageData = reactive({
		loading:true,
		confirmTitle:'',
		confirmText:'',
		totalCount:0,
		postSearchForm:{ //岗位搜索项
			page:1,
			limit:15,
			roleName:'',
			ifTeaCourse:2,
			createChannel:'0'
		},
		postTable:[], //岗位table数据
		updatePost:{  //新增员工、编辑员工
			show:false,
			title:'新增岗位',
			form:{
				encryptionId:'',
				roleName:'',
				remark:'',
			}
		},
		roleMenu:{   //岗位权限弹窗状态
			title:'设置[大区经理]的岗位权限',
			show:false
		},
		isCheck:false,
		tableCount:0,
		treeData:[] ,//岗位权限树
		ifRunTeam:0, // 运动版，授课权限，团体
		ifRunPrivate:0,// 运动版，授课权限，私教
		ifRunCourse:0,// 运动版，授课权限，班课
		ifTeaOne:0,// 教务版，授课权限，一对一
		ifTeaCourse:0,// 教务版，授课权限，班课
		queryOpts : {
			fields:[{
				type : 'input',
				fieldName : 'roleName',
				placehold : '请输入岗位名称'
			},{
				type : 'select',
				fieldName : 'ifTeaCourse',
				placehold : '全部岗位',
				options : [ 
							{ value : '2' , label : '全部岗位' },
							{ value : '1' , label : '有授课权限' },
							{ value : '0' , label : '无授课权限' },
						],
				value:'2'
			},{
				type : 'select',
				fieldName : 'createChannel',
				placehold : '全部发布渠道',
				options : [ 
							{ value : '0' , label : '全部渠道' },
							{ value : '1' , label : '系统默认' },
							{ value : '2' , label : '总店' },
							{ value : '3' , label : '门店' },
						],
				value:'0'
			}]
		}
	})
	var nowTd = '';
	//筛选表单回调
	function getTableData(options){
		for(var key in options){
			pageData.postSearchForm[key] = options[key];
		}
		searchTable();
	}
	
	//切换分页数量
	function pageSizeChange(limit){
		pageData.postSearchForm.limit = limit;
		searchTable();
	}
	function searchTable(){
		pageData.postSearchForm.page = 1;
		getRoleManage();
	}
	
	//获取岗位
	getRoleManage();
	function getRoleManage(){
		roleManageStore(pageData.postSearchForm).then((res)=>{
			if(res.code == 0){
				pageData.loading = false;
				pageData.postTable = res.data || [];
				pageData.tableCount = res.count;
				
			}
		})
	}
	
	//新增、编辑岗位
	function addAndupdate(row){
		pageData.updatePost.title = row ? '编辑岗位' : '新增岗位';
		pageData.updatePost.form.roleName = row ? row.roleName :'';
		pageData.updatePost.form.remark = row ? row.remark :'';
		pageData.updatePost.form.encryptionId = row ? row.encryptionId : nowStoreData.instId;
		pageData.ifRunTeam = row && row.ifRunTeam == 1 ? true : false;
		pageData.ifRunPrivate = row && row.ifRunPrivate == 1 ? true : false;
		pageData.ifRunCourse = row && row.ifRunCourse == 1 ? true : false;
		pageData.ifTeaOne = row && row.ifTeaOne == 1 ? true : false;
		pageData.ifTeaCourse = row && row.ifTeaCourse == 1 ? true : false;
		pageData.updatePost.show = true;
	}
	//提交新增、编辑岗位
	function postSave(){
		if(!pageData.updatePost.form.roleName){
			ElMessage({
				type: 'warting',
				message: '请输入岗位名称',
			})
			return false;
		}
		pageData.updatePost.form.ifRunTeam = pageData.ifRunTeam ? 1 : 0;
		pageData.updatePost.form.ifRunPrivate = pageData.ifRunPrivate ? 1 : 0;
		pageData.updatePost.form.ifRunCourse = pageData.ifRunCourse ? 1 : 0;
		pageData.updatePost.form.ifTeaOne = pageData.ifTeaOne ? 1 : 0;
		pageData.updatePost.form.ifTeaCourse = pageData.ifTeaCourse ? 1 : 0;
		if(pageData.updatePost.title == '新增岗位'){
			userRoleInsertStore(pageData.updatePost.form).then((res)=>{
				if(res.code == 0){
					ElMessage({
						type: 'success',
						message: '保存成功',
					})
					pageData.updatePost.show = false;
					searchTable();
				}
			})
		}else{
			userRoleUpdate(pageData.updatePost.form).then((res)=>{
				if(res.code == 0){
					ElMessage({
						type: 'success',
						message: '保存成功',
					})
					pageData.updatePost.show = false;
					searchTable();
				}
			})
		}
	}
	const confirmRef = ref();
	//删除岗位
	function delePost(row){
		nowTd = row;
		pageData.confirmTitle = '确认删除该岗位吗？';
		pageData.confirmText = '';
		confirmRef.value.show();
	}
	function delePostSave(){
		userRoleDelete({
			encryptionId:nowTd
		}).then((res)=>{
			if(res.code == 0){
				ElMessage({
					type: 'success',
					message: '删除成功',
				})
				searchTable();
			}
		})
	}
	//岗位权限
	function lookPower(row, isCheck){
		pageData.isCheck = isCheck;
		nowTd = row;
		pageData.treeData = [];
		menuToRole({
			encryptionId:row.encryptionId
		}).then((res)=>{
			if (res.code  == 0) {
				pageData.roleMenu.show = true;
				if(isCheck){
					pageData.treeData = res.data;
					res.remark2.forEach((item) => {
						nextTick(() => {
							var node = treeRef.value.getNode(item)
							if(node.isLeaf){
							 	treeRef.value.setChecked(node, true);
							}
						})  
            		})
				} else {
					if (res.remark2.length) {
						let arr = []
						res.data && res.data.forEach((items) => {
							if ( res.remark2.indexOf(items.id)> -1) {
								let arr1 = []
								items.children && items.children.forEach((obj) => {
									if (res.remark2.indexOf(obj.id) > -1) {
										let arr2 = []
										obj.children && obj.children.forEach((obj1) => {
											if (res.remark2.indexOf(obj1.id) > -1) {
												let arr3 = []
												obj1.children && obj1.children.forEach((obj2) => {
													if (res.remark2.indexOf(obj2.id) > -1) {
														arr3.push(obj2)
													}
												})
												obj1.children = arr3
												arr2.push(obj1)
											}
										})
										obj.children = arr2
										arr1.push(obj)
									}
								})
								items.children = arr1
								arr.push(items)
							}
						})
						pageData.treeData = arr
					}else {
						pageData.treeData = res.data
					}
				}
				pageData.roleMenu.title = (isCheck ? '设置' : '查看') +'【'+row.roleName+'】的岗位权限' ;
				
			}
		})
	}
	//保存权限编辑
	function savePower(){
		let arry = []
			treeRef.value.getCheckedNodes(false, true).forEach((item) => {
				arry.push(item.id)
			})
			if(arry.length == 0){
				ElMessage({
					type: 'warning',
					message: '请选择权限',
				})
				return false;
			}
		userRoleMenuInsert({
			encryptionId:nowTd.encryptionId,
			menu_ids: arry.join(',')
		}).then((res)=>{
			if(res.code == 0){
				ElMessage({
					type: 'success',
					message: '保存成功',
				})
				pageData.roleMenu.show = false;
			}
		})
	}
</script>

<style scoped lang="stylus">
	.post_container{
		.post_tree{
			li{
				border-left 2px dashed #DFEDF3;
				border-bottom 2px dashed #DFEDF3;
				padding 20px 0 10px 10px;
				position relative;
				cursor pointer;
			}
			li.active{
				color #409EFF;
				font-weight bold;
			}
			li:hover{
				color #409EFF;
				font-weight bold;
			}
			li.active::after{
				content '';
				position absolute;
				right -5px;
				top 50%;
				width: 0;
				height: 0;
				border-top 4px solid transparent;
				border-left 8px solid #409EFF;
				border-bottom 4px solid transparent;
			}
		}
		.post_list{
			width 60%;
			border-left 10px solid #F5F5F5;
			border-right 10px solid #F5F5F5;
		}
		.tree_box{
			.tree_item:not(:last-child){
				border-bottom 1px dashed #ccc;
			}
		}
	}
</style>
