<template>
	<div class="pt20">
		<!-- 查询 -->
		<div class="ww100 rx-wsc" ref="queryBox">
			<div class="query_item mb12 rx-c">
				<div class="query_tip rx-ec">课程名称</div>
				<el-input class="query_con" v-model="pageData.searchForm.courseName" placeholder="输入关键字" />
			</div>
			<div class="query_item mb12 rx-c">
				<div class="query_tip rx-ec">课程类型</div>
				<div class="query_con">
					<el-select class="" v-model="pageData.searchForm.courseType" placeholder="请选择">
						<el-option label="全部" value="" selected></el-option>
						<el-option label="团体课" value="11"></el-option>
						<el-option label="私教1V1" value="14"></el-option>
						<el-option label="私教1V多" value="15"></el-option>
						<!-- <el-option label="小班课" value="13"></el-option> -->
					</el-select>
				</div>
			</div>
			<div class="query_item mb12 rx-c">
				<div class="query_tip rx-ec">上课门店</div>
				<div class="query_con">
					<el-select  class="" v-model="pageData.searchForm.storeId" placeholder="请选择">
						<el-option label="全部" value="" selected></el-option>
						<template v-for="item in pageData.storeList" :key="item.id">
							<el-option v-if="item.ifBranchStore == 1" :label="item.storeName" :value="item.id"></el-option>
						</template>
					</el-select>
				</div>
			</div>
			<div class="query_item mb12 rx-c">
				<div class="query_tip rx-ec">发布渠道</div>
				<div class="query_con">
					<el-select class="" v-model="pageData.searchForm.channelSid" placeholder="请选择">
						<el-option label="全部" value="" selected></el-option>
						<el-option v-for="item in pageData.storeList" :key="item.id" :label="item.storeName" :value="item.id"></el-option>
					 </el-select>
				</div>
			</div>
			<el-button class="mb12 ml16" type="primary" @click="searchTable('')" >查询</el-button>
			<div v-show="queryItemShowAll < 2" @click="triggerShowItemAll" class="query_btn_trans mb12 ml16 rx-cc">{{queryItemShowAll == 1 ? "收起":"展开"}}<el-icon class="el-icon--right" :style="{transform: 'rotate('+(queryItemShowAll == 1 ? '-90deg':'90deg')+')'}"><DArrowRight /></el-icon></div>
		</div>
		<!-- 按钮区域 -->
		<div class="ww100 rx-sc">
			<el-button class="mb12" type="primary" @click="()=>{addStaff('')}">新增课程</el-button>
			<!-- <el-button class="query_btn_red mb16 ml16" size="small" @click="deleStaff">批量删除</el-button> -->
		</div>
		<!-- 数据表格 -->
		<div>
			<el-table ref="satffTable" :data="pageData.tableData" stripe style="width: 100%" @selection-change="checkChange">
				<!-- <el-table-column type="selection" width="55" :selectable="(row)=>{return row.userType != 2}" /> -->
			    <el-table-column prop="courseName" label="课程名称" show-overflow-tooltip>
					<template #default="scope">
						<span v-if="scope.row.sid != 0">{{  scope.row.courseName }}</span>
						<a v-else href="javascript:;" class="color-409EFF tool_btn" @click="addStaff(scope.row)">{{scope.row.courseName}}</a>
					</template>
				</el-table-column>
			    <el-table-column prop="courseType" label="课程类型" >
					<template #default="scope">
						<span>{{scope.row.courseType == 11 ? '团体课' :scope.row.courseType == 12 ? '私教课' : scope.row.courseType == 14 ?'私教1v1':scope.row.courseType == 15?'私教1v多':''}}</span>
					</template>
				</el-table-column>
			    <el-table-column prop="courseTime" label="单课时长" />
			    <el-table-column prop="storeName" label="上课门店" show-overflow-tooltip />
			    <el-table-column prop="sellType" label="售卖方式" >
					<template #default="scope">
						<span>{{scope.row.sellType == 1 ? '线下售卖' :'不售卖'}}</span>
					</template>
				</el-table-column>
			    <el-table-column prop="pricingMethodType" label="定价方式" >
					<template #default="scope">
						<span>{{scope.row.sellType == 1 ? scope.row.priceType == 1 ? '统一定价' :'门店自主定价' : '-'}}</span>
					</template>
				</el-table-column>
			    <el-table-column label="课程单价">
					<template #default="scope">
						<span>{{scope.row.sellType == 1 ? (scope.row.coursePrice ? scope.row.coursePrice : '-') : '-'}}</span>
					</template>
				</el-table-column>
			    <el-table-column prop="courseTacher" label="授课教练数" >
					<template #default="scope">
						<span :class="scope.row.courseTeacher ? 'color-409EFF c_p tool_btn' : ''" @click="lookCoach(scope.row)">{{scope.row.courseTeacher ? getTeacherNum(scope.row.courseTeacher) : 0}}</span>
					</template>
				</el-table-column>
			    <el-table-column prop="channelName" label="发布渠道 " />
				<el-table-column label="操作" >
					<template #default="scope">
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="lookCourse(scope.row.courseNameId)">查看详情</a>
					</template>
				</el-table-column>
			</el-table>
			<div class="mt20 rx-ec" v-show="pageData.tableData.length > 0">
			    <el-pagination
			      v-model:currentPage="pageData.searchForm.page"
			      :page-sizes="[15,20,30,50,100]"
			      :page-size="pageData.searchForm.limit"
			      layout="total, sizes, prev, pager, next, jumper"
			      :total="pageData.totalCount"
			      @size-change="pageSizeChange"
			      @current-change="()=>{getList()}"
			    >
			    </el-pagination>
			  </div>
		</div>
		<!-- 查看授课教练 -->
		<el-dialog
		    v-model="pageData.lookCoachShow"
		    :title="pageData.lookCoachTitle"
			width="648px"
		    :before-close="()=>{pageData.lookCoachShow = false}"
		    custom-class="popwechat"
		  >
		  	<div class="p20">
			  	<el-table ref="satffTable" stripe :data="pageData.lookCoachList">
		    	    <el-table-column prop="realName" label="教练名称" />
		    	    <el-table-column prop="ifFullTime" label="教练类型" >
						<template #default="scope">
							<span>{{scope.row.ifFullTime == 1 ? '全职' : scope.row.ifFullTime == 2 ? '兼职':'未知'}}</span>
						</template>
					</el-table-column>
		    	    <el-table-column prop="storeName" label="教练归属" show-overflow-tooltip />
				</el-table>
		  	</div>
		    <template #footer>
    		  <span class="dialog-footer"></span>
    		</template>
		</el-dialog>
	</div>
</template>
<script setup>
	import {ref,reactive,onMounted,onActivated } from 'vue';
	import {useRouter,onBeforeRouteLeave } from 'vue-router';
	import { ElMessage} from 'element-plus'
	import {DArrowRight,Close} from '@element-plus/icons-vue'
	import utils from '@/common/utils.js'
	import Confirm from '@/components/Confirm.vue'
	import {getFrameworkAllList} from '@/api/staff.js'
	import {adminList,queryCourseTeacher} from '@/api/course.js';
	import {useStore} from 'vuex'; 
	const store = useStore();
	const router = useRouter();
	const pageData = reactive({
		tableData:[],
		totalCount : 0,
		searchForm:{
			page:1,
			limit:15,
			storeId:'',
			courseName:'',
			channelSid:'',
			courseType:''
		},
		storeList:[],
		lookCoachList:[],
		lookCoachShow:false,
		lookCoachTitle:'查看教练',
	})
	// 查看详情
	function lookCourse(id){
		router.push({path:'/courseDetail',query:{id}})
	}
	//所属架构搜索下拉框
	getFrameworkAllList().then((res)=>{
		if(res.code == 0){
			pageData.storeList = res.data;
			for(var i=0;i<res.data.length;i++){
				if(res.data[i]['storeName'] == '品牌总店'){
					res.data[i]['id'] = 0 ;
				}
			}
			console.log(res.data)
		}
	})
	
	//搜索table
	function searchTable(){
		pageData.searchForm.page = 1;
		getList();
	}
	//切换分页数量
	function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		searchTable();
	}
	getList();
	function getList(){
		adminList(pageData.searchForm).then((res)=>{
			if(res.code == 0){
				pageData.tableData = res.data;
				pageData.totalCount = res.count;
			}
		})
	}
	
	// 新增-编辑
	function addStaff(row){
		var query = row ? {id:row.courseNameId,eId:row.encryptionId} : {};
		router.push({path:'/addUpdateCourse',query});
	}
	
	function getTeacherNum(text){
		var list = text.split(',');
		return list.length;
	}
	//查看教练数
	function lookCoach(row){
		if(row.courseTeacher){
			queryCourseTeacher({
				courseTeacher:row.courseTeacher
			}).then((res)=>{
				if(res.code == 0){
					pageData.lookCoachList = res.data;
					pageData.lookCoachTitle = row.courseName+'-授课教练';
					pageData.lookCoachShow = true;
				}
			})
		}
	}
	// 控制查询区域显示隐藏
	var queryBox = ref(null)
	var queryItemShowAll = ref(9);
	// 触发隐藏和展开
	function triggerShowItemAll(){
		for(var i=queryBox.value.children.length - 1;i>0;i--){
			if(queryBox.value.children[i].className.indexOf('query_item') > -1){
				queryBox.value.children[i].style.display= queryItemShowAll.value == 1 ? "none" : "";
			}
			if(queryItemShowAll.value == 1 && queryBox.value.offsetHeight < 60){
				break;
			}
		}
		queryItemShowAll.value = queryItemShowAll.value == 1 ? 0 : 1;
	}
	onMounted(()=>{
		utils.initQuery(queryItemShowAll,queryBox);
	});
	
	// 初始化页面
	onActivated(()=>{
	    getList();
	})
	
	// 页面缓存
	onBeforeRouteLeave((to, from)=>{
	    if (to.path === '/addUpdateCourse' || to.path === '/courseDetail') {
	        store.commit('SetKeep',{keepLiveRoute : ['MainCards']});
	    } else {
	        store.commit('SetKeep',{keepLiveRoute : []});
	    }
	})
</script>

<style scoped lang="stylus">
	@media screen and (max-width: 1366px) {
	    .h400{
			height 300px !important;
		}
	}
.el-dialog__body{padding:0px !important;}
	.shuttle_box{
		border 1px solid #EBEEF5;
		border-radius 4px;
		.add_title{
			background-color #F4F9FF;
			padding 10px;
			text-align center;
		}
		.add_checked{
			width 100%;
			padding 20px;
			li{
				background-color #F5F5F5;
				border-radius 2px;
				border 1px solid #D6D6D6;
				cursor pointer;
				padding 5px 10px;
			}
			li:hover{
				border 1px solid #409EFF;
				i{
					color red;
				}
			}
		}
		
	}
</style>
