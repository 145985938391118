<!-- 课节详情页面 -->
<template>
    <workArea :barlist="barlist">
        <div class="ww100 h200">
            <div class="rx-bc p20">
                <div class="rx-c">
                    <img class="w70 h70 mr5" :src="require('@/assets/courseDetailAvatar.png')" alt="">
                    <h4 class="mr10">{{ pageData.form.time }}</h4>
                    <div class="color-red" v-if="pageData.form.ifMakeUpCourse == 1">补课课节</div>
                </div>
                <el-button type="primary" :icon="Edit" @click="edit">编辑</el-button>
            </div>
            <div class="pl100">
                <el-row>
                    <el-col class="mb25" :span="6">课程名称：{{ pageData.form.courseName }}</el-col>
                    <el-col :span="6" v-if="pageData.form.courseType == 13">班级名称：{{ pageData.form.cycleName || '-' }}</el-col>
                    <el-col :span="6" v-if="pageData.form.courseType == 13">班级类型：{{ pageData.form.cycleType == 11 ? '固定上课模式' : '自由预约模式' }}</el-col>
                    <el-col :span="6" v-if="pageData.form.courseType == 13">单课时长：{{ pageData.form.courseTime || '-' }}</el-col>
                    <el-col :span="6">上课教练：{{ pageData.form.teacherName }}</el-col>
                    <el-col :span="6" v-if="pageData.form.courseType == 11 || pageData.form.courseType == 13">上课场地：{{ pageData.form.roomName ? pageData.form.roomName : '-' }}</el-col>
                    <el-col v-if="pageData.form.courseType == 12" :span="6">最大上课人数：{{ pageData.form.containNum }}</el-col>
                    <el-col v-if="pageData.form.courseType == 11 || (pageData.form.courseType == 13 && pageData.form.cycleType == 12)" :span="6">容纳人数：{{ pageData.form.allPeopleNum }}</el-col>
                    <el-col v-if="pageData.form.ifFull && (pageData.form.courseType == 11 || (pageData.form.courseType == 13 && pageData.form.cycleType == 12))" :span="6">满员开课：{{ pageData.form.fullNum }}</el-col>
                    <el-col v-if="pageData.form.courseType == 11 && pageData.form.abbreviation" :span="6">上课场地：{{ pageData.form.abbreviation }}</el-col>
                </el-row>
            </div>
        </div>
        <div class="ww100 h10 bg-gray"></div>
        <!-- tab切换 -->
        <div class="pl20 pr20">
            <el-tabs v-model="pageData.tabName">
                <el-tab-pane label="课节详情" name="course"></el-tab-pane>
            </el-tabs>
        </div>
        <div class="mt20 pl20 pr20">
            <h4 class="p10 pl20 pr20 bg-FFB800">
                {{ pageData.form.ifMakeUpCourse == 1 ? '补课' :'预约' }}情况：共
                <span class="ml5 mr5 color-red" v-if="pageData.form.cycleType == 11">{{ pageData.form.leaveStudentNum + pageData.form.confirmClassNum }}</span>
                <span class="ml5 mr5 color-red" v-else>{{ pageData.form.confirmClassNum }}</span>
                
                名会员{{ pageData.form.ifMakeUpCourse == 1 ? '补课' :'预约' }}，已签到&nbsp;{{ pageData.form.confirmClassCourse  || 0 }}&nbsp;人
            </h4>
            <formQuery ref="queryOptsRef" :queryOpts="queryOpts" @query="searchList"></formQuery>
        </div>
        <div v-if="pageData.form.courseType == 11 || pageData.form.courseType == 13 || ((pageData.form.courseType == 12) && pageData.form.containNum > 1 ) " class="ww100 h30 rx-bc">
            <div class="rx-sc">
                <el-button v-if="(pageData.form.courseType == 11 && utils.getMenuRole(3150008)) || (pageData.form.courseType == 12 && utils.getMenuRole(3150109)) || pageData.form.courseType == 13" type="primary" class="ml20" @click="batchSignIn">批量签到</el-button>
                <el-button v-if="(pageData.form.courseType == 11 && utils.getMenuRole(3150009)) || (pageData.form.courseType == 12 && utils.getMenuRole(3150110)) || pageData.form.cycleType == 12" type="primary" @click="addOrder">新增预约</el-button>
                <el-button v-if="(pageData.form.courseType == 11 && utils.getMenuRole(3150010)) || (pageData.form.courseType == 12 && utils.getMenuRole(3150111)) || pageData.form.cycleType == 12" type="danger" plain @click="closeOrder">取消预约</el-button>
            </div>
        </div>
        <div class="ww100 pl20 pr20 pt16 pb12">
            <el-table class="ww100" ref="multipleTableRef" :row-key="getRowId" :data="pageData.query.tableData" stripe @selection-change="checkChange">
                <el-table-column type="selection" fixed :reserve-selection="true" width="55" />
                <el-table-column prop="realName" fixed width="160" label="会员姓名" ></el-table-column>
                <el-table-column prop="telephoneNum"  label="手机号码" width="120" />
                <el-table-column prop="productName"  label="扣费支付方式" width="120" />
                <el-table-column label="预约时间" width="160">
                    <template #default="scope">
                        {{utils.getFormatDate2(scope.row.createTime)}}
                    </template>
                </el-table-column>
                <el-table-column label="考勤状态" width="200">
                    <template #default="scope">
                        <el-button size="small" v-if="scope.row.alreadyCome == 1" type="primary">已签到</el-button>
                        <el-button size="small" v-if="pageData.form.cycleType == 11 && scope.row.ifLeave == 1" type="danger">已请假</el-button>
                        <el-button size="small" v-if="scope.row.alreadyCome == 0 && scope.row.ifLeave == 0" type="success">未签到</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="shortCode" label="本次扣除">
                    <template #default="scope">
                        <div v-if="pageData.form.courseType == 13">
                            <span v-if="scope.row.ifConsume == 1">{{ scope.row.payMethodId == 100 || scope.row.payMethodId == 101 ? scope.row.consumeMoney + '元' : scope.row.consumeNum + '次' }}</span>
                            <span v-else>未消课</span>
                        </div>
                        <div v-else>
                            {{ scope.row.payMethodId == 100 || scope.row.payMethodId == 101 ? scope.row.consumeMoney + '元' : scope.row.consumeNum + '次' }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="预约人" show-overflow-tooltip v-if="pageData.form.courseType != 13">
                    <template #default="scope">
                        {{ scope.row.sysUserName? scope.row.sysUserName : scope.row.relName }}
                    </template>
                </el-table-column>
                <el-table-column prop="orderQuota" label="预约人数" v-if="pageData.form.courseType != 13" />
                <el-table-column prop="remark" show-overflow-tooltip label="备注" min-width="150" v-if="pageData.form.courseType != 13">
					<template #default="scope">
						<div class="rx-sc ww100" v-show="!scope.row.show">
							<span class="mr5 flex1 maxLength">{{scope.row.remark}}</span>
							<el-icon  @click="editRemarkClick(scope.row)"><edit-pen /></el-icon>
						</div>
						<el-input v-show="scope.row.show" maxlength="200" v-model="scope.row.remark" @blur="editRemarkBlur(scope.row)" :autofocus="scope.row.show" placeholder="请输入备注" />
				    </template>
				</el-table-column>
                <el-table-column width="160" fixed="right" label="操作">
                    <template #default="scope">
                        <a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="scope.row.alreadyCome == 1 && ((pageData.form.courseType == 11 && utils.getMenuRole(3150012)) || (pageData.form.courseType == 12 && utils.getMenuRole(3150113)) || (pageData.form.courseType == 13  && scope.row.ifLeave == 0))" @click="signCourse(scope.row, 0)">撤销签到</a>
                        <a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="!scope.row.alreadyCome && ((pageData.form.courseType == 11 && utils.getMenuRole(3150011)) || (pageData.form.courseType == 12 && utils.getMenuRole(3150112)) || (pageData.form.courseType == 13  && scope.row.ifLeave == 0))" @click="signCourse(scope.row, 1)">签到</a>
                        <a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="!scope.row.alreadyCome && ((pageData.form.courseType == 11 && utils.getMenuRole(3150010)) || (pageData.form.courseType == 12 && utils.getMenuRole(3150111))) || (pageData.form.cycleType == 12 && scope.row.ifLeave == 0 && pageData.form.ifMakeUpCourse == 0 && scope.row.alreadyCome == 0)" @click="cancelReservation(scope.row)">取消预约</a>
                        <a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="pageData.form.cycleType == 11 && scope.row.ifLeave == 1 && pageData.form.ifMakeUpCourse == 0 && scope.row.alreadyCome == 0" @click="cancelLeave(scope.row, 0)">撤销请假</a>
                        <a href="javascript:;" class="t_d_u color-red tool_btn" v-if="pageData.form.cycleType == 11 && scope.row.ifLeave == 0 && pageData.form.ifMakeUpCourse == 0 && scope.row.alreadyCome == 0" @click="cancelLeave(scope.row, 1)">请假</a>
                    </template>
                </el-table-column>
            </el-table>
            <div class="mt10 ml20" v-show="pageData.query.tableData.length >= 0">
                <el-pagination
                  v-model:currentPage="pageData.query.searchForm.page"
                  :page-sizes="[15,20,30,50,100]"
                  :page-size="pageData.query.searchForm.limit"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="pageData.query.totalCount"
                  @size-change="classPageSizeChange"
                  @current-change="getsearchList"
                >
                </el-pagination>
            </div>
        </div>
        
        <el-dialog 
            v-model="pageData.addSubscribe"
            width="980px"
            :title="pageData.title"
            :before-close="closeSubscribe"
        >
            <template #default>
                <div class="pb25 pl40 pr40" v-if="pageData.form.courseType == 11 || pageData.form.courseType == 13 || (pageData.form.courseType == 12 && pageData.title == '新增预约')">
                    <formQuery ref="queryOpts1Ref" :queryOpts="queryOpts1" @query="searchGetList" />
                    <el-table class="ww100" ref="subscribeTable" :row-key="getRowId" :data="pageData.subscribePage.tableData.slice((pageData.subscribePage.searchForm.page-1)*pageData.subscribePage.searchForm.limit, pageData.subscribePage.searchForm.page*pageData.subscribePage.searchForm.limit)" stripe @selection-change="checkSubscribe" height="500px">
                        <el-table-column type="selection" :reserve-selection="true" width="55" />
                        <el-table-column prop="realName" fixed width="160" label="会员姓名" ></el-table-column>
                        <el-table-column prop="telephoneNum"  label="手机号码" />
                        <el-table-column prop="batchCode"  label="扣费支付方式">
                            <template #default="scope">
                                <el-select v-model="scope.row.deduction" value-key="value" :no-data-text="pageData.noDataText" clearable placeholder="请选择扣费方式" @focus="changeDeduction(scope.row)" @change="getDeduction(scope.row)">
                                    <el-option v-for="item in scope.row.AvailableAssetsLsit" :key="item.value" :label="item.label" :value="item" />
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column v-if="pageData.form.courseType == 11" label="预约人数">
                            <template #default="scope">
                                <el-input-number 
                                    v-model="scope.row.bookPeopleNum" 
                                    class="mx-4" :min="1" :max="pageData.orderPeopleNum" 
                                    :step="1" 
                                    :precision="0" 
                                    controls-position="right" 
                                    style="width:95%" 
                                    @change="changeBookPeopleNum(scope.row)"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column label="本次扣除"  v-if="pageData.form.courseType != 13">
                            <template #default="scope">
                                <template v-if="scope.row.deduction">
                                    <div v-if="scope.row.payMethodId == 105">
                                        <span>{{ scope.row.consumeMoney }} 元</span>
                                    </div>
                                    <div v-else>
                                        <span v-if="scope.row.deduction.type == 3">{{ scope.row.deduction.price }} 元</span>
                                        <span v-else>{{ scope.row.deduction.price }} 次</span>
                                    </div>
                                </template>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="mt10 ml20" v-show="pageData.subscribePage.tableData.length >= 0">
                        <el-pagination
                            v-model:currentPage="pageData.subscribePage.searchForm.page"
                            :page-sizes="[15,20,30,50,100]"
                            :page-size="pageData.subscribePage.searchForm.limit"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="pageData.subscribePage.totalCount"
                            @size-change="pageSizeChange"
                            @current-change="getOrderList"
                        >
                        </el-pagination>
                    </div>
                </div>
                <div class="pb25 pl40 pt30 pr40" v-if="pageData.form.courseType == 12 && pageData.title == '编辑课节'">
                    <OrderPrivate v-if="pageData.addSubscribe" ref="orderPrivateRef" :info="pageData.classInfo" @onEditWork="getEmitData"></OrderPrivate>
                </div>
            </template>
            <template #footer>
                <div class="dialog-footer">
                  <el-button @click="closeSubscribe">取 消</el-button>
                  <el-button type="primary" @click="submitSubscribe">确 定</el-button>
                </div>
            </template>
        </el-dialog>
        <EditWork ref="editWorkRef" @close="editWorkClose" :type="pageData.form.courseType" @onEditWork="confirmEditWork"></EditWork>
        <Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave"/>
    </workArea>
</template>

<script setup>
    import { ref, reactive, onMounted, onActivated, nextTick } from 'vue';
    import {onBeforeRouteLeave,onBeforeRouteUpdate } from "vue-router";
    import { useStore } from 'vuex'
    import { useRouter } from 'vue-router';
    import { Search, Edit, Plus ,EditPen} from '@element-plus/icons-vue';
    import { ElMessageBox, ElMessage } from 'element-plus'
    import formQuery from '@/components/branch-store/formQuery.vue';
    import workArea from '@/components/branch-store/workArea.vue';
    import Confirm from '@/components/Confirm.vue'
    import EditWork from '@/components/branch-store/course/editWork.vue'
    import OrderPrivate from '@/components/branch-store/course/orderPrivate.vue'
    import utils from '@/common/utils.js';
    import { courseToEdit, getAvailableAssets, getAppointmentCards, courseInfo, storeBook, storeBookCancel, courseInfoSign, courseInfoLeaveCancel, courseInfoLeave } from '@/api/course.js';
    import { getCourseInfoList,courseInfoRemark, getCourseUnpay } from '@/api/courseInfo.js';
    import { selectStoreMembersSimple, getMemberList, storeBookBatch } from '@/api/member.js';
    import { getBaseInfo } from '@/api/storeCenter.js';
    const queryOptsRef = ref()
    const queryOpts1Ref = ref()
    const multipleTableRef=ref()
    const subscribeTable=ref()
    const confirmRef =ref()
    const editWorkRef = ref()
    const orderPrivateRef = ref()
    const router = useRouter()
    const store = useStore()
    const encryptionId = router.currentRoute.value.query.encryptionId
    const encryptionCourseNameId = router.currentRoute.value.query.encryptionCourseNameId
    const barlist = reactive([{ name : '返回',type:0 },{ name : '课节详情' }]); // 面包屑
    const queryOpts = reactive({});
    const queryOpts1 = reactive({});
    const pageData = reactive({
        title: '',
        classInfo: '',
        noDataText: '', // 下拉框加载提示
        form: {},
        orderPeopleNum: 0, // 没接最多可预约多少人
        addSubscribe: false, // 新增预约弹框
        showEdit: false,
        hasGroup: false,
        courseList: [], // 课程数据
        teacherList: [], // 授课老师数据
        currentRow: {},
        tabName : 'course',
        query : {
            totalCount : 0,
            searchForm : {
                page: 1,
                limit: 15,
                sort: '',
                order: ''
            },
            tableData : []
        },
        subscribePage : {
            totalCount : 0,
            searchForm : {
                page : 1,
                limit : 15
            },
            tableData : []
        },
        multipleSelection: [], // 选中的会员列表数据
        SubscribeSelection: [], // 选中的新增预约数据
        confirmTitle:'',
		confirmText:'',
        dataInfo: {}
    });
    queryOpts['fields'] = [{
        type : 'input',
        fieldName : 'cardCode',
        placehold : '请输入姓名、手机号'
    }];
    queryOpts1['fields'] = [{
        type : 'input',
        fieldName : 'searchKeys',
        placehold : '请输入姓名、手机号'
    }];
    
	var confirmType = '';
    /**
     * @param(Object, Number) row-会员预约设置 Type 0-撤销签到， 1-签到
     * 签到
     */
	var signType = '',signId = '';
    function signCourse(row, type) {
		pageData.confirmTitle = type == 1 ? '是否确认签到？' :'确定要撤销签到吗？';
		pageData.confirmText = '';
		confirmType = 'signCourse';
		confirmRef.value.show();
		signType = type;
		signId = row.encryptionCourseInfoId;
    }
    /**
     * @param(Object, Number) row-会员预约设置 Type 0-撤销请假， 1-请假
     */
    function cancelLeave(row, type) {
        pageData.confirmTitle = type == 1 ? '是否确认请假？' :'确定要撤销请假吗？';
		pageData.confirmText = '';
		confirmType = 'cancelLeave';
		confirmRef.value.show();
		signType = type;
		signId = row.encryptionId;
    }
    /**
     * 获取课节详情
     */
    function getInfo() {
        courseToEdit({encryptionId: encryptionId}).then((res) => {
            if (res.code == 0) {
                pageData.form = res.data
                localStorage.setItem('courseTable', pageData.form.courseType == 11 ? '0' : pageData.form.courseType == 12 ? '1' : '2')
            }
        })
    }
    // 查询数据列表
    function searchList(){
        pageData.query.searchForm.page = 1
        getsearchList()
    }
    //切换分页数量
	function classPageSizeChange(limit){
		pageData.query.searchForm.limit = limit;
		getsearchList();
	}
    /**
     * 会员预约列表
     */
    function getsearchList() {
        let params = {
            encryptionId: encryptionId,
            encryptionCourseNameId: encryptionCourseNameId,
            sort: pageData.query.searchForm.sort,
            order: pageData.query.searchForm.order,
            limit: pageData.query.searchForm.limit,
            page: pageData.query.searchForm.page
        }
		Object.assign(params, queryOptsRef.value.getQueryForm())
        getCourseInfoList(params).then((res) => {
            if (res.code == 0) {
                pageData.query.tableData = res.data
                pageData.form.signCount = res.results.signCount
                pageData.query.totalCount = res.count
            }
        })
    }
    function pageSizeChange(limit){
		pageData.subscribePage.searchForm.limit = limit;
		getOrderList();
	}
	function searchGetList() {
	   pageData.subscribePage.searchForm.page = 1;
       getOrderList()
	}
    /**
     * 新增预约时的会员列表
     */
    function getOrderList(){
        let params = {
            limit: pageData.subscribePage.searchForm.limit,
            page: pageData.subscribePage.searchForm.page
        }
		Object.assign(params, queryOpts1Ref.value.getQueryForm())
        if (pageData.form.courseType == 13) {
            // console.log()
            params.courseNameId = pageData.form.courseNameId
            params.cycleId = pageData.form.cycleId
            params.excludeCourseId = encryptionId
            getMemberList(params).then((res) =>{
                if(res.code == 0){
                    res.data.forEach((item) => {
                        item.bookPeopleNum = 1
                    })
                    pageData.subscribePage.tableData = res.data.map((n)=>{
		    			n.show = false;
		    			return n;
		    		});
                    pageData.subscribePage.totalCount = res.data.length
                }
            });
        } else {
            selectStoreMembersSimple(params).then((res) =>{
                if(res.code == 0){
                    res.data.forEach((item) => {
                        item.bookPeopleNum = 1
                    })
                    pageData.subscribePage.tableData = res.data.map((n)=>{
		    			n.show = false;
		    			return n;
		    		});
                    pageData.subscribePage.totalCount = res.data.length
                }
            });
        }
        
    }
    /**
     * 确定编辑
     */
    function confirmEditWork(){
        getInfo()
    }
    /**
     * 选择扣费方式
     */
    function changeDeduction(row){
        if (pageData.form.courseType != 13) {
            pageData.noDataText = '正在加载中'
            let params = {
                encryptionCardMyId: row.encryptionCardMyId,
                courseNameId: pageData.form.courseNameId
            }
            getAvailableAssets(params).then((res) => {
                if (res.code == 0) {
                    row.AvailableAssetsLsit =  []
                    if (res.data.cardCount > 0) {
                        let obj = {
                            courseNameId: params.courseNameId,
                            encryptionCardMyId: params.encryptionCardMyId,
                            encryptionId: encryptionId,
                        }
                        getAppointmentCards(obj).then((reserve) => {
                            if ( reserve.code == 0)  {
                                reserve.results.list.forEach((item) => {
                                    let params = {
                                        value: item.id,
                                        consumeNum: item.consumeNum,
                                        type: item.type
                                    }
                                    if (item.type == 3) {
                                        if (!item.startTime) {
                                            params.coursunType = '未开始'
                                            params.label = `${item.productName}(未开始计时)`
                                        } else if (utils.expired(item.startTime) > 0) {
                                            params.coursunType = '未开始'
                                            params.label = `${item.productName}(未开始计时)`
                                        } else if (utils.expired(item.endTime+' 23:59:59') <= 0) {
                                            params.coursunType = '已结束'
                                            params.label = `${item.productName}(已结束)`
                                        } else {
                                            params.coursunType = item.storeMoneyGive+item.storeMoneyBase
                                            params.label = `${item.productName}(可用${ item.storeMoneyGive+item.storeMoneyBase }元)`
                                        }
                                    } else {
                                        if (item.productTyp == 3) {
                                            if (!item.startTime) {
                                                params.coursunType = '未开始'
                                                params.label = `${item.productName}(未开始计时)`
                                            } else if (utils.expired(item.startTime) > 0) {
                                                params.coursunType = '未开始'
                                                params.label = `${item.productName}(未开始计时)`
                                            } else if (utils.expired(item.endTime+' 23:59:59') <= 0) {
                                                params.coursunType = '已结束'
                                                params.label = `${item.productName}(已结束)`
                                            } else {
		    									var et = utils.getDateObj(item.endTime).time;
		    									var ntd = utils.getDateObj().text.split(' ')[0];
		    									var nt = utils.getDateObj(ntd).time;
		    									var d = parseInt((et-nt)/24/60/60/1000)+1; //跟进结束时间和当前时间计算剩余天数  加1是今天
                                                params.coursunType = '不限'
                                                params.label = `${item.productName}(余${d}天)`;
                                            }
                                       } else {
                                            if (!item.startTime) {
                                                params.coursunType = '未开始'
                                                params.label = `${item.productName}(未开始计时)`
                                            } else if (utils.expired(item.startTime) > 0) {
                                                params.coursunType = '未开始'
                                                params.label = `${item.productName}(未开始计时)`
                                            } else if (utils.expired(item.endTime+' 23:59:59') <= 0) {
                                                params.coursunType = '已结束'
                                                params.label = `${item.productName}(已结束)`
                                            } else {
                                                params.coursunType = item.productNum
                                                params.label = `${item.productName}(剩余${ item.productNum }次)`
                                            }
                                       }
                                    }
                                    if (res.data.ifOrder && item.type == 3) {
                                        row.AvailableAssetsLsit.push(params)
                                    } else if(item.type != 3) {
                                        row.AvailableAssetsLsit.push(params)
                                    }
                                })
                            }
                        })
                    }
                    if (res.data.courseCount > 0) {
                        row.AvailableAssetsLsit.push({
                            label: pageData.form.courseName+'(剩余'+res.data.courseCount+'节)',
                            value: pageData.form.courseNameId,
                            orderPrice: row.orderPrice,
                            courseTime: row.courseTime,
                            consumeNum: pageData.form.consumeNum,
                            type: '课程'
                        })
                    } else {
                        pageData.noDataText = ''
                    }

                }
            })
        } else {
            row.AvailableAssetsLsit = []
            let obj = {
		    	encryptionCardMyId: row.encryptionCardMyId,
                courseNameId: pageData.form.courseNameId,
		    	cycleId: pageData.form.cycleId
            }
            getAppointmentCards(obj).then((res) => {
                if ( res.code == 0)  {
                    res.results.list.forEach((item) => {
                        if (item.cycleId) {
					    	row.AvailableAssetsLsit.push({
					    	    label: item.productName+'(剩余'+item.productNum+'节)',
					    	    value: item.id,
					    	    type: '班级'
					    	})
					    } else {
					    	row.AvailableAssetsLsit.push({
					    	    label: item.productName+'(剩余'+item.storeMoneyGive+'元)',
					    	    value: item.id,
					    	    type: '班级'
					    	})
					    }
                    })
                }
            })
        }
        
    }
    /**
     * 选择扣费方式
     */
    function getDeduction(row) {
        let params = {
            courseNameId: pageData.form.courseNameId
        }
        courseInfo(params).then((res)=> {
            if (res.code == 0) {
                if (row.deduction.coursunType == '已结束') {
                    ElMessage.warning('课程时间不在扣费商品有效期内')
                }
                if (row.deduction.type == 3) {
                    row.deduction.univalent = res.data.orderPrice
                    row.deduction.price = res.data.orderPrice * row.bookPeopleNum*100/100
                } else if(row.deduction.type == '课程') {
                    row.deduction.price = row.bookPeopleNum
                } else {
                    row.deduction.price = row.deduction.consumeNum * row.bookPeopleNum*100/100
                }
                
            }
        })
    }
    /**
     * 改变预约人数
     */
    function changeBookPeopleNum(row) {
        if (row.deduction) {
            if (row.deduction.type == 3) {
                row.deduction.price = row.deduction.univalent*row.bookPeopleNum
            } else if(row.deduction.type == '课程') {
                row.deduction.price = row.bookPeopleNum
            }else {
                row.deduction.price = row.deduction.consumeNum*row.bookPeopleNum
            }
        }
    }
    function getEmitData(data) {
        if (data) {
            pageData.addSubscribe = false
            getsearchList()
            getInfo()
        }
    }
    /**
     * 拉起编辑课节弹框
     */
    const edit=() => {
        if ( pageData.form.courseType == 11 || pageData.form.courseType == 13 ) {
            editWorkRef.value.getEditInfo(encryptionId)
            editWorkRef.value.show()
        } else if (pageData.form.courseType == 12) {
            pageData.classInfo = {
                courseNameId: pageData.form.courseNameId,
                teacherIds: pageData.form.courseTeacher,
                classTimeEnd: pageData.form.classTimeEnd,
                classTime: pageData.form.classTime,
                encryptionId: encryptionId
            }
            pageData.addSubscribe = true
            pageData.title = '编辑课节'
        }
        
    }
    /**
     * @param(Object) row- 预约详情数据
     * 取消预约
     */
    let rowId = ''
    function cancelReservation(row){
        pageData.confirmTitle = '确定取消预约吗？';
		pageData.confirmText = '会员预约成功时已扣费，取消预约后将归还会员资产！';
		confirmType = 'cancelReservation';
        rowId = row.encryptionCardId
        confirmRef.value.show();
    }
    /**
     * 获取选择会员数据
     */
    const checkChange = (val) => {
        pageData.multipleSelection = val
    }
    /**
     * 获取选择新增预约
     */
    const checkSubscribe = (val) => {
        pageData.SubscribeSelection = val
    }
    /**
     * 表格排序
     */
    const sortChange=(column) => {
        pageData.query.searchForm.sort = column.prop
        pageData.query.searchForm.order = column.order
    }
    /**
     * 拉起新增预约
     */
    const addOrder=() => {
		if((pageData.form.courseType == 12 || pageData.form.cycleType == 12) && (pageData.form.confirmClassNum != 0 && pageData.form.confirmClassNum >= pageData.form.allPeopleNum)){
			ElMessage.warning('课节人数已约满');
			return
		}
        pageData.addSubscribe = true
        pageData.title = '新增预约'
        nextTick(() => {
            getOrderList()
        })
       let params = {
            courseType: 11
        }
        getBaseInfo(params).then((res) => {
            if (res.code == 0) {
                pageData.orderPeopleNum = res.data.orderPeopleNum
            }
        })
    }
    /**
     * 取消新增预约
     */
    const closeSubscribe= () => {
        if ( pageData.title == '编辑课节') {
            orderPrivateRef.value.closeForm()
        } else {
            subscribeTable.value.clearSelection()
        }
        pageData.addSubscribe = false
    }
    /**
     * 确定新增预约
     */
	var subscribes = '';
    const submitSubscribe= () => {
        if ( pageData.title == '编辑课节' ) {
            orderPrivateRef.value.formValidate()

        } else {
            var len = pageData.SubscribeSelection.length;
            if (!len) {
                ElMessage.warning('请先选择需要预约的会员')
                return
            }
            let object = []
            for (var i= 0; i< len; i++) {
                if (!pageData.SubscribeSelection[i].deduction) {
                    ElMessage.warning('请先选择扣费支付方式')
                    return
                }
                object.push(pageData.SubscribeSelection[i])
            }
            subscribes = object;
		    var num = 0;
		    object.map((n)=>{
		    	num+=Number(n.bookPeopleNum);
		    })
            var num = num + Number(pageData.form.confirmClassNum) - Number(pageData.form.allPeopleNum); //新增人数+已预约人数-容纳人数  判断超出人数
		    if(num > 0){
                getCourseUnpay({courseId:pageData.form.id}).then(res=> {
                    if (res.code == 0) {
                        if (res.data) {
                            pageData.confirmTitle = '添加后该课节人数将超过<span class="color-red"> '+num+' </span>人，是否确认添加？ <span class="ft12 colo-999">（当前课节有会员单次预约后待支付，名额已占用'+ res.data +'人，5分钟超时未支付后将自动释放名额）</span>';
                        } else {
                            pageData.confirmTitle = '添加后该课节人数将超过<span class="color-red"> '+num+' </span>人，是否确认添加？';
                        }
                        confirmType = 'subscribes';
		    	        pageData.confirmText = '';
		    	        confirmRef.value.show();
                    }
                })
		    } else {
                submitSubSave()
            }
            
        }
    }
	function submitSubSave(){
		for (var i= 0; i< subscribes.length; i++) {
			let params = {
				ifPrivate: pageData.form.courseType == 12 || pageData.form.courseType == 13 ? 1 : 0,
				encryptionCardMyId: subscribes[i].encryptionCardMyId,
                encryptionId: encryptionId,
				type: 1,
				ifRepeat: 1,
				classTime: pageData.form.classTime,
				classTimeEnd: pageData.form.classTimeEnd,
				courseTime: pageData.form.courseTime,
				chargeType: subscribes[i].deduction.type == '课程' ? 2 : 1,
				orderId: subscribes[i].deduction.type == '课程' ? '' : subscribes[i].deduction.value,
				bookPeopleNum: pageData.form.courseType == 12 ? 1 : subscribes[i].bookPeopleNum
			}
            if (pageData.form.courseType == 12) {
                params.courseNameId =  pageData.form.courseNameId
                params.courseTeacher =  pageData.form.courseTeacher
            }
			// if (pageData.form.courseType == 11){
			//     if ((pageData.form.allPeopleNum - pageData.query.totalCount) < subscribes[i].bookPeopleNum)
			// }
            if (pageData.form.courseType != 13) {
                appointment(params, 1)
            } else {
			    let list=[]
			    pageData.SubscribeSelection.forEach(item => {
			    	list.push({
			    		encryptionCardMyId: item.encryptionCardMyId,
			    		chargeType: 4,
			    		orderId: item.deduction.value,
			    		bookPeopleNum: 1
			    	})
			    })
			    let params = {
			    	type: 1,
			    	ifRepeat: 1,
			    	courseIds: pageData.form.courseId,
			    	stuInfoList: list
			    }
			    storeBookBatch(params).then(res => {
			    	if (res.code == 0){
			    		resetMessage({
                            message: '预约成功',
                            type: 'success'
                        })
                        subscribeTable.value.clearSelection()
                        getsearchList()
                        getInfo()
                        pageData.addSubscribe = false
			    	}
			    })
            }
		}
	}
    let messageInstance = null;
    const resetMessage = (options)=>{
      if(messageInstance){
        messageInstance.close()
      }
      messageInstance = ElMessage(options)
    }
    /**
     * @param (Object, Number) data - 请求接口参数， type 1-新增预约， 0-取消预约
     * 新增团体课预约
     */
    function appointment(data, type) {
        if (type == 1) {
            storeBook(data).then((res)=> {
                if (res.code == 0) {
                     resetMessage({
                         message: '预约成功',
                         type: 'success'
                     })
                     subscribeTable.value.clearSelection()
                     getsearchList()
                     getInfo()
                     pageData.addSubscribe = false
                }
            })
        }  else {
            storeBookCancel(data).then((res) => {
				if(res.code == 0){
					resetMessage({
                        message: '取消成功',
                        type: 'success'
                    })
                    getsearchList()
                    getInfo()
				}
			})
        }
        
    }
    /**
     * 批量签到
     */
    const batchSignIn=() => {
        if (!pageData.multipleSelection.length) {
            ElMessage.warning('请先选择需要签到的会员')
            return
        } else {
            ElMessageBox.confirm('确认签到吗?', '签到',{
                confirmButtonText: '确 定',
                cancelButtonText: '取 消',
                type: 'warning',
            }).then(() => {
                let arry = []
                pageData.multipleSelection.forEach((item) => {
                    arry.push(item.encryptionCourseInfoId)
                })
                let params = {
                    ids: arry.join(),
                    alreadyCome: 1,
                    signType: 6
                }
                courseInfoSign(params).then((res) => {
                    if (res.code == 0) {
                        ElMessage.success('操作成功')
                        getsearchList()
                    }
                })
            })
        }
    }
    /**
     * 批量取消预约
     */
    const closeOrder=() => {
        if (!pageData.multipleSelection.length) {
            ElMessage.warning('请选择需要取消的会员')
            return
        }
        for (var i= 0; i< pageData.multipleSelection.length; i++) {
            if (pageData.multipleSelection[i].alreadyCome == 1) {
                ElMessage.warning('已签到会员暂不支持取消')
                return
            }
		}
        pageData.confirmTitle = '确定取消预约吗？';
		pageData.confirmText = '会员预约成功时已扣费，取消预约后将归还会员资产！';
		confirmType = 'closeOrder';
        confirmRef.value.show();
    }
    const confirmSave=() => {
        if(confirmType == 'closeOrder'){
			for (var i= 0; i< pageData.multipleSelection.length; i++) {
				let params = {
					encryptionId: encryptionId,
					encryptionCardMyId: pageData.multipleSelection[i].encryptionCardId,
					type: 3
				}
				appointment(params, 0)
			}
		} else if (confirmType == 'cancelReservation') {
            let params = {
                encryptionId: encryptionId,
                encryptionCardMyId: rowId,
                type: 3
            }
            appointment(params, 0)
        }else if(confirmType == 'signCourse'){
			let params = {
			    ids: signId,
			    alreadyCome: signType,
			    signType: 6
			}
			courseInfoSign(params).then((res) => {
			    if (res.code == 0) {
			        ElMessage.success('操作成功')
			        getsearchList()
			    }
			})
            
		}else if(confirmType == 'cancelLeave'){
			let params = {
			    encryptionId: signId,
			}
            let apiName = ''
            if (signType == 0) {
                apiName = courseInfoLeaveCancel
            } else {
                apiName = courseInfoLeave
            }
			apiName(params).then((res) => {
			    if (res.code == 0) {
			        ElMessage.success('操作成功')
			        getsearchList()
			    }
			})
		}else {//新增预约确定保存
			submitSubSave();
		}

    }
    /**
     * 记住分页标记
     */
    const getRowId=(row) => {
        return row.id
    }
	
	//编辑备注
	var itemInfo = '';
	function editRemarkClick(row){
		row.show = true;
	}
	function editRemarkBlur(row){
		if(row.remark.length > 200){
			ElMessage.warning('备注最大可输入200字符');
			return false;
		}
		courseInfoRemark({
			encryptionId: row.encryptionCourseInfoId,
			remark:row.remark
		}).then((res)=>{
			row.show = false;
			if(res.code == 0){
				ElMessage.success('编辑成功')
			}else{
				row.remark = '';
			}
		})
		
	}
	
	
    onMounted(()=>{
        getInfo()
        getsearchList()    
    })
</script>
<style scoped>
    .bg-FFB800 {
        color: #47545C;
    }
    .title_menu {
        background: #eef9fe;
        padding: 7px 8px;
    }
    .title_content {
        border-left: 3px solid #00a3ff;
        padding-left: 8px;
        padding-top: 2px;
        font-weight: bold;
        padding-bottom: 1px;
    }
    
</style>