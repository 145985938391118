<template>
    <workArea :barlist="barlist">
        <div class="bg-fff pl20 pt10 pr20">
            <el-tabs v-model="pageData.tabNum" @tab-click="handleClick">
                <el-tab-pane label="场地管理" name="0" v-if="utils.getMenuRole(33501)"></el-tab-pane>
                <el-tab-pane label="计费规则" name="1" v-if="utils.getMenuRole(33504)"></el-tab-pane>
                <el-tab-pane label="场地设置" name="2" v-if="utils.getMenuRole(33503)"></el-tab-pane>
            </el-tabs>
            <component :is="components[pageData.tabNum]" :tab="pageData.tabNum"></component>
        </div>
    </workArea>
</template>

<script setup>
    import { reactive, onMounted, onBeforeUnmount } from 'vue';
    import workArea from '@/components/branch-store/workArea.vue';
    import manage from '@/views/branch-store/appstore/place/manage.vue' // 场地管理
    import setting from '@/views/branch-store/appstore/place/setting.vue' // 场地设置
    import billingRules from '@/views/branch-store/appstore/place/billingRules.vue' // 计费规则
    
    import utils from '@/common/utils.js'; 
    const barlist = reactive([{ name : '首页' },{ name : '应用中心' },{ name : '场地预约' }]); // 面包屑
    const components = [manage, billingRules, setting,];
    const pageData = reactive({
        tabNum : '0'
    });
    var menuId = [33501,33504,33503]; //会员有权限tab默认项
    for(var i = 0;i < menuId.length;i++){
    	if(utils.getMenuRole(menuId[i])){
    		pageData.tabNum = i.toString();
    		break;
    	}
    }  
    function handleClick(tab) {
        localStorage.setItem('appstorePlace', tab.props.name)
	}
	onMounted(()=> {
        if (localStorage.getItem('appstorePlace') != null) {
            pageData.tabNum = localStorage.getItem('appstorePlace')
	    }
	})
    onBeforeUnmount(() => {
		localStorage.removeItem('appstorePlace')
	})
</script>
<style type='css/style' scoped>

</style>