<template>
    <div class="mt20">
         <div class="order_nav">
            <span class="d_i_b c_p" :class="pageData.activeName == item.val ? 'active': '' " v-for="(item, index) in pageData.tabeList" :key="index" @click="getContentList(item.val)">{{ item.label }}</span>
        </div>
        <div v-show="pageData.activeName == 0" class="mt20">
            <el-button class="f_r" v-if="utils.getMenuRole(3320301) && pageData.show" type="primary" :icon="Edit" plain @click="edit(0)">编 辑</el-button>
            <el-button class="f_r" v-if="!pageData.show" type="primary" @click="onSubmit(0)">保 存</el-button>
            <div>
                <div class="h30 rx-sc">
                    <div class="ml35 w80 t_a_r">私教课：</div>
                    <el-radio-group v-model="pageData.form.resource" :disabled="pageData.disabled" >
                        <el-radio :label="1">不限制</el-radio>
                        <el-radio :label="2">限制</el-radio>
                    </el-radio-group>
                </div>
                <div class="h30 ml115 mt5">
                    <div v-if="pageData.form.resource == 1"> 会员在私教课当日入场时间不限制 </div>
                    <div v-else>
                        会员在私教课当日最早可在上课时间前 
                        <span v-if="pageData.show">{{ pageData.form.entrance }}</span>
                        <div v-else class="w100 d_i_b">
                            <el-select v-model="pageData.form.entrance">
                                <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                />
                            </el-select>
                        </div>
                        小时入场，提前不可入场 
                    </div>
                </div>
                <div class="h30 rx-sc">
                    <!-- /班 -->
                    <div class="ml35 w80 t_a_r">团体课：</div> 
                    <el-radio-group v-model="pageData.form.resource1" :disabled="pageData.disabled">
                        <el-radio :label="1">不限制</el-radio>
                        <el-radio :label="2">限制</el-radio>
                    </el-radio-group>
                </div>
                <div class="h30 ml115 mt5">
                    <div v-if="pageData.form.resource1 == 1">会员在团体课当日入场时间不限制</div>
                    <div v-else>
                        会员在团体课当日最早可在上课时间前
                        <span v-if="pageData.show">{{ pageData.form.exit }}</span>
                        <div v-else class="w100 d_i_b">
                            <el-select v-model="pageData.form.exit">
                                <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                />
                            </el-select>
                        </div>
                        小时入场，提前不可入场
                    </div>
                </div>
				<div class="h30 rx-sc">
				    <div class="ml35 w80 t_a_r">场地预约：</div> 
				    <el-radio-group v-model="pageData.form.resource2" :disabled="pageData.disabled">
				        <el-radio :label="1">不限制</el-radio>
				        <el-radio :label="2">限制</el-radio>
				    </el-radio-group>
				</div>
				<div class="h30 ml115 mt5">
				    <div v-if="pageData.form.resource2 == 1">会员预约的场地当日入场时间不限制</div>
				    <div v-else>
				        会员预约的场地当日最早可在开始时间前
				        <span v-if="pageData.show">{{ pageData.form.room }}</span>
				        <div v-else class="w100 d_i_b">
				            <el-select v-model="pageData.form.room">
                                <el-option label="0" :value="0" />
				                <el-option
				                    v-for="item in options"
				                    :key="item.value"
				                    :label="item.label"
				                    :value="item.value"
				                />
				            </el-select>
				        </div>
				        小时入场，提前不可入场
				    </div>
				</div>
            </div>
        </div>
        <div v-show="pageData.activeName == 1" class="mt20">
            <el-button class="f_r" v-if="pageData.show1" type="primary" :icon="Edit" plain @click="edit(1)">编 辑</el-button>
            <el-button class="f_r" v-if="!pageData.show1" type="primary" @click="onSubmit(1)">保 存</el-button>
            <div>
                <div class="h30 rx-sc">
                    <div class="ml35 w80 t_a_r">私教课：</div>
                    <el-radio-group v-model="pageData.form1.resource" :disabled="pageData.disabled1" >
                        <el-radio :label="1">不限制</el-radio>
                        <el-radio :label="2">限制</el-radio>
                    </el-radio-group>
                </div>
                <div class="h30 ml115 mt5">
                    <div v-if="pageData.form1.resource == 1"> 会员在私教课当日离场时间不限制 </div>
                    <div v-else>
                        会员在私教课当日最晚需在上课结束时间后
                        <span v-if="pageData.show1">{{ pageData.form1.entrance }}</span>
                        <div v-else class="w100 d_i_b">
                            <el-select v-model="pageData.form1.entrance">
                                <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                />
                            </el-select>
                        </div>
                        小时内离场 
                    </div>
                </div>
                <div class="h30 rx-sc">
                    <!-- /班 -->
                    <div class="ml35 w80 t_a_r">团体课：</div>
                    <el-radio-group v-model="pageData.form1.resource1" :disabled="pageData.disabled1">
                        <el-radio :label="1">不限制</el-radio>
                        <el-radio :label="2">限制</el-radio>
                    </el-radio-group>
                </div>
                <div class="h30 ml115 mt5">
                    <div v-if="pageData.form1.resource1 == 1">会员在团体课当日离场时间不限制</div>
                    <div v-else>
                        会员在团体课当日最晚需在上课结束时间后
                        <span v-if="pageData.show1">{{ pageData.form1.exit }}</span>
                        <div v-else class="w100 d_i_b">
                            <el-select v-model="pageData.form1.exit">
                                <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                />
                            </el-select>
                        </div>
                        小时内离场
                    </div>
                </div>
				<div class="h30 rx-sc">
				    <div class="ml35 w80 t_a_r">场地预约：</div>
				    <el-radio-group v-model="pageData.form1.resource2" :disabled="pageData.disabled1">
				        <el-radio :label="1">不限制</el-radio>
				        <el-radio :label="2">限制</el-radio>
				    </el-radio-group>
				</div>
				<div class="h30 ml115 mt5">
				    <div v-if="pageData.form1.resource2 == 1">会员预约的场地当日离场时间不限制</div>
				    <div v-else>
				        会员预约的场地当日最晚需在结束时间后
				        <span v-if="pageData.show1">{{ pageData.form1.room }}</span>
				        <div v-else class="w100 d_i_b">
				            <el-select v-model="pageData.form1.room">
                                <el-option label="0" :value="0" />
				                <el-option
				                    v-for="item in options"
				                    :key="item.value"
				                    :label="item.label"
				                    :value="item.value"
				                />
				            </el-select>
				        </div>
				        小时内离场
				    </div>
				</div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { reactive, onMounted, onBeforeUnmount } from 'vue';
    import { ElMessage } from 'element-plus'
    import { Edit } from '@element-plus/icons-vue'
    import { getOtherSetting, enterOutSiteSetting } from '@/api/storeCenter.js';
    import utils from '@/common/utils.js'
    const pageData = reactive({
        activeName: 0,
        disabled: true,
        disabled1: true,
        show: true,
        show1: true,
        form:{
            resource: 1,
            resource1: 1,
            resource2: 1,
            entrance: '0.5',
            exit: '0.5',
			room: '0.5'
        },
        form1:{
            resource: 1,
            resource1: 1,
			resource2: 1,
            entrance: '0.5',
            exit: '0.5',
            room: '0.5'
        },
         tabeList: [{
            label: '入场设置',
            val: 0
        }, {
            label: '离场设置',
            val: 1
        }],
        dataInfo: {}
    })
    const options = [
        { value: '0.5', label: '0.5' },
        { value: '1', label: '1' },
        { value: '1.5', label: '1.5' },
        { value: '2', label: '2' },
        { value: '2.5', label: '2.5' },
        { value: '3.5', label: '3.5' },
        { value: '4', label: '4' }
    ]
    //所以设置列表
    getSettingList()
	function getSettingList(){
		getOtherSetting({type: 7}).then((res)=>{
			if(res.code == 0){
				pageData.dataInfo = res.data.systemSetting
                pageData.form.resource = pageData.dataInfo.enterPriCourseHour == 0 ? 1 : 2 // 私教入场
                pageData.form.resource1 = pageData.dataInfo.enterTeamCourseHour == 0 ? 1 : 2 // 团体入场
                pageData.form.resource2 = pageData.dataInfo.enterRoomOrderTimeHour == -1 || !pageData.dataInfo.enterRoomOrderTimeHour ? 1 : 2 // 场地入场
                pageData.form.entrance = pageData.form.resource == 1 ? '' : pageData.dataInfo.enterPriCourseHour
                pageData.form.exit = pageData.form.resource1 == 1 ? '' : pageData.dataInfo.enterTeamCourseHour
                pageData.form.room = pageData.form.resource2 == 1 ? '' : pageData.dataInfo.enterRoomOrderTimeHour
                pageData.form1.resource = pageData.dataInfo.outPriCourseHour == 0 ? 1 : 2 // 私教离场
                pageData.form1.resource1 = pageData.dataInfo.outTeamCourseHour == 0 ? 1 : 2 // 团体离场
                pageData.form1.resource2 = pageData.dataInfo.outRoomOrderTimeHour == -1 || !pageData.dataInfo.outRoomOrderTimeHour ? 1 : 2 // 场地离场
                pageData.form1.entrance = pageData.form1.resource == 1 ? '' : pageData.dataInfo.outPriCourseHour
                pageData.form1.exit = pageData.form1.resource1 == 1 ? '' : pageData.dataInfo.outTeamCourseHour
                pageData.form1.room = pageData.form1.resource2 == 1 ? '' : pageData.dataInfo.outRoomOrderTimeHour				
			}
		})
	}
    /**
     * 编辑
     */
    function edit (type) {
        if (type) {
            pageData.disabled1 = false
            pageData.show1 = false
        } else {
            pageData.show = false
            pageData.disabled = false
        }
    }
    /**
     * 保存
     */
    function onSubmit(type) {
        let params = {
            encryptionId: pageData.dataInfo.encryptionId
        }
        if (type) {
            params.outPriCourseHour = pageData.form1.resource == 1 ? 0 : pageData.form1.entrance;
            params.outTeamCourseHour = pageData.form1.resource1 == 1 ? 0 : pageData.form1.exit;
            params.outRoomOrderTimeHour = pageData.form1.resource2 == 1 ? -1 : pageData.form1.room;
        } else {
            params.enterPriCourseHour = pageData.form.resource == 1 ? 0 : pageData.form.entrance;
            params.enterTeamCourseHour = pageData.form.resource1 == 1 ? 0 : pageData.form.exit;
			params.enterRoomOrderTimeHour = pageData.form.resource2 == 1 ? -1 : pageData.form.room;
        }
        enterOutSiteSetting(params).then((res) => {
            if (res.code == 0) {
                ElMessage.success('设置成功')
                if (type) {
                    pageData.disabled1 = true
                    pageData.show1 = true
                } else {
                    pageData.show = true
                    pageData.disabled = true
                }
                getSettingList()
            }
        })
    }
    /**
     * 设置入离场
     */
    function getContentList(val) { // 切换tab (数据更换)
        pageData.activeName = val
        getInfo(val)
    }
	onMounted(()=> {
        if (localStorage.getItem('entryndExit') != null) {
          pageData.activeName = localStorage.getItem('entryndExit')
	    }
	})
    onBeforeUnmount(() => {
		localStorage.removeItem('entryndExit')
	})
</script>
<style type='css/style' scoped>
.order_nav {
        background: #F2F2F2
    }
.order_nav span{
    padding: 10px 20px 11px 18px;
    color: #666;
}
.order_nav .active {
    background: #03A3FF;
    color: #FFF;
    border-radius: 2px;
}
</style>
