<template> 
    <div>
    <el-dialog
        v-model="pageData.openShow"
        title="批量排期"
        width="900px"
        :before-close="close"
      >
        <div class="ww100 cx-sc">
            <div class="w800 h70 rx-bc">
                <div class="rx-sc">
                    <span>应用日期</span>
                    <div class="data ml10" style="height: 32px">
                        <el-date-picker 
                            class="hh100"
                            format="YYYY-MM-DD" 
                            value-format="YYYY-MM-DD" 
                            v-model="pageData.daterange" 
                            type="daterange" 
                            placeholder="应用日期" 
                            start-placeholder="开始" 
                            end-placeholder="结束"
                            :disabled-date="pageData.disabledDate"
                            :disabled="pageData.edit"
                        />
                    </div>
                </div>
                <div class="rx-ec">
                    <span>授课教练</span>
                    <div class="w200 ml10 mr12">
                        <el-select multiple collapse-tags clearable filterable v-model="pageData.teacherIds" placeholder="请选择教练" :disabled="pageData.edit">
                            <el-option v-for="item in pageData.teacherList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="w800 h600" style="">
                <table class="ww100 weektab" cellpadding="0" cellspacing="0">
                    <tr class="weektr" style="background-color:#00a3ff11">
                        <td class="w70 t_a_c">时间段</td>
                        <div class="rx-wbs">
                            <td class="h50" style="line-height: 50px" v-for="(item, index) in pageData.timerList" :key="index">{{item}}</td>
                        </div>
                        <td class="w70 t_a_c">操作</td>
                    </tr>
                    <tr class="weektr" v-for="line in pageData.lineList" :key="line.id">
                        <td class="w70 t_a_c">{{line.week}}</td>
                        <td class="h75">
                            <div class="w660 rx-cc barbox" @mouseleave="mouseleavebox">
                                <div class="barout cx-cc posi-r" v-for="item,index in line.barList" :key="index">
                                    <div v-show="item.startTimeShow" class="posi-a timeup rx-cc">
                                        {{item.time}}
                                        <!-- <div style="margin-top:2px;font-size:10px;"></div> -->
                                        <div class="arrowup posi-a left0"></div>
                                        <div class="arrowup1 posi-a left0"></div>
                                    </div>
                                    <div class="bar" @mousedown="mousedown(item)" @mouseup="mouseup(item)" @mouseenter="mouseenter(item)" :class="[item.check ? 'bar-on' :'bar-off',item.col == 48 ? 'bar-right':'']" ></div>
                                    <div v-show="item.endTimeShow" class="posi-a timedown rx-cc">
                                        {{item.time}}
                                        <!-- <div style="margin-top:2px;font-size:10px;">{{item.time}}</div> -->
                                        <div class="arrowdown posi-a right0"></div>
                                        <div class="arrowdown1 posi-a right0"></div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="w70 t_a_c">
                            <el-popover
                                placement="right"
                                :width="90"
                                trigger="hover">
                                <div class="w60 cx-sc">
                                    <div class="w60 rx-sc h30">复制到</div>
                                    <div class="h25 rx-cc" v-for="item in pageData.copyToWeek" :key="item">
                                        <el-checkbox :disabled="line.week == item.name" v-model="item.check" :label="item.name"/>
                                    </div>
                                    <button @click="copyToWeek(line.id)" class="query_btn_blue rx-cc mt10" style="height:25px;width:60px;padding:0px;" >确定</button>
                                </div>
                                <template #reference>
                                    <el-icon><CopyDocument></CopyDocument></el-icon>
                                </template>
                            </el-popover>
                            <Delete class="w15 h15 ml10 c_p" @click="clearLine(line.id)"></Delete>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" @click="save">确定</el-button>
          </div>
        </template>
      </el-dialog>
  </div>
</template>

<script setup>
    import {ref,reactive,onMounted} from 'vue';
    import {ElMessage} from  'element-plus';
    import utils from '@/common/utils.js';
    import { CopyDocument,Delete } from '@element-plus/icons-vue';
    import { saveTeacherSchedule } from '@/api/courseInfo.js';
    import { getStoreAccountStaffSimple } from '@/api/staff.js';
    const pageData = reactive({
        lastenterid : '', // 上次进入的id，会触发重复的enter要去重
        edit: false,
        openShow : false,
        daterange : '',// 应用日期
        teacherList : [],
        teacherIds : '',
        pullFlag : 0, // 是否正在拖动
        lineList : [],
        timerList: ['00:00', '06:00', '12:00', '18:00', '24:00'],
        copyToWeek : [{name:"周一"},{name:"周二"},{name:"周三"},{name:"周四"},{name:"周五"},{name:"周六"},{name:"周日"}],
        disabledDate(time) {
          return disDate(time)
        }
    });
    const recept = defineProps({ 
        date      : String,
        teacherId : String
    })
    const disDate = (time) => {
        return time.getTime() < new Date().getTime() - 8.64e7 
    }
    //暴露方法
    defineExpose({
        show,
        edit
    })
    const emit = defineEmits(['close','save']);

    getStoreAccountStaffSimple({teachType: 2, page: 1, limit: 9999}).then((res)=>{
            if(res.code == 0){
                var typeArray = [];
                for(var i=0;i<res.data.length;i++){
                    typeArray.push({
                        value : res.data[i]['id'],
                        label : res.data[i]['realName'] 
                    });
                }
                pageData.teacherList = typeArray
            }
        })
    function show(){
        pageData.openShow = !pageData.openShow;
        setBarList()
        pageData.edit = false
        if (pageData.openShow) {
            pageData.teacherIds = []
            pageData.daterange = []
        }
    }
    function close(){
        show();
    }
    /**
     * 编辑本周时复制
     */
    function edit(row) {
        pageData.edit = true
        pageData.teacherIds = Array.of(row.sysUserId)
        pageData.daterange = [row.dateStart, row.dateEnd]
        let timerArry = [row.week1, row.week2, row.week3,row.week4, row.week5, row.week6, row.week7]
        let list = []
        timerArry.forEach((item) => {
            if (item) {
                let arr = item.split(',')
                let list1 = []
                arr.forEach((obj) => {
                    list1.push(obj)
                })
                list.push(list1)
                
            } else {
                list.push([])
            }
           
        })
        setBarList(list)
    }
    function save() {
        // 整理参数
        var params = {};
        if(!pageData.daterange){
            ElMessage({
                type : 'warning',
                message : '请选择应用日期'
            });
            return;
        }
        params['dateStartStr'] = pageData.daterange[0];
        params['dateEndStr'] = pageData.daterange[1];
        params['sysUserIds'] = pageData.teacherIds.join(',');
        var lineList = pageData.lineList;
        for(var i=0;i<lineList.length;i++){
            var barList = lineList[i]['barList'];
            var timerangArray = [];
            var timestart = '';
            var timeend = '';
            for(var j=0;j<barList.length;j++){
                if(barList[j]['startTimeShow']){
                    timestart = barList[j]['time'];
                }
                if(barList[j]['endTimeShow']){
                    timeend = barList[j]['time'];
                }
                if(timestart && timeend){
                    timerangArray.push(timestart + '-' + timeend);
                    timestart = '';
                    timeend = '';
                }
            }
            params['week'+(i+1)] = timerangArray.join(',');
        }
        params.type = pageData.edit ? 2 : 1
        saveTeacherSchedule(params).then((res)=>{
            if(res.code == 0){
                ElMessage({
                    type : 'success',
                    message : '操作成功'
                });
                close()
                emit('close');
            }
        });
    }
    // 判断是否展示时间
    function showTimeTip(item){
        var lineList = pageData.lineList;
        for(var i=0;i<lineList.length;i++){
            if(lineList[i]['id'] == item['line']){
                var barList = lineList[i]['barList'];
                for(var i=0;i<barList.length;i++){
                    if(i == 0){
                        barList[i]['startTimeShow'] = barList[i]['check'];
                    }
                    if(i > 0){
                        if(!barList[i - 1]['check'] && barList[i]['check']){
                            barList[i]['startTimeShow'] = true;
                        }else{
                            barList[i]['startTimeShow'] = false;
                        }
                    }
                    if(i+1 < barList.length){
                        if(barList[i]['check'] && !barList[i+1]['check']){
                            barList[i]['endTimeShow'] = true;
                        }else{
                            barList[i]['endTimeShow'] = false;
                        }
                    }
                    if(i == barList.length - 1){
                        barList[i]['endTimeShow'] = barList[i]['check'];
                    }
                }
            }
        }

    }
    // 块
    function mousedown(item){
        pageData.pullFlag = true;
        item.check = !item.check; 
        showTimeTip(item);
    }
    // 块
    function mouseup(item){
        pageData.pullFlag = false;
        showTimeTip(item);
    }
    // 块
    function mouseenter(item){
        if(pageData.lastenterid == item.id){
            return;
        }else{
            pageData.lastenterid = item.id;
        }
        if(pageData.pullFlag){
            item.check = !item.check ;
            showTimeTip(item)
        }
    }
    // 条
    function mouseleavebox(){
        pageData.pullFlag = false;
    }
    function setBarList(data){
        var lineList = [];
        var weekObj = {1:'周一',2:'周二',3:'周三',4:'周四',5:'周五',6:'周六',7:'周日'}
        for(var i=1;i<8;i++){
            var line = {
                id : i,
                week : weekObj[i]
            }
            var barList = [];
            for(var j=0;j<49;j++){
                var hour = parseInt(j/2) + '';
                var min = j % 2 == 0 ? '00' : '30';
                hour = hour.length == 1 ? '0' + hour : hour;
                let params ={
                    id : i + '-' + j,
                    line : i,
                    col : j,
                    time : hour + ':' + min
                }
                if (data && data[i-1].length) {
                    data[i-1].forEach((item) => {
                        if (convert(hour, min) >= convert(item.split('-')[0].split(':')[0], item.split('-')[0].split(':')[1]) &&  convert(hour,min) <= convert(item.split('-')[1].split(':')[0], item.split('-')[1].split(':')[1])) {
                            params.check = true
                            if (convert(hour, min) == convert(item.split('-')[0].split(':')[0], item.split('-')[0].split(':')[1])) {
                              params.startTimeShow =true
                            }
                            if (convert(hour,min) == convert(item.split('-')[1].split(':')[0], item.split('-')[1].split(':')[1])) {
                              params.endTimeShow =true
                            }
                            showTimeTip(params)
                        }
                    })   
                    
                }
                barList.push(params);
            }
            line['barList'] = barList;
            lineList.push(line);
        }
        pageData.lineList = lineList;
    }
    /**
     * 小时转分钟
     */
    function convert(hour,minute) {
        return Number(hour)*60 + Number(minute)
    }
    function clearLine(line){
        var barList = pageData.lineList[line-1]['barList'];
        for(var i=0;i<barList.length;i++){
            barList[i]['check'] = false;
            barList[i]['startTimeShow'] = false;
            barList[i]['endTimeShow'] = false;
        }
    }
    function copyToWeek(line){
        var copyToWeekArray = pageData.copyToWeek;
        var line = pageData.lineList[line- 1];
        var fromBarList = line['barList'];
        for(var i=0;i<copyToWeekArray.length;i++){
            if(copyToWeekArray[i]['check']){
                var toBarList = pageData.lineList[i]['barList'];
                for(var j=0;j<toBarList.length;j++){
                    toBarList[j]['check'] = fromBarList[j]['check'];
                    toBarList[j]['startTimeShow'] = fromBarList[j]['startTimeShow'];
                    toBarList[j]['endTimeShow'] = fromBarList[j]['endTimeShow'];
                }
                copyToWeekArray[i]['check'] = false;
            }
        }
    }
</script>

<style type="text/css" scoped>
    .barbox{}
    .barout{width:3%;height:50px;}
    .bar{width:100%;border:1px solid #e0e0e0;border-right:none;height:15px;}
    .bar-right{border-right:1px solid #e0e0e0;}
    .bar-on{background-color: #00a3ff}
    .bar-off{background-color: #f0f0f0}
    .weektab{
        border:1px solid #f0f0f0;
        border-bottom: 0
    }
    .weektab td{
        border-bottom:1px solid #f5f5f5;
    }
    .timeup{
        z-index:1;
        left:-10px;
        bottom:-6px;
        height: 18px;
        width:40px;
        background-color: #f0f0f0;
        border-radius:3px 3px 3px 3px;
        border:1px solid #D6D6D6;
        font-size: 10px;
    }
    .timedown{
        z-index:1;
        right:-10px;
        top:-6px;
        height:18px;
        width:40px;
        background-color: #f0f0f0;
        border-radius:3px 3px 3px 3px;
        border:1px solid #D6D6D6;
        font-size: 10px;
    }
    .arrowup{
        top: -10px;
        left: 5px;
        width:0px;
        height:0px;
        border: 5px solid transparent;
        border-bottom:5px solid #D6D6D6;
    }
    .arrowup1{
        top: -8px;
        left: 6px;
        width:0px;
        height:0px;
        border: 4px solid transparent;
        border-bottom:4px solid #f0f0f0;
    }
    .arrowdown{
        bottom: -10px;
        right: 5px;
        width: 0px;
        height: 0px;
        border: 5px solid transparent;
        border-top: 5px solid #D6D6D6;
    }
    .arrowdown1{
        bottom: -8px;
        right: 6px;
        width: 0px;
        height: 0px;
        border: 4px solid transparent;
        border-top: 4px solid #f0f0f0;
    }
    .data /deep/ .el-date-editor.el-input__inner {
        width: 270px;
    }
</style>