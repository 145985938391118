<template>
	<div class="applet_bind bg-fff hh100">
		<div class="w500 noBind pt50 pb30 pl50 pr50" v-if="bindData.bind != 1">
			<div class="rx-cc">
				<img class="w80" src="@/assets/applet/appletBind.png" >
			</div>
			<div class="rx-cc mt30 mb20">
				<el-button type="primary" @click="getAppletAuthUrl">已注册小程序，立即授权</el-button>
			</div>
			<div class="t_a_c color-666 fwb">
				小程序管理员扫码授权以企业营业执照为主体注册的小程序，授权过程中请勾选所有权限以确保小程序功能完整性。如果您已授权，请耐心等待平台审核，审核时间1-3个工作日。
			</div>
		</div>
		<div v-if="bindData.bind == 1">
			<CommonTitle text="小程序基本信息"></CommonTitle>
			<div class="pl20">
				<div class="rx-sc mb20">
					小程序名称：{{ bindData.store.appName }}
				</div>
				<div class="rx-sc mb20">
					<span>小程序码：</span>
					<img class="w120 h120 ml20 mr20" :src="pageData.imgsrc" alt="">
					<a class="color-409EFF t_d_u tool_btn" target="_blank" :href="pageData.imgsrc">下载小程序</a>
				</div>
			</div>
			<CommonTitle text="关联商户号"></CommonTitle>
			<div class="pl20">
				<div class="mb20">
					请联系平台服务人员开通商户号，以用于线上售卡等的收款账户，
				</div>
				<div class="mb20">
					如已开通商户号，请在【门店管理】列表查看各门店已关联的商户号。
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	const propData = defineProps({
	  bindData:Object
	})
	const emit = defineEmits(['update'])
	import {ref,reactive} from 'vue'
	import { ElMessage} from 'element-plus'
	import {getAuthUrl} from '../../../api/applet.js';
	import CommonTitle from '../../CommonTitle.vue';
	import {commonUpload} from '../../../common/common.js'
	import {config} from '@/common/config.js'
	const token = localStorage.getItem('businessToken');
	const pageData = reactive({
		imgsrc: ''
	})
	if ( propData.bindData.bind == 1 ) {
		pageData.imgsrc = `${location.origin}/api/open/getQrcodeMiniProd?grayCode=keep001&path=pages/home/home&appId=${propData.bindData.store.appId}&access_token=${token}`
	}
	var businessUserData = JSON.parse(localStorage.getItem('businessUserData'));
    var bid = businessUserData.bid;
	function getAppletAuthUrl(){
		getAuthUrl({
			type:1,
            bid:bid
		}).then((res)=>{
			if(res.code == 0){
				window.open(res.results.authUrl);
			}
		})
	}
</script>

<style scoped lang="stylus">
	.applet_bind{
		.noBind{
			border 1px solid #ccc;
			margin 0 auto;
			margin-top 20%;
			transform translateY(-50%)
			margin-bottom 50px;
			border-radius 4px;
		}
		.qrcode{
			border-radius 4px;
			border 1px solid #ccc;
		}
	}
</style>
