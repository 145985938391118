<template>
    <workArea :barlist="appletData.barlist">
        <div class="applet_bind bg-fff hh100">
            <div class="w500 noBind pt50 pb30 pl50 pr50" v-if="appletData.appBindData.bind != 1">
                <div class="rx-cc">
                    <img class="w80" src="@/assets/applet/appletBind.png" >
                </div>
                <div class="rx-cc mt30 mb20">
                    <el-button type="primary" @click="getAppletAuthUrl">已注册公众号，立即授权</el-button>
                </div>
                <div class="t_a_c color-666 fwb">
                    公众号{{appletData.appBindData.bind}}管理员扫码授权以企业营业执照为主体注册的公众号，授权过程中请勾选所有权限以确保公众号功能完整性。如果您已授权，请耐心等待平台审核，审核时间1-3个工作日。
                </div>
            </div>
            <div v-if="appletData.appBindData.bind == 1">
                <CommonTitle text="公众号基本信息"></CommonTitle>
                <div class="pl20">
                    <div class="rx-sc mb20">
                        公众号名称：{{appletData.appBindData.store.appName}}
                    </div>
                 <!--    <div class="rx-sc mb20"> -->
                        <!-- <span>公众号码：</span> -->
                        <!-- <img class="w120 h120 ml20 mr20" :src="`${config.httpUrl}/api/open/getQrcodeMiniProd?grayCode=keep001&path=pages/home/home&appId=${appletData.appBindData.store.appId}&access_token=${token}`" alt=""> -->
                        <!-- <a class="color-409EFF t_d_u" target="_blank" :href="`${config.httpUrl}/api/open/getQrcodeMiniProd?grayCode=keep001&path=pages/home/home&appId=${appletData.appBindData.store.appId}&access_token=${token}`">下载小程序</a> -->
                   <!--  </div> -->
                </div>
                <CommonTitle text="关联商户号"></CommonTitle>
                <div class="pl20">
                    <div class="mb20">
                        请联系平台服务人员开通商户号，以用于线上售卡等的收款账户，
                    </div>
                    <div class="mb20">
                        如已开通商户号，请在【门店管理】列表查看各门店已关联的商户号。
                    </div>
                </div>
            </div>
        </div>
    </workArea>
</template>

<script setup>
    import { ref, reactive, nextTick} from 'vue';
    import {getAuthUrl} from '@/api/applet.js';
    import CommonTitle from '@/components/CommonTitle.vue';
    import {config} from '@/common/config.js';
    import workArea from '@/components/branch-store/workArea.vue';
    import { useStore } from 'vuex';
    import { getStoreMiniBindInfo } from '@/api/applet.js';
    const token = localStorage.getItem('businessToken');
    const appletData = reactive({
        barlist:[{ name : '首页' },{ name : '门店管理' },{ name : '公众号设置' }],
        appBindData : {}
    });
    const store = useStore();
    var businessUserData = JSON.parse(localStorage.getItem('businessUserData'));
    var bid = businessUserData.bid;
    function getAppletAuthUrl(){
        getAuthUrl({
            type:1,
            authType:1,
            bid:bid
        }).then((res)=>{
            if(res.code == 0){
                window.open(res.results.authUrl);
            }
        })
    }
    // //获取小程序绑定状态
    getStoreMiniBindInfo({  access_token:localStorage.getItem('businessToken'),type:1 }).then((res)=>{
            if(res.code == 0 && res.results){
                appletData.appBindData = res.results;
            }
        })

    
    //获取tab对应的配置
    function getConfig(tabNum){
        var newTabNum = tabNum || appletData.tabNum;
        appletData.loading = true;
        appletData.saveStatus = true;
        var storeData = store.state.appletFormData[newTabNum];
        storeData.active = 0; //初始化tab下左侧选中状态
    }
    
</script>
<style scoped lang="stylus">
    .applet_bind{
        .noBind{
            border 1px solid #ccc;
            margin 0 auto;
            margin-top 20%;
            transform translateY(-50%)
            margin-bottom 50px;
            border-radius 4px;
        }
        .qrcode{
            border-radius 4px;
            border 1px solid #ccc;
        }
    }
</style>
