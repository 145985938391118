<template>
    <div class="rx-bc mt20">
        <div class="formSearch">
            <el-form :inline="true" :model="pageData.formInline" class="demo-form-inline">
                <el-form-item>
                    <el-input v-model="pageData.formInline.keywords" placeholder="输入会员姓名、手机号" />
                </el-form-item>
                <el-form-item>
                    <el-select v-model="pageData.formInline.trialFlag">
                        <el-option label="全部会员类型" value="" />
                        <el-option label="正式会员" value="1" />
                        <el-option label="潜在会员" value="2" />
                    </el-select>
                </el-form-item>
                <el-form-item>
                  <el-select v-model="pageData.formInline.timeIn" @change="changeTimeIn">
                    <el-option v-for="(item, index) in dataList" :key="index" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
                <el-form-item v-if="pageData.formInline.timeIn == -1">
                    <el-date-picker
                        style="height: 32px"
                        v-model="pageData.formInline.searchTime"
                        type="daterange"
                        range-separator="至"
                        format="YYYY-MM-DD"
                        value-format="YYYY-MM-DD"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :disabled-date="pageData.disabledDate"
                    />
                </el-form-item>
                <el-form-item v-if="pageData.formInline.timeIn == -2">
                  <el-date-picker
                        style="height: 32px"
                        v-model="pageData.formInline.searchTime"
                        type="monthrange"
                        format="YYYY-MM"
                        value-format="YYYY-MM"
                        range-separator="至"
                        start-placeholder="开始月份"
                        end-placeholder="结束月份"
                        :disabled-date="pageData.setMonthDisabled"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" :icon="Search" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="mb15">
            <el-button :icon="Upload" @click="getList(true)">导出表格</el-button>
        </div>
    </div>
    <div class="tabe-data rx-sc">
        <div class="tabe_div rx-bc">
            <div class="tabe_div_left">
                <div class="color-333 fwb ft32 mb8">{{ pageData.remark.comeCount || 0 }}</div>
                <div class="color-999 ft16">应到次数汇总</div>
            </div>
            <div class="tabe_div_right posi-r">
                <img class="w60 h60" src="@/assets/peopleIcon.png" alt="">
                <div class="div_right_img ft14 color-fff fwb posi-a bg-FA73B0">次</div>
            </div>
        </div>
        <div class="tabe_div rx-bc">
            <div class="tabe_div_left">
                <div class="color-333 fwb ft32 mb8">{{ pageData.remark.signCount || 0 }}</div>
                <div class="color-999 ft16">签到次数汇总</div>
            </div>
            <div class="tabe_div_right posi-r">
                <img class="w60 h60" src="@/assets/peopleIcon.png" alt="">
                <div class="div_right_img ft14 color-fff fwb posi-a bg-F8CE66">签</div>
            </div>
        </div>
        <div class="tabe_div rx-bc">
            <div class="tabe_div_left">
                <div class="color-333 fwb ft32 mb8">{{ pageData.remark.unSignCount || 0 }}</div>
                <div class="color-999 ft16">未签到次数汇总</div>
            </div>
            <div class="tabe_div_right posi-r">
                <img class="w60 h60" src="@/assets/peopleIcon.png" alt="">
                <div class="div_right_img ft14 color-fff fwb posi-a bg-5DDCA9">未</div>
            </div>
        </div>
        <div class="tabe_div rx-bc">
            <div class="tabe_div_left">
                <div class="color-333 fwb ft32 mb8">{{ Math.floor(pageData.remark.signPercent * 10000)/100 || 0 }} %</div>
                <div class="color-999 ft16">平均出勤率</div>
            </div>
            <div class="tabe_div_right posi-r">
                <img class="w60 h60" src="@/assets/peopleIcon.png" alt="">
                <div class="div_right_img ft14 color-fff fwb posi-a bg-FA73B0">率</div>
            </div>
        </div>
    </div>
    <div class="mt20">
        <el-table :data="pageData.tableData" stripe >
            <el-table-column fixed label="会员姓名" show-overflow-tooltip >
				<template #default="scope">
					<span class="color-409EFF tool_btn">{{scope.row.realName}}</span>
				</template>
			</el-table-column>
            <el-table-column prop="classDateStr" label="会员类型">
                <template #default="scope">
                    <span class="tag_blue tag" v-if="scope.row.trialFlag == 1">正式会员</span>
                    <span class="tag_red tag" v-else>潜在会员</span>
                </template>
            </el-table-column>
			<el-table-column prop="comeCount" label="应到次数" />
			<el-table-column prop="signCount" label="已签到次数" />
            <el-table-column prop="unSignCount" label="未签到次数" />
            <el-table-column prop="validCourseNum" label="签到率">
                <template #default="scope">
                    {{ Math.floor(scope.row.signPercent * 10000)/100 || 0 }} %
                </template>
            </el-table-column>
		</el-table>
        <div class="mt20 rx-ec">
	        <el-pagination
	          v-model:currentPage="pageData.page"
	          :page-sizes="[15,20,30,50,100]"
	          :page-size="pageData.limit"
	          layout="total, sizes, prev, pager, next, jumper"
	          :total="pageData.totalCount"
	          @size-change="classPageSizeChange"
              @current-change="()=>{getList()}"
	        >
	        </el-pagination>
	    </div>
    </div>
</template>

<script setup>
    import { reactive, onMounted } from 'vue';
    import { Search, Upload, Download } from '@element-plus/icons-vue'
    import {ElMessage} from  'element-plus';
    import utils from '@/common/utils.js';
    import { getSignData, getSignDataList } from '@/api/dataCenter.js';
    
    const dataList =[
        { value : "5" , label : '近7天' },
        { value : "7" , label : '近30天' },
        { value : "8" , label : '近60天' },
        { value : "9" , label : '近90天' },
        { value : "24", label : '近6个月' },
        { value : "25", label : '近12个月' },
        { value : "-1" , label : '自定义日期'},
        { value : "-2" , label : '自定义月份' }
    ]
    const pageData = reactive({
        page: 1,
        limit: 15,
        totalCount: 0,
        formInline: {
            timeIn: '5',
            keywords: '',
            trialFlag: '',
            searchTime: []
        },
        tableData: [],
        remark:{},
        disabledDate(time) {
          return time.getTime() > new Date().getTime()
        },
        setMonthDisabled(time) {
            // 获取当前的月份信息
            const date = new Date(); // 获取当前的时间基本信息，值是这样的： Tue Jul 20 2021 14:59:43 GMT+0800 (中国标准时间)
            const year = date.getFullYear(); // 获取当前年份，值是这样的： 2021
            let month = date.getMonth() + 1; // 获取当前月份，值是这样的： 6   getMonth()方法返回值是0-11，也就是1月份到12月份，所以要加上1，才是当前月份
            if (month >= 1 && month <= 9) {
              // 如果是1月到9月就要在前面补上一个0   比如：02、07    月份这样表示
              month = "0" + month;
            }
            const nowDate = year.toString() + month.toString(); // 转换成字符串拼接，最终得到年和月，比如此时的时间是2021年7月20号，所以nowDate的值是：202107
            // 获取时间选择器的月份信息
            const timeyear = time.getFullYear(); // 获取时间选择器的年份（有很多）
            let timemonth = time.getMonth() + 1; // 与上面同理
            if (timemonth >= 1 && timemonth <= 9) {
              timemonth = "0" + timemonth;
            }
            const elTimeData = timeyear.toString() + timemonth.toString();

            // 返回，时间选择器的日期月份大于当前日期的月份，又因为disabledDate函数是控制月份禁用不可选
            // 所以，最终就是：时间选择器的月份大于当前的月份，就都禁用掉，所以就实现了最终效果：
            // 大于等于当前月份都不可选 
            return elTimeData > nowDate; // 这里虽然是字符串，但是弱类型语言js会做一个转换，是可以比较大小的如： '202107' > '202008'
        },
    })
    /**
     * 搜索
     */
    function search() {
        if (pageData.formInline.timeIn == '-1') {
            if (!pageData.formInline.searchTime || !pageData.formInline.searchTime.length) {
                ElMessage({
                    type : 'warning',
                    message : '请选择日期'
                });
                return;
            }
            if (utils.datedifference(pageData.formInline.searchTime[0], pageData.formInline.searchTime[1]) > 31) {
                ElMessage({
                    type : 'warning',
                    message : '自定义日期不能超过31天'
                });
                return;
            }
        }
        if (pageData.formInline.timeIn == '-2') {
            if (!pageData.formInline.searchTime || !pageData.formInline.searchTime.length) {
                ElMessage({
                    type : 'warning',
                    message : '请选择月份'
                });
                return;
            }
            if (utils.MonthsBetw(pageData.formInline.searchTime[0], pageData.formInline.searchTime[1]) > 12) {
                ElMessage({
                    type : 'warning',
                    message : '自定义月份不能超过12个月'
                });
                return;
            }
        }
        pageData.page = 1
        getInfo()
    }
    /**
     * 切换日期范围
     */
    function changeTimeIn(val) {
        pageData.formInline.searchTime = []
    }
    /**
     * 会员统计汇总
     */
    function getInfo() {
        let params = {
            timeIn: pageData.formInline.timeIn,
            keywords: pageData.formInline.keywords,
            trialFlag: pageData.formInline.trialFlag,
            searchTime: pageData.formInline.searchTime.join('-'),
        }
        getSignData(params).then(res => {
            if (res.code == 0) {
                pageData.remark = res.data
            }
        })
        getList()
    }
    /**
     * 会员统计列表
     */
    function getList(exportData) {
        let obj = {
            timeIn: pageData.formInline.timeIn,
            keywords: pageData.formInline.keywords,
            trialFlag: pageData.formInline.trialFlag,
            searchTime: pageData.formInline.searchTime.join('-'),
        }
        if (exportData) {
            obj.exportData = 1
            obj.page = 1
            obj.limit = 9999
        } else {
            obj.exportData = 2
            obj.page = pageData.page
            obj.limit = pageData.limit
        }
        getSignDataList(obj).then(res => {
            if (res.code == 0) {
                if(exportData){ //导出
					ElMessage({
						type: 'success',
						message: '正在导出，请稍后前往下载中心下载'
					})
				} else {
                    pageData.tableData = res.data
                    pageData.totalCount = res.count
                }
                
            }
        })
    }
    function classPageSizeChange(limit){
		pageData.limit = limit;
	}
    onMounted(() => {
        getInfo()
    })
</script>
<style type='css/style' scoped>
    .formSearch ::v-deep .el-form--inline .el-form-item {
        margin-right: 16px;
        margin-bottom: 15px;
    }
    .tabe-data{
        margin-top: 5px;
        height: 148px;
        background: #EFF6FF;
        padding: 21px 20px 23px 21px;
    }
    .tabe_div{
        height: 104px;
        background: #FFF;
        padding: 13px 32px 16px 34px;
        margin-right: 20px;
        width: 25%;
    }
    .div_right_img{
        width: 24px;
        height: 24px; 
        border-radius: 50%;
        text-align: center;
        line-height: 24px;
        bottom: 0;
        right: 0;
    }
    .tag {
        padding: 1px 4px;
    }
</style>