<template>
	<div class="member_info" v-show="storeData.moduleStatus">
		<h3 class=" mb10 rx-sc" v-show="!pageData.updateTitleShow"> 
			<div class="rx-ss">
				<span class="ft16">{{storeData.name}}</span>
				<unicon class="ml10 mr10" width="18px" name="edit-alt" fill="#47A1FE" @click="pageData.updateTitleShow = true"></unicon>
			</div>
			<span class="tips color-999 ft12">下面最多可添加20个入场卡</span>
		</h3>
		<div class="mb10 rx-sc" v-show="pageData.updateTitleShow">
			<div class="w150 mr20">
				<el-input type="text" v-model="pageData.titleName" maxlength="6" placeholder="限6个字符"></el-input>
			</div>
			<span class="color-409EFF mr20 c_p tool_btn" @click="updateTitle">确定</span>
			<span class="c_p" @click="pageData.updateTitleShow = false">取消</span>
		</div>
		<div class="store_form mt20">
			<div class="rx-sc mb12">
				<span>展示方式：</span>
				<div>
					<el-radio-group v-model="storeData.showType">
						<el-radio label="1" size="large">横向滚动</el-radio>
						<el-radio label="2" size="large">列表模式</el-radio>
					</el-radio-group>
				</div>
			</div>
			<div class="rx-sc mb12">
				<span>展示规则：</span>
				<div>
					<el-radio-group v-model="storeData.showRule" @change="changeShowRule">
						<el-radio label="1" size="large">全部入场卡</el-radio>
						<el-radio label="2" size="large">自定义</el-radio>
					</el-radio-group>
				</div>
			</div>
			<div class="rx-ss mb12" v-show="storeData.showRule == '2'">
				<div class="w70">展示卡项：</div>
				<div>
					<div class="bg-fff w350 pt20 pb20 pl10 pr10 mb20" v-if="storeData.list.length">
						<MoveSort :list="storeData.list" :key="(new Date()).getTime()">
							<template #[index] v-for="(item,index) in storeData.list" :key="index">
								<div class="pt5 pb5">
									<div class="coachMoveItem pl20 bg-FAFAFC pt20 pb20 pr20" :class="{active:storeData.active == index}" >
										<div class="move_icon_top" v-if="index > 0">
											<unicon width="15px" name="align-center-v" fill="#C2C2C4"></unicon>
										</div>
										<div class="rx-sc">
											<img class="w120 h80 mr20 bdr4" :src="item.productImg" alt="">
											<div class="flex1">
												<p class="mb5 h3">{{item.productName}}</p>
												<div class="rx-be">
													<span class="ft12 color-999">含{{item.productTyp == 1 ? item.productNum+'次' : (item.ifMonth == 1 ? '天' :item.ifMonth == 2 ? '月' : '年')}}</span>
													<div class="rx-se">
														<span class="color-red ft12">￥</span>
														<strong class="color-red ft16 l_h17">{{item.productPrice}}</strong>
													</div>
												</div>
											</div>
										</div>
										<div class="move_icon_bottom">
											<unicon width="18" height="18" name="trash-alt" fill="#C2C2C4" hover-fill="#F87677" @click.capture="deleItme(index)"></unicon>
										</div>
									</div>
								</div>
							</template>
						</MoveSort>
					</div>
					<div v-show="storeData.list.length < 20">
						<el-button :icon="Plus" type="primary" @click="selectClick">添加入场卡</el-button>
					</div>
				</div>
			</div>
		</div>
		<AddCardsOrClass ref="addCardsOrClassRef" @close="handleClose" @addSave="save"></AddCardsOrClass>
	</div>
</template>

<script setup>
	const propData = defineProps({
	  tab:String,
	  itemIndex:Number
	})
	import {ref,reactive, onMounted} from 'vue'
	import { useStore } from 'vuex'
	import MoveSort from '../MoveSort.vue'; //通用移动排序
	import { Plus } from '@element-plus/icons-vue'
	import AddCardsOrClass from './addCardsOrClass.vue';
	import { productList } from '@/api/cards.js'

	const addCardsOrClassRef = ref()
	const store = useStore();
	const storeData = store.state.appletFormData[propData.tab].list[propData.itemIndex];
	const storage = JSON.parse(JSON.stringify(storeData.list))
	const pageData = reactive({
		titleName:'',
		updateTitleShow:false
	})
	
	//编辑标题
	function updateTitle(){
		if(pageData.titleName){
			storeData.name = pageData.titleName;
			pageData.updateTitleShow = false;
		}else{
			ElMessage({
				message:'请输入标题',
				type: 'warning'
			})
		}
	}
	//选择课程
	function selectClick(){
		let params = {
			storeData: storeData,
			checkedList: storeData.list,
			title: '入场卡',
			type: 2
		}
		addCardsOrClassRef.value.show()
		addCardsOrClassRef.value.getInfo(params)
	}
	function save(data) {
		data.forEach((item) => {
			item.productImg = item.productCover
		})
		storeData.list = data
	}
	//删除已选
	function deleItme(index){
		storeData.list.splice(index,1);
	}
	// 切换展示规则
	function changeShowRule(val) {
		if (val == 1) {
			getList()
		} else {
			storeData.list = storage
		}
	}
	function getList() {
		let params = {
			page:1,
			limit: 9999
		}
        params.type = 2
        productList(params).then((res)=>{
			if(res.code == 0){
				res.data.forEach((item) => {
					item.productImg = item.productCover
				})
				storeData.list = res.data
			}
		})
	}
	onMounted(() => {
		if (storeData.showRule !=2) {
			getList()
		}
	})
		
</script>

<style scoped lang="stylus">
	.member_info{
		.coachMoveItem{
			position relative;
			.move_icon_top{
				position absolute;
				left 0px;
				top 0px;
			}
			.move_icon_bottom{
				position absolute;
				bottom 15px;
				right 5px;
				display none;
			}
			.moveLabel{
				display inline-block;
				background-color #F0F0F2;
				color #999;
				font-size 12px;
				padding 2px 10px;
				margin-right 5px;
			}
		}
		.coachMoveItem:hover .move_icon_bottom{
			display block;
		}
		.coachMoveItem:hover {
			border: 1px dashed #409eff;
			background: #EFF6FF;
		}
		.tips{
			font-weight: 500
		}
	}
	.flex1 .h3 {
		width: 150px;
        overflow: hidden;
		text-overflow: ellipsis;
	}
</style>
