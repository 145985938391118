<template>
    <workArea :barlist="barlist">
        <div v-if="!pageData.tabName">
            <div class="p16 pt0">
                <CommonTitle text="班级信息"/>
                <div class="rx-c mb16">
                    <div class="rx-sc w360 mr40">
		    			<span class="w120 t_a_r"><span class="color-red">*</span>班级名称：</span>
		    			<el-input class="w240" type="text" placeholder="输入班级名称（20字）" maxlength="20" v-model="pageData.formData.cycleName" ></el-input>
		    		</div>
                    <div class="rx-sc w360 mr40">
		    			<span class="w120 t_a_r"><span class="color-red">*</span>开班时间：</span>
		    			<div class="w240">
		    				<el-date-picker
		    					style="width: 100%;"
								:disabled="pageData.disabled1"
    	    				  	v-model="pageData.formData.cycleTimeStr"
    	    				  	type="date"
    	    				  	placeholder="请选择开班时间"
		    				  	format="YYYY-MM-DD"
                        		value-format="YYYY-MM-DD"
    	    				/>
		    			</div>
		    		</div>
                    <div class="rx-sc w360">
		    			<span class="w120 t_a_r"><span class="color-red" >*</span>总课次（节）：</span>
		    			<el-input class="w240" type="text" placeholder="班级上课总课节数" maxlength="3" v-model="pageData.formData.cycleNum" @input="(v) => (pageData.formData.cycleNum = v.replace(/^0|[^0-9]/g,''))" ></el-input>
		    		</div>
                </div>
                <div class="rx-sc mb16">
		    		<span class="w120 t_a_r">扣课规则：</span>
                    <el-radio-group v-model="pageData.formData.consumeType" :disabled="pageData.disabled || pageData.disabled1">
                        <el-radio :label="1">{{ TYPE==11 ? '预约即扣课' : '预约后扣课' }}</el-radio>
                        <el-radio v-if="TYPE==11" :label="2">签到后扣课</el-radio>
                    </el-radio-group>
		    	</div>
                <div class="mb16" v-if="TYPE==11">
		    		<div class="rx-sc mb16">
                        <span class="w120 t_a_r">请假限制：</span>
                        <el-radio-group v-model="pageData.formData.ifLeave" :disabled="pageData.disabled1">
                            <el-radio :label="0">不支持请假</el-radio>
							<el-radio :label="1">支持请假</el-radio>
                        </el-radio-group>
                    </div>
                    <div class="ml120 w350" v-if="pageData.formData.ifLeave == 1">
                        <el-card shadow="never"> 
                            <div class="rx-sc mb16">
		    	            	<span class="w90 t_a_r">可请假次数：</span>
                                <el-input-number class="w60" v-model="pageData.formData.leaveLimitNum" :min="0" :max="999" :step="1" step-strictly :controls="false" />
                                <span class="ml10">次</span>
		    	            </div>
                            <div class="rx-sc mb16" v-if="pageData.formData.consumeType == 2">
		    	            	<span class="w90 t_a_r">请假扣课：</span>
                                <el-switch v-model="pageData.formData.leaveConsumeFlag" :active-value="1" :inactive-value="0" />
		    	            </div>
                            <div class="rx-sc">
		    	            	<span class="w90 t_a_r">请假时间：</span>
                                <span class="mr10">截止至上课前</span>
                                <el-input-number class="w60" v-model="pageData.formData.leaveEndRule" :min="0" :max="999" :step="1" step-strictly :controls="false" />
                                <span class="ml10">小时</span>
		    	            </div>
                        </el-card>
                    </div>
		    	</div>
                <CommonTitle text="售卖信息"/>
                <div class="rx-c mb16">
                    <div class="rx-sc w460 mr40">
		    			<span class="w120 t_a_r"><span class="color-red">*</span>售卖方式：</span>
                        <el-checkbox-group v-model="pageData.formData.sellType">
                            <el-checkbox :label="1">
                                <span class="color-333">会员端售卖</span>
                            </el-checkbox>
                            <el-checkbox :label="2">
                                <span class="color-333">线下售卖</span>
                            </el-checkbox>
                        </el-checkbox-group>
		    		</div>
                    <div class="rx-sc w360 mr40">
		    			<span class="w120 t_a_r"><span class="color-red">*</span> {{ TYPE==11 ? '班级售价' : '单节价格' }}：</span>
                        <el-input class="w240" type="text" placeholder="输入金额" maxlength="6" :disabled="pageData.disabled1" v-model="pageData.formData.sellPrice" @blur="productPriceBlur" />
		    		</div>
                </div>
                <div class="rx-c mb16">
                    <div class="w460 mr40" v-if="pageData.formData.sellType.includes(1)">
                        <div class="rx-sc">
                            <span class="w120 t_a_r"><span class="color-red">*</span>报名时间：</span>
                            <div class="w340">
		    			    	<el-date-picker
									v-if="pageData.disabled1"
		    			    		style="width: 100%;"
									:disabled="pageData.disabled1"
    	    			    	  	v-model="pageData.formData.sellTimeStr"
    	    			    	  	type="daterange"
    	    			    	  	range-separator="至"
    	    			    	  	start-placeholder="开始日期"
    	    			    	  	end-placeholder="结束日期"
		    			    	  	format="YYYY-MM-DD"
                            		value-format="YYYY-MM-DD"
                                    :disabled-date="pageData.disabledDate"
    	    			    	/>
								<div v-if="pageData.disabled" class="rx-c">
									<el-date-picker
        							  	v-model="pageData.formData.sellTimeStr[0]"
										:disabled="pageData.disabled"
        							  	format="YYYY-MM-DD"
                            			value-format="YYYY-MM-DD"
        							  	placeholder="开始日期"
										type="date"
        							/>
									<span class="ml10 mr10">至</span>
									<el-date-picker
        							  	v-model="pageData.formData.sellTimeStr[1]"
        							  	format="YYYY-MM-DD"
                            			value-format="YYYY-MM-DD"
        							  	placeholder="结束日期"
										type="date"
										:disabled-date="pageData.disabledDate1"
        							/>
								</div>
								<el-date-picker
									v-if="!pageData.disabled1 && !pageData.disabled"
		    			    		style="width: 100%;"
    	    			    	  	v-model="pageData.formData.sellTimeStr"
    	    			    	  	type="daterange"
    	    			    	  	range-separator="至"
    	    			    	  	start-placeholder="开始日期"
    	    			    	  	end-placeholder="结束日期"
		    			    	  	format="YYYY-MM-DD"
                            		value-format="YYYY-MM-DD"
                                    :disabled-date="pageData.disabledDate"
    	    			    	/>
		    			    </div>
                        </div>
                        <div class="ml120 color-999 mt10">结束时间要小于开班开始时间</div>
		    		</div>
                    <div class="w360 mr40">
		    			<div class="rx-sc">
                            <span class="w120 t_a_r"><span class="color-red">*</span>报名人数限制：</span>
							<el-input-number v-if="pageData.disabled1" :disabled="pageData.disabled1" class="w240 t_a_l" v-model="pageData.formData.allPeopleNum" :min="0" :max="999" :step="1" :controls="false" />
							<el-input-number v-if="pageData.disabled" class="w240 t_a_l" v-model="pageData.formData.allPeopleNum" :min="pageData.formData.allPeopleNum1" :max="999" :step="1" :controls="false" />
							<el-input-number v-if="!pageData.disabled && !pageData.disabled1" class="w240 t_a_l" v-model="pageData.formData.allPeopleNum" :min="0" :max="999" :step="1" :controls="false" />
                        </div>
                        <div class="ml120 color-999 mt10">班级最大容纳人数</div>
		    		</div>
                </div>
                <div v-if="TYPE==12">
                    <div class="rx-sc mr40">
		    	    	<span class="w120 t_a_r"><span class="color-red">*</span> 单次购买：</span>
                        <el-input class="w240" type="text" placeholder="输入会员起步购买课节数" maxlength="3" v-model="pageData.formData.sellOneLimitMin" @input="(v) => (pageData.formData.sellOneLimitMin = v.replace(/^0|[^0-9]/g,''))" ></el-input>
                        <span class="ml5 mr5">～</span>
                        <el-input class="w240" type="text" placeholder="输入会员最大购买课节数" maxlength="3" v-model="pageData.formData.sellOneLimitMax" @input="(v) => (pageData.formData.sellOneLimitMax = v.replace(/^0|[^0-9]/g,''))" ></el-input>
		    	    </div>
                    <div class="ml120 color-999 mt10">会员报名班级后，若购买的课节数用完可由场馆续费购买</div>
                </div>
                <CommonTitle text="上课信息"/>
                <div class="rx-c mb16">
                    <div class="rx-sc w360 mr40">
		    			<span class="w120 t_a_r"><span class="color-red">*</span>上课课程：</span>
                        <div class="w240">
                            <el-select v-model="pageData.formData.courseNameId" placeholder="请选择小班课" @change="changeCourse" :disabled="pageData.disabled || pageData.disabled1">
		    			    	<el-option :label="item.courseName" :value="item.courseNameId" v-for="(item, index) in pageData.courseList" :key="index"></el-option>
		    			    </el-select>
                        </div>
		    		</div>
                    <div class="rx-sc w360 mr40">
		    			<span class="w120 t_a_r"><span class="color-red">*</span> 单课时长：</span>
                        <div class="w240">
		    		    	<el-input type="text" placeholder="请输入(5的倍数)" v-model="pageData.formData.courseTime"
		    		    		@blur="courseTimeBlur">
		    		    		<template #append>分钟</template>
		    		    	</el-input>
		    		    </div>
		    		</div>
                    <div class="rx-sc w360 mr40">
		    			<span class="w120 t_a_r">适用范围：</span>
                        <div class="w240">
		    		    	<el-input type="text" placeholder="请输入(限20字)" maxlength="20" v-model="pageData.formData.applyAge" />
		    		    </div>
		    		</div>
                </div>
                <div class="rx-ss mb12">
		        	<span class="w120 t_a_r"><span class="color-red">*</span>授课教练： </span>
		        	<div class="w630">
		        		<div class="add_coach_title pt10 pb10">
		        			<span class="color-999 fwb pl20">教练</span>
		        			<span class="color-999 fwb pl10">工作性质</span>
		        			<span class="color-999 fwb pl10">操作</span>
		        		</div>
		        		<div class="coach_list">
		        			<div class="add_coach_title pt10 pb10" v-for="(item, index) in pageData.checkCoachList" :key="index">
		        				<span class="pl20">{{item.realName}}</span>
		        				<span class="pl10">{{item.ifFullTime == 1 ? '全职' : item.ifFullTime == 2 ? '兼职':'未知'}}</span>
		        				<span class="pl10">
		        					<a href="javascript:;" class="t_d_u color-red "
		        						@click="pageData.checkCoachList.splice(index, 1)">删除</a>
		        				</span>
		        			</div>
		        		</div>
		        		<div class="rx-cc pt10 pb10 color-409EFF c_p bg-fff tool_btn" @click="addCoach" style="border-bottom: 1px solid #eee">
		        			<el-icon>
		        				<Plus />
		        			</el-icon>选择教练
		        		</div>
		        	</div>
		        </div>
                <div class="rx-ss mb12">
		        	<span class="w110 t_a_r pt10"><span class="color-red">*</span>课程封面：</span>
		        	<div>
		        		<el-radio-group v-model="pageData.coverCheck">
		        			<el-radio label="0">风格一</el-radio>
		        			<el-radio label="1">风格二</el-radio>
		        			<el-radio label="2">自定义</el-radio>
		        		</el-radio-group>
		        		<div class="rx-ss mt15 mb10">
		        			<div class="cover_img">
		        				<div v-if="pageData.coverCheck == 2" class="cover_dele c_p">
		        					<unicon name="trash-alt" fill="#fff" hover-fill="#fff" @click="deleCover"></unicon>
		        				</div>
		        				<img v-show="pageData.cover[pageData.coverCheck]" class="w120 h80"
		        					:src="pageData.cover[pageData.coverCheck]" alt="" />
		        			</div>
		        			<div v-show="!pageData.cover[pageData.coverCheck]" class="w120 t_a_c color-999 h80 rx-cc mr10"
		        				style="border: 1px solid #ccc;">
		        				<div>
		        					<CommonUpload @change="uploadChange" :autoCropWidth="375" :autoCropHeight="250" :isCropper="true">
		        						<img class="w25" src="@/assets/addBank.png" alt="">
		        						<p>上传图片</p>
		        					</CommonUpload>
		        				</div>
		        			</div>
		        			<div v-show="!pageData.cover[pageData.coverCheck]" class="color-999 w200">
		        				建议大小为750*500px的jpg、png、bmp格式不超过2M的图片。
		        			</div>
		        		</div>
		        	</div>
		        </div>
		        <div class="rx-sc mb25">
		        	<span class="w110 t_a_r"><span class="color-red">*</span>训练目的：</span>
		        	<div class="w240 mr16">
		        		<el-select multiple collapse-tags v-model="pageData.formData.courseTrainIds" multiple-limit="2" placeholder="请选择">
		        			<el-option v-for="item in pageData.objectiveList" :key="item.id" :label="item.name"
		        				:value="item.id">
		        			</el-option>
		        		</el-select>
		        	</div>
		        	<el-button type="primary" @click="pageData.addUpdateObjectShow = true">新增</el-button>
		        </div>
		        <div class="rx-ss mb25">
		        	<span class="w110 t_a_r">课程介绍：</span>
		        	<div class="flex1" ref="editor"></div>
		        </div>
		        <div class="pl110 pb10">
		        	<el-button @click="goBack">取消</el-button>
		        	<el-button type="primary" @click="addCourseSave">保存</el-button>
		        </div>
            </div>
        </div>
        <div v-else class="rx-cc">
            <div class="mt100 t_a_c">
				<div class="rx-cc">
					<circle-check-filled  style="width: 6em; height: 6em;color:#74C041"/>
				</div>
				<h3 class="ft24 mt20 c_p" @click="goTeaching">{{ ID ? '编辑' : '创建' }}成功，<span class="ft24 color-409EFF">去排课>></span></h3>
			</div>
        </div>
        <!-- 新增-编辑弹窗 -->
		<el-dialog v-model="pageData.addUpdateObjectShow" title="新增目的" width="350px"
			:before-close="()=>{pageData.addUpdateObjectShow = false}" custom-class="popwechat">
			<div class="pt30 pb30">
				<div class="query_item mb16 rx-bc">
					<div class="query_tip rx-ec w130"><span class="color-red">*</span>目的名称</div>
					<el-input v-model="pageData.objectiveName" placeholder="输入关键字" />
				</div>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="pageData.addUpdateObjectShow = false">取消</el-button>
					<el-button type="primary" @click="addOrUpdateSave">确定</el-button>
				</div>
			</template>
		</el-dialog>
        <!-- 选择教练 -->
		<el-dialog v-model="pageData.addCoachShow" v-loading="pageData.loading" title="选择教练" width="650px"
			:before-close="handleClose" custom-class="popwechat">
			<div class="pt30 pb30 pl30 pr30">
				<div class="rx-sc mb12">
					<span class="w80">搜索教练</span>
					<div class="w200">
						<el-input type="text" placeholder="输入关键字" v-model="pageData.addCoachName"></el-input>
					</div>
					<el-button class="ml20" type="primary" @click="searchCoach">查询</el-button>
				</div>
				<CommonTip class="mb12" :text="'已选择 '+ pageData.selectCheckData.length +' 条'" />
				<el-table ref="multipleTableRef" :data="pageData.coachList.slice((pageData.addCoachPage-1)*pageData.addCoachLimit, pageData.addCoachPage*pageData.addCoachLimit)" stripe height="500px" :row-key="getRowKey"
			     @selection-change="selectionChange">
					<el-table-column type="selection" :reserve-selection="true" align="center" width="55"/>
					<el-table-column prop="realName" label="姓名" />
					<el-table-column prop="stallPhone" label="手机号" width="120" />
					<el-table-column prop="frameName" label="所属机构" show-overflow-tooltip />
					<el-table-column prop="ifFullTime" label="员工类型">
						<template #default="scope">
							<span>{{scope.row.ifFullTime == 1 ? '全职' : scope.row.ifFullTime == 2 ? '兼职':'未知'}}</span>
						</template>
					</el-table-column>
				</el-table>
				<div class="mt20" v-show="pageData.coachList.length > 0">
					<el-pagination v-model:currentPage="pageData.addCoachPage" :page-size="pageData.addCoachLimit"
						layout="prev, pager, next" :total="pageData.addCoachCount" @current-change="getStaff">
					</el-pagination>
				</div>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="handleClose">取消</el-button>
					<el-button type="primary" @click="addCoachSave">确定</el-button>
				</div>
			</template>
		</el-dialog>
    </workArea>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import workArea from '@/components/branch-store/workArea.vue';
    import CommonTitle from '@/components/CommonTitle.vue'
    import WangEditor from 'wangeditor'
	import { wangEditorUpload } from '@/common/common.js'
    import CommonUpload from '@/components/CommonUpload.vue';
    import { CircleCheckFilled } from '@element-plus/icons-vue'
    import { ElMessage } from 'element-plus'
	import utils from '@/common/utils.js';
    import { useRouter } from 'vue-router';
    import { getStoreAccountStaffSimple } from '@/api/staff.js'
    import { courseList, courseInfo, trainPurposeList, trainPurposeInsert, addCourseCycle, courseCycleInfo, editCourseCycle } from '@/api/course.js'
	
    const editor = ref();
    const router = useRouter()
	const TYPE = router.currentRoute.value.query.type
    const ID = router.currentRoute.value.query.id
    const multipleTableRef = ref()
    const barlist = reactive([{ name : '返回',type:0 },{ name : ID ? '编辑班级' : '新增班级' }]); // 面包屑
    
    let instance = ''; 
    const pageData = reactive({
        tabName: false,
        formData:{
            cycleName: '',
            cycleTimeStr: '',
            consumeType: 1,
            cycleNum: '',
            ifLeave: 0,
            leaveLimitNum: 0,
            leaveConsumeFlag: 1,
            leaveEndRule: 0,
            sellType: [],
            sellPrice: '',
            allPeopleNum: '',
            sellOneLimitMin: '',
            sellOneLimitMax: '',
            courseTrainIds: [],
            courseTime: '',
            applyAge: '',
			sellPeopleNum: '',
			sellTimeStr: []
        },
        addCoachPage: 1,
		addCoachCount: 0,
		addCoachLimit: 10,
        coachList: [],
		checkCoachList: [],
        selectCheckData: [],
        addCoachShow: false,
        loading: false,
        coverCheck: '0',
		cover: ['https://image.taokatao.cn/imgs/runBusiness/classStyle1.png',
			'https://image.taokatao.cn/imgs/runBusiness/classStyle2.png'
		],
        courseList: [],
        objectiveList: [],
        addUpdateObjectShow: false,
        objectiveName: '',
		encryptionId: '',
        disabledDate(time) {
          return time.getTime() > new Date(pageData.formData.cycleTimeStr).getTime() - 8.64e7
        },
		// disabledDate1(time) {
        //   return time.getTime() > new Date(pageData.formData.cycleTimeStr[0]).getTime() - 8.64e7 || time.getTime() < new Date(pageData.formData.sellTimeStr[0]).getTime()
        // },
		disabled: false,
		disabled1: false,
    })
    //消销售金额失去焦点
	function productPriceBlur(){
		var {sellPrice} = pageData.formData;
		if(sellPrice){
			if(isNaN(Number(sellPrice))){
				ElMessage.warning('请输入正确的金额')
				pageData.formData.sellPrice = '';
				return false;
			}
			pageData.formData.sellPrice = Number(sellPrice).toFixed(2);
		}
	}
    //单课时长失去焦点验证
	function courseTimeBlur() {
		var { courseTime } = pageData.formData;
		if (courseTime && courseTime % 5 != 0) {
            ElMessage.warning('单课时长需为5的倍数')
			pageData.formData.courseTime = '';
			return false;
		}
	}
    //新增教练
	function addCoach() {
		pageData.addCoachPage = 1;
		pageData.checkCoachList.map((item)=>{
			setTimeout(function(){
				multipleTableRef.value.toggleRowSelection(item, true)
			},0)
        })
		pageData.addCoachShow = true;
		
	}
    //获取员工列表
	function getStaff() {
		pageData.loading = true
		getStoreAccountStaffSimple({
			page: 1,
			limit: 999,
			searchKeys: pageData.addCoachName,
            teachType: 3
		}).then((res) => {
			pageData.loading = false
			if (res.code == 0) {
				pageData.coachList = res.data
				pageData.addCoachCount = res.count;
			}
		}).catch(() => {
			pageData.loading = false
		})
	}
    /**
     * 数据选择
     */
	function selectionChange(data) {
		pageData.selectCheckData = data
	}
	/**
	 * 取消教练选择
	 */
	function handleClose() {
       multipleTableRef.value.clearSelection()
       pageData.addCoachShow = false
    }
	/**
     * 记住分页
     */
    function getRowKey(row) {
       return row.id
    }
    /**
     * 选择课程
     */
    function changeCourse(val){
		pageData.checkCoachList = []
		pageData.cover= ['https://image.taokatao.cn/imgs/runBusiness/classStyle1.png',
			'https://image.taokatao.cn/imgs/runBusiness/classStyle2.png'
		]
        courseInfo({ courseNameId: val }).then(res => {
			if (res.code == 0) {
            	instance.txt.html(res.data.courseContent);
            	pageData.formData.courseTime = res.data.courseTime
            	pageData.formData.applyAge = res.data.applyAge
				if (res.data.courseTeacher) {
						res.data.courseTeacher.split(',').map((n) => {
							var arr = pageData.coachList.filter(z =>  z.id == n);
							if (arr.length > 0) {
								pageData.checkCoachList.push(arr[0]);
							}
						})
					}
            	if (res.data.trainPurposeIds) {
					pageData.formData.courseTrainIds = res.data.trainPurposeIds.split(',').map(n =>
						Number(n));
				}
            	let arry = pageData.cover.filter((item)=>{ return item == res.data.courseCover})
				if (arry.length) {
            	   	pageData.cover.forEach((item, index)=>{
						if (item == res.data.courseCover) {
            		        pageData.coverCheck = index+''
						}
					})
				} else {
					pageData.coverCheck = '2'
					if (res.data.courseCover) {
						pageData.cover.push(res.data.courseCover)
					}
				}
			}
        })
    }
	/**
	 * 确定选择教练
	 */
	function addCoachSave() {
		pageData.checkCoachList = pageData.selectCheckData;
		multipleTableRef.value.clearSelection()
		pageData.addCoachShow = false;
	}
    //上传封面
	function uploadChange(url) {
		pageData.cover.push(url);
	}
	// 删除封面
	function deleCover() {
		pageData.cover.splice(2, 1);
	}

    //新增目的
	function addOrUpdateSave() {
		if (!pageData.objectiveName) {
			ElMessage.warning('请输入目的名称')
			return false;
		}
		trainPurposeInsert({
			name: pageData.objectiveName
		}).then((res) => {
			if (res.code == 0) {
				ElMessage({
					type: 'success',
					message: '保存成功',
				})
				pageData.addUpdateObjectShow = false;
				pageData.objectiveName = '';
				trainList();
			}
		})
	}
    function trainList(){
        trainPurposeList().then((res) => {
			if (res.code == 0) {
				pageData.objectiveList = res.data || [];
			}
		})
    }
    function getCourseList() {
        courseList({
            page: 1,
            limit: 99999,
            courseType: 13
        }).then(res => {
            pageData.courseList = res.data
        })
    }
    /**
	 * 取消
	 */
	function goBack() {
        localStorage.setItem('classManage', TYPE == 11 ? '0' : '1')
        router.push({ path: '/branch/classManage'})
	}
    //新增课程
	function addCourseSave() {
		let formData = {} 
		if (!pageData.formData.cycleName) {
			ElMessage.warning('请输入班级名称')
			return false;
		}
        if (!pageData.formData.cycleTimeStr) {
			ElMessage.warning('请选择开班时间')
			return false;
		}
        if (!pageData.formData.cycleNum) {
			ElMessage.warning('请输入总课次')
			return false;
		}
		if (pageData.formData.ifLeave){
			if (!pageData.formData.leaveLimitNum) {
				ElMessage.warning('请输入可请假次数')
				return false;
			}
			if (!pageData.formData.leaveEndRule) {
				ElMessage.warning('请输入请假时间')
				return false;
			}
		}
        if (!pageData.formData.sellType.length) {
			ElMessage.warning('请选择售卖方式')
			return false;
		}
        if (!pageData.formData.sellPrice) {
			ElMessage.warning('请输入班级售价')
			return false;
		}
        if (pageData.formData.sellType.includes(1) && (pageData.formData.sellTimeStr && !pageData.formData.sellTimeStr.length)) {
			ElMessage.warning('请选择报名时间')
			return false;
		}
        if (!pageData.formData.allPeopleNum) {
			ElMessage.warning('请输入报名人数限制')
			return false;
		}
        if (TYPE == 12) {
            if (!pageData.formData.sellOneLimitMin) {
		    	ElMessage.warning('请输入会员起步购买课节数')
		    	return false;
		    }
            if (!pageData.formData.sellOneLimitMax) {
		    	ElMessage.warning('请输入会员最大购买课节数')
		    	return false;
		    }
            if (Number(pageData.formData.sellOneLimitMin)>Number(pageData.formData.sellOneLimitMax)) {
                ElMessage.warning('会员最大购买课节数不能小于最小购买课节数')
		    	return false;
            }
        }
        if (!pageData.formData.courseTime) {
			ElMessage.warning('请输入单课时长')
			return false;
		}
		if (!pageData.checkCoachList.length) {
			ElMessage.warning('请选择授课教练')
			return false;
		}
		if (pageData.formData.sellTimeStr && pageData.formData.sellTimeStr.length) {
			pageData.formData.sellTimeStr[0] = pageData.formData.sellTimeStr[0]
			pageData.formData.sellTimeStr[1] = pageData.formData.sellTimeStr[1]
		}
		formData.cycleType = TYPE
		formData.cycleName = pageData.formData.cycleName
        formData.cycleTimeStr = pageData.formData.cycleTimeStr
        formData.cycleNum = pageData.formData.cycleNum
        formData.consumeType = pageData.formData.consumeType
        if (TYPE == 11) {
            formData.ifLeave = pageData.formData.ifLeave
            if (formData.ifLeave) {
                formData.leaveLimitNum = pageData.formData.leaveLimitNum
                formData.leaveEndRule = pageData.formData.leaveEndRule
                if (pageData.formData.consumeType == 2) {
                    formData.leaveConsumeFlag = pageData.formData.leaveConsumeFlag
                }
            }
        }
        formData.sellType = pageData.formData.sellType.length && pageData.formData.sellType.join(',')
        formData.sellPrice = pageData.formData.sellPrice
        if (pageData.formData.sellType.includes(1)) {
            formData.sellTimeStr = pageData.formData.sellTimeStr.length && pageData.formData.sellTimeStr.join('-') || ''
        }
        if (TYPE == 12) {
            formData.sellOneLimitMin = pageData.formData.sellOneLimitMin
            formData.sellOneLimitMax = pageData.formData.sellOneLimitMax
        }
		formData.allPeopleNum = pageData.formData.allPeopleNum
        formData.courseNameId = pageData.formData.courseNameId
        formData.courseTime = pageData.formData.courseTime
        formData.applyAge = pageData.formData.applyAge
        var list = pageData.checkCoachList.map(n => n.id); //教练
		formData.courseTeacher = list.join(',');
        formData.courseTrainIds = pageData.formData.courseTrainIds.join(',')
        formData.courseCoverPic = pageData.cover[pageData.coverCheck]
        formData.courseContent = instance.txt.html();
		if (ID) {
			formData.encryptionId = ID
			editCourseCycle(formData).then((res) => {
				if (res.code == 0) {
					ElMessage.success('编辑成功')
					router.go(-1)
				}
			})
		} else {
			addCourseCycle(formData).then((res) => {
				if (res.code == 0) {
					ElMessage.success('保存成功')
					pageData.encryptionId = res.data.encryptionId
                    pageData.tabName = true
				}
			})
		}
	}
    /**
     * 去排课
     */
    function goTeaching() {
		router.push({ path: '/branch/addClass', query :{ id: pageData.encryptionId}})
    }
    onMounted(() => {
        if (TYPE == 12) {
            localStorage.setItem('classManage', '1')
        }
        if (TYPE == 11) {
            localStorage.setItem('classManage', '0')
        }

		instance = new WangEditor(editor.value);
		instance.config.customUploadImg = function (resultFiles, insertImgFn) {
			wangEditorUpload({fileData:resultFiles[0]},(res)=>{
				if(res.data.code == 0){
					insertImgFn(res.data.data.wholeUrl);
				}else{
					ElMessage({
						type: 'warning',
						message: '上传失败',
					})
				}
			});
		}
		Object.assign(instance.config, {
			height:300,
			zIndex:100,
		});
		instance.create();
        getCourseList()
        trainList()
		getStaff()
        if (ID) {
            courseCycleInfo({ encryptionId: ID }).then(res => {
				pageData.formData.sellPeopleNum = res.data.sellPeopleNum
				if (utils.expired(res.data.cycleStartCourseTimeStr) > 0) {
					if (pageData.formData.sellPeopleNum > 0) {
						pageData.disabled = true
					} else {
						pageData.disabled = false
					}
				} else {
					pageData.disabled1 = true
				}
		        pageData.formData.cycleName = res.data.cycleName
                pageData.formData.cycleTimeStr = res.data.cycleStartCourseTimeStr.substring(0,10)
                pageData.formData.cycleNum = res.data.cycleNum
                pageData.formData.consumeType = res.data.consumeType
                pageData.formData.ifLeave = res.data.ifLeave
                pageData.formData.leaveLimitNum = res.data.leaveLimitNum
                pageData.formData.leaveEndRule = res.data.leaveEndRule || 0
                pageData.formData.leaveConsumeFlag = res.data.leaveConsumeFlag
                pageData.formData.sellType = res.data.sellType.split(',').map(n => Number(n))
                pageData.formData.sellPrice = res.data.sellPrice
                pageData.formData.sellTimeStr = res.data.sellStartTime && res.data.sellEndTime ? [utils.getFormatDate(res.data.sellStartTime).substring(0,10), utils.getFormatDate(res.data.sellEndTime).substring(0,10)] : []
                pageData.formData.allPeopleNum = res.data.allPeopleNum
				pageData.formData.allPeopleNum1 = res.data.allPeopleNum
                pageData.formData.sellOneLimitMin = res.data.sellOneLimitMin
                pageData.formData.sellOneLimitMax = res.data.sellOneLimitMax
                pageData.formData.courseNameId = res.data.courseNameId
                pageData.formData.courseTime = res.data.courseTime
                pageData.formData.applyAge = res.data.applyAge
                pageData.formData.sellType = res.data.sellType.split(',').map(n => Number(n))
				pageData.checkCoachList = res.data.teacherList
                // if (res.data.courseTeacher) {
				// 	res.data.courseTeacher.split(',').map((n) => {
				// 		var arr = pageData.coachList.filter(z =>  z.id == n);
				// 		if (arr.length > 0) {
				// 			pageData.checkCoachList.push(arr[0]);
				// 		}
				// 	})
				// }
                pageData.formData.courseTrainIds =  res.data.courseTrainIds && res.data.courseTrainIds.split(',').map(n => Number(n))
                let arry = pageData.cover.filter((item)=>{ return item == res.data.courseCoverPic})
			    if (arry.length) {
                   	pageData.cover.forEach((item, index)=>{
			    		if (item == res.data.courseCoverPic) {
                	        pageData.coverCheck = index+''
			    		}
			    	})
			    } else {
			    	pageData.coverCheck = '2'
			    	if (res.data.courseCoverPic) {
			    		pageData.cover.push(res.data.courseCoverPic)
			    	}
			    }
                instance.txt.html(res.data.courseContent);
            })
        }
    })
</script>
<style scoped lang="stylus">
    .coach_list {
		max-height: 400px;
		overflow: auto;
		div {
			background-color: #FAFAFA;
		}
		div:nth-child(even) {
			background-color: white;
		}
	}
    .add_coach_title {
    	background-color: #F4F9FF;
    	overflow: hidden;
    	border-bottom: 1px solid #eee;
        span {
	    	width: 33.33%;
	    	float: left;
	    }
    }
	.cover_img {
			position relative;

			.cover_dele {
				position absolute;
				left 0;
				top 0;
				bottom 0;
				right 0;
				background-color rgba(0, 0, 0, .3);
				color white;
				text-align center;
				cursor pointer;
				display none;
				line-height 80px;
			}
		}
		.cover_img:hover .cover_dele {
			display block;
		}
/deep/ .el-input-number .el-input__inner{
  text-align: left;
}
</style>