<template>
    <workArea :barlist="barlist">
    <div class="bg-fff pl16 pt10 pr16">
        <el-tabs v-model="pageData.tabNum" @tab-click="handleClick">
            <el-tab-pane label="上课分析" name="0" v-if="utils.getMenuRole(32401)"></el-tab-pane>
            <el-tab-pane label="教务分析" name="1" v-if="utils.getMenuRole(32402)"></el-tab-pane>
            <el-tab-pane label="入场分析" name="2" v-if="utils.getMenuRole(32403)"></el-tab-pane>
            <el-tab-pane label="消费分析" name="3" v-if="utils.getMenuRole(32404)"></el-tab-pane>
            <el-tab-pane label="会员分析" name="4" v-if="utils.getMenuRole(32405)"></el-tab-pane>
        </el-tabs>
        <component :is="components[pageData.tabNum]" :tab="pageData.tabNum"></component>
    </div>
    </workArea>
</template>
<script setup>
  import { reactive, onMounted, onBeforeUnmount } from 'vue';
  import classAnalyse from '@/views/branch-store/datacenter/workData/classAnalyse.vue' // 上课分析
  import workAnalyse from '@/views/branch-store/datacenter/workData/workAnalyse.vue' // 教务分析
  import entranceAnalyse from '@/views/branch-store/datacenter/workData/entranceAnalyse.vue' // 入场分析
  import consumeAnalyse from '@/views/branch-store/datacenter/workData/consumeAnalyse.vue' // 消费分析
  import memberAnalyse from '@/views/branch-store/datacenter/workData/memberAnalyse.vue' // 会员分析
  import workArea from '@/components/branch-store/workArea.vue'; 
  import utils from '@/common/utils.js'; 
  const barlist = [{ name : '首页' },{ name : '数据中心' },{ name : '教务数据' }];
  const components = [classAnalyse, workAnalyse, entranceAnalyse, consumeAnalyse, memberAnalyse];
  const pageData = reactive({
      tabNum : '0'
  });
  var menuId = [32401,32402,32403,32404,32405]; //会员有权限tab默认项
  for(var i = 0;i < menuId.length;i++){
  	if(utils.getMenuRole(menuId[i])){
  		pageData.tabNum = i.toString();
  		break;
  	}
  }  
    function handleClick(tab) {
      localStorage.setItem('workData', tab.props.name)
	}
	onMounted(()=> {
      if (localStorage.getItem('workData') != null) {
          pageData.tabNum = localStorage.getItem('workData')
	    }
	})
  onBeforeUnmount(() => {
		localStorage.removeItem('workData')
	})
    
</script>

<style>
</style>

