<template>
	<ul class="move_sort">
		<template v-for="(item,index) in MoveData.lists" :key="index">
			<li v-if="item" 
				:draggable="first && index == 0 ? false : true"
				@dragenter="enter"
				@dragstart="start"
				@dragend="end"
				:class="{active:MoveData.activeIndex == index && !border,leftBorder:MoveData.activeIndex == index && border,borderCss:border}"
				:data-index="index"
				:style="{display:index == 0 && MoveData.lists.length >= hide ? 'none' : ''}"
			>
				<slot :name="index"></slot>
			</li>
		</template>
	</ul>
</template>

<script setup>
	const propData = defineProps({
	  list:Array,
	  first:Boolean,//是否第一个不移动
	  border:String,//有显示左边框状态  无显示顶部边框状态
	  hide:Number //小于几隐藏第一个
	})
	import {ref,reactive} from 'vue'
	const MoveData = reactive({
		activeIndex:-1,
		startIndex:-1,
		lists:[]
	})
	MoveData.lists = propData.list;
	const emit = defineEmits(['moveover'])
	var state = false;
	function enter(e){
		getParentNode(e.target,(index)=>{
			if(propData.first && index == 0){
				return false;
			}
			MoveData.activeIndex = index;
		})
		return false;
		// e.path.map((n)=>{
		// 	if(n.nodeName === 'LI'){
		// 		if(propData.first && n.dataset.index == 0){
		// 			return false;
		// 		}
		// 		MoveData.activeIndex = n.dataset.index;
		// 		return false;
		// 	}
		// })
	}
	function start(e){
		getParentNode(e.target,(index)=>{
			if(propData.first && index == 0){
				return false;
			}
			MoveData.startIndex = index;
		});
		return false;
		// e.path.map((n)=>{
		// 	if(n.nodeName === 'LI'){
		// 		if(propData.first && n.dataset.index == 0){
		// 			return false;
		// 		}
		// 		MoveData.startIndex = n.dataset.index;
		// 		return false;
		// 	}
		// })
		
		
	}
	function end(e){
		// if(propData.first && MoveData.activeIndex == 0){
		// 	return false;
		// }
		if(MoveData.startIndex != MoveData.activeIndex) {
			var sLi = MoveData.lists[MoveData.startIndex];
			MoveData.lists.splice(MoveData.activeIndex,0,sLi);
			var index = MoveData.startIndex < MoveData.activeIndex ? MoveData.startIndex : Number(MoveData.startIndex)+1; 
			MoveData.lists.splice( index,1);
			// var eli = MoveData.lists[MoveData.activeIndex];
			// MoveData.lists[MoveData.activeIndex] = sLi;
			// MoveData.lists[MoveData.startIndex] = eli;
			// MoveData.startIndex = MoveData.activeIndex;
			emit('moveover',MoveData.lists);
		}
		MoveData.startIndex = -1;
		MoveData.activeIndex = -1;
	}
	function getParentNode(node,callback){
		if(node.nodeName == 'LI'){
			callback(node.dataset.index);
		}else{
			if(node.parentNode){
				getParentNode(node.parentNode,callback);
			}else{
				callback('');
			}
		}
	}
</script>

<style lang="stylus">
	.move_sort {
		width 100%;
		li.active{
			border-top 2px dashed blue;
		}
		li.borderCss{
			float left;
			width 50%
			margin-bottom 15px;
		}
		li.leftBorder{
			border-left 2px dashed blue !important;
		}
		
	}
	
</style>
