<template>
	<div class="course_card">
		<formQuery :queryOpts="pageData.queryOpts" @query="getTableData"></formQuery>
		<div class="mb16 rx-sc">
			<el-button type="primary" @click="updateRow('')" v-if="utils.getMenuRole(3160100)">新增入场卡</el-button>
			<el-button type="danger" plain @click="deletSomeOne" v-if="utils.getMenuRole(3160101)">批量删除</el-button>
		</div>
		<div>
			<el-table :data="pageData.tableData" stripe style="width: 100%" @selection-change="selectionChange">
				<el-table-column type="selection" width="55" :selectable="(row)=>{return row.sid != 0}" />
			    <el-table-column prop="productName" label="入场卡名称" show-overflow-tooltip>
					<template #default="scope">
						<a href="javascript:;" class="color-409EFF tool_btn" v-if="scope.row.sid != 0 && utils.getMenuRole(3160103)" @click="updateRow(scope.row.encryptionId)">{{ scope.row.productName }}</a>
						<span v-else>{{scope.row.productName}}</span>
					</template>
				</el-table-column>
			    <el-table-column prop="productTyp" label="入场卡类型">
					<template #default="scope">
						<span>{{scope.row.productTyp === 1 ? '次卡' : '期限卡'}}</span>
					</template>
				</el-table-column>
			   <el-table-column prop="productNum" label="可消费次数">
					<template #default="scope">
						{{ scope.row.productTyp === 1 ? scope.row.productNum + '次' : scope.row.productNum ? scope.row.productNum + '次' : '不限次' }}	
					</template>
				</el-table-column>
          <el-table-column prop="productCycle" label="有效期" >
            <template #default="scope">
              <span>
                {{
                  (scope.row.productCycle || '') + (scope.row.productCycle ? scope.row.ifMonth === 1 ? '天' : scope.row.ifMonth === 2 ? '月' : '年' : '不限时')
                }}
              </span>
            </template>
          </el-table-column>
			    <el-table-column prop="productPrice" label="售价(元)" />
			    <el-table-column prop="consumeStoreName" label="支持入场门店" />
			    <el-table-column prop="name" label="入场卡状态" v-if="utils.getMenuRole(3160102)">
			    	<template #default="scope">
			    		<div @click="updateShelfState(scope.row)">
			    			<!-- el-switch 初始值为0默认回走一次onchange事件 所以不用 -->
			    			<el-switch v-model="scope.row.shelfState" inline-prompt :active-value="1" :inactive-value="0" ></el-switch>
			    		</div>
			    	</template>
			    </el-table-column>
			    <el-table-column prop="storeName" label="发布渠道" />
				<el-table-column label="操作" width="160">
					<template #default="scope">
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="lookDetail(scope.row.encryptionId)">查看详情</a>
					</template>
				</el-table-column>
			</el-table>
			<div class="mt20 rx-ec">
			    <el-pagination
			      v-model:currentPage="pageData.searchForm.page"
			      :page-sizes="[15,20,30,50,100]"
			      :page-size="pageData.searchForm.limit"
			      layout="total, sizes, prev, pager, next, jumper"
			      :total="pageData.totalCount"
			      @size-change="pageSizeChange"
			      @current-change="()=>{getList()}"
			    >
			    </el-pagination>
			</div>
		</div>
	</div>
	<Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave"/>
</template>

<script setup>
	import {ref,reactive,onMounted,onActivated} from 'vue';
	import {useRouter,onBeforeRouteLeave} from 'vue-router';
	import {useStore} from 'vuex'
	import {Refresh,DArrowRight,Connection,Close} from '@element-plus/icons-vue'
	import utils from '@/common/utils.js'
  	import {list4Store, updateShelf,productDelete} from '@/api/cards.js';
  	import {ElMessage} from "element-plus";
  	import Confirm from '@/components/Confirm.vue'
  	import formQuery from '@/components/branch-store/formQuery.vue';
  const store = useStore();
	const router = useRouter();
	const pageData = reactive({
		tabNum:'0',
		storeList:[],
		tableData:[],
		totalCount:0,
		searchForm:{
			page:1,
			limit:15,
			type:2,
			productName:'',
			productTyp:'',
			shelfState:'1',
			storeId:'',
			sortType:'1'
		},
		confirmTitle:'',
		confirmText:'',
		deleCheckData:[],
		queryOpts:{
			fields:[{
				type : 'input',
				fieldName : 'productName',
				placehold : '请输入课程卡名称'
			},{
				type : 'select',
				fieldName : 'productTyp',
				placehold : '选择卡类型',
				options : utils.getDict('productType',{label : '全部类型' ,value : ''})
			},{
				type : 'select',
				fieldName : 'shelfState',
				placehold : '选择卡状态',
				options : utils.getDict('shelfState',{label : '全部状态' ,value : ''}),
				value:'1'
			},{
				type : 'select',
				fieldName : 'storeId',
				placehold : '全部发布渠道',
				options : [],
				value:''
			},{
				type : 'select',
				fieldName : 'sortType',
				placehold : '排序方式',
				options : utils.getDict('sortType'),
				value:'1'
			}]
		}
	})
	utils.getDynaDict('storeList',{label : '全部发布渠道' ,value : ''},function(res){
	    for(var i=0;i<res.length;i++){
	        pageData.queryOpts.fields[3].options.push(res[i]);
	    }
	});
	//筛选表单回调
	function getTableData(options){
		for(var key in options){
			pageData.searchForm[key] = options[key];
		}
		searchTable();
	}
	//搜索table
	function searchTable(){
		pageData.searchForm.page = 1;
		getList();
	}
	//切换分页数量
	function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		searchTable();
	}
	getList();
	function getList(){
		list4Store(pageData.searchForm).then((res)=>{
			if(res.code == 0){
				pageData.tableData = res.data;
        pageData.totalCount = res.count;
			}
		})
	}
	//新增或编辑
	function updateRow(id){
		router.push({path:'/branch/entranceCard',query:{id}});
	}
	//查看详情
	function lookDetail(id){
		router.push({path:'/branch/cardDetail',query:{id}});
	}

 
  
  //上下架操作
  function updateShelfState(row){
  	updateShelf({
  		productIds:row.encryptionId,
  		shelfState:row.shelfState ? 1 : 0
  	}).then((res)=>{
  		if(res.code == 0){
  			ElMessage({
  			  type: 'success',
  			  message: row.shelfState ?'上架成功':'下架成功',
  			})
  			searchTable();
  		}
  	})
  }
  //批量删除员工
  const confirmRef = ref();
  function selectionChange(data){
  	pageData.deleCheckData = data.map(n=>n.encryptionId);
  }
  function deletSomeOne(){
  	if(pageData.deleCheckData.length == 0){
  		ElMessage({
  			type: 'warning',
  			message: '请选择要删除的卡项',
  		})
  		return false;
  	}
  	pageData.confirmTitle = '确定要删除选中的数据吗？';
  	confirmRef.value.show();
  }
  function confirmSave(){
  	productDelete({
  		encryptionId:pageData.deleCheckData.join(',')
  	}).then((res)=>{
  		if(res.code == 0){
  			ElMessage({
  				type: 'success',
  				message: '删除成功',
  			})
  			searchTable();
  		}
  	})
  	
  }
  
  // 初始化页面
  onActivated(()=>{
      getList();
  })
  
  // 页面缓存
  onBeforeRouteLeave((to, from)=>{
      if (to.path === '/branch/cardDetail' || to.path === '/branch/entranceCard') {
          store.commit('SetKeep',{keepLiveRoute : ['BranchCards']});
      } else {
          store.commit('SetKeep',{keepLiveRoute : []});
      }
  })

</script>

<style scoped lang="stylus">
	.course_card{
		.bind_table{
			position relative;
			overflow hidden;
			transition 0.5s;
			.bind_table_show{
				position absolute;
				right 10px;
				top 9px;
				z-index 100;
				color #409EFF;
				
			}
		}
		.bind_table_num{
			border none;
			border-bottom 1px solid #999;
			background-color initial;
		}
    .stateIcon{
      position relative;
    }
    .stateIcon::after{
      content '';
      position absolute;
      left 0;
      top 50%;
      transform translateY(-50%);
      width 8px;
      height 8px;
      border 2px solid #FBE3E3;
      background-color #FE8585;
      border-radius 100%;
    }
    .stateIcon.blue::after{
      content '';
      position absolute;
      left 0;
      top 50%;
      transform translateY(-50%);
      width 8px;
      height 8px;
      border 2px solid #DAF7F0;
      background-color #44D7B6;
      border-radius 100%;
    }
	}
</style>
