<template>
    <workArea :barlist="barlist">
    <div class="bg-fff pl16 pt10 pr16">
        <el-tabs v-model="pageData.tabNum" @tab-click="handleClick">
            <el-tab-pane label="营收汇总" name="0" v-if="utils.getMenuRole(32601)"></el-tab-pane>
            <el-tab-pane label="收入统计" name="1" v-if="utils.getMenuRole(32602)"></el-tab-pane>
            <el-tab-pane label="支出统计" name="2" v-if="utils.getMenuRole(32603)"></el-tab-pane>
            <!-- <el-tab-pane label="储值卡分析" name="3" v-if="utils.getMenuRole(32604)"></el-tab-pane> -->
        </el-tabs>
        <component :is="components[pageData.tabNum]" :tab="pageData.tabNum"></component>
    </div>
    </workArea>
</template>
<script setup>
  import { reactive, onMounted, onBeforeUnmount } from 'vue';
  import revenueGather from '@/views/branch-store/datacenter/financeData/revenueGather.vue' // 营收汇总
  import income from '@/views/branch-store/datacenter/financeData/income.vue' // 收入分析
  import expend from '@/views/branch-store/datacenter/financeData/expend.vue' // 支出分析
//   import storedValueCard from '@/views/branch-store/datacenter/financeData/storedValueCard.vue' // 储值卡分析
  import workArea from '@/components/branch-store/workArea.vue'; 
  import utils from '@/common/utils.js'; 
  const barlist = [{ name : '首页' },{ name : '数据中心' },{ name : '教务数据' }];
  const components = [revenueGather, income, expend];
  const pageData = reactive({
      tabNum : '0'
  });
  var menuId = [32601,32602,32603]; //会员有权限tab默认项
  for(var i = 0;i < menuId.length;i++){
  	if(utils.getMenuRole(menuId[i])){
  		pageData.tabNum = i.toString();
  		break;
  	}
  }  
    function handleClick(tab) {
      localStorage.setItem('financeData', tab.props.name)
	}
	onMounted(()=> {
      if (localStorage.getItem('financeData') != null) {
          pageData.tabNum = localStorage.getItem('financeData')
	    }
	})
  onBeforeUnmount(() => {
		localStorage.removeItem('financeData')
	})
    
</script>

<style>
</style>

