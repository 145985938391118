<template>
	<div class="pt20 pb20">
		<div class="rx-bc bg-f2f2f2 h35">
			<div class="rx-sc">
				<div class="w95 h35 rx-cc color-666 c_p bdr2" :class="{'color-fff bg-03A3FF':tab == 1}" @click="tabClick(1)">
					预约课程消费</div>
				<div class="w95 h35 rx-cc color-666 c_p bdr2" :class="{'color-fff bg-03A3FF':tab == 2}" @click="tabClick(2)">
					入场消费</div>
				<div class="w95 h35 rx-cc color-666 c_p bdr2" :class="{'color-fff bg-03A3FF':tab == 3}" @click="tabClick(3)">
					总未消统计</div>
			</div>
			<div class="rx-cc mr20">
				<span class="color-999 mr20 ft12">温馨提示：当前 页面数据仅统计截止至当前时间</span>
				<span class="color-main c_p t_d_u ft12" @click="formulaClick">计算公式</span>
			</div>
		</div>
		<div class="rx-bc mt20">
			<div class="rx-sc">
				<div class="w210 mr12" v-show="tab !=3 ">
					<el-select v-model="searchQuery.timeIn" placeholder="日期类型">
						<el-option v-for="item in searchQueryList.timeTypeList" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</div>
				<div class="w210 mr12" v-show="searchQuery.timeIn == -1">
					<el-date-picker style="width: 210px; height: 32px" format="YYYY-MM-DD" value-format="YYYY-MM-DD"
						v-model="searchQuery.searchTime" type="daterange" start-placeholder="开始日期"
						end-placeholder="结束日期" :disabled-date="datePickerDisabled.disabledDate" clearable>
					</el-date-picker>
				</div>
				<div class="w210 mr12" v-show="searchQuery.timeIn == -2">
					<el-date-picker style="width: 210px; height: 32px" format="YYYY-MM" value-format="YYYY-MM"
						v-model="searchQuery.searchTime" type="monthrange" start-placeholder="开始月份"
						end-placeholder="结束月份" :disabled-date="datePickerDisabled.setMonthDisabled">
					</el-date-picker>
				</div>
				<div class="w210 mr12" v-show="tab != 2">
					<el-select v-model="searchQuery.courseType" filterable placeholder="全部课程类型"
						@change="courseTypeChange">
						<el-option label="全部课程类型" value="" />
						<el-option v-for="item in searchQueryList.courseTypeList" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</div>
				<div class="w210 mr12" v-show="tab != 2">
					<el-select v-model="searchQuery.courseNameIds" multiple collapse-tags filterable placeholder="全部课程">
						<el-option v-for="item in searchQueryList.courseDataList" :key="item.courseNameId"
							:label="item.courseName" :value="item.courseNameId" />
					</el-select>
				</div>
				<div class="w210 mr12" v-show="tab == 1">
					<el-select v-model="searchQuery.cardType" filterable placeholder="全部卡类型" @change="cardTypeChange">
						<el-option label="全部卡类型" value="" />
						<el-option v-for="item in searchQueryList.cardTypeList" :key="item.value" :label="item.label"
						 :value="item.value" />
					</el-select>
				</div>
				<div class="w210 mr12" v-show="tab == 3">
					<el-select v-model="searchQuery.cardType" filterable placeholder="全部卡类型" @change="cardTypeChange">
						<el-option label="全部卡类型" value="" />
						<el-option v-for="item in searchQueryList.cardTypeList1" :key="item.value" :label="item.label"
						 :value="item.value" />
					</el-select>
				</div>
				<div class="w210 mr12">
					<el-select v-model="searchQuery.productId" multiple collapse-tags filterable placeholder="全部卡">
						<el-option v-for="item in searchQueryList.cardDataList" :key="item.productId"
							:label="item.productName" :value="item.productId" />
					</el-select>
				</div>
				<el-button type="primary" @click="tabSearchClick" :icon="Search">搜索</el-button> 
			</div>
			<div class="rx-cc">
				<el-button :icon="Download" @click="downloadEcharts" v-show="tab != 3">下载图表</el-button>
				<el-button :icon="Upload" @click="downloadTable">导出表格</el-button>
			</div>
		</div>
		<div class="rx-sc pl10 pr10 pt20 pb20 posi-r mt20" style="background-color: #EFF6FF;">
			<div class="ww25 pl10 pr10" v-show="tab != 3">
				<div class="bdr4 bg-fff rx-bc pl35 pr35 h110">
					<div>
						<h3 class="fwb color-333 ft32 mb5">{{summaryData.allTimes}}</h3>
						<p class="fwb color-999 ft16">消费总次数</p>
					</div>
					<div class="iconBox">
						<img class="w60" src="@/assets/bookIcon.png" alt="" v-show="tab == 1">
						<img class="w60" src="@/assets/itemIcon.png" alt="" v-show="tab == 2">
						<span class="bg-FA73B0 rx-cc w25 h25 bdr100 color-fff fwb iconItem">次</span>
					</div>
				</div>
			</div>
			<div class="ww25 pl10 pr10" v-show="tab != 3">
				<div class="bdr4 bg-fff rx-bc pl35 pr35 h110">
					<div>
						<h3 class="fwb color-333 ft32 mb5">{{summaryData.allDays}}</h3>
						<p class="fwb color-999 ft16">按天消费总天数</p>
					</div>
					<div class="iconBox">
						<img class="w60" src="@/assets/bookIcon.png" alt="" v-show="tab == 1"> 
						<img class="w60" src="@/assets/itemIcon.png" alt="" v-show="tab == 2">
						<span class="bg-73B0FA rx-cc w25 h25 bdr100 color-fff fwb iconItem">天</span>
					</div>
				</div>
			</div>
			<div class="ww25 pl10 pr10" v-show="tab != 3">
				<div class="bdr4 bg-fff rx-bc pl35 pr35 h110">
					<div>
						<h3 class="fwb color-333 ft32 mb5">￥{{summaryData.allMoney}}</h3>
						<p class="fwb color-999 ft16">消费总金额</p>
					</div>
					<div class="iconBox">
						<img class="w60" src="@/assets/boxIcon.png" alt="">
						<span class="bg-B773FA rx-cc w25 h25 bdr100 color-fff fwb iconItem">额 </span>
					</div>
				</div>
			</div>
			<div class="ww25 pl10 pr10" v-show="tab == 3">
				<div class="bdr4 bg-fff rx-bc pl35 pr35 h110">
					<div>
						<h3 class="fwb color-333 ft32 mb5">{{summaryData.allTimes}}</h3>
						<p class="fwb color-999 ft16">课卡未消次数</p>
					</div>
					<div class="iconBox">
						<img class="w60" src="@/assets/cardIcon.png" alt="">
						<span class="bg-FA73B0 rx-cc w25 h25 bdr100 color-fff fwb iconItem">次</span>
					</div>
				</div>
			</div>
			<div class="ww25 pl10 pr10" v-show="tab == 3">
				<div class="bdr4 bg-fff rx-bc pl35 pr35 h110">
					<div>
						<h3 class="fwb color-333 ft32 mb5">{{summaryData.allTimesCourse}}</h3>
						<p class="fwb color-999 ft16">课程未消节数</p>
					</div>
					<div class="iconBox">
						<img class="w60" src="@/assets/cardIcon.png" alt="">
						<span class="bg-F8CE66 rx-cc w25 h25 bdr100 color-fff fwb iconItem">次</span>
					</div>
				</div>
			</div>
			<div class="ww25 pl10 pr10" v-show="tab == 3">
				<div class="bdr4 bg-fff rx-bc pl35 pr35 h110">
					<div>
						<h3 class="fwb color-333 ft32 mb5">{{summaryData.allTimesEnter}}</h3>
						<p class="fwb color-999 ft16">入场卡未消次数</p>
					</div>
					<div class="iconBox">
						<img class="w60" src="@/assets/cardIcon.png" alt="">
						<span class="bg-73B0FA rx-cc w25 h25 bdr100 color-fff fwb iconItem">次</span>
					</div>
				</div>
			</div>
			<div class="ww25 pl10 pr10" v-show="tab == 3">
				<div class="bdr4 bg-fff rx-bc pl35 pr35 h110">
					<div>
						<h3 class="fwb color-333 ft32 mb5" v-if="summaryData.allMoney<99999.99">￥{{ summaryData.allMoney }}</h3>
						<h3 class="mb5" v-if="summaryData.allMoney>99999.99"><span class="fwb color-333 ft32">￥{{ Number(summaryData.allMoney/10000).toFixed(2) }}</span> <span>万</span></h3>
						<p class="fwb color-999 ft16">未消总金额</p>
					</div>
					<div class="iconBox">
						<img class="w60" src="@/assets/cardIcon.png" alt="">
						<span class="bg-F16443 rx-cc w25 h25 bdr100 color-fff fwb iconItem">额</span>
					</div>
				</div>
			</div>
			<img style="position: absolute;right: 40px;top: 5%;height: 90%;" v-show="tab != 3"
				src="@/assets/dataIcon.png" alt="">
		</div>
		<div class="mt20 pl10 fwb ft16 color-333" style="border-left: 4px solid #03A3FF;">
			消费金额趋势 
		</div>
		<div id="main" class="h500 mt20" v-show="tab != 3"></div>
		<div class="mt20 color-999 ft12" v-show="tab == 1">温馨提示：以下预约课程消费包含[课程卡期限卡]、[体验卡期限卡]之按天自动扣费金额。</div>	
		<div class="mt20 color-999 ft12" v-show="tab == 2">温馨提示：以下入场消费包含[入场卡期限卡]之按天自动扣费金额。</div>	
		<div class="mt20 color-999 ft12" v-show="tab == 3">温馨提示：各商品类目的未消统计仅为在本门店购买并消费后，剩余的未消情况。</div>	
		<div class="mt20">
			<el-table :data="tableData.list.slice(tabForm.page*tabForm.limit-tabForm.limit,tabForm.page*tabForm.limit)"
				style="width: 100%">
				<el-table-column prop="analysisDate" fixed label="统计日期" v-if="tab != 3 " />
				<el-table-column label="课程卡" align="center" v-if="tab == 1 && (pageData.courseCardTimes != -1 || pageData.courseCardDays != -1 || pageData.courseCardMoney != -1)">
					<el-table-column prop="courseCardTimes" label="消费次数" sortable  v-if="pageData.courseCardTimes != -1" />
					<el-table-column prop="courseCardDays" label="消费天数" sortable   v-if="pageData.courseCardDays != -1"/>
					<el-table-column prop="courseCardMoney" label="消费金额" sortable  v-if="pageData.courseCardMoney != -1" />
				</el-table-column>
				<el-table-column label="体验卡" align="center" v-if="tab == 1 && (pageData.experienceCardTimes != -1 || pageData.experienceCardDays != -1 || pageData.experienceCardMoney != -1)">
					<el-table-column prop="experienceCardTimes" label="消费次数" sortable v-if="pageData.experienceCardTimes != -1" />
					<el-table-column prop="experienceCardDays" label="消费天数" sortable  v-if="pageData.experienceCardDays != -1"/>
					<el-table-column prop="experienceCardMoney" label="消费金额" sortable v-if="pageData.experienceCardMoney != -1" />
				</el-table-column>
				<el-table-column prop="storeCardMoney" label="储值卡消费金额" v-if="tab == 1 && pageData.storeCardMoney != -1"/>
				<el-table-column prop="payOnLineMoney" label="单次预约微信支付" v-if="tab == 1 && pageData.payOnLineMoney != -1"/>
				<el-table-column label="团体课" align="center" v-if="tab == 1 && (pageData.teamCourseTimes != -1 || pageData.teamCourseMoney != -1)">
					<el-table-column prop="teamCourseTimes" label="消费节数" sortable v-if="pageData.teamCourseTimes != -1" />
					<el-table-column prop="teamCourseMoney" label="消费金额" sortable v-if="pageData.teamCourseMoney != -1" />
				</el-table-column>
				<el-table-column label="私教课" align="center" v-if="tab == 1 && (pageData.privateCourseTimes != -1 || pageData.privateCourseMoney != -1)">
					<el-table-column prop="privateCourseTimes" label="消费节数" sortable v-if="pageData.privateCourseTimes != -1" />
					<el-table-column prop="privateCourseMoney" label="消费金额" sortable v-if="pageData.privateCourseMoney != -1" />
				</el-table-column>
				<el-table-column prop="allTimes" label="消费总次数" sortable v-if="tab != 3 && pageData.allTimes != -1"/>
				<el-table-column prop="allDays" label="消费总天数" sortable v-if="tab != 3  && pageData.allDays != -1"/>
				<el-table-column prop="allMoney" label="消费总金额" sortable v-if="tab != 3  && pageData.allMoney != -1"/>
				
				<!-- 未消统计 -->
				<el-table-column prop="typeName" label="商品类型" v-if="tab == 3 "/>
				<el-table-column prop="allMoney" label="总购买金额" v-if="tab == 3 "/>
				<el-table-column prop="consumeMoney" label="已消费总金额" v-if="tab == 3 "/>
				<el-table-column prop="unConsumeMoney" label="未消总金额" v-if="tab == 3 ">
					<template #default="scope">
						<span v-if="scope.row.type != 3">{{scope.row.unConsumeMoney}}</span>
						<div v-else>
							<p class="ft12 l_h17">本金:{{scope.row.unConsumeBaseMoney}}</p>
							<p class="ft12 l_h17">赠送:{{scope.row.unConsumeGiveMoney}}</p>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="expireMoney" label="已过期总金额" v-if="tab == 3 "/>
				<el-table-column prop="refundMoney" label="已退款金额" v-if="tab == 3 "/>
				<el-table-column prop="productNum" label="未消总次/节数" v-if="tab == 3 "/>
				<el-table-column prop="unConsumeDays" v-if="tab == 3 ">
					<template #header>
						<div class="rx-sc">
							<span>未消总天数</span>
							<el-tooltip
								class="box-item"
								effect="dark"
								content="当会员购买的期限卡未开始计时情况下，购买天数月份暂时按照30天计算，年份按照365天计算，开始计时后恢复正常周期计算"
								placement="top"
							  >
							  <img class="w15 h15 ml5 v_a_m" src="@/assets/wenhao.png" >
							</el-tooltip>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" v-if="tab == 3 ">
					<template #default="scope">
						<el-link class="t_d_u" :underline="false" type="primary" @click="lookDetailEvent(scope.row)">详情</el-link>
					</template>
				</el-table-column>
			</el-table>
			<div class="mt20 rx-ec" v-show="tableData.list.length > 0">
				<el-pagination v-model:currentPage="tabForm.page" :page-sizes="[15,20,30,50,100]"
					:page-size="tabForm.limit" layout="total, sizes, prev, pager, next, jumper" :total="tabForm.count"
					@size-change="pageSizeChange" @current-change="pageChange">
				</el-pagination>
			</div>
		</div>
		<Formula v-model="formulaShow" :type="formulaType"></Formula>
		<el-dialog
		    v-model="lookDetailData.show"
		    :title="'【'+lookDetailData.title+'】未消详情'"
			width="1200px"
		    :before-close="()=>{lookDetailData.show = false}"
		    custom-class="popwechat"
		  >
		    <div class="p20">
				<div class="rx-sc mb20">
					<div class="w210 mr12">
						<el-input v-model="lookDetailForm.searchKey" placeholder="请输入会员姓名" />
					</div>
					<el-button type="primary" @click="searchDetailList" :icon="Search">搜索</el-button>
				</div>	
				<div class="mb20 color-999 ft12">温馨提示：当会员购买的期限卡未开始计时情况下，购买天数月份暂时按照30天计算，年份按照365天计算，开始计时后恢复正常周期计算</div>
				<el-table :data="lookDetailData.list"
					style="width: 100%"
					height="600"
				>
					<el-table-column prop="realName" label="会员姓名" >
						<template #default="scope">
							<a href="javascript:;" class="color-409EFF tool_btn" @click="toMemberDetail(scope.row.encryptionCardMyId)">{{scope.row.realName}}</a>
						</template>
					</el-table-column>
					<el-table-column label="购买情况" align="center" v-if="lookDetailData.type != 3 && lookDetailData.type < 5">
						<el-table-column prop="buyTimes" label="购买次数"  />
						<el-table-column prop="buyDays" label="购买天数"  />
						<el-table-column prop="buyMoney" label="购买金额"  />
					</el-table-column>
					<el-table-column label="消费情况" align="center" v-if="lookDetailData.type != 3 && lookDetailData.type < 5">
						<el-table-column prop="consumeTimes" label="消费次数"  />
						<el-table-column prop="consumeDays" label="消费天数"  />
						<el-table-column prop="consumeMoney" label="消费金额"  />
					</el-table-column>
					<el-table-column label="未消情况" align="center" v-if="lookDetailData.type != 3 && lookDetailData.type < 5">
						<el-table-column prop="unConsumeTimes" label="未消次数"  />
						<el-table-column prop="unConsumeDays" label="未消天数"  />
						<el-table-column prop="unConsumeMoney" label="未消金额"  />
					</el-table-column>
					<el-table-column label="过期情况" align="center" v-if="lookDetailData.type != 3 && lookDetailData.type < 5">
						<el-table-column prop="expireTimes" label="过期次数"  />
						<el-table-column prop="expireMoney" label="过期金额"  />
					</el-table-column>
					<!-- 储值卡 -->
					<el-table-column label="购买情况" align="center" v-if="lookDetailData.type == 3">
						<el-table-column prop="buyStoreMoneyBase" label="购买金额"  />
						<el-table-column prop="buyStoreMoneyGive" label="赠送金额"  />
					</el-table-column>
					<el-table-column prop="consumeMoney" align="center" label="消费金额" v-if="lookDetailData.type == 3 "/>
					<el-table-column label="未消情况" v-if="lookDetailData.type == 3">
						<el-table-column prop="storeMoneyBase" label="剩余本金"  />
						<el-table-column prop="storeMoneyGive" label="剩余赠送"  />
					</el-table-column>
					<el-table-column prop="expireMoney" label="过期金额" v-if="lookDetailData.type == 3 "/>
					<!-- 课程 -->
					<el-table-column label="购买情况" align="center" v-if="lookDetailData.type > 5">
						<el-table-column prop="buyTimes" label="购买节数"  />
						<el-table-column prop="buyGiveTimes" label="赠送节数"  />
						<el-table-column prop="buyMoney" label="购买金额"  />
					</el-table-column>
					<el-table-column label="消费情况" align="center" v-if="lookDetailData.type > 5">
						<el-table-column prop="consumeTimes" label="消费节数"  />
						<el-table-column prop="consumeMoney" label="消费金额"  />
					</el-table-column>
					<el-table-column label="未消情况" align="center" v-if="lookDetailData.type > 5">
						<el-table-column prop="unConsumeTimes" label="未消节数"  />
						<el-table-column prop="unConsumeMoney" label="消费金额"  />
					</el-table-column>
					
					<el-table-column prop="refundMoney" label="退款金额" />
			
				</el-table>
				<div class="mt20 rx-ec" v-show="lookDetailData.list.length > 0">
					<el-pagination v-model:currentPage="lookDetailForm.page" :page-sizes="[15,20,30,50,100]"
						:page-size="lookDetailForm.limit" layout="total, sizes, prev, pager, next, jumper" :total="lookDetailData.count"
						@size-change="detailSizeChange" @current-change="getDetailList">
					</el-pagination>
				</div>
			</div>
			<template #footer>
			 	<div class="dialog-footer">
			     	<el-button @click="lookDetailData.show = false">取消</el-button>
			     	<el-button type="primary" @click="lookDetailData.show = false">确定</el-button>
			 	</div>
			 </template>
		</el-dialog>
	</div>
</template>

<script setup>
	import {
		reactive,
		ref,
		onMounted,
		onBeforeUnmount
	} from 'vue';
	import {
		Search,
		Upload,
		Download
	} from '@element-plus/icons-vue';
	import * as echarts from 'echarts';
	import utils from '@/common/utils.js'

	import {
		courseList
	} from '@/api/course.js'
	import {
		list4Store
	} from '@/api/cards.js';
	import {
		getBookAnalysis,
		getEnterAnalysis,
		getLeftAnalysis,
		getLeftCardDetailAnalysisData
	} from '@/api/dataCenter.js'
	import {
		ElMessage
	} from 'element-plus'
	import {useRouter,onBeforeRouteLeave} from 'vue-router';
	import Formula from '@/components/branch-store/datacenter/formula.vue';
	const router = useRouter();
	var myChart = '';
	//日期范围/月份范围控件配置
	const datePickerDisabled = reactive({
		disabledDate(time) {
		  return time.getTime() > new Date().getTime()
		},
		setMonthDisabled(time) {
		    // 获取当前的月份信息
		    const date = new Date(); // 获取当前的时间基本信息，值是这样的： Tue Jul 20 2021 14:59:43 GMT+0800 (中国标准时间)
		    const year = date.getFullYear(); // 获取当前年份，值是这样的： 2021
		    let month = date.getMonth() + 1; // 获取当前月份，值是这样的： 6   getMonth()方法返回值是0-11，也就是1月份到12月份，所以要加上1，才是当前月份
		    if (month >= 1 && month <= 9) {
		      // 如果是1月到9月就要在前面补上一个0   比如：02、07    月份这样表示
		      month = "0" + month;
		    }
		    const nowDate = year.toString() + month.toString(); // 转换成字符串拼接，最终得到年和月，比如此时的时间是2021年7月20号，所以nowDate的值是：202107
		    // 获取时间选择器的月份信息
		    const timeyear = time.getFullYear(); // 获取时间选择器的年份（有很多）
		    let timemonth = time.getMonth() + 1; // 与上面同理
		    if (timemonth >= 1 && timemonth <= 9) {
		      timemonth = "0" + timemonth;
		    }
		    const elTimeData = timeyear.toString() + timemonth.toString();
		
		    // 返回，时间选择器的日期月份大于当前日期的月份，又因为disabledDate函数是控制月份禁用不可选
		    // 所以，最终就是：时间选择器的月份大于当前的月份，就都禁用掉，所以就实现了最终效果：
		    // 大于等于当前月份都不可选 
		    return elTimeData > nowDate; // 这里虽然是字符串，但是弱类型语言js会做一个转换，是可以比较大小的如： '202107' > '202008'
		},
	})
	//搜索字段
	const searchQuery = reactive({
		timeIn: 5,
		courseNameIds: [],
		searchTime: [],
		courseType: '',
		cardType: '',
		productId:[],
	})
	//切换课类型
	const tab = ref(1);
	const tabClick = function(num) {
		tab.value = num;
		initSearchQuery();
		tabSearchClick();
		if(num == 2){
			searchQuery.cardType = 2;
			cardTypeChange();
		} else {
			courseTypeChange()
			cardTypeChange()
		}
	}

	//搜索项数据
	const searchQueryList = reactive({
		teamCourseList: [],
		courseDataList: [],
		courseTypeList: [{
				label: '团体课',
				value: 11
			},
			{
				label: '私教课',
				value: 12
			},
		],
		cardDataList: [],
		cardTypeList: [{
				label: '课程卡',
				value: 1
			},
			{
				label: '储值卡',
				value: 3
			},
			{
				label: '体验卡',
				value: 4
			}],
		cardTypeList1: [{
				label: '课程卡',
				value: 1
			},
			{
				label: '入场卡',
				value: 2
			},
			{
				label: '储值卡',
				value: 3
			},
			{
				label: '体验卡',
				value: 4
			}],
		timeTypeList: [{
				label: '近7天',
				value: 5
			},
			{
				label: '近15天',
				value: 6
			},
			{
				label: '近30天',
				value: 7
			},
			{
				label: '近60天',
				value: 8
			},
			{
				label: '近90天',
				value: 9
			},
			{
				label: '近6月',
				value: 24
			},
			{
				label: '近12月',
				value: 25
			},
			{
				label: '自定义日期',
				value: -1
			},
			{
				label: '自定义月份',
				value: -2
			}
		]
	});
	
	//获取全部团体课
	utils.getDynaDict('teamCourseNameAll', '', function(res) {
		searchQueryList.teamCourseList = res.map((n) => {
			n.value = Number(n.value);
			return n;
		});
	});
	
	//课程类型切换
	const courseTypeChange = function() {
		searchQuery.courseNameIds = [];
		courseList({
			courseType: searchQuery.courseType,
			sortType: 1,
			page: 1,
			limit: 9999
		}).then((res) => {
			if (res.code == 0) {
				searchQueryList.courseDataList = res.data;
			}
		})
	}
	//卡类型切换
	const cardTypeChange = function() {
		searchQuery.productId = [];
		list4Store({
			type: searchQuery.cardType,
			shelfState: 1,
			sortType: 1,
			page: 1,
			limit: 9999
		}).then((res) => {
			if (res.code == 0) {
				searchQueryList.cardDataList = res.data;
			}
		})
	}
	//初始化表单
	const initSearchQuery = function() {
		for (var k in searchQuery) {
			searchQuery[k] = Object.prototype.toString.call(searchQuery[k]) == '[object Array]' ? [] : '';
		}
		searchQuery['timeIn'] = 5;
		searchQueryList.cardDataList = [];
	}

	//汇总数据字段
	const summaryData = {
		allMoney: 0, //消费总金额
		allDays: 0, //按天消费总天数
		allTimes: 0, //消费总次数
		allTimesCourse:'', //课程未消节数
		allTimesEnter:'', //入场卡未消次数
	}
	//图标数据
	var getOption = {
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'cross',
				crossStyle: {
					color: '#999'
				}
			}
		},
		grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
		legend: {
			show: false
		},
		xAxis: [{
			type: 'category',
			data: [],
			axisPointer: {
				type: 'shadow'
			},
            axisLabel: {
                interval: 6,
                rotate: 35
            }
		}],
		yAxis: [{
				type: 'value',
				name: '金额/元',
			}
		],
		series:[
				{
					name: '消费金额',
					type: 'line',
					smooth: true,
                    symbol: 'circle',
					symbolSize: 6,
					label: {
						show: true
					},
					color: '#749EFA',
					data: []
				}
			]
	};
	//获取数据
	const tableData = reactive({
		list: []
	});
	const tabForm = reactive({
		count: 0,
		limit: 15,
		page: 1,
		exportData: 0
	})
	//切换列表数据展示数量
	const pageSizeChange = function(limit) {
		tabForm.limit = limit;
	}
	//搜索
	const tabSearchClick = function(){
		if (searchQuery.timeIn == '-1') {
            if (!searchQuery.searchTime || !searchQuery.searchTime.length) {
                ElMessage({
                    type : 'warning',
                    message : '请选择日期'
                });
                return;
            }
            if (utils.datedifference(searchQuery.searchTime[0], searchQuery.searchTime[1]) > 31) {
                ElMessage({
                    type : 'warning',
                    message : '自定义日期不能超过31天'
                });
                return;
            }
        }
        if (searchQuery.timeIn == '-2') {
            if (!searchQuery.searchTime || !searchQuery.searchTime.length) {
                ElMessage({
                    type : 'warning',
                    message : '请选择月份'
                });
                return;
            }
            if (utils.MonthsBetw(searchQuery.searchTime[0], searchQuery.searchTime[1]) > 12) {
                ElMessage({
                    type : 'warning',
                    message : '自定义月份不能超过12个月'
                });
                return;
            }
        }
		tabForm.exportData = 0
		tabForm.page = 1;
		getData();
	}
	const pageData = reactive({
		allTimes: -1,
		allDays: -1,
		allMoney: -1,
		privateCourseTimes: -1,
		privateCourseMoney: -1,
		teamCourseTimes: -1,
		teamCourseMoney: -1,
		storeCardMoney: -1,
		payOnLineMoney: -1,
		experienceCardTimes: -1,
		experienceCardDays: -1,
		experienceCardMoney: -1,
		courseCardTimes: -1,
		courseCardDays: -1,
		courseCardMoney: -1,
	})
	const formulaType = ref('2'); //计算公式类型
	const getData = function() {
		if (tab.value == 1) {
			formulaType.value = '7';
			getBookAnalysis({
				timeIn: searchQuery.timeIn,
				searchTime: searchQuery.searchTime.join(' - '),
				courseType: searchQuery.courseType,
				courseNameIds: searchQuery.courseNameIds.join(','),
				cardType: searchQuery.cardType,
				productIds: searchQuery.productId.join(','),
				exportData: tabForm.exportData
			}).then((res) => {
				if (res.code == 0) {
					if (tabForm.exportData == 1) { //导出
						ElMessage({
							type: 'success',
							message: '正在导出，请稍后前往下载中心下载'
						})
						return false;
					}
					for (var k in summaryData) {
						summaryData[k] = res.results[k];
					}
					tableData.list = res.data;
					pageData.allTimes = res.data.findIndex((v) => {
						return v.allTimes != 0;
					});
					pageData.allDays = res.data.findIndex((v) => {
						return v.allDays != 0;
					});
					pageData.allMoney = res.data.findIndex((v) => {
						return v.allMoney != 0;
					});
					pageData.privateCourseTimes = res.data.findIndex((v) => {
						return v.privateCourseTimes != 0;
					});
					pageData.privateCourseMoney = res.data.findIndex((v) => {
						return v.privateCourseMoney != 0;
					});
					pageData.teamCourseTimes = res.data.findIndex((v) => {
						return v.teamCourseTimes != 0;
					});
					pageData.teamCourseMoney = res.data.findIndex((v) => {
						return v.teamCourseMoney != 0;
					});
					pageData.storeCardMoney = res.data.findIndex((v) => {
						return v.storeCardMoney != 0;
					});
					pageData.payOnLineMoney = res.data.findIndex((v) => {
						return v.payOnLineMoney != 0;
					});
					pageData.experienceCardTimes = res.data.findIndex((v) => {
						return v.experienceCardTimes != 0;
					});
					pageData.experienceCardDays = res.data.findIndex((v) => {
						return v.experienceCardDays != 0;
					});
					pageData.experienceCardMoney = res.data.findIndex((v) => {
						return v.experienceCardMoney != 0;
					});
					pageData.courseCardTimes = res.data.findIndex((v) => {
						return v.courseCardTimes != 0;
					});
					pageData.courseCardDays = res.data.findIndex((v) => {
						return v.courseCardDays != 0;
					});
					pageData.courseCardMoney = res.data.findIndex((v) => {
						return v.courseCardMoney != 0;
					});
					tabForm.count = res.count;
					var xData = [],
						seriesData = [],
						maxNum = 0;
					tableData.list.map((n) => {
						xData.push(n.analysisDate);
						seriesData.push(n.allMoney);
						if (n.allMoney > maxNum) {
							maxNum = n.allMoney;
						}
					})
					getOption.xAxis[0].data = xData;
					if (tableData.list.length > 10) {
                    	getOption.xAxis[0].axisLabel = {
                	        interval:0,
                	        rotate: 40
                	    }
                	} else {
                	    getOption.xAxis[0].axisLabel = {
                	        interval:0,
                	        rotate: 0
                	    }
                	}
					getOption.series[0].data = seriesData;
					getOption.series[0].itemStyle = {
                        normal: {
                            color: '#5B8FF9', 
                            borderColor:'#FFF',
                            borderWidth:1,
                            lineStyle: {
                                color: '#5B8FF9',
                                shadowColor: 'rgba(91, 143, 249, 0.3)',//设置折线阴影
                                shadowBlur: 1,
                                shadowOffsetY: 10,
                            }
                        }
                    }
					myChart.setOption(getOption);
				}
			})
		} else if (tab.value == 2) {
			formulaType.value = '8';
			getEnterAnalysis({
				timeIn: searchQuery.timeIn,
				searchTime: searchQuery.searchTime.join(' - '),
				productIds: searchQuery.productId.join(','),
				exportData: tabForm.exportData
			}).then((res) => {
				if (res.code == 0) {
					if (tabForm.exportData == 1) { //导出
						ElMessage({
							type: 'success',
							message: '正在导出，请稍后前往下载中心下载'
						})
						return false;
					}
					for (var k in summaryData) {
						summaryData[k] = res.results[k];
					}
					tableData.list = res.data;
					tabForm.count = res.count;
					var xData = [],
						seriesData = [],
						maxNum = 0;
					tableData.list.map((n) => {
						xData.push(n.analysisDate);
						seriesData.push(n.allMoney);
						if (n.allMoney > maxNum) {
							maxNum = n.allMoney;
						}
					})
					getOption.xAxis[0].data = xData;
					if (tableData.list.length > 10) {
                    	getOption.xAxis[0].axisLabel = {
                	        interval:0,
                	        rotate: 40
                	    }
                	} else {
                	    getOption.xAxis[0].axisLabel = {
                	        interval:0,
                	        rotate: 0
                	    }
                	}
					getOption.series[0].data = seriesData;
					getOption.series[0].itemStyle = {
                        normal: {
                            color: '#B773FA', 
                            borderColor:'#FFF',
                            borderWidth:1,
                            lineStyle: {
                                color: '#B773FA',
                                shadowColor: 'rgba(183, 115, 250, 0.3)',//设置折线阴影
                                shadowBlur: 1,
                                shadowOffsetY: 10,
                            }
                        }
                    }
					myChart.setOption(getOption);
				}
			})
		} else if (tab.value == 3) {
			formulaType.value = '9';
			getLeftAnalysis({
				courseType: searchQuery.courseType,
				courseNameIds: searchQuery.courseNameIds.join(','),
				cardType: searchQuery.cardType,
				productIds: searchQuery.productId.join(','),
				exportData: tabForm.exportData
			}).then((res) => {
				if (res.code == 0) {
					if (tabForm.exportData == 1) { //导出
						ElMessage({
							type: 'success',
							message: '正在导出，请稍后前往下载中心下载'
						})
						return false;
					}
					for (var k in summaryData) {
						summaryData[k] = res.results[k];
					}
					tableData.list = res.data.map((n)=>{
						if(n.type == 1){
							n.typeName = '课程卡'
						}else if(n.type == 2){
							n.typeName = '入场卡'
						}else if(n.type == 3){
							n.typeName = '储值卡'
						}else if(n.type == 4){
							n.typeName = '体验卡'
						}else if(n.type == 11){
							n.typeName = '团体课'
						}else if(n.type == 12){
							n.typeName = '私教课'
						}
						return n;
					});
					tabForm.count = res.count;
				}
			})
		}
	}


	//下载图表
	const downloadEcharts = function() {
		var myChartSrc = myChart.getDataURL({
			pixelRatio: 2,
			backgroundColor: '#fff'
		});
		console.log(myChartSrc)
		var a = document.createElement('a');
		a.style.display = 'none';
		// a.href = 'data:image/jpeg;base64,'+myChartSrc;
		a.href = myChartSrc;
		a.download = '消费金额趋势.png';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}
	//导出表格
	const downloadTable = function() {
		tabForm.exportData = 1;
		getData();
	}
	onMounted(() => {
		var chartDom = document.getElementById('main');
		myChart = echarts.init(chartDom);
		window.addEventListener("resize",function(){
		    myChart.resize();
        });
		getData()
		courseTypeChange()
		cardTypeChange()
	})

	//表格排序
	// const sortChange = function(column, prop, order){
	// 	tabForm.page = 1;
	// 	tableData.list.sort((a,b)=>{
	// 		var x = a[prop],y = b[prop];
	// 		if(order == 'ascending'){
	// 			return ((x < y) ? -1 : ((x > y) ? 1 : 0));
	// 		}else{
	// 			return ((x < y) ? ((x > y) ? 1 : 0) : -1);
	// 		}
	// 	})
	// }
	//计算公式
	const formulaShow = ref(false);
	const formulaClick = function() {
		formulaShow.value = true;
	}
	
	//未消查看详情
	const lookDetailData = reactive({
		show:false,
		title:'',
		type:0,
		list:[],
		count:0,
	})
	const lookDetailForm = reactive({
		page:1,
		limit:15,
		searchKey:'',
		productId:'',
		courseNameId:'',
		cardType:'',
		courseType:''
	})
	//查看详情
	const lookDetailEvent = function(row){
		lookDetailData.title = row.typeName;
		lookDetailData.type = row.type;
		lookDetailForm.page = 1;
		lookDetailForm.searchKey = '';
		if(row.type < 5){
			lookDetailForm.cardType = row.type;
			lookDetailForm.courseType = '';
			lookDetailForm.productId = searchQuery.productId.join(',');
			lookDetailForm.courseNameId = ''
		}else{
			lookDetailForm.cardType = '';
			lookDetailForm.courseType = row.type;
			lookDetailForm.courseNameId = searchQuery.courseNameIds.join(',')
			lookDetailForm.productId = ''
		}
		searchDetailList();
		lookDetailData.show = true;
	}
	//详情切花分页数量
	const detailSizeChange = function(){
		lookDetailForm.limit = limit;
		searchDetailList();
	}
	//搜索
	const searchDetailList = function(){
		lookDetailForm.page = 1;
		getDetailList();
	}
	//获取详情列表
	const getDetailList = function(){
		console.log(lookDetailForm)
		getLeftCardDetailAnalysisData(lookDetailForm).then((res) => {
			if (res.code == 0) {
				lookDetailData.count = res.count
				lookDetailData.list = res.data;
			}
		})
	}
	//会员详情
	const toMemberDetail = function(id){
		router.push({path:'/branch/memberDetail',query:{encryptionId:id}});
	}
	onBeforeUnmount(() => {
      if (!myChart) {
        return
      }
      myChart.dispose()
      myChart = null
    })
</script>
<style type='css/style' scoped>
	.typeActive{
		position: relative;
		color: #00A3FF;
	}
	.typeActive::after{
		position: absolute;
		content: '';
		bottom: -8px;
		left: 50%;
		width: 70%;
		height: 3px;
		transform: translateX(-50%);
		background-color: #03A3FF;
		border-radius: 2px;
	}
	.bg-F8CE66{
		background-color: #F8CE66;
	}
	.bg-FA73B0{
		background-color: #FA73B0;
	}
	.bg-73B0FA{
		background-color: #73B0FA;
	}
	.bg-B773FA{
		background-color: #B773FA; 
	}
	.bg-F16443{
		background-color: #F16443; 
	}
	.iconBox{
		position: relative;
	}
	.iconItem{
		position: absolute;
		bottom: 0;
		right: 0;
	}
	.el-select-dropdown__item{
		width: 325px
	}
</style>
