<template>
    <div class="p20 pt0">
        <div class="nav">
            <formQuery ref="queryOptsRef" :queryOpts="queryOpts" @query="searchGetList" style="flex: 1"></formQuery>
			<div class="derive">
				<el-button @click="getList(true)" v-if="utils.getMenuRole(3200000)"  :icon="Upload" >导出</el-button>
			</div>
        </div>
        <el-table v-loading="pageData.loading" stripe element-loading-text="拼命加载中。。。" ref="multipleTable" :data="pageData.tableData" style="width: 100%">
			<el-table-column prop="realName" fixed label="会员名称" width="150" show-overflow-tooltip>
                <template #default="scope">
					<span @click="memberDetail(scope.row.encryptionCardMyId)" class="color-409EFF c_p">{{scope.row.realName}}</span>
                    <span class="ft12 color-fff bg-ED412A pl5 pr5 pt3 pb2 ml5 bdr2" v-if="scope.row.financeState == 3">续课</span>
				</template>
            </el-table-column>
            <el-table-column prop="productName" label="商品名称" width="120" show-overflow-tooltip/>
            <el-table-column label="商品类型">
                <template #default="scope">
                    {{ filtersType(scope.row.orderProductType, 1) }}
                </template>
            </el-table-column>
            <el-table-column label="售卖金额" width="120">
                <template #default="scope">
                    ￥{{ scope.row.actualMoney ? scope.row.actualMoney : 0 }}
                </template>
            </el-table-column>
            <el-table-column label="减免金额" width="120">
                <template #default="scope">
                    ￥{{ scope.row.reducePrice ? scope.row.reducePrice : 0 }}
                </template>
            </el-table-column>
            <el-table-column label="实收金额" width="120">
                <template #default="scope">
                    ￥{{ scope.row.actualMoney ? scope.row.actualMoney : 0 }}
                </template>
            </el-table-column>
            <el-table-column label="订单来源" width="120" >
                <template #default="scope">
                    <span v-if="scope.row.courseType == 13">{{ scope.row.buyPayType == 0 ? '门店创建' : scope.row.buyPayType == 1 ? '会员自主买班' : '会员单次约课'  }}</span>
                    <span v-else>{{ scope.row.buyPayType == 0 ? '门店创建' : scope.row.buyPayType == 1 ? '会员自主买卡' : '会员单次约课'  }}</span>
                </template>
            </el-table-column>
            <el-table-column label="订单状态">
                <template #default="scope">
                    <span v-if="scope.row.payState == 0" class="color-FA6400">待支付</span>
                    <span v-if="scope.row.payState == 1" class="color-44D7B6">已支付</span>
                    <span v-if="scope.row.payState == 4" class="color-666">已取消</span>
                    <span v-if="scope.row.payState == 6" class="color-FA0008">已退款</span>
                </template>
            </el-table-column>
            <el-table-column label="支付方式">
                <template #default="scope">
                    <el-tooltip
                      class="box-item"
                      effect="dark"
                      :content="filtersType(scope.row.payMethodId, 3)"
                      placement="top-start"
                    >
                       <img class="w20 h20" :src="payIconList[scope.row.payMethodId]" alt="">
                    </el-tooltip>
                    
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="160" />
            <el-table-column label="支付时间" width="160">
                <template #default="scope">
                    {{ scope.row.payState != 0 ? scope.row.operationTime : '-' }}
                </template>
            </el-table-column>
            <el-table-column prop="batchCode" label="订单号" width="180" />
            <el-table-column prop="saleUserName" label="销售员" />
            <el-table-column fixed="right" align="center" label="操作" width="150">
                <template #default="scope">
                    <a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="(scope.row.payState == 0 || scope.row.payState == 2) && utils.getMenuRole(3200004)" @click="collection(scope.row)">收款</a>
                    <a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="utils.getMenuRole(3200002)" @click="details(scope.row)">详情</a>
                    <a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="scope.row.payState == 0 || scope.row.payState == 2" @click="cancelOrder(scope.row)">取消</a>
                    <a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="scope.row.payState == 1 && utils.getMenuRole(3200001)" @click="memberRefundEvent(scope.row)">退款</a>
                    <a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="(scope.row.payState == 1 || scope.row.payState == 6) && utils.getMenuRole(3200003)" @click="printTicketEvent(scope.row)">打印</a>
                </template>
            </el-table-column>
            <template #empty>
                <el-empty description="暂无数据"></el-empty>
            </template>
        </el-table>
        <div class="mt20 rx-bc">
            <div>
                <span class="mr20">已支付实收总计：<span class="color-red mr5">{{ pageData.summarySale || 0 }}</span>元</span>
                <span>已退款总计：<span class="color-red mr5">{{ pageData.summarySaleOut || 0 }}</span>元</span>
            </div>
		    <el-pagination
		      v-model:currentPage="pageData.searchForm.page"
		      :page-sizes="[15,20,30,50,100]"
		      :page-size="pageData.searchForm.limit"
		      layout="total, sizes, prev, pager, next, jumper"
		      :total="pageData.totalCount"
		      @size-change="pageSizeChange"
		      @current-change="()=>{getList()}"
		    >
		    </el-pagination>
		</div>
        <PrintTicket v-model="pageData.printShow" type="5" :id="pageData.courseInfo" @save="printSave"></PrintTicket>
        <MemberRefund v-model="pageData.memberRefundShow" :memberid="pageData.memberId" :type="pageData.type" :payTypeId="pageData.payTypeId" :orderid="pageData.orderId" @close="memberRefundClose" @save="memberRefundSave"></MemberRefund>
    </div>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import { useStore } from 'vuex'
    import { useRouter,onBeforeRouteLeave } from 'vue-router';
    import { ElMessageBox, ElMessage } from 'element-plus'
    import { Upload } from '@element-plus/icons-vue'
    import formQuery from '@/components/branch-store/formQuery.vue';
    import PrintTicket from '@/components/branch-store/PrintTicket.vue';
    import MemberRefund from '@/components/branch-store/member/MemberRefund.vue';
    import utils from '@/common/utils.js'
    import { getStoreAccountStaffSimple } from '@/api/staff.js'
    import { queryAllSaleList, closeTrans } from '@/api/finance.js'
    
    const store = useStore();
	const router = useRouter();
    const formRef = ref()
    const queryOptsRef = ref()
    const pageData = reactive({
        loading: false,
        dialogVisible: false, // 订单收款弹框
        memberRefundShow:false, // 退款弹框
		orderId:'',
		memberId:'',
        totalCount: 0,
        searchForm: {
            page: 1,
            limit: 15
        },
        tableData: [],
        printShow: false, //打印小票弹框
        courseInfo: '',
        summarySale: 0,
        summarySaleOut: 0,
        type: 0,

        payTypeId: '',
        payMethodList: [],
    })
    const payIconList = {
		0: require('@/assets/payIcon/payIcon0.png'), 
		1: require('@/assets/payIcon/payIcon1.png'), 
		2: require('@/assets/payIcon/payIcon2.png'), 
		3: require('@/assets/payIcon/payIcon3.png'), 
		4: require('@/assets/payIcon/payIcon4.png'), 
		8: require('@/assets/payIcon/payIcon8.png'),
        100: require('@/assets/payIcon/payIcon100.png'), 
		101: require('@/assets/payIcon/payIcon101.png'), 
	}
    const queryOpts = reactive({});
    const orderProductType = [
        {label : '商品类型' ,value : ''},
        { value : '1' , label : '课程卡' },
        { value : '2' , label : '入场卡' },
        { value : '3' , label : '储值卡' },
        { value : '4' , label : '体验卡' },
        { value : '11' , label : '团体课' },
        { value : '12' , label : '私教课' },
        { value : '13' , label : '小班课' },
        // { value : '21' , label : '场地预约' }
    ]
    const payState = [
        {label : '订单状态' ,value : ''},
        { value : '0' , label : '待支付' },
        { value : '1' , label : '已支付' },
        // { value : '2' , label : '支付失败' },
        // { value : '3' , label : '无需支付' },
        // { value : '4' , label : '已关闭' },
        { value : '4' , label : '已取消' },
        { value : '6' , label : '已退款' }
    ]
    const timerList = [
        { value : "1" , label : '今天' },
        { value : "2" , label : '昨天' },
        { value : "5" , label : '最近7天' },
        { value : "20" , label : '最近30天' },
        { value : "-1" , label : '其他' }
    ]
    const saleUserList = reactive([])
    queryOpts['fields'] = [{
		type : 'input',
		fieldName : 'searchKeys',
		placehold : '请输入会员姓名',
        value:''
	},{
		type : 'input',
		fieldName : 'productName',
		placehold : '请输入商品名称',
        value:''
	},{
		type : 'input',
		fieldName : 'batchCode',
		placehold : '请输入订单号',
        value:''
	},{
		type : 'select',
		fieldName : 'orderProductType',
		placehold : '商品类型',
		options : orderProductType,
        value:''
	},{
		type : 'select',
		fieldName : 'payState',
		placehold : '订单状态',
		options : payState,
        value:''
	},{
		type : 'select',
		fieldName : 'payMethodId',
		placehold : '支付方式',
		options : utils.getDict('waterPayType',{label : '全部支付方式' ,value : ''}),
        value:''
	},{
        type : 'select',
        fieldName : 'timeIn',
        placehold : '支付时间',
        value : '5',
        options : timerList
    },{
        showField : 'timeIn', // 根据显示字段
        showValue : '-1', // 根据显示字段值
        type : 'dateRange',
        fieldName : 'querydate',
        placeholder : '日期范围',
        value:''
    },{
		type : 'select',
		fieldName : 'saleUserId',
		placehold : '销售员',
		options : saleUserList,
        value:''
	}]
    
    /**
     * @param (String, Number) value-类型, type-1 商品类型 2-支付状态 3-支付方式
     * 表格字段过滤过滤展示
     */
    function filtersType(value, type) {
        let arry = type == 1 ? orderProductType : type == 2 ? payState : utils.getDict('waterPayType')
        let text = ''
        arry.forEach((item) => {
            if (item.value == value) {
                text = item.label
            }
        })
        return text
    }
    /**
     * 获取销售员
     */
    function getPersonnel() {
        let params = {
            page:1,
            limit:1000,
            delFlag: 0
        }
        getStoreAccountStaffSimple(params).then((res) => {
            if (res.code == 0) {
                saleUserList.push({
                   label: '全部销售员',
                   value: ''
                })
                res.data.forEach(item => {
                    let params = {
                       label: item.realName,
                       value: item.id
                    }
                   saleUserList.push(params)
               })
            }
        })
    }
    /**
     * 切换每页条数
     */
    function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		getList();
	}
    /**
     * 搜索
     */
	function searchGetList() {
	   pageData.searchForm.page = 1;
       getList()
	}
    /**
     * 获取表格数据
     */
    function getList(isExport){
		let params = {}
        Object.assign(params, queryOptsRef.value.getQueryForm())
		params.limit = pageData.searchForm.limit
		params.page = pageData.searchForm.page
        params.state = 1
        if (params.timeIn == '-1' && !params.querydate) {
            ElMessage.warning('请选择自定义时间')
            return false
        }
        if (params.timeIn == '-1' && params.querydate) {
            params.operationTimeStr = params.querydate.join('-')
        }
		if(isExport){ //导出
			params.limit = 9999;
			params.page = 1;
			params.exportData = 1;
		}
        pageData.loading = true
		queryAllSaleList(params).then((res)=>{
			pageData.loading = false
			if(res.code == 0){
				if(isExport){ //导出
					ElMessage({
						type: 'success',
						message: '正在导出，请稍后前往下载中心下载'
					})
				}else{
					pageData.tableData = res.data
					pageData.totalCount = res.count
					pageData.summarySaleOut = res.remark.summarySaleOut
					pageData.summarySale = res.remark.summarySale
				}
				
			}
		}).catch(() => {
            pageData.loading = false
        })
	}
    /**
     * 会员详情
     */
	const memberDetail=(id) => {
		router.push({path:'/branch/memberDetail',query:{encryptionId:id}});
	}
	/**
     * 会员详情
     */
	onBeforeRouteLeave((to, from)=>{
	    if (to.path === '/branch/memberDetail' || to.path === '/branch/orderDetail') {
	        store.commit('SetKeep',{keepLiveRoute : ['IntentionList']});
	    } else {
	        store.commit('SetKeep',{keepLiveRoute : []});
	    }
	})
    /**
     * @param (Object) row 订单数据
     * 收款
     */
    const collection=(row)=>{
        let data = {
            encryptionBatchId: row.encryptionBatchId,
            batchCode: row.batchCode,
            actualMoney: row.actualMoney,
            realName: row.realName,
            telephoneNum: row.telephoneNum
        } 	   
        window.open('/#/branch/payCourseMoney?from=4&info=' + encodeURIComponent(JSON.stringify(data)), '_blank')
    }
    /**
     * 更改减免金额
     */
    const changeExemption =(val)=> {
        form.subtotal = Math.round((parseFloat(form.price) - parseFloat(form.exemption))*100)/100
    }
    /**
     * @param (Object) row 订单数据
     * 订单详情
     */
    const details=(row)=>{
        router.push({path:'/branch/orderDetail',query:{encryptionId: row.encryptionId}});
    }
    /**
     * @param (Object) row 订单数据
     * 取消订单
     */
    const cancelOrder=(row)=>{
        ElMessageBox.confirm('确定取消订单吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(() => {
            closeTrans({ encryptionFinanceId: row.encryptionId }).then((res)=> {
                if (res.code == 0) {
                    ElMessage.success('取消成功')
                    getList()
                }
            })
        })
    }
    //退款
	function memberRefundEvent(row){
		pageData.orderId = row.encryptionId;
		pageData.memberId = row.encryptionCardMyId
        pageData.type = row.orderProductType
        pageData.payTypeId = row.payMethodId
		pageData.memberRefundShow = true;
	}
	function memberRefundSave(){
		pageData.memberRefundShow = false;
		getList();
	}
	function memberRefundClose(){
		pageData.memberRefundShow = false;
	}
    /**
     * 打印
     */
	const printTicketEvent=(row)=>{
		pageData.courseInfo = row.encryptionId;
		pageData.printShow = true;
	}
	const printSave=()=>{
		pageData.printShow = false;
	}
    onMounted(()=>{
        getPersonnel()
        getList()
    })
</script>
<style type='css/style' scoped>
    .nav{
		display: flex;
	}
    .derive {
        margin-top: 16px;
    }
    .formRef /deep/ .el-select{
        width: 200px !important;
    }
    .formRef /deep/ .el-input-number.is-without-controls .el-input__inner{
        text-align: left;
    }
</style>