<template>
    <workArea :barlist="barlist">
    <div class="bg-fff pl16 pt10 pr16">
        <el-tabs v-model="pageData.tabNum" @tab-click="handleClick">
            <el-tab-pane label="售出分析" name="0" v-if="utils.getMenuRole(32501)"></el-tab-pane>
            <el-tab-pane label="订单分析" name="1" v-if="utils.getMenuRole(32502)"></el-tab-pane>
            <el-tab-pane label="来源分析" name="2" v-if="utils.getMenuRole(32503)"></el-tab-pane>
            <el-tab-pane label="跟进分析" name="3" v-if="utils.getMenuRole(32504)"></el-tab-pane>
        </el-tabs>
        <component :is="components[pageData.tabNum]" :tab="pageData.tabNum"></component>
    </div>
    </workArea>
</template>
<script setup>
  import { reactive, onMounted, onBeforeUnmount } from 'vue';
  import soldAnalyse from '@/views/branch-store/datacenter/saleData/soldAnalyse.vue' // 销售分析
  import orderAnalyse from '@/views/branch-store/datacenter/saleData/orderAnalyse.vue' // 订单分析
  import sourceAnalyse from '@/views/branch-store/datacenter/saleData/sourceAnalyse.vue' // 来源分析
  import followAnalyse from '@/views/branch-store/datacenter/saleData/followAnalyse.vue' // 跟进分析
  import workArea from '@/components/branch-store/workArea.vue'; 
  import utils from '@/common/utils.js'; 
  const barlist = [{ name : '首页' },{ name : '数据中心' },{ name : '销售数据' }];
  const components = [soldAnalyse, orderAnalyse, sourceAnalyse, followAnalyse];
  const pageData = reactive({
      tabNum : '0'
  });
  var menuId = [32501,32502,32503,32504]; //会员有权限tab默认项
  for(var i = 0;i < menuId.length;i++){
  	if(utils.getMenuRole(menuId[i])){
  		pageData.tabNum = i.toString();
  		break;
  	}
  }  
    function handleClick(tab) {
      localStorage.setItem('saleData', tab.props.name)
	}
	onMounted(()=> {
      if (localStorage.getItem('saleData') != null) {
          pageData.tabNum = localStorage.getItem('saleData')
	    }
	})
  onBeforeUnmount(() => {
		localStorage.removeItem('saleData')
	})
    
</script>

<style>
</style>

