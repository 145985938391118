<template>
    <div>
        <formQuery ref="queryOptsRef" :queryOpts="pageData.queryOpts" @query="searchGetList"></formQuery>
        <div class="rx-bc mb10">
            <el-button type="primary" @click="modify()" v-if="utils.getMenuRole(3180100)">新增预约到访</el-button>
            <el-button @click="getList(true)"  :icon="Upload" >导出</el-button>
        </div>
        <el-table :data="pageData.tableData" stripe v-loading="pageData.loading">
            <el-table-column prop="date" label="会员姓名">
                <template #default="scope">
                    <span @click="toMemberDetail(scope.row.encryptionId)" class="color-409EFF c_p tool_btn">{{scope.row.realName}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="telephoneNum" label="手机号" width="120" />
            <el-table-column prop="orderVisitTime" label="预约到访时间" />
			<el-table-column prop="actualVisitTime" label="实际到访时间" />
            <el-table-column prop="createUserName" label="操作人" />
            <el-table-column prop="remark" label="备注" />
            <el-table-column label="操作">
				<template #default="scope">
					<div v-if="!scope.row.actualVisitTime">
                        <a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="modify(scope.row)" v-if="utils.getMenuRole(3180101)">编辑</a>
                        <a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="remove(scope.row.encryptionId)" v-if="utils.getMenuRole(3180102)">删除</a>
                    </div>
				</template>
			</el-table-column>
            <template #empty>
                <el-empty description="暂无数据"></el-empty>
            </template>
        </el-table>
        <div class="mt20 rx-bc" v-show="pageData.tableData.length > 0">
            <div>
                共预约<span class="color-red ml5">{{ pageData.remark.orderVisitCount }}</span>人，实际到访<span class="color-red ml5">{{  pageData.remark.actualVisitCount}}</span>人
            </div>
	        <el-pagination
	            v-model:currentPage="pageData.searchForm.page"
	            :page-sizes="[15,20,30,50,100]"
	            :page-size="pageData.searchForm.limit"
	            layout="total, sizes, prev, pager, next, jumper"
	            :total="pageData.totalCount"
	            @size-change="pageSizeChange"
	            @current-change="()=>{getList()}"
	        />
	    </div>

        <el-dialog
            v-model="pageData.dialogVisible"
            :title="pageData.title"
            width="500px"
            :before-close="handleClose"
        >
            <div class="p20">
                <el-form
                    ref="ruleFormRef"
                    :model="pageData.visitForm"
                    :rules="rules"
                    label-width="100px"
                  >
                    <el-form-item label="选择会员: " prop="encryptionCardMyId">
                        <el-select
                            v-model="pageData.visitForm.encryptionCardMyId"
                            placeholder="请选择会员"
                            filterable
					        reserve-keyword
					        :remote-method="searchMember"
					        :loading="pageData.searchLoading"
                        >
                            <el-option 
                              v-for="item in pageData.checkUserList"
                              :key="item.encryptionId"
                              :label="item.realName"
                              :value="item.encryptionId"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="到访时间: " prop="orderVisitTime">
                        <el-date-picker
                            v-model="pageData.visitForm.orderVisitTime"
                            type="datetime"
                            placeholder="请选择到访时间"
                            format="YYYY-MM-DD HH:mm"
                            value-format="YYYY-MM-DD HH:mm"
                            :disabled-date="pageData.disabledDate"
                            :disabled-hours="disabledHours"
                            :disabled-minutes="disabledMinutes"
                            :disabled-seconds="disabledSeconds"
                            style="width:100%"
                        />
                    </el-form-item>
                    <el-form-item label="备注: ">
                       <el-input v-model="pageData.visitForm.remark" type="textarea" maxlength="30" show-word-limit />
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="handleClose">取 消</el-button>
                    <el-button type="primary" @click="onSubmit">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import formQuery from '@/components/branch-store/formQuery.vue';
	import { ElMessage, ElMessageBox } from 'element-plus'
    import { Upload } from '@element-plus/icons-vue'
    import utils from '@/common/utils.js';
    import { getStoreAccountStaffSimple, selectStoreMembersSimple } from '@/api/staff.js';
    import { orderVisitLsit, orderVisitUp, orderVisitUpdate, orderVisitDelete } from '@/api/consume.js'

	const queryOptsRef = ref()
    const ruleFormRef = ref ()
    let timeInLsit = [
        { value : '1' , label : '今天' },
        { value : '3' , label : '明天' },
        { value : '41' , label : '近七天' },
        { value : '20' , label : '本月' },
        { value : '-1' , label : '其他' }
    ]
    const pageData = reactive({
        searchLoading:false,
		loading: false,
        dialogVisible: false, // 预约到访弹框
        title: '', // 弹框标题
        tableData: [ ],
        encryptionId: '', // 预约到访记录id
        checkUserList: [], // 员工列表
        searchForm: {
            page: 1,
            limit: 15
        },
        visitForm:{
            encryptionCardMyId: '',
            orderVisitTime: '',
            remark: ''
        },
        remark: {},
        totalCount: 0,
        queryOpts:{
			fields:[{
				type : 'input',
				fieldName : 'searchKeys',
				placehold : '会员姓名/手机号'
			}, {
                type : 'select',
                fieldName : 'timeIn',
                placehold : '时间范围',
                value : '41',
                options : timeInLsit
            }, {
                showField : 'timeIn', // 根据显示字段
                showValue : '-1', // 根据显示字段值
                type : 'dateRange',
                fieldName : 'orderVisitTimeStr',
                placeholder : '日期范围'
            }, {
                type : 'select',
                fieldName : 'createBy',
                placehold : '操作员',
                options : [],
                value : ''
            }]
		},
        disabledDate(time) {
          return disDate(time)
        }
    })
    const makeRange = (start, end) => {
        const result = []
        for (let i = start; i <= end; i++) {
            result.push(i)
        }
        return result
    }
    let dataTimer = new Date() // 获取当前实际
    let hours = dataTimer.getHours();
    let minutes = dataTimer.getMinutes();
    let second = dataTimer.getSeconds();
    function disabledHours(){
        return makeRange(0, hours-1)
    }
    function disabledMinutes(hour){
        if (hours == hour) {
            return makeRange(0, minutes-1)
        }
    }
    function disabledSeconds(hour, minute){
        if (hours == hour && minutes == minute) {
            return makeRange(0, second)
        }
    }
    const disDate = (time) => {
        return time.getTime() < new Date().getTime() - 8.64e7 
    }
    const rules = reactive({
        encryptionCardMyId: [{ required: true, message: '请选择会员', trigger: 'change' }],
        orderVisitTime: [{ required: true, message: '请选择预约到访时间', trigger: 'change' }]
    })
    /**
     * 搜索
     */
    function searchGetList(){
        pageData.searchForm.page = 1;
        getList()
    }
    /**
     * 切换每页条数
     */
	function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		getList();
	}
	/**
	 * 获取预约到访记录列表
	 */
	function getList(isExport){
		let params = {}
		Object.assign(params, queryOptsRef.value.getQueryForm())
        if (params.timeIn == '-1' && !params.orderVisitTimeStr) {
            ElMessage.warning('请选择自定义时间')
            return false
        }
        params.orderVisitTimeStr = params.timeIn == '-1' && params.orderVisitTimeStr.join('-')
		params.limit = pageData.searchForm.limit
		params.page = pageData.searchForm.page
        pageData.loading = true
		if(isExport){ //导出
			params.limit = 9999;
			params.page = 1;
			params.exportData = 1;
		}
		orderVisitLsit(params).then((res)=>{
			pageData.loading = false
			if(res.code == 0){
				if(isExport){ //导出
					ElMessage({
						type: 'success',
						message: '正在导出，请稍后前往下载中心下载'
					})
				}else{
					pageData.tableData = res.data;
					pageData.totalCount = res.count;
					pageData.remark = res.remark
				}
			}
		}).catch(() => {
            pageData.loading = false
		})
	}
    /**
     * 新增或者编辑预约到访
     */
    function modify(row) {
        if (row) {
            pageData.title = '编辑预约到访'
            pageData.encryptionId = row.encryptionId
            pageData.visitForm.encryptionCardMyId = row.encryptionCardMyId
            pageData.visitForm.orderVisitTime = row.orderVisitTime
            pageData.visitForm.remark = row.remark
            
        } else {
            pageData.visitForm = {
                encryptionCardMyId: '',
                orderVisitTime: '',
                remark: ''
            }
            pageData.title = '新增预约到访'
        }
        pageData.dialogVisible = true
    }
    /**
     * 获取员工列表
     */
    getPersonnel()
    function getPersonnel() {
        let params = {
            page:1,
            limit:1000,
            delFlag: 1
        }
        getStoreAccountStaffSimple(params).then((res) => {
           if (res.code == 0) {
               for(var i=0;i< res.data.length;i++){
                    let params = {}
                    params.label = res.data[i].realName
                    params.value = res.data[i].id
                    pageData.queryOpts.fields[3].options.push(params)
                }
            }
        })
    }
    searchMember()
    var timer = ''
	function searchMember(key){
		clearTimeout(timer);
		timer = setTimeout(()=>{
			pageData.searchLoading = true;
            let params = {
                searchKeys: key,
                page: 1,
                limit:9999
            }
			selectStoreMembersSimple(params).then((res) => {
                pageData.searchLoading = false;
                if (res.code == 0) {
                    pageData.checkUserList = res.data
                }
            })
		}, 100)
	}
    /**
     * 取消关闭弹框
     */
    function handleClose() {
        ruleFormRef.value.resetFields()
        pageData.dialogVisible = false
    }
    /**
     * 确定提交弹框表单
     */
    function onSubmit() {
        ruleFormRef.value.validate((valid) => {
            if (valid) {
                let params = {
                    encryptionCardMyId: pageData.visitForm.encryptionCardMyId,
                    orderVisitTime: pageData.visitForm.orderVisitTime,
                    remark:  pageData.visitForm.remark
                }
                if (pageData.title == '新增预约到访') {
                    orderVisitUp(params).then((res) => {
                        if (res.code == 0) {
                            ElMessage.success('新增成功')
                            pageData.dialogVisible = false
                            getList()

                        }
                    })
                } else {
                    params.encryptionId = pageData.encryptionId
                    orderVisitUpdate(params).then((res) => {
                        if (res.code == 0) {
                            ElMessage.success('修改成功')
                            pageData.dialogVisible = false
                            getList()
                        }
                    })
                }
                
            } else {
              console.log('error submit!!');
              return false;
            }
        });
    }
	/**
	 * @param (String) id - 预约到访记录的id
	 * 删除预约
	 */
	function remove(id) {
		ElMessageBox.confirm('确定删除该条记录吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(() => {
            orderVisitDelete({encryptionId: id}).then((res) => {
                if (res.code == 0) {
                    ElMessage.success('删除成功')
                    getList()
                }
            })
        })
	}
    onMounted(()=> {
        getList()
    })
</script>
<style type='css/style' scoped>
</style>