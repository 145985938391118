<template>
	<div class="coach_list">
		<div class="rx-bc mb10">
			<div class="color-333 fwb coach_title" :style="{'border-left': '4px solid ' + themeBgColor.list[themeBgColor.active].textColor}" :class="{'color-fff':themeBgColor.bgColor == 2}">{{storeData.name}}</div>
			<div class="ft12" :class="{'color-fff':themeBgColor.bgColor == 2}">全部 ></div>
		</div>
		<ul>
			<li v-for="(item,index) in storeData.list" :key="index">
				<div class="t_a_c" :style="{backgroundColor:themeBgColor.bgColor == 2 ? 'rgba(255,255,255,0.1)' : '#FAFAFC'}">
					<img class="w30 h30 b_r_100" :src="item.headImgUrl" >
					<p class="maxLength" :class="{'color-fff':themeBgColor.bgColor == 2}">{{item.name}}</p>
				</div>
			</li>
		</ul>
	</div>
</template>

<script setup>
	const propData = defineProps({
	  tab:String,
	  itemIndex:Number
	})
	import {ref,reactive} from 'vue'
	import { useStore } from 'vuex'
	const store = useStore();
	const storeData = store.state.appletFormData[propData.tab].list[propData.itemIndex];
	const themeBgColor = store.state.appletFormData[4].list[0]
</script>

<style scoped lang="stylus">
	.coach_list{
		.coach_title{
			
			padding-left 5px;
		}
		ul{
			overflow hidden;
			li{
				width 33.33%;
				float left;
				padding 5px 5px;
				div{
					padding 10px 0;
					background-color #FAFAFC;
					text-align center;
					min-height 80px;
				}
			}
			li:nth-child(3n-2){
				padding-right 10px;
				padding-left 0px;
			}
			li:nth-child(4n-1){
				padding-left 10px;
				padding-right 0px;
			}
		}
	}
	.maxLength {
		height: 20px;
    	line-height: 20px;
	}
</style>
