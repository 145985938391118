<template>
	<div class="pt20 pb20">
		<div class="rx-bc bg-f2f2f2 h35">
			<div class="rx-sc">
				<div class="w95 h35 rx-cc bdr2 color-666 c_p" :class="{'color-fff bg-03A3FF':tab == 1}" @click="tabClick(1)">
					销售员</div>
				<div class="w95 h35 rx-cc bdr2 color-666 c_p" :class="{'color-fff bg-03A3FF':tab == 2}" @click="tabClick(2)">
					商品</div>
			</div>
			<div class="rx-cc mr20">
				<span class="color-999 mr20 ft12">温馨提示：当前 页面数据仅统计截止至当前时间</span>
				<span class="color-main c_p t_d_u ft12" @click="formulaClick">计算公式</span>
			</div>
		</div>
		<div class="rx-bc mt20">
			<div class="rx-sc">
				<div class="w210 mr12" v-show="tab == 1">
					<el-select v-model="searchQuery.userId" filterable placeholder="全部销售">
						<el-option label="全部销售" value="" />
						<el-option v-for="item in searchQueryList.coachList" :key="item.id" :label="item.realName"
							:value="item.id" />
					</el-select>
				</div>
				<div class="w210 mr12" v-show="tab == 2">
					<el-select v-model="searchQuery.productType" placeholder="商品类型">
						<el-option label="全部类型" value="" /> 
						<el-option v-for="item in searchQueryList.productTypeList" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</div>
				<div class="w210 mr12">
					<el-select v-model="searchQuery.timeIn" placeholder="日期类型">
						<el-option v-for="item in searchQueryList.timeTypeList" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</div>
				<div class="w210 mr12" v-show="searchQuery.timeIn == -1">
					<el-date-picker style="width: 210px; height: 32px" format="YYYY-MM-DD" value-format="YYYY-MM-DD"
						v-model="searchQuery.searchTime" type="daterange" start-placeholder="开始日期"
						end-placeholder="结束日期" :disabled-date="datePickerDisabled.disabledDate" clearable>
					</el-date-picker>
				</div>
				<div class="w210 mr12" v-show="searchQuery.timeIn == -2">
					<el-date-picker style="width: 210px; height: 32px" format="YYYY-MM" value-format="YYYY-MM"
						v-model="searchQuery.searchTime" type="monthrange" start-placeholder="开始月份"
						end-placeholder="结束月份" :disabled-date="datePickerDisabled.setMonthDisabled">
					</el-date-picker>
				</div>
				<el-button class="mr12" type="primary" @click="tabSearchClick" :icon="Search">搜索</el-button> 
				<el-checkbox class="checkbox" v-model="searchQuery.hasDelete" size="large" @change="tabSearchClick" >
					<span class="color-333">{{tab == 1 ? '包含已删除员工' : '包含已删除商品'}}</span>
				</el-checkbox>
			</div>
			<div class="rx-cc">
				<el-button :icon="Download" @click="downloadEcharts">下载图表</el-button>
				<el-button :icon="Upload" @click="downloadTable">导出表格</el-button>
			</div>
		</div>
		<div class="rx-sc pl10 pr10 pt20 pb20 posi-r mt20" style="background-color: #EFF6FF;">
			<div class="ww25 pl10 pr10" >
				<div class="bdr4 bg-fff rx-bc pl35 pr35 h110">
					<div>
						<h3 class="fwb color-333 ft32 mb5">￥{{summaryData.actualMoney}}</h3>
						<p class="fwb color-999 ft16">订单总实收金额</p>
					</div>
					<div class="iconBox">
						<img class="w60" src="@/assets/bookIcon.png" alt="">
						<span class="rx-cc w25 h25 bdr100 color-fff fwb iconItem" :class="tab == 1 ? 'bg-48D79D' :'bg-7FD7F7' ">额</span>
					</div>
				</div>
			</div>
			<div class="ww25 pl10 pr10" >
				<div class="bdr4 bg-fff rx-bc pl35 pr35 h110">
					<div>
						<h3 class="fwb color-333 ft32 mb5">￥{{summaryData.refundMoney}}</h3>
						<p class="fwb color-999 ft16">订单总退款金额 </p>
					</div>
					<div class="iconBox">
						<img class="w60" src="@/assets/bookIcon.png" alt="" > 
						<span class="rx-cc w25 h25 bdr100 color-fff fwb iconItem" :class="tab == 1 ? 'bg-7CA6FB' :'bg-FCC963' ">额</span>
					</div>
				</div>
			</div>
			<div class="ww25 pl10 pr10">
				<div class="bdr4 bg-fff rx-bc pl35 pr35 h110">
					<div>
						<h3 class="fwb color-333 ft32 mb5">{{summaryData.orderSuccessCount}}</h3>
						<p class="fwb color-999 ft16">订单成交总笔数</p>
					</div>
					<div class="iconBox">
						<img class="w60" src="@/assets/boxIcon.png" alt="">
						<span class="bg-48D79D rx-cc w25 h25 bdr100 color-fff fwb iconItem">笔</span>
					</div>
				</div>
			</div>
			<div class="ww25 pl10 pr10" >
				<div class="bdr4 bg-fff rx-bc pl35 pr35 h110">
					<div>
						<h3 class="fwb color-333 ft32 mb5">{{summaryData.orderRefundCount}}</h3>
						<p class="fwb color-999 ft16">订单退款总笔数</p>
					</div>
					<div class="iconBox">
						<img class="w60" src="@/assets/cardIcon.png" alt="">
						<span class="bg-7CA6FB rx-cc w25 h25 bdr100 color-fff fwb iconItem">笔</span>
					</div>
				</div>
			</div>
			<!-- <div class="ww25 pl10 pr10" v-show="tab == 2">
				<div class="bdr4 bg-fff rx-bc pl35 pr35 h110">
					<div>
						<h3 class="fwb color-333 ft32 mb5">{{summaryData.allTimesCourse}}</h3>
						<p class="fwb color-999 ft16">储值卡总售出</p>
					</div>
					<div class="iconBox">
						<img class="w60" src="@/assets/docIcon.png" alt="">
						<span class="bg-7FD7F7 rx-cc w25 h25 bdr100 color-fff fwb iconItem">额</span>
					</div>
				</div>
			</div>
			<div class="ww25 pl10 pr10" v-show="tab == 2">
				<div class="bdr4 bg-fff rx-bc pl35 pr35 h110">
					<div>
						<h3 class="fwb color-333 ft32 mb5">{{summaryData.allTimesEnter}}</h3>
						<p class="fwb color-999 ft16">储值卡总退款</p>
					</div>
					<div class="iconBox">
						<img class="w60" src="@/assets/docIcon.png" alt="">
						<span class="bg-FCC963 rx-cc w25 h25 bdr100 color-fff fwb iconItem">额</span>
					</div>
				</div>
			</div> -->
		</div>
		<div class="mt20 pl10 fwb ft16 color-333 rx-bc" style="border-left: 4px solid #03A3FF;">
			<div>
				售出统计<span class="color-999 fw500">（仅展示筛选范围内前排名前20的{{tab == 1 ? '销售' : '商品'}}）</span> 
			</div>
			<div>
				<el-button-group>
				    <el-button :type="btnTab == 1 ? 'primary' : ''" @click="btnChange(1)">订单金额</el-button>
				    <el-button :type="btnTab == 2 ? 'primary' : ''" @click="btnChange(2)">订单笔数</el-button>
				</el-button-group>
			</div>
		</div>
		<div id="main" class="h500 mt20"  ></div>
		<div class="mt20 color-999 ft12" v-show="tab == 1">温馨提示：总实收和总退款金额中已包含了储值卡订单的实收和退款金额，且销售维度统计中，不包含会员线上购买未选择业绩归属人的订单</div>	
		<div class="mt20 color-999 ft12" v-show="tab == 2">温馨提示：以下明细中包含会员线上购买时未选择推荐人的订单汇总</div>	
		<div class="mt20">
			<el-table :data="tableData.list.slice(tabForm.page*tabForm.limit-tabForm.limit,tabForm.page*tabForm.limit)"
				style="width: 100%">
				<el-table-column prop="realName" fixed label="销售姓名" v-if="tab == 1 " show-overflow-tooltip>
					<template #default="scope">
						<div>{{scope.row.realName}}<span v-if="scope.row.status == 0">(已删除)</span></div>
					</template>
				</el-table-column>
				<el-table-column prop="actualMoney" label="订单总实收金额(元)" sortable v-if="tab == 1 " />
				<el-table-column prop="refundMoney" label="订单总退款金额(元)" sortable v-if="tab == 1 "  />
				<el-table-column prop="orderSuccessCount" label="订单成交笔数(笔)" sortable v-if="tab == 1 "  />
				<el-table-column prop="orderRefundCount" label="订单退款笔数(笔)" sortable v-if="tab == 1 "  />
				<el-table-column prop="storeSellMoney" label="储值卡销售金额(元)" sortable v-if="tab == 1 "  />
				<el-table-column prop="storeRefundMoney" label="储值卡退款金额(元)" sortable v-if="tab == 1 "  />
				
				<!-- //商品 -->
				<el-table-column prop="productName" fixed label="商品名称" v-if="tab == 2 " show-overflow-tooltip>
					<template #default="scope">
						<div>{{scope.row.productName}}<span v-if="scope.row.status == 0">(已删除)</span></div>
					</template>
				</el-table-column>
				<el-table-column prop="actualMoney" label="订单实收金额(元)" sortable v-if="tab == 2 "  />
				<el-table-column prop="refundMoney" label="订单退款金额(元)" sortable v-if="tab == 2 "  />
				<el-table-column prop="shouldMoney" label="应售卖价格(元)" sortable v-if="tab == 2 "  />
				<el-table-column prop="reduceMoney" label="减免金额(元) " sortable v-if="tab == 2 "  />
				<el-table-column prop="orderSuccessCount" label="成交单数" sortable v-if="tab == 2 "  />
				<el-table-column prop="orderRefundCount" label="退款单数" sortable v-if="tab == 2 "  />
				<el-table-column label="操作" fixed="right" >
					<template #default="scope">
						<el-link class="t_d_u" :underline="false" type="primary" @click="lookDetailEvent(scope.row)">详情</el-link>
					</template>
				</el-table-column>
			</el-table>
			<div class="mt20 rx-ec" v-show="tableData.list.length > 0">
				<el-pagination v-model:currentPage="tabForm.page" :page-sizes="[15,20,30,50,100]"
					:page-size="tabForm.limit" layout="total, sizes, prev, pager, next, jumper" :total="tabForm.count"
					@size-change="pageSizeChange" @current-change="pageChange">
				</el-pagination>
			</div>
		</div>
		<Formula v-model="formulaShow" :type="formulaType"></Formula>
		<el-dialog
		    v-model="lookDetailData.show"
		    :title="'【'+lookDetailData.title+'】订单详情'"
			width="1200px"
		    :before-close="()=>{lookDetailData.show = false}"
		    custom-class="popwechat"
		  >
		    <div class="p20">
				<div class="rx-sc mb20">
					<div class="w210 mr12" v-show="tab == 1">
						<el-input v-model="lookDetailForm.keywords" placeholder="请输入商品名称" />
					</div>
					<div class="w210 mr12">
						<el-select v-model="lookDetailForm.orderStatus" placeholder="订单状态">
							<el-option label="订单状态" value="" />
							<el-option label="已退款" value="0"  />
							<el-option label="已支付" value="1"  />
						</el-select>
					</div>
					<div class="w210 mr12" v-show="tab == 2">
						<el-select v-model="lookDetailForm.userId" filterable placeholder="全部销售">
							<el-option label="全部销售" value="" />
							<el-option v-for="item in searchQueryList.coachList" :key="item.id" :label="item.realName"
								:value="item.id" />
						</el-select>
					</div>
					<el-button type="primary" @click="searchDetailList" :icon="Search">搜索</el-button>
				</div>	
				<!-- <div class="mb20 color-999 ft12">温馨提示：当会员购买的期限卡未开始计时情况下，购买天数月份暂时按照30天计算，年份按照365天计算，开始计时后恢复正常周期计算</div> -->
				<el-table :data="lookDetailData.list" height="500px"
					style="width: 100%">
					<el-table-column prop="productName" fixed label="商品名称" v-if="tab == 1 " show-overflow-tooltip>
						<template #default="scope">
							<div>{{scope.row.productName}}<span v-if="scope.row.status == 0">(已删除)</span></div>
						</template>
					</el-table-column>
					<el-table-column prop="userName" fixed label="销售员" v-if="tab == 2 " >
						<template #default="scope">
							<div>{{scope.row.userName}}<span v-if="scope.row.status == 0">(已删除)</span></div>
						</template>
					</el-table-column>
					<el-table-column prop="productType" label="商品类型" v-if="tab == 1">
						<template #default="scope">
							{{ filtersType(scope.row.productType) }}
						</template>
					</el-table-column>
					<el-table-column prop="actualMoney" label="订单实收" />
					<el-table-column prop="refundMoney" label="已退款金额" />
					<el-table-column prop="shouldMoney" label="应售卖价格"  v-if="tab != 1" />
					<el-table-column prop="reduceMoney" label="减免金额" />
					<el-table-column prop="realName" label="购买会员" >
						<template #default="scope">
							<a href="javascript:;" class="color-409EFF tool_btn"
								@click="toMemberDetail(scope.row.encryptionCardMyId)">{{scope.row.realName}}</a>
						</template>
					</el-table-column>
					<el-table-column prop="payTime" label="支付时间" min-width="190" />
					<el-table-column prop="refundTime" label="退款时间"  min-width="190" />
					<el-table-column prop="orderStatus" label="订单状态"  >
						<template #default="scope">
							<span v-if="scope.row.orderStatus == 0" style="color:#FF4349">已退款</span>
							<span v-if="scope.row.orderStatus == 1" style="color:#018000">已支付</span>
						</template>
					</el-table-column>
				</el-table>
				<div class="mt20 rx-ec" v-show="lookDetailData.list.length > 0">
					<el-pagination v-model:currentPage="lookDetailForm.page" :page-sizes="[15,20,30,50,100]"
						:page-size="lookDetailForm.limit" layout="total, sizes, prev, pager, next, jumper" :total="lookDetailData.count"
						@size-change="detailSizeChange" @current-change="getDetailList">
					</el-pagination>
				</div>
			</div>
			<template #footer>
			 	<div class="dialog-footer">
			     	<el-button @click="lookDetailData.show = false">取消</el-button>
			     	<el-button type="primary" @click="lookDetailData.show = false">确定</el-button>
			 	</div>
			 </template>
		</el-dialog>
	</div>
</template>

<script setup>
	import { reactive, ref, onMounted, onBeforeUnmount } from 'vue';
	import { useRouter } from 'vue-router';
	import { Search, Upload, Download } from '@element-plus/icons-vue';
	import * as echarts from 'echarts';
	import utils from '@/common/utils.js'
	import { getStoreAccountStaffSimple } from '@/api/staff.js';
	import { getSellUserAnalysis, getSellUserDetailAnalysis, getSellProductAnalysis, getSellProductDetailAnalysis } from '@/api/dataCenter.js'
	import { ElMessage } from 'element-plus'
	import Formula from '@/components/branch-store/datacenter/formula.vue';
	const router = useRouter();
	var myChart = '';
	//日期范围/月份范围控件配置
	const datePickerDisabled = reactive({
		disabledDate(time) {
		  return time.getTime() > new Date().getTime()
		},
		setMonthDisabled(time) {
		    // 获取当前的月份信息
		    const date = new Date(); // 获取当前的时间基本信息，值是这样的： Tue Jul 20 2021 14:59:43 GMT+0800 (中国标准时间)
		    const year = date.getFullYear(); // 获取当前年份，值是这样的： 2021
		    let month = date.getMonth() + 1; // 获取当前月份，值是这样的： 6   getMonth()方法返回值是0-11，也就是1月份到12月份，所以要加上1，才是当前月份
		    if (month >= 1 && month <= 9) {
		      // 如果是1月到9月就要在前面补上一个0   比如：02、07    月份这样表示
		      month = "0" + month;
		    }
		    const nowDate = year.toString() + month.toString(); // 转换成字符串拼接，最终得到年和月，比如此时的时间是2021年7月20号，所以nowDate的值是：202107
		    // 获取时间选择器的月份信息
		    const timeyear = time.getFullYear(); // 获取时间选择器的年份（有很多）
		    let timemonth = time.getMonth() + 1; // 与上面同理
		    if (timemonth >= 1 && timemonth <= 9) {
		      timemonth = "0" + timemonth;
		    }
		    const elTimeData = timeyear.toString() + timemonth.toString();
		
		    // 返回，时间选择器的日期月份大于当前日期的月份，又因为disabledDate函数是控制月份禁用不可选
		    // 所以，最终就是：时间选择器的月份大于当前的月份，就都禁用掉，所以就实现了最终效果：
		    // 大于等于当前月份都不可选 
		    return elTimeData > nowDate; // 这里虽然是字符串，但是弱类型语言js会做一个转换，是可以比较大小的如： '202107' > '202008'
		},
	})
	
	//搜索字段
	const searchQuery = reactive({
		timeIn: 5,
		userId: [],
		searchTime: [],
		productType: '',
		hasDelete:true,
	})
	//切换类型
	const tab = ref(1);
	const tabClick = function(num) {
		tab.value = num;
		if (num == 1) {
			getStaffSimple()
		}
		initSearchQuery();
		tabSearchClick();
	}
	//搜索项数据
	const searchQueryList = reactive({
		coachList: [],
		productTypeList:[{
			label:'课程卡',
			value:1
		},{
			label:'入场卡',
			value:2
		},{
			label:'储值卡',
			value:3
		},{
			label:'体验卡',
			value:4
		},{
			label:'团体课',
			value:11
		},{
			label:'私教课',
			value:12
		}],
		timeTypeList: [{
				label: '近7天',
				value: 5
			},
			{
				label: '近15天',
				value: 6
			},
			{
				label: '近30天',
				value: 7
			},
			{
				label: '近60天',
				value: 8
			},
			{
				label: '近90天',
				value: 9
			},
			{
				label: '近6月',
				value: 24
			},
			{
				label: '近12月',
				value: 25
			},
			{
				label: '自定义日期',
				value: -1
			},
			{
				label: '自定义月份',
				value: -2
			}
		]
	});
	/**
     * @param (String, Number) value-类型, type-1 商品类型 2-支付状态 3-支付方式
     * 表格字段过滤过滤展示
     */
    function filtersType(value) {
        let text = ''
        searchQueryList.productTypeList.forEach((item) => {
            if (item.value == value) {
                text = item.label
    		}
        })
        return text
    }
	/**
	 * 获取全部销售员
	 */
	function getStaffSimple () {
		getStoreAccountStaffSimple({
			delFlag: searchQuery.hasDelete ? 1 : 0,
		}).then((res) => {
			if (res.code == 0) {
				searchQueryList.coachList = res.data;
			}
		})
	}
	
	//初始化表单
	const initSearchQuery = function() {
		for (var k in searchQuery) {
			searchQuery[k] = Object.prototype.toString.call(searchQuery[k]) == '[object Array]' ? [] : '';
		}
		searchQuery['timeIn'] = 5;
		searchQuery.hasDelete = true;
	}

	//汇总数据字段
	const summaryData = {
		actualMoney: 0, //总实收
		orderSuccessCount: 0, //总笔数
		refundMoney: 0, //总退款
		orderRefundCount:0, //总退笔数
	}
	//图标数据
	var getOption = {
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'cross',
				crossStyle: {
					color: '#999'
				}
			}
		},
		grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
		legend: {
			data: ['实收金额','退款金额']
		},
		xAxis: [{
			type: 'category',
			data: [],
			axisPointer: {
				type: 'shadow'
			},
            axisLabel: {
                interval: 6,
                rotate: 35
            }
		}], 
		yAxis: [{
				type: 'value',
				name: '金额/元',
				min: 0
			}
		],
		series:[
			{
				name: '实收金额',
				type: 'bar',
				barWidth: 30,
				color: '#749EFA',
				data: []
			},
			{
				name: '退款金额',
				type: 'bar',
				barWidth: 30,
				color: '#749EFA',
				data: []
			}
		]
	};
	//获取数据
	const tableData = reactive({
		list: []
	});
	const tabForm = reactive({
		count: 0,
		limit: 15,
		page: 1,
		exportData: 0
	})
	//切换按钮组
	const btnTab = ref(1);
	const btnChange = function(type){
		btnTab.value = type;
		var xData = [],seriesData1 = [],
			seriesData2 = [];
		tableData.list.map((n) => {
			if(tab.value == 1){
				xData.push(n.realName);
			}else{
				xData.push(n.productName);
			}
			if(type == 1){
				seriesData1.push(n.actualMoney);
				seriesData2.push(n.refundMoney)
			}else{
				seriesData1.push(n.orderSuccessCount);
				seriesData2.push(n.orderRefundCount)
			}
			
		})
		getOption.series[0].data = seriesData1;
		getOption.series[1].data = seriesData2;
		if(type == 1){
			getOption.legend.data = ['实收金额','退款金额'];
			getOption.series[0].name = '实收金额';
			getOption.series[1].name = '退款金额';
			getOption.yAxis[0].name = '金额/元';
		}else{
			getOption.legend.data = ['成交订单','退款笔数'];
			getOption.series[0].name = '成交订单';
			getOption.series[1].name = '退款笔数';
			getOption.yAxis[0].name = '笔数/笔';
		}
		myChart.setOption(getOption);
	}
	//切换列表数据展示数量
	const pageSizeChange = function(limit) {
		tabForm.limit = limit;
	}
	//搜索
	const tabSearchClick = function(){
		if (searchQuery.timeIn == '-1') {
            if (!searchQuery.searchTime || !searchQuery.searchTime.length) {
                ElMessage({
                    type : 'warning',
                    message : '请选择日期'
                });
                return;
            }
            if (utils.datedifference(searchQuery.searchTime[0], searchQuery.searchTime[1]) > 31) {
                ElMessage({
                    type : 'warning',
                    message : '自定义日期不能超过31天'
                });
                return;
            }
        }
        if (searchQuery.timeIn == '-2') {
            if (!searchQuery.searchTime || !searchQuery.searchTime.length) {
                ElMessage({
                    type : 'warning',
                    message : '请选择月份'
                });
                return;
            }
            if (utils.MonthsBetw(searchQuery.searchTime[0], searchQuery.searchTime[1]) > 12) {
                ElMessage({
                    type : 'warning',
                    message : '自定义月份不能超过12个月'
                });
                return;
            }
        }
		tabForm.page = 1;
		getData();
	}
	const formulaType = ref('2'); //计算公式类型
	const getData = function() {
		if (tab.value == 1) {
			formulaType.value = '15';
			getSellUserAnalysis({
				timeIn: searchQuery.timeIn,
				searchTime: searchQuery.searchTime.join(' - '),
				userId: searchQuery.userId,
				hasDelete: searchQuery.hasDelete ? 1 :2,
				exportData: tabForm.exportData
			}).then((res) => {
				if (res.code == 0) {
					if (tabForm.exportData == 1) { //导出
						ElMessage({
							type: 'success',
							message: '正在导出，请稍后前往下载中心下载'
						})
						return false;
					}
					for (var k in summaryData) {
						summaryData[k] = res.results[k];
					}
					tableData.list = res.data;
					tabForm.count = res.count;
					var xData = [],
						seriesData1 = [],
						seriesData2 = [];
					tableData.list.map((n) => {
						xData.push(n.realName);
						seriesData1.push(n.actualMoney);
						seriesData2.push(n.refundMoney);
					})
					getOption.xAxis[0].data = xData;
					if (res.data.length > 10) {
                	    getOption.xAxis[0].axisLabel = {
                	        interval:0,
                	        rotate: 40
                	    }
                	} else {
                	    getOption.xAxis[0].axisLabel = {
                	        interval:0,
                	        rotate: 0
                	    }
                	}
					getOption.series[0].data = seriesData1;
					getOption.series[0].barMaxWidth = '10%'
					getOption.series[1].data = seriesData2;
					getOption.series[1].barMaxWidth = '10%'
					getOption.series[0].color = '#48D79D';
					getOption.series[1].color = '#7CA6FB';
					myChart.setOption(getOption);
				}
			})
		} else if (tab.value == 2) { 
			formulaType.value = '16';
			getSellProductAnalysis({
				timeIn: searchQuery.timeIn,
				searchTime: searchQuery.searchTime.join(' - '),
				productType: searchQuery.productType,
				hasDelete: searchQuery.hasDelete ? 1 :2,
				exportData: tabForm.exportData
			}).then((res) => {
				if (res.code == 0) {
					if (tabForm.exportData == 1) { //导出
						ElMessage({
							type: 'success',
							message: '正在导出，请稍后前往下载中心下载'
						})
						return false;
					}
					for (var k in summaryData) {
						summaryData[k] = res.results[k];
					}
					tableData.list = res.data;
					tabForm.count = res.count;
					var xData = [],
						seriesData1 = [],
						seriesData2 = [];
					tableData.list.map((n) => {
						xData.push(n.productName);
						seriesData1.push(n.actualMoney);
						seriesData2.push(n.refundMoney);
					})
					getOption.xAxis[0].data = xData;
					if (res.data.length > 10) {
                	    getOption.xAxis[0].axisLabel = {
                	        interval:0,
                	        rotate: 40
                	    }
                	} else {
                	    getOption.xAxis[0].axisLabel = {
                	        interval:0,
                	        rotate: 0
                	    }
                	}
					getOption.series[0].data = seriesData1;
					getOption.series[0].barMaxWidth = '30%'
					getOption.series[1].data = seriesData2;
					getOption.series[1].barMaxWidth = '30%'
					getOption.series[0].color = '#7FD7F7';
					getOption.series[1].color = '#FCC963';
					myChart.setOption(getOption);
				}
			})
		}
	}


	//下载图表
	const downloadEcharts = function() {
		var myChartSrc = myChart.getDataURL({
			pixelRatio: 2,
			backgroundColor: '#fff'
		});
		var a = document.createElement('a');
		a.style.display = 'none';
		a.href = myChartSrc;
		a.download = '销售统计.png';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}
	//导出表格
	const downloadTable = function() {
		tabForm.exportData = 1;
		getData();
	}
	onMounted(() => {
		var chartDom = document.getElementById('main');
		myChart = echarts.init(chartDom);
		window.addEventListener("resize",function(){
		    myChart.resize();
        });
		getStaffSimple()
		getData()
	})

	//表格排序
	// const sortChange = function(column, prop, order){
	// 	tabForm.page = 1;
	// 	tableData.list.sort((a,b)=>{
	// 		var x = a[prop],y = b[prop];
	// 		if(order == 'ascending'){
	// 			return ((x < y) ? -1 : ((x > y) ? 1 : 0));
	// 		}else{
	// 			return ((x < y) ? ((x > y) ? 1 : 0) : -1);
	// 		}
	// 	})
	// }
	//计算公式
	const formulaShow = ref(false);
	const formulaClick = function() {
		formulaShow.value = true;
	}
	
	//未消查看详情
	const lookDetailData = reactive({
		show:false,
		title:'',
		list:[],
		count:0,
	})
	const lookDetailForm = reactive({
		page:1,
		limit:15,
		userId:[],
		productId:'',
		keywords:'',
		orderStatus:''
	})
	//查看详情
	const lookDetailEvent = function(row){
		if (searchQuery.timeIn == '-1') {
            if (!searchQuery.searchTime || !searchQuery.searchTime.length) {
                ElMessage({
                    type : 'warning',
                    message : '请选择日期'
                });
                return;
            }
            if (utils.datedifference(searchQuery.searchTime[0], searchQuery.searchTime[1]) > 31) {
                ElMessage({
                    type : 'warning',
                    message : '自定义日期不能超过31天'
                });
                return;
            }
        }
        if (searchQuery.timeIn == '-2') {
            if (!searchQuery.searchTime || !searchQuery.searchTime.length) {
                ElMessage({
                    type : 'warning',
                    message : '请选择月份'
                });
                return;
            }
            if (utils.MonthsBetw(searchQuery.searchTime[0], searchQuery.searchTime[1]) > 12) {
                ElMessage({
                    type : 'warning',
                    message : '自定义月份不能超过12个月'
                });
                return;
            }
        }
		lookDetailForm.orderStatus = ''
		if(tab.value == 1){
			lookDetailData.title = row.realName;
			lookDetailForm.userId = row.userId;
		}else{
			lookDetailData.title = row.productName;
			lookDetailForm.productId = row.productId;
		}
		lookDetailForm.page = 1
		searchDetailList();
		lookDetailData.show = true;
	}
	//详情切花分页数量
	const detailSizeChange = function(limit){
		lookDetailForm.limit = limit;
		// searchDetailList();
	}
	//搜索
	const searchDetailList = function(){
		lookDetailForm.page = 1;
		getDetailList();
	}
	//获取详情列表
	const getDetailList = function(){
		lookDetailForm.timeIn = searchQuery.timeIn;
		lookDetailForm.searchTime =	searchQuery.searchTime.join(' - ');
		lookDetailForm.hasDelete = searchQuery.hasDelete ? 1 :2;
		if(tab.value == 1){ //销售员详情
			getSellUserDetailAnalysis(lookDetailForm).then((res) => {
				if (res.code == 0) {
					var type = {
						1:'课程卡',
						2:'入场卡',
						3:'储值卡',
						4:'体验卡',
						11:'团体课',
						12:'私教课'
					}
					lookDetailData.list = res.data.map((n)=>{
						n.productTypeName = type[Number(n.productType)];
						return n;
					});
					lookDetailData.count = res.count;
					
				}
			})
		}else{ //商品详情
			getSellProductDetailAnalysis(lookDetailForm).then((res) => {
				if (res.code == 0) {
					lookDetailData.list = res.data;
					lookDetailData.count = res.count;
				}
			})
		}
		
	}
	//会员详情
	const toMemberDetail = function(id){
		router.push({path:'/branch/memberDetail',query:{encryptionId:id}});
	}
	onBeforeUnmount(() => {
      if (!myChart) {
        return
      }
      myChart.dispose()
      myChart = null
    })
</script>
<style type='css/style' scoped>
	.typeActive{
		position: relative;
		color: #00A3FF;
	}
	.typeActive::after{
		position: absolute;
		content: '';
		bottom: -8px;
		left: 50%;
		width: 70%;
		height: 3px;
		transform: translateX(-50%);
		background-color: #03A3FF;
		border-radius: 2px;
	}
	.bg-03A3FF{
		background-color: #03A3FF;
	}
	.bg-48D79D{
		background-color: #48D79D;
	}
	.bg-7FD7F7{
		background-color: #7FD7F7;
	}
	.bg-7CA6FB{
		background-color: #7CA6FB;
	}
	.bg-FCC963{
		background-color: #FCC963; 
	}
	.iconBox{
		position: relative;
	}
	.iconItem{
		position: absolute;
		bottom: 0;
		right: 0;
	}
	.checkbox {
		height: 16px;
		margin-top: 15px;
	}
</style>
 