<template>
	<!-- 退款 -->
	<el-dialog
	    title="退款"
	 	width="750px"
	    custom-class="popwechat"
		:append-to-body="true"
		@opened="opened"
	>
		<div class="pl20 pt20 pb20 pr20" v-loading="pageData.loading">
			<CommonTip text="储值卡订单退款后，之前使用该储值卡购买的卡项或课程无法再退款。" v-show="pageData.info.storeCardOrderId"></CommonTip>
			<CommonTitle text="购买信息"/> 
			<div class="rx-bs mb16">
				<div class="rx-ss">
					<span class="w130 t_a_r">会员信息：</span>
					<div class="w220">{{pageData.info.realName+'-'+pageData.info.telephoneNum}}</div>
				</div>
				<div class="rx-ss">
					<span class="w150 t_a_r">退款商品：</span>
					<div class="w220" v-if="propData.type == 13">
						<div>{{ pageData.info.cycleName }}({{pageData.info.courseName }})</div>
						<div class="tag">{{ pageData.info.cycleType == 11 ? '固定上课模式' : '自主预约模式' }}</div>
					</div>
					<div class="w220" v-else>{{pageData.info.productName}}</div>
				</div>
			</div>
			<div class="rx-bc mb16" v-if="pageData.info.cycleType != 12">
				<div class="rx-sc ">
					<span class="w130 t_a_r">实收金额：</span>
					<div class="w220">{{pageData.info.actualMoney}}</div>
				</div>
				<div class="rx-sc" v-if="propData.type == 3">
					<span class="w150 t_a_r">储值金额：</span>
					<div class="w220">￥{{ Number(pageData.info.storeMoneyGive) + Number(pageData.info.storeMoneyBase) }} </div>
				</div>
				<div v-else-if="propData.type == 13">
					
				</div>
				<div class="rx-sc" v-else>
					<span class="w150 t_a_r">剩余金额：</span>
					<div class="w220">
						￥{{pageData.info.remainMoney}} 
						<span class="v_a_m">
							<el-tooltip
							    class="box-item"
							    effect="dark"
							    content="剩余有效金额，不包含过期金额"
							    placement="right-start"
							  >
							  <img class="w15 h15 ml5" src="@/assets/wenhao.png" >
							  </el-tooltip> 
						</span>
					</div>
				</div>
			</div>
			<div class="rx-bc mb16" v-if="propData.type == 13">
				<div class="rx-sc">
					<span class="w130 t_a_r">{{ pageData.info.cycleType == 11 ? '剩余未消课节数' : '剩余未消总课节数' }}：</span>
					<div class="w220">
						{{pageData.info.productNum}} 
						<span class="v_a_m">
							<el-tooltip
							    class="box-item"
							    effect="dark"
							    content="退款后会员将自动退出班级，同时将自动退出全部未开始课节"
							    placement="right-start"
							  >
							  <img class="w15 h15 ml5" src="@/assets/wenhao.png" >
							</el-tooltip> 
						</span>
					</div>
				</div>
				<div class="rx-sc">
					<span class="w150 t_a_r">剩余未消价值：</span>
					<div class="w220">
						￥{{pageData.info.remainMoney}} 
					</div>
				</div>
			</div>
			<div class="rx-bc mb16" v-if="pageData.info.cycleType == 11">
				<div class="rx-sc">
					<span class="w130 t_a_r">未开始上课课节数：</span>
					<div class="w220">
						{{ pageData.results.productNum }} 
						<span class="v_a_m">
							<el-tooltip
							    class="box-item"
							    effect="dark"
							    content="指该班级排课后还未到上课时间的课节，会员还没有上课"
							    placement="right-start"
							  >
							  <img class="w15 h15 ml5" src="@/assets/wenhao.png" >
							</el-tooltip> 
						</span>
					</div>
				</div>
				<div class="rx-sc">
					<span class="w150 t_a_r">剩余未上课课节价值：</span>
					<div class="w220">
						￥{{ pageData.results.remainMoney }} 
					</div>
				</div>
			</div>
			<div class="rx-bc mb16" v-if="propData.type == 3">
				<div class="rx-sc ">
					<span class="w130 t_a_r">剩余本金：</span>
					<div class="w220 color-red">{{pageData.info.storeMoneyBase}}</div>
				</div>
				<div class="rx-sc">
					<span class="w130 t_a_r">剩余赠送：</span>
					<div class="w220">{{pageData.info.storeMoneyGive}}</div>
				</div>
			</div>
			<CommonTitle text="退款信息" />
			<div class="mb16" v-if="pageData.info.cycleType == 12">
				<div class="mb16">选择退款订单：</div>
				<el-table ref="multipleTableRef" :data="pageData.tableData" @selection-change="handleSelectionChange" class="single-select-table">
				  	<el-table-column type="selection" width="55" />
				  	<el-table-column label="订单号">
				  	  	<template #default="scope">{{ scope.row.orderNo }}</template>
				  	</el-table-column>
				  	<el-table-column property="productNumBuy" label="购买课节数" />
				  	<el-table-column property="actualMoney" label="实收金额" />
					<el-table-column property="operationTime" label="购买时间" />
					<el-table-column property="productNum" label="剩余未消课节" />
					<el-table-column property="remainMoney" label="剩余未消价值" />
				</el-table>
				<div class="mt16 ft12 color-999">若会员购买的订单全部发生退款时，则会员将自动退出班级，若退款部分订单时，则不会退出班级</div>
			</div>
			<div class="rx-bc mb16">
				<div class="rx-sc ">
					<span class="w110 t_a_r"><span class="color-red">*</span>退款金额：</span>
					<div class="w220">
						<el-input v-model="pageData.actualMoney" type="text" placeholder="请输入退款金额">
							<template #append>元</template>
						</el-input>
					</div>
				</div>
				<div class="rx-sc">
					<span class="w110 t_a_r">退款方式：</span>
					<div class="w220">
						<el-select v-model="pageData.info.refundType" placeholder="请选择">
							<el-option label="退现金" value="1" v-if="!pageData.info.storeCardOrderId" />
							<el-option label="退款至原支付账户" value="2" v-if="pageData.info.buyPayType == 1 || pageData.info.buyPayType == 2" />
							<el-option label="退至储值卡" v-if="pageData.info.storeCardOrderId" value="3" />
						</el-select>
					</div>
				</div>
			</div>
			<div class="rx-bc mb16">
				<div class="rx-sc">
					<span class="w110 t_a_r">销售员：</span>
					<div class="w220">
						<el-select v-model="pageData.info.saleUserId" placeholder="请选择">
							<el-option v-for="item in pageData.saleUserList" :key="item.id" :label="item.realName" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
			</div>
			<div class="rx-ss">
				<span class="w110 t_a_r">备注：</span>
				<div class="flex1">
					<el-input
					   v-model="pageData.info.remark"
					   :rows="3"
					   type="textarea"
					   placeholder="请输入备注"
					 />
				</div>
			</div>
		</div>
	   <template #footer>
			<div class="dialog-footer">
				<el-button @click="close">取消</el-button>
				<el-button type="primary" @click="save">确定</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
	import {ref,reactive,onMounted} from 'vue';
	import { ElMessage} from 'element-plus'
	import CommonTitle from '@/components/CommonTitle.vue'
	import CommonTip from '@/components/CommonTip.vue'
	import {info4Ref,refund } from '@/api/member.js';
	import {getStoreAccountStaffSimple } from '@/api/staff.js';
	import {payDetailToEdit} from '@/api/storeCenter.js'
	const emit = defineEmits(['close','save']);
	const multipleTableRef = ref()
	const propData = defineProps({
		orderid: String,
		memberid: String,
		type: Number,
		payTypeId: Number
	})
	const pageData = reactive({
		loading: false,
		info:{
			realName:'',
			telephoneNum:'',
			productName:'',
			cycleName: '',
			courseName: '',
			cycleType: '',
			productNum: '',
			remainMoney:'',
			refundType:'',
			saleUserId:'',
			remark:'',
			actualMoney:'',
			encryptionId:'',
			storeCardOrderId:'',
			productCycleBuy: '',
			storeMoneyBase: '',
			storeMoneyGive: '',
			buyPayType: ''
		},
		results: {},
		text: '',
		actualMoney: '',
		saleUserList:[],
		selectCheckData: []
	})
	
	
	function close(){
		pageData.PlaceCheckList = []
		emit('close');
	}
	/**
	 * 选择需要退款的订单
	 */
	function handleSelectionChange(val) {
		if (val.length > 1) {
          multipleTableRef.value.clearSelection();
          multipleTableRef.value.toggleRowSelection(val.pop());
        } else {
          pageData.selectCheckData = val
        }
	}
        
	function save(){
		var {encryptionId,refundType,saleUserId,remark,productNum} = pageData.info;
		var actualMoney = pageData.actualMoney
		if (pageData.info.cycleType == 12) {
			if (!pageData.selectCheckData.length) {
				ElMessage.warning('请选择需要退款的订单')
				return false;
			}
			encryptionId = pageData.selectCheckData[0].encryptionId
			productNum = pageData.selectCheckData[0].productNum
		}
		if(!actualMoney){
			ElMessage.warning('请输入退款金额')
			return false;
		}
		// if (propData.type == 13) {
		// 	if (Number(actualMoney)>Number(pageData.info.remainMoney)) {
		// 		ElMessage.warning('退款金额不得大于剩余课节价值')
		// 		return false;
		// 	}
		// } else {
			
		// }
		if (Number(actualMoney)>Number(pageData.info.actualMoney)) {
			ElMessage.warning('退款金额不得大于实收金额')
			return false;
		}
		refund({
			encryptionId,
			actualMoney,
			refundType,
			saleUserId,
			productNum,
			remark
		}).then((res)=>{
			if(res.code == 0){
				ElMessage({
					type: 'success',
					message: '退款成功'
				})
				emit('save');
			}
		})
	}
	//获取销售
	function getSysUser(id){
		//获取销售员
		getStoreAccountStaffSimple().then((res)=>{
			if(res.code == 0){
				pageData.saleUserList = res.data;
			}
		})
	}
	function opened(){
		if(propData.orderid && propData.memberid){
			pageData.loading = true
			info4Ref({
				encryptionId: propData.orderid
			}).then((res)=>{
				pageData.loading = false
				if(res.code == 0){
					for(var key in pageData.info){
						pageData.info[key] = res.data[key] || 0;
					}
					pageData.info.productCycleBuy = Number(res.data.storeConsumeBase) + Number(res.data.storeMoneyGive)
					pageData.info.refundType =  res.data.storeCardOrderId ? '3' : '1';
					pageData.info.remark =  '';
					pageData.actualMoney = ''
					pageData.info.encryptionId = propData.orderid;
					pageData.info.saleUserId = pageData.info.saleUserId == 0 ? '' : pageData.info.saleUserId
					pageData.tableData = res.remark || []
					pageData.results = res.results || []
				}
			}).catch(() => {
				pageData.loading = false
			})
			getSysUser(propData.memberid);
		}
	}
</script>

<style scoped>
.single-select-table /deep/ thead .el-table-column--selection .cell{
    display: none;
}
.single-select-table /deep/ .el-checkbox__inner { width: 16px; height: 16px; border-radius: 50%; }
.single-select-table /deep/ .el-checkbox__inner::after{
    height: 8px;
    left: 5px;
}
.tag {
	padding: 2px 5px;
	border-radius: 2px;
	background-color: #F4912B;
	color: #FFF;
	width: 100px;
	text-align: center;
	line-height: 20px;
}
</style>
