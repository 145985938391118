import { createStore } from 'vuex'

const keepLiveRoute = [];

export default createStore({
  state: {
  	keepLiveRoute,
	moduleIcon:[  //小程序配置功能风格图标
		  {
			icon11:require('@/assets/applet/icon11.png'),
			icon12:require('@/assets/applet/icon12.png'),
			icon13:require('@/assets/applet/icon13.png'),
			icon14:require('@/assets/applet/icon14.png'),
			icon15:require('@/assets/applet/icon15.png'),
			icon16:require('@/assets/applet/icon16.png'),
			icon17:require('@/assets/applet/icon17.png'),
			icon18:require('@/assets/applet/icon18.png')
		  },
		  {
			icon21:require('@/assets/applet/icon21.png'),
			icon22:require('@/assets/applet/icon22.png'),
			icon23:require('@/assets/applet/icon23.png'),
			icon24:require('@/assets/applet/icon24.png'),
			icon25:require('@/assets/applet/icon25.png'),
			icon26:require('@/assets/applet/icon26.png'),
			icon27:require('@/assets/applet/icon27.png'),
			icon28:require('@/assets/applet/icon28.png')
		  },
		  {
			icon31:require('@/assets/applet/icon31.png'),
			icon32:require('@/assets/applet/icon32.png'),
			icon33:require('@/assets/applet/icon33.png'),
			icon34:require('@/assets/applet/icon34.png'),
			icon35:require('@/assets/applet/icon35.png'),
			icon36:require('@/assets/applet/icon36.png'),
			icon37:require('@/assets/applet/icon37.png'),
			icon38:require('@/assets/applet/icon38.png')
		  },
		  {
			icon41:require('@/assets/applet/icon41.png'),
			icon42:require('@/assets/applet/icon42.png'),
			icon43:require('@/assets/applet/icon43.png'),
			icon44:require('@/assets/applet/icon44.png'),
			icon45:require('@/assets/applet/icon45.png'),
			icon46:require('@/assets/applet/icon46.png'),
			icon47:require('@/assets/applet/icon47.png'),
			icon48:require('@/assets/applet/icon48.png')
		  }
	  ],
	  serviceIcon:[  //小程序配置服务风格图标
	  		  {
	  			icon11:require('@/assets/applet/icon51.png'),
	  			icon12:require('@/assets/applet/icon52.png'),
	  			icon13:require('@/assets/applet/icon53.png'),
	  			icon14:require('@/assets/applet/icon54.png'),
	  			icon15:require('@/assets/applet/icon55.png'),
	  			icon16:require('@/assets/applet/icon56.png'),
	  			icon17:require('@/assets/applet/icon57.png'),
	  			icon18:require('@/assets/applet/icon58.png'),
	  			icon19:require('@/assets/applet/icon59.png')
	  		  },
	  		  {
	  			icon21:require('@/assets/applet/icon61.png'),
	  			icon22:require('@/assets/applet/icon62.png'),
	  			icon23:require('@/assets/applet/icon63.png'),
	  			icon24:require('@/assets/applet/icon64.png'),
	  			icon25:require('@/assets/applet/icon65.png'),
	  			icon26:require('@/assets/applet/icon66.png'),
	  			icon27:require('@/assets/applet/icon67.png'),
	  			icon28:require('@/assets/applet/icon68.png'),
	  			icon29:require('@/assets/applet/icon69.png')
	  		  }
	  ],
	  navIcon:[  //小程序配置风格导航图标
	  		  {
	  			icon11:require('@/assets/applet/icon71.png'),
	  			icon12:require('@/assets/applet/icon72.png'),
	  			icon13:require('@/assets/applet/icon73.png'),
	  			icon14:require('@/assets/applet/icon74.png'),
	  		  },
	  		  {
	  			icon21:require('@/assets/applet/icon81.png'),
	  			icon22:require('@/assets/applet/icon82.png'),
	  			icon23:require('@/assets/applet/icon83.png'),
	  			icon24:require('@/assets/applet/icon84.png'),
	  		  }
	  ],
	  appletFormData:[  //小程序配置
			{
			  title:'首页模块',
			  subTitle:'点击模块进行编辑，可拖动排序。',
			  active:0,
			  list:[
				{
					name:'门店信息',
					storeName:'DTO Dance Studio',
					logo:'',
					component:'StoreInfo',
					banners:['1'],
					time:'',
					phone:'',
					coordinate:'',
					city:[],
					address:'',
					cityAddress: '',
					sid: '',
					moduleName:'1_STORE_INFO',
					moduleStatus:true
				},
				{
					name:'功能入口',
					component:'FunEntry',
					moduleName:'1_FUNCTION_ENTRANCE',
					moduleStatus:true,
					showNum:'3',
					moduleType:'1',
					modules:[{
						name:'约课',
						checked:false,
						remark:'可跳转课程预约页面'
					},{
						name:'预约场地',
						checked:false,
						remark:'可跳转预约场地页面'
					},{
						name:'卡办理',
						checked:false,
						remark:'可跳转会员卡售卖页面'
					},{
						name:'教练列表',
						checked:false,
						remark:'可跳转教练列表页面'
					},{
						name:'班课',
						checked:false,
						remark:'可跳转班课售卖页面'
					},{
						name:'商店',
						checked:false,
						remark:'可跳转实物商店页面'
					},{
						name:'积分商城',
						checked:false,
						remark:'可跳转积分商城页面'
					},{
						name:'营销活动',
						checked:false,
						remark:'可跳转营销活动页面'
					}]
				},
				{
					name:'教练介绍',
					component:'CoachIntroduction',
					moduleName:'1_COACH_INTRODUCTION',
					moduleStatus:true,
					active:-1,
					showType:'1',
					list:[]
				},
				{
					name:'推荐班级',
					component:'RecommendedCycles',
					moduleName:'1_RECOMMENDED_CYCLES',
					moduleStatus:true,
					active:-1,
					showType:'1',
					showRule:'1',
					list:[]
				},
				{
					name:'推荐课程',
					component:'RecommendedCourses',
					moduleName:'1_RECOMMENDED_COURSES',
					moduleStatus:true,
					active:-1,
					showType:'1',
					showRule:'1',
					list:[]
				},
				{
					name:'课程卡',
					component:'CourseCard',
					moduleName:'1_COURSE_NAME_CARD',
					moduleStatus:true,
					active:-1,
					showType:'1',
					showRule:'1',
					list:[]
				},
				{
					name:'入场卡',
					component:'EntranceCard',
					moduleName:'1_ADMISSION_CARD',
					moduleStatus:true,
					active:-1,
					showType:'1',
					showRule:'1',
					list:[]
				},
				{
					name:'储值卡',
					component:'StoredCard',
					moduleName:'1_STORED_VALUE_CARD',
					moduleStatus:true,
					active:-1,
					showType:'1',
					showRule:'1',
					list:[]
				},
				{
					name:'体验卡',
					component:'ExperienceCard',
					moduleName:'1_EXPERIENCE_CARD',
					moduleStatus:true,
					active:-1,
					showType:'1',
					showRule:'1',
					list:[]
				}
			  ],
			 
			},{
			  title:'预约页面',
			  subTitle:'该模块不可编辑,可修改预约类型名称',
			  active:0,
			  list:[
			  	{
					name:'预约类型',
					active:-1,
					moduleName:'2_APPOINTMENT_TYPE',
					component:'OrderType',
					moduleStatus:true,
					list:[
							{name:'团体课',courseType:'11',newName:'',checked:false},
							// {name:'私教',courseType:'12',newName:'',checked:false},
							{name:'小班课',courseType:'13',newName:'',checked:false},
							{name:'私教1V1',courseType:'14',newName:'',checked:false},
							{name:'私教1V多',courseType:'15',newName:'',checked:false},
							{name:'场地预约',courseType:'21',newName:'',checked:false},
						]
				},
			  ]
		  },{
			  title:'我的页面',
			  subTitle:'点击模块进行编辑，可拖动排序。',
			  active:0,
			  list:[
			  	{
					name:'会员资产',
					moduleStatus:true,
					component:'MemberAssets',
					active:0,
					moduleName:'3_MEMBER_ASSETS',
					list:[
						{name:'卡项',newName:'',courseType:'0'},
						{name:'团体课',newName:'',courseType:'11'},
						{name:'私教课',newName:'',courseType:'12'},
						{name:'小班课',newName:'',courseType:'13'}
					]
				},
			  	{
					name:'累计上课',
					tips:'无需编辑',
					moduleStatus:true,
					moduleName:'3_CUMULATIVE_CLASS',
					component:'AllClass',
				},
			  	{
					name:'体测中心',
					tips:'无需编辑',
					moduleStatus:false,
					moduleName:'3_BODY__CENTER',
					component:'TestCenter',
				},
			  	{
					name:'更多服务',
					moduleStatus:false,
					moduleName:'3_MORE_FEATURES',
					component:'MoreService',
					showType:'1',
					modules:[{
						name:'预约日程',
						type:1,
						checked:false,
					},{
						name:'优惠券',
						type:2,
						checked:false,
					},{
						name:'我的订单',
						type:3,
						checked:false,
					},{
						name:'体验卡',
						type:4,
						checked:false,
					},{
						name:'分享有礼',
						type:5,
						checked:false,
					},{
						name:'我的活动',
						type:6,
						checked:false,
					},{
						name:'收货地址',
						type:7,
						checked:false,
					},{
						name:'我的评价',
						type:8,
						checked:false,
					},{
						name:'我的班级',
						type:9,
						checked:false,
					}]
				},
			  ]
		  },{
			  title:'底部导航',
			  subTitle:'',
			  active:0,
			  list:[
			  	{
					name:'底部导航',
					showType:'1',
					iconType:'1',
					moduleStatus:true,
					component:'BottomNav',
					moduleName:'4_BOTTOM_NAVIGATION',
					list:[
						{name:'首页',newName:'',id:1,checked:true},
						{name:'约课',newName:'',id:2,checked:true},
						{name:'商城',newName:'',id:3,checked:false},
						{name:'我的',newName:'',id:4,checked:true},
						]
				},
			  ]
		  },{
			  active:0,
			  list:[
				  {
				  	name:'选择皮肤',
				  	bgColor:'1',
					active:0,
					bgUse:0,
					colorUse:0,
					moduleStatus:true,
					component:'SelectTheme',
					moduleName:'5_SKIN_SETTINGS',
				  	list:[
				  		{name:'活力黄',color:'#FDC402',textColor:'#D6AA00',id:1},
				  		{name:'优雅紫',color:'#B485FD',textColor:'#8864BF',id:2},
				  		{name:'仙女粉',color:'#FC6CB7',textColor:'#C85792',id:3},
				  		{name:'科技蓝',color:'#1890FF',textColor:'#136FC4',id:4},
				  		{name:'健康绿',color:'#25C68A',textColor:'#02A76A',id:5},
				  		{name:'奔放红',color:'#FF4D4F',textColor:'#B7393A',id:6},
				  		{name:'高级灰',color:'#6F6475',textColor:'#716B75',id:7},
				  		]
				  },
			  ]
		  }
	  ],
	  newStoreId:{
		  value:false //小程序配置当前选中的分店sid
	  },
	  addCoachForm:{ //添加教练
		headImgUrl:'',
		name:'',
		id:0,
		userId:'',
		labels:[],
		certificateLink:[],
		briefIntroduction:''
	  },
	  saveStatus:{
		  value:false //小程序配置暂存或保存状态
	  },
	  areaList:[],//小程序配置地区数据
	  storeLogo:{
		  value:''
	  }
  },
  mutations: {
	setAppletData(state,datas){
		var {appletFormData} = state;
		appletFormData[datas.name] = datas.value;
		state.appletFormData = appletFormData;
	},
	setAppletListData(state,datas){
		var {appletFormData} = state;
		appletFormData[datas.name].list[datas.index] = datas.value;
		state.appletFormData = appletFormData;
	},
	ClearKeep(state) {
      if (state.keepLiveRoute.length > 0) {
      	state.keepLiveRoute = [];
      }
    },
    SetKeep(state,data) {
      	state.keepLiveRoute = data.keepLiveRoute;
      
    }
  },
  actions: {
  },
  modules: {
  }
})
