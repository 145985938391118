import {postAjax,getAjax}  from "../common/axios.js"

// 查询机构台牌收款码
export const getPayCode = params => {
	return postAjax('/api/upay/storeQrcode/getPayCode',params);
}

// 查询机构支付配置信息
export const selectOnePayOrderById = params => {
	return postAjax('/api/upay/wap/selectOnePayOrderById',params);
}

// 获取支付二维码详细信息
export const getInfoByQrCode = params => {
	return postAjax('/api/upay/storeQrcode/wap/getInfoByQrCode',params);
}

// 发起扫台牌码支付
export const startPayOrder = params => {
	return postAjax('/api/upay/wap/startPayOrder',params);
}

// 发起扫台牌码支付
export const macScanCode = params => {
	return postAjax('/api/upay/wap/macScanCode',params);
}
