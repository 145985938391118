<template>
    <div>
        应用中心
        
    </div>
</template>

<script>
</script>

<style>
</style>
