<template>
	<div class="store_info" v-show="storeData.moduleStatus">
		<div class="rx-sc mb10">
			<h3 class="ft16 mr20">功能入口</h3>
			<span class="color-999 ft12">可自定义选择模块进行展示</span>
		</div>
		<div class="store_form mt20">
			<div class="rx-sc mb12">
				<span>展示数量：</span>
				<div>
					<el-radio-group v-model="storeData.showNum" @change="showNumChange">
						<el-radio label="3" size="large">3个</el-radio>
						<el-radio label="4" size="large">4个</el-radio>
						<el-radio label="6" size="large">6个</el-radio>
						<el-radio label="8" size="large">8个</el-radio>
					</el-radio-group>
				</div>
			</div>
			<div class="rx-sc mb12">
				<span>模块风格：</span>
				<div>
					<el-radio-group v-model="storeData.moduleType">
						<el-radio label="1" size="large">风格一</el-radio>
						<el-radio label="2" size="large">风格二</el-radio>
						<el-radio label="3" size="large">风格三</el-radio>
					</el-radio-group>
				</div>
			</div>
			<div class="rx-ss mb12">
				<span>展示模块：</span>
				<div class="w300">
					<ul class="fun_list">
						<template v-for="(item,index) in storeData.modules" :key="index">
							<li class="t_a_c ww25" v-if="pageData.fide == 'false' && item.nameType == 2 ? false:true">
								<img class="ww50" :src="moduleIcon[storeData.moduleType-1]['icon'+storeData.moduleType+''+item.nameType]" alt="">
								<p class="color-333 ftb ft12">{{item.name}}</p>
								<p v-if="item.nameType == 1 || (item.nameType == 2 && pageData.fide == 'true') || item.nameType == 3 || item.nameType == 4 || item.nameType == 5"><el-checkbox @change="checkChange" :disabled="item.disabled" v-model="item.checked" size="large"></el-checkbox></p>
								<p v-else><el-checkbox @change="checkChange" disabled v-model="item.checked" size="large"></el-checkbox></p>
							</li>
						</template>
					</ul>
				</div>
			</div>
			<div class="rx-ss mb12">
				<span>已选模块：</span>
				<div>
					<template v-for="(item,index) in storeData.modules" :key="index">
						<div class="rx-sc mb10" v-if="item.checked">
							<div class="w100 mr10">
								<el-input type="text" v-model="item.name" maxlength="4"></el-input>
							</div>
							<span class="color-999 ft12">{{item.remark}}</span>
						</div>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	const propData = defineProps({
	  tab:String,
	  itemIndex:Number
	})
	import { ref,reactive, onMounted} from 'vue'
	import { useStore } from 'vuex'
	import utils from '@/common/utils.js';

	const store = useStore();
	const storeData = store.state.appletFormData[propData.tab].list[propData.itemIndex];
	const moduleIcon = store.state.moduleIcon;
	const pageData = reactive({
		uploadData:'',
		areaList:[],
		fide: localStorage.getItem("fide")
	})
	//切换展示数量 重置展示模块勾选状态
	function showNumChange(){
		storeData.modules.map((n,i)=>{
			n.disabled = false;
			n.checked = false;
		})
	}
	//选择模块判断展示数量  超出禁用其他模块
	function checkChange(){
		var checks = storeData.modules.filter(n=>n.checked);
		storeData.modules.map((n,i)=>{
			if(checks.length == storeData.showNum){ //展示数量已满
				n.disabled = n.checked ? false : true;
			}else{
				n.disabled = false;
			}
		})
	}
    onMounted(()=>{
		checkChange()
	})
	
</script>

<style scoped lang="stylus">
	.store_info{
		.fun_list{
			overflow hidden;
			li{
				float left;
				padding 10px 0;
			}
		}
		.store_form{
			clear both;
		}
	}
</style>
