<template>
    <div class="mt25 w800">
        <div v-if="utils.getMenuRole(3350301)">
            <CommonTitle text="基础设置" />
            <el-card class="box-card mb25" shadow="never">
                <div class="rx-bc">
                   <h4 class="ft16 pb10">预约设置</h4>
                   <el-button type="primary" :icon="EditPen" plain @click="rouseDialog(1)">编辑</el-button>
                </div>
                <div class="pt25">
                    <div class="rx-c">
                        <div class="circle mr10"></div>
                        <h4>可预约范围</h4>
                    </div>
                    <p class="color-666 ml15 mt15">
                        会员可预约 <span class="ml5 mr5 color-red">{{ pageData.orderLimit && pageData.orderLimitNum ? pageData.orderLimitNum : '-' }}</span> {{ pageData.limitNumUnit == 1 ? '天' : '周' }}以内的场地。
                    </p>
                </div>
                <div class="pt25">
                    <div class="rx-c">
                        <div class="circle mr10"></div>
                        <h4>取消预约时间</h4>
                    </div>
                    <p class="color-666 ml15 mt15">
                        允许会员在场地预约开始前 <span class="ml5 mr5 color-red">{{pageData.orderCancelLimit && pageData.orderCancelNum ? pageData.orderCancelNum : '-' }}</span>  小时取消预约，员工代取消不受此限制。
                    </p>
                </div>
            </el-card>
        </div>
        <div v-if="utils.getMenuRole(3350302)">
            <CommonTitle text="预约限制" />
            <div>
                <div class="color-999 ft12 mb16">温馨提示：预约限制锁定的时间将影响会员端不可预约，但不影响场馆代预约</div>
                <div class="rx-c mb20">
                    <div class="mr12">
                        <el-button type="primary" @click="astrict(1)">限制</el-button>
                        <el-button type="primary" @click="astrict(2)">取消</el-button>
                    </div>
                    <el-form :inline="true" :model="pageData.formInline" class="demo-form-inline">
                        <el-form-item>
                            <el-select v-model="pageData.formInline.groupId" @change="(val) => {getField(val, 1)}">
                                <el-option
                                    v-for="item in pageData.grouping"
                                    :key="item.id"
                                    :label="item.groupName"
                                    :value="item.id"
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-select v-model="pageData.formInline.roomIds" placeholder="全部场地" multiple collapse-tags  @change="getFieldRoom">
                                <el-option
                                    v-for="item in pageData.tableList"
                                    :key="item.id"
                                    :label="item.roomName"
                                    :value="item.id"
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="search" :icon="Search">搜索</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="rx-c">
                    <div class="bg-EFFBFF weekDiv c_p" :class="[pageData.active == item.value ? 'active' : '']" v-for="item in pageData.weekList" :key="item.value" @click="activeVal(item.value)">{{ item.name }}</div>
                </div>
                <div class="mt20 mb20 rx-c tableout posi-r">
                    <div class="box-left">
                        <div class="h50 w130 headcell">场地名称</div>
                        <div class="w130 h50 o_w_t headcell" v-for="week in pageData.tableDataTitle" :key="week">{{week.roomName}}</div>
                    </div>
                    <div class="o_y_x pl130 w800">
                        <div :style="{width: pageData.tableData.length*100+'px'}">
                            <div class=" w100 h50 d_i_b headcell" v-for="week in pageData.tableData" :key="week">{{week.timer}}</div>
                        </div>
                        <div class="rx-c">
                            <div class="w100" style="vertical-align:top;" v-for="weeks in pageData.tableData1" :key="weeks">
                                <div class="h50 w100 bodycell" v-for="week in weeks.weekArray" :key="week">
                                    <div class="h50 posi-r" v-if="week.lockSettingList == 1">
                                        <img class="img w15 h15 posi-a" src="@/assets/lock2.png">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <el-dialog
        v-model="pageData.dialogVisible"
        title="会员预约限制"
        width="530px"
        :before-close="handleClose"
      >
        <div class="pt25 pl20 pr20 dialog_form">
            <el-form :model="pageData.form" label-width="120px">
                <el-form-item label="可预约范围:">
                    <el-radio-group v-model="pageData.form.orderLimit">
                        <el-radio :label="0"><span class="color-666 fw400">不限制</span></el-radio>
                        <el-radio :label="1"><span class="color-666 fw400">自定义</span></el-radio>
                    </el-radio-group>
                    <div v-if="pageData.form.orderLimit">
                        <div>会员可预约
                            <el-input-number class="w65 v_a_m" v-model="pageData.form.orderLimitNum" :min="0" :max="9999" :precision="0" :step="1" :controls="false" />
                            <el-select class="v_a_m form_select" v-model="pageData.form.limitNumUnit">
                                <el-option label="天" value="1"></el-option>
                                <el-option label="周" value="2"></el-option>
                            </el-select>
                            <span class="ml10">以内的场地</span>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="预约可以取消:">
                    <el-radio-group v-model="pageData.form.orderCancelLimit">
                        <el-radio :label="0"><span class="color-666 fw400">否</span></el-radio>
                        <el-radio :label="1"><span class="color-666 fw400">是</span></el-radio>
                    </el-radio-group>
                    <div v-if="pageData.form.orderCancelLimit">
                        <span>允许会员在场地预约开始前&nbsp;</span>
                        <el-input class="w115 t_a_c" v-model="pageData.form.orderCancelNum" maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')">
                            <template #append>小时</template>
                        </el-input>
                        <span>&nbsp;取消预约，员工代取消不受此限制</span>
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="handleClose">取消</el-button>
                <el-button type="primary" @click="confirm">确定</el-button>
            </span>
        </template>
    </el-dialog>
    <el-dialog
        v-model="pageData.dialogShow"
        :title="pageData.title"
        width="530px"
        :before-close="dialogShowClose"
      >
        <div class="pt25 pl50 pr50 dialog_form">
            <el-form ref="ruleFormRef" :rules="rules" :model="pageData.dialogShowForm" label-width="80px">
                <el-form-item label="分组：" prop="groupId">
                    <el-select v-model="pageData.dialogShowForm.groupId" @change="(val) => {getField(val, 2)}">
                        <el-option
                            v-for="item in pageData.grouping"
                            :key="item.id"
                            :label="item.groupName"
                            :value="item.id"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="场地：">
                    <el-select v-model="pageData.dialogShowForm.roomIds" placeholder="全部场地" multiple collapse-tags>
                            <el-option
                                v-for="item in pageData.formTableList"
                                :key="item.id"
                                :label="item.roomName"
                                :value="item.id"
                            />
                    </el-select>
                </el-form-item>
                <el-form-item label="周期：" prop="week">
                    <el-checkbox-group v-model="pageData.dialogShowForm.week">
                        <el-checkbox label="1" >
                            <span class="color-333">周一</span>
                        </el-checkbox>
                        <el-checkbox label="2" >
                            <span class="color-333">周二</span>
                        </el-checkbox>
                        <el-checkbox label="3" >
                            <span class="color-333">周三</span>
                        </el-checkbox>
                        <el-checkbox label="4" >
                            <span class="color-333">周四</span>
                        </el-checkbox>
                        <el-checkbox label="5" >
                            <span class="color-333">周五</span>
                        </el-checkbox>
                        <el-checkbox label="6" >
                            <span class="color-333">周六</span>
                        </el-checkbox>
                        <el-checkbox label="7" >
                            <span class="color-333">周日</span>
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="时间：">
                    <div class="rx-c">
                        <el-time-select
                            v-model="pageData.dialogShowForm.startTime"
                            :max-time="pageData.dialogShowForm.endTime"
                            class="mr-4"
                            placeholder="开始时间"
                            startTime
                            :start="pageData.groupRow.startTime"
                            :step="pageData.groupRow.perSceneTime == 30 ? '00:30' : '00:60'"
							:end="pageData.groupRow.endTime"
                        />
                        <span class="pl20 pr20">至</span>
                        <el-time-select
                            v-model="pageData.dialogShowForm.endTime"
                            :min-time="pageData.dialogShowForm.startTime"
                            placeholder="结束时间"
                            :start="pageData.groupRow.startTime"
                            :step="pageData.groupRow.perSceneTime == 30 ? '00:30' : '00:60'"
							:end="pageData.groupRow.endTime"
                        />
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialogShowClose">取消</el-button>
                <el-button type="primary" @click="dialogShowConfirm">确定</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import { EditPen } from '@element-plus/icons-vue'
    import { ElMessage } from 'element-plus'
    import { sportRoomSettingInfo, sportRoomSettingUpdate, sportRoomLockSetting, sportRoomGroupList, settingInsert, settingCancel } from '@/api/appstore.js';
    import { sportRoomList } from '@/api/place.js';
    import { Search } from '@element-plus/icons-vue';
    import utils from '@/common/utils.js';
    import CommonTitle from '@/components/CommonTitle.vue'
    import moment from 'moment'
    const refWidth = ref()
    const ruleFormRef = ref()
    const pageData = reactive({
        dialogVisible: false, // 编辑弹框
        orderLimitNum: 4, // 可预约范围
        limitNumUnit: '',
        orderCancelNum: 1,
		orderLimit:'', //可预约范围:0=不限制;1=自定义
		orderCancelLimit:'', //会员可取消:1=是;0=否
        form:{
            orderLimit: 0,
            orderLimitNum: '',
            limitNumUnit: '2',
            orderCancelLimit: 0,
            orderCancelNum: '',
        },
        formInline:{
            roomIds: []
        },
        weekList: [
            {name: '周一', value: 1},
            {name: '周二', value: 2},
            {name: '周三', value: 3},
            {name: '周四', value: 4},
            {name: '周五', value: 5},
            {name: '周六', value: 6},
            {name: '周日', value: 7},
        ],
        active: 1,
        grouping: [],
        tableList: [],
        formTableList: [],
        tableDataTitle: [],
        tableData: [],
        tableData1: [],
        dialogShow: false, // 限制/取消弹框
        groupRow:{}, // 弹框选择的分组数据
        title: '', // 限制/取消弹框标题
        dialogShowForm: {
            groupId: '',
            roomIds: [],
            week: [], // 限制周几的数据
        }, // 限制/取消表单
    })
    const rules = reactive({
        groupId: [{ required: true, message: '请选择分组', trigger: 'change' }],
        week: [{ type: 'array', required: true, message: '请选择周期', trigger: 'change'}],
    })
    let encryptionId = ''
    /**
     * 获取设置信息
     */
    function getInfo(){
        sportRoomSettingInfo().then(res => {
            if (res.code == 0) {
                pageData.orderLimitNum = res.data.orderLimitNum
                pageData.limitNumUnit = res.data.limitNumUnit
                pageData.orderCancelNum = res.data.orderCancelNum
                pageData.orderLimit = res.data.orderLimit
                pageData.orderCancelLimit = res.data.orderCancelLimit
                pageData.form.orderLimit = res.data.orderLimit
                pageData.form.orderLimitNum = res.data.orderLimitNum
                pageData.form.limitNumUnit = res.data.limitNumUnit + ''
                pageData.form.orderCancelLimit = res.data.orderCancelLimit
                pageData.form.orderCancelNum = res.data.orderCancelNum
                encryptionId = res.data.encryptionId
            }
        })
    }
    /**
     * 搜索
     */
    function search (){
        getFieldLock()
    }
    /**
     * 获取锁场限制信息
     */
    function getFieldLock(){
        let params = {
            week: pageData.active,
            groupId: pageData.formInline.groupId, 
            roomIds: pageData.formInline.roomIds.length ? pageData.formInline.roomIds.join(',') : 0
        }
        sportRoomLockSetting(params).then(res => {
            if (res.code == 0) {
                pageData.tableDataTitle = res.remark
                if (!res.remark2) {
                    pageData.tableData = []
                } else {
                    let startTime = res.remark2.startTime.substr(0,2)+':'+ res.remark2.startTime.substr(2,4)
                    let endTime = res.remark2.endTime.substr(0,2)+':'+ res.remark2.endTime.substr(2,4)
                    let meLength = moment('2022-06-03 '+ endTime).diff('2022-06-03 '+ startTime, "minute")/30
                    let start = startTime.split(':').map(Number)
                    let arr = [],arr2 = []
                    for(let i = 0; i < meLength; i++){
                        let obj = { timer:''}
                        if (start[1] == 30) {
                            if(i%2 == 0){
                                if (start[0]+i/2+1<=9) {
                                    obj.timer = '0'+(start[0]+i/2)+':'+start[1]+'-0'+(start[0]+i/2+1)+':00'
                                }  else if(start[0]+i/2+1 == 10) {
                                    obj.timer = '0'+(start[0]+i/2)+':'+start[1]+'-'+(start[0]+i/2+1)+':00'
                                } else {
                                    obj.timer = (start[0]+i/2)+':'+start[1]+'-'+(start[0]+i/2+1)+':00'
                                }
                            } else{
                                if (start[0]+((i-1)/2)+1<=9) {
                                    obj.timer = '0'+(start[0]+((i-1)/2)+1)+':00-0'+(start[0]+((i-1)/2)+1)+':30'
                                }  else if(start[0]+((i-1)/2)+1 == 10) {
                                    obj.timer = +(start[0]+((i-1)/2)+1)+':00-'+(start[0]+((i-1)/2)+1)+':30'
                                } else {
                                    obj.timer = (start[0]+((i-1)/2)+1)+':00-'+(start[0]+((i-1)/2)+1)+':30'
                                }

                            }
                        } else {
                            if(i%2 == 0){
                                if ((start[0]+i/2)<=9) {
                                    obj.timer = '0'+(start[0]+i/2)+':00-0'+(start[0]+i/2)+':30'
                                }  else if((start[0]+i/2) >= 10) {
                                    obj.timer = (start[0]+i/2)+':00-'+(start[0]+i/2)+':30'
                                }
                            } else{
                                if ((start[0]+((i-1)/2)+1) <= 9) {
                                    obj.timer = '0'+(start[0]+((i-1)/2))+':30-0'+(start[0]+((i-1)/2)+1)+':00'
                                }  else if((start[0]+((i-1)/2)+1) == 10) {
                                    obj.timer = '0'+(start[0]+((i-1)/2))+':30-'+(start[0]+((i-1)/2)+1)+':00'
                                } else {
                                    obj.timer = (start[0]+((i-1)/2))+':30-'+(start[0]+((i-1)/2)+1)+':00'
                                }
                            }
                        }
                        arr.push(obj)
                    }
                    pageData.tableData = arr
                    for (var i=0; i < arr.length; i++ ) {
                        let obj = { weekArray: [] }
                        for (var j=0; j < pageData.tableDataTitle.length; j++ ) {
                            let Object = {lockSettingList: 0}
                            if (res.data) {
                                for (var k=0; k < res.data.length; k++ ) {
                                    if (pageData.tableDataTitle[j].id == res.data[k].roomId ) {
                                        res.data[k].lockSettingList.forEach(item => {
                                            item = item.substr(0,2)+':'+ item.substr(2,4)
                                            if (item == arr[i].timer.split('-')[0] ) {
                                                Object.lockSettingList = 1
                                            }
                                        })
                                    }
                                }
                            }
                            obj.weekArray.push(Object)
                        }
                        arr2.push(obj)
                    }
                    pageData.tableData1 = arr2
                }
            }
        })
    }
    /**
     * 获取分组列表
     */
    function getGroupList() {
        sportRoomGroupList().then(res => {
            if (res.code == 0) {
                pageData.grouping = res.data;
                pageData.formInline.groupId = res.data[0].id
                getList(res.data[0].id)
                getFieldLock()
            }
        })
    }
    /**
     * 切换分组
     */
    function getField(val, type) {
        pageData.dialogShow ? pageData.dialogShowForm.roomIds = [] : pageData.formInline.roomIds = []
        pageData.grouping.forEach(item =>{
            if (item.id == val) {
                pageData.groupRow = item
                if (item.startTime && item.startTime.indexOf(':') == -1) {
                    pageData.groupRow.startTime = item.startTime.substr(0,2)+':'+item.startTime.substr(2,4)
                    pageData.groupRow.endTime = item.endTime.substr(0,2)+':'+item.endTime.substr(2,4)
                }
            }
        })
        getList(val)
        if (type == 1) {
            getFieldLock()
        }
    }
    function getFieldRoom(val){
        getFieldLock()
    }
    /**
     * 点击分组查看场地
     */
    function getList(id){
        let params = {
            groupId: id,
            purposeOrder: 1,
            limit: 9999,
            page: 1
        }
        sportRoomList(params).then(res => {
            if(res.code == 0){
                pageData.dialogShow ? pageData.formTableList  = res.data : pageData.tableList = res.data
            }
        })
    }
    /**
     * 拉起编辑弹框
     */
    function rouseDialog() {
        pageData.dialogShowForm = {
            groupId: '',
            roomIds: [],
            week: []
        }
        pageData.dialogVisible = true
    }
    /**
     * 取消关闭弹框
     */
    function handleClose() {
        // ruleFormRef.value.resetFields()
        pageData.dialogVisible = false
    }
    /**
     * 确定编辑
     */
    function confirm() {
        let params = {
            orderLimit: pageData.form.orderLimit,
            orderLimitNum: pageData.form.orderLimitNum ? Math.floor(pageData.form.orderLimitNum) : '',
            limitNumUnit: pageData.form.limitNumUnit,
            orderCancelLimit: pageData.form.orderCancelLimit,
            orderCancelNum: pageData.form.orderCancelLimit ? pageData.form.orderCancelNum : ''
        }
        params.encryptionId = encryptionId
        sportRoomSettingUpdate(params).then(res => {
            if (res.code == 0) {
                ElMessage.success('设置成功')
                getInfo()
                pageData.dialogVisible = false
            }
        })
    }
    /**
     * 星期切换
     */
    function activeVal(val) {
        pageData.active = val
        getFieldLock()
    }
    /**
     * 拉起限制弹框
     */
    function astrict(val) {
        pageData.title = val == 1 ? '限制' : '取消'
        pageData.dialogShowForm = {
            groupId: '',
            roomIds: [],
            week: [], // 限制周几的数据
        }
        pageData.dialogShow = true
    }
    /**
     * 关闭限制弹框
     */
    function dialogShowClose() {
        ruleFormRef.value.resetFields()
        pageData.dialogShow = false
    }
    /**
     * 提交限制表单
     */
    function dialogShowConfirm() {
        ruleFormRef.value.validate((valid) => {
            if (valid) {
                if (!pageData.dialogShowForm.startTime || !pageData.dialogShowForm.endTime) {
                    ElMessage.warning('请选择时间')
                    return
                }
                let params = {
                    weeks: pageData.dialogShowForm.week.join(','),
                    groupId: pageData.dialogShowForm.groupId,
                    roomIds: pageData.dialogShowForm.roomIds.length ? pageData.dialogShowForm.roomIds.join(',') : 0,
                    lockTimeInterval: pageData.dialogShowForm.startTime +'-'+pageData.dialogShowForm.endTime
                }
                
                let apiName = pageData.title == '限制' ? settingInsert : settingCancel
                apiName(params).then(res => {11
                    if (res.code == 0) {
                        ElMessage.success('操作成功')
                        getFieldLock()
                        pageData.dialogShow = false
                    }
                })
            } else {
              console.log('error submit!!');
              return false;
            }
        })
    }
    onMounted(() => {
        getGroupList()
        getInfo()
    })
</script>
<style type='css/style' scoped>
    .circle {
        width: 8px;
        height: 8px;
        border: 2px solid #CCC;
        border-radius: 50%;
    }
    .dialog_form {
        min-height: 280px;
    }
    .dialog_form /deep/ .el-form-item__content {
       display: inline;
    }
    .form_select{
        width:60px !important;
        margin: -10px -6px !important;
    }
    .form_select1{
        width:60px !important;
    }
    .dialog_form /deep/ .form_select .el-input__wrapper{
        background-color: #F2F6F9;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .dialog_form /deep/ .form_select1 .el-input__wrapper{
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0
    }
    ::v-deep .el-form-item__label {
        font-weight: 500;
    }
    ::v-deep .el-form--inline .el-form-item {
        margin-right: 16px;
        margin-bottom: 0;
    }
    .weekDiv {
        width: 100%;
        height: 48px;
        text-align: center;
        color: #666;
        font-weight: bold;
        line-height: 48px;
    }
    .active {
        color: #FFF;
        background: #00A3FF;
    }
    .box-left {
        position: absolute;
		left: 0px;
		top: 0;
		border-left:1px solid #E6E6E6;
		background-color: #fff;
		z-index: 200;
    }
    .tableout{
        border-top:1px solid #e5e5e5;
        border-right:1px solid #e5e5e5;
    }
    .headcell{
        border-right:1px solid #e5e5e5;
        border-bottom:1px solid #e5e5e5;
        text-align: center;
        background: #F4F7FB;
        line-height: 50px;
    }
    .bodycell{
        border-right:1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        text-align: center;
    }
    .img{
        top: 40%;
        transform: translateX(-50%);
    }
</style>