<template>
	<workArea :barlist="pageData.barlist">
	<div class="store_info bg-fff pt20 pb20 pl20 pr20">
		<div class="rx-sc mb12">
			<span class="mr16">门店名称</span>
			<div class="w200 mr16">
				<el-input type="text" v-model="pageData.storeName" placeholder="请输入门店名称" />
			</div>
			<el-button class="mr50" type="primary" :icon="Search" @click="searchTable">查询</el-button>
			<div class="">
				<el-checkbox-group v-model="pageData.storeGrade" @change="searchTable">
				    <el-checkbox label="1"><span class="color-666 fw400">直营店</span></el-checkbox>
				    <el-checkbox label="2"><span class="color-666 fw400">加盟店</span></el-checkbox>
				</el-checkbox-group>
			</div>
		</div>
		<div>
			<el-table :data="pageData.tableData" stripe class="mt20" style="width: 100%">
			    <el-table-column prop="storeName" label="门店名称" show-overflow-tooltip>
					<template #default="scope">
						<span v-if="scope.row.storeGrade != 1">{{scope.row.storeName}}</span>
						<a v-else href="javascript:;" class="color-409EFF tool_btn" @click="updateStoreInfo(scope.row)">{{scope.row.storeName}}</a>
					</template>
				</el-table-column>
			    <el-table-column label="门店地址" show-overflow-tooltip>
					<template #default="scope">
						<a href="javascript:;" class="color-409EFF tool_btn" @click="edit(scope.row)">{{ scope.row.provinceName ? scope.row.provinceName + '-' + scope.row.cityName + '-' + scope.row.areaName + '   ' + scope.row.storeAdd : '设置地址'}}</a>
					</template>
				</el-table-column>
			    <el-table-column label="店长" show-overflow-tooltip>
					<template #default="scope">
						{{ scope.row.shopowner ? scope.row.shopowner : '-' }}
					</template>
				</el-table-column>
			    <!-- <el-table-column prop="crossCourse" label="是否支持跨店约课" >
					<template #header>
						<div class="rx-sc">
							<span>是否支持跨店约课</span>
							<div>
								<el-tooltip
										class="box-item"
										effect="dark"
										placement="top-start"
									  >
									<template #content>
										<div style="max-width: 200px;">开启跨店约课功能的分店，可以设置本店的某课程能被其他分店的会员预约上课</div>
									</template>
									<div class="pt5">
										<unicon width="20px" name="question-circle" fill="#606266"></unicon>
									</div>
								</el-tooltip>
							</div>
						</div>
					</template>
					<template #default="scope">
						<el-switch v-model="scope.row.check" inline-prompt active-text="开启" inactive-text="关闭" @change="crossStore(scope.row.encryptionId,scope.row.check)"></el-switch>
					</template>
				</el-table-column> -->
			    <el-table-column label="注册时间">
					<template #default="scope">
						{{ scope.row.createTimeString ? scope.row.createTimeString : '-' }}
					</template>
				</el-table-column>
			    <el-table-column label="到期时间">
					<template #default="scope">
						{{ scope.row.deadTimesString ? scope.row.deadTimesString : '-' }}
					</template>
				</el-table-column>
			    <el-table-column label="绑定商户号">
					<template #default="scope">
						{{ scope.row.memberId ? scope.row.memberId : '-' }}
					</template>
				</el-table-column>
			</el-table>
			<div class="mt20 rx-ec" v-show="pageData.tableData.length > 0">
			    <el-pagination
			      v-model:currentPage="pageData.page"
			      :page-sizes="[15,20,30,50,100]"
			      :page-size="pageData.limit"
			      layout="total, sizes, prev, pager, next, jumper"
			      :total="pageData.count"
			      @size-change="pageSizeChange"
			      @current-change="searchTable"
			    >
			    </el-pagination>
			 </div>
		</div>
		<el-dialog
		    v-model="pageData.updateShow"
		    title="编辑门店"
			width="400px"
		    :before-close="()=>{pageData.updateShow = false}"
		    custom-class="popwechat"
		  >
		    <div class="pl20 pt40 pb40 pr20">
				<div class="rx-sc">
					<span class="mr16">分店名称</span>
					<div class="w200">
						<el-input type="text" v-model="pageData.updateStoreName" />
					</div>
				</div>
			</div>
		    <template #footer>
		    	<div class="dialog-footer">
		        	<el-button @click="pageData.updateShow = false">取消</el-button>
		        	<el-button type="primary" @click="updateStoreSave">确定</el-button>
		    	</div>
		    </template>
		</el-dialog>
	</div>
	</workArea>
	<el-dialog
	    v-model="pageData.dialogVisible"
	    :title="pageData.title"
	    width="600px"
	    :close-on-click-modal="false"
	    :close-on-press-escape="false"
	    :before-close="handleClose"
	  >
	    <div class="p20">
	        <el-form 
	          :model="ruleForm" 
	          :rules="rules"
	          ref="refruleForm" 
	          label-width="85px" 
	        >
	            <el-row>
	                <el-col>
	                    <el-form-item label="所在区域" prop="city">
	                        <div class="w220">
					        	<el-cascader
					        		class="ww100"
					        		v-model="ruleForm.city"
					        	    placeholder="请选择"
					        	    :options="pageData.allAddress"
					        	    clearable 
					        	/>
					        </div>
	                    </el-form-item>
	                </el-col>
	            </el-row>
	            <el-row>
	                <el-col>
	                    <el-form-item label="详细地址" prop="storeAdd">
	                      <el-input type="text" v-model="ruleForm.storeAdd" placeholder="请输入详细地址"></el-input>
	                    </el-form-item>
	                </el-col>
	            </el-row>
	            <el-row>
	                <el-col :span="12">
	                    <el-form-item label="经度" prop="longItude">
	                      <el-input type="Number" v-model="ruleForm.longItude" placeholder="请输入经度"></el-input>
	                    </el-form-item>
	                </el-col>
	                <el-col :span="12">
	                    <el-form-item label="纬度" prop="latItude">
	                      <el-input type="Number" v-model="ruleForm.latItude" placeholder="请输入纬度"></el-input>
	                    </el-form-item>
	                </el-col>
	            </el-row>
	            <el-row>
	                <el-col class="ml90 mb10 rx-c">
						<el-link class="t_d_u" href="https://lbs.qq.com/getPoint/" :underline="false" type="primary" target="_blank" @click="getCoordinate">获取坐标</el-link>
	                    <div class="rx-c" v-show="pageData.coordinateShow">
	                        <el-input class="mr20" v-model="pageData.coordinate" placeholder="请将复制的坐标粘贴到此处" />
	                        <el-button type="primary" @click="coordinateSubmit">确认</el-button>
	                    </div>
	                    
	                </el-col>
	                <el-col>
	                    <el-form-item>
	                      <span class="color-999 ft12">教程：点击获取坐标，打开网后输入店铺详细地址，选中地图上正确的位置后将出现一组数字（纬度，经度），即为坐标，可双击鼠标复制并粘贴到此处对应的输入框。</span>
	                    </el-form-item>
	                </el-col>
	            </el-row>
	        </el-form>
	    </div>
	    <template #footer>
	      <span class="dialog-footer">
	        <el-button @click="handleClose">取 消</el-button>
	        <el-button type="primary" @click="onSubmit">确 定</el-button>
	      </span>
	    </template>
	</el-dialog>
</template>

<script setup>
	import { ref, reactive } from 'vue'
	import { ElMessage} from 'element-plus'
	import { getBusinessStores, updateStore, lineStoreEdit } from '@/api/store.js'
	import { storeAddAddr, storeUpdAddr } from '@/api/storeCenter.js';
	import { getAllAddress } from '@/api/applet.js';
	import workArea from '@/components/branch-store/workArea.vue';
	import { Search } from '@element-plus/icons-vue'
	import {getLocalStorage} from '@/common/common.js';
	const pageData = reactive({
		barlist:[{ name : '首页' },{ name : '门店管理' },{ name : '门店信息' }],
		tableData:[],
		count:0,
		page: 1,
		limit: 15,
		storeName: '',
		storeGrade: ['1','2'],
		updateShow:false,
		updateStoreName:'',
		allAddress:[],
		title: '',  // 弹框标题
		dialogVisible: false, // 弹框显隐
	})
	getList();
	function searchTable(){
		pageData.page = 1;
		getList();
	}
	function pageSizeChange(limit){
		pageData.limit = limit;
		searchTable();
	}
	function getList(){
		getBusinessStores({
			page:pageData.page,
			limit:pageData.limit,
			storeName:pageData.storeName,
			storeGrade:pageData.storeGrade.join(',')
		}).then((res)=>{
			if(res.code == 0){
				pageData.count = res.count;
				pageData.tableData = res.data.map((n)=>{
					n.check = n.crossCourse == 1 ? true : false;
					return n;
				});
			}
		})
	}
	//是否跨店
	function crossStore(id,check){
		updateStore({
			encryptionId: id,
			crossCourse: check ? 1 : 0
		}).then((res)=>{
			if(res.code == 0){
				ElMessage({
					type: 'success',
					message: (check ? '开启':'关闭')+'成功',
				})
				searchTable();
			}
		})
	}
	//编辑
	var storeId = '';
	function updateStoreInfo(row){
		storeId = row.id;
		pageData.encryptionId = row.encryptionId;
		pageData.updateStoreName = row.storeName;
		pageData.updateShow = true;
	}
	const emit = defineEmits(['update']); //home.vue 方法
	function updateStoreSave(){
		lineStoreEdit({
			encryptionId: pageData.encryptionId,
			storeName: pageData.updateStoreName
		}).then((res)=>{
			if(res.code == 0){
				pageData.updateShow = false;
				ElMessage({
					type: 'success',
					message: '编辑成功',
				})
				searchTable();
				// 更新门店信息
				var businessNowStoreData = getLocalStorage('businessNowStoreData');
				var businessLoginData = getLocalStorage('businessLoginData');
				businessNowStoreData.sysUserStoreList.map((z,i)=>{
					if(z.id == storeId){
						z.storeName = pageData.updateStoreName;
					}
				})
				businessLoginData.map((n)=>{
					n.sysUserStoreList.map((z)=>{
						if(z.id == storeId){
							z.storeName = pageData.updateStoreName;
						}
					})
				})
				emit('update',{name:pageData.updateStoreName,id:storeId});
				localStorage.setItem('businessNowStoreData',JSON.stringify(businessNowStoreData));
				localStorage.setItem('businessLoginData',JSON.stringify(businessLoginData));
			}
		})
	}
	
	//设置地址
	const  refruleForm=ref();
	const ruleForm = reactive({
	    provinceId: '',
	    cityId: '',
	    areaId: '',
	    storeAdd: '',
	    longItude: '',
	    latItude: '',
	    city:[]
	});
	const rules = {
	    city: [{ required: true, message: '请选择所在区域', trigger: 'change' }],
	    storeAdd: [{ required: true, message: "请输入详细地址", trigger: "blur" }],
	    // businessPhone: [{ validator: checkPhone, trigger: "blur" }],
	    longItude: [{ required: true, message: "请输入经度", trigger: "blur" }],
	    latItude: [{ required: true, message: "请输入纬度", trigger: "blur" }]
	};
	/**
	 * @param (Obj) row 机构地址信息
	 * 编辑地址
	 */
	
	const edit=(row)=> {
		getAddress()
	    pageData.title = '设置门店地址'
	    ruleForm.encryptionId = row.encryptionId
		ruleForm.sid = row.id
	    ruleForm.storeAdd = row.storeAdd
	    ruleForm.longItude = row.longitude
	    ruleForm.latItude = row.latitude
	    ruleForm.city = [row.provinceId, row.cityId, row.areaId]
	    pageData.dialogVisible = true
	}
	/**
	 * 取消关闭弹框
	 */
	const handleClose=() => {
	    refruleForm.value.resetFields()
	    pageData.dialogVisible = false
	}
	const getParams=() => {
	    let params = {
	        provinceId: ruleForm.city[0],
            cityId: ruleForm.city[1],
            areaId: ruleForm.city[2],
            storeAdd: ruleForm.storeAdd,
            encryptionId: ruleForm.encryptionId,
			sid: ruleForm.sid,
            longItude: ruleForm.longItude,
            latItude: ruleForm.latIude,
	    }
	    return params
	}
	const onSubmit=() => {
	    refruleForm.value.validate((valid) => {
	        if (valid) {
	            let params = getParams()
	            storeUpdAddr(params).then((res) => {
	                if (res.code == 0) {
	                   getList()
	                   ElMessage.success('设置成功')
	                }
	            })
	        refruleForm.value.resetFields()
	        pageData.dialogVisible = false
	        } else {
	          console.log('error submit!!');
	          return false;
	        }
	    });
	}
	const getCoordinate=() => {
	    pageData.coordinate = ''
	    pageData.coordinateShow = true
	}
	/**
	 * 提交复制的坐标
	 */
	const coordinateSubmit=()=> {
	    if (pageData.coordinate) {
	        let arry = pageData.coordinate.split(',')
	        ruleForm.longItude = arry[1]
	        ruleForm.latItude = arry[0]
	        
	    }
	    pageData.coordinateShow = false
	}
	// 获取地区数据
	const getAddress=() => {
	    getAllAddress().then((res) => {
	        if(res.code == 0){
		    	pageData.allAddress = res.data;
		    }
	    })
	}
</script>

<style lang="stylus" scoped>
	.el-row /deep/ input::-webkit-outer-spin-button,.el-row /deep/ input::-webkit-inner-spin-button{
	  -webkit-appearance: none !important;
	  -moz-appearance: none !important;
	  -o-appearance: none !important;
	  -ms-appearance: none !important;
	  appearance: none !important;
	  margin: 0;
	}
	.el-row /deep/ input[type="number"]{
	  -webkit-appearance:textfield !important;
	  -moz-appearance:textfield !important;
	  -o-appearance:textfield !important;
	  -ms-appearance:textfield !important;
	  appearance:textfield !important;
	}
</style>
