<template>
    <div class="pt20 pb20" v-loading="pageData.loading">
        <div class="nav rx-bc">
            <div>
                <span class="d_i_b c_p" :class="pageData.tabNum == item.val ? 'active': '' " v-for="(item, index) in pageData.tabeList" :key="index" @click="getContentList(item.val)">{{ item.label }}</span>
            </div>
            <div>
                <span class="color-999 ft12">温馨提示：当前页面数据仅统计截止至当前时间</span>
                <a href="javascript:;" class="t_d_u color-409EFF tool_btn ft12 mr20" @click="formula">计算公式</a>
            </div>
        </div>
        <MemberAnalyse v-if="pageData.tabNum == '10'"></MemberAnalyse>
        <SignInSurvey v-if="pageData.tabNum == '11'"></SignInSurvey>
        <Formula v-model="pageData.formulaShow" :type="pageData.tabNum"></Formula>
    </div>
</template>

<script setup>
    import { reactive, onMounted, onBeforeUnmount } from 'vue';
    import MemberAnalyse from '@/views/branch-store/datacenter/workData/memberAnalyse/memberSurvey.vue' // 会员概况
    import SignInSurvey from '@/views/branch-store/datacenter/workData/memberAnalyse/signInSurvey.vue' // 出勤统计
    import Formula from '@/components/branch-store/datacenter/formula.vue';
    const pageData = reactive({
        loading: false,
        formulaShow: false,
        tabNum: '10',
        tabeList: [{
            label: '会员概况',
            val: '10'
        }, {
            label: '签到统计',
            val: '11'
        }
        // ,{
        //     label: '消费统计',
        //     val: '12'
        // }, {
        //     label: '过期统计',
        //     val:'13'
        // }, {
        //     label: '未消统计',
        //     val: '14'
        // }
        ], // 头部tabe数组（单选伪装）
    })
    /**
     * tabe数组切换
     */
    function getContentList(val) { 
        pageData.tabNum = val
        localStorage.setItem('memberAnalyse', val)
    }
    /**
     * 计算公式
     */
    function formula() {
        pageData.formulaShow = true
    }
    onMounted(()=> {
      if (localStorage.getItem('memberAnalyse') != null) {
          pageData.tabNum = localStorage.getItem('memberAnalyse')
	    }
	})
    onBeforeUnmount(() => {
		localStorage.removeItem('memberAnalyse')
	})
</script>
<style type='css/style' scoped>
    .nav {
        background: #F2F2F2
    }
    .nav span{
        padding: 7px 20px 8px 18px;
        color: #666;
    }
    .nav .active {
        background: #03A3FF;
        color: #FFF;
        border-radius: 2px;
    }
</style>