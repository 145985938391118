<template>
	<workArea :barlist="pageData.barlist">
		<div class="card_manage bg-fff pl16 pt10 pb16 pr16">
			<el-tabs v-model="pageData.tabNum" @tab-click="handleClick">
				<el-tab-pane label="收银明细" name="0" v-if="utils.getMenuRole(32200)"></el-tab-pane>
				<el-tab-pane label="收支统计" name="1" v-if="utils.getMenuRole(32201)"></el-tab-pane>
			</el-tabs>
			<component :is="components[pageData.tabNum]" :tab="pageData.tabNum"></component>
		</div>
	</workArea>
</template>
<script>
  export default {
    name: 'BranchBill'
  }
</script>
<script setup>
	import { ref, reactive, onMounted, onBeforeUnmount } from 'vue';
	import {useRouter} from 'vue-router';
	import workArea from '@/components/branch-store/workArea.vue';
	import BillList from '@/views/branch-store/finance/waterflow/billList.vue'; // 收支明细
	import BillStatistics from '@/views/branch-store/finance/waterflow/billStatistics.vue'; //收支统计
	import utils from '@/common/utils.js';
	const router = useRouter();
	const components = [BillList,BillStatistics];
	const pageData = reactive({
		tabNum:'0',
		barlist:[{ name : '首页' },{ name : '财务中心' }, {name:'收银记账'}]
	})
	var menuId = [32200,32201]; //会员有权限tab默认项
	for(var i = 0;i < menuId.length;i++){
		if(utils.getMenuRole(menuId[i])){
			pageData.tabNum = i.toString();
			break;
		}
	}
	function handleClick(tab){
		localStorage.setItem('waterflow', tab.props.name)
	}
	onMounted(()=> {
      if (localStorage.getItem('waterflow') != null) {
          pageData.tabNum = localStorage.getItem('waterflow')
	    }
	})
    onBeforeUnmount(() => {
		localStorage.removeItem('waterflow')
	})
</script>

<style scoped lang="stylus">
	
</style>
