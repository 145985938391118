<template>
    <workArea :barlist="barlist">
        <div class="p20 pt10">
            <h3 class="t_a_c">{{ pageData.title }}</h3>
            <el-divider class="mt15 mb15"></el-divider> 
            <div v-html="pageData.content"></div>
        </div>
    </workArea>
</template>

<script setup>
    import { reactive } from 'vue';
    import { useRouter } from "vue-router";
    import { useStore } from 'vuex'
    import workArea from '@/components/branch-store/workArea.vue';
    import formQuery from '@/components/branch-store/formQuery.vue';
    import utils from '@/common/utils.js';
    import { detail } from '@/api/reception.js'
    const barlist = reactive([{ name : '返回',type:0 },{ name : '通知公告' },{ name : '通知公告详情' }]); // 面包屑
	const store = useStore();
    const router = useRouter();
    const id = router.currentRoute.value.query.encryptionId;
    const pageData = reactive({
        title: '更新 || 预付款锁定生源！欠费管理已上线！',
        content: ''
    })
    detail({ id: id }).then((res) => {
        if (res.code == 0) {
            pageData.title = res.data.title,
            pageData.content = res.data.content
        }
    })
</script>