<template>
    <workArea :barlist="barlist">
        <div class="p20 pt10">
            <formQuery ref="queryOptsRef" :queryOpts="pageData.queryOpts" @query="getTableData"></formQuery>
            <el-table :data="pageData.tableData" stripe v-loading="pageData.loading">
                <el-table-column label="公告标题">
                    <template #default="scope">
                        <span @click="toMemberDetail(scope.row.id)" class="color-409EFF c_p tool_btn">{{scope.row.title}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="发布时间">
                    <template #default="scope">
                        {{ utils.getFormatDate2(scope.row.createTime) }}
                    </template>
                </el-table-column>
                <el-table-column label="是否已读" >
                    <template #default="scope">
                        {{ scope.row.isRead ? '已读' : '未读' }}
                    </template>
                </el-table-column>
                <template #empty>
                    <el-empty description="暂无数据"></el-empty>
                </template>
            </el-table>
            <div class="mt20 rx-ec" v-show="pageData.tableData.length > 0">
		        <el-pagination
		            v-model:currentPage="pageData.searchForm.page"
		            :page-sizes="[15,20,30,50,100]"
		            :page-size="pageData.searchForm.limit"
		            layout="total, sizes, prev, pager, next, jumper"
		            :total="pageData.totalCount"
		            @size-change="pageSizeChange"
		            @current-change="getList"
		        />
		    </div>
        </div>
    </workArea>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import { useStore } from 'vuex'
    import { useRouter, onBeforeRouteLeave } from 'vue-router';
    import workArea from '@/components/branch-store/workArea.vue';
    import formQuery from '@/components/branch-store/formQuery.vue';
    import utils from '@/common/utils.js';
    import { systemNotice } from '@/api/reception.js';
    const barlist = reactive([{ name : '返回',type:0 },{ name : '通知公告' }]); // 面包屑
    const router = useRouter();
	const store = useStore();
    const queryOptsRef = ref()
    const pageData = reactive({
        loading: false,
        tableData: [],
        searchForm: {
            page: 1,
            limit: 15
        },
        totalCount: 0,
        queryOpts:{
			fields:[{
				type : 'input',
				fieldName : 'searchText',
				placehold : '请输入公告标题'
			}]
		}
    })
    /**
     * 搜索
     */
    function getTableData(e){
        pageData.searchForm.page = 1
        getList()
    }
    /**
     * 更改每页条数
     */
    function pageSizeChange(limit){
		pageData.searchForm.limit = limit
		getList()
	}
    /**
     * 获取公告列表
     */
    function getList() {
        pageData.loading = true
		let params = {}
		Object.assign(params, queryOptsRef.value.getQueryForm())
		params.limit = pageData.searchForm.limit
		params.page = pageData.searchForm.page
        systemNotice().then((res)=> {
            pageData.loading = false
            if (res.code == 0) {
                pageData.tableData = res.data
                pageData.totalCount = res.count
            }
        }).catch(() => {
            pageData.loading = false
        })
    }
    /**
     * 跳转公告详情
     */
    function toMemberDetail(id){
        router.push('/branch/noticeDetail?encryptionId='+ id);
    }
    onMounted(() => {
        getList()
    })
    // 页面缓存
     onBeforeRouteLeave((to, from)=>{
         if (to.path === '/branch/noticeDetail') {
             store.commit('SetKeep',{keepLiveRoute : ['IntentionList']});
         } else {
             store.commit('SetKeep',{keepLiveRoute : []});
         }
     })
</script>
<style type='css/style' scoped>

</style>