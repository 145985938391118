<template>
    <div class="mt16">
        <el-table v-loading="loading" stripe :data="pageData.tableData">
            <el-table-column prop="storeName" label="入场门店" />
            <el-table-column prop="enterSiteTime" label="入场时间"></el-table-column>
            <el-table-column label="离场时间">
                <template #default="scope">
                    {{ scope.row.state ==1 ? scope.row.outSiteTime : '-' }}
                </template>
            </el-table-column>
            <template #empty>
                <el-empty description="暂无数据"></el-empty>
            </template>
        </el-table>
        <div class="mt20 rx-ec" v-show="pageData.tableData.length > 0">
		    <el-pagination
		        v-model:currentPage="pageData.searchForm.page"
		        :page-sizes="[15,20,30,50,100]"
		        :page-size="pageData.searchForm.limit"
		        layout="total, sizes, prev, pager, next, jumper"
		        :total="pageData.totalCount"
		        @size-change="changeSize"
		        @current-change="()=>{getList()}"
		    />
		</div>
    </div>
</template>

<script setup>
    import { reactive } from 'vue';
    import { enterOutSite } from '@/api/intentionList.js';
    const propData = defineProps({
		info: Object
	})
    const pageData = reactive({
        loading: false,
        tableData: [],
        totalCount: 0,
        searchForm: {
            page: 1,
            limit: 15
        }
    })
    /**
     * 获取入场记录
     */
    getList()
    function getList() {
        let params = {
            encryptionCardMyId: propData.info.encryptionId,
            page: pageData.searchForm.page,
            limit: pageData.searchForm.limit
        }
        enterOutSite(params).then((res) => {
            if (res.code == 0) {
                pageData.tableData = res.data
                pageData.totalCount = res.count
            }
        })
    }
    /**
     * 更改每页条数
     */
    function changeSize(val) {
        pageData.searchForm.limit = val
    }
</script>
<style type='css/style' scoped>

</style>
