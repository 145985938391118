<template>
    <div class="pl20 pt16 pr20 rx-bc">
        <div class="mr20 d_i_b t_a_c ww100 h90 tab"
            v-for="(item, index) in pageData.tabaList"
            :key="index"
            :class="{active : pageData.active == item.name}"
            @click="selected(item.name)"
        >
            <div class="mt20 mb10">
                <span class="v_a_m">{{ item.name }}</span>
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  :content="item.content"
                  placement="top"
                >
                  <img class="w15 h15 ml5 v_a_m" src="@/assets/wenhao.png" >
                </el-tooltip>
            </div>
            <div class="ft12 color-999">
                今日消费金额
                <b class="color-red">{{ item.value }}元</b>
            </div>
            <div class="triangle" v-if="pageData.active == item.name"></div>
        </div>
        
    </div>
    <Order :operator="updateByOptions" v-if="pageData.active == '预约课程消费' && !pageData.undoData" @undoData="undoData"/>
    <Admission :operator="updateByOptions" v-if="pageData.active == '入场消费'" />
    <Automatic :operator="updateByOptions" v-if="pageData.active == '期限卡按天自动消费'" />
    <Room :operator="updateByOptions" v-if="pageData.active == '场地预约消费'" />
    <UndoData v-if="pageData.active == '预约课程消费' && pageData.undoData" />
    <!-- <Manual :operator="updateByOptions" v-if="pageData.active == '手动消费'" /> -->

</template>

<script setup>
    import { reactive, onMounted, onBeforeUnmount } from 'vue';
    import Order from './consumeRecord/order.vue'; // 预约消费
    import Admission from './consumeRecord/admission.vue'; // 入场消费
    import Automatic from './consumeRecord/automatic.vue'; // 自动消费
    import Room from './consumeRecord/room.vue'; // 场地预约消费
    import Manual from './consumeRecord/manual.vue'; // 手动消费
    import UndoData from './consumeRecord/undoData.vue'; // 手动消费
    
    import {  getStoreAccountStaffSimple } from '@/api/staff.js'
    import { summary } from '@/api/consume.js';
    
    const pageData = reactive({
        undoData: false,
        active: '预约课程消费',
        tabaList: [{
          name: '预约课程消费',
          content: '即会员使用卡/课/单次微信付费预约课程时所消费的明细汇总',
          value: 0
        },{
          name: '入场消费',
          content: '即会员使用入场卡次卡进场锻炼的消费明细',
          value: 0
        },{
          name: '期限卡按天自动消费',
          content: '即会员全部期限卡类型，按照日单价每晚24点自动扣费',
          value: 0
        },{
          name: '场地预约消费',
          content: '即会员预约场地的消费明细',
          value: 0
        },
        // {
        //   name: '手动消费',
        //   content: '即手动扣除会员资产的明细',
        //   value: 0
        // }
        ]
    })
    const updateByOptions = reactive([]);

    function undoData(val) {
        pageData.undoData = val
    }
    /**
     * tab切换
     */
    const selected=(name) => {
        pageData.active = name;
        pageData.undoData = false;
        localStorage.setItem('consumeRecord', name)
    }
    /**
     * 操作员
     */
    function getUpdateByOptions() {
        let params = {
            page:1,
            limit:1000,
            delFlag: 1
        }
        getStoreAccountStaffSimple(params).then((res) => {
           if (res.code == 0) {
                for(var i=0;i< res.data.length;i++){
                    let params = {}
                    params.label = res.data[i].realName
                    params.value = res.data[i].id
                    updateByOptions.push(params)
                }
            }
        })
    }
    /**
     * 获取顶部统计
     */
    function getInfo() {
        let params = {
            timeIn: 1
        }
        for (let i = 0; i < 4; i++) {
            params.consumeType = i+1
            summary(params).then((res) => {
                if (res.code == 0) {
                    pageData.tabaList[i].value = res.data.consumeMoneyAll
                }
            })
        }
        
    }
    onMounted(()=> {
        getUpdateByOptions()
        getInfo()
        if (localStorage.getItem('consumeRecord') != null) {
            pageData.active = localStorage.getItem('consumeRecord')
	      }
	  })
    onBeforeUnmount(() => {
	  	  localStorage.removeItem('consumeRecord')
	  })
</script>

<style type="css/style" scoped>
    .tab {
        background: #F7F6FB;
        position: relative;
        cursor: pointer;
    }
    .active {
      background: rgba(64, 158, 255, 0.1);
    }
    .active::after{
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				border-bottom: 4px solid #00A3FF;
			}
    .active::before{
				content: '';
				width: 0;
				height: 0;
				border: 5px solid transparent;
				border-top-color: #00A3FF;
				position: absolute;
				bottom: -10px;
				left: 50%;
				transform: translateX(-50%);
			}
</style>