<template>
	<workArea :barlist="pageData.barlist">
		<div class="card_manage bg-fff pl16 pt10 pr16">
			<el-tabs v-model="pageData.tabNum" @tab-click="handleClick">
				<el-tab-pane label="潜在会员" name="0" v-if="utils.getMenuRole(31400)"></el-tab-pane>
				<el-tab-pane label="公海池" name="1" v-if="utils.getMenuRole(31401)"></el-tab-pane>
				<el-tab-pane label="跟进提醒" name="2" v-if="utils.getMenuRole(31402)"></el-tab-pane>
				<el-tab-pane label="跟进记录" name="3" v-if="utils.getMenuRole(31403)"></el-tab-pane>
			</el-tabs>
			<component :is="components[pageData.tabNum]" :tab="pageData.tabNum"></component>
		</div>
	</workArea>
</template>
<script>
  export default {
    name: 'IntentionList'
  }
</script>
<script setup>
	import { reactive, onMounted, onBeforeUnmount } from 'vue';
	import {useRouter} from 'vue-router';
	import workArea from '@/components/branch-store/workArea.vue';
	import PotentialMember from '@/views/branch-store/member/intentionList/potentialMember.vue'; //潜在会员
	import HighSeasSool from '@/views/branch-store/member/intentionList/highSeasSool.vue'; //公海池
	import FollowRemind from '@/views/branch-store/member/intentionList/followRemind.vue'; //跟进提醒
	import FollowRecord from '@/views/branch-store/member/intentionList/followRecord.vue';//跟进记录
	import utils from '@/common/utils.js';
	const router = useRouter();
	const components = [PotentialMember,HighSeasSool,FollowRemind,FollowRecord];
	const pageData = reactive({
		tabNum:'0',
		barlist:[{ name : '首页' },{ name : '会员管理' },{ name : '潜客管理' }]// 面包屑
	})
	var menuId = [31400,31401,31402,31403]; //会员有权限tab默认项
	for(var i = 0;i < menuId.length;i++){
		if(utils.getMenuRole(menuId[i])){
			pageData.tabNum = i.toString();
			break;
		}
	}  
	function handleClick(tab) {
      localStorage.setItem('intentionList', tab.props.name)
	}
	onMounted(()=> {
        if (localStorage.getItem('intentionList') != null) {
            pageData.tabNum = localStorage.getItem('intentionList')
	    }
	})
    onBeforeUnmount(() => {
		localStorage.removeItem('intentionList')
	})
</script>

<style scoped lang="stylus">
	
</style>
