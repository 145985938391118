<template>
	<div v-loading="loading">
	    <formQuery :queryOpts="queryStatisticsOpts" @query="getStatisticsList"></formQuery>
		<div id="category" class="h400"></div>
		<div class="rx-bs">
			<div class="mr40 flex1">
				<h3 class="mb12" style="border-bottom: 1px solid #ccc;">{{ pageData.incomeText }}类型</h3>
				<div id="incomeType" class="h400" style="transform: translateX(-10%);"></div>
			</div>
			<div class="flex1">
				<h3 class="mb12" style="border-bottom: 1px solid #ccc;">{{ pageData.incomeText }}排名</h3>
				<div class="p16">
					<el-table :data="pageData.tableData" stripe style="width: 100%" height="400">
						<el-table-column type="index" label="排名" align="center" width="50"/>
					    <el-table-column label="类别" width="110" show-overflow-tooltip >
							<template #default="scope">
								<span @click="getDetail(scope.row.typeId)" class="color-409EFF c_p tool_btn">{{scope.row.title}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="amount" align="right" label="金额" min-width="100" />
					    <el-table-column label="占比" align="right" min-width="150">
							<template #default="scope">
								<span>{{scope.row.rate}}%</span>
							</template>
						</el-table-column>
						<template #empty>
                            <el-empty description="暂无数据"></el-empty>
                        </template>
					</el-table>
				</div>
			</div>
		</div>
		<el-dialog
		    v-model="pageData.categoryShow"
		    title="收入明细"
		 	width="800px"
		    :before-close="()=>{pageData.categoryShow = false}"
		    custom-class="popwechat"
		>
			<div class="p20">
				<el-table :data="pageData.categoryData" stripe style="width: 100%">
				    <el-table-column prop="title" label="收支类别" width="110" show-overflow-tooltip />
				    <el-table-column prop="productName" label="商品" />
				    <el-table-column prop="productTyp" label="类别">
						<template #default="scope">
							{{ scope.row.type ? '收入' : '支出' }}
						</template>
					</el-table-column>
				    <el-table-column prop="amount" label="金额" />
				    <el-table-column prop="happenTime" label="时间" />
				</el-table>
		  </div>
		</el-dialog>
	</div>
</template>

<script setup>
    import {ref,reactive,onMounted,onBeforeUnmount} from 'vue';
	import * as echarts from 'echarts';
    import formQuery from '@/components/branch-store/formQuery.vue';
    import utils from '@/common/utils.js';
	import { chart, pieDetail } from '@/api/finance.js';
	let category = null
    let incomeType = null
    const queryStatisticsOpts = reactive({}); // 训练目的
    const pageData = reactive({
		loading: false,
		incomeText: '收入',
       	tableData:[],
	   	tableCount:0,
	   	tablePage:1,
	   	categoryShow:false,
	   	categoryData:[],
	   	column: {
		    tooltip: {
			    trigger: 'axis',
			    axisPointer: {
			      type: 'shadow'
			    }
			},
            xAxis: {
	    		type: 'category',
	    		data: [],
				axisTick: {
                  alignWithLabel: true
                },
            	axisLabel: {
            	    interval: 6,
            	    rotate: 35
            	}
	    	},
	    	yAxis: {
	    	    type: 'value'
	    	},
	    	series: [{
	    	  	data: [],
				barWidth: 30,
	    	  	type: 'bar',
				name: '金额'
	    	}]
	   	}, // 柱状图
	   	pieChart: {
			title:{
				text:'总额',
				itemGap:15,
				left:'center',
				top:'center',
				textStyle:{
					color:'#999',
					fontSize:14,
				},
				subtext:'￥1234',
				subtextStyle:{
					color:'#000',
					fontSize:24,
				}
			},
			tooltip: {
				trigger: 'item'
			},
			legend: {
				right: 'right',
				y: 'center',
				orient:'horizontal',
				selectedMode: false
			},
			series: [{
			 	name: '类型占比',
			 	type: 'pie',
			 	radius: ['40%', '70%'],
			 	avoidLabelOverlap: false,
			 	itemStyle: {
					borderRadius: 10,
					borderColor: '#fff',
					borderWidth: 2
			 	},
			 	label: {
					show: false,
					position: 'center'
			 	},
			 	labelLine: {
					show: false
			 	},
			 	data: []
			}]   
		}, // 饼图
    });
	const form = ref({})
	queryStatisticsOpts['fields'] = [{
	    type : 'select',
	    fieldName : 'type',
	    placehold : '全部类别',
	    value : "1",
	    options : utils.getDict('waterKind')
	},{
	    type : 'select',
	    fieldName : 'timeType',
	    placehold : '',
	    value : 1,
	    options : [{ label:"指定年", value: 1 }, { label: "指定月", value: 2 }]
	},{
	    type : 'select',
	    fieldName : 'year',
	    placehold : '',
	    value : new Date().getFullYear(),
	    options : (function(){
	        var years = [];
	        for(var i=2015;i<2031;i++){
	            years.push({label : i+'年',value:i});
	        }
	        return years;
	    })()
	},{
	    type : 'select',
	    fieldName : 'month',
	    showField : 'timeType', // 根据显示字段
	    showValue : 2, // 根据显示字段值
	    placehold : '',
	    value : (new Date().getMonth() + 1),
	    options : (function(){
	        var months = [];
	        for(var i=1;i<13;i++){
	            months.push({label : i+'月',value:i});
	        }
	        return months;
	    })()
	},{
	    type : 'select',
	    fieldName : 'sourceFrom',
	    placehold : '',
	    value : '',
	    options : [{ label: "全部渠道", value: '' }, { label:"线上", value: 0 }, { label: "线下", value: 1 }]
	}];
	
	//筛选项回调
	function getStatisticsList(e){
		form.value = Object.assign({}, e)
		pageData.incomeText = e.type == 1 ? '收入': '支出'
		getCategory(e)
	}
	
	//获取类别明细
	function getDetail(id){
		pageData.categoryShow = true
		form.value.typeId = id
		pieDetail(form.value).then((res) => {
			if (res.code == 0) {
				pageData.categoryData = res.data;
			}
		})
	}
	
	//统计图标
	function getCategory(data){
		category = echarts.init(document.getElementById('category'))
		incomeType = echarts.init(document.getElementById('incomeType'));
		pageData.loading = true
		let params = {
            sourceFrom: data.sourceFrom,
            timeType: data.timeType,
            type: data.type,
            year: data.year,
		}
		if (data.timeType == 2) {
           params.month = data.month
		}
		chart(params).then((res) => {
			pageData.loading = false
			if (res.code == 0) {
				pageData.tableData = res.remark;
				pageData.tableCount = res.count;
				pageData.column.series[0].data = []
				pageData.column.xAxis.data = []
				res.data&&res.data.forEach(item => {
					pageData.column.xAxis.data.push(item.time)
					pageData.column.series[0].data.push(item.amount)
				})
				pageData.pieChart.title.subtext = `￥${res.remark2 && res.remark2.amountAll ? res.remark2.amountAll : 0 }`
				pageData.pieChart.title.text = `${ pageData.incomeText }总额`
				pageData.pieChart.series[0].name = `${ pageData.incomeText }类型占比`
				pageData.pieChart.series[0].data = []
				res.remark && res.remark.forEach(item => {
					let obj ={ value: item.amount, name: item.title }
					pageData.pieChart.series[0].data.push(obj)
				})
				
		        category.setOption(pageData.column, true);
				incomeType.setOption(pageData.pieChart, true);
				window.addEventListener("resize",function(){
		           category.resize();
		           incomeType.resize();
               });
			}
		}).catch(() => {
			pageData.loading = false
		})
	}
	onMounted(()=>{
		let params = {
            sourceFrom: "",
            timeType: 1,
            type: "1",
            year: 2022,
		}
		form.value = Object.assign({}, params)
		getCategory(params)
	})
	onBeforeUnmount(() => {
      if (!category || !incomeType) {
        return
      }
      category.dispose()
      category = null
	  incomeType.dispose()
      incomeType = null
    })
</script>

<style>
</style>
