<template>
	<div class="pt20 pb20">
		<div class="rx-bc bg-f2f2f2 h35">
			<div class="rx-sc">
				<div class="w95 h35 rx-cc color-666 c_p bdr2" :class="{'color-fff bg-03A3FF':tab == 1}" @click="tabClick(1)">
					团体课约课</div>
				<div class="w95 h35 rx-cc color-666 c_p bdr2" :class="{'color-fff bg-03A3FF':tab == 2}" @click="tabClick(2)">
					私教课约课</div>
				<div class="w95 h35 rx-cc color-666 c_p bdr2" :class="{'color-fff bg-03A3FF':tab == 3}" @click="tabClick(3)">
					私教存课</div>
			</div>
			<div class="rx-cc mr20" v-show="tab != 1">
				<span class="color-999 mr20 ft12">温馨提示：当前页面数据仅统计截止至当前时间</span>
				<span class="color-main c_p t_d_u ft12" @click="formulaClick">计算公式</span>
			</div>
		</div>
		<div class="rx-bc mt15" v-show="tab == 1">
			<div class="rx-cc">
				<span class="ft16 color-666 c_p" :class="{typeActive:typeTab == 1}" @click="typeTabClick(1)">日期</span>
				<span class="ml15 mr15">|</span>
				<span class="ft16 color-666 c_p" :class="{typeActive:typeTab == 2}" @click="typeTabClick(2)">课程</span>
			</div>
			<div class="rx-cc">
				<span class="color-999 mr20 ft12">温馨提示：当前页面数据仅统计截止至当前时间</span>
				<span class="color-main c_p t_d_u ft12" @click="formulaClick">计算公式</span>
			</div>
		</div>
		<div class="rx-bc mt30">
			<div class="rx-sc">
				<div class="w210 mr12" v-show="tab == 1">
					<el-select v-model="searchQuery.courseNameIds" multiple collapse-tags filterable
						placeholder="全部团体课">
						<el-option v-for="item in searchQueryList.teamCourseList" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</div>
				<div class="w210 mr12" v-show="tab == 2">
					<el-select v-model="searchQuery.teacherIds" multiple collapse-tags filterable placeholder="全部教练">
						<el-option v-for="item in searchQueryList.coachList" :key="item.id" :label="item.realName"
							:value="item.id" />
					</el-select>
				</div>
				<div class="w210 mr12" v-show="tab != 3">
					<el-select v-model="searchQuery.timeIn" placeholder="日期类型">
						<el-option v-for="item in searchQueryList.timeTypeList" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</div>
				<div class="w210 mr12" v-show="tab != 3 && searchQuery.timeIn == -1">
					<el-date-picker style="width: 210px; height: 32px" format="YYYY-MM-DD" value-format="YYYY-MM-DD"
						v-model="searchQuery.searchTime" type="daterange" start-placeholder="开始日期"
						end-placeholder="结束日期" :disabled-date="datePickerDisabled.disabledDate" clearable>
					</el-date-picker>
				</div>
				<div class="w210 mr12" v-show="tab != 3 && searchQuery.timeIn == -2">
					<el-date-picker style="width: 210px; height: 32px" format="YYYY-MM" value-format="YYYY-MM"
						v-model="searchQuery.searchTime" type="monthrange" start-placeholder="开始月份"
						end-placeholder="结束月份" :disabled-date="datePickerDisabled.setMonthDisabled">
					</el-date-picker>
				</div>
				<div class="w210 mr12" v-show="tab == 3">
					<el-select v-model="searchQuery.courseType" filterable placeholder="全部课程类型"
						@change="courseTypeChange">
						<el-option label="全部课程类型" value="" />
						<el-option v-for="item in searchQueryList.courseTypeList" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</div>
				<div class="w210 mr12" v-show="tab == 3">
					<el-select v-model="searchQuery.courseNameIds" multiple collapse-tags filterable
						placeholder="全部私教课程">
						<el-option v-for="item in searchQueryList.privateCourseList" :key="item.courseNameId"
							:label="item.courseName" :value="item.courseNameId" />
					</el-select>
				</div>
				<el-button type="primary" @click="tabSearchClick" :icon="Search">搜索</el-button>
			</div>
			<div class="rx-cc">
				<el-button :icon="Download" @click="downloadEcharts">下载图表</el-button>
				<el-button :icon="Upload" @click="downloadTable">导出表格</el-button>
			</div>
		</div>
		<div class="rx-sc pl10 pr10 pt20 pb20 posi-r mt20" style="background-color: #EFF6FF;">
			<div class="ww25 pl10 pr10" v-show="tab == 1">
				<div class="bdr4 bg-fff rx-bc pl35 pr35 h110">
					<div>
						<h3 class="fwb color-333 ft32 mb5">{{summaryData.courseNum}}</h3>
						<p class="fwb color-999 ft16">排课总节数</p>
					</div>
					<div class="iconBox">
						<img class="w60" src="@/assets/bookIcon.png" alt="">
						<span class="bg-F8CE66 rx-cc w25 h25 bdr100 color-fff fwb iconItem">节</span>
					</div>
				</div>
			</div>
			<div class="ww25 pl10 pr10" v-show="tab == 1 && typeTab == 2">
				<div class="bdr4 bg-fff rx-bc pl35 pr35 h110">
					<div>
						<h3 class="fwb color-333 ft32 mb5">{{summaryData.allPeopleNum}}</h3>
						<p class="fwb color-999 ft16">容纳总人数</p>
					</div>
					<div class="iconBox">
						<img class="w60" src="@/assets/peopleIcon.png" alt="">
						<span class="bg-3CCBEC rx-cc w25 h25 bdr100 color-fff fwb iconItem">容</span>
					</div>
				</div>
			</div>
			<div class="ww25 pl10 pr10" v-show="tab == 1">
				<div class="bdr4 bg-fff rx-bc pl35 pr35 h110">
					<div>
						<h3 class="fwb color-333 ft32 mb5">{{summaryData.confirmClassNum}}</h3>
						<p class="fwb color-999 ft16">约课总人数</p>
					</div>
					<div class="iconBox">
						<img class="w60" src="@/assets/peopleIcon.png" alt="">
						<span class="bg-FA73B0 rx-cc w25 h25 bdr100 color-fff fwb iconItem">约</span>
					</div>
				</div>
			</div>
			<div class="ww25 pl10 pr10" v-show="tab == 2">
				<div class="bdr4 bg-fff rx-bc pl35 pr35 h110">
					<div>
						<h3 class="fwb color-333 ft32 mb5">{{summaryData.courseTimeH}}</h3>
						<p class="fwb color-999 ft16">排课总时长</p>
					</div>
					<div class="iconBox">
						<img class="w60" src="@/assets/timeIcon.png" alt="">
						<span class="bg-3CCBEC rx-cc w25 h25 bdr100 color-fff fwb iconItem">时</span>
					</div>
				</div>
			</div>
			<div class="ww25 pl10 pr10" v-show="tab == 2">
				<div class="bdr4 bg-fff rx-bc pl35 pr35 h110">
					<div>
						<h3 class="fwb color-333 ft32 mb5">{{summaryData.validCourseTimeH}}</h3>
						<p class="fwb color-999 ft16">有效上课总时长</p>
					</div>
					<div class="iconBox">
						<img class="w60" src="@/assets/timeIcon.png" alt="">
						<span class="bg-3CCBEC rx-cc w25 h25 bdr100 color-fff fwb iconItem">时</span>
					</div>
				</div>
			</div>
			<div class="ww25 pl10 pr10" v-show="tab == 2">
				<div class="bdr4 bg-fff rx-bc pl35 pr35 h110">
					<div>
						<h3 class="fwb color-333 ft32 mb5">{{summaryData.validCourseNum}}</h3>
						<p class="fwb color-999 ft16">有效上课总课节</p>
					</div>
					<div class="iconBox">
						<img class="w60" src="@/assets/powerIcon.png" alt="">
						<span class="bg-FA73B0 rx-cc w25 h25 bdr100 color-fff fwb iconItem">时</span>
					</div>
				</div>
			</div>
			<div class="ww25 pl10 pr10" v-show="tab == 3">
				<div class="bdr4 bg-fff rx-bc pl35 pr35 h110">
					<div>
						<h3 class="fwb color-333 ft32 mb5">{{summaryData.productNumBuy}}</h3>
						<p class="fwb color-999 ft16">会员购买节数</p>
					</div>
					<div class="iconBox">
						<img class="w60" src="@/assets/powerIcon.png" alt="">
						<span class="bg-3CCBEC rx-cc w25 h25 bdr100 color-fff fwb iconItem">节</span>
					</div>
				</div>
			</div>
			<div class="ww25 pl10 pr10" v-show="tab == 3">
				<div class="bdr4 bg-fff rx-bc pl35 pr35 h110">
					<div>
						<h3 class="fwb color-333 ft32 mb5">{{summaryData.productNumGiv}}</h3>
						<p class="fwb color-999 ft16">赠送总节数</p>
					</div>
					<div class="iconBox">
						<img class="w60" src="@/assets/powerIcon.png" alt="">
						<span class="bg-F8CE66 rx-cc w25 h25 bdr100 color-fff fwb iconItem">节</span>
					</div>
				</div>
			</div>
			<div class="ww25 pl10 pr10" v-show="tab == 3">
				<div class="bdr4 bg-fff rx-bc pl35 pr35 h110">
					<div>
						<h3 class="fwb color-333 ft32 mb5">{{summaryData.productNum}}</h3>
						<p class="fwb color-999 ft16">剩余未消节数</p>
					</div>
					<div class="iconBox">
						<img class="w60" src="@/assets/powerIcon.png" alt="">
						<span class="bg-FA73B0 rx-cc w25 h25 bdr100 color-fff fwb iconItem">节</span>
					</div>
				</div>
			</div>
			<div class="ww25 pl10 pr10" v-show="tab == 3">
				<div class="bdr4 bg-fff rx-bc pl35 pr35 h110">
					<div>
						<h3 class="fwb color-333 ft32 mb5">￥{{summaryData.remainMoney}}</h3>
						<p class="fwb color-999 ft16">剩余总金额</p>
					</div>
					<div class="iconBox">
						<img class="w60" src="@/assets/boxIcon.png" alt="">
						<span class="bg-B773FA rx-cc w25 h25 bdr100 color-fff fwb iconItem">元</span>
					</div>
				</div>
			</div>
			<img style="position: absolute;right: 40px;top: 5%;height: 90%;" v-show="tab != 3"
				src="@/assets/dataIcon.png" alt="">
		</div>
		<div class="mt20 pl10 fwb ft16 color-333" style="border-left: 4px solid #03A3FF;">
			{{echartTitle}}
			<span class="ft12 color-999" v-show="tab == 3 ">（仅展示前排班时长排名前20的教练）</span>
		</div>
		<div id="main" class="h500 mt20"></div>
		<div class="mt20">
			<el-table :data="tableData.list.slice(tabForm.page*tabForm.limit-tabForm.limit,tabForm.page*tabForm.limit)"
				style="width: 100%" @sort-change="changeTableSort" :default-sort="{prop: '', order: 'ascending '}">
				<el-table-column prop="courseName" label="课程名称" v-if="tab == 1 && typeTab == 2" show-overflow-tooltip />
				<el-table-column prop="classDateStr" label="日期" v-if="tab == 1 && typeTab == 1" />
				<el-table-column prop="courseNum" label="排课总节数" sortable v-if="tab == 1" />
				<el-table-column prop="allPeopleNum" label="容纳总人数" sortable v-if="tab == 1 && typeTab == 2" />
				<el-table-column prop="confirmClassNum" label="约课总人次" sortable v-if="tab == 1" />
				<el-table-column prop="confirmClassNumAvg" label="课均预约人次" sortable v-if="tab == 1 && typeTab == 1" />
				<el-table-column prop="saturation" label="工作饱和度" sortable v-if="tab == 1 && typeTab == 2">
					<template #default="scope">
						<span>{{ scope.row.saturation }}%</span>
					</template>
				</el-table-column>

				<!-- 私教约课 -->
				<el-table-column prop="courseTeacherName" label="教练姓名" v-if="tab == 2" show-overflow-tooltip />
				<el-table-column prop="courseTimeH" label="排课总时长" sortable v-if="tab == 2">
					<template #default="scope">
						<span>{{ scope.row.courseTimeH }}h</span>
					</template>
				</el-table-column>
				<el-table-column prop="validCourseTimeH" label="上课总时长" sortable v-if="tab == 2">
					<template #default="scope">
						<span>{{ scope.row.validCourseTimeH }}h</span>
					</template>
				</el-table-column>
				<el-table-column prop="validCourseNum" label="有效上课课节数" sortable v-if="tab == 2" />
				<el-table-column prop="saturation" label="工作饱和度" sortable v-if="tab == 2">
					<template #default="scope">
						<span>{{ scope.row.saturation }}%</span>
					</template>
				</el-table-column>
				<!-- 私教存课 -->
				<el-table-column prop="courseName" label="课程名称" v-if="tab == 3" show-overflow-tooltip />
				<el-table-column prop="productNum" label="剩余总节数" sortable="custom" v-if="tab == 3" />
				<el-table-column prop="remainMoney" label="剩余总金额" sortable="custom" v-if="tab == 3" />
				<el-table-column prop="remainMoneyAvg" label="剩余摊销(元)" sortable="custom" v-if="tab == 3" />
				<el-table-column prop="productNumBuy" label="会员购买总节数" sortable="custom" v-if="tab == 3" />
				<el-table-column prop="productNumGiv" label="赠送总课时" sortable="custom" v-if="tab == 3" />
				<el-table-column label="操作" v-if="tab == 3">
					<template #default="scope">
						<el-link class="t_d_u" :underline="false" type="primary" @click="lookDetailEvent(scope.row)">详情
						</el-link>
					</template>
				</el-table-column>
			</el-table>
			<div class="mt20 rx-ec" v-show="tableData.list.length > 0">
				<el-pagination v-model:currentPage="tabForm.page" :page-sizes="[15,20,30,50,100]"
					:page-size="tabForm.limit" layout="total, sizes, prev, pager, next, jumper" :total="tabForm.count"
					@size-change="pageSizeChange" @current-change="pageChange">
				</el-pagination>
			</div>
		</div>
		<Formula v-model="formulaShow" :type="formulaType"></Formula>
		<el-dialog v-model="lookDetailData.show" :title="'【'+lookDetailData.title+'】存课详情'" width="1200px"
			:before-close="()=>{lookDetailData.show = false}" custom-class="popwechat">
			<div class="p20">
				<div class="rx-sc mb20">
					<div class="w210 mr12">
						<el-select v-model="lookDetailForm.cardMyIds" multiple collapse-tags filterable
							placeholder="全部会员" @change="courseTypeChange">
							<el-option v-for="item in lookDetailData.memberList" :key="item.cardMyId" :label="item.realName"
								:value="item.cardMyId" />
						</el-select>
					</div>
					<el-button type="primary" @click="searchDetailList" :icon="Search">搜索</el-button>
				</div>
				<el-table :data="lookDetailData.list" style="width: 100%">
					<el-table-column prop="realName" label="会员姓名">
						<template #default="scope">
							<a href="javascript:;" class="color-409EFF tool_btn"
								@click="toMemberDetail(scope.row.encryptionCardMyId)">{{scope.row.realName}}</a>
						</template>
					</el-table-column>
					<el-table-column prop="productNum" label="剩余节数" />
					<el-table-column prop="remainMoney" label="剩余金额" />
					<el-table-column prop="productNumBuy" label="购买节数" />
					<el-table-column prop="productNumGiv" label="赠送节数" />
					<el-table-column prop="consumeNum" label="已消费节数" />
					<el-table-column prop="consumeMoney" label="消费总金额" />
					<el-table-column prop="remainMoneyAvg" label="剩余摊销" />
					<el-table-column prop="actualMoney" label="购买金额" />

				</el-table>
				<div class="mt20 rx-ec" v-show="lookDetailData.list.length > 0">
					<el-pagination v-model:currentPage="lookDetailForm.page" :page-sizes="[15,20,30,50,100]"
						:page-size="lookDetailForm.limit" layout="total, sizes, prev, pager, next, jumper"
						:total="lookDetailData.count" @size-change="detailSizeChange" @current-change="getDetailList">
					</el-pagination>
				</div>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="lookDetailData.show = false">取消</el-button>
					<el-button type="primary" @click="lookDetailData.show = false">确定</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script setup>
	import {
		reactive,
		ref,
		onMounted,
		onBeforeUnmount
	} from 'vue';
	import {useRouter,onBeforeRouteLeave} from 'vue-router';
	import {
		Search,
		Upload,
		Download
	} from '@element-plus/icons-vue';
	import * as echarts from 'echarts';
	import utils from '@/common/utils.js'
	import {
		getStoreAccountStaffSimple,
		selectStoreMembersSimple
	} from '@/api/staff.js';
	import {
		courseList
	} from '@/api/course.js'
	import {
		eduGroupAnalysisDate,
		eduGroupAnalysisCourse,
		eduPrivateAnalysisTeacher,
		eduPrivateRemainAnalysisCourse,
		eduPrivateRemainAnalysisCourseDetail
	} from '@/api/dataCenter.js'
	import {
		ElMessage
	} from 'element-plus'
	import Formula from '@/components/branch-store/datacenter/formula.vue';
	const router = useRouter();
	var myChart = '';
	//日期范围/月份范围控件配置
	const datePickerDisabled = reactive({
		disabledDate(time) {
		  return time.getTime() > new Date().getTime()
		},
		setMonthDisabled(time) {
		    // 获取当前的月份信息
		    const date = new Date(); // 获取当前的时间基本信息，值是这样的： Tue Jul 20 2021 14:59:43 GMT+0800 (中国标准时间)
		    const year = date.getFullYear(); // 获取当前年份，值是这样的： 2021
		    let month = date.getMonth() + 1; // 获取当前月份，值是这样的： 6   getMonth()方法返回值是0-11，也就是1月份到12月份，所以要加上1，才是当前月份
		    if (month >= 1 && month <= 9) {
		      // 如果是1月到9月就要在前面补上一个0   比如：02、07    月份这样表示
		      month = "0" + month;
		    }
		    const nowDate = year.toString() + month.toString(); // 转换成字符串拼接，最终得到年和月，比如此时的时间是2021年7月20号，所以nowDate的值是：202107
		    // 获取时间选择器的月份信息
		    const timeyear = time.getFullYear(); // 获取时间选择器的年份（有很多）
		    let timemonth = time.getMonth() + 1; // 与上面同理
		    if (timemonth >= 1 && timemonth <= 9) {
		      timemonth = "0" + timemonth;
		    }
		    const elTimeData = timeyear.toString() + timemonth.toString();
		
		    // 返回，时间选择器的日期月份大于当前日期的月份，又因为disabledDate函数是控制月份禁用不可选
		    // 所以，最终就是：时间选择器的月份大于当前的月份，就都禁用掉，所以就实现了最终效果：
		    // 大于等于当前月份都不可选 
		    return elTimeData > nowDate; // 这里虽然是字符串，但是弱类型语言js会做一个转换，是可以比较大小的如： '202107' > '202008'
		},
	})
	
	//切换课类型
	const tab = ref(1);
	const tabClick = function(num) {
		tab.value = num;
		pageData.orderColumn = ''
		pageData.orderAscDesc = ''
		initSearchQuery();
		tabSearchClick()
		if (num == 3) {
			courseTypeChange()
		}
	}

	//切换日期、课程
	const typeTab = ref(1);
	const typeTabClick = function(num) {
		typeTab.value = num;
		initSearchQuery();
		tabSearchClick()
	}
	//搜索项数据
	const searchQueryList = reactive({
		coachList: [],
		teamCourseList: [],
		privateCourseList: [],
		courseTypeList: [{
				label: '私教1v1',
				value: 14
			},
			{
				label: '私教1v多',
				value: 15
			},
		],
		timeTypeList: [{
				label: '近7天',
				value: 5
			},
			{
				label: '近15天',
				value: 6
			},
			{
				label: '近30天',
				value: 7
			},
			{
				label: '近60天',
				value: 8
			},
			{
				label: '近90天',
				value: 9
			},
			{
				label: '近6月',
				value: 24
			},
			{
				label: '近12月',
				value: 25
			},
			{
				label: '自定义日期',
				value: -1
		},
			{
				label: '自定义月份',
				value: -2
		}
			]
	});
	//搜索字段
	const searchQuery = reactive({
		timeIn: 5,
		courseNameIds: [],
		searchTime: [],
		courseType: '',
		teacherIds: []
	})
	//获取教练
	getStoreAccountStaffSimple({
		delFlag: 0,
		teachType: 2,
	}).then((res) => {
		if (res.code == 0) {
			searchQueryList.coachList = res.data;
		}
	})
	//获取全部团体课
	utils.getDynaDict('teamCourseNameAll', '', function(res) {
		searchQueryList.teamCourseList = res.map((n) => {
			n.value = Number(n.value);
			return n;
		});
	});
	//私教课存课-课程类型切换
	const courseTypeChange = function() {
		searchQuery.courseNameIds = [];
		courseList({
			courseType: searchQuery.courseType ? searchQuery.courseType : 12,
			sortType: 1,
			page: 1,
			limit: 9999
		}).then((res) => {
			if (res.code == 0) {
				searchQueryList.privateCourseList = res.data;
			}
		})
	}
	//初始化表单
	const initSearchQuery = function() {
		for (var k in searchQuery) {
			searchQuery[k] = Object.prototype.toString.call(searchQuery[k]) == '[object Array]' ? [] : '';
		}
		searchQuery['timeIn'] = 5;
	}

	//汇总数据字段
	const summaryData = {
		courseNum: 0, //排课总节数
		allPeopleNum: 0, //容纳总人数
		confirmClassNum: 0, //约课总人数
		courseTimeH: 0, //排班总时长-H
		validCourseTimeH: 0, //上课总时长-H
		validCourseNum: 0, //有效上课课节数
		productNumBuy: 0, //会员购买节数
		productNumGiv: 0, //赠送总节数
		productNum: 0, //剩余总节数
		remainMoney: 0, //剩余总金额
	}
	//图标数据
	var getOption = {
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'cross',
				crossStyle: {
					color: '#999'
				}
			}
		},
		legend: {
			data: []
		},
		grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
		xAxis: [{
			type: 'category',
			data: [],
			axisPointer: {
				type: 'shadow'
			},
            axisLabel: {
                interval: 6,
                rotate: 35
            }
		}],
		yAxis: [],
		series: []
	};
	//获取数据
	const tableData = reactive({
		list: []
	});
	const tabForm = reactive({
		count: 0,
		limit: 15,
		page: 1,
		exportData: 0
	})
	const pageData = reactive({
		orderColumn: '',
		orderAscDesc: ''
	})
	/**
	 * 列表排序
	 */
	function changeTableSort(item){
		pageData.orderColumn = item.prop
		pageData.orderAscDesc = item.order
		getData()
	}
	//切换列表数据展示数量
	const pageSizeChange = function(limit) {
		tabForm.limit = limit;
		// tabSearchClick();
	}
	const echartTitle = ref();
	const formulaType = ref('2'); //计算公式类型
	const tabSearchClick = function(){
		if (searchQuery.timeIn == '-1') {
            if (!searchQuery.searchTime || !searchQuery.searchTime.length) {
                ElMessage({
                    type : 'warning',
                    message : '请选择日期'
                });
                return;
            }
            if (utils.datedifference(searchQuery.searchTime[0], searchQuery.searchTime[1]) > 31) {
                ElMessage({
                    type : 'warning',
                    message : '自定义日期不能超过31天'
                });
                return;
            }
        }
        if (searchQuery.timeIn == '-2') {
            if (!searchQuery.searchTime || !searchQuery.searchTime.length) {
                ElMessage({
                    type : 'warning',
                    message : '请选择月份'
                });
                return;
            }
            if (utils.MonthsBetw(searchQuery.searchTime[0], searchQuery.searchTime[1]) > 12) {
                ElMessage({
                    type : 'warning',
                    message : '自定义月份不能超过12个月'
                });
                return;
            }
        }
		tabForm.exportData = 0
		tabForm.page = 1;
		getData();
	}
	const getData = function() {
		if (tab.value == 1) {
			if (typeTab.value == 1) {
				echartTitle.value = '团课基本统计';
				formulaType.value = '2';
				eduGroupAnalysisDate({
					timeIn: searchQuery.timeIn,
					searchTime: searchQuery.searchTime.join(' - '),
					courseNameIds: searchQuery.courseNameIds.join(','),
					teacherIds: searchQuery.teacherIds.join(','),
					exportData: tabForm.exportData
				}).then((res) => {
					if (res.code == 0) {
						if (tabForm.exportData == 1) { //导出
							ElMessage({
								type: 'success',
								message: '正在导出，请稍后前往下载中心下载'
							})
							return false;
						}
						for (var k in summaryData) {
							summaryData[k] = res.remark[k];
						}
						tableData.list = res.data;
						tabForm.count = res.count;
						var xData = [],
							seriesData1 = [],
							seriesData2 = [],
							seriesData3 = [],
							maxNum = 0;
						tableData.list.map((n) => {
							xData.push(n.classDateStr);
							seriesData1.push(n.courseNum);
							seriesData2.push(n.confirmClassNum);
							seriesData3.push(n.confirmClassNumAvg);
							if(n.courseNum > maxNum){
								maxNum = n.courseNum;
							}
						})
						
						getOption.legend.data = ['排课节数', '已约课人次', '课均约课人数'];
						getOption.xAxis[0].data = xData;
						if (tableData.list.length > 10) {
                    	    getOption.xAxis[0].axisLabel = {
                    	        interval:0,
                    	        rotate: 40
                    	    }
                    	} else {
                    	    getOption.xAxis[0].axisLabel = {
                    	        interval:0,
                    	        rotate: 0
                    	    }
                    	}
						getOption.yAxis = [{
								type: 'value',
								name: '课节/节',
								min: 0,
								max: maxNum > 100 ? maxNum :100,
								interval: maxNum > 100 ? maxNum/5 : 20,

							},
							{
								type: 'value',
								name: '人次/人',
								min: 0,
								max: 100,
								interval: 20,

							}
						];
						getOption.series = [{
								name: '排课节数',
								type: 'bar',
								color: '#F7C74F',
								barWidth: 30,
								data: seriesData1
							},
							{
								name: '已约课人次',
								type: 'line',
								symbol: 'circle',
								symbolSize: 6,
								smooth: true,
								label: {
									show: true
								},
								yAxisIndex: 1,
								data: seriesData2,
								itemStyle:{
                    			    normal: {
                    			        color: '#FA73B0', 
                    			        borderColor:'#FFF',
                    			        borderWidth:1,
                    			        lineStyle: {
                    			            color: '#FA73B0',
                    			            shadowColor: 'rgba(252, 125, 125, 0.3)',//设置折线阴影
                    			            shadowBlur: 1,
                    			            shadowOffsetY: 10,
                    			        }
                    			    }
                    			},
							},
							{
								name: '课均约课人数',
								type: 'line',
								symbol: 'circle',
								symbolSize: 6,
								smooth: true,
								label: {
									show: true
								},
								yAxisIndex: 1,
								data: seriesData3,
								itemStyle:{
                    			    normal: {
                    			        color: '#749EFA', 
                    			        borderColor:'#FFF',
                    			        borderWidth:1,
                    			        lineStyle: {
                    			            color: '#749EFA',
                    			            shadowColor: 'rgba(116, 158, 250, 0.3)',//设置折线阴影
                    			            shadowBlur: 1,
                    			            shadowOffsetY: 10,
                    			        }
                    			    }
                    			},
							}
						]
						myChart.setOption(getOption);
					}
				})
			} else {
				echartTitle.value = '工作饱和度分析';
				formulaType.value = '3';
				eduGroupAnalysisCourse({
					timeIn: searchQuery.timeIn,
					searchTime: searchQuery.searchTime.join(' - '),
					courseNameIds: searchQuery.courseNameIds.join(','),
					exportData: tabForm.exportData
				}).then((res) => {
					if (res.code == 0) {
						if (tabForm.exportData == 1) { //导出
							ElMessage({
								type: 'success',
								message: '正在导出，请稍后前往下载中心下载'
							})
							return false;
						}
						for (var k in summaryData) {
							summaryData[k] = res.remark[k];
						}
						tableData.list = res.data;
						tabForm.count = res.count;
						var xData = [],
							seriesData1 = [],
							seriesData2 = [],
							seriesData3 = [];
						var maxNum = 0;
						tableData.list.map((n) => {
							xData.push(n.courseName);
							seriesData1.push(n.allPeopleNum);
							seriesData2.push(n.confirmClassNum);
							seriesData3.push(n.saturation);
							if (Number(n.allPeopleNum) > maxNum) {
								maxNum = Number(n.allPeopleNum);
							}
						})
						getOption.legend.data = ['容纳人数', '课均约课人次', '工作饱和度'];
						getOption.xAxis[0].data = xData;
						if (tableData.list.length > 10) {
                    	    getOption.xAxis[0].axisLabel = {
                    	        interval:0,
                    	        rotate: 40
                    	    }
                    	} else {
                    	    getOption.xAxis[0].axisLabel = {
                    	        interval:0,
                    	        rotate: 0
                    	    }
                    	}
						getOption.yAxis = [{
								type: 'value',
								name: '人数/人',
								min: 0,
								max: maxNum > 100 ? maxNum : 100,
								interval: maxNum > 100 ? maxNum / 5 : 20,

							},
							{
								type: 'value',
								name: '饱和度',
								min: 0,
								max: 100,
								interval: 20,
								axisLabel: {
									formatter: '{value} %'
								}
							}
						];
						getOption.series = [{
								name: '容纳人数',
								type: 'bar',
								color: '#FA73B0',
								barWidth: 30,
								data: seriesData1
							},
							{
								name: '课均约课人次',
								type: 'bar',
								barWidth: 30,
								color: '#3CCBEC',
								data: seriesData2
							},
							{
								name: '工作饱和度',
								type: 'line',
								yAxisIndex: 1,
								symbol: 'circle',
								symbolSize: 6,
								smooth: true,
								label: {
									show: true,
									formatter: '{c}%'
								},
								color: '#A358E6',
								data: seriesData3
							}
						]
						myChart.setOption(getOption);
					}
				})
			}
		} else if (tab.value == 2) {
			echartTitle.value = '教练工作饱和度';
			formulaType.value = '4';
			eduPrivateAnalysisTeacher({
				timeIn: searchQuery.timeIn,
				searchTime: searchQuery.searchTime.join(' - '),
				teacherIds: searchQuery.teacherIds.join(','),
				exportData: tabForm.exportData
			}).then((res) => {
				if (res.code == 0) {
					if (tabForm.exportData == 1) { //导出
						ElMessage({
							type: 'success',
							message: '正在导出，请稍后前往下载中心下载'
						})
						return false;
					}
					for (var k in summaryData) {
						summaryData[k] = res.remark[k];
					}
					tableData.list = res.data;
					tabForm.count = res.count;
					var xData = [],
						seriesData1 = [],
						seriesData2 = [],
						seriesData3 = [],
						maxNum1 = 0,
						maxNum2 = 0;
					tableData.list.map((n,index) => {
						if (index<20) {
							xData.push(n.courseTeacherName);
							seriesData1.push(n.validCourseTimeH);
							seriesData2.push(n.courseTimeH);
							seriesData3.push(n.saturation);
							if (Number(n.validCourseTimeH) > maxNum1) {
								maxNum1 = Number(n.validCourseTimeH);
							}
							if (Number(n.courseTimeH)> maxNum2) {
								maxNum2 = Number(n.courseTimeH);
							}
						}
					})
					var num = (maxNum1 - maxNum2) < 0 ? maxNum2 : maxNum1; //取最大的
					getOption.legend.data = ['上课时长', '排班时长', '工作饱和度'];
					if (tableData.list.length > 10) {
                    	    getOption.xAxis[0].axisLabel = {
                    	        interval:0,
                    	        rotate: 40
                    	    }
                    	} else {
                    	    getOption.xAxis[0].axisLabel = {
                    	        interval:0,
                    	        rotate: 0
                    	    }
                    	}
					getOption.xAxis[0].data = xData;
					getOption.yAxis = [{
							type: 'value',
							name: '时长/h',
							min: 0,
							max: num > 100 ? num : 100,
							interval: num > 100 ? num / 5 : 20,
							axisLabel: {
								formatter: function(value, index) {
									return value.toFixed(1) + 'h';
								}
							}

						},
						{
							type: 'value',
							name: '饱和度',
							min: 0,
							max: 100,
							interval: 20,
							axisLabel: {
								formatter: '{value} %'
							}

						}
					];
					getOption.series = [{
							name: '上课时长',
							type: 'bar',
							color: '#3CCBEC',
							barWidth: 30,
							data: seriesData1
						},
						{
							name: '排班时长',
							type: 'bar',
							barWidth: 30,
							color: '#73B0FA',
							data: seriesData2
						},
						{
							name: '工作饱和度',
							type: 'line',
							symbol: 'circle',
							symbolSize: 6,
							smooth: true,
							yAxisIndex: 1,
							label: {
								show: true,
								formatter: '{c}%'
							},
							color: '#FB5A5F',
							data: seriesData3
						}
					]
					myChart.setOption(getOption);
				}
			})
		} else if (tab.value == 3) {
			echartTitle.value = '存课课程';
			formulaType.value = '5';
			eduPrivateRemainAnalysisCourse({
				courseType: searchQuery.courseType,
				courseNameIds: searchQuery.courseNameIds.join(','),
				exportData: tabForm.exportData,
				orderColumn: pageData.orderColumn,
				orderAscDesc: pageData.orderAscDesc,
			}).then((res) => {
				if (res.code == 0) {
					if (tabForm.exportData == 1) { //导出
						ElMessage({
							type: 'success',
							message: '正在导出，请稍后前往下载中心下载'
						})
						return false;
					}
					for (var k in summaryData) {
						summaryData[k] = res.remark[k];
					}
					tableData.list = res.data;
					tabForm.count = res.count;
					var xData = [],
						seriesData1 = [],
						seriesData2 = [],
						seriesData3 = [],
						maxNum1 = 0,
						maxNum2 = 0,
						maxNum3 = 0;
					tableData.list.map((n) => {
						xData.push(n.courseName);
						seriesData1.push(n.productNum);
						seriesData2.push(n.remainMoney);
						seriesData3.push(n.remainMoneyAvg);
						if (Number(n.productNum) > maxNum1) {
							maxNum1 = Number(n.productNum);
						}
						if (Number(n.remainMoney) > maxNum2) {
							maxNum2 = Number(n.remainMoney);
						}
						if (Number(n.remainMoneyAvg) > maxNum3) {
							maxNum3 = Number(n.remainMoneyAvg);
						}
					})
					var num = (maxNum1 - maxNum2) < 0 ? maxNum2 : maxNum1; //取最大的
					getOption.legend.data = ['剩余课节', '剩余金额', '剩余摊销'];
					getOption.xAxis[0].data = xData;
					if (tableData.list.length > 10) {
                    	    getOption.xAxis[0].axisLabel = {
                    	        interval:0,
                    	        rotate: 40
                    	    }
                    	} else {
                    	    getOption.xAxis[0].axisLabel = {
                    	        interval:0,
                    	        rotate: 0
                    	    }
                    	}
					getOption.yAxis = [{
							type: 'value',
							name: '节数/节',
							min: 0,
							max: num > 100 ? num : 100,
							interval: num > 100 ? num / 5 : 20,
							axisLabel: {
								formatter: function(value, index) {
									return value.toFixed(1);
								}
							}
						},
						{
							type: 'value',
							name: '金额/元',
							min: 0,
							max: maxNum3 > 100 ? maxNum3 : 100,
							interval: maxNum3 > 100 ? maxNum3 / 5 : 20,
							axisLabel: {
								formatter: function(value, index) {
									return value.toFixed(1);
								}
							}
						}
					];
					getOption.series = [{
							name: '剩余课节',
							type: 'bar',
							color: '#FA73B0',
							barWidth: 30,
							data: seriesData1
						},
						{
							name: '剩余金额',
							type: 'bar',
							barWidth: 30,
							color: '#F8CE66',
							data: seriesData2
						},
						{
							name: '剩余摊销',
							type: 'line',
							yAxisIndex: 1,
							label: {
								show: true,
								formatter: '{c}'
							},
							color: '#5A95FB',
							data: seriesData3
						}
					]
					myChart.setOption(getOption);
				}
			})
		}
	}


	//下载图表
	const downloadEcharts = function() {
		var myChartSrc = myChart.getDataURL({
			pixelRatio: 2,
			backgroundColor: '#fff'
		});
		var a = document.createElement('a');
		a.style.display = 'none';
		// a.href = 'data:image/jpeg;base64,'+myChartSrc;
		a.href = myChartSrc;
		if(tab.value == 1){
			a.download = '团课基本统计.png';
		}else if(tab.value == 2){
			a.download = '教练工作饱和度.png';
		}else{
			a.download = '存课课程.png';
		}
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}
	//导出表格
	const downloadTable = function() {
		tabForm.exportData = 1;
		getData();
	}
	onMounted(() => {
		var chartDom = document.getElementById('main');
		myChart = echarts.init(chartDom);
		window.addEventListener("resize",function(){
		    myChart.resize();
        });
		getData()
	})

	//表格排序
	// const sortChange = function(column, prop, order){
	// 	tabForm.page = 1;
	// 	tableData.list.sort((a,b)=>{
	// 		var x = a[prop],y = b[prop];
	// 		if(order == 'ascending'){
	// 			return ((x < y) ? -1 : ((x > y) ? 1 : 0));
	// 		}else{
	// 			return ((x < y) ? ((x > y) ? 1 : 0) : -1);
	// 		}
	// 	})
	// }
	//计算公式
	const formulaShow = ref(false);
	const formulaClick = function() {
		formulaShow.value = true;
	}

	//查看详情
	const lookDetailData = reactive({
		show: false,
		title: '',
		list: [],
		memberList: [],
		count: 0,
	})
	const lookDetailForm = reactive({
		page: 1,
		limit: 15,
		courseNameId: '',
		cardMyIds: [],
	})
	//查看详情
	const lookDetailEvent = function(row) {
		lookDetailForm.courseNameId = row.courseNameId;
		lookDetailForm.cardMyIds = [];
		lookDetailData.title = row.courseName;
		getMemberList();
		searchDetailList();
		lookDetailData.show = true;
	}
	//详情切花分页数量
	const detailSizeChange = function() {
		lookDetailForm.limit = limit;
		searchDetailList();
	}
	//搜索
	const searchDetailList = function() {
		lookDetailForm.page = 1;
		getDetailList();
	}
	//获取详情列表
	const getDetailList = function() {
		let params = Object.assign({}, lookDetailForm)
		params.cardMyIds = lookDetailForm.cardMyIds.join(',')
		eduPrivateRemainAnalysisCourseDetail(params).then((res) => {
			if (res.code == 0) {
				lookDetailData.count = res.count;
				lookDetailData.list = res.data;
			}
		})
	}
	//获取会员列表
	const getMemberList = function() {
		selectStoreMembersSimple({
			page: 1,
			limit: 9999
		}).then((res) => {
			if (res.code == 0) {
				lookDetailData.memberList = res.data;
			}
		})
	}
	//会员详情
	const toMemberDetail = function(id){
		router.push({path:'/branch/memberDetail',query:{encryptionId:id}});
	}
	onBeforeUnmount(() => {
      if (!myChart) {
        return
      }
      myChart.dispose()
      myChart = null
    })
</script>
<style type='css/style' scoped>
	.typeActive{
		position: relative;
		color: #00A3FF;
	}
	.typeActive::after{
		position: absolute;
		content: '';
		bottom: -8px;
		left: 50%;
		width: 70%;
		height: 3px;
		transform: translateX(-50%);
		background-color: #03A3FF;
		border-radius: 2px;
	}
	.bg-F8CE66{
		background-color: #F8CE66;
	}
	.bg-FA73B0{
		background-color: #FA73B0;
	}
	.bg-3CCBEC{
		background-color: #3CCBEC;
	}
	.bg-B773FA{
		background-color: #B773FA; 
	}
	.iconBox{
		position: relative;
	}
	.iconItem{
		position: absolute;
		bottom: 0;
		right: 0;
	}
</style>
