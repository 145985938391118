<template>
	<workArea :barlist="pageData.barlist">
		<div class="card_manage bg-fff pl16 pt10 pr16">
			<el-tabs v-model="pageData.tabNum" @tab-click="tabChangeEvent">
				<el-tab-pane label="预约设置" name="0" v-if="utils.getMenuRole(33200)">
					<order />
				</el-tab-pane>
				<el-tab-pane label="消息设置" name="1" v-if="utils.getMenuRole(33201)">
					<messageSetting v-if="pageData.tabNum == 1"></messageSetting>
				</el-tab-pane>
				<el-tab-pane label="标签管理" name="2" v-if="utils.getMenuRole(33202)">
					<div class="mb16 mt20">
						<el-button type="primary" @click="addOrUpdateLabel('')" v-if="utils.getMenuRole(3320200)">添加标签</el-button>
					</div>
					<div>
						<el-table :data="pageData.tableData" stripe style="width: 100%" >
						    <el-table-column  prop="relName" label="标签名称">
								<template #default="scope">
									<span v-if="!utils.getMenuRole(3320200)">{{scope.row.tagName}}</span>
									<span v-if="utils.getMenuRole(3320200)" @click="addOrUpdateLabel(scope.row)" class="color-409EFF c_p tool_btn">{{scope.row.tagName}}</span>
								</template>
							</el-table-column>
							<el-table-column  prop="fontColor" label="字体颜色">
								<template #default="scope">
									<input type="color" :value="'#'+scope.row.fontColor" disabled="disabled">
								</template>
							</el-table-column>
							<el-table-column  prop="backColor" label="背景颜色">
								<template #default="scope">
									<input type="color" :value="'#'+scope.row.backColor" disabled="disabled">
								</template>
							</el-table-column>
							<el-table-column  label="预览">
								<template #default="scope">
									<div class="d_i_b pl5 pr5 bdr2" :style="{color:'#'+scope.row.fontColor,backgroundColor:'#'+scope.row.backColor}">{{scope.row.tagName}}</div>
								</template>
							</el-table-column>
							<el-table-column label="描述">
								<template #default="scope">
									{{ scope.row.tagDesc ? scope.row.tagDesc : '-' }}
								</template>
							</el-table-column>
							<el-table-column label="操作">
								<template #default="scope">
									<a href="javascript:;" class="t_d_u color-red " @click="deleLabel(scope.row.encryptionId)">删除</a>
								</template>
							</el-table-column>
						</el-table>
						<div class="mt20 rx-ec">
						    <el-pagination
						      v-model:currentPage="pageData.searchForm.page"
						      :page-sizes="[15,20,30,50,100]"
						      :page-size="pageData.searchForm.limit"
						      layout="total, sizes, prev, pager, next, jumper"
						      :total="pageData.totalCount"
						      @size-change="pageSizeChange"
						      @current-change="getLabelList"
						    >
						    </el-pagination>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="门店后台管理" name="3" v-if="utils.getMenuRole(33203)">
					<div class="w800 mt20">
						<el-card class="box-card" shadow="never">
							<div class="rx-bc mb20">
								<strong>收款方式设置</strong>
								<el-button type="primary" :icon="Edit" plain @click="updatePayType" v-if="utils.getMenuRole(3320300)">修改收款方式</el-button>
							</div>
							<ul class="rx-sc">
								<li class="rx-sc pl12 pt5 pb5 pr10 bdr2 mr16 w130" v-for="(item, index) in pageData.payList" :key="index" style="border: 1px solid #D6D6D6;">
									<img class="w30 h30 mr10" :src="payIconList[item.payId]" alt="">
									<span>{{item.payMethodName}}</span>
								</li>
							</ul>
						</el-card>
						<el-card class="box-card mt20" shadow="never">
							<strong>入离场设置</strong>
							<entryndExit v-if="pageData.tabNum == 3" />
						</el-card>
					</div>
				</el-tab-pane>
				<el-tab-pane label="跟进管理设置" name="4" v-if="utils.getMenuRole(33204)">
					<div class="w800 mt20 mb20">
					<CommonTitle text="跟进状态"></CommonTitle>
						<div class="mb12">
							<el-button type="primary" @click="addOrUpdateFollow('')" v-if="utils.getMenuRole(3320400)">添加跟进状态</el-button>
						</div>
						<div>
							<el-table :data="pageData.followTableData" stripe style="width: 100%">
								<el-table-column  prop="upStatusName" label="跟进状态"/>
								<el-table-column prop="courseCommentCount" label="开启状态">
									<template #default="scope">
										<div @click="updateFollowState(scope.row)">
											<!-- el-switch 初始值为0默认回走一次onchange事件 所以不用 -->
											<el-switch v-model="scope.row.status" inline-prompt :active-value="1" :inactive-value="0" ></el-switch>
										</div>
									</template>
								</el-table-column>
								<el-table-column label="备注">
									<template #default="scope">
										{{ scope.row.remark ? scope.row.remark : '-' }}
									</template>
								</el-table-column>
								<el-table-column label="操作">
									<template #default="scope">
										<a v-if="scope.row.sId" href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="addOrUpdateFollow(scope.row)">编辑</a>
										<a v-if="scope.row.sId" href="javascript:;" class="t_d_u color-red" @click="deleFollow(scope.row.encryptionId)">删除</a>
									</template>  
								</el-table-column>
							</el-table>
							<div class="mt20 rx-ec">
								<el-pagination
								  v-model:currentPage="pageData.followSearchForm.page"
								  :page-sizes="[15,20,30,50,100]"
								  :page-size="pageData.followSearchForm.limit"
								  layout="total, sizes, prev, pager, next, jumper"
								  :total="pageData.followCount"
								  @size-change="followSizeChange"
								  @current-change="getFollowList"
								>
								</el-pagination>
							</div>
						</div>
						<CommonTitle text="会员来源"></CommonTitle>
						<div class="mb12">
							<el-button type ="primary" @click="addOrUpdateSource('')"  v-if="utils.getMenuRole(3320401)">添加来源</el-button>
						</div>
						<div>
							<el-table :data="pageData.sourceTableData" stripe style="width: 100%">
								<el-table-column  prop="channelName" label="来源名称" />
								<el-table-column label="操作">
									<template #default="scope">
										<a v-if="scope.row.sid" href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="addOrUpdateSource(scope.row)">编辑</a>
										<a v-if="scope.row.sid" href="javascript:;" class="t_d_u color-red" @click="deleSource(scope.row.encryptionId)">删除</a>
									</template>  
								</el-table-column>
							</el-table>
							<div class="mt20 rx-ec">
							    <el-pagination
								  v-model:currentPage="pageData.sourceTableForm.page"
								  :page-sizes="[15,20,30,50,100]"
								  :page-size="pageData.sourceTableForm.limit"
								  layout="total, sizes, prev, pager, next, jumper"
								  :total="pageData.sourceTableData.length"
								  @size-change="sourceTableForm"
								  @current-change="getSettingList(2)"
								>
								</el-pagination>
							</div>
						</div>
					</div>
				</el-tab-pane>
				
			</el-tabs>
		</div>
		<!-- 标签管理 -->
		<el-dialog
		    v-model="pageData.addShow"
		    :title="pageData.addTitle"
		 	width="400px"
		    :before-close="()=>{pageData.addShow = false}"
		    custom-class="popwechat"
		   >
			<div class="pl20 pt20 pb20 pr20">
				<div class="rx-cc mb12">
					<span class=""><span class="color-red">*</span>标签名称：</span>
					<div class="w200">
						<el-input v-model="pageData.addForm.tagName" type="text" placeholder="请输入标签名称"></el-input>
					</div>
				</div>
				<div class="rx-cc mb12">
					<span class=""><span class="color-red">*</span>字体颜色：</span>
					<div class="w200">
						<el-color-picker v-model="pageData.addForm.fontColor" />
					</div>
				</div>
				<div class="rx-cc mb12">
					<span class=""><span class="color-red">*</span>背景颜色：</span>
					<div class="w200">
						<el-color-picker v-model="pageData.addForm.backColor" />
					</div>
				</div>
				<div class="rx-cs mb12">
					<span class="">标签描述：</span>
					<div class="w200">
						<el-input v-model="pageData.addForm.tagDesc" :rows="5" type="textarea" placeholder="请输入标签描述"></el-input>
					</div>
				</div>
		  </div>
		   <template #footer>
		    	<div class="dialog-footer">
		        	<el-button @click="pageData.addShow = false">取消</el-button>
		        	<el-button type="primary" @click="addLabelSave">确定</el-button>
		    	</div>
		    </template>
		</el-dialog>
		<!-- 跟进状态 -->
		<el-dialog
		    v-model="pageData.addFollowShow"
		    :title="pageData.addFollowTitle"
		 	width="400px"
		    :before-close="()=>{pageData.addFollowShow = false}"
		    custom-class="popwechat"
		   >
			<div class="pl20 pt20 pb20 pr20">
				<div class="rx-cc mb12">
					<span class="w80"><span class="color-red">*</span>跟进状态：</span>
					<div class="w200">
						<el-input v-model="pageData.addFollowForm.upStatusName" type="text" placeholder="请输入跟进状态名称" maxlength="10"></el-input>
					</div>
				</div>
				<div class="rx-cs mb12">
					<span class="w80 t_a_r">备注：</span>
					<div class="w200">
						<el-input v-model="pageData.addFollowForm.remark" :rows="5" type="textarea" placeholder="请输入备注"></el-input>
					</div>
				</div>
		  </div>
		   <template #footer>
		    	<div class="dialog-footer">
		        	<el-button @click="pageData.addFollowShow = false">取消</el-button>
		        	<el-button type="primary" @click="addFollowSave">确定</el-button>
		    	</div>
		    </template>
		</el-dialog>
		<!-- 会员来源 -->
		<el-dialog
		    v-model="pageData.addSourceShow"
		    :title="pageData.addSourceTitle"
		 	width="400px"
		    :before-close="()=>{pageData.addSourceShow = false}"
		    custom-class="popwechat"
		   >
			<div class="pl20 pt50 pb50 pr20">
				<div class="rx-cc mb12">
					<span class="w80"><span class="color-red">*</span>来源名称：</span>
					<div class="w200">
						<el-input v-model="pageData.addSourceName" type="text" placeholder="请输入跟进状态名称" maxlength="10"></el-input>
					</div>
				</div>
		  </div>
		   <template #footer>
		    	<div class="dialog-footer">
		        	<el-button  @click="pageData.addSourceShow = false">取消</el-button>
		        	<el-button  type="primary" @click="addSourceSave">确定</el-button>
		    	</div>
		    </template>
		</el-dialog>
		<!-- 收款方式 -->
		<el-dialog
		    v-model="pageData.payShow"
		    title="修改收款方式"
		 	width="700px"
		    :before-close="()=>{pageData.payShow = false}"
		    custom-class="popwechat"
		   >
			<div class="pl20 pt50 pb50 pr20">
				<CommonTip text="会员端小程序储值卡支付记为“储值卡”，小程序其他支付方式记为“小程序支付”。"></CommonTip>
				<div class="mt20">
					<el-checkbox-group v-model="pageData.checkPayList">
						<el-checkbox :disabled="item.id == 1" :label="item.id" v-for="item in pageData.allPayList" :key="item.id">{{item.payMethodName}}</el-checkbox>
					</el-checkbox-group>
				</div>
		  </div>
		   <template #footer>
		    	<div class="dialog-footer">
		        	<el-button @click="pageData.payShow = false">取消</el-button>
		        	<el-button type="primary" @click="paySave">确定</el-button>
		    	</div>
		    </template>
		</el-dialog>
		<Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave"/>
	</workArea>
</template>
<script>
  export default {
    name: 'BaseInfo'
  }
</script>
<script setup>
	import { ref, reactive, onMounted, onBeforeUnmount } from 'vue';
	import { ElMessage } from 'element-plus'
	import { Edit } from '@element-plus/icons-vue'
	import workArea from '@/components/branch-store/workArea.vue';
	import Confirm from '@/components/Confirm.vue'
	import CommonTip from '@/components/CommonTip.vue'
	import CommonTitle from '@/components/CommonTitle.vue'
	import {
		getCustomTag,
		customTagDelete,
		customTagInsert,
		customTagUpdate,
		followStatusManage,
		statusAdd,
		updateInterimUpStatus,
		deleteStatus,
		updateStatusStatus,
		channelAdd,
		channelEdit,
		channelDelete,
		getOtherSetting,
		payDetailToEdit,
		payDetailSave
	} from '@/api/storeCenter.js';
	import order from '@/views/branch-store/store/baseInfo/order.vue'; // 预约设置
	import entryndExit from '@/views/branch-store/store/baseInfo/entryndExit.vue'; // 入离场设置
	import messageSetting from '@/views/branch-store/store/baseInfo/messageSetting.vue'; // 消息设置
	import utils from '@/common/utils.js';
	const components = [order, entryndExit];
	const pageData = reactive({
		tabNum: '0',
		barlist:[{ name : '首页' },{ name : '机构中心' },{ name : '基础设置' }],// 面包屑
		
		confirmTitle:'',
		confirmType:'',
		
		tableData:[], //标签管理
		totalCount : 0,
		searchForm:{
			page:1,
			limit:15,
		},
		addShow:false,
		addTitle:'',
		addForm:{
			tagName:'',
			fontColor:'',
			backColor:'',
			tagDesc:'',
		},
		
		payList:[], //门店后台管理-收款方式
		allPayList:[],
		checkPayList:[],
		payShow:false,
		
		followTableData:[] ,//跟进状态管理
		followCount:0,
		addFollowShow:false,
		addFollowTitle:false,
		addFollowForm:{
			upStatusName: '',
			remark: ''
		},
		followSearchForm:{
			page: 1,
			limit: 15,
			timeIn: 3
		},
		
		sourceTableData: [] ,//会员来源管理
		sourceTableForm: {
			page: 1,
			limit: 15,
		},
		addSourceShow:false,
		addSourceTitle:false,
		addSourceName:'',
	})
	var menuId = [33200,33201,33202,33203,33204]; //会员有权限tab默认项
	for(var i = 0;i < menuId.length;i++){
		if(utils.getMenuRole(menuId[i])){
			pageData.tabNum = i.toString();
			break;
		}
	}  
	const confirmRef = ref();
	const payIconList = {
		0: require('@/assets/payIcon/payIcon0.png'), 
		1: require('@/assets/payIcon/payIcon1.png'), 
		2: require('@/assets/payIcon/payIcon2.png'), 
		3: require('@/assets/payIcon/payIcon3.png'), 
		4: require('@/assets/payIcon/payIcon4.png'), 
		8: require('@/assets/payIcon/payIcon8.png'), 
	}
	//切换tab
	function tabChangeEvent(tab){
		localStorage.setItem('baseInfo', tab.props.name)
		pageData.tabNum = tab.props.name
		if(pageData.tabNum == 0){
			
		}else if(pageData.tabNum == 1){
		}else if(pageData.tabNum == 2){
			searchLabelTable()
		}else if(pageData.tabNum == 3){
			getPayList()
		}else if(pageData.tabNum == 4){
			searchFollowTable()
			getSettingList(2)
		}
	}
	// 标签管理
	//搜索table
	function searchLabelTable(){
		pageData.searchForm.page = 1;
		getLabelList();
	}
	//切换分页数量
	function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		searchLabelTable();
	}
	function getLabelList(){
		
		getCustomTag(pageData.searchForm).then((res)=>{
			if(res.code == 0){
				pageData.tableData = res.data;
				pageData.totalCount = res.count;
			}
		})
	}
	// 新增标签
	var labelId = '';
	function addOrUpdateLabel(row){
		labelId = row.encryptionId || '';
		pageData.addShow = true;
		pageData.addTitle = row ? '编辑标签' : '新增标签';
		for(var key in pageData.addForm){
			pageData.addForm[key] = row[key] || '';
		}
		pageData.addForm.fontColor = row ? '#'+row.fontColor : '';
		pageData.addForm.backColor = row ? '#'+row.backColor : '';
	}
	//新增保存
	function addLabelSave(){
		var formData = JSON.parse(JSON.stringify(pageData.addForm));
		if(!formData.tagName){
			ElMessage({
				type: 'warning',
				message: '请输入标签名称',
			})
			return false;
		}
		if(!formData.fontColor){
			ElMessage({
				type: 'warning',
				message: '请选择字体颜色',
			})
			return false;
		}
		if(!formData.backColor){
			ElMessage({
				type: 'warning',
				message: '请选择背景颜色',
			})
			return false;
		}
		if(labelId){
			formData.encryptionId = labelId;
			customTagUpdate(formData).then((res)=>{
				if(res.code == 0){
					ElMessage({
						type: 'success',
						message: '编辑成功',
					})
					pageData.addShow = false;
					searchLabelTable();
				}
			})
			return false;
		}
		customTagInsert(formData).then((res)=>{
			if(res.code == 0){
				ElMessage({
					type: 'success',
					message: '保存成功',
				})
				pageData.addShow = false;
				searchLabelTable();
			}
		})
	}
	// 删除标签
	function deleLabel(id){
		labelId = id;
		pageData.confirmTitle = '确定要删除该标签吗？';
		pageData.confirmType = 'deleLabel';
		confirmRef.value.show();
	}
	
	// 跟进管理
	//搜索table
	function searchFollowTable(){
		pageData.followSearchForm.page = 1;
		getFollowList();
	}
	//切换分页数量
	function followSizeChange(limit){
		pageData.followSearchForm.limit = limit;
		searchFollowTable();
	}
	//切换分页数量
	function sourceTableForm(limit){
		pageData.sourceTableForm.limit = limit;
		getSettingList(2)
	}
	function getFollowList(){
		followStatusManage(pageData.followSearchForm).then((res)=>{
			if(res.code == 0){
				pageData.followTableData = res.data;
				pageData.followCount = res.count;
			}
		})
	}
	// 新增跟进状态
	var followId = '';
	function addOrUpdateFollow(row){
		followId = row.encryptionId || '';
		pageData.addFollowShow = true;
		pageData.addFollowTitle = row ? '编辑跟进状态' : '新增跟进状态';
		for(var key in pageData.addFollowForm){
			pageData.addFollowForm[key] = row[key] || '';
		}
	}
	//新增保存
	function addFollowSave(){
		var formData = JSON.parse(JSON.stringify(pageData.addFollowForm));
		if(!formData.upStatusName){
			ElMessage({
				type: 'warning',
				message: '请输入跟进状态名称',
			})
			return false;
		}
		if(followId){
			formData.encryptionId = followId;
			updateInterimUpStatus(formData).then((res)=>{
				if(res.code == 0){
					ElMessage({
						type: 'success',
						message: '编辑成功',
					})
					pageData.addFollowShow = false;
					searchFollowTable();
				}
			})
			return false;
		}
		statusAdd(formData).then((res)=>{
			if(res.code == 0){
				ElMessage({
					type: 'success',
					message: '保存成功',
				})
				pageData.addFollowShow = false;
				searchFollowTable();
			}
		})
	}
	// 删除跟进状态
	function deleFollow(id){
		followId = id;
		pageData.confirmTitle = '确定要删除该跟进状态吗？';
		pageData.confirmType = 'deleFollow';
		confirmRef.value.show();
	}
	//跟进状态开启。关闭
	function updateFollowState(row){
		updateStatusStatus({
			encryptionId:row.encryptionId,
			status:row.status == 1 ? 1 : 2
		}).then((res)=>{
			if(res.code == 0){
				ElMessage({
				  type: 'success',
				  message: row.status == 1 ?'开启成功':'关闭成功',
				})
				searchFollowTable();
			}
		})
	}
	
	
	
	// 会员来源
	// 新增会员来源
	var sourceId = '';
	function addOrUpdateSource(row){
		sourceId = row.encryptionId || '';
		pageData.addSourceShow = true;
		pageData.addSourceTitle = row ? '编辑跟进状态' : '新增跟进状态';
		pageData.addSourceName = row.channelName || '';
	}
	//新增保存
	function addSourceSave(){
		if(!pageData.addSourceName){
			ElMessage({
				type: 'warning',
				message: '请输入来源名称',
			})
			return false;
		}
		if(sourceId){
			channelEdit({
				encryptionId:sourceId,
				channelName:pageData.addSourceName
			}).then((res)=>{
				if(res.code == 0){
					ElMessage({
						type: 'success',
						message: '编辑成功',
					})
					pageData.addSourceShow = false;
					getSettingList(2);
				}
			})
			return false;
		}
		channelAdd({
			channelName:pageData.addSourceName
		}).then((res)=>{
			if(res.code == 0){
				ElMessage({
					type: 'success',
					message: '保存成功',
				})
				pageData.addSourceShow = false;
				getSettingList(2);
			}
		})
	}
	// 删除会员来源
	function deleSource(id){
		sourceId = id;
		pageData.confirmTitle = '确定要删除该来源吗？';
		pageData.confirmType = 'deleSource';
		confirmRef.value.show();
	}
	function confirmSave(){
		if(pageData.confirmType == 'deleLabel'){
			customTagDelete({
				encryptionId:labelId
			}).then((res)=>{
				if(res.code == 0){
					ElMessage({
						type: 'success',
						message: '删除成功',
					})
					searchLabelTable();
				}
			})
		}else if(pageData.confirmType == 'deleFollow'){
			deleteStatus({
				encryptionId:followId
			}).then((res)=>{
				if(res.code == 0){
					ElMessage({
						type: 'success',
						message: '删除成功',
					})
					searchFollowTable();
				}
			})
		}else if(pageData.confirmType == 'deleSource'){
			channelDelete({
				encryptionId:sourceId
			}).then((res)=>{
				if(res.code == 0){
					ElMessage({
						type: 'success',
						message: '删除成功',
					})
					getSettingList(2);
				}
			})
		}
	}
	
	//修改收款方式
	function updatePayType(row){
		pageData.payShow = true;
	}
	
	function getPayList(){
		payDetailToEdit().then((res)=>{
			if(res.code == 0){
				pageData.payList = res.data;
				pageData.checkPayList = res.data.map(n=>n.payId);
				pageData.allPayList = res.remark;
			}
		})
	}
	function paySave(){
		payDetailSave({
				payIds:pageData.checkPayList.join(',')
			}).then((res)=>{
				if(res.code == 0){
					ElMessage({
						type: 'success',
						message: '修改成功',
					})
					pageData.payShow = false;
					getPayList();
				}
			})
	}
	 
	//所以设置列表
	function getSettingList(type){
		let params = {
			type: type,
			limit: pageData.sourceTableForm.limit,
			page: pageData.sourceTableForm.page
		}
		getOtherSetting(params).then((res)=>{
			if(res.code == 0){
				pageData.sourceTableData = res.data.channelList
			}
		})
		
	}
	onMounted(()=> {
        if (localStorage.getItem('baseInfo') != null) {
            pageData.tabNum = localStorage.getItem('baseInfo')
		    if(pageData.tabNum == 0){
			  
			}else if(pageData.tabNum == 1){
			}else if(pageData.tabNum == 2){
				searchLabelTable();
			}else if(pageData.tabNum == 3){
				getPayList();
			}else if(pageData.tabNum == 4){
				searchFollowTable();
				getSettingList(2);
			}
	    }
	})
    onBeforeUnmount(() => {
		localStorage.removeItem('baseInfo')
	})
</script>

<style scoped lang="stylus">
	.itemBorder{
		border 1px solid #ccc;
	}
</style>
