<template>
    <workArea :barlist="barlist">
        <div class="main posi-r">
            <div class="box posi-a rx-c">
                <el-card class="c_p card" :class="index == pageData.cardList.length-1 ? '' : 'mr20' " 
                    v-for="(item, index) in pageData.cardList" :key="index" :body-style="{ padding: '0px' }"
                    @click="addClass(item.type)"
                >
                    <div class="img">
                        <img class="ww100 hh100" v-if="item.type == 11" src="@/assets/fixedimg.png"/>
                        <img class="ww100 hh100" v-else src="@/assets/bookingimg.png"/>
                    </div>
                    <div class="p20 pt12 pb40">
                        <span class="color-333 ft16">{{ item.text }}</span>
                    </div>
                </el-card>
            </div>
        </div>
    </workArea>
</template>

<script setup>
    import { reactive} from 'vue';
    import workArea from '@/components/branch-store/workArea.vue';
    import { useRouter } from 'vue-router'

    const router = useRouter()
    const barlist = reactive([{ name : '返回',type:0 },{ name : '新增班级' }]); // 面包屑
    const pageData = reactive({
        cardList: [{
            type: 11,
            name: '固定上课模式',
            text: '有固定课节数，会员需完全按照排课规律上课，报名后将自动预约全部课节，且不可取消预约（支持请假）'
        },{
            type: 12,
            name: '自由预约模式',
            text: '场馆可自由排课，会员购买后，可自主预约想上的课节，适用于课程进度体系不强的日常训练式课程'
        }]
    })
    /**
     * 跳转到添加表单
     */
    function addClass(type) {
        router.push({ path: '/branch/classManage/addClassPage', query :{ type: type } })
    }
</script>

<style scoped>
    .main {
        width: 100%;
        height: 86vh;
    }
    .box{
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: #F7F6FB;
       
    }
    .box .card{
        width: 378px;
        height: 356px;
    }
    .img {
        height: 220px;
    }
</style>
