import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// import 'mpvue-calendar/src/style.css'
import './common/style.css'
import router from './router/index.js'
import store from './store/index.js'
import App from './App.vue'
import locale from 'element-plus/lib/locale/lang/zh-cn';
import Unicon from 'vue-unicons' //图标库
import { uniTrashAlt, uniEditAlt, uniAlignCenterV,uniTimes,uniQuestionCircle } from 'vue-unicons/dist/icons' //引入用的到图标
Unicon.add([uniTrashAlt, uniEditAlt, uniAlignCenterV,uniTimes,uniQuestionCircle ])
const app = createApp(App)
app.use(ElementPlus,{ locale })
app.use(router)
app.use(store)
app.use(Unicon)
app.mount('#app')
