<template>
	<!-- 会员签单 -->
	<el-drawer
	    :title="typeName[propData.type]"
	    direction="rtl"
		size="initial"
		@opened="opened"
		:before-close="close"
	  >
		<div class="w450 hh100 drawer_box o_a" style="padding-bottom: 130px;">
			<div class="pl20 pr20 rx-sc" v-if="propData.info && propData.type != 99">
				<img class="w60 h60 mr10 b_r_100" :src="propData.info.headImgUrl" alt="">
				<div>
					<h3 class="mb10">{{propData.info.realName}}</h3>
					<p>{{propData.info.telephoneNum}}</p>
				</div>
			</div>
			<div v-else-if="propData.type != 99" class="mb16 pl20 pr20">
				<div v-if="pageData.addMemberShow" class="rx-sc">
					<span class="w85 t_a_r"><span class="color-red">*</span>选择会员：</span>
					<div class="flex1">
						<el-select
						   	class="query_con addMember"
						    v-model="pageData.memberId"
						    filterable
						    placeholder="请输入会员名称和手机号搜索"
						    :remote-method="searchMember"
							@change="memberChange"
						>
						    <el-option v-for="item in pageData.memberList" :key="item.encryptionId" :label="item.realName" :value="item.encryptionId"></el-option>
						</el-select>
						<a href="javascript:;" class="color-409EFF tool_btn ml10"  v-if="pageData.addMemberShow" @click="pageData.addMemberShow = !pageData.addMemberShow">新增会员</a>
					</div>
				</div>
				<div v-else>
					<div class="rx-sc mb16">
						<span class="w85 t_a_r"><span class="color-red">*</span>会员名称：</span>
						<el-input class="flex1" v-model="pageData.realName" placeholder="请输入会员姓名" />
					</div>
					<div class="rx-sc mb16">
						<span class="w85 t_a_r"><span class="color-red">*</span>手机号：</span>
						<el-input class="flex1" v-model="pageData.telephoneNum" placeholder="请输入会员手机号" />
					</div>
				</div>
			</div>
			<CommonTitle :text="propData.type != 99 ?' 购买信息' : '续课信息'"/>
			<div class="pl20 pr20">
				<div class="rx-sc mb16" v-if="propData.type != 99">
					<span class="w85 t_a_r"><span class="color-red">*</span>选择商品：</span>
					<div class="flex1">
						<el-select class="ww100" v-model="pageData.goodsInfo.encryptionProductId" placeholder="请选择" @change="prodcutChange">
							<el-option v-for="item in pageData.productList" :key="item.encryptionId" :label="item.productName" :value="item.encryptionId">
							</el-option>
						</el-select>
					</div>
				</div>
				<div v-if="propData.type == 13 && Object.keys(pageData.cycleInfo).length>0">
					<div class="rx-sc mb16">
						<div class="w85 t_a_r h30"></div>
						<div class="flex1">
							<el-row>
 							  <el-col :span="12">
								<div class="color-666 mb10">
									<span class="w70 t_a_r d_i_b">所上课程：</span>
									<span>{{ pageData.cycleInfo.courseName}}</span>
								</div>
							  </el-col>
 							  <el-col :span="12">
								<div class="color-666 mb10">
									<span class="w75 t_a_r d_i_b">总课时/节：</span>
									<span>{{ pageData.cycleInfo.cycleNum }}</span>
								</div>
							  </el-col>
 							  <el-col :span="12">
								<div class="color-666">
									<span class="w70 t_a_r d_i_b">班级状态：</span>
									<span v-if="utils.expired(pageData.cycleInfo.cycleStartCourseTimeStr) > 0">未开始</span>
									<span v-else>已进行{{ pageData.cycleInfo.courseHasCount }}课节</span>
								</div>
							  </el-col>
 							  <el-col :span="12">
								<div class="color-666">
									<span class="w75 t_a_r d_i_b">班级模式：</span>
									<span>{{ pageData.cycleInfo.cycleType == 11 ? '固定上课模式' :'自主预约模式'}}</span>
								</div>
							  </el-col>
 							</el-row>
						</div>
					</div>
					<div class="rx-c">
						<div class="w85 t_a_r">购买课时：</div>
						<div class="rx-sc">
							<el-input-number class="w240 t_a_l" v-model="pageData.goodsInfo.productNum" :min="0" :max="9999" :step="1" :controls="false" @change="changeProductNum" />
						</div>
					</div>
					<div class="rx-c mb16">
						<div class="w85 t_a_r h30"></div>
						<div class="rx-sc">
							<span class="color-666" v-if="pageData.cycleInfo.cycleType == 11">会员将从第{{ utils.expired(pageData.cycleInfo.cycleStartCourseTimeStr) > 0 ? 1 : pageData.cycleInfo.courseHasCount+1 }}节开始进入班级课节</span>
							<span class="color-666" v-if="pageData.cycleInfo.cycleType == 12">会员购买后需预约后 进入课节</span>
						</div>
					</div>
				</div>
				<div v-if="propData.type <= 4 && pageData.goodsInfo.encryptionProductId">
					<!-- 卡项 -->
					<div class="rx-sc mb16" v-show="(propData.type == 1 || propData.type == 2 || propData.type == 4) && pageData.goodsInfo.productTyp == 1">
						<span class="w85 t_a_r">{{propData.type == 1 ? '课程' : propData.type == 2 ? '入场' : '体验'}}卡次数：</span> 
						<div class="flex1">
							<el-input v-model="pageData.goodsInfo.productNum" @input="(val)=>{numberInput(val,'productNum')}" type="text" placeholder="请输入">
								<template #append>次</template>
							</el-input>
						</div>
					</div>
					<div class="rx-sc mb16" v-show="!(pageData.goodsInfo.productTyp == 1 && !pageData.goodsInfo.productCycle)">
						<span class="w85 t_a_r">消费时长：</span>
						<div class="flex1">
							<el-input v-model="pageData.goodsInfo.productCycle" @input="(val)=>{numberInput(val,'productCycle')}" type="text" placeholder="请输入">
								<template #append>{{pageData.goodsInfo.ifMonth === 1 ? '天' : pageData.goodsInfo.ifMonth === 2 ? '月' : '年'}}</template>
							</el-input>
						</div>
					</div>
					<div class="rx-sc mb16" v-show="!(pageData.goodsInfo.productTyp == 1 && !pageData.goodsInfo.productCycle)">
						<span class="w85 t_a_r">时长开始：</span>
						<div class="flex1">{{pageData.goodsInfo.productTimeType == 1 ? '从购买之日起算' : '第一次消费起算'}}</div>
					</div> 
					<div class="rx-sc mb16" v-show="propData.type == 3">
						<span class="w85 t_a_r">储值金额：</span>
						<div class="flex1">
							<el-input v-model="pageData.goodsInfo.storeCardValue" @input="(val)=>{numberInput(val,'storeCardValue')}" type="text" placeholder="请输入">
								<template #append>元</template>
							</el-input>
						</div>
					</div>
					<div class="rx-sc mb16">
						<span class="w85 t_a_r">售卖价格：</span>
						<div class="flex1">
							<el-input v-model="pageData.goodsInfo.productPrice" @input="(val)=>{numberInput(val,'productPrice')}" type="text" placeholder="请输入">
								<template #append>元</template>
							</el-input>
						</div>
					</div>
				</div>
				<!-- 课程 -->
				<div v-if="propData.type > 4 && propData.type != 13 && pageData.goodsInfo.encryptionProductId &&  propData.type != 99">
					<div class="rx-c mb16">
						<div class="mr30">
							<span class="d_i_b t_a_r w85">课程单价：</span>
							<span>{{pageData.goodsInfo.productPrice}}</span>
						</div>
						<div class="rx-sc">
							<span class="w85 t_a_r">单课时长：</span>
							<span>{{pageData.goodsInfo.courseTime}}分钟</span>
						</div>
					</div>
					<div class="rx-sc mb16">
						<span class="w85 t_a_r"><span class="color-red">*</span>购买数量：</span>
						<div class="flex1">
							<el-input v-model="pageData.goodsInfo.productNum" @input="(val)=>{numberInput(val,'productNum')}" type="text" placeholder="请输入">
								<template #append>节</template>
							</el-input>
						</div>
					</div>
					<div class="rx-sc mb16">
						<span class="w85 t_a_r">赠送数量：</span>
						<div class="flex1">
							<el-input v-model="pageData.goodsInfo.buyGiveNum" @input="(val)=>{numberInput(val,'buyGiveNum')}" type="text" placeholder="请输入">
								<template #append>节</template>
							</el-input>
						</div>
					</div>
				</div>
				<div v-if="propData.type == 99">
					<div class="rx-c mb16">
						<span class="d_i_b t_a_r w85">所在班级：</span>
						<span>{{ pageData.cycleInfo.cycleName }}</span>
					</div>
					<div></div>
					<div class="rx-ss mb16">
						<span class="w85 t_a_r">班级信息：</span>
						<div>
							<div class="mb16">
								<span class="color-666">所上课程：{{ pageData.cycleInfo.courseName }} </span>
							</div>
							<div class="mb16">
								<span class="color-666">总课时/节：{{ pageData.cycleInfo.cycleNum }}</span>
							</div>
							<div class="mb16">
								<span class="color-666">班级状态：</span>
								<span class="color-666" v-if="utils.expired(pageData.cycleInfo.cycleStartCourseTimeStr) > 0">未开始</span>
							    <span class="color-666" v-else>已进行{{ pageData.cycleInfo.courseHasCount }}课节</span>
							</div>
							<div>
								<span class="color-666">班级模式：自主预约模式</span>
							</div>
						</div>
					</div>
					<div class="rx-c mb16">
						<div class="w85 t_a_r"><span class="color-red">*</span>续课课时：</div>
						<div class="rx-sc">
							<el-input-number class="w240 t_a_l" v-model="pageData.goodsInfo.productNum" :min="0" :max="9999" :step="1" :controls="false" @change="changeProductNum" />
						</div>
					</div>
				</div>
				<div class="rx-sc mb16">
					<span class="w85 t_a_r">减免金额：</span>
					<div class="flex1">
						<el-input v-model="pageData.goodsInfo.reducePrice" @input="(val)=>{numberInput(val,'reducePrice')}" type="text" placeholder="请输入">
							<template #append>元</template>
						</el-input>
					</div>
				</div>
			</div>
			<CommonTitle text="收款信息"/>
			<div class="pl20 pr20">
				<div class="rx-sc mb16">
					<span class="w85 t_a_r"><span class="color-red">*</span>收款方式：</span>
					<div class="flex1">
						<el-select @change="payMethodChange" v-model="pageData.goodsInfo.payMethodId" placeholder="请选择">
							<el-option v-for="item in pageData.payMethodList" :key="item.payId" :label="item.payMethodName" :value="item.payId">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="rx-sc mb16" v-show="pageData.goodsInfo.payMethodId == 101">
					<span class="w85 t_a_r">储值卡：</span>
					<div class="flex1">
						<el-select v-model="pageData.goodsInfo.storeCardOrderIdStr" placeholder="请选择">
							<el-option v-for="item in pageData.storeCardList" :key="item.encryptionId" :label="item.productName+'-余额'+item.storeMoneyAll" :value="item.encryptionId">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="rx-sc mb16">
					<span class="w85 t_a_r">销售员：</span>
					<div class="flex1">
						<el-select v-model="pageData.goodsInfo.saleUserId" placeholder="请选择">
							<el-option v-for="item in pageData.saleUserList" :key="item.id" :label="item.realName" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="rx-ss mb16">
					<span class="w85 t_a_r">备注：</span>
					<div class="flex1">
						<el-input
							v-model="pageData.goodsInfo.remark"
							:rows="5"
							type="textarea"
							maxlength="300"
    						show-word-limit
							placeholder="请输入备注"
						  />
					</div>
				</div> 
			</div>
		</div>
		<div class="total_box bg-fff">
			<h2 class="ft18 mb12">合计：</h2>
			<div class="rx-bc mb12" v-show="propData.type > 4">
				<span class="flex1">购买课节：{{ pageData.goodsInfo.productNum }}</span>
				<span class="flex1">赠送课节：{{ pageData.goodsInfo.buyGiveNum }}</span>
				<span class="flex1"></span>
			</div>
			<div class="rx-bc mb12">
				<b class="color-000 flex1">应付金额：{{ pageData.shouldMoney ? '￥'+ pageData.shouldMoney : '-'}}</b>
				<b class="color-000 flex1">减免金额：{{ pageData.goodsInfo.reducePrice ? '￥'+ pageData.goodsInfo.reducePrice : '-' }}</b>
				<b class="color-red flex1">实收金额：{{ pageData.goodsInfo.actualMoney ? '￥'+ pageData.goodsInfo.actualMoney : '-' }}</b>
			</div>
		</div>
		<div class="drawer_btn">
			<el-button @click="close">取消</el-button>
			<el-button type="primary" @click="save">确定</el-button>
		</div>
	</el-drawer>
</template>

<script setup>
	import { ref, reactive, resolveComponent } from 'vue';
	import { ElMessage, ElMessageBox} from 'element-plus'
	import CommonTitle from '@/components/CommonTitle.vue'
	import {  listSimple, listCourseSimple, productBuy, productBuyCourse, listStoreCard4Order, selectStoreMembersSimple, buyCourseCycle, buyCourseCycleAdd } from '@/api/member.js'
	import { getStoreAccountStaffSimple } from '@/api/staff.js'
	import { newUserInsert } from '@/api/intentionList.js'
	import { payDetailToEdit } from '@/api/storeCenter.js'
	import { courseCycleList } from '@/api/course.js'
	import utils from '@/common/utils.js';
	
	import { getPayCode } from '@/api/pay.js'
	const emit = defineEmits(['close','save']);
	const propData = defineProps({
		type: String, //0->6 商品类型 99 续课
		info: Object, //会员详情
		from: Number, // 从哪里进来 1-前台 2-正式会员列表 3-会员详情 8-会员列表会员课程
		cycleId: Number, // 班级id,
		memberId: String
	})
	
	const typeName = {
		1:'课程卡签单',
		2:'入场卡签单',
		3:'储值卡签单',
		4:'体验卡签单',
		11:'团体课签单',
		12:'私教课签单',
		13:'小班课签单',
		99: '小班课续课'
	}
	const pageData = reactive({
		addMemberShow: true,
		goodsInfo:{
			encryptionProductId:'',
			productNum:'',
			productTyp:'',
			courseTime:'',
			productCycle:'',
			ifMonth:'',
			productPrice:'',
			productTimeType:'',
			storeCardValue:'',
			reducePrice:'',
			payMethodId:'',
			saleUserId:'',
			remark:'',
			actualMoney:'',
			buyGiveNum:'',
			storeCardOrderIdStr:'',
		},
		realName:'',
		telephoneNum:'',
		shouldMoney:'',
		productList:[],
		saleUserList:[],
		payMethodList:[],
		storeCardList:[],
		memberList:[],
		memberId:'',
		cycleInfo: {}
	})
	//搜索会员
	searchMember()
	function searchMember(){
		selectStoreMembersSimple({page: 1, limit: 9999 }).then((res)=>{
			if(res.code == 0){
				pageData.memberList = res.data;
			}
		})
	}
	function memberChange(val){
		if (val) {
			initForm();
			getStoreCard(pageData.memberId);
			getSysUser();
			pageData.cycleInfo = {}
		}
	}
	
	function prodcutChange(val){
		var arr = pageData.productList.filter(n=>n.encryptionId == val);
		pageData.cycleInfo = arr[0]
		for(var k in pageData.goodsInfo){
			if(k != 'encryptionProductId' && k != 'payMethodId' && k != 'saleUserId'){
				pageData.goodsInfo[k] = arr[0][k];
			}
		}
		if (propData.type == 13) {
			if (utils.expired(pageData.cycleInfo.cycleStartCourseTimeStr) > 0) {
				pageData.goodsInfo.productNum = pageData.cycleInfo.cycleNum
			} else {
				pageData.goodsInfo.productNum = pageData.cycleInfo.cycleNum - pageData.cycleInfo.courseHasCount
			}
			if (pageData.cycleInfo.cycleType == 11) {
				pageData.goodsInfo.productPrice = (pageData.cycleInfo.sellPrice/pageData.cycleInfo.cycleNum).toFixed(4)
			}
			if (pageData.cycleInfo.cycleType == 12) {
				pageData.goodsInfo.productPrice = pageData.cycleInfo.sellPrice
			}
		}
		if (propData.type == 99) {
			if (pageData.cycleInfo.cycleType == 12) {
				pageData.goodsInfo.productPrice = pageData.cycleInfo.sellPrice
			}
			pageData.goodsInfo.encryptionProductId = val
		}
		calculatedAmount();
	}
	function close(){
		pageData.addMemberShow = true
		pageData.cycleInfo = {}
		emit('close');
	}
	function save(){
		var formData = JSON.parse(JSON.stringify(pageData.goodsInfo));
		formData.encryptionId = propData.info ? propData.info.encryptionId : pageData.memberId
		if (propData.type != 99 &&!propData.info) {
			if ( pageData.addMemberShow  && !formData.encryptionId) {
				ElMessage({
					type: 'warning',
					message: '请选择会员'
				})
				return false;
		   }
		   if (!pageData.addMemberShow && !pageData.realName) {
			    ElMessage({
					type: 'warning',
					message: '请输入会员姓名'
				})
				return false;
		   }
		   if (!pageData.addMemberShow && !pageData.telephoneNum) {
			   ElMessage({
					type: 'warning',
					message: '请输入会员手机号'
				})
				return false;
		   }
		}
		if(propData.type != 99 && !formData.encryptionProductId){
			ElMessage({
				type: 'warning',
				message: '请选择商品'
			})
			return false;
		}
		if(!pageData.goodsInfo.payMethodId){
			ElMessage({
				type: 'warning',
				message: '请选择收款方式'
			})
			return false;
		}
		if(propData.type > 4 && propData.type != 13 && propData.type != 99 && !pageData.goodsInfo.productNum){
			ElMessage.warning('请输入购买数量')
			return false;
		}
		if (propData.type == 99 && !pageData.goodsInfo.productNum) {
			ElMessage.warning('请输入续课数量')
			return false;
		}
		if(pageData.goodsInfo.payMethodId == 101){ //判断储值卡余额是否充足
			var cardInfo = pageData.storeCardList.filter(n=>n.encryptionId == pageData.goodsInfo.storeCardOrderIdStr);
			if(Number(cardInfo[0].storeMoneyAll) < Number(pageData.goodsInfo.actualMoney)){
				ElMessage({
					type: 'warning',
					message: '储值卡余额不足'
				})
				return false;
			}
		}
		if (!propData.info && !pageData.addMemberShow) {
			let params = {
				realName: pageData.realName,
				telephoneNum: pageData.telephoneNum,
				trialFlag: 2,
				buyAdd:1
			}
			newUserInsert(params).then((res)=>{
				if(res.code == 0){
					formData.encryptionId = res.remark
					if (res.data) {
                        ElMessageBox.confirm('检测到会员信息在'+ res.data == 1 ? '其他门店已存在' : '本门店已存在' +'，请直接同步信息！', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning',
                        }).then(() => {
							params.forceAdd = 1
							newUserInsert(params).then((res)=>{
								if(res.code == 0){
									signature(formData)
								}
							})
                        })
					} else {
                        signature(formData)
					}
				}
			})
		} else {
			signature(formData)
		}
		
	}
	/**
	 * 确定签章
	 */
	function signature(formData) {
       	if(propData.type < 5){ //买卡
			productBuy(formData).then((res)=>{
			    if(res.code == 0){
					if (pageData.goodsInfo.payMethodId == 100) {
						if (pageData.goodsInfo.actualMoney  == 0.00) {
							ElMessage({
			    				type: 'success',
			    				message: '签单成功'
			    			})
							pageData.addMemberShow = true
			    			emit('save');
						}else {
							if (propData.info) {
								window.open('/#/branch/payCourseMoney?from=' + propData.from + '&info=' + encodeURIComponent(JSON.stringify(res.data)) + '&id=' + propData.info.encryptionId, '_blank')
							} else {
								window.open('/#/branch/payCourseMoney?from=' + propData.from + '&info=' + encodeURIComponent(JSON.stringify(res.data)), '_blank')
							}
						}
					} else {
						ElMessage({
			    			type: 'success',
			    			message: '签单成功'
			    		})
						pageData.addMemberShow = true
			    		emit('save');
					}
			    }
			})
		} else if (propData.type == 13) {
			buyCourseCycle(formData).then((res)=>{
				if(res.code == 0){
					if (res.data == 6) {
						ElMessageBox.confirm('该课程与会员已有课程冲突，是否确认购买？', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning',
                        }).then(() => {
							formData.ifRepeat = 1
							buyCourseCycle(formData).then((res)=>{
								if (res.code == 0) {
									if (pageData.goodsInfo.payMethodId == 100) {
										if (pageData.goodsInfo.actualMoney  == 0.00) {
											ElMessage.success('签单成功')
											pageData.addMemberShow = true
			    							emit('save');
										} else {
											if (propData.info) {
												window.open('/#/branch/payCourseMoney?from=' + propData.from + '&info=' + encodeURIComponent(JSON.stringify(res.data)) + '&id=' + propData.info.encryptionId, '_blank')
											} else {
												window.open('/#/branch/payCourseMoney?from=' + propData.from + '&info=' + encodeURIComponent(JSON.stringify(res.data)), '_blank')
											}
										}
									} else {
										ElMessage.success('签单成功')
										pageData.addMemberShow = true
										emit('save');
									}
								}
							})
                        })
					} else {
						if (pageData.goodsInfo.payMethodId == 100) {
							if (pageData.goodsInfo.actualMoney  == 0.00) {
								ElMessage.success('签单成功')
								pageData.addMemberShow = true
			    				emit('save');
							} else {
								if (propData.info) {
									window.open('/#/branch/payCourseMoney?from=' + propData.from + '&info=' + encodeURIComponent(JSON.stringify(res.data)) + '&id=' + propData.info.encryptionId, '_blank')
								} else {
									window.open('/#/branch/payCourseMoney?from=' + propData.from + '&info=' + encodeURIComponent(JSON.stringify(res.data)), '_blank')
								}
							}
						} else {
							ElMessage.success('签单成功')
							pageData.addMemberShow = true
							emit('save');
						}
					}
					
				}
			})
		}  else if (propData.type == 99) {
			buyCourseCycleAdd(formData).then((res)=>{
				if(res.code == 0){
					if (pageData.goodsInfo.payMethodId == 100) {
						if (pageData.goodsInfo.actualMoney  == 0.00) {
							ElMessage.success('续课成功')
							pageData.addMemberShow = true
			    			emit('save');
						} else {
							if (propData.info) {
								window.open('/#/branch/payCourseMoney?from=' + propData.from + '&info=' + encodeURIComponent(JSON.stringify(res.data)) + '&id=' + propData.info.encryptionId, '_blank')
							} else {
								window.open('/#/branch/payCourseMoney?from=' + propData.from + '&info=' + encodeURIComponent(JSON.stringify(res.data)), '_blank')
							}
						}
					} else {
						ElMessage.success('续课成功')
						pageData.addMemberShow = true
						emit('save');
					}
				}
			})
		} else {  //买课
			productBuyCourse(formData).then((res)=>{
				if(res.code == 0){
					if (pageData.goodsInfo.payMethodId == 100) {
						if (pageData.goodsInfo.actualMoney  == 0.00) {
							ElMessage.success('签单成功')
							pageData.addMemberShow = true
			    			emit('save');
						} else {
							if (propData.info) {
								window.open('/#/branch/payCourseMoney?from=' + propData.from + '&info=' + encodeURIComponent(JSON.stringify(res.data)) + '&id=' + propData.info.encryptionId, '_blank')
							} else {
								window.open('/#/branch/payCourseMoney?from=' + propData.from + '&info=' + encodeURIComponent(JSON.stringify(res.data)), '_blank')
							}
						}
					} else {
						ElMessage.success('签单成功')
						pageData.addMemberShow = true
						emit('save');
					}
				}
			})
		}
	}
	// 数字校验
	function numberInput(e,key){
		if(isNaN(Number(e))){
			pageData.goodsInfo[key] = '';
		} else {
			//只能输入数字
			e = ('' + e) // 第一步：转成字符串
			.replace(/[^\d^\.]+/g, '') // 第二步：把不是数字，不是小数点的过滤掉
         	.replace(/^0+(\d)/, '$1') // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
         	.replace(/^\./, '0.') // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
         	.match(/^\d*(\.?\d{0,2})/g)[0] || '' // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，	而且小数点后面只能有0到2位小数
		}
		pageData.goodsInfo[key] = e
		if(key == 'reducePrice' && propData.type <=4 && Number(pageData.goodsInfo.reducePrice) > Number(pageData.goodsInfo.productPrice)){ //卡减免金额单独处理			
			pageData.goodsInfo[key] = '';
			ElMessage({
				type: 'warning',
				message: '减免金额不能大于售卖价格'
			})
		}
		calculatedAmount();
	}
	function changeProductNum() {
		calculatedAmount();
	}
	//计算金额
	function calculatedAmount(){
		if(propData.type <=4){ //卡类型应付金额计算方式
			pageData.shouldMoney = Number(pageData.goodsInfo.productPrice || 0).toFixed(2);
		}else{  //课类型应付金额计算方式
			pageData.shouldMoney = (Number(pageData.goodsInfo.productPrice || 0) * Number(pageData.goodsInfo.productNum || 0)).toFixed(2); //数量*单价=应付
		}
		pageData.goodsInfo.actualMoney = (pageData.shouldMoney - Number(pageData.goodsInfo.reducePrice || 0)).toFixed(2); //应付-减免=实付
	}
	
	//获取可用消费的储值卡
	function getStoreCard(stuId){
		listStoreCard4Order({
			encryptionId:stuId,
			consumeType: propData.type < 5 ? 5 : propData.type == 11 ? 4 : propData.type == 12 ? 3 : 8 
		}).then((res)=>{
			if(res.code == 0){
				if(res.data.length > 0){
					pageData.storeCardList = res.data
					if (propData.type != 3) {
						pageData.payMethodList.push({
							payMethodName:'储值卡',
							payId:101
						})
						let hash = {}; 
						pageData.payMethodList = pageData.payMethodList.reduce((preVal, curVal) => {
							hash[curVal.payId] ? '' : hash[curVal.payId] = true && preVal.push(curVal); 
							return preVal 
						}, [])
					}
				}
			}
		})
	}
	//切换收款方式
	function payMethodChange(){
		pageData.goodsInfo.storeCardOrderIdStr = pageData.goodsInfo.payMethodId == 101 ? pageData.storeCardList[0].encryptionId : '';
	}
	//获取销售
	function getSysUser(){
		let params = {
            page:1,
            limit:1000,
            delFlag: 0
        }
		getStoreAccountStaffSimple(params).then((res)=>{
			if(res.code == 0){
				pageData.saleUserList = res.data;
				pageData.goodsInfo.saleUserId = res.data[0].id;
			}
		})
	}
	function initForm(){
		for(var key in pageData.goodsInfo){
			pageData.goodsInfo[key] = '';
		}
		pageData.shouldMoney = ''
	}
	
	function opened(){
		pageData.memberId = ''
		initForm();
		//获取商品列表
		if(propData.type <= 4){ //卡项商品
			listSimple({
				type:propData.type
			}).then((res)=>{
				if(res.code == 0){
					pageData.productList = res.data;
				}
			})
		} else if(propData.type == 13) {
			courseCycleList({
				page: 1,
            	limit: 9999,
				cycleState: 4,
				sellType: 2
			}).then((res)=>{
				if(res.code == 0){
					res.data.forEach(item => {
						item.productName = item.cycleName
					});
					pageData.productList = res.data;
				}
			})
		} else if (propData.type == 99) {
			courseCycleList({
				page: 1,
            	limit: 9999,
				cycleState: 4,
				sellType: 2
			}).then((res)=>{
				if(res.code == 0){
					res.data.forEach(item => {
						item.productName = item.cycleName
					});
					pageData.productList = res.data;
					res.data.forEach(item => {
						if (propData.cycleId == item.id) {
							prodcutChange(item.encryptionId)
						}
					});
				}
			})
			pageData.memberId = propData.memberId
		} else {
			listCourseSimple({
				courseType:propData.type
			}).then((res)=>{
				if(res.code == 0){
					pageData.productList = res.data;
				}
			})
		}
		//收款方式
		payDetailToEdit().then((res)=>{
			if(res.code == 0){
				getPayCode().then((resolve) => {
					if (resolve.code == 0) {
						pageData.payMethodList = res.data;
						if (resolve.data&&resolve.data.url) {
							pageData.payMethodList.push({
								payMethodName:'收款码',
								payId:100
							})
							pageData.goodsInfo.payMethodId = 100;
						}
					}
				})
				pageData.goodsInfo.payMethodId = res.data[0].payId;
				if(propData.type != 3){ //获取可用储值卡
					if(propData.info){
						getStoreCard(propData.info.encryptionId);
					}
				}
			}
		})
		getSysUser()
	}
</script>

<style lang="stylus" scoped>
	.total_box{
		position absolute;
		bottom 50px;
		left 0;
		right 0;
		padding 20px;
		border-top 1px solid #eee
	}
	.addMember {
		width: 78% !important;
	}
	.el-select-dropdown__item{
		width: 325px
	}
	/deep/ .el-input-number .el-input__inner{
	  text-align: left;
	}
</style>
