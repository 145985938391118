<template>
	<workArea :barlist="pageData.barlist">
	<div class="update_course_card bg-fff pl10 pr10 pt10 pb10 hh100 o_a" v-loading="pageData.loading">
		<CommonTitle text="基本信息"/>
		<div class="rx-sc mb25">
			<div class="rx-sc mr40">
				<span class="w120 t_a_r"><span class="color-red">*</span>储值卡名称：</span>
				<div class="w240">
					<!--  :disabled="id ? true : false" -->
					<el-input type="text" placeholder="请输入(限50字)" maxlength="50" v-model="pageData.formData.productName"></el-input>
				</div>
			</div>
			<div class="rx-sc mr40">
				<span class="w120 t_a_r"><span class="color-red">*</span>储值卡面额：</span>
				<div class="w240">
					<el-input type="text" placeholder="请输入(整数)" v-model="pageData.formData.storeCardValue" @blur="storeCardValueBlur" maxlength="6">
						<template #append>元</template>
					</el-input>
				</div>
			</div>
		</div>
		<div class="rx-sc mb25">
			<div class="rx-sc mr40">
				<span class="w120 t_a_r">卡消费时长：</span>
				<div class="w240 select-name">
						<el-input type="text" placeholder="请输入大于0的数字" v-model="pageData.formData.productCycle" @blur="productCycleBlur">
							<template #append>
								<el-select v-model="pageData.formData.ifMonth" style="width: 115px">
        						  	<el-option v-for="item in pageData.dateType" :key="item.id" :label="item.name" :value="item.id" />
        						</el-select>
						</template>
					</el-input>
				</div>
			</div>
			<div class="rx-sc mr40">
				<span class="w120 rx-ec">时长开始：</span>
				<div class="w300">
					<el-radio-group v-model="pageData.formData.productTimeType" :disabled="id ? true : false">
						<el-radio :label="1"><span class="color-666 fw400">从购买之日起算</span></el-radio>
						<el-radio :label="2"><span class="color-666 fw400">第一次消费起算</span></el-radio>
					</el-radio-group>
				</div>
			</div>
		</div>
		<div class="rx-ss mb10">
			<div class="rx-ss mr40">
				<span class="w120 t_a_r"><span class="color-red">*</span>可消费类目：</span>
				<div class="w800">
					<el-checkbox-group v-model="pageData.formData.consumeType">
						<template v-for="(item, index) in pageData.categoryList" :key="index">
							<el-checkbox class="mb10" :label="item.id" border>{{item.name}}</el-checkbox>
						</template>
					</el-checkbox-group>
				</div>
			</div>
		</div>
		<div class="mb25 o_h">
			<span class="w120 mt9 f_l t_a_r">
				<span class="color-red">*</span>
				<span>可消费门店</span>
				<span class="v_a_m d_i_b w15 h15 ml5">
					<el-tooltip
					    class="box-item"
					    effect="dark"
					    content="会员可使用本卡跨店消费"
					    placement="right-start"
					  >
					  <img class="w15 h15" src="@/assets/wenhao.png" >
					</el-tooltip> 
				</span>
				<span>：</span>
			</span>
			<div class="w500 f_l">
				<el-radio v-model="pageData.formData.consumeSid" label="0" size="large"><span class="color-666 fw400">全部门店</span></el-radio>
				<el-radio v-model="pageData.formData.consumeSid" label="1" size="large"><span class="color-666 fw400">多门店</span></el-radio>
				<div class="rx-wss mt10" v-show="pageData.formData.consumeSid == '1'">
					<template v-for="(item,index) in pageData.storeList" :key="index">
						<div class="category mb20 mr20" v-if="item.ifBranchStore == 1">
				 			<el-checkbox v-model="item.check" :label="item.id" >
			     		      	<span class="color-666 fw400">{{item.storeName}}</span>
			     		 	</el-checkbox>
				 		</div>
					</template>
				</div>
			</div>
		</div>
		<CommonTitle text="售卖信息"/>
		<div class="rx-sc mb25">
			<div class="rx-sc mr40">
				<span class="w120 t_a_r"><span class="color-red">*</span>销售金额：</span>
				<div class="w240">
					<el-input type="text" placeholder="请输入(保留2位小数)" maxlength="6" v-model="pageData.formData.productPrice" @blur="productPriceBlur">
						<template #append>元</template>
					</el-input>
				</div>
			</div>
			<div class="rx-sc">
				<span class="w120 t_a_r"><span class="color-red">*</span>售卖方式：</span>
				<div class="w240">
					<el-checkbox-group v-model="pageData.formData.sellType">
						<el-checkbox label="1"><span class="color-666 fw400">会员端售卖</span></el-checkbox>
						<el-checkbox label="2"><span class="color-666 fw400">线下售卖</span></el-checkbox>
					</el-checkbox-group>
				</div>
			</div>
		</div>
		<div class="rx-c">
			<div class="rx-sc mr40">
				<span class="w120 t_a_r" style="margin-top: -2px">
					<span class="color-red">*</span>
					<span>可销售门店</span>
					<span class="v_a_m d_i_b w15 h15 ml5">
						<el-tooltip
						    class="box-item"
						    effect="dark"
						    content="门店将直接上架此卡售卖，无需再次创建"
						    placement="right-start"
						  >
						  <img class="w15 h15" src="@/assets/wenhao.png" >
						</el-tooltip> 
					</span>
					<span>：</span>
				</span>
				<div class="w240 v_a_m">
					<el-radio v-model="pageData.formData.sellSid" label="0" size="large"><span class="color-666 fw400">全部门店</span></el-radio>
					<el-radio v-model="pageData.formData.sellSid" label="1" size="large"><span class="color-666 fw400">多门店</span></el-radio>
				</div>
			</div>
			<div class="rx-sc mr40">
				<span class="w120 t_a_r"><span class="color-red">*</span>扣费顺序：</span>
				<div class="w500">
					<el-radio v-model="pageData.formData.deductionType" :label="1" size="large">优先扣除本金</el-radio>
				</div>
			</div>
		</div>
		<div class="rx-wss mt10 ml120" v-show="pageData.formData.sellSid == '1'">
			<template v-for="(item,index) in pageData.storeList1" :key="index">
				<div class="category mb20 mr20" v-if="item.ifBranchStore == 1">
		 			<el-checkbox v-model="item.check" :label="item.id" >
		 		      	<span class="color-666 fw400">{{item.storeName}}</span>
		 		 	</el-checkbox>
		 		</div>
			</template>
		</div>
		<CommonTitle text="其他信息"/>
		<div class="rx-ss mb25">
			<span class="w120 t_a_r pt10"><span class="color-red">*</span>储值卡封面：</span>
			<div>
				<el-radio-group v-model="pageData.coverCheck">
					<el-radio label="0"><span class="color-666 fw400">风格一</span></el-radio>
					<el-radio label="1"><span class="color-666 fw400">风格二</span></el-radio>
					<el-radio label="2"><span class="color-666 fw400">自定义</span></el-radio>
				</el-radio-group>
				<div class="rx-ss">
					<div class="cover_img">
						<div v-if="pageData.coverCheck == 2" class="cover_dele"><unicon name="trash-alt" fill="#fff" hover-fill="#fff" @click="deleCover"></unicon></div>
						<img v-show="pageData.cover[pageData.coverCheck]" class="w120 h80" :src="pageData.cover[pageData.coverCheck]" alt=""/>
					</div>
					<div v-show="!pageData.cover[pageData.coverCheck]" class="w120 t_a_c color-999 h80 rx-cc mr10" style="border: 1px solid #ccc;">
						<div>
							<CommonUpload @change="uploadChange" :autoCropWidth="375" :autoCropHeight="250" :isCropper="true">
								<img class="w25" src="@/assets/addBank.png" alt="">
								<p>上传图片</p>
							</CommonUpload>
						</div>
					</div>
					<div v-show="!pageData.cover[pageData.coverCheck]" class="color-999 w200">
						建议大小为750*500px的jpg、png、bmp格式不超过2M的图片。
					</div>
				</div>
			</div>
		</div>
		<div class="rx-ss mb25">
			<span class="w120 t_a_r">储值卡介绍：</span>
			<div class="flex1" ref="editor"></div>
		</div>
		
		<div class="pl100">
			<el-button @click="goBack()">取消</el-button>
			<el-button type="primary" v-loading="pageData.loadingButton" @click="addSave">保存并上架</el-button>
		</div>
	</div>
	</workArea>
</template>

<script setup>
	import { ref, reactive, onMounted, onBeforeUnmount } from 'vue';
  	import { useRouter } from 'vue-router';
  	import { Plus, ArrowRight, ArrowDown, ArrowUp, CircleCheckFilled } from '@element-plus/icons-vue'
	import { ElMessage } from 'element-plus'
	import WangEditor from 'wangeditor'
	import CommonTitle from '@/components/CommonTitle.vue'
	import { getFrameworkAllList } from '@/api/staff.js'
  	import { productInfo, productInsert, productUpdate } from '@/api/cards.js'
	import { wangEditorUpload } from '@/common/common.js'
	import workArea from '@/components/branch-store/workArea.vue';
	import CommonUpload from '@/components/CommonUpload.vue';
  	const router = useRouter();
  	const id = router.currentRoute.value.query.id;
	const editor = ref();
	let instance = ''; 
	const pageData = reactive({
		barlist:[{ name : '返回',type:'0' },{ name : (id?'编辑':'创建')+'储值卡' }],
		loading:false,
		loadingButton: false,
		dropdown: false,
		formData:{
			type:3,
			productName:'',
			productTyp:'1',
			ifMonth:1,
			productCycle:'',
			storeCardValue:'',
			deductionType:1,
			productTimeType:'',
			productPrice:'',
			consumeType:[],
			sellSid:'0',
			consumeSid:'0',
			sellType:[],
			productContent:''
		},
		categoryList:[
			{id:1,name:'预约单节团体课'},
			{id:2,name:'预约单节私教课'},
			{id:3,name:'购买私教课'},
			{id:4,name:'购买团课'},
			{id:5,name:'购买卡项'},
			// {id:6,name:'购买商城商品'},
			{id:7,name:'预约场地'},
	        {id:8,name:'购买班级'}
			],
		consumeCheckList:[],
		sellCheckList:[],
		storeList:[],
		storeList1:[],
		coverCheck:'0',
		cover:['https://image.taokatao.cn/imgs/runBusiness/card1.png','https://image.taokatao.cn/imgs/runBusiness/card2.png'],
		dateType:[{id:1,name:'天'},{id:2,name:'月'},{id:3,name:'年'}],
	})
	//所属架构搜索下拉框
	getFrameworkAllList().then((res)=>{
		if(res.code == 0){
			res.data.forEach((item) => {
				item.check = false
			})
			pageData.storeList = res.data;
			pageData.storeList1 = JSON.parse(JSON.stringify(res.data))
		}
	})
	
	//保存
	function addSave(){
		var formData = JSON.parse(JSON.stringify(pageData.formData));
		if(!formData.productName){
			ElMessage({
				type: 'warning',
				message: '请填写储值卡名称',
			})
			return false;
		}
		
		if(formData.productTyp == 3 && !formData.productCycle){
			ElMessage({
				type: 'warning',
				message: '期限卡类型须填写卡消费时长',
			})
			return false;
		}
		if(!formData.productPrice){
			ElMessage({
				type: 'warning',
				message: '请输入售卖金额',
			})
			return false;
		}
		if(formData.sellType.length == 0){
			ElMessage({
				type: 'warning',
				message: '请选择售卖方式',
			})
			return false;
		}
		if(formData.sellSid == 1){
			pageData.sellCheckList = []
      		pageData.storeList1.filter((n) => {
      		  	if (n.check) {
      		  	    pageData.sellCheckList.push(n.id)
      		  	}
      		})
			if (pageData.sellCheckList.length == 0) {
				ElMessage({
					type: 'warning',
					message: '请选择销售门店',
				})
				return false;
			}
		}
		if(formData.consumeSid == 1){
			pageData.consumeCheckList = []
      		pageData.storeList.filter((n) => {
      		  	if (n.check) {
      		  	    pageData.consumeCheckList.push(n.id)
      		  	}
      		})
			if (pageData.consumeCheckList.length == 0) {
				ElMessage({
					type: 'warning',
					message: '请选择消费门店',
				})
				return false;
			}
		}
		if(pageData.coverCheck == 2 && pageData.cover.length == 2){
			ElMessage({
				type: 'warning',
				message: '请上传储值卡封面',
			})
			return false;
		}
		if(!pageData.formData.productCycle && pageData.formData.productCycle != 0){
				pageData.formData.ifMonth = 0
			}
		if(formData.sellSid == 1){
			formData.sellSid = pageData.sellCheckList.join(',');
		}
		if(formData.consumeSid == 1){
			formData.consumeSid = pageData.consumeCheckList.join(',');
		}
		formData.consumeType = formData.consumeType.join(',');
		formData.sellType = formData.sellType.join(',');
		formData.productCover = pageData.cover[pageData.coverCheck];
		formData.productContent = instance.txt.html();
    	pageData.loadingButton = true;
    if(id){
      formData.encryptionId = id;
      productUpdate(formData).then((res)=>{
        pageData.loadingButton = false;
        if(res.code == 0){
          ElMessage({
            type: 'success',
            message: '保存成功',
          })
          setTimeout(()=>{
            goBack();
          },1000)
        }
      }).catch(() => {
		pageData.loadingButton = false;
	  })
    }else{
      productInsert(formData).then((res)=>{
        pageData.loadingButton = false;
        if(res.code == 0){
          ElMessage({
            type: 'success',
            message: '保存成功',
          })
          goBack();
        }
      }).catch(() => {
		pageData.loadingButton = false;
	  })
    }
		
	}
  // 取消返回
  function goBack(){
    router.push({path:'/vipCard',query:{tab:2}});
  }
	//初始化数据
	function toInit(){
		pageData.formData = {
			type:3,
			productName:'',
			productTyp:'1',
			ifMonth:1,
			productCycle:'',
			storeCardValue:'',
			deductionType:1,
			productTimeType:'',
			productPrice:'',
			consumeType:[],
			sellSid:'0',
			consumeSid:'0',
			sellType:[],
			productContent:''
		}
		pageData.coverCheck = '0';
		pageData.cover = ['https://image.taokatao.cn/imgs/runBusiness/card1.png','https://image.taokatao.cn/imgs/runBusiness/card2.png'];
		pageData.consumeCheckList = [];
		pageData.sellCheckList = [];
		instance.txt.html('');
	}
	
	//消费时长失去焦点
	function productCycleBlur(){
    	var {productCycle,productTimeType} = pageData.formData;
    	if(id && productTimeType){
    	  	return false;
    	}
    	if(productCycle && !productTimeType){
    	  	pageData.formData.productTimeType = 1;
    	}else if(!productCycle){
    	  	pageData.formData.productTimeType = '';
    	}
		if (productCycle > 999999) {
			ElMessage({
				type: 'warning',
				message: '最大仅支持六个九',
			})
            pageData.formData.productCycle = ''
			pageData.formData.productTimeType = '';
			return false;
		}
		if (productCycle == 0) {
            pageData.formData.productCycle = ''
			pageData.formData.productTimeType = '';
		}
	}
	
	//消销售金额失去焦点
	function productPriceBlur(){
		var {productPrice} = pageData.formData;
		if(productPrice){
			if(isNaN(Number(productPrice))){
				ElMessage({
					type: 'warning',
					message: '输入销售金额数不正确',
				})
				pageData.formData.productPrice = '';
				return false;
			}
			pageData.formData.productPrice = Number(productPrice).toFixed(2);
		}
	}
	//消销售金额失去焦点
	function storeCardValueBlur(type){
		var {storeCardValue} = pageData.formData;
		if(storeCardValue){
			if(isNaN(Number(storeCardValue))){
				ElMessage({
					type: 'warning',
					message: '输入储值卡面额数不正确',
				})
				pageData.formData.storeCardValue = '';
				return false;
			}
			pageData.formData.storeCardValue = Number(storeCardValue).toFixed(2);
		}
	}
	//上传封面
	function uploadChange(url){
		pageData.cover.push(url);
	}
	// 删除封面
	function deleCover(){
		pageData.cover.splice(2,1);
	}
	onMounted(()=>{
		instance = new WangEditor(editor.value);
		instance.config.customUploadImg = function (resultFiles, insertImgFn) {
			wangEditorUpload({fileData:resultFiles[0]},(res)=>{
				if(res.data.code == 0){
					insertImgFn(res.data.data.wholeUrl);
				}else{
					ElMessage({
						type: 'warning',
						message: '上传失败',
					})
				}
			});
		}
		Object.assign(instance.config, {
			height:300,
			zIndex:100,
		});
		instance.create();
    //编辑
    if(id){
      // pageData.loading = true;
      	productInfo({ encryptionId:id}).then((res)=>{
        	if(res.code == 0){
        	  // pageData.loading = false;
        	  	for(var key in res.data){
        	  	  	pageData.formData[key] = res.data[key];
        	  	}
        	  	if(res.data.sellSid != 0){
					pageData.formData.sellSid = '1';
					pageData.sellCheckList = res.data.sellSid.split(',').map(n=>Number(n));
					res.data.sellSid.split(',').forEach((item) => {
    				  	pageData.storeList1.filter((n) => {
    				  	  	if (n.id == item) {
    				  	  	    n.check = true
    				  	  	}
    				  	})
    				})
				}
				if(res.data.consumeSid != 0){
					pageData.formData.consumeSid = '1';
					res.data.consumeSid.split(',').forEach((item) => {
    				  	pageData.storeList.filter((n) => {
    				  	  	if (n.id == item) {
    				  	  	    n.check = true
    				  	  	}
    				  	})
    				})
				}
        	  	pageData.formData.consumeType = res.data.consumeType.split(',').map(n=>Number(n));
        	  	pageData.formData.sellType = res.data.sellType.split(',');
        	  	var coverIndex = '2';
        	  	pageData.cover.map((n,i)=>{
        	  	  	if(n == res.data.productCover){
        	  	  	  	coverIndex = i+'';
        	  	  	}
        	  	});
        	  	if(coverIndex == 2){ //证明是自定义
        	  	  	pageData.cover.push(res.data.productCover);
        	  	}
        	  	pageData.coverCheck = coverIndex;
        	  	instance.txt.html(res.data.productContent);
        	}
      	})
    }
	})
	onBeforeUnmount(() => {
		instance.destroy();
		instance = null;
	});
	localStorage.setItem('vipCard', 2)
</script>

<style scoped lang="stylus">
	.update_course_card{
		.step_box{
			background-color #FFFAE7;
			li{
				position relative;
				.step_icon{
					width 32px;
					height 32px;
					line-height 32px;
					text-align center;
					border-radius 100%;
					border 1px solid #909399;
					color #909399;
				}
				.step_icon.active{
					color white;
					background-color #409EFF;
					border-color #409EFF;
				}
				.step_right{
					position absolute;
					right 0;
					top 0;
				}
			}
		}
		.table_list{
			table{
				width 100%;
				border-bottom 1px solid #ccc;
				thead{
					tr:first-child{
						background-color #F4F9FF;
						color #999;
					}
					div{
						padding 10px 0;
					}
				}
				tbody{
					tr:nth-child(even){
						background-color #fafafa;
					}
				}
			}
		}
		.cover_img{
			position relative;
			.cover_dele{
				position absolute;
				left 0;
				top 0;
				bottom 0;
				right 0;
				background-color rgba(0,0,0,.3);
				color white;
				text-align center;
				cursor pointer;
				display none;
				line-height 80px;
			}
		}
		.cover_img:hover .cover_dele{
			display block;
		}
	}
	.select-name /deep/ .el-input-group__append{
	    padding:0 !important;
		width: 62px
	}
	.category {
		padding: 0 13px;
		background: #F7F7F7;
    	border-radius: 1px;
    	border: 1px solid #EAF0F8;
	}
</style>
