<template>
    <el-dialog
	    title="计算公式"
	 	width="70%"
	    custom-class="popwechat"
		:append-to-body="true"
		@opened="opened"
	>
        <div class="p20">
            <el-table :data="pageData.data" stripe >
		    	<el-table-column prop="name" label="数据名称" width="250"/>
                <el-table-column prop="value" label="数据含义与计算方式"/>
		    </el-table>
        </div>
    </el-dialog>
</template>

<script setup>
	import { reactive } from 'vue';
    const propData = defineProps({
		type:String,
	})
	const pageData = reactive({
		data: '',
	})
	function opened() {
		switch(propData.type) {
		    case '1':
    			pageData.data = [{
    			    name: '有效上课课节数',
    			    value: '已排课的团体课课节中，至少有1人是签到状态的课节数——按上课日期汇总'
    			},{
    			    name: '有效上课总时长',
    			    value: '有效课节数的课节上课时间加总'
    			},{
    			    name: '已签到人数',
    			    value: '有效课节数中，课节里的签到人数汇总'
    			},{
    			    name: '有效课节消费金额',
    			    value: '有效课节数中，课节里会员预约课程消费总金额（包含未签到的会员消费金额）'
    			}]
		       break;
		    case '2':
    			pageData.data = [{
    			    name: '排课总节数',
    			    value: '筛选范围内，团体课已排课的总课节数—按上课日期汇总'
    			},{
    			    name: '约课总人次',
    			    value: '已排课的团课课节，总的已预约成功的会员人次'
    			},{
    			    name: '课均预约人次',
    			    value: '约课成功总人次➗已排课总节数'
    			}]
		       break;
			case '3':
    			pageData.data = [{
    			    name: '排课总节数',
    			    value: '筛选范围内，团体课已排课的总课节数—按上课日期汇总'
    			},{
    			    name: '容纳总人数',
    			    value: '筛选范围内，团体课已排课的总课节数中设置的总容纳人数'
    			},{
    			    name: '约课总人次',
    			    value: '已排课的团课课节，总的已预约成功的会员人次'
    			},{
    			    name: '课程饱和度',
    			    value: '约课总人次➗容纳总人数'
    			}]
		       break;
			case '4':
    			pageData.data = [{
    			    name: '排班总时长',
    			    value: '筛选范围内，已设置的教练每天工作时长的加总'
    			},{
    			    name: '有效上课总时长',
    			    value: '筛选范围内，私教课有效上课课节数（即课节中有至少1人签到）'
    			},{
    			    name: '有效上课总课节',
    			    value: '筛选范围内，私教课有效上课课节数的课节时长加总'
    			},{
    			    name: '工作饱和度',
    			    value: '有效上课总时长➗排班总时长'
    			}]
		       break;
			case '5':
    			pageData.data = [{
    			    name: '会员购买节数',
    			    value: '本门店售出的（当前状态是有效和冻结的）私教课课节数（含1v1+1v多类型），不包含赠送次数'
    			},{
    			    name: '赠送总节数',
    			    value: '本门店售出的（当前状态是有效和冻结的）私教课赠送课节数（含1v1+1v多类型）'
    			},{
    			    name: '剩余未消节数',
    			    value: '即会员剩余未使用完的私教课总次数（包含冻结中的，但不包含已过期的）'
    			},{
    			    name: '剩余总金额',
    			    value: '即会员剩余未使用完的私教课剩余总金额（包含冻结中的，但不包含已过期的）'
    			},{
    			    name: '剩余摊销（元）',
    			    value: '剩余总金额➗剩余未消节数'
    			}]
		       break;
			case '6':
    			pageData.data = [{
    			    name: '入场总人次',
    			    value: '筛选范围内，通过各种入场凭证成功入场的人次汇总=前台人工入场人次+硬件入场人次（产生入场记录的入场一次算1次）'
    			},{
    			    name: '日均入场人次',
    			    value: '入场总人次➗筛选时间范围天数'
    			},{
    			    name: '入场消费总金额',
    			    value: '使用入场卡消费的金额汇总——汇总值应等于入场卡次卡+期限卡按天自动消费总金额'
    			},{
    			    name: '前台人工入场人次',
    			    value: 'PC端前台手动入场人次'
    			},{
    			    name: '智能硬件入场人次',
    			    value: '通过智能硬件入场人次（入场一次算1次）'
    			}]
		       break;
			case '7':
    			pageData.data = [{
    			    name: '消费总次数',
    			    value: '课程卡、体验卡之次卡会员已消费次数汇总+团体课、私教课消费次（节）数汇总'
    			},{
    			    name: '按天消费总天数',
    			    value: '课程卡、体验卡之期限卡会员已消费扣除天数汇总（期限卡每晚23:59分自动扣费）'
    			},{
    			    name: '消费总金额',
    			    value: '课程卡、体验卡、储值卡、团体课、私教课之会员已消费金额汇总，其中包含储值卡赠送金额消费、单次预约微信支付消费'
    			}]
		       break;
			case '8':
    			pageData.data = [{
    			    name: '消费总次数',
    			    value: '入场卡之次卡会员已消费次数汇总'
    			},{
    			    name: '按天消费总天数',
    			    value: '入场卡期限卡之会员已自动消费天数汇总（期限卡每晚23:59分自动扣费）'
    			},{
    			    name: '消费总金额',
    			    value: '入场卡之会员已消费金额汇总'
    			}]
		       break;
			case '9':
    			pageData.data = [{
    			    name: '课卡未消次数',
    			    value: '全部会员的课程卡、体验卡剩余未消次数——汇总用于上课的卡的未消'
    			},{
    			    name: '课程未消节数',
    			    value: '全部会员的团体课、私教课的未消节数——汇总购买课程的未消'
    			},{
    			    name: '入场卡未消次数',
    			    value: '全部会员的入场卡余未消次数——汇总自主锻炼的次数'
    			},{
    			    name: '未消总金额',
    			    value: '全部会员的卡项和课程的未消总金额，包括储值卡未消金额'
    			},{
    			    name: '已过期金额',
    			    value: '全部期限卡已过期的金额汇总'
    			},{
    			    name: '未消总天数',
    			    value: '全部期限卡的剩余天数汇总'
    			},{
    			    name: '汇总表说明',
    			    value: '拆分商品课程6大类：统计课程卡、体验卡、入场卡、储值卡、团体课、私教课'
    			}]
		       break;
			case '10':
    			pageData.data = [{
    			    name: '会员性别分布',
    			    value: '男、女占比，圈里为所选会员类型的总人数'
    			},{
    			    name: '会员年龄分布',
    			    value: '年龄分布，按照已定义的年龄段拆分'
    			},{
    			    name: '会员跟进情况',
    			    value: '会员是否被跟进过1次的跟进情况，圈里为所选会员类型的总人数'
    			}]
		       break;
			case '11':
    			pageData.data = [{
    			    name: '应到次数汇总',
    			    value: '已预约课程的会员人次中应签到次数汇总'
    			},{
    			    name: '签到次数汇总',
    			    value: '已签到的会员签到次数汇总'
    			},{
    			    name: '未签到次数汇总',
    			    value: '应到次数-签到次数'
    			},{
    			    name: '平均签到率',
    			    value: '已签到次数➗应到次数'
    			}]
		       break;
			case '12':
    			pageData.data = [{
    			    name: '消费总次数',
    			    value: '课程卡、入场卡、体验卡之次卡会员已消费次数汇总+团体课、私教课课节消费次数汇总（单节预约课程算为课程消费里）'
    			},{
    			    name: '消费总金额',
    			    value: '课程卡、入场卡、体验卡、储值卡之会员已消费金额汇总++团体课、私教课课节消费金额汇总，其中包含储值卡赠送金额消费、会员单次微信付费预约'
    			}]
		       break;
			case '13':
    			pageData.data = [{
    			    name: '过期总次数',
    			    value: '全部会员的课程卡、体验卡、入场卡的过期次数'
    			},{
    			    name: '过期金额汇总',
    			    value: '全部会员的课程卡、体验卡、入场卡、储值卡的过期金额汇总'
    			},{
    			    name: '明细表统计规则',
    			    value: '以单个会员的单个购买订单为维度，统计某会员下全部卡的过期情况'
    			}]
		       break;
			case '14':
    			pageData.data = [{
    			    name: '未消总次数',
    			    value: '全部会员的全部卡种类和课程的剩余未消次数（仅统计在本门店购买的订单）'
    			},{
    			    name: '未消总金额',
    			    value: '全部会员的全部卡种类和课程的剩余未消金额（仅统计在本门店购买的订单）'
    			},{
    			    name: '明细表统计规则',
    			    value: '以单个会员为维度，统计某会员下全部卡和课程的购买、未消情况'
    			}]
		       break;
			case '15':
    			pageData.data = [{
    			    name: '订单总实收金额',
    			    value: '即全部销售在本门店售出的全部订单的实收金额汇总——不包含会员线上自主购买时未选择推荐人的订单，且售出概念不包含单次预约付费产生的订单'
    			},{
    			    name: '订单总退款金额',
    			    value: '即全部销售售出订单的退款金额汇总——不包含会员线上自主购买时未选择推荐人的订单'
    			},{
    			    name: '订单总成交笔数',
    			    value: '即全部销售在本门店售出的全部订单笔数汇总'
    			},{
    			    name: '订单总退款笔数',
    			    value: '即全部销售在本门店售出的全部订单发生退款的订单笔数汇总'
    			},{
    			    name: '储值卡售出金额',
    			    value: '即通过本门店售出的储值卡订单的实收金额'
    			},{
    			    name: '储值卡退款金额',
    			    value: '即通过本门店售出的储值卡订单的退款金额'
    			}]
				break;
			case '16':
    			pageData.data = [{
    			    name: '订单总实收金额',
    			    value: '即本门店全部商品售出的订单的实收金额汇总——包含会员线上自主购买时未选择推荐人的订单，但不包含单次预约付费产生的订单'
    			},{
    			    name: '订单总退款金额',
    			    value: '即本门店全部商品售出的订单的实退金额汇总——包含会员线上自主购买时未选择推荐人的订单，但不包含单次预约付费产生的订单'
    			},{
    			    name: '订单总成交笔数',
    			    value: '即本门店全部商品售出的全部订单笔数汇总'
    			},{
    			    name: '订单总退款笔数',
    			    value: '即本门店全部商品的退款订单笔数汇总'
    			},{
    			    name: '原价',
    			    value: '该商品售出时应售卖价格加总'
    			},{
    			    name: '减免金额',
    			    value: '该商品售出时已减免价格加总'
    			}]
				break;
			case '17':
    			pageData.data = [{
    			    name: '订单总数',
    			    value: '即筛选范围内创建订单总数，包含待支付、已支付、已退款、已取消-订单创建时间'
    			},{
    			    name: '已支付订单',
    			    value: '即筛选范围内已支付的订单总数——订单支付时间'
    			},{
    			    name: '已取消订单',
    			    value: '即筛选范围内已取消的订单总数——订单取消时间'
    			},{
    			    name: '已退款订单',
    			    value: '即筛选范围内已退款的订单总数——订单退款时间'
    			},{
    			    name: '部分退款订单',
    			    value: '即筛选范围内已退款的订单总数中，商品被使用过后发生退款的订单数——订单退款时间'
    			},{
    			    name: '订单总实收金额',
    			    value: '即筛选范围内已支付的订单总数的订单实收金额汇总'
    			},{
    			    name: '订单总退款金额',
    			    value: '即筛选范围内已退款的订单总数的订单退款金额汇总'
    			},{
    			    name: '订单净收入',
    			    value: '订单总实收-订单总退款'
    			},{
    			    name: '应售卖总额',
    			    value: '订单售出时应售卖价格加总'
    			},{
    			    name: '减免金额',
    			    value: '订单售出时已减免价格加总'
    			}]
		       break;
			case '18':
    			pageData.data = [{
    			    name: '已售出的订单总数',
    			    value: '即筛选范围内已支付的订单总数——订单支付时间'
    			},{
    			    name: '日均单量',
    			    value: '已售出订单➗搜索日期范围天数'
    			},{
    			    name: '订单总实收金额',
    			    value: '即筛选范围内已支付的订单总数的订单实收金额汇总'
    			},{
    			    name: '客单价',
    			    value: '订单总实收金额➗已售出订单总数'
    			},{
    			    name: '线上订单数',
    			    value: '会员端线上自主购买的已支付订单总数'
    			},{
    			    name: '线上实收',
    			    value: '会员端线上自主购买的订单实收总金额'
    			},{
    			    name: '汇总明细表',
    			    value: '按照几种商品类型分布，可分析店内哪一种商品卖的比较好'
    			}]
		       break;
			case '19':
    			pageData.data = [{
    			    name: '会员来源渠道',
    			    value: '统计各类型会员进入系统的渠道，分析渠道占比'
    			},{
    			    name: '占比',
    			    value: '渠道来源人数➗会员类型下总人数'
    			}]
		       break;
			case '20':
    			pageData.data = [{
    			    name: '新增客户成交',
    			    value: '统计筛选范围内，新增潜在会员数量、新增数据里发生跟进的潜在会员数量、新增数据里成交的潜在会员数量——垂直漏斗模型'
    			},{
    			    name: '整体客户成交',
    			    value: '统计截止目前，门店全部新增潜在会员数量、全部已被跟进的潜在会员数量、潜在会员中全部已成交的数量'
    			}]
		       break;
			case '21':
    			pageData.data = [{
    			    name: '实际营收',
    			    value: '总营收-营收退款-手动录入支出'
    			},{
    			    name: '总营收',
    			    value: '售出订单的本金实付金额之和（不含预收款、不含储值卡赠送金额支付金额）+人工录入收入=收银记账总收入-使用储值卡赠送金额支付'
    			},{
    			    name: '营收退款',
    			    value: '不包含预收款退款及退回储值卡赠送金额的其他退款金额之和'
    			},{
    			    name: '预收款',
    			    value: '储值卡购买订单实收金额汇总'
    			},{
    			    name: '预收款退款',
    			    value: '储值卡退款订单实退金额汇总'
    			},{
    			    name: '储值卡本金支付',
    			    value: '使用储值卡本金支付的金额（储值卡购买实付金额即为本金）'
    			},{
    			    name: '储值卡赠送支付',
    			    value: '使用储值卡赠送金额支付的金额（储值卡面额-本金即为赠送金额）'
    			}]
		       break;
			case '22':
    			pageData.data = [{
    			    name: '私教课购买收入',
    			    value: '本门店售出的私教课订单收入（单次预约课程不纳入售出收入）'
    			},{
    			    name: '团体课购买收入',
    			    value: '本门店售出的团体课订单收入（单次预约课程不纳入售出收入）'
    			},{
    			    name: '课程卡购买收入',
    			    value: '本门店售出的课程卡订单收入'
    			},{
    			    name: '入场卡购买收入',
    			    value: '本门店售出的入场卡订单收入'
    			},{
    			    name: '体验卡购买收入',
    			    value: '本门店售出的体验卡订单收入'
    			},{
    			    name: '单次预约课程收入',
    			    value: '单次预约课程使用微信支付或储值卡抵扣支付的订单收入'
    			},{
    			    name: '人工录入收入',
    			    value: '线下人工录入收入'
    			},{
    			    name: '数据汇总',
    			    value: '最终总收入将等于【收银记账】中已录入的总收入'
    			}]
		       break;
			case '23':
    			pageData.data = [{
    			    name: '私教课退款',
    			    value: '本门店售出的私教课订单退款（单次预约课程取消退款不纳入）'
    			},{
    			    name: '团体课退款',
    			    value: '本门店售出的团体课订单退款（单次预约课程取消退款不纳入）'
    			},{
    			    name: '课程卡退款',
    			    value: '本门店售出的课程卡订单退款'
    			},{
    			    name: '入场卡退款',
    			    value: '本门店售出的入场卡订单退款'
    			},{
    			    name: '体验卡退款',
    			    value: '本门店售出的体验卡订单退款'
    			},{
    			    name: '单次预约课程退款',
    			    value: '单次预约课程使用微信支付或储值卡抵扣支付的订单退款'
    			},{
    			    name: '人工录入支出',
    			    value: '线下人工录入支出'
    			},{
    			    name: '数据汇总',
    			    value: '最终总支出将等于【收银记账】中已录入的总支出'
    			}]
		       break;
		}
	}
	
</script>
<style type='css/style' scoped>

</style>