<template>
	<workArea :barlist="pageData.barlist">
	<div class="p20 bg-fff">
		<!-- 查询 -->
		<div class="ww100 rx-wsc" ref="queryBox">
			<div class="query_item mb16 rx-bc">
				<div class="query_tip rx-ec">姓名/手机号</div>
				<el-input class="query_con" v-model="pageData.searchForm.searchKeys" placeholder="输入关键字" />
			</div>
			<div class="query_item mb16 rx-bc">
				<div class="query_tip rx-ec">所属架构</div>
				<div class="query_con">
					<el-select v-model="pageData.searchForm.frameId" placeholder="请选择">
						<el-option label="全部" value="" selected></el-option>
						<el-option v-for="item in pageData.storeList" :key="item.id" :label="item.storeName" :value="item.encryptionId">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="query_item mb16 rx-bc">
				<div class="query_tip rx-ec">岗位</div>
				<div class="query_con">
					<el-select v-model="pageData.searchForm.roleName" placeholder="请选择">
						<el-option label="全部" value="" selected></el-option>
						<el-option v-for="item in pageData.roleList" :key="item.id" :label="item.roleName" :value="item.roleName">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="query_item mb16 rx-bc">
				<div class="query_tip rx-ec">员工类型</div>
				<div class="query_con">
					<el-select v-model="pageData.searchForm.ifFullTime" placeholder="请选择">
						<el-option label="全部" value="" selected></el-option>
						<el-option label="未知" value="0"></el-option>
						<el-option label="全职" value="1"></el-option>
						<el-option label="兼职" value="2"></el-option>
					 </el-select>
				</div>
			</div>
			<el-button class="mb16 ml16" type="primary" @click="searchTable">查询</el-button>
			<div v-show="queryItemShowAll < 2" @click="triggerShowItemAll" class="query_btn_trans mb16 ml16 rx-cc">{{queryItemShowAll == 1 ? "收起":"展开"}}<el-icon class="el-icon--right" :style="{transform: 'rotate('+(queryItemShowAll == 1 ? '-90deg':'90deg')+')'}"><DArrowRight /></el-icon></div>
		</div>
		<!-- 按钮区域 -->
		<div class="ww100 rx-sc mb16">
			<el-button type="primary" @click="()=>{addStaff()}">新增员工</el-button>
			<!-- <el-button class="query_btn_gray mb16 ml16" size="small">数据权限</el-button> -->
			<el-button type="danger" plain @click="deleStaff">批量删除</el-button>
			<el-button plain @click="resetPassword">重置密码</el-button>
		</div>
		<!-- 数据表格 -->
		<div>
			<el-table ref="satffTable" :data="pageData.tableData" stripe style="width: 100%" @selection-change="checkChange">
				<el-table-column type="selection" width="55" :selectable="(row)=>{return row.userType != 2}" />
			    <el-table-column prop="realName" label="姓名" >
					<template #default="scope">
						<span v-if="scope.row.userType == 2">{{scope.row.realName}}</span>
						<a v-else href="javascript:;" class="color-409EFF tool_btn" @click="addStaff(scope.row)">{{scope.row.realName}}</a>
					</template>
				</el-table-column>
			    <el-table-column label="手机号">
					<template #default="scope">
						{{ scope.row.stallPhone ? scope.row.stallPhone : '-' }}
					</template>
				</el-table-column>
			    <el-table-column label="所属架构" show-overflow-tooltip>
					<template #default="scope">
						{{ scope.row.frameName ? scope.row.frameName : '-' }}
					</template>
				</el-table-column>
			    <el-table-column label="岗位" show-overflow-tooltip>
					<template #default="scope">
						{{ scope.row.roleName ? scope.row.roleName : '-' }}
					</template>
				</el-table-column>
			    <el-table-column show-overflow-tooltip >
					<template #header>
						<div class="rx-sc">
							<span class="v_a_m">监管门店</span>
							<el-tooltip
									class="box-item"
									effect="dark"
									placement="top-start"
								  >
								<template #content>
									<div style="max-width: 200px;">监管门店：为总店成员作为最高管理者可进入的门店，并拥有该门店（直营店）的全部权限</div>
								</template>
								<img class="w15 h15 ml5 v_a_m" src="@/assets/wenhao.png" >
							</el-tooltip>
						</div>
					</template>
					<template #default="scope">
						{{ scope.row.storeName ? scope.row.storeName : '-' }}
					</template>
				</el-table-column>
			    <el-table-column prop="ifFullTime" label="员工类型" >
			    	<template #default="scope">
						<span>{{scope.row.ifFullTime == 1 ? '全职' : scope.row.ifFullTime == 2 ? '兼职':'未知'}}</span>
					</template>
			    </el-table-column>
				<el-table-column label="操作">
					<template #header>
						<div class="rx-sc">
							<span class="v_a_m">操作</span>
							<el-tooltip
									class="box-item"
									effect="dark"
									placement="top-start"
								  >
								<template #content>
									<div style="max-width: 200px;">员工绑定微信后，可微信扫码登陆PC端进入各门店以及接收公众号消息</div>
								</template>
								<img class="w15 h15 ml5 v_a_m" src="@/assets/wenhao.png" >
							</el-tooltip>
						</div>
					</template>
					<template #default="scope">
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" :class="{'color-red':scope.row.openId}" @click="bindWechat(scope.row.encryptionId,scope.row.openId)">{{scope.row.openId ? '解绑微信' : '绑定微信'}}</a>
					</template>
				</el-table-column>
			</el-table>
			<div class="mt20 rx-ec" v-show="pageData.tableData.length > 0">
			    <el-pagination
			      v-model:currentPage="pageData.searchForm.page"
			      :page-sizes="[15,20,30,50,100]"
			      :page-size="pageData.searchForm.limit"
			      layout="total, sizes, prev, pager, next, jumper"
			      :total="pageData.totalCount"
			      @size-change="pageSizeChange"
			      @current-change="searchTable"
			    >
			    </el-pagination>
			  </div>
		</div>
		<el-drawer
		    v-model="pageData.drawer.show"
		    direction="rtl"
			size="initial"
		  >
		  <template #title>
    		  <h4 class="ft18 fw400 color-333 pt5 pb5">{{ pageData.drawer.title }}</h4>
    		</template>
			<div class="w640 hh100 drawer_box o_a pb40">
				<CommonTip text="新增员工登录初始默认密码为8个8。"/>
				<div class="rx-sc mb12">
					<div class="query_tip rx-ec"><span class="color-red">*</span>员工头像：</div>
					<div class="rx-sc">
						<div class="w65 h65 mr10">
							<CommonUpload @change="uploadChange" :autoCropWidth="375" :autoCropHeight="375" :isCropper="true">
								<img class="ww100 hh100 b_r_100" :src="pageData.addFormData.headImgUrl" alt="">
							</CommonUpload>
						</div>
						<div>
							<p>
								<CommonUpload @change="uploadChange" :autoCropWidth="375" :autoCropHeight="375" :isCropper="true">
									<el-button type="primary" plain>{{pageData.addFormData.headImgUrl=='https://image.taokatao.cn/imgs/runBusiness/detailPhoto.png' ? '立即上传' : '重新上传'}}</el-button>
								</CommonUpload>
							</p>
							<p class="color-999 mt10">建议大小：750*750px</p>
						</div>
					</div>
				</div>
				<div class="rx-bc mb12">
					<div class="query_item rx-bc">
						<div class="query_tip rx-ec"><span class="color-red">*</span>员工姓名：</div>
						<el-input class="query_con" v-model="pageData.addFormData.realName" placeholder="输入员工姓名(限20字符)" maxlength="20"/>
					</div>
					<div class="query_item rx-bc">
						<div class="query_tip rx-ec"><span class="color-red">*</span>登录账号：</div>
						<el-input class="query_con" v-model="pageData.addFormData.logingName" placeholder="输入登录账号" />
					</div>
				</div>
				<div class="rx-bc mb12">
					<div class="query_item rx-bc">
						<div class="query_tip rx-ec w75"><span class="color-red">*</span>手机号：</div>
						<el-input class="query_con" v-model="pageData.addFormData.stallPhone" maxlength="11" placeholder="输入手机号"  @input="telInputEvent"/>
					</div>
					<div class="query_item rx-bc">
						<div class="query_tip rx-ec">员工类型：</div>
						<div class="query_con">
							<el-select v-model="pageData.addFormData.ifFullTime" placeholder="请选择">
								<el-option :value="0" label="未知"></el-option>
								<el-option :value="1" label="全职"></el-option>
								<el-option :value="2" label="兼职"></el-option>
							</el-select>
						</div>
					</div>
				</div>
				<div v-show="noBranchStoreCount > 0" class="rx-bc mb12">
					<div class="query_item rx-bc">
						<div class="query_tip rx-ec">监管门店：</div>
						<div class="query_con">
							<el-select multiple collapse-tags v-model="pageData.addFormData.storeId" placeholder="请选择">
								<el-option v-for="item in pageData.storeGateList" :key="item.id" :label="item.storeName" :value="item.encryptionId">
								</el-option>
							</el-select>
						</div>
					</div>
				</div>
				<div v-show="noBranchStoreCount > 0" class="rx-sc mb12 color-aaa ft12"><span class="ml20">*总店成员作为最高管理者可进入的门店，并拥有该门店（直营店）的全部权限</span></div>
				<h4 class="mb12">设置所属架构：</h4>
				<div class="rx-bc pl20 pr20">
					<div class="flex1 shuttle_box">
						<div class="add_title">请选择</div>
						<div class="h400 o_a" v-loading="pageData.treeLoad">
							<el-tree
								ref="storeTree"
							    :data="pageData.storeTree"
							    show-checkbox
							    node-key="id"
								@check-change="changeChecked"
							    :props="{label:'menuName'}"
								:default-checked-keys="pageData.addFormData.roleId"
							  />
						</div>
					</div>
					<div class="mr20 ml20">
						<img class="w45" src="../../assets/right.png" alt="">
					</div>
					<div class="flex1 shuttle_box">
						<div class="add_title">已选择</div>
						<ul class="add_checked h400 o_a">
							<li class="rx-cc mb12" v-for="(item,index) in pageData.treeCheckData" :key="index">
								<img class="w20" src="../../assets/li_icon.png" alt="">
								<div class="color-333 ml5 mr5 w150">
									<span>{{item.storeName+'-'}}</span>
									<span class="t_a_r">{{ '【'+ item.menuName+ '】' }}</span></div>
									<el-icon @click="deleTreePost(item.id)"><Close /></el-icon>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="drawer_btn">
				<el-button @click="pageData.drawer.show = false">取消</el-button>
				<el-button type="primary" @click="saveStaff">确定</el-button>
			</div>
		</el-drawer> 
		<!-- 绑定微信弹框 -->
		<el-dialog
		    v-model="pageData.bindWechat.show"
		    :title="pageData.bindWechat.title"
			width="280px"
			:before-close="bindWechatSave"
		    custom-class="popwechat"
		  >
		    <div class="ww100 h280 cx-sc">
		    	<div class="ww100 rx-cc mt20 h30 color-666">请使用微信扫一扫绑定</div>
		    	<div class="w160 h160 mt20 rx-cc bdr4" style="border:1px solid #d6d6d6">
			    	<div class="w140 h140" id="bindWechatQrcode"></div>
			    </div>
		    </div>
		    <template #footer>
		    	<div class="dialog-footer">
		        	<el-button type="primary" @click="bindWechatSave">确定</el-button>
		    	</div>
		    </template>
		</el-dialog>
		<Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave"/>
	</div>
	</workArea>
</template>
<script setup>
	import { ref, reactive, onMounted } from 'vue';
	import { ElMessage } from 'element-plus'
	import QRCode from 'qrcodejs2'
	import { Refresh, DArrowRight, Close} from '@element-plus/icons-vue'
	import CommonTip from '@/components/CommonTip.vue'
	import Confirm from '@/components/Confirm.vue'
	import utils from '@/common/utils.js'
	import workArea from '@/components/branch-store/workArea.vue';	
	 import CommonUpload from '@/components/CommonUpload.vue';
	import {
		getFrameworkRoleList,
		employeeInsert,
		getBessAccountStaff,
		getFrameworkAllList,
		getAllRoleNoReList,
		getFrameworkStoreList, // 监管门店列表
		getLinkWechat,// 员工绑定
		employeeListStatusUpdate,
		employeeUpdate,
		removeLinkWeChat,
		sysUserPasswordReset
	} from '@/api/staff.js';
	const pageData = reactive({
		barlist:[{ name : '首页' },{ name : '组织架构' },{ name : '员工管理' }],
		tableData:[],
		totalCount : 0,
		searchForm:{
			page:1,
			limit:15,
			frameId:'',
			searchKeys:'',
			roleName:'',
			ifFullTime:''
		},
		roleList:[],
		storeList:[],
		storeGateList:[], // 门店列表
		storeTree:[],
		treeLoad:false,
		treeCheckData:[],
		addFormData:{
			encryptionId:'',
			realName:'',
			logingName:'',
			stallPhone:'',
			ifFullTime:0,
			roleId:[],
			storeId:[],
			headImgUrl:'https://image.taokatao.cn/imgs/runBusiness/detailPhoto.png'
		},
		drawer:{
			title:'新增员工',
			show:false,
		},
		bindWechat:{
			title : '绑定微信',
			show : false
		},
		confirmTitle:'',
		confirmText:'',
		confirmType:'',
		checkStaffList:[],
		bindWechatId:''
	})
	const confirmRef = ref();
	// 绑定微信
	function bindWechat(encryptionId,openId){
		pageData.bindWechatId = encryptionId;
		if(openId){ //解绑
			pageData.confirmTitle = '确定要对该员工解绑微信吗？'
			pageData.confirmType = 'bind';
			confirmRef.value.show();
		}else{ //绑定
			pageData.bindWechat.show = true;
			getLinkWechat({
				encryptionId : encryptionId
			}).then((res)=>{
				document.getElementById('bindWechatQrcode').innerHTML = '';
				new QRCode('bindWechatQrcode', {
				  width: 140,
				  height: 140, // 高度
				  text: res.data, // 二维码内容
				  background: '#fff'
				})
			});
		}
	}
	function bindWechatSave(){
		pageData.bindWechat.show = false;
		searchTable();
	}
	//所属架构搜索下拉框
	getFrameworkAllList().then((res)=>{
		if(res.code == 0){
			pageData.storeList = res.data;
		}
	})
	//监管门店下拉框
	getFrameworkStoreList().then((res)=>{
		if(res.code == 0){
			pageData.storeGateList = res.data;
		}
	})
	//岗位搜索下拉框
	getAllRoleNoReList().then((res)=>{
		if(res.code == 0){
			pageData.roleList = res.data;
		}
	})
	function pageSizeChange(limit){
		pageData.searchForm.page = 1;
		pageData.searchForm.limit = limit;
		searchTable();
	}
	//获取员工列表数据
	searchTable();
	function searchTable(){
		getBessAccountStaff(pageData.searchForm).then((res)=>{
			if(res.code == 0){
				pageData.tableData = res.data;
				pageData.totalCount = res.count;
			}
		})
	}
	
	const noBranchStoreCount = ref(0);
	const storeTree = ref();
	// 新增弹出窗
	function addStaff(row){
		pageData.treeCheckData.length = 0;
		pageData.addFormData.encryptionId = '';
		pageData.addFormData.realName = '';
		pageData.addFormData.logingName = '';
		pageData.addFormData.stallPhone = '';
		pageData.addFormData.ifFullTime = 0;
		pageData.addFormData.roleId.length = 0;
		pageData.addFormData.storeId.length = 0;
		noBranchStoreCount.value = 0;
		if(row){
			for(var key in pageData.addFormData){
				pageData.addFormData[key] = row[key];
			}
			if(!row.headImgUrl){
				pageData.addFormData.headImgUrl = 'https://image.taokatao.cn/imgs/runBusiness/detailPhoto.png';
			}
			pageData.addFormData.roleId = row.roleId ? row.roleId.split(',') : [];
			pageData.addFormData.storeId = row.storeId ? row.storeId.split(',') : [];
		}
		getOrganization();
		pageData.drawer.title = row ? '编辑员工' : '新增员工';
		pageData.drawer.show = true;
	}
	// 新增-获取组织架构
	function getOrganization(){
		pageData.treeLoad = true;
		getFrameworkRoleList().then((res)=>{
			pageData.treeLoad = false;
			if(res.code == 0){
				var list = [];
				res.data.map((n,i)=>{
					list.push({
						menuName:n.storeName,
						children:[]
					})
					if(n.children && n.children.length > 0){
						n.children.map((j,k)=>{
							list[i].children.push({
								id:j.id,
								storeId:n.id,
								storeName:n.storeName,
								ifBranchStore : n.ifBranchStore,
								menuName:j.roleName,
								children:[]
							})
							if(pageData.addFormData.roleId.indexOf(j.id) > -1){
								if(n.ifBranchStore == 0){
									noBranchStoreCount.value = 1;
								}
								pageData.treeCheckData.push({
									id:j.id,
									storeId:n.id,
									storeName:n.storeName,
									ifBranchStore : n.ifBranchStore,
									menuName:j.roleName,
									children:[]
								});
							}
						})
					}
				})
				pageData.storeTree = list;
			}
		})
	}
	//上传封面
	function uploadChange(url){
		pageData.addFormData.headImgUrl = url;
	}
	
	//新增-选择岗位
	function changeChecked(item,isCheck){
		var checkList = storeTree.value.getCheckedNodes();
		pageData.treeCheckData = checkList.filter(n=>n.id);
		var list = pageData.treeCheckData.filter(n=>n.ifBranchStore == 0);
		noBranchStoreCount.value = list.length; //大于0  证明有品牌总店岗位  显示监管门店选项
	}
	
	// //新增-删除已选的岗位
	function deleTreePost(id){
		pageData.treeCheckData.map((n,i)=>{
			if(n.id == id){
				pageData.treeCheckData.splice(i,1);
				storeTree.value.setChecked(id,false);
				var checkList = storeTree.value.getCheckedNodes();
				var list = checkList.filter(n=>n.ifBranchStore == 0);
				noBranchStoreCount.value = list.length;
				return false;
			}
		})
	}
	//新增员工保存
	function saveStaff(){
		var roleId = [],storeId = [];
		pageData.treeCheckData.map((n)=>{
			roleId.push(n.id);
		})
		var opts = {
			realName : pageData.addFormData['realName'],
			logingName : pageData.addFormData['logingName'],
			stallPhone : pageData.addFormData['stallPhone'],
			ifFullTime : pageData.addFormData['ifFullTime'],
			headImgUrl : pageData.addFormData['headImgUrl'],
			roleId : roleId.join(',')
		};
		if(noBranchStoreCount.value > 0){
			opts['storeId'] = pageData.addFormData.storeId.join(',');
		}else{
			opts['storeId'] = '';
		}
		if(pageData.drawer.title == '编辑员工'){
			opts.encryptionId = pageData.addFormData.encryptionId;
			employeeUpdate(opts).then((res)=>{
				if(res.code == 0){
					ElMessage({
						type: 'success',
						message: '编辑成功',
					})
					pageData.drawer.show = false;
					pageData.searchForm.page = 1;
					searchTable();
				}
			})
			return false;
		}
		employeeInsert(opts).then((res)=>{
			if(res.code == 0){
				ElMessage({
					type: 'success',
					message: '保存成功',
				})
				pageData.drawer.show = false;
				pageData.searchForm.page = 1;
				searchTable();
			}
		})
	}
	//获取已选员工列表
	function checkChange(arr){
		pageData.checkStaffList = arr.map(n => n.encryptionId);
	}
	//删除员工
	function deleStaff(){
		if(pageData.checkStaffList.length == 0){
			ElMessage({
				type: 'warning',
				message: '请选择要删除的员工',
			})
			return false;
		}
		pageData.confirmTitle = '确定要删除选中的数据吗？';
		pageData.confirmType = 'dele';
		confirmRef.value.show();
	}
	//重置密码
	function resetPassword(){
		if(pageData.checkStaffList.length == 0){
			ElMessage({
				type: 'warning',
				message: '请选择要重置密码的员工',
			})
			return false;
		}
		pageData.confirmTitle = '确定要重置选中数据的密码吗？';
		pageData.confirmType = 'reset';
		confirmRef.value.show();
	}
	function confirmSave(){
		if(pageData.confirmType == 'dele'){
			employeeListStatusUpdate({
				encryptionId:pageData.checkStaffList.join(',')
			}).then((res)=>{
				if(res.code == 0){
					ElMessage({
						type: 'success',
						message: '删除成功',
					})
					pageData.searchForm.page = 1;
					searchTable();
				}
			})
		}else if(pageData.confirmType == 'bind'){
			removeLinkWeChat({
				encryptionId:pageData.bindWechatId
			}).then((res)=>{
				if(res.code == 0){
					ElMessage({
						type: 'success',
						message: '解绑成功',
					})
					pageData.searchForm.page = 1;
					searchTable();
				}
			})
		}else if(pageData.confirmType == 'reset'){
			sysUserPasswordReset({
				encryptionId:pageData.checkStaffList.join(',')
			}).then((res)=>{
				if(res.code == 0){
					ElMessage({
						type: 'success',
						message: '重置成功',
					})
				}
				pageData.searchForm.page = 1;
				searchTable();
			})
		}
		
	}
	//手机号输入验证
	var telVal = '';
	function telInputEvent(val){
		if(isNaN(Number(val))){
			pageData.addFormData.stallPhone = telVal;
		}else{
			telVal = val;
		}
	}
	// 控制查询区域显示隐藏
	var queryBox = ref(null)
	var queryItemShowAll = ref(9);
	// 触发隐藏和展开
	function triggerShowItemAll(){
		for(var i=queryBox.value.children.length - 1;i>0;i--){
			if(queryBox.value.children[i].className.indexOf('query_item') > -1){
				queryBox.value.children[i].style.display= queryItemShowAll.value == 1 ? "none" : "";
			}
			if(queryItemShowAll.value == 1 && queryBox.value.offsetHeight < 60){
				break;
			}
		}
		queryItemShowAll.value = queryItemShowAll.value == 1 ? 0 : 1;
	}
	onMounted(()=>{
		utils.initQuery(queryItemShowAll,queryBox);
	});
</script>

<style scoped lang="stylus">
	@media screen and (max-width: 1366px) {
	    .h400{
			height 300px !important;
		}
	}
.el-dialog__body{padding:0px !important;}
	.shuttle_box{
		border 1px solid #EBEEF5;
		border-radius 4px;
		.add_title{
			background-color #F4F9FF;
			padding 10px;
			text-align center;
		}
		.add_checked{
			width 100%;
			padding 20px;
			li{
				background-color #F5F5F5;
				border-radius 2px;
				border 1px solid #D6D6D6;
				cursor pointer;
				padding 5px 10px;
			}
			li:hover{
				border 1px solid #409EFF;
				i{
					color red;
				}
			}
		}
		
	}
</style>
