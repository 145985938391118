<template>
    <div class="p20 pt0">
        <div class="nav">
            <formQuery ref="queryOptsRef" :queryOpts="queryOpts" @query="searchGetList" style="flex: 1;"></formQuery>
			<div class="derive">
				<el-button @click="getList(true)" v-if="utils.getMenuRole(3200100)"  :icon="Upload" >导出</el-button>
			</div>
        </div>
         <el-table v-loading="pageData.loading" stripe element-loading-text="拼命加载中。。。" ref="multipleTable" :data="pageData.tableData">
			<el-table-column prop="realName" fixed label="会员名称">
                <template #default="scope">
					<div @click="memberDetail(scope.row.encryptionCardMyId)" class="color-409EFF c_p tool_btn">{{scope.row.realName}}</div>
				</template>
            </el-table-column>
            <el-table-column prop="productName" label="商品名称">
                <template #default="scope">
                    <div v-if="scope.row.orderProductType == 21">
                        {{ scope.row.productName }}
                    </div>
                    <div v-else>{{ scope.row.productName }}</div>
                </template>
            </el-table-column>
            <el-table-column prop="actualMoney" label="退款金额">
                <template #default="scope">
                    ￥{{ scope.row.actualMoney ? scope.row.actualMoney : 0 }}
                </template>
            </el-table-column>
            <el-table-column label="退款方式">
                <template #default="scope">
                    {{ scope.row.refundType == 1 ? '退现金' : scope.row.refundType == 2 ? '退至原支付账户' : '退回储值卡' }}
                </template>
            </el-table-column>
            <el-table-column prop="operationTime" label="退款时间" />
            <el-table-column label="关联订单号">
                <template #default="scope">
                    {{ scope.row.origBatchCode ? scope.row.origBatchCode : '-' }}
                </template>
            </el-table-column>
            <el-table-column label="备注">
                <template #default="scope">
                    {{ scope.row.remark ? scope.row.remark : '-' }}
                </template>
            </el-table-column>
            <el-table-column label="操作员">
                <template #default="scope">
                    {{ scope.row.createUserName ? scope.row.createUserName : '-' }}
                </template>
            </el-table-column>
            <template #empty>
                <el-empty description="暂无数据"></el-empty>
            </template>
        </el-table>
        <div class="mt20 rx-bc">
            <div>
                <span>退款金额总计：<span class="color-red mr5">{{ pageData.summarySaleOut || 0 }}</span>元</span>
            </div>
		    <el-pagination
		      v-model:currentPage="pageData.searchForm.page"
		      :page-sizes="[15,20,30,50,100]"
		      :page-size="pageData.searchForm.limit"
		      layout="total, sizes, prev, pager, next, jumper"
		      :total="pageData.totalCount"
		      @size-change="pageSizeChange"
		      @current-change="getTableData"
		    >
		    </el-pagination>
		</div>
    </div>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import formQuery from '@/components/branch-store/formQuery.vue';
    import utils from '@/common/utils.js'
    import { useStore } from 'vuex'
    import { ElMessage } from 'element-plus'
    import { Upload } from '@element-plus/icons-vue'
    import { useRouter,onBeforeRouteLeave } from 'vue-router';
    import {  getStoreAccountStaffSimple } from '@/api/staff.js'
    import {  queryAllSaleList } from '@/api/finance.js'

    const store = useStore();
	const router = useRouter();
    const pageData = reactive({
        loading: false,
        tableData: [],
        totalCount: 0,
        searchForm: {
            page: 1,
            limit: 15
        },
        summarySaleOut: 0
    })
    const queryOpts = reactive({});
    const saleUserList = reactive([])
    const queryOptsRef = ref()
    const timerList = [
        { value : "1" , label : '今天' },
        { value : "2" , label : '昨天' },
        { value : "5" , label : '最近7天' },
        { value : "7" , label : '最近30天' },
        { value : "-1" , label : '其他' }
    ]
    queryOpts['fields'] = [{
		type : 'input',
		fieldName : 'searchKeys',
		placehold : '请输入会员姓名'
	},{
		type : 'input',
		fieldName : 'productName',
		placehold : '请输入商品名称'
	},{
		type : 'input',
		fieldName : 'origBatchCode',
		placehold : '关联订单号'
	},{
		type : 'select',
		fieldName : 'refundType',
		placehold : '退款方式',
		options : [{label : '退款方式' ,value : ''},{ value : '1' , label : '退现金' },{ value : '2' , label : '退至原支付账户' },{ value : '3' , label : '退回储值卡' }],
        value:''
	},{
        type : 'select',
        fieldName : 'timeIn',
        placehold : '退款时间',
        value : '5',
        options : timerList
    },{
        showField : 'timeIn', // 根据显示字段
        showValue : '-1', // 根据显示字段值
        type : 'dateRange',
        fieldName : 'querydate',
        placeholder : '日期范围'
    },{
		type : 'select',
		fieldName : 'saleUserId',
		placehold : '销售员',
		options : saleUserList,
        value:''
	}]
    /**
     * 获取销售员
     */
    function getPersonnel() {
        let params = {
            page:1,
            limit:1000,
            delFlag: 0
        }
        getStoreAccountStaffSimple(params).then((res) => {
            if (res.code == 0) {
                saleUserList.push({ label:'全部销售员', value: '' })
                res.data.forEach(item => {
                    let params = {
                       label: item.realName,
                       value: item.id
                    }
                   saleUserList.push(params)
               })

            }
        })
    }
    /**
     * 切换每页多少条
     */
    function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		getList();
	}
    /**
     * 搜索
     */
	function searchGetList() {
	   pageData.searchForm.page = 1;
       getList()
	}
    function getList(isExport){
		let params = {}
        Object.assign(params, queryOptsRef.value.getQueryForm())
		params.limit = pageData.searchForm.limit
		params.page = pageData.searchForm.page
        params.state = 2
        if (params.timeIn == '-1' && !params.querydate) {
            ElMessage.warning('请选择自定义时间')
            return false
        }
        if (params.timeIn == '-1' && params.querydate) {
            params.operationTimeStr = params.querydate.join('-')
        }
        pageData.loading = true
		if(isExport){ //导出
			params.limit = 9999;
			params.page = 1;
			params.exportData = 1;
		}
		queryAllSaleList(params).then((res)=>{
			pageData.loading = false
			if(res.code == 0){
				if(isExport){ //导出
					ElMessage({
						type: 'success',
						message: '正在导出，请稍后前往下载中心下载'
					})
				}else{
					pageData.tableData = res.data
					pageData.totalCount = res.count
					pageData.summarySaleOut = res.remark.summarySaleOut
				}
			}
		}).catch(() => {
            pageData.loading = false
        })
	}
   
    /**
     * 会员详情
     */
	const memberDetail=(id) => {
		router.push({path:'/branch/memberDetail',query:{encryptionId:id}});
	}
	/**
     * 会员详情
     */
	onBeforeRouteLeave((to, from)=>{
	    if (to.path === '/branch/memberDetail' || to.path === '/branch/orderDetail') {
	        store.commit('SetKeep',{keepLiveRoute : ['IntentionList']});
	    } else {
	        store.commit('SetKeep',{keepLiveRoute : []});
	    }
	})
    onMounted (() => {
        getList()
        getPersonnel()
    })
</script>
<style type='css/style' scoped>
    .nav{
		display: flex;
	}
    .derive {
        margin-top: 16px;
    }
</style>