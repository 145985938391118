<template>
    <workArea :barlist="barlist" v-loading="pageData.loading">
        <div class="p20" >
            <div class="pb50">
                <h4 class="pl10 mb20">会员信息</h4>
                <div class="ml15 rx-wsc">
                    <div class="mr100">
                        <span>会员姓名：</span>
                        <span>{{ pageData.dataInfo.realName }}</span>
                    </div>
                    <div class="mr100">
                        <span>手机号码：</span>
                        <span>{{ pageData.dataInfo.telephoneNum }}</span>
                    </div>
                    <div>
                        <span>订单来源：</span>
                        <span>{{ pageData.dataInfo.buyPayType == 0 ? '门店创建' : pageData.dataInfo.buyPayType == 1 ? '会员自主买卡' : '会员单次约课'  }}</span>
                    </div>
                </div>
            </div>
            <div class="pb50">
                <h4 class="pl10 mb20">订单信息</h4>
                <div class="ml15 rx-bs">
                    <div>
                        <span>订单状态：</span>
                        <span v-if=" pageData.dataInfo.payState == 0" class="color-FA6400">待支付</span>
                        <span v-if=" pageData.dataInfo.payState == 1" class="color-44D7B6">已支付</span>
                        <span v-if=" pageData.dataInfo.payState == 4" class="color-666">已取消</span>
                        <span v-if=" pageData.dataInfo.payState == 6" class="color-FA0008">已退款</span>
                    </div>
                    <div>
                        <span>订单号：</span>
                        <span>{{ pageData.dataInfo.origBatchCode }}</span>
                    </div>
                    <div>
                        <span>创建时间：</span>
                        <span>{{ pageData.dataInfo.createTime }}</span>
                    </div>
                    <div v-if="pageData.dataInfo.payState != 0">
                        <span>支付时间：</span>
                        <span>{{ pageData.dataInfo.operationTime }}</span>
                    </div>
                    <div>
                        <span>支付方式：</span>
                        <span>{{ filtersType(pageData.dataInfo.payMethodId, 3) }}</span>
                    </div>
                    <div>
                        <span>销售员：</span>
                        <span>{{ pageData.dataInfo.saleUserName }}</span>
                    </div>
                </div>
                <el-table class="mt20" :data="pageData.tableData" stripe>
                    <el-table-column align="center" label="商品名称" show-overflow-tooltip>
                        <template #default>
                            {{ pageData.dataInfo.productName }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="购买课节">
                        <template #default>
                            {{ pageData.dataInfo.productNumBuy }}
                        </template>
                    </el-table-column>
                    <el-table-column v-if="pageData.dataInfo.orderProductType == 11 || pageData.dataInfo.orderProductType == 12" align="center" label="单价(元)">
                        <template #default>
                            {{ pageData.dataInfo.productPrice }}
                        </template>
                    </el-table-column>
                    <el-table-column v-if="pageData.dataInfo.orderProductType == 11 || pageData.dataInfo.orderProductType == 12" align="center" label="购买数量" >
                        <template #default>
                            {{ pageData.dataInfo.productNum }}
                        </template>
                    </el-table-column>
                    <el-table-column v-if="pageData.dataInfo.orderProductType == 11 || pageData.dataInfo.orderProductType == 12" align="center" label="赠送数量">
                        <template #default>
                            {{ pageData.dataInfo.buyGiveNum ? pageData.dataInfo.buyGiveNum : 0 }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="售卖金额(元)">
                        <template #default>
                            {{ pageData.dataInfo.actualMoney }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="减免金额(元)">
                        <template #default>
                            {{ pageData.dataInfo.reducePrice }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="实收金额(元)">
                        <template #default>
                            {{ pageData.dataInfo.actualMoney }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="date" align="center" label="操作">
                        <template #default>
                            <a href="javascript:;" v-if="pageData.dataInfo.payState == 0" class="t_d_u color-409EFF tool_btn" @click="collection">收款</a>
                            <a href="javascript:;" v-if="pageData.dataInfo.payState == 0" class="t_d_u color-409EFF tool_btn" @click="cancelOrder">取消</a>
                            <a href="javascript:;" v-if="pageData.dataInfo.payState == 1" class="t_d_u color-409EFF tool_btn" @click="memberRefundEvent">退款</a>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="pb50">
                <h4 class="pl10 mb20">操作记录</h4>
                <el-table class="mt20" :data="pageData.recordsList" stripe>
                    <el-table-column prop="createTime" align="center" label="时间" />
                    <el-table-column prop="opType" align="center" label="行为">
                        <template #default="scope">
                            {{ filtersType(scope.row.opType, 4) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="realName" align="center" label="操作人" />
                    <el-table-column prop="remark" align="center" label="备注" />
                </el-table>
            </div>
            <MemberRefund v-model="pageData.memberRefundShow" :memberid="pageData.memberId" :type="pageData.type" :orderid="pageData.orderId" @close="memberRefundClose" @save="memberRefundSave"></MemberRefund>
        </div>
    </workArea>
</template>

<script setup>
    import {onMounted, reactive} from 'vue';
    import workArea from '@/components/branch-store/workArea.vue';
    import MemberRefund from '@/components/branch-store/member/MemberRefund.vue';
    import { ElMessageBox, ElMessage } from 'element-plus'
    import { useRouter } from 'vue-router'
    import utils from '@/common/utils.js'
    import {  querySaleInfo, closeTrans } from '@/api/finance.js'

    const barlist = reactive([{ name : '返回',type:0},{ name : '销售记录' },{ name : '订单详情' }]); // 面包屑
    const router = useRouter()
    const encryptionId = router.currentRoute.value.query.encryptionId
    const pageData = reactive({
        loading: false,
        memberRefundShow:false, // 退款弹框
		orderId:'',
		memberId:'',
        tableData: [{}], // 
        recordsList:[],
        dataInfo: {}, // 记录信息
        type: 0,
    })
    const orderProductType = [
        { value : '1' , label : '课程卡' },
        { value : '2' , label : '入场卡' },
        { value : '3' , label : '储值卡' },
        { value : '4' , label : '体验卡' },
        { value : '11' , label : '团体课' },
        { value : '12' , label : '私教课' },
        // { value : '13' , label : '小班课' },
        // { value : '21' , label : '场地预约' }
    ]
    const payState = [
        { value : '0' , label : '待支付' },
        { value : '1' , label : '已支付' },
        { value : '2' , label : '支付失败' },
        { value : '3' , label : '无需支付' },
        { value : '4' , label : '已关闭' },
        { value : '5' , label : '已取消' },
        { value : '6' , label : '已退款' }
    ]
    const opTypeList = [
        { value : '1' , label : '创建订单' },
        { value : '2' , label : '作废订单' },
        { value : '3' , label : '支付订单' },
        { value : '4' , label : '申请退款' },
        { value : '5' , label : '退款完成' }
    ]
    /**
     * @param (String, Number) value-类型, type-1 商品类型 2-支付状态 3-支付方式
     * 表格字段过滤过滤展示
     */
    function filtersType(value, type) {
        let arry = type == 1 ? orderProductType : type == 2 ? payState :  type == 3 ? utils.getDict('waterPayType') : opTypeList
        let text = ''
        arry.forEach((item) => {
            if (item.value == value) {
                text = item.label
            }
        })
        return text
    }
    /**
     * 获取记录详情
     */
    function getInfo() {
        pageData.loading = true
        querySaleInfo({ encryptionId: encryptionId }).then((res) => {
            pageData.loading = false
            if (res.code == 0) {
                pageData.dataInfo = res.data
                pageData.recordsList = res.remark
            }
            
        }).catch(() => {
            pageData.loading = false
        })
    }
    /**
     * 收款
     */
    const collection=()=>{
        let data = {
            encryptionBatchId: pageData.dataInfo.encryptionBatchId,
            batchCode: pageData.dataInfo.batchCode,
            actualMoney: pageData.dataInfo.actualMoney,
            realName: pageData.dataInfo.realName,
            telephoneNum: pageData.dataInfo.telephoneNum
        } 	    
        window.open('/#/branch/payCourseMoney?from=5&info=' + encodeURIComponent(JSON.stringify(data)) + '&id=' + encryptionId, '_blank')
    }
    /**
     * 更改减免金额
     */
    const changeExemption =(val)=> {
        form.subtotal = Math.round((parseFloat(form.price) - parseFloat(form.exemption))*100)/100
    }
    /**
     * 取消订单
     */
    const cancelOrder=()=>{
        ElMessageBox.confirm('确定取消订单吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(() => {
            closeTrans({ encryptionFinanceId: pageData.dataInfo.encryptionId }).then((res)=> {
                if (res.code == 0) {
                    ElMessage.success('取消成功')
                    getList()
                }
            })
        })
    }
    //退款
	function memberRefundEvent(){
		pageData.orderId = pageData.dataInfo.encryptionId;
		pageData.memberId = pageData.dataInfo.encryptionCardMyId;
        pageData.type = pageData.dataInfo.orderProductType
		pageData.memberRefundShow = true;
	}
	function memberRefundSave(){
		pageData.memberRefundShow = false;
		getList();
	}
	function memberRefundClose(){
		pageData.memberRefundShow = false;
	}
    onMounted(() => {
        getInfo()
    })
</script>
<style type='css/style' scoped>
    h4{
		border-left: 4px solid #00A3FF;
	}
    .formRef /deep/ .el-select{
        width: 200px !important;
    }
    .formRef /deep/ .el-input-number.is-without-controls .el-input__inner{
        text-align: left;
    }
</style>