<template>
	<div class="course_card mt16">
		<div class="ww100 rx-wsc" ref="queryBox">
			<div class="query_item mb16 rx-c">
				<div class="query_tip rx-ec">储值卡名称</div>
				<el-input class="query_con" v-model="pageData.searchForm.productName" placeholder="输入关键字" />
			</div>
			<div class="query_item mb16 rx-c">
				<div class="query_tip rx-ec">储值卡状态</div>
				<div class="query_con">
					<el-select  class="" v-model="pageData.searchForm.shelfState" placeholder="请选择">
						<el-option label="已上架" value="1"></el-option>
						<el-option label="已下架" value="0"></el-option>
					</el-select>
				</div>
			</div>
			<div class="query_item mb16 rx-c">
				<div class="query_tip rx-ec">发布渠道</div>
				<div class="query_con">
					<el-select class="" v-model="pageData.searchForm.storeId" placeholder="请选择">
						<el-option label="全部发布渠道" value=""></el-option>
						<el-option v-for="item in pageData.storeList" :key="item.sid" :label="item.storeName" :value="item.sid">
						</el-option>
					 </el-select>
				</div>
			</div>
			<el-button class="mb16 ml16" type="primary" @click="searchTable">查询</el-button>
			<div v-show="queryItemShowAll < 2" @click="triggerShowItemAll" class="query_btn_trans mb16 ml16 rx-cc">{{queryItemShowAll == 1 ? "收起":"展开"}}<el-icon class="el-icon--right" :style="{transform: 'rotate('+(queryItemShowAll == 1 ? '-90deg':'90deg')+')'}"><DArrowRight /></el-icon></div>
		</div>
		<div class="mb12">
			<el-button class="mb10" type="primary" @click="updateRow('')">新增储值卡</el-button>
		</div>
		<div>
			<el-table :data="pageData.tableData" stripe style="width: 100%">
			    <el-table-column prop="productName" label="储值卡名称" show-overflow-tooltip>
				  	<template #default="scope">
				  		<span v-if="scope.row.sid == 0" @click="updateRow(scope.row.encryptionId)" class="color-409EFF c_p tool_btn">{{scope.row.productName}}</span>
				  		<span v-else>{{scope.row.productName}}</span>
				  	</template>
				  </el-table-column>
			    <el-table-column prop="storeCardValue" label="储值卡面额(元)" />
          <el-table-column prop="productCycle" label="可消费时限" >
            <template #default="scope">
                <span>
                  {{
                    (scope.row.productCycle || '') + (scope.row.productCycle ? scope.row.ifMonth === 1 ? '天' : scope.row.ifMonth === 2 ? '月' : '年' : '不限时')
                  }}
                </span>
            </template>
          </el-table-column>
			    <el-table-column prop="productPrice" label="售价(元)" />
          <el-table-column prop="consumeStoreName" label="可消费门店" show-overflow-tooltip />
			    <el-table-column prop="name" label="上架/可售门店">
					<template #default="scope">
            <span @click="isSellClick(scope.row.encryptionId)" class="color-409EFF c_p tool_btn">{{scope.row.shelfCount+'/'+scope.row.sellSidCount}}</span>
					</template>
				</el-table-column>
			    <el-table-column prop="storeName" label="发布渠道" />
				<el-table-column label="操作" width="160">
					<template #default="scope">
						<a v-if="scope.row.sid == 0" href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="categoryClick(scope.row)">消费类目</a>
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="lookDetail(scope.row)">查看详情</a>
					</template>
				</el-table-column>
			</el-table>
			<div class="mt20 rx-ec">
			    <el-pagination
			      v-model:currentPage="pageData.searchForm.page"
			      :page-sizes="[15,20,30,50,100]"
			      :page-size="pageData.searchForm.limit"
			      layout="total, sizes, prev, pager, next, jumper"
			      :total="pageData.totalCount"
			      @size-change="pageSizeChange"
			      @current-change="()=>{getList()}"
			    >
			    </el-pagination>
			</div>
		</div>
    <!-- 可售卖门店-->
    <el-dialog
        v-model="pageData.isSellShow"
        title="可售卖门店"
        width="750px"
        :before-close="()=>{pageData.isSellShow = false}"
        @close="getList"
        custom-class="popwechat"
    >
      <div class="pl20 pt20 pr20 pb20">
        <div class="mb16">
          <el-button type="primary" @click="updateShelfState('',1)">批量上架</el-button>
          <el-button type="danger" plain @click="updateShelfState('',0)">批量下架</el-button>
        </div>
        <div>
          <el-table ref="isSellTableRef" :data="pageData.isSellTableData" border stripe style="width: 100%"  @selection-change="selectionChange">
            <el-table-column type="selection" width="55" />
            <el-table-column prop="storeName" label="门店名称" />
            <el-table-column prop="shelfState" label="上架状态">
              <template #default="scope">
                <span class="stateIcon pl15" :class="scope.row.shelfState ? 'blue' : 'red'">{{scope.row.shelfState ? '已上架' : '已下架'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="updateByName" label="操作人"/>
            <el-table-column label="操作" >
              <template #default="scope">
                <a v-if="scope.row.shelfState" href="javascript:;" class="t_d_u color-F96667 tool_btn_r" @click="updateShelfState(scope.row,0)">下架</a>
                <a v-else href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="updateShelfState(scope.row,1)">上架</a>
              </template>
            </el-table-column>
          </el-table>
          <div class="mt20 rx-ec">
            <el-pagination
                v-model:currentPage="pageData.isSellPage"
                page-size="15"
                layout=" prev, pager, next"
                @current-change="shelfChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <template #footer>
    		  	<span class="dialog-footer"></span>
    		</template>
    </el-dialog>
<!--    消费类目-->
    <el-dialog
        v-model="pageData.categoryShow"
        title="消费类目"
        width="516px"
        :before-close="()=>{pageData.categoryShow = false}"
        @close="getList"
        custom-class="popwechat"
    >
      <div class="pl20 pt40 pr20 pb40">
          <div class="rx-wss">
				      <div class="category mb20 mr20" v-for="item in pageData.categoryList" :key="item.id">
				      	  <el-checkbox v-model="item.check" :label="item.id" >
			                <span class="color-666 fw400">{{item.name}}</span>
			            </el-checkbox>
				      </div>
      		</div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="pageData.categoryShow = false">取消</el-button>
          <el-button type="primary" @click="categorySave">确定</el-button>
        </div>
      </template>
    </el-dialog>
	</div>
</template>

<script setup>
	import {ref,reactive,onMounted,onActivated} from 'vue';
	import {useRouter,onBeforeRouteLeave} from 'vue-router';
	import {Refresh,DArrowRight,Connection,Close} from '@element-plus/icons-vue'
	import utils from '@/common/utils.js'
	import {getFrameworkAllList} from '@/api/staff.js';
  import {productList,storeShelfState, updateShelf,updateRun} from '@/api/cards.js';
  import {ElMessage} from "element-plus";
  import {useStore} from 'vuex'
  const store = useStore();
	const router = useRouter();
	const pageData = reactive({
		tabNum:'0',
		storeList:[],
		tableData:[],
		totalCount:0,
		searchForm:{
			page:1,
			limit:15,
			type:3,
			productName:'',
			shelfState:'1',
			storeId:''
		},
		isSellShow:false,//可售卖门店
		isSellTableData:[],
		isSellPage:1,
    categoryList:[
      {id:1, name:'预约单节团体课', check: false},
      {id:2, name:'预约单节私教课', check: false},
      {id:3, name:'购买私教课', check: false},
      {id:4, name:'购买团课', check: false},
      {id:5, name:'购买卡项', check: false},
      // {id:6,name:'购买商城商品'},
      {id:7,name:'预约场地'},
	    {id:8,name:'购买班级'}
    ],
    categoryShow:false
	})
	//渠道下拉框
	getFrameworkAllList().then((res)=>{
		if(res.code == 0){
			pageData.storeList = res.data;
		}
	})
	//搜索table
	function searchTable(){
		pageData.searchForm.page = 1;
		getList();
	}
	//切换分页数量
	function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		searchTable();
	}
	getList();
	function getList(){
		productList(pageData.searchForm).then((res)=>{
			if(res.code == 0){
				pageData.tableData = res.data;
        pageData.totalCount = res.count;
			}
		})
	}
	//新增或编辑
	function updateRow(id){
		router.push({path:'/updateStoreCard',query:{id}});
	}
	//查看详情
	function lookDetail(row){
    router.push({path:'/cardDetail',query:{
			id:row.encryptionId,
      type: 2,
			consumeType: row.consumeType
		}});
	}
  // 批量上下架
  function isSellClick(id){
    pageData.bindCourseId = id;
    pageData.isSellShow = true;
    getSellList();
  }
  function getSellList(){
    storeShelfState({
      encryptionId:pageData.bindCourseId
    }).then((res)=>{
      if(res.code == 0){
        pageData.isSellTableData = res.data;
      }
    })
  }
  let encryptionId = [],sids = [];
  function selectionChange(arr){
    arr.map((n)=>{
      encryptionId.push(n.encryptionId);
      sids.push(n.sids);
    })
  }
  function updateShelfState(row,shelfState){
    var eid = '',sid = '';
    if(row){ //单个
      eid = row.encryptionId;
      sid = row.encryptionSid;
    }else{ //批量
      if(encryptionId.length == 0){
        ElMessage({
          type: 'warning',
          message: '请选择门店',
        })
        return false;
      }
      eid = encryptionId.join(',');
      sid = sids.join(',');
    }

    updateShelf({
      productIds:pageData.bindCourseId,
      encryptionId:eid,
      sids:sid,
      shelfState
    }).then((res)=>{
      if(res.code == 0){
        ElMessage({
          type: 'success',
          message: shelfState == 1?'上架成功':'下架成功',
        })
        getSellList();
      }
    })
  }
  //消费类目
  let rowId = '' ;
  function categoryClick(row) {
    rowId = row.encryptionId;
    row.consumeType.split(',').forEach((item) => {
      pageData.categoryList.filter((n) => {
        if (n.id == item) {
            n.check = true
        }
      })
    })
    pageData.categoryShow = true;
  }
  function categorySave() {
    let arr = []
      pageData.categoryList.filter((n) => {
        if (n.check) {
            arr.push(n.id)
        }
      })
    updateRun({
      consumeType:arr.join(','),
      encryptionId:rowId
    }).then((res)=>{
      if(res.code == 0){
        ElMessage({
          type: 'success',
          message: '保存成功',
        })
        pageData.categoryShow = false;
        getList();
      }
    })
  }



	// 控制查询区域显示隐藏
	var queryBox = ref(null)
	var queryItemShowAll = ref(9);
	// 触发隐藏和展开
	function triggerShowItemAll(){
		for(var i=queryBox.value.children.length - 1;i>0;i--){
			if(queryBox.value.children[i].className.indexOf('query_item') > -1){
				queryBox.value.children[i].style.display= queryItemShowAll.value == 1 ? "none" : "";
			}
			if(queryItemShowAll.value == 1 && queryBox.value.offsetHeight < 60){
				break;
			}
		}
		queryItemShowAll.value = queryItemShowAll.value == 1 ? 0 : 1;
	}
	onMounted(()=>{
		utils.initQuery(queryItemShowAll,queryBox);
	});
	// 初始化页面
	onActivated(()=>{
	    getList();
	})
	
	
	// 页面缓存
	onBeforeRouteLeave((to, from)=>{
	    if (to.path === '/updateStoreCard' || to.path === '/cardDetail') {
	        store.commit('SetKeep',{keepLiveRoute : ['MainCards']});
	    } else {
	        store.commit('SetKeep',{keepLiveRoute : []});
	    }
	})
</script>

<style scoped lang="stylus">
	.course_card{
		.bind_table{
			position relative;
			overflow hidden;
			transition 0.5s;
			.bind_table_show{
				position absolute;
				right 10px;
				top 9px;
				z-index 100;
				color #409EFF;
				
			}
		}
		.bind_table_num{
			border none;
			border-bottom 1px solid #999;
			background-color initial;
		}
    .stateIcon{
      position relative;
    }
    .stateIcon::after{
      content '';
      position absolute;
      left 0;
      top 50%;
      transform translateY(-50%);
      width 8px;
      height 8px;
      border 2px solid #FBE3E3;
      background-color #FE8585;
      border-radius 100%;
    }
    .stateIcon.blue::after{
      content '';
      position absolute;
      left 0;
      top 50%;
      transform translateY(-50%);
      width 8px;
      height 8px;
      border 2px solid #DAF7F0;
      background-color #44D7B6;
      border-radius 100%;
    }
	}
  .category {
		padding: 0 13px;
		background: #F7F7F7;
    border-radius: 1px;
    border: 1px solid #EAF0F8;
	}
</style>
