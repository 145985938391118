<template>
	<workArea :barlist="pageData.barlist">
		<div class="rx-cc ww100 hh100 bg-fff">
			<div class="t_a_c">
				<img class="w420 h225" src="@/assets/noData.png" alt="">
				<p class="color-666 ft20 fwb mt65">欢迎使用跃犀云运动健身管理系统</p>
			</div>
		</div>
	</workArea>
</template>

<script setup>
import { reactive } from 'vue';
import workArea from '@/components/branch-store/workArea.vue';	
const pageData = reactive({
		barlist:[{ name : '数据概览' }]
})
</script>

<style>
</style>
